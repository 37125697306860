import React, { useState, useEffect,useContext, useCallback, useRef } from "react";
import moment from "moment";
//import InfiniteCalendar from "react-infinite-calendar";
import "react-infinite-calendar/styles.css"; // only needs to be imported once
// Render the Calendar
import Calendar from "react-calendar";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../theme/Theme";
import { ObjectLiteral } from "../interfacesProps/ObjectLiteralProps";
import { RBContext } from './RBContext';
import { forEach } from "lodash";



interface RBCalendorForUnavailabilityProps {
  account:any;
    unavailableDays: any;
    enableInput:boolean;
    onValueChange:Function;
}

export const RBCalendorForUnavailability: React.FC<RBCalendorForUnavailabilityProps> = ({ ...props }) => {
  const [currentActiveDate, setCurrentActiveDate] = useState(new Date());
  const [currentUnavailability, setCurrentUnavailability] = useState<ObjectLiteral[]>([]);

  const {primaryColor,secondaryColor } = useContext(RBContext);
  const [enableInput, setEnableInput] = useState<any>(false);
   
    const dates = props.unavailableDays.map((item:any) => item.date);
  const [unavailableDays, setUnavailableDays] = useState<any>(dates);
  const [selectedDates, setSelectedDates] = useState<any>([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  // const calendarRef = useRef<any>(null);

  const [calendarKey, setCalendarKey] = useState(Date.now());
  useEffect(() => {
    setEnableInput(localStorage.getItem("enableInput"))
    console.log('unavailableDays',unavailableDays)
    setSelectedDates(dates)
  }, []);

  useEffect(() => {

    // this.setState({
    //   items: this.props.items,
    //   dateStylesForShifts: this.SetUpDateStylesForShifts(this.props.items),
    // });
    //setUnavailableDays(props.unavailableDays)

    //getClassName(props.date,unavailableDays)
    //setSelectedDates(selectedDates);
  }, [updateTrigger]);


  const useStyles = makeStyles((_theme) => ({
    calendar: {
      borderWidth: `0 !important`,
      maxWidth: "100%",
  
      "& .react-calendar__viewContainer": {
        "& .react-calendar__tile": {
          borderWidth: "1px",
          borderRadius: "5",
          borderStyle: "solid",
          borderColor: Colors.white,
          
          //backgroundColor: '',
  
  
          // pointer-events: none,
          // background-color: #f7f7f7,
          // cursor: not-allowed,
          //   backgroundColor: Colors.white,
        },
        "& .react-calendar__tile--active": {
          //borderRadius: "5px",
          // borderWidth: "0px",
  
          padding: "0px",
          margin: "0px",
          color: `${enableInput == "false"?  "black" : "white" }`,
          backgroundColor: `${enableInput == "false"?  "" : "red" }` ,
        },
        "& .react-calendar__tile--now": {
          padding: "0px",
          margin: "0px",
          color: `${enableInput == "false"?  "black" : "white" }`,
          backgroundColor: `${enableInput == "false"?  "#989494" : "#989494" }` ,
        },
  
        "& .react-calendar__tile--occupied": {
          // borderRadius: "5px",
  
          // paddingRight: "-1px",
          // paddingLeft: "-1px",
  
          //  borderWidth: "0px",
  
          color: `#FFFFFF !important`,
          backgroundColor: `red !important`,
        },
        // "& .react-calendar__tile--now": {
        //   color: `#34af47 !important`,
        //   backgroundColor: `rgba(0,0,0,0) !important`,
        // },
      },
      "& .react-calendar__navigation": {
        height: "40px",
        "& .react-calendar__navigation__arrow": {
          color: "#000",
          fontSize: "2em",
          backgroundColor: `#FFFFFF !important`,
          "&:enabled": {
            "&:hover, &:focus": {
              backgroundColor: `${Colors.defaultGrey} !important`,
            },
          },
        },
        "& .react-calendar__navigation__label": {
          color: "#000",
          fontSize: "2em",
          backgroundColor: `#FFFFFF !important`,
          "&:enabled": {
            "&:hover, &:focus": {
              backgroundColor: `${Colors.defaultGrey} !important`,
            },
          },
        },
      },
      "& abbr": {
        border: `none !important`,
        cursor: `inherit !important`,
        textDecoration: `none`,
      },
      "& .react-calendar__month-view__weekdays ": {
        fontSize: "1.5em",
        textAlign: "center",
        textDecoration: "none",
        border: "none",
      },
      "& .react-calendar__month-view__days__day ": {
        fontSize: "1.5em",
        textAlign: "center",
        textDecoration: "none",
      },
      "& .react-calendar__month-view__days__day--neighboringMonth": {
        color: "#757575",
      },
      "& .react-calendar__year-view__months__month": {
        fontSize: "2em",
        textAlign: "center",
        textDecoration: "none",
      },
    },
  }));


//   useEffect(() => {
//     //props.onActiveDayChanged(currentActiveDate);
//   }, [currentActiveDate]);
  const onChange = (date: any) => {
   


  };

  const onClickDay = (date: any) => {
   
  };
  const onViewChange = (activeStartDate: any) => {
    //console.log("onViewChange ", activeStartDate);

  };
  const onClickMonth = (date: any) => {
    //console.log("onClickMonth ", date);
  };


  const highlightedDates = [new Date()];
  // const getClassName = (date: any) => {
  //   const rawDay = moment().add(1, 'minute')
  //   const newDrop = unavailableDays.filter((item: any) => moment(item).isAfter(rawDay))
  //   //const newDrop = unavailableDays;
  //   if (
  //     newDrop.includes(moment(date).format("YYYY-MM-DD"))
  //   ) {
  //     return "react-calendar__tile--occupied";
  //   } else {
  //     return "calendar-unselected";
  //   }

  // };
  
  const classes = useStyles();
  const isDateDisabled = (date:Date) => {
    // Disable dates before the current date
    return moment(date).isBefore(moment().startOf('day'));;
  };
  const handleDayClick = (date:any) => {
    // if(enableInput === 'true')
    // {
    //   const rawDay = moment(date).add(1, 'minute')
    //   if (moment().isBefore(rawDay) && moment().add(91, 'days').isAfter(rawDay)) {
    //     const day = rawDay.format('YYYY-MM-DD')
    //     if (unavailableDays.length > 0 && unavailableDays.includes(day)) {
    //       // remove date
    //       const newDrop = unavailableDays.filter((item: any) => item !== day)
    //       setUnavailableDays(newDrop);
    //       props?.onValueChange(newDrop);
    //       getClassName(date)
         
    //     } else {
    //       // add date
    //       unavailableDays.push(day)
    //       const newAdd = unavailableDays;
    //       setUnavailableDays(newAdd);
    //       props.onValueChange(newAdd);
    //       getClassName(date)

         
    //     }
    //     console.log("unavailableDays",unavailableDays)
    //   }
    // }
  };

  // const handleDateChange = useCallback(
  //   (date:any) => {
  //     if(enableInput === "true")
  //     {
  //       const formattedDate = moment(date).format('YYYY-MM-DD');
  //       if (selectedDates.includes(formattedDate)) {
  //         // Remove the date if it is already selected
  //         setSelectedDates(selectedDates.filter((d:any) => d !== formattedDate));
  //       } else {
  //         // Add the date to selectedDates array
  //         setSelectedDates([...selectedDates, formattedDate]);
  //       }

  //       // Toggle the updateTrigger to trigger a re-render
  //       setUpdateTrigger((prev) => !prev);
  //     }
  //   },
  //   [selectedDates]
    
  // );

  const handleDateChange = (date:any) => {
    if(enableInput === "true")
    {
      const formattedDate = moment(date).format('YYYY-MM-DD');

      if (selectedDates.includes(formattedDate)) {
        // Remove the date if it is already selected
        setSelectedDates(selectedDates.filter((d:any) => d !== formattedDate));
        props?.onValueChange(selectedDates.filter((d:any) => d !== formattedDate));
       
      } else {
        // Add the date to selectedDates array
        setSelectedDates([...selectedDates, formattedDate]);
        props?.onValueChange([...selectedDates, formattedDate]);
      }
      setCalendarKey(Date.now());

      // debugger
      
    }
  };


  const getClassName = (date:any) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const filteredDates = selectedDates.filter((date:any) => {
      return moment(date).isSameOrAfter(moment().startOf('day'));
    });
    if (filteredDates.includes(formattedDate)) {
      return 'react-calendar__tile--occupied';
    } else if (unavailableDays.includes(formattedDate)) {
      return 'calendar-unselected';
    } else {
      return '';
    }
  };

 
  // const getClassName = useCallback(
  //   (date) => {
     
  //     const formattedDate = moment(date).format('YYYY-MM-DD');

  //     if (selectedDates.includes(formattedDate)) {
  //       return 'react-calendar__tile--occupied';
  //     } else if (unavailableDays.includes(formattedDate)) {
  //       return 'calendar-unselected';
  //     } else {
  //       return '';
  //     }

  // },
  //   [selectedDates, unavailableDays]
  // );




  return (
    <div style={{}}>
      <Calendar
        locale="en-US"
        minDetail={"month"}
        className={classes.calendar}
        onChange={handleDateChange}
        key={calendarKey}
        //onClickMonth={onClickMonth}
        //onActiveStartDateChange={onViewChange}
        //onViewChange={onViewChange}
        // value={value}
        //onClickDay={handleDayClick}
        tileClassName={({ date }) => getClassName(date)}
        //defaultValue={new Date()}
        minDate={new Date()}
       // minDate={}
        maxDate={new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000)}
        //selectRange={false}
        tileDisabled={({ date }) => isDateDisabled(date)}
      />
    </div>
  );
};
