import {
  RouteProps,
  RouteComponentProps,
  Redirect,
  Route,
} from "react-router-dom";
import React from "react";
import { AuthService } from "./api/AuthService";

type AuthRouteProps = RouteProps;

//todo
//
export class AuthRoute extends React.PureComponent<AuthRouteProps> {
  render() {
    const { children, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={({ location }) =>
          AuthService.checkAuth() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/signIn",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
}
