import React, { useEffect, useState } from "react";
import { PharmacyService } from "../../../api/PharmacyService";

import { SkillType, workerTypeEnum } from "../../../util/Enumeration";
import { LoadingIndicator } from "../../../components/LoadingIndicator";
import { Formik, Form } from "formik";
import { RBMultipleSelectForForm } from "../../../components/RBTextField";
import { Spacing, Colors } from "../../../theme/Theme";
import { skillTextStyle } from "../../../theme/ComponentTheme";
import {
  MenuItem,
  ListItemText,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { Claims } from "../../../util/Common";
import { RBDropFile } from "../../../components/RBDropFile";
import { RBButton } from "../../../components/RBButton";
import Popup from "reactjs-popup";
import SignaturePad  from "../../../components/SignaturePad"
import moment from "moment";

export interface SkillsValueTypes {
  selectedSoftware: string[];
  selectedSpecializations: string[];
  selectedLanguages: string[];
  resume: File | undefined;
  signature: string | undefined;
}

interface SkillsProps {
  innerRef?: any;
  onFormChange: any;
  previousFormData?: any;
  initialValues: SkillsValueTypes;
  workerType?: any;
  account?:any;
}
export const Skills: React.FC<SkillsProps> = ({ ...props }) => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [softwares, setSoftwares] = useState<any[]>([]);
  const [specializations, setSpecializations] = useState<any[]>([]);
  const [languages, setLanguages] = useState<any[]>([]);
 // const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [isSignatureVisible, setIsSignatureVisible] = useState(false);
  const [savedSignatureDataURL, setSavedSignatureDataURL] = useState('');
  const [viewSign, setViewSign] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsPageLoading(true);
      // const fetchSkillsData = await PharmacyService.fetchSkills("pharmacist");
      const fetchSkillsData = await PharmacyService.fetchSkills(props.workerType);
      console.log('fetchSkillsData', fetchSkillsData)
      if(props.workerType.includes('entist')){
        console.log('dental type : ', props.workerType)
        setSoftwares(
          fetchSkillsData.data.filter(
            (skill: any) => skill.type === SkillType.dentalSoftware
          )
        );
      }else{
        setSoftwares(
          fetchSkillsData.data.filter(
            (skill: any) => skill.type === SkillType.software
          )
        );
      }
      setSpecializations(
        fetchSkillsData.data.filter(
          (skill: any) => skill.type === SkillType.specialization
        )
      );
      setLanguages(
        fetchSkillsData.data.filter(
          (skill: any) => skill.type === SkillType.language
        )
      );
      setIsPageLoading(false);
    };
    fetchData();
    console.log('softwares set ', softwares);
  }, []);

  const onMultipleChange = (e: any) => {
    console.log("onMultipleChange ", e.target.value);
  };


  const handleCancel = () => {
    setIsSignatureVisible(false);
    localStorage.removeItem("workerSign")
    setSavedSignatureDataURL('');
  };
  return (
    <div>
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Typography variant="body1" style={{ marginBottom: Spacing.large }}>
            Please select all applicable software, skills and languages.
          </Typography>
          <Formik
            initialValues={props.initialValues}
            innerRef={props.innerRef}
            // validationSchema={Schema.UserLocation}
            validateOnBlur={false}
            validateOnMount={true}
            validateOnChange={true}
            isInitialValid={true}
            onSubmit={() => {}}
          >
            {({
              values,
              errors,
              isSubmitting,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form
                onSubmit={handleSubmit}
                // basically call onFormChange to check if the form is valid
                onKeyUp={props.onFormChange}
                onMouseDown={props.onFormChange}
              >
                <RBMultipleSelectForForm
                  label="Software"
                  name="selectedSoftware"
                  value={values.selectedSoftware}
                  onChange={handleChange}
                  helperText={Claims.selectSoftware}
                  renderValue={(selected) => {
                    // if ((selected as string[]).length === 0) {
                    //   return <em>Add Skills</em>;
                    // }
                    return (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {(selected as string[]).map((skillID) => {
                          return (
                            <div key={skillID} style={{ ...skillTextStyle }}>
                              {
                                softwares.filter(
                                  (skill) => skill.id === skillID
                                )[0].name
                              }
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                  style={{
                    marginBottom: Spacing.larger,
                  }}
                >
                  {softwares.map((skill) => (
                    <MenuItem key={skill.id} value={skill.id}>
                      <Checkbox
                        checked={values.selectedSoftware.indexOf(skill.id) > -1}
                        style={{
                          color:
                            values.selectedSoftware.indexOf(skill.id) > -1
                              ? localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue
                              : Colors.darkGrey,
                        }}
                      />
                      <ListItemText primary={skill.name} />
                    </MenuItem>
                  ))}
                </RBMultipleSelectForForm>

                <RBMultipleSelectForForm
                  label="Skills"
                  name="selectedSpecializations"
                  value={values.selectedSpecializations}
                  onChange={handleChange}
                  renderValue={(selected) => {
                    // if ((selected as string[]).length === 0) {
                    //   return <em>Add Skills</em>;
                    // }
                    return (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {(selected as string[]).map((skillID) => {
                          return (
                            <div key={skillID} style={{ ...skillTextStyle }}>
                              {
                                specializations.filter(
                                  (skill) => skill.id === skillID
                                )[0].name
                              }
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                  style={{
                    marginBottom: Spacing.larger,
                  }}
                >
                  {specializations.map((skill) => (
                    <MenuItem key={skill.id} value={skill.id}>
                      <Checkbox
                        checked={
                          values.selectedSpecializations.indexOf(skill.id) > -1
                        }
                        style={{
                          color:
                            values.selectedSpecializations.indexOf(skill.id) >
                            -1
                              ? localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue
                              : Colors.darkGrey,
                        }}
                      />
                      <ListItemText primary={skill.name} />
                    </MenuItem>
                  ))}
                </RBMultipleSelectForForm>

                <RBMultipleSelectForForm
                  label="Language"
                  name="selectedLanguages"
                  value={values.selectedLanguages}
                  onChange={handleChange}
                  renderValue={(selected) => {
                    // if ((selected as string[]).length === 0) {
                    //   return <em>Add Skills</em>;
                    // }
                    return (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {(selected as string[]).map((skillID) => {
                          return (
                            <div key={skillID} style={{ ...skillTextStyle }}>
                              {
                                languages.filter(
                                  (skill) => skill.id === skillID
                                )[0].name
                              }
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                  style={{
                    marginBottom: Spacing.larger,
                  }}
                >
                  {languages.map((skill) => (
                    <MenuItem key={skill.id} value={skill.id}>
                      <Checkbox
                        checked={
                          values.selectedLanguages.indexOf(skill.id) > -1
                        }
                        style={{
                          color:
                            values.selectedLanguages.indexOf(skill.id) > -1
                              ? localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue
                              : Colors.darkGrey,
                        }}
                      />
                      <ListItemText primary={skill.name} />
                    </MenuItem>
                  ))}
                </RBMultipleSelectForForm>
                <Typography  variant="body1" style={{ marginBottom: Spacing.large }}>
                  <h3>Resume and Registering Body Documents (PDF Only)</h3>
                </Typography>
                <Typography  variant="body1" style={{ marginBottom: Spacing.large }}>
                *If you are unable to upload your resume right now, you may send it to us by replying to the registration confirmation email you will receive. 
                </Typography>
                <RBDropFile
                  dropzoneText={
                    "Drag and drop PDF file here"
                  }
                  acceptedFiles={[".pdf"]}
                  initialFiles={values.resume ? [values.resume] : []}
                  // onChange
                  showPreviews={true}
                  showFileNamesInPreview={true}
                  showPreviewsInDropzone={false}
                  onChange={(file) => {
                    console.log("on change ", file);
                    if (file.length === 1) {
                      setFieldValue("resume", file[0], true);
                    }
                  }}
                  onDelete={(file) => {
                    setFieldValue("resume", undefined, true);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "flex-end",
                  }}
                >
                  <Typography  variant="body1" style={{ marginBottom: Spacing.medium }}>
                    <h3>Signature (Optional)</h3> 
                  </Typography>
                  {savedSignatureDataURL && 
                  <Typography  variant="body1" style={{ marginBottom: Spacing.medium, paddingLeft:"10%" }}>
                    <h5><a onClick={() => setViewSign(!viewSign)} style={{color:Colors.themeDarkBlue}}>View</a></h5> 
                  </Typography>}
                </div>
                {savedSignatureDataURL && viewSign && (
                    <div style={{ maxWidth: '100%', width: '100%', overflow: 'hidden',border: '1px solid black' }}>
                      <img src={savedSignatureDataURL} alt="Saved Signature" style={{ width: '100%', height: 'auto' }}/>
                    </div>
                  )}
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "flex-end",
                  }}
                >
                  {!isSignatureVisible && <RBButton
                    key={"back"}
                    onClick={() => {
                      if(savedSignatureDataURL == '')
                      {
                        setIsSignatureVisible(true);
                      }
                    }}
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: Spacing.medium,
                      margin: Spacing.large,
                      background:localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue
                    }}
                    text="Create e-signature"
                  />}
                  {localStorage.getItem("workerSign") && <RBButton
                    key={"back"}
                    onClick={() => {
                      setIsSignatureVisible(false);
                      setSavedSignatureDataURL('');
                      localStorage.removeItem("workerSign")
                      //console.log("savedSignatureDataURL",savedSignatureDataURL)
                    }}
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: Spacing.medium,
                      margin: Spacing.large,
                      background:localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue
                    }}
                    text="Clear"
                  />}
                </div>
                  <SignaturePad
                    isVisible={isSignatureVisible}
                    onLoad = {(file,dataURL) =>{
                      setSavedSignatureDataURL(dataURL);
                      setFieldValue("signature", file, true);
                    }}
                    onSave={(file,dataURL) =>{
                     // handleSave
                      setSavedSignatureDataURL(dataURL);
                      setIsSignatureVisible(false);
                      setViewSign(false)
                      setFieldValue("signature", file, true);
                    }}
                    onCancel={handleCancel}
                    onClear={() => {
                      setSavedSignatureDataURL('');
                      localStorage.removeItem("workerSign")
                    }}
                    userData = {props.previousFormData}
                    page="workerRegistration"
                  />
          
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};
