import {
    withStyles,
    Switch,
    SwitchProps,
    FormControlLabel,
    FormControlLabelProps,
    Typography,
  } from "@material-ui/core";
  import { Colors, Spacing } from "../theme/Theme";
  import React, { useContext } from "react";
  import { CSSProperties } from "@material-ui/core/styles/withStyles";
  import { RBContext } from "./RBContext";
  
  interface RBSwitchProps extends SwitchProps {
    label?: React.ReactNode;
    labelPlacement?: string;
    formControlStyle?: CSSProperties;
  }
  
  
  export const RBSwitchOutside: React.FC<RBSwitchProps> = ({
    label,
    labelPlacement,
    formControlStyle,
    ...props
  }) => {
    const { checked, onChange, name, ...rest } = props;
    const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  
    const PureRBSwitch = withStyles({
      switchBase: {
        color: Colors.defaultGrey,
        "&$checked": {
          color: primaryColor,
        },
        "&$checked + $track": {
          backgroundColor: secondaryColor,
        },
      },
      checked: {},
      track: {},
    })(Switch);
  
    return (
      <>
          <Typography>{label}</Typography>
          <Typography></Typography>
          <Typography>
              <div style={{ flex: 1, alignItems: "flex-start" }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >

                  <div
                    style={{
                      marginLeft: Spacing.smallest,
                    }}
                  >
                <PureRBSwitch
                  checked={checked}
                  onChange={onChange}
                  name={name}
                  {...rest}
                />
                  </div>
                </div>

              </div>
          </Typography>
        </>
    );
  };
  