import React, { useState,useContext } from "react";
import Rating, { RatingProps } from "@material-ui/lab/Rating";
import { Colors } from "../theme/Theme";
import { withStyles } from "@material-ui/core";
import { RBContext } from "./RBContext";
import { IndustryModeEnum } from "../api/constants";

interface StarRatingsProps {
  rating: number;
  numberReviews: number;
  readOnly?: boolean;
  page?:string;
}

const StyledRating = withStyles({
  iconFilled: {
    color: '#f1c40f',
  },
  iconHover: {
    color: Colors.themeDarkBlue,
  },
})(Rating);

export const StarRatings: React.FC<StarRatingsProps> = ({ ...props }) => {
  const { rating, readOnly = true, page } = props;
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  if (industryMode === IndustryModeEnum.Dentistry) {
    return null;
  }
  const text =
  rating === 0
        ? 'Not rated yet'
        : '(' + rating + ' reviews)';
    const showText = (rating == 0) ? true : false;

  return (
    <div style={{ flexDirection: 'row', alignItems: 'center' }}>
      <StyledRating
        value={rating}
        size={"medium"}
        precision={0.1}
        readOnly={readOnly}
      />
      {showText && (
        <div
          style={{
            textAlign: text == "Not rated yet" ? (page === "jobDetails" || page === "shiftDetails"? "left" :"center") : "left"
          }}
        >
          {text}
        </div>)}
    </div>
  );
};

interface EditableStarRatings extends RatingProps {
  defaultRating?: number;
  onValueChange: any;
}
export const EditableStarRatings: React.FC<EditableStarRatings> = ({
  ...props
}) => {
  const { defaultRating, onValueChange, ...rest } = props;
  const [rating, setRating] = useState(defaultRating ?? 0);

  return (
    <StyledRating
      value={rating}
      size={"medium"}
      onChange={(e, value) => {
        onValueChange(value ?? 0);
        setRating(value ?? 0);
      }}
      precision={1}
      readOnly={false}
      {...rest}
    />
  );
};
