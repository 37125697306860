import React from "react";
import { Formik, Form } from "formik";
import {
  RBTextFieldForForm,
  RBSelectForForm,
} from "../../../components/RBTextField";
import { Spacing } from "../../../theme/Theme";
import { Schema } from "../YupSchema";
import { RBSwitch } from "../../../components/RBSwitch";
import { RBMonthYearPicker } from "../../../components/RBMonthYearPicker";
import { SchoolYear } from "../../../util/Common";

export interface StudentInformationValueTypes {
  currentSchoolYear: string;
  schoolName: string;
  pharmacyAssistantExperienceMonths: number;
  registrationNumber: string;
}

interface StudentInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: StudentInformationValueTypes;
  account?:any;
}

export const StudentInformation: React.FC<StudentInformationProps> = ({
  ...props
}) => {
  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={Schema.StudentInformation}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={Schema.StudentInformation.isValidSync(
        props.initialValues
      )}
      onSubmit={() => {}}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <RBTextFieldForForm
            label="Institution Name"
            placeholder="Required"
            name="schoolName"
            value={values.schoolName}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            label="Registration Number"
            placeholder="Required"
            name="registrationNumber"
            value={values.registrationNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBSelectForForm
            label={"Current School Year"}
            name={"currentSchoolYear"}
            value={values.currentSchoolYear}
            mappingValues={SchoolYear}
          />

          <RBMonthYearPicker
            numberOfMonths={values.pharmacyAssistantExperienceMonths}
            onValueChange={(value: number) =>
              setFieldValue("pharmacyAssistantExperienceMonths", value, true)
            }
          />
        </Form>
      )}
    </Formik>
  );
};
