import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  RBTextFieldForForm,
  RBSelectForForm,
  RBTextFieldForFormFieldType,
} from "../../../components/RBTextField";
import { Spacing } from "../../../theme/Theme";
import { RBPlaceSearchForForm } from "../../../components/RBPlaceSearch";
import { CanadaProvinces, PharmacyRxCounts } from "../../../util/Common";
import { Schema } from "../YupSchema";
import { SkillResponseDto } from "../../../dtos/Skill.dto";
import { PharmacyService } from "../../../api/PharmacyService";
import { SkillType } from "../../../util/Enumeration";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { MenuItem } from "@material-ui/core";
import { LabelText } from "../../../util/TextConstant";

export interface PharmacyInformationValueTypes {
  pharmacyName: string;
  pharmacyStoreNumber: string;
  street: string;
  city: string;
  postalCode: string;
  province: string;
  pharmacyPhoneNumber: string;
  pharmacyFaxNumber: string;
  pharmacyAccreditationNumber: string;
  pharmacyAccreditationProvince: string;
  pharmacyRxCount: string;
  pharmacySoftware: string;
  apartmentSuiteNumber?: string;
}

interface PharmacyInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: PharmacyInformationValueTypes;
  ownerType?: string;
  account?: any;
}

export const PharmacyInformation: React.FC<PharmacyInformationProps> = ({
  ...props
}) => {
  const [softwares, setSoftwares] = useState<SkillResponseDto[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchSkillsData = await PharmacyService.fetchSkills('Pharmacy');
        setSoftwares(
          fetchSkillsData.data.filter(
            (skill: ObjectLiteral) => skill.type === SkillType.software
          )
        );
      } catch (err) {}
    };
    fetchData();
  }, []);
  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={Schema.PharmacyInformation}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={Schema.PharmacyInformation.isValidSync(
        props.initialValues
      )}
      onSubmit={() => {
        console.log("sdasdas");
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <RBTextFieldForForm
            label="Pharmacy Name"
            name="pharmacyName"
            value={values.pharmacyName}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Store Number"
            name="pharmacyStoreNumber"
            value={values.pharmacyStoreNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <div
            style={{
              marginBottom: Spacing.large,
            }}
          >
            <RBPlaceSearchForForm
              label="Street Address"
              name="street"
              value={values.street}
              setFieldValue={(addressObject: any) => {
                setFieldValue("street", addressObject.street, true);
                setFieldValue("city", addressObject.city, true);
                setFieldValue("postalCode", addressObject.postalCode, true);
                setFieldValue("province", addressObject.province, true);
              }}
              onBlur={handleBlur}
            />
          </div>

          <RBTextFieldForForm
            label="Apartment/Suite #"
            placeholder="Optional"
            name="apartmentSuiteNumber"
            value={values.apartmentSuiteNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="City"
            name="city"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Postal Code"
            name="postalCode"
            FieldType={RBTextFieldForFormFieldType.postalCode}
            value={values.postalCode}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label="Province"
            name="province"
            value={values.province}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={"Province"}
            mappingValues={CanadaProvinces.map(
              (province: any) => province.abbr
            )}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Phone Number"
            name="pharmacyPhoneNumber"
            FieldType={RBTextFieldForFormFieldType.phone}
            value={values.pharmacyPhoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            label="Fax Number"
            name="pharmacyFaxNumber"
            FieldType={RBTextFieldForFormFieldType.phone}
            value={values.pharmacyFaxNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            label="Accreditation Number"
            name="pharmacyAccreditationNumber"
            value={values.pharmacyAccreditationNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label="Accreditation Province"
            name="pharmacyAccreditationProvince"
            value={values.pharmacyAccreditationProvince}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={"Accreditation Province1"}
            mappingValues={CanadaProvinces.map(
              (province: any) => province.abbr
            )}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label={LabelText.DailyAvgRxCount}
            name="pharmacyRxCount"
            value={values.pharmacyRxCount}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={"Accreditation Province2"}
            mappingValues={PharmacyRxCounts}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label="Pharmacy Software"
            name="pharmacySoftware"
            value={values.pharmacySoftware}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={"Pharmacy Software"}
            style={{
              marginBottom: Spacing.large,
            }}
          >
            {softwares.map((skill) => (
              <MenuItem key={skill.id} value={skill.id}>
                {/* <ListItemText primary={skill.name} /> */}
                <div>{skill.name}</div>
              </MenuItem>
            ))}
          </RBSelectForForm>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre>
          <pre>{JSON.stringify(errors, null, 2)}</pre> */}
        </Form>
      )}
    </Formik>
  );
};
