import React, { useEffect, useState, useContext } from 'react';
import { formatWorkerType } from "../../util/FormattingService";
import { Typography, IconButton, Select, MenuItem, } from "@material-ui/core";
import { Colors, Spacing, AppContainerWidth } from "../../theme/Theme";
import { RBCardView } from "../../components/RBCardView";
import { RBButton } from "../../components/RBButton";
import { RoutePathConstant } from "../../util/Common";
import { workerTypeEnum } from "../../util/Enumeration";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AuthService } from "../../api/AuthService";
import { IndustryModeEnum } from '../../api/constants';
import { RBContext } from "../../components/RBContext";
import {
    useHistory,
    useLocation,
  } from "react-router-dom";

export const RegisterationIncompletePage = ({ ...props}) => {
    console.log(props)
    const history = useHistory();
    const location = useLocation<{
        userData: any;
      }>();
      const { primaryColor,secondaryColor, industryMode,contactEmail,contactPhone ,updateprimaryColor,updateSecondaryColor,updateContactEmail,updateContactPhone,updateMode } = useContext(RBContext);
      const [loading, setIsloading] = useState(false);
      
    useEffect(() => {
        (async () => {
            await updateprimaryColor( localStorage.getItem('primaryColor')); 
            await updateSecondaryColor( localStorage.getItem('secondaryColor')); 
            await updateContactEmail( localStorage.getItem('contactEmail')); 
            await updateContactPhone( localStorage.getItem('contactPhone')); 
            await updateMode(localStorage.getItem('industryMode')); 
        })(); 
        loadForm()
    }, []);
    const loadForm = async () => {
        const userData = await AuthService.loadAccountInfo();
        if (userData.roleName === 'owner') {
            history.push(RoutePathConstant.RegisterOwnAndPharmacy, {
                background: location,
                ownerType: userData.roleOpted,
                industryMode: userData?.healthcareProfession,
                account:userData
            })
        }
        else {
            history.push(
                `${RoutePathConstant.RegisterWorker}${userData.roleOpted}`,
                {
                    background: location,
                    workerType: userData.roleOpted,
                    industryMode: userData?.healthcareProfession,
                    account:userData
                }
            )
        }
    }
    return null

    // return (
    //     <RBCardView
    //     style={{
    //         padding: Spacing.large,

    //         marginTop: "10%",
    //         width: AppContainerWidth,

    //         display: "flex",
    //         flex: 1,
    //         height: "100%",
    //         maxWidth: "100%",
    //         flexDirection: "column",
    //         alignItems: "flex-start",
    //     }}
    // >
    //     <div
    //         style={{
    //             width: "100%",
    //             paddingBottom: 10
    //         }}
    //     > 
    //     </div>
    //         <Typography
    //             variant={"h6"}
    //             style={{ textAlign: "center" }}
    //         >
    //            Complete the Regitration process.
    //         </Typography>
           
    //     <Typography>
    //         <div
    //             style={{
    //                 display: "flex",
    //                 marginTop: 2 * Spacing.larger,
    //                 marginBottom: Spacing.larger,
    //                 paddingLeft: 8 * Spacing.larger,
    //                 paddingRight: 8 * Spacing.large,
    //             }}
    //         >
    //             <RBButton
    //                 style={{
    //                     width: 594,
    //                     paddingLeft: Spacing.large,
    //                     paddingRight: Spacing.large,
    //                     paddingTop: Spacing.medium,
    //                     paddingBottom: Spacing.medium,
    //                 }}
    //                 buttonBackgroundColor={primaryColor}
    //                 text="Logout"
    //                 isLoading={isSubmitting}
    //                 variant="contained"
    //                 type="submit"
    //                 onClick={() => {
    //                     AuthService.removeAuth();
    //                     history.push(RoutePathConstant.SignIn);
    //                 }}
    //             />
    //         </div>
    //     </Typography>

    // </RBCardView>
    // );
};
