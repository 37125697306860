import React, { useState,useContext,useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { ShiftProps } from "../interfacesProps/ShiftProps";
import { ShiftService } from "../api/ShiftService";
import { ShiftRelatedConstants, ShiftRelatedStylingConstants } from "../util/ShiftConstants";
import { FontSize, FontWeight, Spacing } from "../theme/Theme";
import moment from "moment-timezone";
import { capitalize, ButtonBase } from "@material-ui/core";
import {
  defaultKmAllowanceIcon,
  defaultAccomodationAllowanceIcon,
} from "../util/Icons";
import { RBShiftLayoutButton } from "./RBShiftLayoutButton";
import { formatWorkerType } from "../util/FormattingService";
import { IndustryModeEnum, roleNameEnum } from "../util/Common";
import { UIRelatedConstants } from "../theme/Theme";
import { RBContext } from './RBContext';
import { AuthService } from "../api/AuthService";
import { ObjectLiteral } from "../interfacesProps/ObjectLiteralProps";
import { defaultAllowOffersIcon } from '../components/RBShiftRelatedIcons';
const useStyles = makeStyles({
  Card: {
    "&:hover": {
      backgroundColor: "#E5E5E5",
      cursor: "pointer",
    },
  },
  CardContent: {
    paddingTop: 8,
    "&:last-child": {
      paddingBottom: 8,
    },
  },
});
interface RBShiftLayoutCardProps {
  shift: ShiftProps;
  rolename: string;
  onClick: (shiftId: string) => void;
  account:ObjectLiteral
}

const renderStateHeaderForWorker = (shift: ShiftProps,primaryColor:string,secondaryColor:string,rolename:string) => {
  const state = ShiftService.getStatusForShiftAsWorker(shift,rolename);
  let shiftStateStatus = state;
    console.log("shiftStateStatus",shiftStateStatus)
    if ((shift.pharmacistType.includes('entist') && shift?.workerAutoAcknowledged && shift?.workerAutoAcknowledged === true)) {
      if(shift.flags.isAssigned && shift.flags.isCompleted ){
        
        if(!shift.flags.isCompletionAcknowledged){
          if (shift.flags.isDisputed) {
            shiftStateStatus =  ShiftRelatedConstants.ShiftStateText.InDispute;
          }
          shiftStateStatus =ShiftRelatedConstants.ShiftStateText.AwaitingHoursConfirmation;
        }
        if(shift.flags.isCompletionAcknowledged){
          shiftStateStatus = ShiftRelatedConstants.ShiftStateText.ShiftCompleted
        }
      }
    }
    
  let stateColor =
    ShiftRelatedStylingConstants.Colors[
      ShiftService.getStatusForShiftAsWorker(shift,rolename)
    ];
  if(stateColor === '#2B3270')
  {
    stateColor = primaryColor;
  }
  else if(stateColor === '#6EC9CD'){
    stateColor = secondaryColor;
  }
  return (
    <div
      style={{
        fontSize: FontSize.large,
        fontWeight: "bold",
        color: stateColor,
      }}
    >
      {shiftStateStatus}
    </div>
  );
};
const renderJobStateHeaderForWorker = (shift: ShiftProps,primaryColor:string,secondaryColor:string) => {
  debugger
  const state = ShiftService.getStatusForJobAsWorker(shift);
  let stateColor =
    ShiftRelatedStylingConstants.Colors[
      ShiftService.getStatusForJobAsWorker(shift)
    ];
  if(stateColor === '#2B3270')
  {
    stateColor = primaryColor;
  }
  else if(stateColor === '#6EC9CD'){
    stateColor = secondaryColor;
  }
  return (
    <div
      style={{
        fontSize: FontSize.large,
        fontWeight: "bold",
        color: stateColor,
      }}
    >
      {state}
    </div>
  );
};
const renderStateHeaderForPharmacy = (shift: ShiftProps,primaryColor:string,secondaryColor:string,rolename:string) => {
  const state = ShiftService.getStatusForShiftAsPharmacy(shift,rolename);
  let stateText = state;

  if(shift.pharmacistType.includes('entist') && shift?.locationAutoAcknowledged && shift?.locationAutoAcknowledged === true){
    if(shift.flags.isCompletionAcknowledged){
      stateText = ShiftRelatedConstants.ShiftStateText.ShiftCompleted
    }

  }

  let stateColor =
    ShiftRelatedStylingConstants.Colors[
      ShiftService.getStatusForShiftAsPharmacy(shift,rolename)
    ];

    if(stateColor === '#2B3270')
    {
      stateColor = primaryColor;
    }
    else if(stateColor === '#6EC9CD'){
      stateColor = secondaryColor;
    }

  
  return (
    <div
      style={{
        fontSize: FontSize.large,
        fontWeight: "bold",
        color: stateColor,
      }}
    >
      {stateText}
    </div>
  );
};

const renderAllowance = (shift: ShiftProps,primaryColor:string) => {
  const kmAllowanceAvailable = shift.kmAllowance > 0;
  const accomodationAllowanceAvailable = shift.accomodationAllowance > 0;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        // marginTop: spacing.small,
      }}
    >
      {kmAllowanceAvailable && (
        <div style={{ marginRight: 4 }}>{defaultKmAllowanceIcon(primaryColor)}</div>
      )}
      {accomodationAllowanceAvailable && (
        <div>{defaultAccomodationAllowanceIcon(primaryColor)}</div>
      )}
    </div>
  );
};
const renderContentForWorker = (
  shift: ShiftProps,
  setCardActionAreaClickable: (actionAreaClickable: boolean) => void,
  rolename: string,
  primaryColor:string,
  account:any
) => {
  const startTime = moment(shift.officialStartTime).tz(shift.shiftTimeZone);
  const endTime = moment(shift.officialEndTime).tz(shift.shiftTimeZone);
  const hoursDisplay = ShiftService.getShiftOfficialLengthDisplay(shift);
  const wage = shift.actualCommission ?  shift.wage:ShiftService.getWage(shift,account);


  //example: Wed, Nov 6th 2019
  const dateDisplay = startTime.format("ddd, MMM Do YYYY");
  const shiftCity = shift.shiftCity ?? "N/A";
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
        }}
      >
        <div style={{ flex: 3 }}>
          <div style={{ fontWeight: "bold" }}>
            {capitalize(startTime.fromNow())}
          </div>
          <div>{dateDisplay}</div>
          <div>
            {startTime.format("hh:mm a")} - {endTime.format("hh:mm a")}
          </div>
        </div>
        <div
          style={{
            flex: 2,
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontWeight: "bold", textAlign: "right" }}>
            ${(wage / 100).toFixed(2)}/hr
          </div>

          {renderAllowance(shift,primaryColor)}
          <div style={{ fontWeight: "bold", textAlign: "right" }}>
            {hoursDisplay}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: 10,
          justifyContent: "space-between",
          minHeight: 45,
        }}
      >
        <div style={{ justifyContent: "center" }}>
          <div>
            <label style={{ fontWeight: "bold" }}>
              In <label style={{ fontWeight: "normal" }}> {shiftCity}</label>
            </label>
          </div>
        </div>

        <div
          style={{ alignItems: "flex-end" }}
          onMouseEnter={() => {
            setCardActionAreaClickable(false);
          }}
          onMouseLeave={() => {
            setCardActionAreaClickable(true);
          }}
        >
          <RBShiftLayoutButton shift={shift} rolename={rolename} />
        </div>
      </div>
    </div>
  );
};

const renderJobContentForWorker = (
  shift: ShiftProps,
  setCardActionAreaClickable: (actionAreaClickable: boolean) => void,
  rolename: string,
  primaryColor:string,
  account:any
) => {
  debugger
  const startDate = shift.estStartDate;
  const endDate = shift.expireAt;
  // const hoursDisplay = ShiftService.getShiftLengthDisplayForOriginal(shift);
  const wage = shift.estHourlyRate;
  const jobCategory = shift.jobCategory.name;
  const pharmacistType = shift.pharmacistType;


  //example: Wed, Nov 6th 2019
  const dateDisplay = startDate;
  const jobCity = shift.jobCity ?? "N/A";
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
        }}
      >
        <div style={{ flex: 3 }}>
          <div>
            Est. Start Date
          </div>
          <div>{dateDisplay}</div>
          <div>
            In <label style={{ fontWeight: "normal" }}> {jobCity}</label>
          </div>
        </div>
        <div
          style={{
            flex: 2,
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <div style={{textAlign: "right" }}>
            {jobCategory}
          </div>
          <div style={{textAlign: "right" }}>
            ${(wage / 100).toFixed(2)}/hr
          </div>
          <div style={{textAlign: "right" }}>
            {capitalize(pharmacistType)}
          </div>

          {renderAllowance(shift,primaryColor)}
          {/* <div style={{ fontWeight: "bold", textAlign: "right" }}>
            {hoursDisplay}
          </div> */}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: 10,
          justifyContent: "space-between",
          minHeight: 45,
        }}
      >
        {/* <div style={{ justifyContent: "center" }}>
          <div>
            <label style={{ fontWeight: "bold" }}>
            </label>
          </div>
        </div> */}

        <div
          style={{ alignItems: "flex-end" }}
          onMouseEnter={() => {
            setCardActionAreaClickable(false);
          }}
          onMouseLeave={() => {
            setCardActionAreaClickable(true);
          }}
        >
          <RBShiftLayoutButton shift={shift} rolename={rolename} />
        </div>
      </div>
    </div>
  );
};

const renderContentForPharmacy = (
  shift: ShiftProps,
  setCardActionAreaClickable: (actionAreaClickable: boolean) => void,
  rolename: string,
  account:any,
  industryMode:string,
  secondaryColor:string
) => {
  const startTime = moment(shift.officialStartTime).tz(shift.shiftTimeZone);
  const endTime = moment(shift.officialEndTime).tz(shift.shiftTimeZone);
  const hoursDisplay = ShiftService.getShiftOfficialLengthDisplay(shift);
  //const wage = ShiftService.getWage(shift,account);
  const wage = industryMode === IndustryModeEnum.Dentistry ?(ShiftService.getWage(shift,account)).toFixed(2) : (shift.actualCommission? shift.wage  : (ShiftService.getWage(shift,account)).toFixed(2));


  const workerType = shift.pharmacistType;
  //example: Wed, Nov 6th 2019
  const dateDisplay = startTime.format("ddd, MMM Do YYYY");
  const pharmacyName = shift.pharmacy.name ?? "N/A";


  const { isAssigned } = shift.flags;
    const { allowOffers } = shift;

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
        <div style={{ flex: 3 }}>
          <div style={{ fontWeight: "bold" }}>
            {capitalize(startTime.fromNow())}
          </div>
          <div>{dateDisplay}</div>
          <div>
            {startTime.format("hh:mm a")} - {endTime.format("hh:mm a")}
          </div>
        </div>
        <div
          style={{
            flex: 2,
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {/* <div style={{ fontWeight: "bold", textAlign: "right",display:"flex",flexDirection:"row",alignItems: 'flex-end'}}>
            ${(wage / 100).toFixed(2)}/hr
            { (allowOffers && !isAssigned) && 
              <div>
                {defaultAllowOffersIcon(secondaryColor)}
              </div>
            }
          </div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              // marginTop: spacing.small,
            }}
          >
            <div style={{ marginRight: 4,fontWeight: "bold" }}>${(wage / 100).toFixed(2)}/hr</div>
            { (allowOffers && !isAssigned) && 
              <div style={{paddingTop:2}}>
                 {defaultAllowOffersIcon(secondaryColor)}
              </div>
            }
          </div>


          <div style={{ fontWeight: "bold", textAlign: "right" }}>
            {formatWorkerType(workerType)}
          </div>
          <div style={{ fontWeight: "bold", textAlign: "right" }}>
            {hoursDisplay}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: 10,
          justifyContent: "space-between",
          minHeight: 45,
        }}
      >
        <div style={{ justifyContent: "center" }}>
          <div>
            <label style={{ fontWeight: "bold" }}>
              At <label style={{ fontWeight: "normal" }}> {pharmacyName}</label>
            </label>
          </div>
        </div>

        <div
          style={{ alignItems: "flex-end" }}
          onMouseEnter={() => {
            setCardActionAreaClickable(false);
          }}
          onMouseLeave={() => {
            setCardActionAreaClickable(true);
          }}
        >
          <RBShiftLayoutButton shift={shift} rolename={rolename} />
        </div>
      </div>
    </div>
  );
};
const RenderShiftLayoutForWorker = (
  shift: ShiftProps,
  onClick: (shiftId: string) => void,
  rolename: string,
  account:any
) => {
  const classes = useStyles();
  const [CardActionAreaClickable, SetCardActionAreaClickable] = useState(true);
  const state = ShiftService.getStatusForShiftAsWorker(shift,rolename);
  const [stateColor,setStateColor] = useState<any>( ShiftRelatedStylingConstants.Colors[
    ShiftService.getStatusForShiftAsWorker(shift,rolename)
  ])
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);

    useEffect(() => {
      const stateGlobalColor = ShiftRelatedStylingConstants.Colors[
        ShiftService.getStatusForShiftAsWorker(shift,rolename)
      ];
      if(stateGlobalColor === '#2B3270')
      {
        setStateColor(primaryColor)
      }
      else if(stateGlobalColor === '#6EC9CD'){
        setStateColor(secondaryColor)
      }
    }, []);
  return (
    <Card
      variant="outlined"
      className={classes.Card}
      style={{
        borderRadius: UIRelatedConstants.CardBorderRadius,
        borderWidth: 3,
        borderColor: stateColor,
      }}
    >
      <div
        onClick={() => {
          if (CardActionAreaClickable) {
            onClick(shift.id);
          } else {
            console.log("CardActionAreaClickable is false");
          }
        }}
      >
        <CardContent className={classes.CardContent}>
          {renderStateHeaderForWorker(shift,primaryColor,secondaryColor,rolename)}
          {renderContentForWorker(shift, SetCardActionAreaClickable, rolename,primaryColor,account)}
        </CardContent>
      </div>
    </Card>
  );
};
const RenderJobLayoutForWorker = (
  shift: ShiftProps,
  onClick: (shiftId: string) => void,
  rolename: string,
  account:any
) => {
  const classes = useStyles();
  const [CardActionAreaClickable, SetCardActionAreaClickable] = useState(true);
  const state = ShiftService.getStatusForJobAsWorker(shift);
  const [stateColor,setStateColor] = useState<any>( ShiftRelatedStylingConstants.Colors[
    ShiftService.getStatusForJobAsWorker(shift)
  ])
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);

    useEffect(() => {
      const stateGlobalColor = ShiftRelatedStylingConstants.Colors[
        ShiftService.getStatusForJobAsWorker(shift)
      ];
      if(stateGlobalColor === '#2B3270')
      {
        setStateColor(primaryColor)
      }
      else if(stateGlobalColor === '#6EC9CD'){
        setStateColor(secondaryColor)
      }
    }, []);
  return (
    <Card
      variant="outlined"
      className={classes.Card}
      style={{
        borderRadius: UIRelatedConstants.CardBorderRadius,
        borderWidth: 3,
        borderColor: stateColor,
      }}
    >
      <div
        onClick={() => {
          if (CardActionAreaClickable) {
            onClick(shift.id);
          } else {
            console.log("CardActionAreaClickable is false");
          }
        }}
      >
        <CardContent className={classes.CardContent}>
          {renderJobStateHeaderForWorker(shift,primaryColor,secondaryColor)}
          {renderJobContentForWorker(shift, SetCardActionAreaClickable, rolename,primaryColor,account)}
        </CardContent>
      </div>
    </Card>
  );
};
const RenderShiftLayoutForPharmacy = (
  shift: ShiftProps,
  onClick: (shiftId: string) => void,
  rolename: string,
  account:any
) => {
  const classes = useStyles();
  const [CardActionAreaClickable, SetCardActionAreaClickable] = useState(true);

  const [stateColor,setStateColor] = useState<any>( ShiftRelatedStylingConstants.Colors[
    ShiftService.getStatusForShiftAsPharmacy(shift,rolename)
  ])
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const state = ShiftService.getStatusForShiftAsPharmacy(shift,rolename);

  useEffect(() => {
    const stateGlobalColor = ShiftRelatedStylingConstants.Colors[
      ShiftService.getStatusForShiftAsPharmacy(shift,rolename)
    ]
    if(stateGlobalColor === '#2B3270')
    {
      setStateColor(primaryColor)
    }
    else if(stateGlobalColor === '#6EC9CD'){
      setStateColor(secondaryColor)
    }
    console.log('stateColor',stateColor)
  }, []);
  
  return (
    <Card
      variant="outlined"
      className={classes.Card}
      style={{
        borderRadius: UIRelatedConstants.CardBorderRadius,
        borderWidth: 3,
        borderColor: stateColor,
      }}
    >
      <div
        onClick={() => {
          if (CardActionAreaClickable) {
            onClick(shift.id);
          } else {
            console.log("CardActionAreaClickable is false");
          }
        }}
      >
        <CardContent className={classes.CardContent}>
          {renderStateHeaderForPharmacy(shift,primaryColor,secondaryColor,rolename)}
          {renderContentForPharmacy(
            shift,
            SetCardActionAreaClickable,
            rolename,
            account,
            industryMode,
            secondaryColor
          )}
        </CardContent>
      </div>
    </Card>
  );
};

export const RBShiftLayoutCard: React.FC<RBShiftLayoutCardProps> = ({
  ...props
}) => {
  const { shift, onClick, rolename ,account} = props;
  if (rolename === roleNameEnum.pharmacist) {
    if (shift.jobNumber){
      return (
        <div
          style={{
            width: "100%",
            paddingLeft: Spacing.large,
            paddingRight: Spacing.large,
            paddingBottom: Spacing.medium,
          }}
        >
          {RenderJobLayoutForWorker(shift, onClick, rolename,account)}
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
            paddingLeft: Spacing.large,
            paddingRight: Spacing.large,
            paddingBottom: Spacing.medium,
          }}
        >
          {RenderShiftLayoutForWorker(shift, onClick, rolename,account)}
        </div>
      );
    }
  }
  if (rolename === roleNameEnum.owner) {
    return (
      <div
        style={{
          paddingLeft: Spacing.large,
          paddingRight: Spacing.large,
          paddingBottom: Spacing.medium,
        }}
      >
        {RenderShiftLayoutForPharmacy(shift, onClick, rolename,account)}
      </div>
    );
  }
  return <></>;
};
