import { useState, useRef,useContext } from "react";
import React from "react";
import { Schema } from "../YupSchema";
import { Formik, Form, ErrorMessageProps } from "formik";
import { RBButton, RBButtonType } from "../../../components/RBButton";
import { Spacing } from "../../../theme/Theme";
import { ProfileCardSection } from "./ProfileCardSection";
import { renderTextWithTitleAndChildComponent } from "../../../components/TextWithHeader";
import {
  RBTextFieldForForm,
  RBTextFieldForFormFieldType,
} from "../../../components/RBTextField";
import { EmptyString, Claims } from "../../../util/Common";
import { Typography } from "@material-ui/core";
import { AuthService } from "../../../api/AuthService";
import { RBContext } from "../../../components/RBContext";

interface SecuritySectionProps {
  email: string;
}
export const SecuritySection: React.FC<SecuritySectionProps> = ({ email }) => {
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [isEdit, setIsEdit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const formRef = useRef<any>();
  const [errorMessage, setErrorMessage] = useState(EmptyString);
  const editDetail = () => {
    return (
      <Formik
        innerRef={formRef as any}
        initialValues={{
          currentPassword: EmptyString,
          password: EmptyString,
          confirmPassword: EmptyString,
        }}
        validationSchema={Schema.SecuritySection}
        validateOnBlur={false}
        validateOnMount={true}
        validateOnChange={true}
        isInitialValid={false}
        onSubmit={() => {}}
      >
        {({ values, handleBlur, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {submitErrorMessage && (
              <div style={{ color: "red" }}>
                {errorMessage? errorMessage : submitErrorMessage}
              </div>
            )}
            {renderTextWithTitleAndChildComponent(
              "Current Password",
              <RBTextFieldForForm
                value={values.currentPassword}
                FieldType={RBTextFieldForFormFieldType.password}
                name="currentPassword"
                margin={"dense"}
                onChange={handleChange}
              />
            )}
            <div style={{ marginBottom: Spacing.large }} />
            {renderTextWithTitleAndChildComponent(
              "New Password",
              <RBTextFieldForForm
                value={values.password}
                FieldType={RBTextFieldForFormFieldType.password}
                name="password"
                margin={"dense"}
                onChange={handleChange}
              />
            )}
            <div
              style={{
                marginBottom: Spacing.large,
              }}
            >
              <Typography variant="caption">{Claims.password}</Typography>
            </div>
            {renderTextWithTitleAndChildComponent(
              "Confirm New Password",
              <RBTextFieldForForm
                value={values.confirmPassword}
                FieldType={RBTextFieldForFormFieldType.password}
                name="confirmPassword"
                margin={"dense"}
                onChange={handleChange}
              />
            )}
          </Form>
        )}
      </Formik>
    );
  };
  const renderDetail = () => {
    return <></>;
  };
  const onSaveChange = async () => {
    try {
      if (formRef.current) {
        formRef.current.handleSubmit();
        if (!formRef.current.isValid) {
          return;
        } else {
          // update
          setIsSaving(true);
          await AuthService.signIn({
            email: email,
            password: formRef.current.values.currentPassword,
          });
          const result = await AuthService.updatePassword(
            formRef.current.values.password
          );
          setIsEdit(false);
          setSubmitErrorMessage(undefined);
        }
      }
    } catch (err) {
      console.log("onSaveChange err ", err);
      const errorMessage = err.response ? err.response.data : err.message;
      console.log("errormessage",errorMessage)
      setSubmitErrorMessage(errorMessage.message);
      if(err.response && err.response.data.message == "Invalid email address or password")
      {
        setErrorMessage("Invalid password")
      }
    } finally {
      setIsSaving(false);
    }
  };
  const onCancelChange = () => {
    setIsEdit(false);
    setSubmitErrorMessage(undefined);
  };
  const renderAction = () => {
    return (
      <>
        {isEdit ? (
          <>
            <RBButton
              text="Cancel"
              buttonType={RBButtonType.cancel}
              style={{ marginRight: Spacing.medium }}
              onClick={onCancelChange}
            />
            <RBButton text="Save" onClick={onSaveChange}  buttonBackgroundColor={secondaryColor} isLoading={isSaving} />
          </>
        ) : (
          <RBButton text="Edit" buttonBackgroundColor={secondaryColor} onClick={() => setIsEdit(true)} />
        )}
      </>
    );
  };
  return (
    <ProfileCardSection
      sectionHeader="Password Reset"
      detail={isEdit ? editDetail() : renderDetail()}
      action={renderAction()}
    />
  );
};
