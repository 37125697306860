import React, { Fragment, useState, useEffect ,useContext} from "react";
import {
  withRouter,
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { JobInformation } from "./components/JobInformation";
import { PharmacyInformation } from "./components/PharmacyInformation";
import { DentalJobInfo } from './components/DentalJobInfo';
import { ShiftProps } from "../../interfacesProps/ShiftProps";
import { pageStyle, containerStyle } from "../../theme/ComponentTheme";
import { WorkerInformation } from "./components/WorkerInformation";
import { roleNameEnum, RoutePathConstant } from "../../util/Common";
import { ShiftService } from "../../api/ShiftService";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { ShiftRelatedConstants } from "../../util/ShiftConstants";
import { Colors, FontSize, Spacing } from "../../theme/Theme";
import { RBCardView } from "../../components/RBCardView";
import moment from "moment-timezone";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { RBSnackbar } from "../../components/RBSnackbar";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { AuthService } from "../../api/AuthService";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { RBContext } from '../../components/RBContext';
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { IndustryModeEnum } from "../../api/constants";
import { DentalPracticeInfo } from "./components/DentalPracticeInfo";
import { RBNetworkCancelAlert } from "../../components/RBNetworkCancelAlert";

interface JobDetailPageProps {}
export const JobDetailPage: React.FC<JobDetailPageProps> = ({
  ...props
}) => {
  // const { shiftData, roleName } = props;
  const [jobData, setJobDetail] = useState<ShiftProps>({});
  const [roleName, _] = useState<string>(AuthService.getRoleName());
  const [isAlertShowing, setJobAlertShowing] = useState(false);
  const [jobAlertInfo, setjobAlertInfo] = useState<AlertInfoProps>({});

  const [jobNetworkAlertInfo, setjobNetworkAlertInfo] = useState<AlertInfoProps>({});
  const [isNetworkAlertShowing, setJobNetworkAlertShowing] = useState(false);

  

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [jobCancelable, setjobCancelable] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<{ jobId: string }>();
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [account, setAccount] = useState<any>([]);
  const [networkAdminInfo, setNetworkAdminInfo] = useState<any>([]);
  const [jobNetworkMail,setJobNetworkMail] = useState('');
  const [jobNetworkPhn,setJobNetworkPhn] = useState('');
  const [networkJobPostFee, setNetworkJobPostFee] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorAlertInfo,setErrorAlertInfo] =  useState<ObjectLiteral>({})
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await AuthService.loadAccountInfo();
        setAccount(userData);
        setIsPageLoading(true);
        const jobDetailData = await ShiftService.getJobtDetailByJobId(
          match.params.jobId
        );

        await fetchNetworkInfo(jobDetailData.pharmacy.networkId )
        const jobNetworkData = await ShiftService.getNetworkDetails(jobDetailData.pharmacy.networkId)
        console.log('info',jobNetworkData)
        setJobNetworkMail(jobNetworkData.contactEmail)
        setJobNetworkPhn(jobNetworkData.contactPhone)
        setNetworkJobPostFee(jobNetworkData.paymentConfig.jobPostFeeDefault) 

        setJobDetail(jobDetailData);
        setIsPageLoading(false);
      } catch (err) {
        setSubmitErrorMessage(err.response ? err.response.data : err.message);
      } finally {
      }
    };

    fetchData();
  }, [match.params.jobId]);
  const fetchNetworkInfo = async (networkId:string) =>{
    const networkInfo = await ShiftService.getNetworkAdminInfo(networkId);
    setNetworkAdminInfo(networkInfo)
  }
  // when there is a need to talk to API
  const onActionRelatedToAPI = async (buttonText: string) => {
    try {
      setJobAlertShowing(false);
      setIsButtonLoading(true);

      if (roleName === roleNameEnum.owner) {
        switch (buttonText) {
          case ShiftRelatedConstants.ShiftButtonText.CancelJob: {
            await ShiftService.cancelJobById(jobData.id);
            //await new Promise((resolve) => setTimeout(resolve, 3000));
            history.push("/job/list")
            break;
          }
          case ShiftRelatedConstants.ShiftButtonText.extendJob: {
            await ShiftService.extendJob(jobData.id);
            //await new Promise((resolve) => setTimeout(resolve, 3000));
            history.push("/job/list")
            break;
          }
        }
      } else if (roleName === roleNameEnum.pharmacist) {
        switch (buttonText) {
          case ShiftRelatedConstants.ShiftButtonText.CancelJob: {
            // await new Promise((resolve) => setTimeout(resolve, 3000));
            await ShiftService.cancelJobById(jobData.id);
            history.push("/job/list")
            break;
          }
          case ShiftRelatedConstants.ShiftButtonText.WithdrawJob: {
            await ShiftService.withdrawRequestedJobById(jobData.id);
            history.push("/job/list")
            break;
          }
        }
      }
     //history.goBack();
    } catch (err) {

      if (err.response) {
        if(err.response?.data?.message && err.response?.data?.message.includes('card'))
        {

          setShowErrorModal(true);
          setErrorAlertInfo({
            title: 'An Error Occured',
            message: `${err.response.data.message}`,
            buttons: [
              <RBButton
                key={RBButtonType.cancel}
                buttonBackgroundColor= {primaryColor}
                style={{color: "white"}}
                buttonType={RBButtonType.cancel}
                onClick={() => {
                    setShowErrorModal(false);
                    history.push('/payment',{
                      tabType: 'Job',
                    });
                }}
                text="Go To Payment Settings"
              />,
            ],
          })
        }
        else{
          setShowErrorModal(true);
          setErrorAlertInfo({
            title: 'An Error Occured',
            message: `Unable to submit at this time. Please try again later.`,
            buttons: [
              <RBButton
                key={RBButtonType.cancel}
                buttonBackgroundColor= {primaryColor}
                style={{color: "white"}}
                buttonType={RBButtonType.cancel}
                onClick={() => {
                    setShowErrorModal(false);
                    history.push('/dashboard');
                }}
                text="Go Home"
              />,
            ],
          })
        }
      }


      console.log("onActionRelatedToAPI error ", err);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const doAction = (buttonText: string) => {
    const { flags } = jobData;
    if (roleName === roleNameEnum.owner) {
      switch (buttonText) {
        case ShiftRelatedConstants.ShiftButtonText.jobApplicant:
          history.push(RoutePathConstant.JobApplicant + jobData.id);
          break;
        case ShiftRelatedConstants.ShiftButtonText.jobCandidates:
            history.push(RoutePathConstant.JobCandidates + jobData.id);
            break;

        case ShiftRelatedConstants.ShiftButtonText.EditJob:
          if(industryMode === IndustryModeEnum.Pharmaceutical)
          {
            history.push(RoutePathConstant.EditJob + jobData.id);
          }
          else{
            history.push(RoutePathConstant.EditeDentalJob + jobData.id);
          }   
          break;

          case ShiftRelatedConstants.ShiftButtonText.extendJob: {
            let jobPostFee = jobData.pharmacy?.jobPostFee ? jobData.pharmacy.jobPostFee : networkJobPostFee;
            setjobAlertInfo({
              title: "Extend Job",
              message: `Are you sure you want to extend this job posting for another 30 days for $${jobPostFee} + tax?`,
              buttons: [
                <RBButton
                  key={"confirm"}
                  onClick={async () => {
                    await onActionRelatedToAPI(
                      ShiftRelatedConstants.ShiftButtonText.extendJob
                    );
                  }}
                  buttonBackgroundColor={primaryColor}
                  text="Yes"
                />,
                <RBButton
                  key={"cancel"}
                  buttonBackgroundColor={primaryColor}
                  onClick={() => {
                    setJobAlertShowing(false);
                  }}
                  text="No"
                />,
                
              ],
            });
            setJobAlertShowing(true);
            break;
          }

        case ShiftRelatedConstants.ShiftButtonText.CancelJob: {
          let message = "Are you sure you wish to cancel this job?";
          
          if (!flags.isAssigned) {
            setjobAlertInfo({
              title: "Cancel Job",
              message: message,
              buttons: [
                <RBButton
                  key={"confirm"}
                  onClick={async () => {
                    await onActionRelatedToAPI(
                      ShiftRelatedConstants.ShiftButtonText.CancelJob
                    );
                  }}
                  isLoading={isButtonLoading}
                  buttonBackgroundColor={primaryColor}
                  //color="primary"
                  text="Yes"
                />,
                <RBButton
                  key={"cancel"}
                  buttonBackgroundColor={primaryColor}
                  onClick={() => {
                    setJobAlertShowing(false);
                  }}
                  text="No"
                />,
                
              ],
            });
            setJobAlertShowing(true);
          } else {
            
            setjobNetworkAlertInfo({
              title: "Cancel Job",
              phone: jobNetworkPhn,
              email:jobNetworkMail,
              page:"job",
              buttons: [
                <RBButton
                  key={"ok"}
                  onClick={() => {
                    setJobNetworkAlertShowing(false);
                  }}
                  //color="primary"
                  text="Ok"
                  buttonBackgroundColor={secondaryColor}
                />,
              ],
            });
            setJobNetworkAlertShowing(true)
          }
          
          break;
        }
        
      }
    } else if (roleName === roleNameEnum.pharmacist) {
      switch (buttonText) {
        case ShiftRelatedConstants.ShiftButtonText.CancelJob: {
          let message = "Are you sure you wish to cancel this job?";
         
          if (!flags.isAssigned) {
            setjobAlertInfo({
              title: "Cancel Job",
              message: message,
              buttons: [
                <RBButton
                  key={"cancel"}
                  buttonType={RBButtonType.cancel}
                  onClick={() => {
                    setJobAlertShowing(false);
                  }}
                  text="No"
                />,
                <RBButton
                  key={"confirm"}
                  onClick={async () => {
                    await onActionRelatedToAPI(
                      ShiftRelatedConstants.ShiftButtonText.CancelJob
                    );
                  }}
                  buttonBackgroundColor={primaryColor}
                  // color="primary"
                  text="Yes"
                />,
              ],
            });
            setJobAlertShowing(true);
          } else {
            setjobNetworkAlertInfo({
              title: "Cancel Job",
              phone: jobNetworkPhn,
              email:jobNetworkMail,
              page:'job',
              buttons: [
                <RBButton
                  key={"ok"}
                  onClick={() => {
                    setJobNetworkAlertShowing(false);
                  }}
                  //color="primary"
                  text="Ok"
                  buttonBackgroundColor={secondaryColor}
                />,
              ],
            });
            setJobNetworkAlertShowing(true)
          }
         
          break;
        }
        case ShiftRelatedConstants.ShiftButtonText.WithdrawJob: {
          setjobAlertInfo({
            title: "Withdraw from Job",
            message: "Are you sure you wish to withdraw from this job?",
            buttons: [
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  await onActionRelatedToAPI(
                    ShiftRelatedConstants.ShiftButtonText.WithdrawJob
                  );
                }}
                buttonBackgroundColor={primaryColor}
                text="Yes"
              />,
              <RBButton
                key={"cancel"}
                buttonBackgroundColor={primaryColor}
                onClick={() => {
                  setJobAlertShowing(false);
                }}
                text="No"
              />,
              
            ],
          });
          setJobAlertShowing(true);
          break;
        }
      }
    }
  };

  const renderPharmacyJobDetailLayout = (
    jobData: ShiftProps,
    roleName: string,
    doAction: (buttonText: string) => void
  ) => {
    const owner = jobData.creator ? jobData.creator.owner : false;
    return (
      <>
        {renderJobStatus(jobData, roleName)}
      
        <RBCardView
          marginVertical={Spacing.large}
          style={{ padding: Spacing.large }}
          children={
            <>
              <WorkerInformation jobData={jobData}/>
              <JobInformation jobData={jobData} account={account}/>
            </>
          }
        />
        
        {renderActionsForOwner(jobData, doAction)}
      </>
    );
  };


  const renderDentalJobDetailLayout = (
    jobData: ShiftProps,
    roleName: string,
    doAction: (buttonText: string) => void
  ) => {

    const { pharmacy } = jobData;
    const owner = jobData.creator ? jobData.creator.owner : false;
    const ownerEmail = jobData.creator ? jobData.creator.email : false;

    return (
      <>
        {renderJobStatus(jobData, roleName)}
        <RBCardView
          marginVertical={Spacing.large}
          style={{ padding: Spacing.large }}
          children={
            <>
              <WorkerInformation jobData={jobData}/>
              <DentalJobInfo jobData={jobData} account={account} />
              <DentalPracticeInfo
                pharmacy={pharmacy}
                jobData={jobData}
                owner={owner}
                ownerEmail={ownerEmail}
                account={account}
                page="jobDetails" 
              />
            </>
          }
        />
        {renderActionsForOwner(jobData, doAction)}
      </>
    );
  };

  const renderWorkerJobDetailLayout = (
    jobData: ShiftProps,
    roleName: string,
    doAction: (buttonText: string) => void
  ) => {
    const owner = jobData.creator ? jobData.creator.owner : false;
    
    return (
      <>
        {renderJobStatus(jobData, roleName)}
        <RBCardView
          marginVertical={Spacing.large}
          style={{ padding: Spacing.large }}
          children={
            <>
              <PharmacyInformation jobData={jobData} pharmacy={jobData.pharmacy}
                owner={owner}
                account={account}
                page="jobDetails" 
                applied={true}
                />
              <JobInformation jobData={jobData} account={account}/>
            </>
          }
        />
        {renderActionsForWorker(jobData, doAction)}
      </>
    );
  };


  const renderDentalWorkerJobDetailLayout = (
    jobData: ShiftProps,
    roleName: string,
    doAction: (buttonText: string) => void
  ) => {

    const { pharmacy } = jobData;
    // when the worker applied the shift but not got confirmed yet, the worker will not be able to view any pharmacy/creator information
    const owner = jobData.creator ? jobData.creator.owner : false;
    const ownerEmail = jobData.creator ? jobData.creator.email : false;
  
    return (
      <>
        {renderJobStatus(jobData, roleName)}
        
          <RBCardView
            marginVertical={Spacing.large}
            style={{ padding: Spacing.large }}
            children={
              <>
                <DentalJobInfo
                account={account}
                jobData={jobData}
              />
              <DentalPracticeInfo
                pharmacy={pharmacy}
                jobData={jobData}
                owner={owner}
                ownerEmail={ownerEmail}
                account={account}
                page="jobDetails" 
                applied={true}
              />
              </>
            }
          />
        {renderActionsForWorker(jobData, doAction)}
      </>
    );
  };

  const renderJobStatus = (jobData: ShiftProps, roleName: string) => {
    return (
      <div
        style={{
          backgroundColor: primaryColor,
          padding: Spacing.large,
        }}
      >
        <div
          style={{
            fontSize: FontSize.large,
            fontWeight: "bold",
            color: "white",
          }}
        >
          {roleName === roleNameEnum.owner
            ? ShiftService.getStatusForJobAsPharmacy(jobData)
            : ShiftService.getStatusForJobAsWorker(jobData)}
        </div>
      </div>
    );
  };

  const renderActionsForWorker = (
    jobData: ShiftProps,
    doAction: (buttonText: string) => void
  ) => {
    const flags = jobData.flags;
    const shiftState = ShiftService.getStatusForJobAsWorker(jobData);
    const applied = true;
    const { isAssigned } = flags;
    const isPast = moment().isAfter(moment(jobData.endTime));

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* Applied but not yet get assign */}
        {!flags.isAssigned && !flags.isCancelled && (
          <RBButton
            isLoading={isButtonLoading}
            onClick={() => {
              doAction(ShiftRelatedConstants.ShiftButtonText.WithdrawJob);
            }}
            text="Withdraw Job"
            style={{ flex: 1, padding: 16 }}
            buttonBackgroundColor={primaryColor}
          />
        )}
         {flags.isAssigned && !flags.isCancelled && (
          <RBButton
            isLoading={isButtonLoading}
            onClick={() => {
              doAction(ShiftRelatedConstants.ShiftButtonText.CancelJob);
            }}
            text="Cancel Job"
            style={{ flex: 1, padding: 16 }}
            buttonBackgroundColor={primaryColor}
          />
        )}

      </div>
    );
  };

  const renderActionsForOwner = (
    jobData: ShiftProps,
    doAction: (buttonText: string) => void
  ) => {
    const flags = jobData.flags;

    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >

          {(flags.isExpiredSoon || flags.isExpired) &&
            !flags.isAssigned &&
            !flags.isCancelled && (
              <RBButton
                onClick={() => {
                  doAction(ShiftRelatedConstants.ShiftButtonText.extendJob);
                }}
                isLoading={isButtonLoading}
                text="Extend Job Posting"
                style={{
                  flex: 1,
                  padding: 16,
                  marginRight: !flags.isPast && !flags.isCancelled ? 8 : 0,
                  background: primaryColor
                }}
              ></RBButton>
            )}
        </div>
        <br></br>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {!flags.isAssigned &&
            !flags.isExpired &&
            !flags.isCancelled && (
              <RBButton
                onClick={() => {
                  doAction(ShiftRelatedConstants.ShiftButtonText.jobCandidates);
                }}
                isLoading={isButtonLoading}
                text="Find Candidates"
                style={{
                  flex: 1,
                  padding: 16,
                  marginRight: !flags.isPast && !flags.isCancelled ? 8 : 0,
                  background: secondaryColor
                }}
              ></RBButton>
            )}

        </div>
        <br></br>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {!flags.isExpired && !flags.isCancelled && (
            <RBButton
              isLoading={isButtonLoading}
              text="Cancel Job"
              onClick={() =>
                doAction(ShiftRelatedConstants.ShiftButtonText.CancelJob)
              }
              style={{
                flex: 1,
                padding: 16,
                marginRight: !flags.isPast && !flags.isCancelled ? 8 : 0,
                background: primaryColor
              }}
            />
          )}

          {!flags.isExpired && flags.isEditable && (
            <RBButton
              onClick={() => {
                doAction(ShiftRelatedConstants.ShiftButtonText.EditJob);
              }}
              isLoading={isButtonLoading}
              text="Edit Job"
              style={{
                flex: 1,
                padding: 16,
                marginRight: !flags.isPast && !flags.isCancelled ? 8 : 0,
                background: primaryColor
              }}
            ></RBButton>
          )}
          {!flags.isExpired &&
            !flags.isEditable &&
            !flags.isAssigned &&
            !flags.isCancelled && (
              <RBButton
                isLoading={isButtonLoading}
                text="Review Applicants"
                onClick={() =>
                  doAction(ShiftRelatedConstants.ShiftButtonText.jobApplicant)
                }
                style={{
                  flex: 1,
                  padding: 16,
                  marginRight: !flags.isPast && !flags.isCancelled ? 8 : 0,
                  background: primaryColor
                }}
              />
            )}

          

        </div>
      </div>
    );
  };

  return (
    <div style={{ ...pageStyle }} className="paddingHorizontal paddingVertical">
      <RBAlert
        show={isAlertShowing}
        alertTitle={jobAlertInfo.title}
        alertMessage={jobAlertInfo.message}
        buttons={jobAlertInfo.buttons}
      />

      <RBAlert
        show={showErrorModal}
        alertTitle= {errorAlertInfo.title}
        alertMessage={errorAlertInfo.message}
        buttons={errorAlertInfo.buttons}
      />

      <RBNetworkCancelAlert
        show={isNetworkAlertShowing}
        alertTitle={jobNetworkAlertInfo.title}
        phone={jobNetworkAlertInfo.phone}
        email={jobNetworkAlertInfo.email}
        page = {jobNetworkAlertInfo.page}
        buttons={jobNetworkAlertInfo.buttons}
      />



      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={JSON.stringify(submitErrorMessage, null, 2)}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      />
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <div style={{ flex: 1 }}>
          {industryMode === IndustryModeEnum.Pharmaceutical ? ( roleName === roleNameEnum.owner
            ? renderPharmacyJobDetailLayout(jobData, roleName, doAction)
            : renderWorkerJobDetailLayout(jobData, roleName, doAction))
          :(
            roleName === roleNameEnum.owner
            ? renderDentalJobDetailLayout(jobData, roleName, doAction)
            : renderDentalWorkerJobDetailLayout(jobData, roleName, doAction)
          )}
        </div>
      )}
    </div>
  );
};
