export const firstName = "";

// Phone regex from
// https://github.com/validatorjs/validator.js/blob/master/src/lib/isMobilePhone.js

const PhoneValidation = /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;
const PasswordValidation = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const yearMonthPattern = /^\d{4}-(0[1-9]|1[0-2])$/;
const yearPattern = /^\d{4}$/;
const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// https://github.com/validatorjs/validator.js/blob/master/src/lib/isPostalCode.js
const CanadaPostalCodeValidation = /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][\s\-]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

export const Regexes = {
  PhoneValidation,
  PasswordValidation,
  CanadaPostalCodeValidation,
  yearMonthPattern,
  yearPattern,
  emailPattern
};
