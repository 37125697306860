import React, { Fragment, useState, useEffect ,useContext} from "react";
import {
  withRouter,
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { ShiftInformation } from "./components/ShiftInformation";
import { PharmacyInformation } from "./components/PharmacyInformation";
import { DentalShiftInfo } from './components/DentalShiftInfo';
import { ShiftProps } from "../../interfacesProps/ShiftProps";
import { pageStyle, containerStyle, ColumnStyle } from "../../theme/ComponentTheme";
import { BillInformation } from "./components/BillInformation";
import { WorkerInformation } from "./components/WorkerInformation";
import { roleNameEnum, RoutePathConstant } from "../../util/Common";
import { ShiftService } from "../../api/ShiftService";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { ShiftRelatedConstants } from "../../util/ShiftConstants";
import { Colors, FontSize, Spacing } from "../../theme/Theme";
import { RBCardView } from "../../components/RBCardView";
import moment from "moment-timezone";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { RBSnackbar } from "../../components/RBSnackbar";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { AuthService } from "../../api/AuthService";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { RBContext } from '../../components/RBContext';
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { IndustryModeEnum } from "../../api/constants";
import { DentalPracticeInfo } from "./components/DentalPracticeInfo";
import { ShiftNetworkInformation } from "./components/ShiftNetworkInformation";
import { RBNetworkCancelAlert } from "../../components/RBNetworkCancelAlert";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { RBTextField } from "../../components/RBTextField";
import { formatWorkerType } from "../../util/FormattingService";
import { PharmacyService } from "../../api/PharmacyService";
import { RBActiveShiftCancelModal } from "../../components/RBActiveShiftCancelModal";
import { Typography } from "@material-ui/core";

interface ShiftDetailPageProps {}
export const ShiftDetailPage: React.FC<ShiftDetailPageProps> = ({
  ...props
}) => {
  // const { shiftData, roleName } = props;
  const [shiftData, setShiftDetail] = useState<ShiftProps>({});
  const [roleName, _] = useState<string>(AuthService.getRoleName());
  const [isAlertShowing, setShiftAlertShowing] = useState(false);
  const [shiftAlertInfo, setShiftAlertInfo] = useState<AlertInfoProps>({});

  const [shiftNetworkAlertInfo, setShiftNetworkAlertInfo] = useState<AlertInfoProps>({});
  const [isNetworkAlertShowing, setShiftNetworkAlertShowing] = useState(false);

  

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [shiftCancelable, setShiftCancelable] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<{ shiftId: string }>();
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [account, setAccount] = useState<any>([]);
  const [networkAdminInfo, setNetworkAdminInfo] = useState<any>([]);
  const [shiftNetworkMail,setShiftNetworkMail] = useState('');
  const [shiftNetworkPhn,setShiftNetworkPhn] = useState('');
  
  const [blockUserDialogVisible, setBlockUserDialogVisible] = useState(false);
  const [blockUserComment, setBlockUserComment] = useState('');
  const [reason, setReason] = useState('');
  const [cancelLoading, setCancelLoading] = useState(false);
  

  useEffect(() => {
    fetchData();
  }, [match.params.shiftId]);
  const fetchData = async () => {
    try {
      const userData = await AuthService.loadAccountInfo();
      setAccount(userData);
      setIsPageLoading(true);
      const shiftDetailData = await ShiftService.getShiftDetailByShiftId(
        match.params.shiftId
      );

      // let pharmaNetworkId = shiftDetailData.pharmacy ?  shiftDetailData.pharmacy.networkId : null;
      // if(pharmaNetworkId === null){
      //   const fetchedShift = await ShiftService.getShift(shiftDetailData.id);
      //   pharmaNetworkId = fetchedShift.pharmacy.networkId;
      // }

      await fetchNetworkInfo(shiftDetailData.pharmacy.networkId )
      const shiftNetworkData = await ShiftService.getNetworkDetails(shiftDetailData.pharmacy.networkId)
      console.log('info',shiftNetworkData)
      setShiftNetworkMail(shiftNetworkData.contactEmail)
      setShiftNetworkPhn(shiftNetworkData.contactPhone)



      const rbShiftPreference = await AuthService.getRBShiftPreference();
      if (typeof rbShiftPreference.shiftCancelable !== "undefined") {
        setShiftCancelable(rbShiftPreference.shiftCancelable);
        console.log(
          "ShiftDetailController rbShiftPreference ",
          rbShiftPreference
        );
      }
      setShiftDetail(shiftDetailData);
      setIsPageLoading(false);
    } catch (err) {
      setSubmitErrorMessage(err.response ? err.response.data : err.message);
    } finally {
    }
  };
  const fetchNetworkInfo = async (networkId:string) =>{
    const networkInfo = await ShiftService.getNetworkAdminInfo(networkId);
    setNetworkAdminInfo(networkInfo)
  }
  // when there is a need to talk to API
  const onActionRelatedToAPI = async (buttonText: string) => {
    try {
      setShiftAlertShowing(false);
      setIsButtonLoading(true);

      if (roleName === roleNameEnum.owner) {
        switch (buttonText) {
          case ShiftRelatedConstants.ShiftButtonText.CancelShift: {
            await ShiftService.cancelShiftById(shiftData.id);
            //await new Promise((resolve) => setTimeout(resolve, 3000));
            history.push("/shift/list")
            NotificationService.publish(
              NotificationServiceTopic.ShiftChanged,
              shiftData.id
            );
            break;
          }
        }
      } else if (roleName === roleNameEnum.pharmacist) {
        switch (buttonText) {
          case ShiftRelatedConstants.ShiftButtonText.CancelShift: {
            // await new Promise((resolve) => setTimeout(resolve, 3000));
            await ShiftService.cancelShiftById(shiftData.id);
            history.push("/shift/list")
            break;
          }
          case ShiftRelatedConstants.ShiftButtonText.WithdrawShift: {
            await ShiftService.withdrawRequestedShiftById(shiftData.id);
            history.push("/shift/list")
            break;
          }
        }
      }
     //history.goBack();
    } catch (err) {
      setSubmitErrorMessage(err.response ? err.response.data : err.message);
      console.log("onActionRelatedToAPI error ", err);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const doAction = (buttonText: string) => {
    if (roleName === roleNameEnum.owner) {
      switch (buttonText) {
        case ShiftRelatedConstants.ShiftButtonText.ViewApplications:
          history.push(RoutePathConstant.ViewApplicant + shiftData.id);
          break;
        case ShiftRelatedConstants.ShiftButtonText.RateShift:
          history.push(RoutePathConstant.ShiftSurvey + shiftData.id);
          break;
        case ShiftRelatedConstants.ShiftButtonText.ConfirmHours:
          history.push(RoutePathConstant.ShiftConfirmHour + shiftData.id);
          break;
        case ShiftRelatedConstants.ShiftButtonText.EditShift:
          if(industryMode === IndustryModeEnum.Pharmaceutical)
          {
            history.push(RoutePathConstant.EditShift + shiftData.id);
          }
          else{
            history.push(RoutePathConstant.EditeDentalShift + shiftData.id);
          }
          
          break;
        case ShiftRelatedConstants.ShiftButtonText.CancelShift: {
          const cancellationFee = ShiftService.getCancellationFee(shiftData);
          let message = "Are you sure you wish to cancel this shift?";
          if (cancellationFee) {
            const feeAsDollars = `$${(cancellationFee / 100).toFixed(2)}`;
            message = `By cancelling at this point you agree to pay the amount of ${feeAsDollars} as a penalty.`;
          }
          if (shiftCancelable || !shiftData.flags.isAssigned) {
            setShiftAlertInfo({
              title: "Cancel Shift",
              message: "",
              buttons: [
                <RBButton
                  key={"confirm"}
                  onClick={async () => {
                    await onActionRelatedToAPI(
                      ShiftRelatedConstants.ShiftButtonText.CancelShift
                    );
                  }}
                  buttonBackgroundColor={primaryColor}
                  //color="primary"
                  text="Yes"
                />,
                <RBButton
                  key={"cancel"}
                  buttonType={RBButtonType.cancel}
                  onClick={() => {
                    setShiftAlertShowing(false);
                  }}
                  text="No"
                />,
                
              ],
            });
            setShiftAlertShowing(true);
          } else {
            
            setShiftNetworkAlertInfo({
              title: "\n",
              phone: shiftNetworkPhn,
              email:shiftNetworkMail,
              buttons: [
                <RBButton
                  key={"ok"}
                  onClick={() => {
                    setShiftNetworkAlertShowing(false);
                  }}
                  //color="primary"
                  text="Close"
                  buttonBackgroundColor={secondaryColor}
                />,
              ],
            });
            setShiftNetworkAlertShowing(true)
          }
          
          break;
        }
      }
    } else if (roleName === roleNameEnum.pharmacist) {
      switch (buttonText) {
        case ShiftRelatedConstants.ShiftButtonText.ConfirmHours:
          history.push(RoutePathConstant.ShiftConfirmHour + shiftData.id);
          break;
        case ShiftRelatedConstants.ShiftButtonText.CancelShift: {
          const cancellationFee = ShiftService.getCancellationFee(shiftData);
          let message = "Are you sure you wish to cancel this shift?";
          if (cancellationFee) {
            const feeAsDollars = `$${(cancellationFee / 100).toFixed(2)}`;
            message = `By cancelling at this point you agree to pay the amount of ${feeAsDollars} as a penalty.`;
          }
          if (shiftCancelable) {
            setShiftAlertInfo({
              title: "Cancel Shift",
              message: message,
              buttons: [
                <RBButton
                  key={"cancel"}
                  buttonType={RBButtonType.cancel}
                  onClick={() => {
                    setShiftAlertShowing(false);
                  }}
                  text="No"
                />,
                <RBButton
                  key={"confirm"}
                  onClick={async () => {
                    await onActionRelatedToAPI(
                      ShiftRelatedConstants.ShiftButtonText.CancelShift
                    );
                  }}
                  buttonBackgroundColor={primaryColor}
                  // color="primary"
                  text="Yes"
                />,
              ],
            });
            setShiftAlertShowing(true);
          } else {
            setShiftNetworkAlertInfo({
              title: "\n",
              phone: shiftNetworkPhn,
              email:shiftNetworkMail,
              buttons: [
                <RBButton
                  key={"ok"}
                  onClick={() => {
                    setShiftNetworkAlertShowing(false);
                  }}
                  //color="primary"
                  text="Close"
                  buttonBackgroundColor={secondaryColor}
                />,
              ],
            });
            setShiftNetworkAlertShowing(true)
          }
         
          break;
        }
        case ShiftRelatedConstants.ShiftButtonText.WithdrawShift: {
          setShiftAlertInfo({
            title: "Withdraw from Shift",
            message: "Are you sure you wish to withdraw from this shift?",
            buttons: [
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  await onActionRelatedToAPI(
                    ShiftRelatedConstants.ShiftButtonText.WithdrawShift
                  );
                }}
                buttonBackgroundColor={primaryColor}
                text="Yes"
              />,
              <RBButton
                key={"cancel"}
                buttonType={RBButtonType.cancel}
                onClick={() => {
                  setShiftAlertShowing(false);
                }}
                text="No"
              />,
              
            ],
          });
          setShiftAlertShowing(true);
          break;
        }
      }
    }
  };

  const renderPharmacyShiftDetailLayout = (
    shiftData: ShiftProps,
    roleName: string,
    doAction: (buttonText: string) => void
  ) => {
    const isCompletionAcknowledged = shiftData.flags
      ? shiftData.flags.isCompletionAcknowledged
      : false;
    return (
      <>
        {renderShiftStatus(shiftData, roleName)}
        {isCompletionAcknowledged ? (
          <RBCardView
            marginVertical={Spacing.large}
            style={{ padding: Spacing.large }}
            children={
              <>
                <BillInformation account={account} shiftData={shiftData} industryMode={industryMode}roleName={roleName} />
                <ShiftInformation shiftData={shiftData} />
                <WorkerInformation shiftData={shiftData} />
              </>
            }
          />
        ) : (
          <RBCardView
            marginVertical={Spacing.large}
            style={{ padding: Spacing.large }}
            children={
              <>
                <WorkerInformation shiftData={shiftData} />
                <ShiftInformation shiftData={shiftData} />
              </>
            }
          />
        )}
        {renderActionsForOwner(shiftData, doAction,roleName)}
      </>
    );
  };

  const onClose = () => {
    setBlockUserDialogVisible(false);
  }
  const  onBlockUser = async (shiftData:any) => {
    try {
      await AuthService.blockUser({
        comment: blockUserComment.length > 0 ? blockUserComment : null,
        shiftId: shiftData.id,
      });
      let payload = {};
      if(roleName === roleNameEnum.owner)
      {
        payload = {
          id: shiftData.id,
          locationFeedbackGiven: true
        }
      }else{
        payload = {
          id: shiftData.id,
          workerFeedbackGiven: true
        }
      }
      await PharmacyService.updateShiftFeedback(payload)
    } catch (error) {
      console.log('onBlockUser ', error);
    }

    setBlockUserDialogVisible(false);
    history.goBack();
  };

  const  onShiftFeedback = async (shiftData:any) => {
    try {
      let payload = {};
      if(roleName === roleNameEnum.owner)
      {
        payload = {
          id: shiftData.id,
          locationFeedbackGiven: true
        }
      }else{
        payload = {
          id: shiftData.id,
          workerFeedbackGiven: true
        }
      }
      await PharmacyService.updateShiftFeedback(payload)
    } catch (error) {
      console.log('onBlockUser ', error);
    }

    setBlockUserDialogVisible(false);
    history.goBack();
  };

  const renderCreateShiftContent = (shiftData:any) => {
    if (roleName === roleNameEnum.owner)
    {
      return (
        <div
          style={{
            backgroundColor: Colors.defaultGrey,
            display: "flex",
            flex: 1,
          }}
        >
          
            <RBCardView
              style={{
                flex: 1,
                marginLeft: Spacing.large,
                marginRight: Spacing.large,
                marginBottom: Spacing.smallest,
                paddingLeft: Spacing.large,
                paddingTop: Spacing.large,
                paddingRight: Spacing.large,
                paddingBottom: Spacing.large,
              }}
            >
            <div>
              How did the shift go with {shiftData?.pharmacist?.pharmacist?.firstName} {shiftData?.pharmacist?.pharmacist?.lastName}?
              Would you consider hiring this {formatWorkerType(shiftData.pharmacistType)} again?
            </div>
            <br></br>
            <div>
              <RBTextField
                style={{
                  display: "flex",
                  flex: 1,
                  paddingTop: Spacing.small,
                  paddingBottom: Spacing.small,
                }}
                placeholder={"*Please leave a comment if you decide no."}
                value={blockUserComment}
                onChange={(event) => setBlockUserComment(event.target.value)}
                multiline
                rows={3}
                rowsMax={8}
              />
            </div>
            <br></br>
            <div style={{color:'red'}}>
              *If you select "No", this {formatWorkerType(shiftData.pharmacistType)} will not be matched with any future shifts/jobs you
                post for this office or any of the other offices on your profile.
            </div>
            <div style={{
              display: "flex",
              flex: 1,
              alignItems: "flex-end",
            }}>
                  <RBButton
                    buttonBackgroundColor={primaryColor}
                    isLoading={isButtonLoading}
                    disabled={blockUserComment.length === 0}
                    text="No"
                    onClick={() => onBlockUser(shiftData)}
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: Spacing.medium,
                      margin: Spacing.large,
                    }}
                  />
                  <RBButton
                    buttonBackgroundColor={primaryColor}
                    isLoading={isButtonLoading}
                    text="Yes"
                    onClick={() => onShiftFeedback(shiftData)}
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: Spacing.medium,
                      margin: Spacing.large,
                    }}
                  />
            </div>
            </RBCardView>
        
        </div>
      );
    }
    else{
      return (
        <div
          style={{
            backgroundColor: Colors.defaultGrey,
            display: "flex",
            flex: 1,
          }}
        >
          
            <RBCardView
              style={{
                flex: 1,
                marginLeft: Spacing.large,
                marginRight: Spacing.large,
                marginBottom: Spacing.smallest,
                paddingLeft: Spacing.large,
                paddingTop: Spacing.large,
                paddingRight: Spacing.large,
                paddingBottom: Spacing.large,
              }}
            >
             <div>
             How did your shift go with {shiftData.pharmacy.name}? Would you consider
              working at this {shiftData.pharmacy.companyType} again?
             </div>
             <br></br>
             <div>
              <RBTextField
                style={{
                  display: "flex",
                  flex: 1,
                  paddingTop: Spacing.small,
                  paddingBottom: Spacing.small,
                }}
                placeholder={"*Please leave a comment if you decide no."}
                value={blockUserComment}
                onChange={(event) => setBlockUserComment(event.target.value)}
                multiline
                rows={3}
                rowsMax={8}
              />
             </div>
             <br></br>
             <div style={{color:'red'}}>
             *If you select "No", you will never match with that dental practice's
              shifts/jobs again.
             </div>
             <div
             style={{
              display: "flex",
              flex: 1,
              alignItems: "flex-end",
            }}>
                  <RBButton
                    buttonBackgroundColor={primaryColor}
                    isLoading={isButtonLoading}
                    disabled={blockUserComment.length === 0}
                    text="No"
                    onClick={() => onBlockUser(shiftData)}
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: Spacing.medium,
                      margin: Spacing.large,
                    }}
                  />
                  <RBButton
                    buttonBackgroundColor={primaryColor}
                    isLoading={isButtonLoading}
                    text="Yes"
                    onClick={() => onShiftFeedback(shiftData)}
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: Spacing.medium,
                      margin: Spacing.large,
                    }}
                  />
             </div>
            </RBCardView>
         
        </div>
      );
    }
  };
  const renderBlockUserDialog = (shift:any) => {
    
    return (

      <RBModal
          open={blockUserDialogVisible}
          onClose={onClose}
          modalTitle={"Shift Feedback"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              renderCreateShiftContent(shift)
          }
        />

    );
  };


  const renderDentalShiftDetailLayout = (
    shiftData: ShiftProps,
    roleName: string,
    doAction: (buttonText: string) => void
  ) => {

    const { pharmacy } = shiftData;
    const showPartialInfo =
    shiftData.flags.isCompletionAcknowledged || shiftData.flags.isCancelled;


    const isCompletionAcknowledged = shiftData.flags
      ? shiftData.flags.isCompletionAcknowledged
      : false;
    return (
      <>
      {renderBlockUserDialog(shiftData)}
        {renderShiftStatus(shiftData, roleName)}
        {isCompletionAcknowledged ? (
          <RBCardView
            marginVertical={Spacing.large}
            style={{ padding: Spacing.large }}
            children={
              <>
                <BillInformation account={account} shiftData={shiftData} industryMode={industryMode} roleName={roleName} />
                <DentalShiftInfo
                 account={account}
                 pharmacy={pharmacy}
                 shiftData={shiftData}
                 />
                <WorkerInformation shiftData={shiftData} />
              </>
            }
          />
        ) : (
          <RBCardView
            marginVertical={Spacing.large}
            style={{ padding: Spacing.large }}
            children={
              <>
                <WorkerInformation shiftData={shiftData} />
                <DentalShiftInfo
                 account={account}
                 pharmacy={pharmacy}
                 shiftData={shiftData}
                 />
              </>
            }
          />
        )}
        {renderActionsForOwner(shiftData, doAction,roleName)}
      </>
    );
  };

  const renderWorkerShiftDetailLayout = (
    shiftData: ShiftProps,
    roleName: string,
    doAction: (buttonText: string) => void
  ) => {
    const owner = shiftData.creator ? shiftData.creator.owner : false;
    const showPartialInfo =
    shiftData.flags.isCompletionAcknowledged || shiftData.flags.isCancelled || !owner;
    const isCompletionAcknowledged = shiftData.flags
      ? shiftData.flags.isCompletionAcknowledged
      : false;
    return (
      <>
        {renderShiftStatus(shiftData, roleName)}
        {isCompletionAcknowledged ? (
          <RBCardView
            marginVertical={Spacing.large}
            style={{ padding: Spacing.large }}
            children={
              <>
                <BillInformation account={account} shiftData={shiftData} industryMode={industryMode} roleName={roleName} />
                <ShiftInformation shiftData={shiftData} />
                <PharmacyInformation 
                shiftData={shiftData}  
                pharmacy={shiftData.pharmacy}
                owner={owner}
                showPartialInfo={showPartialInfo}
                account={account}
                page="shiftDetails"  />
              </>
            }
          />
        ) : (
          <RBCardView
            marginVertical={Spacing.large}
            style={{ padding: Spacing.large }}
            children={
              <>
                <PharmacyInformation shiftData={shiftData}  
                pharmacy={shiftData.pharmacy}
                owner={owner}
                showPartialInfo={showPartialInfo}
                account={account}
                page="shiftDetails" />

                <ShiftNetworkInformation
                  pharmacy={shiftData.pharmacy}
                  account={account}
                  networkAdminInfo = {networkAdminInfo}
                />
                <ShiftInformation shiftData={shiftData} />
              </>
            }
          />
        )}
        {renderActionsForWorker(shiftData, doAction,roleName)}
      </>
    );
  };


  const renderDentalWorkerShiftDetailLayout = (
    shiftData: ShiftProps,
    roleName: string,
    doAction: (buttonText: string) => void
  ) => {

    const { pharmacy } = shiftData;
    // when the worker applied the shift but not got confirmed yet, the worker will not be able to view any pharmacy/creator information
    const owner = shiftData.creator ? shiftData.creator.owner : false;
    const ownerEmail = shiftData.creator ? shiftData.creator.email : false;
    const isCompletionAcknowledged = shiftData.flags
      ? shiftData.flags.isCompletionAcknowledged
      : false;
      const showPartialInfo =
      shiftData.flags.isCompletionAcknowledged || shiftData.flags.isCancelled;
    return (
      <>
        {renderBlockUserDialog(shiftData)}
        {renderShiftStatus(shiftData, roleName)}
        {isCompletionAcknowledged ? (
          <RBCardView
            marginVertical={Spacing.large}
            style={{ padding: Spacing.large }}
            children={
              <>
                <BillInformation account={account} shiftData={shiftData} industryMode={industryMode} roleName={roleName} />
                <DentalShiftInfo
                account={account}
                pharmacy={pharmacy}
                shiftData={shiftData}
              />
              <DentalPracticeInfo
                pharmacy={pharmacy}
                shiftData={shiftData}
                owner={owner}
                ownerEmail={ownerEmail}
                showPartialInfo={showPartialInfo}
                account={account}
                page="shiftDetails" 
              />
              </>
            }
          />
        ) : (
          <RBCardView
            marginVertical={Spacing.large}
            style={{ padding: Spacing.large }}
            children={
              <>
                <DentalPracticeInfo
                pharmacy={pharmacy}
                shiftData={shiftData}
                owner={owner}
                ownerEmail={ownerEmail}
                showPartialInfo={showPartialInfo}
                account={account}
                page="shiftDetails" 
              />

              <ShiftNetworkInformation
                  pharmacy={shiftData.pharmacy}
                  account={account}
                  networkAdminInfo = {networkAdminInfo}
                />

              <DentalShiftInfo
                account={account}
                pharmacy={pharmacy}
                shiftData={shiftData}
              />
              </>
            }
          />
        )}
        {renderActionsForWorker(shiftData, doAction,roleName)}
      </>
    );
  };

  const renderShiftStatus = (shiftData: ShiftProps, roleName: string) => {

    let shiftStateStatus = (roleName === roleNameEnum.owner)
    ? ShiftService.getStatusForShiftAsPharmacy(shiftData,roleName)
    : ShiftService.getStatusForShiftAsWorker(shiftData,roleName)
    
    if (shiftData.pharmacistType.includes('entist') && 
    (shiftStateStatus === ShiftRelatedConstants.ShiftStateText.FeedbackShift)) {
      if(shiftData.flags.isAssigned && shiftData.flags.isCompleted ){
        
        if(!shiftData.flags.isCompletionAcknowledged){
          if (shiftData.flags.isDisputed) {
            shiftStateStatus =  ShiftRelatedConstants.ShiftStateText.InDispute;
          }
          shiftStateStatus =ShiftRelatedConstants.ShiftStateText.AwaitingHoursConfirmation;
        }
        if(shiftData.flags.isCompletionAcknowledged){
          shiftStateStatus = ShiftRelatedConstants.ShiftStateText.ShiftCompleted
        }
      }
    }

    return (
      <div
        style={{
          backgroundColor: primaryColor,
          padding: Spacing.large,
        }}
      >
        <div
          style={{
            fontSize: FontSize.large,
            fontWeight: "bold",
            color: "white",
          }}
        >
          {shiftStateStatus}
        </div>
      </div>
    );
  };

  const renderActionsForWorker = (
    shiftData: ShiftProps,
    doAction: (buttonText: string) => void,
    rolename: string
  ) => {
    const flags = shiftData.flags;
    const shiftState = ShiftService.getStatusForShiftAsWorker(shiftData,rolename);
    const applied = true;
    const { isAssigned } = flags;
    const isPast = moment().isAfter(moment(shiftData.endTime));

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {!isAssigned && !flags.isCancelled && !applied && (
          <RBButton
            isLoading={isButtonLoading}
            //onPress={this.onShiftRequest}
            text="Apply for Shift"
            style={{ flex: 1, padding: 16 }}
            buttonBackgroundColor={primaryColor}
          />
        )}
        {/* Applied but not yet get assign */}
        {!isAssigned && !flags.isCancelled && applied && (
          <RBButton
            isLoading={isButtonLoading}
            onClick={() => {
              doAction(ShiftRelatedConstants.ShiftButtonText.WithdrawShift);
            }}
            text="Withdraw Shift"
            style={{ flex: 1, padding: 16 }}
            buttonBackgroundColor={primaryColor}
          />
        )}
        {isAssigned && isPast && !flags.isCompleted && !flags.isCancelled && (
          <RBButton
            isLoading={isButtonLoading}
            text="Confirm Shift Completion"
            onClick={() => {
              doAction(ShiftRelatedConstants.ShiftButtonText.ConfirmHours);
            }}
            buttonBackgroundColor={primaryColor}
            style={{ flex: 1, padding: 16 }}
          />
        )}

        {isAssigned &&
          !flags.isCancelled &&
          !flags.isPast &&
          shiftState !== ShiftRelatedConstants.ShiftStateText.ActiveShift && shiftData.cancellationRequested === null  && (
            ((shiftState === ShiftRelatedConstants.ShiftStateText.ReadyForShift && shiftData.pharmacy?.network?.cancellationRequestFlowEnabled) ?
            <RBButton
            isLoading={isButtonLoading}
            text={ShiftRelatedConstants.ShiftButtonText.RequestCancel}
            style={{ flex: 1, padding: 16 }}
            onClick={() => {
              doAction(ShiftRelatedConstants.ShiftButtonText.CancelShift);
            }}
            buttonBackgroundColor={primaryColor}
          />:
            <RBButton
              isLoading={isButtonLoading}
              text={ShiftRelatedConstants.ShiftButtonText.CancelShift}
              style={{ flex: 1, padding: 16 }}
              onClick={() => {
                doAction(ShiftRelatedConstants.ShiftButtonText.CancelShift);
              }}
              buttonBackgroundColor={primaryColor}
            />)
          )}

        {IndustryModeEnum.Dentistry &&
            shiftData.workerAutoAcknowledged &&
            !flags.isCancelled &&
            shiftData.workerFeedbackGiven !== true &&
            !flags.isDisputed &&
            (flags.isCompletionAcknowledged || flags.isCompleted) && (
              <RBButton
              isLoading={isButtonLoading}
              text="Shift Feedback"
              style={{ flex: 1, padding: 16, background: primaryColor }}
              onClick={() => {
                //doAction(RoutePathConstant.ShiftSurvey + shiftData.id);
                setBlockUserDialogVisible(true);
              }}

            />
            )}
      </div>
    );
  };

  const renderActionsForOwner = (
    shiftData: ShiftProps,
    doAction: (buttonText: string) => void,
    roleName:string
  ) => {
    const flags = shiftData.flags;
    const shiftState = ShiftService.getStatusForShiftAsPharmacy(shiftData,roleName);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {flags.isEditable && (
          <RBButton
            onClick={() => {
              doAction(ShiftRelatedConstants.ShiftButtonText.EditShift);
            }}
            isLoading={isButtonLoading}
            text="Edit Shift"
            style={{
              flex: 1,
              padding: 16,
              marginRight: !flags.isPast && !flags.isCancelled ? 8 : 0,
              background:primaryColor
            }}
          ></RBButton>
        )}
        {!shiftData.pharmacist && !flags.isCancelled && !flags.isEditable && (
          <RBButton
            isLoading={isButtonLoading}
            text={ShiftRelatedConstants.ShiftButtonText.ViewApplications}
            onClick={() =>
              doAction(ShiftRelatedConstants.ShiftButtonText.ViewApplications)
            }
            style={{
              flex: 1,
              padding: 16,
              marginRight: !flags.isPast && !flags.isCancelled ? 8 : 0,
              background:primaryColor
            }}
          />
        )}

        {flags.isCompleted &&
          !flags.isCompletionAcknowledged &&
          !flags.isDisputed && (
            <RBButton
              isLoading={isButtonLoading}
              text={ShiftRelatedConstants.ShiftButtonText.ConfirmHours}
              onClick={() =>
                doAction(ShiftRelatedConstants.ShiftButtonText.ConfirmHours)
              }
              style={{
                flex: 1,
                padding: 16,
                marginRight: !flags.isRated ? 8 : 0,
                background:primaryColor
              }}
            />
          )}
          
        {!flags.isPast &&
          !flags.isCancelled &&
          shiftState !== ShiftRelatedConstants.ShiftStateText.ActiveShift && shiftData.cancellationRequested === null && (
            ((shiftState === ShiftRelatedConstants.ShiftStateText.ReadyForShift && shiftData.pharmacy?.network?.cancellationRequestFlowEnabled) ?
            <RBButton
              isLoading={isButtonLoading}
              text={ShiftRelatedConstants.ShiftButtonText.RequestCancel}
              style={{ flex: 1, padding: 16 ,background:primaryColor}}
              onClick={() => {
                doAction(ShiftRelatedConstants.ShiftButtonText.CancelShift);
              }}
            />:
            <RBButton
              isLoading={isButtonLoading}
              text={ShiftRelatedConstants.ShiftButtonText.CancelShift}
              style={{ flex: 1, padding: 16 ,background:primaryColor}}
              onClick={() => {
                doAction(ShiftRelatedConstants.ShiftButtonText.CancelShift);
              }}
            />)
          )}
        {!flags.isCancelled &&
          !flags.isRated &&
          !flags.isDisputed &&
          flags.isCompletionAcknowledged && industryMode === IndustryModeEnum.Pharmaceutical && (
            <RBButton
              isLoading={isButtonLoading}
              text="Rate Shift"
              style={{ flex: 1, padding: 16,background:primaryColor }}
              onClick={() => {
                //doAction(RoutePathConstant.ShiftSurvey + shiftData.id);
                history.push(RoutePathConstant.ShiftSurvey + shiftData.id);
              }}
             
            />
          )}

        {shiftData?.locationAutoAcknowledged &&
          !flags.isCancelled &&
          shiftData.locationFeedbackGiven !== true &&
          !flags.isDisputed &&
          flags.isCompletionAcknowledged &&
          flags.isCompletionAcknowledged && industryMode === IndustryModeEnum.Dentistry && (
            <RBButton
              isLoading={isButtonLoading}
              text="Shift Feedback"
              style={{ flex: 1, padding: 16, background: primaryColor }}
              onClick={() => {
                //doAction(RoutePathConstant.ShiftSurvey + shiftData.id);
                setBlockUserDialogVisible(true);
              }}

            />
          )}
      </div>
    );
  };
  const onCloseModal= () => {
    setShiftNetworkAlertShowing(false)
  };
  const onYesClick = async () =>{
    setCancelLoading(true)
    const payload = {
      shiftId:shiftData.id,
      userId:account.id,
      cancellationRequestReason:reason
    }
    const cancelDetail = await PharmacyService.requestShiftCancel(payload);
    await fetchData();
    setCancelLoading(false)
    setShiftNetworkAlertShowing(false)
    setShiftAlertInfo({
      title: "Success",
      message: "Request sent successfully!",
      buttons: [
        <RBButton
          key={"ok"}
          onClick={async () => {
            setShiftAlertShowing(false);
          }}
          text="Ok"
          buttonBackgroundColor={secondaryColor}
        />,
      ],
    });
    setShiftAlertShowing(true);
  }
  const renderCancelContent = () => {
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
        }}
      >

        <RBCardView
          style={{
            flex: 1,
            marginLeft: Spacing.large,
            marginRight: Spacing.large,
            marginBottom: Spacing.smallest,
            paddingLeft: Spacing.large,
            paddingTop: Spacing.large,
            paddingRight: Spacing.large,
            paddingBottom: Spacing.large,
          }}
        >
          {
            account.roleName === 'owner'?
            <>
              <div>
                Are you sure you want to submit a request to cancel? 
              </div>
              <br/>
              <div>
              Please review <b>Shift Agreement</b> for cancellation policy.
              </div>
              <br/>
              <div>
                If this is a short-notice request, please remember to contact the {formatWorkerType(shiftData.pharmacistType)} to inform them. 
              </div>
              <br/>
              <div>
                Their information is in the shift details.
              </div>
              <br/>
            </>:
            <>
              <div>
                Are you sure you want to submit a request to cancel? 
              </div>
              <br/>
              <div>
              Please review <b>Shift Agreement</b> for cancellation policy.
              </div>
              <br/>
              <div>
                If this is a short-notice request, please remember to contact the {shiftData?.pharmacy?.companyType !== null ? shiftData?.pharmacy?.companyType: 'Pharmacy'} to inform them. 
              </div>
              <br/>
              <div>
                Their information is in the shift details.
              </div>
            </>
          }
          <br></br>

          <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <Typography style={{ display: "flex", flex: 1,fontSize:14 }}>Reason:</Typography>
          </div>
          <RBTextField
            style={{
              display: "flex",
              flex: 1,
              paddingTop: Spacing.small,
              paddingBottom: Spacing.small,
              fontSize:14
            }}
            placeholder={"* Reason for request, please be as descriptive as possible."}
            value={reason}
            onChange={(event) =>setReason(event.target.value)}
            multiline
            rows={3}
            rowsMax={8}
          />

          <br></br>

          <div style={{
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}>
            <RBButton
              buttonBackgroundColor={primaryColor}
              disabled={reason === ''}
              isLoading={cancelLoading}
              text="Yes"
              onClick={() => {
                onYesClick()
              }}
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
            />
            <RBButton
              buttonBackgroundColor={secondaryColor}
              text="No"
              onClick={() => { 
                setReason('')
                setShiftNetworkAlertShowing(false)
              }}
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
            />
          </div>
        </RBCardView>

      </div>
    );
  };

  return (
    <div style={{ ...pageStyle }} className="paddingHorizontal paddingVertical">
      <RBAlert
        show={isAlertShowing}
        alertTitle={shiftAlertInfo.title}
        alertMessage={shiftAlertInfo.message}
        buttons={shiftAlertInfo.buttons}
      />

      {shiftData.pharmacy?.network?.cancellationRequestFlowEnabled ?
        <RBActiveShiftCancelModal
        open={isNetworkAlertShowing}
        onClose={onCloseModal}
        modalTitle={""}
        header={
          <RBEmptyBlock
            style={{
              width: "100%",
              height: Spacing.large,
              backgroundColor: Colors.defaultGrey,
              flexShrink: 0,
            }}
          />
        }
        // actions={this.renderCreateShiftActions()}
        children={
            renderCancelContent()
        }
      />
      :
        <RBNetworkCancelAlert
          show={isNetworkAlertShowing}
          alertTitle={shiftNetworkAlertInfo.title}
          phone={shiftNetworkAlertInfo.phone}
          email={shiftNetworkAlertInfo.email}
          buttons={shiftNetworkAlertInfo.buttons}
        />
      }
       



      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={JSON.stringify(submitErrorMessage, null, 2)}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      />
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <div style={{ flex: 1 }}>
          {industryMode === IndustryModeEnum.Pharmaceutical ? ( roleName === roleNameEnum.owner
            ? renderPharmacyShiftDetailLayout(shiftData, roleName, doAction)
            : renderWorkerShiftDetailLayout(shiftData, roleName, doAction))
          :(
            roleName === roleNameEnum.owner
            ? renderDentalShiftDetailLayout(shiftData, roleName, doAction)
            : renderDentalWorkerShiftDetailLayout(shiftData, roleName, doAction)
          )}
        </div>
      )}
    </div>
  );
};
