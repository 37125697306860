import axios, {
  AxiosInstance,
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
} from "axios";
import { API_URL, WEB_VERSION } from "../config";
import { AuthService } from "./AuthService";

class Api {
  axios: AxiosInstance;
  constructor() {
    // console.log("Api constructor baseURL ", API_URL);
    this.axios = axios.create({
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "RB-platform": "Web",
        "RB-Version": WEB_VERSION,
      },
      baseURL: API_URL,
    });
    if (!!localStorage.getItem("token")) {
      this.axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
        "token"
      )}`;
    }
  }
  async get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
    parameters?: T
  ): Promise<R> {
    try {
      const response = await this.axios.get(url, {
        params: parameters,
      });
      return response.data;
    } catch (err) {
      this.handleError(err);
      throw err;
    }
  }
  // get = (url: string, parameters: any) =>
  //     this.axios
  //         .get(url, {
  //             params: parameters,
  //         })
  //         .then((res) => res.data)
  //         .catch(this.handleError);

  // post<T,AxiosResponse> = (url: string, data: T) =>
  //     this.axios
  //         .post<T,AxiosResponse<T>>(url, data)
  //         .then((res) =>  return res.data)
  //         .catch(this.handleError);

  // delete = (url: string, data: any) =>
  //     this.axios
  //         .delete(url, data)
  //         .then((res) => res.data)
  //         .catch(this.handleError);
  // patch = (url: string, data: any) =>
  //     this.axios
  //         .patch(url, data)
  //         .then((res) => res.data)
  //         .catch(this.handleError);

  handleError = (error: any) => {
    console.log("Network error", { ...error.response });
    //return this.handle401(error).catch(error => Promise.reject(error));
  };
}
const singleton = new Api();

export default singleton;
