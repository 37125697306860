import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';

import axios from 'axios';
import { Spacing } from '../theme/Theme';
import { LoadingIndicator } from './LoadingIndicator';


const PDFViewer = ({...props}) => {
    
    const [pdfDataUrl, setPdfDataUrl] = useState('');
    const [numPages, setNumPages] = useState<any>(null);
    useEffect(() => {

      localStorage.removeItem("downloadedPdf")
    },[])
    useEffect(() => {
        async function fetchAndStorePDF() {
          try {
            console.log("pdfurl",props.pdfUrl)
            const response = await axios.get(props.pdfUrl, { responseType: 'arraybuffer' });
            console.log("response",response)
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfData = URL.createObjectURL(pdfBlob);
            setPdfDataUrl(pdfData);
    
            // Store the PDF Data URL in local storage
            localStorage.setItem('downloadedPdf', pdfData);
            console.log("pdfData",pdfData)
          } catch (error) {
            console.error('Error fetching PDF:', error);
          }
        }
        
        fetchAndStorePDF();
      }, [props.pdfUrl]);


  return (
    <div>
      {!pdfDataUrl &&  <LoadingIndicator />}
      {props?.title == "resumes" || props?.title == "Shift Contract"?
      <Document file={pdfDataUrl} 
      onLoadSuccess={({ numPages })=>setNumPages(numPages)}
      >
       {Array.apply(null, Array(numPages))
                .map((x, i)=>i+1)
                .map(page => <Page pageNumber={page}/>)}
      </Document>
      :
      pdfDataUrl &&
        <img
          src={pdfDataUrl}
          alt="logo"
          style={{
            display: "flex",
            flex: 1,
            maxWidth: "90%",
            maxHeight: "100%",
            marginBottom: Spacing.large,
          }} 
        />
    }
    </div>
  );
};

export default PDFViewer;