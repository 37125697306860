import React, { useState, useEffect,useContext } from "react";
import { Colors, FontSize, Spacing } from "../../theme/Theme";
import { RBCardView } from "../../components/RBCardView";
import { AuthService } from "../../api/AuthService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { RBButton } from "../../components/RBButton";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import {
  RoutePathConstant,
  NotAvailableString,
  Claims,
  roleNameEnum,
} from "../../util/Common";
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBContext } from "../../components/RBContext";

export const SINBusinessSettingPage = () => {
  const [accountInfo, setAccountInfo] = useState<ObjectLiteral>({});
  const [roleName] = useState<string>(AuthService.getRoleName());
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);

  const onAccountChanged = (data: string) => {
    console.log("on SINBusinessSettingPage Changed", data);
    setRefresh((pre) => !pre);
  };
  useEffect(() => {
    setIsPageLoading(true);
    const unsub = NotificationService.subscribe(
      NotificationServiceTopic.AccountChanged,
      onAccountChanged
    );
    const fetchData = async () => {
      try {
        const userData = await AuthService.loadAccountInfo();
        setAccountInfo(userData);
        console.log("SINBusinessSettingPage fetchData : ", userData);
        setIsPageLoading(false);
      } catch (err) {}
    };
    fetchData();
    return () => {
      unsub.unsubscribe();
    };
  }, [refresh]);

  const renderStatusHeader = () => {
    const sinStatus = sinSetupAlready() ? "Setup" : "Not Setup";
    return (
      <div
        style={{
          backgroundColor: primaryColor,
          padding: Spacing.large,
          margin: Spacing.large,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            fontSize: FontSize.large,
            fontWeight: "bold",
            color: "white",
          }}
        >
          Settings Status
        </div>
        <div
          style={{
            display: "flex",
            fontSize: FontSize.large,
            fontWeight: "bold",
            color: "white",
          }}
        >
          {sinStatus}
        </div>
      </div>
    );
  };

  // const renderCreditCards = () => {

  //   if (
  //     !accountInfo.owner ||
  //     !Array.isArray(accountInfo.pharmacies) ||
  //     !accountInfo.pharmacies.length
  //   ) {
  //     return null;
  //   }

  //   const pharmacy = accountInfo.pharmacies.filter((pharmacy: any) =>
  //     pharmacy.id === accountInfo.owner.defaultPharmacyId
  //   )[0];
  //   if (!pharmacy.flags.isCreditCardSetup) {
  //     return null;
  //   }

  //   const { creditCards } = accountInfo;
  //   const defaultCard = creditCards.find((x: any) => x.isDefault);

  //   if (!defaultCard) {
  //     return null;
  //   }

  //   return (
  //     <RBCardView
  //       marginHorizontal={Spacing.large}
  //       style={{
  //         padding: Spacing.large,
  //       }}
  //     >
  //       <div style={{ padding: 16 }}>
  //         <div
  //           style={{
  //             fontWeight: 'bold',
  //             fontStyle: 'italic',
  //             marginBottom: 16,
  //           }}
  //         >
  //           {'Current Card'}
  //         </div>

  //         <div >
  //           Card: {defaultCard.type} ending with {defaultCard.lastFourDigits}
  //         </div>
  //         <div>
  //           Expires: {defaultCard.expiryMonth}/{defaultCard.expiryYear}
  //         </div>
  //       </div>
  //     </RBCardView>
  //   );
  // }

  const goToSetup = () => {
    let updateBusinessInfo = false;
    if (sinSetupAlready()) {
      if (accountInfo.pharmacist.socialInsuranceNumber === null) {
        updateBusinessInfo = true;
      }
    }
    history.push(RoutePathConstant.SINBusinessRegistrationSetting, {
      updateBusinessInfo: updateBusinessInfo,
      businessName: accountInfo.pharmacist.businessName,
      businessNumber: accountInfo.pharmacist.taxNumber,
    });
  };
  const renderNotSetup = () => {
    return (
      <RBCardView
        marginHorizontal={Spacing.large}
        style={{
          padding: Spacing.large,
        }}
      >
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Typography
            variant={"body1"}
            style={{ paddingBottom: Spacing.large }}
          >
            Relief Buddy is required to provide you with a T4A tax slip every
            year for the wages you earn. For this reason, we need your SIN or
            Business/Tax number and name (if applicable). {"\n\n"}You will be
            asked to provide this information upon the completion of your first
            shift. {"\n\n"}
          </Typography>
          <RBButton
            buttonBackgroundColor={secondaryColor}
            style={{
              display: "flex",
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginTop: Spacing.large,
            }}
            text={"Let's Get Started"}
            onClick={goToSetup}
          />
        </div>
      </RBCardView>
    );
  };

  const renderIndividualInfo = () => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          // marginLeft: Spacing.large,
          // marginRight: Spacing.large,
        }}
      >
        <div
          style={{
            ...ColumnStyle,
            marginBottom: Spacing.xlarge,
          }}
        >
          <Typography style={{ display: "flex", flex: 1 }} variant={"h6"}>
            Social Insurance Number
          </Typography>
          <Typography style={{ display: "flex", flex: 1 }} variant={"h6"}>
            xxx - xxx - {accountInfo.pharmacist.socialInsuranceNumber}
          </Typography>
        </div>
        <Typography variant={"caption"}>
          *If you want to change above information, please contact <span style={{color:primaryColor,fontWeight:"bold"}}>{localStorage.getItem("contactEmail")}</span>
        </Typography>
      </div>
    );
  };
  const renderBusinessInfo = () => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          // marginLeft: Spacing.large,
          // marginRight: Spacing.large,
        }}
      >
        <div
          style={{
            ...ColumnStyle,
            marginBottom: Spacing.xlarge,
          }}
        >
          <Typography style={{ display: "flex", flex: 1,fontWeight:"bold" }} variant={"h6"}>
            Business Name
          </Typography>
          <Typography style={{ display: "flex", flex: 1 ,fontWeight:"bold" }} variant={"h6"}>
            {accountInfo.pharmacist.businessName ?? NotAvailableString}
          </Typography>
        </div>
        <div style={ColumnStyle}>
          <Typography style={{ display: "flex", flex: 1,fontWeight:"bold"  }} variant={"h6"}>
            Business Number
          </Typography>
          <Typography style={{ display: "flex", flex: 1 ,fontWeight:"bold" }} variant={"h6"}>
            {accountInfo.pharmacist.taxNumber ?? NotAvailableString}
          </Typography>
        </div>
        <RBButton
          buttonBackgroundColor={secondaryColor}
          style={{
            display: "flex",
            flex: 1,
            marginTop: Spacing.large,
          }}
          text={"Update Business Information"}
          onClick={goToSetup}
        />
      </div>
    );
  };
  // const renderPharmacy = () => {
  //   const pharmacy = accountInfo.pharmacies.filter((pharmacy: any) =>
  //     pharmacy.id === accountInfo.owner.defaultPharmacyId
  //   )[0];
  //   return (
  //     <div
  //       {...(this.renderCreditCards() === null
  //         ? {}
  //         : { style: { marginVertical: 8 } })}
  //     >
  //       {pharmacy.companyType ? 
        
  //       <div style={{ padding: 16 }}>
  //         {/* <Text>
  //           Payment will be automatically processed using this card on file when posting your job(s).
  //         </Text> */}
  //         <div style={{ marginVertical: 8 }}>
  //           Payment for permanent job postings will be automatically charged 
  //           to this card on file through our secure third-party payment processor, Stripe. 
  //           This card will not be automatically charged for the payment of shift fees.{'\n\n'}
  //           Payment of shift fees: You will receive an invoice the first week of every month 
  //           for any shifts completed in the previous month. We accept payment by credit card, 
  //           e-transfer, cheque, and electronic funds transfer.
  //         </div>
  //         <RBButton
  //           text="Update Payment Information"
  //           onPress={() => {
  //             Actions.paymentCreditInput({ account, userInfo });
  //           }}
  //           style={{backgroundColor:this.context.secondaryColor,padding: spacing.large}}
  //         />
  //       </div>
  //        :
  //        <div style={{ padding: 16 }}>
  //         <Text style={styles.font}>
  //         Payment for permanent job postings will be automatically charged to this card on 
  //         file through our secure third-party payment processor, Stripe.
  //         </Text>
  //         <Text style={[styles.font,{ marginVertical: 8 }]}>
  //         Please note: We do not accept credit card payments for payment of shift invoices. 
  //         The card on file is only used for job posting fees, not for shift invoice payments. 
  //         Shift invoices can be paid via cheque, e-transfer, or direct deposit 
  //         (contact Relief Buddy for our banking information if using direct deposit).
  //         </Text>
  //         <Button
  //           text="Update Payment Information"
  //           onPress={() => {
  //             Actions.paymentCreditInput({ account, userInfo });
  //           }}
  //           style={{backgroundColor:this.context.secondaryColor,padding: spacing.large}}
  //         />
  //       </div>
  //       }
  //     </div>
  //   );
  // }
  const renderPharmacist = () => {
    const isIndividual = accountInfo.pharmacist.socialInsuranceNumber !== null;
    return (
      <RBCardView
        marginHorizontal={Spacing.large}
        style={{
          padding: Spacing.large,
        }}
      >
        {isIndividual && renderIndividualInfo()}
        {!isIndividual && renderBusinessInfo()}
      </RBCardView>
    );
  };
  const sinSetupAlready = () => {

    if (accountInfo.owner) {
      const pharmacy = accountInfo.pharmacies.filter((pharmacy: any) =>
        pharmacy.id === accountInfo.owner.defaultPharmacyId
      )[0];
      return pharmacy.flags.isCreditCardSetup;
    }

    return accountInfo.pharmacist.flags.isSinSetup;
  };
  const renderDetail = () => {
    const hasInfo = sinSetupAlready();
    
    return (
      <>
        {renderStatusHeader()}
        {/* {renderCreditCards()} */}
        {/* {sinSetupAlready() ? renderSetUp() : renderNotSetup()} */}
        {!hasInfo
          ? renderNotSetup()
          : accountInfo.roleName === roleNameEnum.pharmacist?
           renderPharmacist(): null}
      </>
    );
  };
  return (
    <>
      {/* //marginHorizontal={Spacing.large}
       */}
      {isPageLoading ? <LoadingIndicator /> : renderDetail()}
    </>
  );
};
