import React,{useContext, useState, useEffect} from "react";
import { ShiftService } from "../../../api/ShiftService";
import { ShiftProps } from "../../../interfacesProps/ShiftProps";
import {
  detailKmAllowanceIcon,
  detailAccomodationAllowanceIcon,
  techOnSiteIcon,
  assistantOnSiteIcon,
} from "../../../util/Icons";
import { Colors, FontSize, Spacing, FontWeight } from "../../../theme/Theme";
import {
  RenderTextWithTitle,
  renderTextWithTitleForSkills,
  renderTextWithTitleAndChildComponent,
  detailTextStyle,
  titleHeaderTextStyle,
} from "../../../components/TextWithHeader";
import moment from "moment-timezone";
import { formatWorkerType, formatPaymentType } from "../../../util/FormattingService";
import { LabelText } from "../../../util/TextConstant";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import { paymentTypeEnum, workerTypeEnum } from "../../../util/Enumeration";
import { RBContext } from '../../../components/RBContext';
import RBPlacementFeeModal from "../../../components/RBPlacementFeeModal";
import { AiTwotoneMessage } from "react-icons/ai";
import { IoIosCash } from "react-icons/io";

import { defaultAllowOffersIcon } from '../../../components/RBShiftRelatedIcons';
import { RBEmptyBlock } from "../../../components/RBEmptyBlock";
import { RBAlert } from "../../../components/RBAlert";
import { RBShiftAgreementModal } from "../../../components/RBShiftAgreementModal";
import { AuthService } from "../../../api/AuthService";
import { Link } from "@material-ui/core";
import { RBCardView } from "../../../components/RBCardView";
import PDFViewer from "../../../components/PdfViewer";
import { RBModal } from "../../../components/RBModal";


 
interface ShiftInformationProps {
  shiftData: ShiftProps;
}


const renderKMAllowance = (shift: ShiftProps,primaryColor:string) => {
  const { kmAllowance } = shift;
  return (
    <div style={{ flex: 1, alignItems: "flex-start" }}>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          ...titleHeaderTextStyle,
        }}
      >
        {detailKmAllowanceIcon(primaryColor)}
        <div
          style={{
            marginLeft: Spacing.smallest,
          }}
        >
          KM Allowance
        </div>
      </div>

      <div style={detailTextStyle}>
        {"$" + (kmAllowance / 100).toFixed(2) + "/km"}
      </div>
    </div>
  );
};

const renderAccomodationAllowance = (shift: ShiftProps,primaryColor:string) => {
  const { accomodationAllowance } = shift;
  return (
    <div style={{ flex: 1, alignItems: "flex-start" }}>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          ...titleHeaderTextStyle,
        }}
      >
        {detailAccomodationAllowanceIcon(primaryColor)}
        <div style={{ marginLeft: Spacing.smallest }}> Allowance</div>
      </div>

      <div style={detailTextStyle}>
        {"$" + (accomodationAllowance / 100).toFixed(2)}
      </div>
    </div>
  );
};



const renderOnSite = (shift: ShiftProps,secondaryColor:string) => {
  const { technicianOnSite, assistantOnSite, pharmacistType } = shift;
  return (
    <div style={ColumnStyle}>
      {renderTextWithTitleAndChildComponent(
        LabelText.TECHNICIAN_ON_SITE,
        techOnSiteIcon(technicianOnSite,secondaryColor)
      )}
      {pharmacistType !== workerTypeEnum.assistant &&
        renderTextWithTitleAndChildComponent(
          LabelText.ASSISTANT_ON_SITE,
          assistantOnSiteIcon(assistantOnSite,secondaryColor)
        )}
    </div>
  );
};
const renderOwnerSpecific = (shift: ShiftProps) => {
  // const { account, shift } = this.props;
  // if (account.roleName === 'owner') {
  //   return (
  //     <Text style={styles.sectionHeaderText}>{shift.pharmacy.name}</Text>
  //   );
  //}
  return <></>;
};
export const ShiftInformation: React.FC<ShiftInformationProps> = ({
  ...props
}) => {
  const { primaryColor,secondaryColor } = useContext(RBContext);
  const { shiftData } = props;
  
  const { software, specializations } = shiftData.skills.reduce(
    (acc: any, value: any) => {
      if (value.type === "software") acc.software.push(value);
      else acc.specializations.push(value);
      return acc;
    },
    { software: [], specializations: [] }
  );
  const softwareValue =
      software.length > 0
        ? software.map((software:any) =>
            software.required
              ? software.name
              : ShiftService.getUnrequiredSoftwareName(software.name),
          )
        : [];
  const extraSkills =
    specializations.length > 0 ? specializations.map((s: any) => s.name) : [];
  const rxCount =
    typeof shiftData.expectedRxCount === "string"
      ? shiftData.expectedRxCount
      : shiftData.pharmacy
      ? shiftData.pharmacy.rxCount
      : "N/A";

      
  // const wage = (ShiftService.getWage(shiftData) / 100).toFixed(2);
  const { kmAllowance, accomodationAllowance } = shiftData;
  const kmAllowanceAvailable = kmAllowance > 0;
  const accomodationAllowanceAvailable = accomodationAllowance > 0;
  const workerType = formatWorkerType(shiftData.pharmacistType);
  const city = shiftData.shiftCity;
  const startTime = moment(shiftData.officialStartTime).tz(shiftData.shiftTimeZone);
  const endTime = moment(shiftData.officialEndTime).tz(shiftData.shiftTimeZone);
  const hours = ShiftService.getShiftLengthInHours(shiftData);
  const hoursDisplay = ShiftService.getShiftOfficialLengthDisplay(shiftData);
  //const dateString = startTime.format('dddd MMMM Do YYYY');
  const dateString = startTime.format("ddd, MMM Do YYYY");
  const startTimeFormat = startTime ? startTime.format("h:mm a") : "N/A";
  const endTimeFormat = endTime ? endTime.format("h:mm a") : "N/A";
  const shiftDistance = shiftData.shiftDistance;
  const pharmacyName = shiftData.pharmacy.name;
  const commissionType = shiftData.commissionType;
  const actualCommission = shiftData.actualCommission;
  const allowOffers = shiftData.allowOffers;

 
  const [account, setAccount] = useState<any>([]);
  const [wage, setWage] = useState<any>(0);
  const [workerFee, setWorkerFee] = useState<any>(0);
  const [rbCommission, setRbCommission] = useState<any>(0);
  const [showContract,setShowContract] = useState(false);
  const [associationFlag,setAssociationFlag] = useState(false);
  const [associationName,setAssociationName] = useState(null);
  


//   let workerFee = "";
//     let rbCommission = "";
// let wage = "";

useEffect(() => {
  (async () => {
    const userData = await AuthService.loadAccountInfo();
    setAccount(userData);
    if(userData.roleName !== 'owner'){
      if(shiftData?.flags?.isAssigned){
          if(shiftData?.associationId){
            setAssociationFlag(true)
            setAssociationName(shiftData.association?.associationName)
          }
      }
      else{
        if(userData?.userAssociationMember && userData.userAssociationMember.length > 0){
          setAssociationFlag(true)
          setAssociationName(userData?.userAssociationMember[0].association.associationName)

        }
      }
    }
    
   

    if (shiftData.actualCommission) {
      const workerFee = (shiftData.wage / 100).toFixed(2);
      const rbCommission = ShiftService.getPlacementFee(shiftData).toFixed(2);
      const wage = ((shiftData.wage / 100) + ShiftService.getPlacementFee(shiftData)).toFixed(2);
      
      setWorkerFee(workerFee);
      setRbCommission(rbCommission);
      setWage(wage);
    } else {
      const wage = userData.roleName === 'owner' ? (shiftData.wage / 100).toFixed(2) : (ShiftService.getOwnerWage(shiftData, userData) / 100).toFixed(2);
      const rbCommission = (ShiftService.getCommission(shiftData, userData) / 100).toFixed(2);
      
      setWage(wage);
      setRbCommission(rbCommission);
    }
  })();  
  
}, []);




  const onContractClose = () =>{
    setShowContract(false)
  }
  const PaymentStatus = (stripeWebhookEvent:any, shift:any ) => {
    const boxColor =  (stripeWebhookEvent !== null && stripeWebhookEvent.isStripeInvoicePaid ? '#C8E6C9' : '#FFCDD2');
    const textColor = ( stripeWebhookEvent !== null && stripeWebhookEvent.isStripeInvoicePaid ? '#00695C' : '#D32F2F');
  
  
    return (

      <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowWrap: "anywhere",
        alignItems: "flex-start",
        alignContent: "flex-start",
        justifyContent: "flex-start",
        width:100
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          overflowWrap: "anywhere",

          ...titleHeaderTextStyle,
        }}
      >
        Payment Status
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          whiteSpace: "pre-wrap",
          overflowWrap: "anywhere",
          ...detailTextStyle,
        }}
      >
       <div style={{ color: textColor,paddingLeft:13,paddingRight:13,paddingTop:2,paddingBottom:2,textAlign:'center',backgroundColor: boxColor  }}>
        { (stripeWebhookEvent !== null && stripeWebhookEvent.isStripeInvoicePaid ? 'Succeeded' : 'Failed')}
      </div>
      </div>
    </div>

      
    );
  };

  const renderContractContent = (pdfUrl:any) => {
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          userSelect:"none",
          pointerEvents:"none"

        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            <PDFViewer pdfUrl={pdfUrl} title="Shift Contract"></PDFViewer>
          </RBCardView>
       
      </div>
    );
  };

  const currentDate = moment().format('MMM-DD-YYYY');
  const currentYear = moment().year();
  const DATE_FORMAT = 'MMM-DD-YYYY h:mm a';

  const pharmacistDetails = account.pharmacist ? 
        (account.pharmacist.firstName + ' ' + account.pharmacist.lastName): null;
  return (
    <>
      {renderOwnerSpecific(shiftData)}
      
        {(account.roleName === 'owner') ? (showContract && 
         ( shiftData?.locationContractPdf && shiftData.locationContractPdf !== ''?
         <RBModal
           open={showContract}
           onClose={onContractClose}
           modalTitle="Shift Contract"
           modalWidth={700}
           header={
             <RBEmptyBlock
               style={{
                 width: "100%",
                 height: Spacing.large,
                 backgroundColor: Colors.defaultGrey,
                 flexShrink: 0,
               }}
             />
           }
           children={
               renderContractContent(shiftData.locationContractPdf)
           }
         />
         :
        <RBShiftAgreementModal
          open={showContract}
          onClose={onContractClose}
          modalTitle="Shift Contract"
          pageName={shiftData.id}
          pageType="contract"
          networkId = {account.networks[0].id}
         
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
        />)):
        (
          showContract && (shiftData.flags.isAssigned ?

            (shiftData?.workerContractPdf && shiftData.workerContractPdf !== ''?
            <RBModal
              open={showContract}
              onClose={onContractClose}
              modalTitle="Shift Contract"
              modalWidth={700}
              header={
                <RBEmptyBlock
                  style={{
                    width: "100%",
                    height: Spacing.large,
                    backgroundColor: Colors.defaultGrey,
                    flexShrink: 0,
                  }}
                />
              }
              children={
                  renderContractContent(shiftData.workerContractPdf)
              }
            />
            :

            <RBShiftAgreementModal
              open={showContract}
              onClose={onContractClose}
              modalTitle="Shift Contract"
              pageName={shiftData.id}
              pageType="contract"
              networkId = {shiftData.pharmacy.networkId}
            
              header={
                <RBEmptyBlock
                  style={{
                    width: "100%",
                    height: Spacing.large,
                    backgroundColor: Colors.defaultGrey,
                    flexShrink: 0,
                  }}
                />
              }
            />):
            <RBShiftAgreementModal
              open={showContract}
              onClose={onContractClose}
              modalTitle="Shift Contract"
              pageName="contract-pharmacist"
                pageType="page"
                networkId = {shiftData.pharmacy.networkId}
                shiftId = {shiftData.id}
                userId= { account.id }
                type = 'shift'
                substitutions={{
                  currentDate,
                  currentYear,
                  endTime: moment(shiftData.endTime)
                    .tz(shiftData.shiftTimeZone)
                    .format(DATE_FORMAT),
                  pharmacistDetails: pharmacistDetails,
                  pharmacyDetails: '[Pharmacy]',
                  shiftNumber: `xxxx-${currentYear}-x-xx`,
                  startTime: moment(shiftData.startTime)
                    .tz(shiftData.shiftTimeZone)
                    .format(DATE_FORMAT),
                  wage: shiftData.actualCommission ? `$${workerFee}/hr` : `$${wage}/hr`,
                  pharmacistSignature: '',
                  pharmacySignature: '',
                  licenseNumber: '',
                  accredidationNumber: '',
                  pharmacistName: pharmacistDetails,
                  reliefBuddySignature: '',
                  workerType: formatWorkerType(shiftData.pharmacistType),

                  kmAllowance: kmAllowanceAvailable
                    ? `$${(shiftData.kmAllowance / 100).toFixed(2)}`
                    : 0,
                  accomodationAllowance: accomodationAllowanceAvailable
                    ? `$${(shiftData.accomodationAllowance / 100).toFixed(2)}`
                    : 0,
                }}
              header={
                <RBEmptyBlock
                  style={{
                    width: "100%",
                    height: Spacing.large,
                    backgroundColor: Colors.defaultGrey,
                    flexShrink: 0,
                  }}
                />
              }
            />
          )
        )}
      
      {account.roleName === 'owner' &&<> 
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          //paddingTop: Spacing.smaller,
          paddingBottom: Spacing.medium,
        }}>
          <div style={{ display: "flex", flex: 1, alignItems: "baseline" }}>
            <div className={"section-header-text"}>Pharmacy</div>
            <div
              style={{
                fontWeight: "normal",
                fontSize: FontSize.default,
                marginLeft: Spacing.small,
              }}
            >
              {shiftData.pharmacy.name}
            </div>

          </div>
          
        </div>
      <br />
        </>}


     {account.roleName === 'owner' && 
      <div style={ColumnStyle}>

        {shiftData.paymentType !== null && RenderTextWithTitle("Payment Type", formatPaymentType(shiftData.paymentType))}
        {shiftData.flags.isCompletionAcknowledged === true && shiftData.stripeWebhookEvent!== null && PaymentStatus(shiftData.stripeWebhookEvent,shiftData)}
      </div>}
      <div style={ColumnStyle}>
      {RenderTextWithTitle("Shift", shiftData.shiftNumber)}
      {RenderTextWithTitle("Worker Type", workerType)}
      </div>

      <div style={ColumnStyle}>
        {RenderTextWithTitle("Date", dateString)}
        {RenderTextWithTitle("Time", `${startTimeFormat} - ${endTimeFormat}`)}
      </div>
      <div style={ColumnStyle}>
        {RenderTextWithTitle("Duration", hoursDisplay)}

          <>
            {( shiftData.disputeResolution === 'pharmacy' && shiftData.disputeUnpaidBreakMinutes) ?
            RenderTextWithTitle('Unpaid Breaks (Min)', shiftData.disputeUnpaidBreakMinutes+' mins') :
            ((shiftData.disputeResolution === 'pharmacist' || shiftData.disputeResolution === null ) && shiftData.unpaidBreakMinutes) ?
            RenderTextWithTitle('Unpaid Breaks (Min)', shiftData.unpaidBreakMinutes+' mins')  : 
              (<div style={{ flex: 1, alignItems: "flex-start" }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    ...titleHeaderTextStyle,
                  }}
                >

                  <div
                    style={{
                      marginLeft: Spacing.smallest,
                    }}
                  >
                    Hourly Rate
                  </div>
                  {allowOffers && defaultAllowOffersIcon(secondaryColor)}
                </div>


                <div style={detailTextStyle}>
                  {"$" + (shiftData.actualCommission ? workerFee : wage)}
                </div>

              </div>)
          }
          </>
        {/* {RenderTextWithTitle("Hourly Rate", "$" + (shiftData.actualCommission ? workerFee: wage))}
        <div style={{paddingTop:2}}>
          {allowOffers && defaultAllowOffersIcon(secondaryColor)}
        </div> */}
      </div>

      {((shiftData.disputeResolution === 'pharmacy' && shiftData.disputeUnpaidBreakMinutes) || ((shiftData.disputeResolution === 'pharmacist' || shiftData.disputeResolution === null) && shiftData.unpaidBreakMinutes !== null)) &&  
        <div style={ColumnStyle}>
          <div style={{ flex: 1, alignItems: "flex-start" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                ...titleHeaderTextStyle,
              }}
            >

              <div
                style={{
                  marginLeft: Spacing.smallest,
                }}
              >
                Hourly Rate
              </div>
              {allowOffers && defaultAllowOffersIcon(secondaryColor)}
            </div>


            <div style={detailTextStyle}>
              {"$" + (shiftData.actualCommission ? workerFee : wage)}
            </div>

          </div>
        </div>
      }
      {(kmAllowanceAvailable || accomodationAllowanceAvailable) && (
        <div style={ColumnStyle}>
          {kmAllowanceAvailable && renderKMAllowance(shiftData,primaryColor)}
          {accomodationAllowanceAvailable &&
            renderAccomodationAllowance(shiftData,primaryColor)}
        </div>
      )}


      {associationFlag && shiftData.actualCommission && !shiftData.flags?.isCompletionAcknowledged  && account.roleName !== 'owner' && <div
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderWidth: 1,
          borderColor: "red",

        }}
      >
        <div style={{ flex: 1, alignItems: 'flex-start' }}>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: Spacing.medium,
              color: 'red',
            }}
          >
            *The Hourly Rate excludes your {associationName} membership benefit which is added upon shift completion.
          </div>
        </div>
      </div>
      }



      <div style={ColumnStyle}>

      {(account.roleName === 'owner') && shiftData.actualCommission  &&  
      <RBPlacementFeeModal title={"Placement Fee"} detail={"$"+rbCommission} commissionType={commissionType}/>}
        {RenderTextWithTitle("City", city)}
      </div>

      {renderOnSite(shiftData,secondaryColor)}

      <div style={ColumnStyle}>
        
        {RenderTextWithTitle("Distance", Math.round(shiftDistance / 1000) + 'km')}
        {renderTextWithTitleForSkills("Software", softwareValue)}
        
      </div>
      <div style={ColumnStyle}>

       
        {RenderTextWithTitle(LabelText.DailyAvgRxCount, rxCount)}
        {renderTextWithTitleForSkills("Specializations", extraSkills)}
      </div>
      
    
      <div style={ColumnStyle}>

        {RenderTextWithTitle("Comment", shiftData.comment || "N/A")}
        {renderTextWithTitleAndChildComponent(
          `${LabelText.SHIFT_AGREEMENT}`,
          <Link style={{color:primaryColor,fontWeight:"bold", cursor: 'pointer' }} onClick={() => setShowContract(true)}>View</Link>
        )}
      </div>
      <div
        style={{
          height: 1,
          backgroundColor: Colors.defaultGrey,
          marginTop: Spacing.medium,
          marginBottom: Spacing.medium,
        }}
      />
    </>
  );
};
