import { ShiftProps } from "../interfacesProps/ShiftProps";
import React,{useContext} from "react";
import { Button } from "@material-ui/core";
import { ShiftService } from "../api/ShiftService";
import {
  ShiftRelatedConstants,
  ShiftRelatedStylingConstants,
} from "../util/ShiftConstants";
import { makeStyles } from "@material-ui/core/styles";
import { roleNameEnum, NotAvailableString } from "../util/Common";
import { UIRelatedConstants } from "../theme/Theme";
import { ShiftListContext } from "./ShiftListContext";
import { RBButton } from "./RBButton";
import { RBContext } from './RBContext';

interface RBShiftLayoutButtonProps {
  shift: ShiftProps;
  rolename: string;
}

const useStyles = makeStyles({
  root: {
    minWidth: 475,
    marginLeft: -250,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  CardContent: {
    paddingTop: 8,
    "&:last-child": {
      paddingBottom: 8,
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    borderRadius: UIRelatedConstants.ButtonBorderRadius,
    borderWidth: 1,
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#CDCDCD",
    },
  },
});

export const RBShiftLayoutButton: React.FC<RBShiftLayoutButtonProps> = ({
  ...props
}) => {
  const { shift, rolename } = props;
  //const shiftListContext = React.useContext(ShiftListContext);
  const { onAction } = React.useContext(ShiftListContext);

  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  //console.log("RBShiftLayoutButton ", onAction);

  const renderShiftLayoutButtonForWorker = () => {
    console.log("props1",props)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const classes = useStyles();
    const shiftState = ShiftService.getStatusForShiftAsWorker(shift,rolename);
    console.log("shiftState",shiftState)
    let buttonText = NotAvailableString;
    let borderColor, isButtonVisible;
    
    switch (shiftState) {
      case ShiftRelatedConstants.ShiftStateText.AwaitingHiring:
        buttonText = ShiftRelatedConstants.ShiftButtonText.WithdrawShift;
        borderColor = primaryColor;
        isButtonVisible = true;

        break;
      case ShiftRelatedConstants.ShiftStateText.AwaitingWorkerConfirmation:
        buttonText = ShiftRelatedConstants.ShiftButtonText.ConfirmHours;
        borderColor = ShiftRelatedStylingConstants.Colors[shiftState];
        isButtonVisible = true;

        break;
      case ShiftRelatedConstants.ShiftStateText.FeedbackShift:
        buttonText = ShiftRelatedConstants.ShiftButtonText.FeedbackShift;
        borderColor = primaryColor;
        isButtonVisible = true;
        break;

          
      default:
        isButtonVisible = false;
      //console.log("shift status is default");
    }

    return (
      <div>
        {isButtonVisible && (
          <Button
            className={classes.button}
            variant="outlined"
            style={{
              borderColor: borderColor,
              color: borderColor,
              textTransform: "none",
            }}
            onClick={() => onAction(buttonText, shift)}
          >
            {buttonText}
          </Button>
        )}
      </div>
    );
  };

  const renderShiftLayoutButtonForPharmacy = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const classes = useStyles();
    const shiftState = ShiftService.getStatusForShiftAsPharmacy(shift,rolename);
    let buttonText = NotAvailableString;
    let borderColor, isButtonVisible;
    switch (shiftState) {
      case ShiftRelatedConstants.ShiftStateText.AwaitingHiring:
        if (shift.flags.isEditable) {
          buttonText = ShiftRelatedConstants.ShiftButtonText.EditShift;
          borderColor = primaryColor;
          isButtonVisible = true;
        } else {
          buttonText = ShiftRelatedConstants.ShiftButtonText.ViewApplications;
          borderColor = primaryColor;
          isButtonVisible = true;
        }

        break;
      case ShiftRelatedConstants.ShiftStateText.AwaitingHoursConfirmation:
        buttonText = ShiftRelatedConstants.ShiftButtonText.ConfirmHours;
        borderColor = ShiftRelatedStylingConstants.Colors[shiftState];
        isButtonVisible = true;

        break;
      case ShiftRelatedConstants.ShiftStateText.RateShift:
        buttonText = ShiftRelatedConstants.ShiftButtonText.RateShift;
        borderColor = ShiftRelatedStylingConstants.Colors[shiftState];
        isButtonVisible = true;

        break;
      case ShiftRelatedConstants.ShiftStateText.FeedbackShift:
        buttonText = ShiftRelatedConstants.ShiftButtonText.FeedbackShift;
        borderColor = primaryColor;
        isButtonVisible = true;
        break;

      default:
        isButtonVisible = false;
    }
    return (
      <div>
        {isButtonVisible && (
          <Button
            className={classes.button}
            variant="outlined"
            style={{
              borderColor: borderColor,
              color: borderColor,
              textTransform: "none",
            }}
            onClick={() => onAction(buttonText, shift)}
          >
            {buttonText}
          </Button>
        )}
      </div>
    );
  };

  return (
    <div>
      {rolename === roleNameEnum.pharmacist &&
        renderShiftLayoutButtonForWorker()}

      {rolename === roleNameEnum.owner && renderShiftLayoutButtonForPharmacy()}
    </div>
  );
};
