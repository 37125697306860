import React, { useEffect, useState } from "react";
import { DashboardPage } from "./DashboardPage";
import { AuthService } from "../../api/AuthService";

export const DashboardController = () => {
  const [userName, setUserName] = useState("");
  const [roleName, setRoleName] = useState("");
  useEffect(() => {
    const roleName = AuthService.getRoleName();
    setRoleName(roleName ?? "");
    setUserName(roleName ?? "");
  }, []);

  return <DashboardPage userName={userName} roleName={roleName} />;
};
