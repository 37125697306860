import React, { useEffect, useState, useContext } from 'react';
import { formatWorkerType } from "../../util/FormattingService";
import { Typography, IconButton ,Select,MenuItem, colors, makeStyles,Grid,Box, Link  } from "@material-ui/core";
import { Colors, Spacing, AppContainerWidth, Font, FontSize, FontWeight} from "../../theme/Theme";
import { RBCardView } from "../../components/RBCardView";
import { RBButton } from "../../components/RBButton";
import { useHistory, useLocation } from "react-router";
import { RoutePathConstant } from "../../util/Common";
import { workerTypeEnum } from "../../util/Enumeration";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AuthService } from "../../api/AuthService";


const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: Spacing.larger,
    marginLeft: 3 * Spacing.larger,
    marginRight: 3 * Spacing.larger,
  },
  card: {
    padding: Spacing.larger,
    maxWidth: 700,
    width: "100%",
    display: 'flex',          // Ensure card container uses flexbox
    flexDirection: 'column',  // Align items in a column
    alignItems: 'center', 
    [theme.breakpoints.down('sm')]: {
      padding: Spacing.medium,
    },
  },
  logo: {
    display: "flex",
    flex: 1,
    maxWidth: "100%",
    maxHeight: "100%",
    paddingLeft: 3 * Spacing.large,
    paddingRight: 3 * Spacing.large,
    paddingTop: Spacing.large,
    paddingBottom: Spacing.large,
    marginBottom: 2 * Spacing.large,
    margin: '0 auto', 
  },
  subText: {
    textAlign: "center",
    fontSize: Font.large,
     [theme.breakpoints.down('sm')]: {
      paddingLeft: 3 * Spacing.medium,
    paddingRight: 3 * Spacing.medium,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 2 * Spacing.larger,
    marginBottom: Spacing.larger,
    paddingLeft: 3 * Spacing.large,
    paddingRight: 3 * Spacing.large,
    paddingBottom : 3 * Spacing.large,
    '@media (max-width: 768px)': {
        flexDirection: "column",
        alignItems: "center",
        '& > *:not(:last-child)': {
            marginBottom: Spacing.medium,
        },
    },
    '@media (min-width: 769px)': {
        '& > *:not(:last-child)': {
            marginRight: Spacing.medium,  // Add margin-right between buttons on larger screens
        },
    },
},
button: {
    minWidth: 200,
    paddingLeft: Spacing.large,
    paddingRight: Spacing.large,
    paddingTop: Spacing.medium,
    paddingBottom: Spacing.medium,
    marginBottom: Spacing.medium,
    '@media (max-width: 768px)': {
        width: "100%",
    },
},
 
}));
export const RegisterSuccessPage = () => {
  const classes = useStyles();
  console.log("RegisterSuccessPage");
  const history = useHistory();
  const logo = localStorage.getItem("registerSuccessLogo");
  const networkName = localStorage.getItem("registerNetwork");
  //const primaryColor = JSON.stringify(localStorage.getItem("primaryColor"));
  const defaultLogo = ("./../register/RB_new_logo.png")
  const parsedLogo = logo ? JSON.parse(logo) : defaultLogo;
  const logoSource = parsedLogo ? parsedLogo : defaultLogo;

  const onGoBack = () => {
      history.push("/signIn");
  }
  const location = useLocation<{
    email: any;
    networkDetails:any;
  }>();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: "100vh", width: "100%" }}
    >
      <Grid item xs={12} sm={10} md={8} lg={6} style={{width:"100%"}}>
        <RBCardView className={classes.card}>
          <img
            src={logoSource}
            alt="logo"
            className={classes.logo}
          />
           <Typography className={classes.subText}>Thank you for registering with {networkName}!</Typography>
          <Typography className={classes.subText}>
          To complete your registration, please verify your email address. We've sent a verification email—just click 'accept' to confirm.
          </Typography>
          <Typography className={classes.subText} >
          If you do not see the email in your inbox, please check your Junk/Spam folders and add us to your Contacts List to ensure future emails reach you.
          </Typography>
          <Typography className={classes.subText}>
         If you are having trouble, please contact us at {location.state.networkDetails.contactEmail} for assistance.
          </Typography>
          <Typography className={classes.subText}>
         We look forward to serving you here on Relief Buddy!
          </Typography>


          <div className={classes.buttonContainer}>
        <RBButton
            style={{
              minWidth: 200,
              paddingLeft: Spacing.large,
              paddingRight: Spacing.large,
              paddingTop: Spacing.medium,
              paddingBottom: Spacing.medium,
            }}
            buttonBackgroundColor={Colors.themeDarkBlue}
            onClick={() => onGoBack()}
            text="Close Window"
            variant="contained"
            type="submit"
        />
        <RBButton
            style={{
              minWidth: 200,
              paddingLeft: Spacing.large,
              paddingRight: Spacing.large,
              paddingTop: Spacing.medium,
              paddingBottom: Spacing.medium,
            }}
            buttonBackgroundColor={Colors.themeDarkBlue}
            onClick={() => {
              history.push(`${RoutePathConstant.Login}`,{email:location.state.email})
            }}
            text="Continue to Login"
            variant="contained"
            type="submit"
        />
      </div>
        </RBCardView>
      </Grid>
    </Grid>
  );
};
