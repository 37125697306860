import React, { useState } from "react";
import {
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  Link,
} from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RBTextFieldForForm } from "../../../components/RBTextField";
import { Spacing, Colors } from "../../../theme/Theme";
import { RBButton } from "../../../components/RBButton";
import { EmptyString } from "../../../util/Common";

import { RBExternalHelpLink } from "../../../components/RBExternalHelpLink";
import { HelperPageTitleEnum } from "../../../util/Enumeration";
import { Schema } from "../YupSchema";
import { RBShiftAgreementModal } from "../../../components/RBShiftAgreementModal";
import { RBEmptyBlock } from "../../../components/RBEmptyBlock";

export interface AdditionalCompanyInformationValueTypes {
  companyWebsite?: string;
  companyOverview?: string;
  companyServices?: string;
  companyBenefits?: string;
  agreePrivacyPolicy: boolean;
}
interface AdditionalCompanyInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: AdditionalCompanyInformationValueTypes;
  account?: any;
}

export const AdditionalCompanyInformation: React.FC<AdditionalCompanyInformationProps> = ({
  ...props
}) => {
  const [showTermOfService, setShowTermOfService] = useState(false) 
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false) 

  const setShowTermAndService = (showTermOfService:boolean) =>
    setShowTermOfService(showTermOfService);

  const setShowPrivacyAndPolicy = (showPrivacyPolicy:boolean) =>
  setShowPrivacyPolicy(showPrivacyPolicy);

  const onTermsClose = () =>{
    setShowTermOfService(false);
    }
  const onPrivacyClose = () =>{
    setShowPrivacyPolicy(false);
    }
  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={Schema.AdditionalCompanyInformation}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={Schema.AdditionalCompanyInformation.isValidSync(
        props.initialValues
      )}
      onSubmit={() => {}}
    >
      {({
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        setFieldValue,
      }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1" style={{ marginBottom: Spacing.small }}>
              Additional Company Information
            </Typography>
            {/* <RBButton
              text={"Same As Account Owner"}
              onClick={() => {
                setFieldValue(
                  "designatedManagerFirstName",
                  props.initialValues.ownerFirstName,
                  true
                );
                setFieldValue(
                  "designatedManagerLastName",
                  props.initialValues.ownerLastName,
                  true
                );
                setFieldValue(
                  "designatedManagerPhoneNumber",
                  props.initialValues.ownerPhoneNumber,
                  true
                );
              }}
              buttonBackgroundColor={Colors.themeGreen}
              style={{ marginBottom: Spacing.large }}
            /> */}
          </div>
          <RBTextFieldForForm
            label="Company Website"
            name="companyWebsite"
            value={values.companyWebsite}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            label="Company Overview"
            name="companyOverview"
            value={values.companyOverview}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            label="Company Services"
            name="companyServices"
            value={values.companyServices}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Company Benefits"
            name="companyBenefits"
            value={values.companyBenefits}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={values.agreePrivacyPolicy}
              style={{
                padding: 0,
                paddingRight: Spacing.large,
                // Why marginLeft -2, so  it will align with regular label or text field
                marginLeft: -2,
                color: values.agreePrivacyPolicy
                  ?  localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue
                  : Colors.darkGrey,
              }}
              onChange={(_, checked) => {
                setFieldValue("agreePrivacyPolicy", checked, true);
              }}
            />
            <Typography variant="body2">
              I have read and understood the{" "}
              <Link onClick={() => setShowTermAndService(true)}  style={{color: localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue, cursor: 'pointer'}}>{HelperPageTitleEnum.termOfService}{" "}
            </Link>{" "}
            and{" "}
            <Link onClick={() => setShowPrivacyAndPolicy(true)} style={{color: localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue, cursor: 'pointer'}}>{HelperPageTitleEnum.privacyPolicy}
            </Link>.
            </Typography>
          </div>
          {showTermOfService && <RBShiftAgreementModal
            open={showTermOfService}
            onClose={onTermsClose}
            modalTitle={"Terms of Service"}
            pageName="terms-of-use"
            pageType="page"
            networkId= ""
            header={
              <RBEmptyBlock
                style={{
                  width: "100%",
                  height: Spacing.large,
                  backgroundColor: Colors.defaultGrey,
                  flexShrink: 0,
                }}
              />
            }
          />
          }
          {showPrivacyPolicy && <RBShiftAgreementModal
            open={showPrivacyPolicy}
            onClose={onPrivacyClose}
            modalTitle={"Privacy Policy"}
            pageName="privacy-policy"
            pageType="page"
            networkId= ""
            header={
              <RBEmptyBlock
                style={{
                  width: "100%",
                  height: Spacing.large,
                  backgroundColor: Colors.defaultGrey,
                  flexShrink: 0,
                }}
              />
            }
          />
          }
          <ErrorMessage
            component="div"
            name="agreePrivacyPolicy"
            className="invalid-feedback"
          >
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre>
          <pre>{JSON.stringify(errors, null, 2)}</pre> */}
        </Form>
      )}
    </Formik>
  );
};
