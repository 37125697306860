import React, { useEffect, useState, createContext,useContext } from "react";
import {
  withRouter,
  RouteComponentProps,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
import {
  Grid,
  GridList,
  GridListTile,
  Paper,
  List,
  ListItem,
} from "@material-ui/core";
import { ShiftProps } from "../../interfacesProps/ShiftProps";
import { makeStyles } from "@material-ui/core/styles";
import { RBShiftLayoutCard } from "../../components/RBShiftLayoutCard";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Colors, Spacing } from "../../theme/Theme";
import { RoutePathConstant, roleNameEnum } from "../../util/Common";
import { RBPopover } from "../../components/RBPopOver";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { ShiftListContext } from "../../components/ShiftListContext";
import { ShiftRelatedConstants } from "../../util/ShiftConstants";
import { ShiftService } from "../../api/ShiftService";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { useLastLocation } from "react-router-last-location";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum } from "../../api/constants";
import { AuthService } from "../../api/AuthService";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { RBCardView } from "../../components/RBCardView";
import { formatWorkerType } from "../../util/FormattingService";
import { RBTextField } from "../../components/RBTextField";
import { PharmacyService } from "../../api/PharmacyService";
import { LocationApplicantsPage } from "../LocationApplicants/LocationApplicantsPage";


interface ShiftListPageWithoutRouteProps extends RouteComponentProps<any> {
  shifts?: ShiftProps[];
  roleName: string;
  applicants?: any;
  pharmacy?: any;
}

const ShiftListPageWithoutRoute: React.FC<ShiftListPageWithoutRouteProps> = ({
  history,
  match,
  ...props
}) => {
  const { shifts, roleName ,applicants, pharmacy} = props;
  const {industryMode, primaryColor,secondaryColor,updateprimaryColor,updateSecondaryColor,updateContactEmail,updateContactPhone,updateMode } = useContext(RBContext);

  const [isAlertShowing, setShiftAlertShowing] = useState(false);
  const [shiftAlertInfo, setShiftAlertInfo] = useState<AlertInfoProps>({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const location = useLocation<{ selectedShiftId?: string }>();
  const lastLocation = useLastLocation();
  const [account, setAccount] = useState<any>([]);

  const [blockUserDialogVisible, setBlockUserDialogVisible] = useState(false);
  const [blockUserComment, setBlockUserComment] = useState('');


  // when there is a need to talk to API
  const onActionRelatedToAPI = async (buttonText: string, shiftId: string) => {
    try {
      setShiftAlertShowing(false);
      setIsButtonLoading(true);

      if (roleName === roleNameEnum.owner) {
        switch (buttonText) {
          case ShiftRelatedConstants.ShiftButtonText.CancelShift: {
            await ShiftService.cancelShiftById(shiftId);
            break;
          }
        }
      } else if (roleName === roleNameEnum.pharmacist) {
        switch (buttonText) {
          case ShiftRelatedConstants.ShiftButtonText.CancelShift: {
            await ShiftService.cancelShiftById(shiftId);
            break;
          }
          case ShiftRelatedConstants.ShiftButtonText.WithdrawShift: {
            console.log("worker  WithdrawShift");
            await ShiftService.withdrawRequestedShiftById(shiftId);
            break;
          }
        }
      }
      NotificationService.publish(
        NotificationServiceTopic.ShiftChanged,
        shiftId
      );
    } catch (err) {
      setSubmitErrorMessage(err.response ? err.response.data : err.message);
      console.log("onActionRelatedToAPI error ", err);
    } finally {
      console.log("onActionRelatedToAPI finally ");
      setIsButtonLoading(false);
    }
  };

  const OnShiftListButtonClick = (buttonText: string, shiftData: any) => {
    console.log("OnShiftListButtonClick ", buttonText, shiftData);
    if (roleName === roleNameEnum.owner) {
      switch (buttonText) {
        case ShiftRelatedConstants.ShiftButtonText.ViewApplications:
          history.push(RoutePathConstant.ViewApplicant + shiftData.id);
          break;
        case ShiftRelatedConstants.ShiftButtonText.ConfirmHours:
          history.push(RoutePathConstant.ShiftConfirmHour + shiftData.id);
          break;
        case ShiftRelatedConstants.ShiftButtonText.RateShift:
          history.push(RoutePathConstant.ShiftSurvey + shiftData.id);
          break;
        case ShiftRelatedConstants.ShiftButtonText.EditShift:
          industryMode === IndustryModeEnum.Pharmaceutical ? history.push(RoutePathConstant.EditShift + shiftData.id) : history.push(RoutePathConstant.EditeDentalShift + shiftData.id);
          break;
        case ShiftRelatedConstants.ShiftButtonText.FeedbackShift:
          setBlockUserDialogVisible(true);
          break;
          
      }
    } else if (roleName === roleNameEnum.pharmacist) {
      switch (buttonText) {
        case ShiftRelatedConstants.ShiftButtonText.ConfirmHours:
          history.push(RoutePathConstant.ShiftConfirmHour + shiftData.id);
          break;

        case ShiftRelatedConstants.ShiftButtonText.WithdrawShift: {
          setShiftAlertInfo({
            title: "Withdraw from Shift",
            message: "Are you sure you wish to withdraw from this shift?",
            buttons: [
              <RBButton
              key={"confirm"}
              onClick={async () => {
                await onActionRelatedToAPI(
                  ShiftRelatedConstants.ShiftButtonText.WithdrawShift,
                  shiftData.id
                );
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Yes"
            />,
              <RBButton
                key={"cancel"}
                buttonType={RBButtonType.cancel}
                onClick={() => {
                  setShiftAlertShowing(false);
                }}
                text="No"
              />,
             
            ],
          });
          setShiftAlertShowing(true);
          break;
        }
        case ShiftRelatedConstants.ShiftButtonText.FeedbackShift:
          setBlockUserDialogVisible(true);
          break;
      }
    }
  };

  const onClickShiftCard = (shiftId: string) => {
    const item = document.querySelector(".restore-" + shiftId);

    history.push(`${RoutePathConstant.ShiftDetail}${shiftId}`, {
      shiftId: shiftId,
      scrollY: window.scrollY,
    });
    console.log(
      "shift onClickShiftCard id ",
      shiftId,
      window.scrollY,
      item?.getBoundingClientRect()
    );
  };
  // const onBackButtonEvent = (e: any) => {
  //   console.log("shift list onBackButtonEvent", e);
  //   console.log("shift list onBackButtonEvent location state", location);
  // };
  //window.onpopstate = onBackButtonEvent;

  useEffect(() => {

    (async () => {
      await updateprimaryColor( localStorage.getItem('primaryColor')); 
      await updateSecondaryColor( localStorage.getItem('secondaryColor')); 
      await updateContactEmail( localStorage.getItem('contactEmail')); 
      await updateContactPhone( localStorage.getItem('contactPhone')); 
      await updateMode(localStorage.getItem('industryMode')); 
      const userData = await AuthService.loadAccountInfo();
      setAccount(userData);
  })(); 
  
    console.log("shift list last location ", lastLocation);
    if (lastLocation?.state) {
      const item = document.querySelector(
        ".restore-" +
          (lastLocation?.state as { shiftId?: string; scrollY?: number })
            .shiftId
      );

      if (item) {
        console.log("item1", item);
        //  item.scrollIntoView({ block: "center" });
        //  item.scrollIntoView();
        //      item.scrollBy({ top: -70 });
        const scrollY =
          (lastLocation?.state as {
            shiftId?: string;
            scrollY?: number;
          }).scrollY ?? 0;
        // window.scrollTo({
        //   top: (lastLocation?.state as { shiftId?: string; scrollY?: number })
        //     .scrollY,
        //   behavior: "smooth",
        // });
        window.scrollTo(555.5, scrollY);
        console.log("item exist pharmacy ");
      } else {
        console.log(`item does not exist`);
        window.scrollTo(200, 0);
      }
    }
  }, []);
  const onClose = () => {
    setBlockUserDialogVisible(false);
  }

  const  onBlockUser = async (shiftData:any) => {
    try {
      await AuthService.blockUser({
        comment: blockUserComment.length > 0 ? blockUserComment : null,
        shiftId: shiftData.id,
      });
      let payload = {};
      if(roleName === roleNameEnum.owner)
      {
        payload = {
          id: shiftData.id,
          locationFeedbackGiven: true
        }
      }else{
        payload = {
          id: shiftData.id,
          workerFeedbackGiven: true
        }
      }
      await PharmacyService.updateShiftFeedback(payload)
    } catch (error) {
      console.log('onBlockUser ', error);
    }

    setBlockUserDialogVisible(false);
    history.goBack();
  };

  const  onShiftFeedback = async (shiftData:any) => {
    try {
      let payload = {};
      if(roleName === roleNameEnum.owner)
      {
        payload = {
          id: shiftData.id,
          locationFeedbackGiven: true
        }
      }else{
        payload = {
          id: shiftData.id,
          workerFeedbackGiven: true
        }
      }
      await PharmacyService.updateShiftFeedback(payload)
    } catch (error) {
      console.log('onBlockUser ', error);
    }

    setBlockUserDialogVisible(false);
    history.goBack();
  };

  const renderCreateShiftContent = (shiftData:any) => {
    console.log("shiftData",shiftData)
    if (roleName === roleNameEnum.owner)
    {
      return (
        <div
          style={{
            backgroundColor: Colors.defaultGrey,
            display: "flex",
            flex: 1,
          }}
        >
          
            <RBCardView
              style={{
                flex: 1,
                marginLeft: Spacing.large,
                marginRight: Spacing.large,
                marginBottom: Spacing.smallest,
                paddingLeft: Spacing.large,
                paddingTop: Spacing.large,
                paddingRight: Spacing.large,
                paddingBottom: Spacing.large,
              }}
            >
            <div>
              How did the shift go with {shiftData?.pharmacist?.pharmacist?.firstName} {shiftData?.pharmacist?.pharmacist?.lastName}?
              Would you consider hiring this {formatWorkerType(shiftData.pharmacistType)} again?
            </div>
            <br></br>
            <div>
              <RBTextField
                style={{
                  display: "flex",
                  flex: 1,
                  paddingTop: Spacing.small,
                  paddingBottom: Spacing.small,
                }}
                placeholder={"*Please leave a comment if you decide no."}
                value={blockUserComment}
                onChange={(event) => setBlockUserComment(event.target.value)}
                multiline
                rows={3}
                rowsMax={8}
              />
            </div>
            <br></br>
            <div style={{color:'red'}}>
              *If you select "No", this {formatWorkerType(shiftData.pharmacistType)} will not be matched with any future shifts/jobs you
                post for this office or any of the other offices on your profile.
            </div>
            <div style={{
              display: "flex",
              flex: 1,
              alignItems: "flex-end",
            }}>
                  <RBButton
                    buttonBackgroundColor={primaryColor}
                    isLoading={isButtonLoading}
                    disabled={blockUserComment.length === 0}
                    text="No"
                    onClick={() => onBlockUser(shiftData)}
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: Spacing.medium,
                      margin: Spacing.large,
                    }}
                  />
                  <RBButton
                    buttonBackgroundColor={primaryColor}
                    isLoading={isButtonLoading}
                    text="Yes"
                    onClick={() => onShiftFeedback(shiftData)}
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: Spacing.medium,
                      margin: Spacing.large,
                    }}
                  />
            </div>
            </RBCardView>
        
        </div>
      );
    }
    else{
      return (
        <div
          style={{
            backgroundColor: Colors.defaultGrey,
            display: "flex",
            flex: 1,
          }}
        >
          
            <RBCardView
              style={{
                flex: 1,
                marginLeft: Spacing.large,
                marginRight: Spacing.large,
                marginBottom: Spacing.smallest,
                paddingLeft: Spacing.large,
                paddingTop: Spacing.large,
                paddingRight: Spacing.large,
                paddingBottom: Spacing.large,
              }}
            >
             <div>
             How did your shift go with {shiftData.pharmacy.name}? Would you consider
              working at this {shiftData.pharmacy.companyType} again?
             </div>
             <br></br>
             <div>
              <RBTextField
                style={{
                  display: "flex",
                  flex: 1,
                  paddingTop: Spacing.small,
                  paddingBottom: Spacing.small,
                }}
                placeholder={"*Please leave a comment if you decide no."}
                value={blockUserComment}
                onChange={(event) => setBlockUserComment(event.target.value)}
                multiline
                rows={3}
                rowsMax={8}
              />
             </div>
             <br></br>
             <div style={{color:'red'}}>
             *If you select "No", you will never match with that dental practice's
              shifts/jobs again.
             </div>
             <div
             style={{
              display: "flex",
              flex: 1,
              alignItems: "flex-end",
            }}>
                  <RBButton
                    buttonBackgroundColor={primaryColor}
                    isLoading={isButtonLoading}
                    disabled={blockUserComment.length === 0}
                    text="No"
                    onClick={() => onBlockUser(shiftData)}
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: Spacing.medium,
                      margin: Spacing.large,
                    }}
                  />
                  <RBButton
                    buttonBackgroundColor={primaryColor}
                    isLoading={isButtonLoading}
                    text="Yes"
                    onClick={() => onShiftFeedback(shiftData)}
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: Spacing.medium,
                      margin: Spacing.large,
                    }}
                  />
             </div>
            </RBCardView>
         
        </div>
      );
    }
  };
  
  const renderBlockUserDialog = (shift:any) => {
    
    return (

      <RBModal
          open={blockUserDialogVisible}
          onClose={onClose}
          modalTitle={"Shift Feedback"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              renderCreateShiftContent(shift)
          }
        />

    );
  };

  return (
    <>
     
      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={JSON.stringify(submitErrorMessage, null, 2)}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      />
      <RBAlert
        show={isAlertShowing}
        alertTitle={shiftAlertInfo.title}
        alertMessage={shiftAlertInfo.message}
        buttons={shiftAlertInfo.buttons}
      />
      <ShiftListContext.Provider
        value={{
          onAction: OnShiftListButtonClick,
          isButtonLoading: isButtonLoading,
        }}
      >
        <div style={{ paddingTop: Spacing.large }}>
          {shifts?.map((shift) => (
            <div key={shift.id} className={`restore-${shift.id}`}>
              {renderBlockUserDialog(shift)}
              <RBShiftLayoutCard
                shift={shift}
                onClick={onClickShiftCard}
                rolename={roleName}
                account = {account}
              />
            </div>
          ))}
          {applicants !== undefined && applicants.length > 0 &&
              <LocationApplicantsPage applicants={applicants} pharmacy={pharmacy}/>
          }
        </div>
      </ShiftListContext.Provider>
    </>
  );
};

export const ShiftListPage = withRouter(ShiftListPageWithoutRoute);
