
import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";
import { RBButton } from "../../components/RBButton";
import { RBCardView } from "../../components/RBCardView";
import { Font, Spacing } from "../../theme/Theme";
import { IndustryModeEnum, RoutePathConstant, roleNameEnum, getAlgorithm, getEncryptionKey, getEncryptionIV } from "../../util/Common";
import CustomButton from "../../components/CustomButton";
import { AuthService } from "../../api/AuthService";
import StatBlock from "../../components/StatBlock";
import { RBContext } from "../../components/RBContext";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { RBAlert } from "../../components/RBAlert";
import { ShiftService } from "../../api/ShiftService";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import crypto from 'crypto';
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    display: "flex",
    flex: 1,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  sectionHeader: {
    fontSize: Font.larger, fontWeight:"bold",
    //  backgroundColor: "red",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  CardContent: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
});

interface DashboardPageWithoutRouteProps extends RouteComponentProps<any> {
  roleName: string;
  userName: string;
}

const DashboardPageWithoutRoute: React.FC<DashboardPageWithoutRouteProps> = ({
  history,
  ...props
}) => {
  const { userName } = props;
  const location = useLocation();
  const [roleName, setRoleName] = useState<string>("");
  const { industryMode,primaryColor,secondaryColor,updateprimaryColor,updateSecondaryColor,updateContactEmail,updateContactPhone,updateMode  } = useContext(RBContext);

  const [alertInfo, setAlertInfo] = useState<AlertInfoProps>({});
  const [isAlertShowing, setAlertShowing] = useState(false);
  const [account, setAccount] = useState<any>([]);
  const [complete, setComplete] = useState<any>(null);
  const [upcoming, setUpcoming] = useState<any>(null);
  const [requestReceived, setRequestReceived] = useState<any>(null);
  const [unassigned, setUnassigned] = useState<any>(null);
  const classes = useStyles();

  const invalidAccountStates = ['pending', 'denied'];

  useEffect(() => {
    (async () => {
      await updateprimaryColor( localStorage.getItem('primaryColor')); 
      await updateSecondaryColor( localStorage.getItem('secondaryColor')); 
      await updateContactEmail( localStorage.getItem('contactEmail')); 
      await updateContactPhone( localStorage.getItem('contactPhone')); 
      await updateMode(localStorage.getItem('industryMode')); 
    })();  
   

    fetchUserData();
    setRoleName(AuthService.getRoleName());
   

  }, []);
  
  const isUserPending = (userData:any) => {
    if (userData.owner) {
      return (
        invalidAccountStates.indexOf(userData.owner.approval.status) >= 0
      );
    }

    if (userData.pharmacist) {
      return (
        invalidAccountStates.indexOf(userData.pharmacist.approval.status) >=
        0
      );
    }

    return false;
  };
   const getUserAccountStatus = (userData:any) => {
    if (userData.owner) {
      return userData.owner.approval.status;
    }
    if (userData.pharmacist) {
      return userData.pharmacist.approval.status;
    }
    return 'approved';
  };

  const fetchUserData = async () => {
    const userData = await AuthService.loadAccountInfo();
    if (isUserPending(userData)) {
      history.push(RoutePathConstant.PendingApproval + (getUserAccountStatus(userData)));

    }
    setAccount(userData);
    if(userData.roleName === roleNameEnum.owner){
      const stripeData = await AuthService.getStripeDetails(userData.networks[0].id);
      if(stripeData.data !== undefined)
      {
        const algorithm = getAlgorithm();
        const encryptionKey = getEncryptionKey();
        const IV = getEncryptionIV();
        const encryptionKeyBuffer = Buffer.from(encryptionKey, 'hex');
        const ivBuffer = Buffer.from(IV, 'hex');

        const decipher = crypto.createDecipheriv(algorithm, encryptionKeyBuffer, ivBuffer);
        let decryptedData = decipher.update(stripeData.data.pubKey, 'hex', 'utf8');
        decryptedData += decipher.final('utf8');
        console.log("decryptedData", decryptedData)
        //const stripePromise = loadStripe(decryptedData);
        localStorage.setItem('stripePromise',decryptedData)
      }
    }
    await fetchStatus()
  }

  const fetchStatus = async () => {
    const account = await AuthService.loadAccountInfo();
    let pharmacy = null;
    if (account.pharmacies) {
      pharmacy = account.pharmacies.filter((pharmacy:any) => pharmacy.id === account.owner.defaultPharmacyId)[0];
      const upcoming = await ShiftService.getShifts('assigned',pharmacy.networkId );
      const completeShift = await ShiftService.getShifts('completed',pharmacy.networkId );
      const unassigned = await ShiftService.getShifts('unassigned',pharmacy.networkId );
      const requestReceived = await ShiftService.getShifts('request-received',pharmacy.networkId );
      setComplete(completeShift.pageInfo.total);
      setUpcoming(upcoming.pageInfo.total);
      setRequestReceived(requestReceived.pageInfo.total);
      setUnassigned(unassigned.pageInfo.total);
    } else {
      pharmacy = { networkId: account.networks[0].id };
      const upcoming = await ShiftService.getShifts('assigned',pharmacy.networkId );
      const completeShift = await ShiftService.getShifts('completed',pharmacy.networkId );
      const requestReceived = await ShiftService.getShifts('requested',pharmacy.networkId );

      const futureShifts = upcoming.data.filter((shift:any) => {
        return moment().diff(shift.startTime, 'minutes') < 0;
      });

      setComplete(completeShift.pageInfo.total);
      setUpcoming(futureShifts.length);
      setRequestReceived(requestReceived.pageInfo.total);
    }
    // const pharmacy = account.pharmacies.filter((pharmacy: any) =>
    //     pharmacy.id === account.owner.defaultPharmacyId
    //   )[0];
      
  }

  
  const onShiftCreatePressed = async () => {

    if (industryMode === IndustryModeEnum.Dentistry) {
      return onShiftCreatePressedForDentalOffice();
    }
    if(roleName === "owner")
    {
        history.push(RoutePathConstant.CreateShift)
    }
    else{
      history.push(RoutePathConstant.SearchShifts)
     
    }

  };
  const onShiftCreatePressedForDentalOffice = () => {
    
    if(roleName === "owner")
    {
        history.push(RoutePathConstant.CreateDentalShift)
    }
    else{
      history.push(RoutePathConstant.SearchShifts)
     
    }
  };

  const onJobCreatePressed = () => {
    if (industryMode === IndustryModeEnum.Dentistry) {
      return onJobCreatePressedForDentalOffice();
    }
    
    if(roleName === "owner")
    {
        history.push(RoutePathConstant.CreateJob)
    }
    else{
      history.push(RoutePathConstant.SearchJobs)
     
    }
  };

  const onJobCreatePressedForDentalOffice = () => {
    if(roleName === "owner")
    {
        history.push(RoutePathConstant.CreateDentalJob)
    }
    else{
      history.push(RoutePathConstant.SearchJobs)
     
    }
  }




  const renderOwnerButtons = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ marginLeft: 158, marginTop: 21 }}>
          {/* <span style={{ marginLeft: 320, marginTop: 21 }}> */}
            <CustomButton
              text="POST SHIFT"
              style={{
                background: `linear-gradient(60deg, #bec0ce, ${primaryColor} )`,
              }}
              onClick={() => onShiftCreatePressed()}
            />
          </span>
          <span style={{ marginLeft: 292, marginTop: 21 }}>
            <CustomButton
              text="POST JOB"
              style={{
                background: `linear-gradient(120deg, #c5d8d8, ${secondaryColor})`,
                border: "2px solid rgba(170, 211, 213, 1)",
              }}
              onClick={() => onJobCreatePressed()}
            />
          </span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{
            marginLeft: 95,
            marginTop: 245
          }}>
            <StatBlock
              loading={complete === null}
              value={complete}
              title="shifts"
              subtitle="completed"
            />
          </span >
          <span style={{
            marginTop: 245
          }}>
            <StatBlock
              loading={unassigned === null}
              value={unassigned}
              title="shifts"
              subtitle="unfilled"
            />
          </span>
          <span style={{
            marginTop: 245
          }}>
            <StatBlock
              loading={requestReceived === null}
              value={requestReceived}
              title="shifts"
              subtitle="requested"
            />
          </span>
        </div>
      </>
    );
  };

  const renderWorkerButtons = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ marginLeft: 158, marginTop: 21 }}>
            <CustomButton
              text="FIND SHIFT"
              style={{
                background: `linear-gradient(60deg, #bec0ce, ${primaryColor})`,
              }}
              onClick={() => onShiftCreatePressed()}
            />
          </span>
          <span style={{ marginLeft: 292, marginTop: 21 }}>
            <CustomButton
              text="FIND JOB"
              style={{
                background: `linear-gradient(120deg, #c5d8d8, ${secondaryColor})`,
                border: "2px solid rgba(170, 211, 213, 1)",
              }}
              onClick={() => onJobCreatePressed()}
            />
          </span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{
            marginLeft: 95,
            marginTop: 245
          }}>
            <StatBlock
              loading={complete === null}
              value={complete}
              title="shifts"
              subtitle="completed"
            />
          </span >
          <span style={{
            marginTop: 245
          }}>
            <StatBlock
              loading={upcoming === null}
              value={upcoming}
              title="shifts"
              subtitle="upcoming"
            />
          </span>
          <span style={{
            marginTop: 245
          }}>
            <StatBlock
              loading={requestReceived === null}
              value={requestReceived}
              title="shifts"
              subtitle="applied"
            />
          </span>
        </div>

      </>

    );
  };

  return (
    <RBCardView
      style={{
        flex: 1,
        margin: Spacing.large,
        padding: Spacing.large,
      }}
    >

      <RBAlert
        show={isAlertShowing}
        alertTitle={alertInfo.title}
        alertMessage={alertInfo.message}
        buttons={alertInfo.buttons}
      />
     
      <div
      className={classes.sectionHeader}
      color="primary"
      style={{
        textAlign:"center"
      }}
    >
      {roleName === roleNameEnum.owner ?  `Welcome back ${localStorage.getItem("pharmacyName")}!` : `Welcome back ${localStorage.getItem("firstName")}!`}  
    </div>
    <br></br>
    
      {roleName === "owner" ? renderOwnerButtons() : renderWorkerButtons()}
    </RBCardView>
  );
};

export const DashboardPage = withRouter(DashboardPageWithoutRoute);