import React from "react";
import { Formik, Form } from "formik";

import { RBSwitch } from "../../../components/RBSwitch";
import { Spacing } from "../../../theme/Theme";
import { PharmacistProfileValueTypes, QUESTIONS } from "./PharmacistProfile";

interface PharmacistProfileProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: PharmacistProfileValueTypes;
  account?:any;
}

export const StudentProfile: React.FC<PharmacistProfileProps> = ({
  ...props
}) => {
  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={true}
      onSubmit={() => {}}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <RBSwitch
            checked={values.q1}
            onChange={handleChange}
            name="q1"
            label={QUESTIONS[0]}
            formControlStyle={{ marginBottom: Spacing.large }}
          />
          <RBSwitch
            checked={values.q3}
            onChange={handleChange}
            name="q3"
            label={QUESTIONS[2]}
            formControlStyle={{ marginBottom: Spacing.large }}
          />

          <RBSwitch
            checked={values.q6}
            onChange={handleChange}
            name="q6"
            label={QUESTIONS[5]}
            formControlStyle={{ marginBottom: Spacing.large }}
          />
        </Form>
      )}
    </Formik>
  );
};
