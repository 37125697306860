import React, { useState,useContext, useEffect } from "react";
import { ShiftProps } from "../../interfacesProps/ShiftProps";
import { RBCardView } from "../../components/RBCardView";
import { Font, Spacing, Colors } from "../../theme/Theme";
import moment from "moment-timezone";
import { ShiftService } from "../../api/ShiftService";
import { Typography } from "@material-ui/core";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { useHistory } from "react-router-dom";
import { RBDateTimePicker } from "../../components/RBDateTimePicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBTextField } from "../../components/RBTextField";
import { EmptyString, RoutePathConstant } from "../../util/Common";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum } from "../../api/constants";
import { formatPaymentType, formatWorkerType } from "../../util/FormattingService";
import { AuthService } from "../../api/AuthService";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import InputMask from "react-input-mask";

interface ConfirmShiftHourPageForPharmacy {
  shiftData: ShiftProps;
}

function UnpaidBreakFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;
  return (
    <InputMask
      {...other}
      inputRef={inputRef}
      mask="999"
      alwaysShowMask={false}
      onChange={onChange}
      maskChar=" "
    />
  );
}

export const ConfirmShiftHourPageForPharmacy: React.FC<ConfirmShiftHourPageForPharmacy> = ({
  ...props
}) => {
  const { shiftData } = props;
  const history = useHistory();

  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [showUpdateDateTime, setShowUpdateDateTime] = useState(false);
  const [newStartTime, setNewStartTime] = useState<any>(
    moment(shiftData.actualStartTime).tz(shiftData.shiftTimeZone)
  );
  const [newEndTime, setNewEndTime] = useState<any>(
    moment(shiftData.actualEndTime).tz(shiftData.shiftTimeZone)
  );
  const [disputeComment, setDisputeComment] = useState(EmptyString);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [attention,setAttention] = useState(false);
  const pharmacist = shiftData.pharmacist.pharmacist;
  const workerName = pharmacist.firstName + " " + pharmacist.lastName;

  const dateString = moment(shiftData.actualStartTime ? shiftData.actualStartTime :shiftData.startTime)
    .tz(shiftData.shiftTimeZone)
    .format("ddd, MMM Do YYYY");
  const startTime = moment(shiftData.actualStartTime)
    .tz(shiftData.shiftTimeZone)
    .format("hh:mm a");
  const endTime = moment(shiftData.actualEndTime)
    .tz(shiftData.shiftTimeZone)
    .format("hh:mm a");

    const actualHours = ShiftService.getShiftActualLengthDisplay(shiftData);
    const hours = ShiftService.getShiftLengthDisplayForOriginal(shiftData);


  //const hours = ShiftService.getShiftActualLengthDisplay(shiftData);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const originalDateString = moment(shiftData.startTime).format(
    'ddd, MMM Do YYYY',
  );
  const originalStartTime = moment(shiftData.startTime).tz(shiftData.shiftTimeZone);
  const originalEndTime = moment(shiftData.endTime).tz(shiftData.shiftTimeZone);

  const [alertSInfo,setAlertSInfo] =  useState<ObjectLiteral>({})
  const [errorAlertInfo,setErrorAlertInfo] =  useState<ObjectLiteral>({})
  const [showModal, setShow] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [blockUserDialogVisible, setBlockUserDialogVisible] = useState(false);
  const [blockUserComment, setBlockUserComment] = useState('');
  const [disputeUnpaidBreakMinutes, steDisputeUnpaidBreakMinutes] = useState<any>(shiftData.unpaidBreakMinutes ? (shiftData.unpaidBreakMinutes).toString() : null);
  const [error, setError] = useState<any>("");
  const getDateTime = (date: any) => {
    let currentDate = moment(date).tz(shiftData.shiftTimeZone)
    let year = currentDate.format('YYYY')
    let month = currentDate.format('MM')
    let day = currentDate.format('DD')
    let hour = currentDate.format('HH')
    let minute = currentDate.format('mm')
    let second = currentDate.format('ss')
    let convertedDate = moment(year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second);
    return convertedDate;
  }
  const convertOffsetToString = (offset: any) => {
    let value = '';
    const absOffset = Math.abs(offset);
    const hr = absOffset / 60;
    const min = absOffset % 60;
    if (offset < 0) {
      value = value + '-';
    }
    else {
      value = value + '-';
    }
    if (hr < 10) {
      value = value + '0' + hr;
    }
    else {
      value = value + hr;
    }
    if (min < 10) {
      value = value + ':0' + min;
    }
    else {
      value = value + ':' + min;
    }
    return value;
  }

  useEffect(() =>  {
    setAttention(!!shiftData.actualEndTime || !!shiftData.actualStartTime)
  }, []);

  const onClose = () => {
    setBlockUserDialogVisible(true);
  }
  const  onBlockUser = async () => {
    try {
      await AuthService.blockUser({
        comment: blockUserComment.length > 0 ? blockUserComment : null,
        shiftId: shiftData.id,
      });
    } catch (error) {
      console.log('onBlockUser ', error);
    }

    setBlockUserDialogVisible(false);
    history.goBack();
  };
  const renderCreateShiftContent = () => {

    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
           <div>
            How did the shift go with {shiftData.pharmacist.pharmacist.firstName} {shiftData.pharmacist.pharmacist.lastName}?
            Would you consider hiring this {formatWorkerType(shiftData.pharmacistType)} again?
           </div>
           <br></br>
           <div>
            <RBTextField
              style={{
                display: "flex",
                flex: 1,
                paddingTop: Spacing.small,
                paddingBottom: Spacing.small,
              }}
              placeholder={"*Please leave a comment if you decide no."}
              value={blockUserComment}
              onChange={(event) => setBlockUserComment(event.target.value)}
              multiline
              rows={3}
              rowsMax={8}
            />
           </div>
           <br></br>
           <div style={{color:'red'}}>
            *If you select "No", this {formatWorkerType(shiftData.pharmacistType)} will not be matched with any future shifts/jobs you
              post for this office or any of the other offices on your profile.
           </div>
           <div style={{
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}>
                <RBButton
                  buttonBackgroundColor={primaryColor}
                  isLoading={isButtonLoading}
                  disabled={blockUserComment.length === 0}
                  text="No"
                  onClick={() => onBlockUser()}
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: Spacing.medium,
                    margin: Spacing.large,
                  }}
                />
                <RBButton
                  buttonBackgroundColor={primaryColor}
                  isLoading={isButtonLoading}
                  text="Yes"
                  onClick={() => {
                    setBlockUserDialogVisible(false)
                    history.goBack()
                    }
                  }
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: Spacing.medium,
                    margin: Spacing.large,
                  }}
                />
           </div>
          </RBCardView>
       
      </div>
    );
  };

  const renderBlockUserDialog = () => {
    
    return (

      <RBModal
          open={blockUserDialogVisible}
          onClose={onClose}
          modalTitle={"Shift Feedback"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              renderCreateShiftContent()
          }
        />

    );
  };

  const renderUpdateDateTime = () => {
    return (
      <>
        <Typography
          style={{
            textAlign: "center",
            fontSize: 25,
          }}
        >
          Update Shift Time
        </Typography>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            paddingLeft: "20%",
            paddingRight: "20%",
            paddingTop: Spacing.large,
          }}
        >
           <div style={{ alignItems: "center", paddingTop:'3%' }}>
            <Typography style={{ display: "flex", flex: 1 }}>
              Start Time
            </Typography>
          </div>
          <div style={{ display: "flex", flex: 1 }}>
            <DatePicker
              selected={getDateTime(moment(newStartTime).tz(shiftData.shiftTimeZone)).toDate()}
              onChange={(date) => {
                const offsetString1 = convertOffsetToString(moment(date).tz(shiftData.shiftTimeZone).utcOffset())
                setNewStartTime(
                  date
                    ? moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1)
                    : moment()
                );
              }}
              showTimeSelect
              minDate={moment().tz(shiftData.shiftTimeZone).toDate()}
              timeFormat="h:mm aa"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MM/dd/yyyy h:mm aa"
              customInput={
                <input
                  style={{
                    paddingTop: Spacing.large,
                    paddingBottom: Spacing.large,
                    paddingRight: "30%",
                    borderRadius: 5,
                    borderColor: "rgb(229 224 224)",
                    width: 500
                  }}
                  key={`dateTime-${shiftData.shiftTimeZone}`}
                />
              }
              withPortal
            />
          </div>
          <div style={{alignItems: "center", paddingTop:'3%' }}>
            <Typography style={{ display: "flex", flex: 1 }}>
              End Time
            </Typography>
          </div>
          <div style={{ display: "flex", flex: 1, paddingTop:'1%' }}>
            <DatePicker
              selected={getDateTime(moment(newEndTime).tz(shiftData.shiftTimeZone)).toDate()}

              onChange={(date) => {
                const offsetString1 = convertOffsetToString(moment(date).tz(shiftData.shiftTimeZone).utcOffset())
                setNewEndTime(
                  date
                    ? moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1)
                    : moment()
                );
              }}
              showTimeSelect
              minDate={
                moment.max(
                  moment().tz(shiftData.shiftTimeZone),
                  moment((newStartTime))
                ).toDate()
              }
              maxDate={moment(getDateTime(newStartTime))
                .tz(shiftData.shiftTimeZone)
                .add(24, "hours")
                .toDate()}
              timeFormat="h:mm aa"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MM/dd/yyyy h:mm aa"
              customInput={
                <input
                  style={{
                    paddingTop: Spacing.large,
                    paddingBottom: Spacing.large,
                    paddingRight: "45%",
                    borderRadius: 5,
                    borderColor: "rgb(229 224 224)",
                    width: 500
                  }}
                  key={`dateTime-${shiftData.shiftTimeZone}`}
                />
              }
              withPortal
            />
          </div>
          <div style={{alignItems: "center", paddingTop:'3%' }}>
            <Typography style={{ display: "flex", flex: 1 }}>
            Unpaid Breaks (Min)
            </Typography>
          </div>
          <div style={{ display: "flex", flex: 1, paddingTop: '3%' }}>
            <RBTextField
                margin={"dense"}
                value={disputeUnpaidBreakMinutes}
                inputComponent={UnpaidBreakFormatCustom as any}
                onChange={(e) => onDisputeUnpaidBreakMinutes(e.target.value)}
                error={error.length > 0}
                errorText={error}
              />

          </div>
          <div style={{ ...ColumnStyle, alignItems: "center", paddingTop:'3%' }}>
            <Typography style={{ display: "flex", flex: 1 }}>
              Comment
            </Typography>
          </div>
          <RBTextField
            style={{
              display: "flex",
              flex: 1,
              paddingTop: Spacing.small,
              marginBottom: Spacing.large,
            }}
            placeholder={"Required"}
            value={disputeComment}
            onChange={(event) => setDisputeComment(event.target.value)}
            multiline
            rows={3}
            rowsMax={8}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: Spacing.large,
            marginTop: Spacing.large,
            marginLeft:"30%",
            marginRight:"30%"
          }}
        >
          <RBButton
            buttonBackgroundColor={primaryColor}
            onClick={() => {
              setShowUpdateDateTime(false)
              steDisputeUnpaidBreakMinutes(shiftData.unpaidBreakMinutes ? (shiftData.unpaidBreakMinutes).toString() : null)
              setError("")
            }}
            text="Cancel"
            style={{ width: 150 }}
          />
          <RBButton
            disabled={disputeComment.length === 0 || error}
            buttonBackgroundColor={primaryColor}
            isLoading={isButtonLoading}
            text="Submit hours"
            onClick={async () => await onSubmitHours()}
            style={{ width: 150 }}
          />
        </div>
      </>
    );
  };

  const onDisputeUnpaidBreakMinutes= (value:any) => {

    setError("")
    const sanitizedValue = value ? value.replace(/\s+/g, '') : null;
    steDisputeUnpaidBreakMinutes(sanitizedValue)


    if (value) {
      validateTime(value);
    }

  }
  const  validateTime = (inputTime:any) => {
    // Calculat e the time difference in milliseconds
    const timeDifferenceMinutes = newEndTime.diff(newStartTime, 'minutes');
    console.log("setUnpaidBreakMinutes",timeDifferenceMinutes)
    console.log("setUnpaidBreakMinutes2",inputTime)
    if (inputTime < 0 || inputTime >= timeDifferenceMinutes) {
      console.log("setUnpaidBreakMinutes23")
      setError("The unpaid time cannot be equal or greater than the shift duration");
      return false;
    }

    setError("")
    return true;
};

  const onSubmitHours = async () => {
    try {
      setIsButtonLoading(true);
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      if (showUpdateDateTime) {
        let payload = {};
        console.log("unpaidBreakMinutes",disputeUnpaidBreakMinutes)
        if (disputeUnpaidBreakMinutes !== null && disputeUnpaidBreakMinutes !== "0" && disputeUnpaidBreakMinutes !== "00" && disputeUnpaidBreakMinutes !== "000") {
          payload = {
            shiftId: shiftData.id,
            disputeStartTime: newStartTime.toISOString(),
            disputeEndTime: newEndTime.toISOString(),
            disputeComment: disputeComment,
            disputeUnpaidBreakMinutes: disputeUnpaidBreakMinutes
          }
        }
        else {
          payload = {
            shiftId: shiftData.id,
            disputeStartTime: newStartTime.toISOString(),
            disputeEndTime: newEndTime.toISOString(),
            disputeComment: disputeComment,
            disputeUnpaidBreakMinutes: null
          }
        }


        await ShiftService.disputeShiftByPharmacy(payload);
        setAlertSInfo({
          title: 'Shift Dispute Created',
          message: 'A dispute for this shift is now pending.  We will let you know of the result shortly.',
          buttons: [
            <RBButton
              key={RBButtonType.cancel}
              buttonType={RBButtonType.cancel}
              onClick={() => {
                  setShow(false);
                  history.goBack();
              }}
              text="Go Home"
            />
          ],
        })
        setShow(true);
        
      } else {
        await ShiftService.acknowledgeShiftByPharmacy(shiftData.id);
        if (industryMode === IndustryModeEnum.Dentistry) {
          setAlertSInfo({
            title: 'Shift Completed',
            message: 'The shift hours are confirmed, and a statement has been sent to you by email.',
            buttons: [
              <RBButton
                key={RBButtonType.cancel}
                buttonType={RBButtonType.cancel}
                onClick={() => {
                    setShow(false);
                    setBlockUserDialogVisible(true);
                    
                }}
                buttonBackgroundColor={primaryColor}
                style={{color:"white"}}
                text="OK"
              />
            ],
          })
          setShow(true);
        }
        else{
          setAlertSInfo({
            title: 'Shift Completed',
            message: 'The shift hours are confirmed, and a statement has been sent to you by email. \n\nYour feedback is important to us! Please take a minute to rate the performance of the relief you had working for this shift.',
            buttons: [
              <RBButton
                key={RBButtonType.cancel}
                buttonType={RBButtonType.cancel}
                buttonBackgroundColor={primaryColor}
                onClick={() => {
                    setShow(false);
                    history.push(RoutePathConstant.ShiftSurvey + shiftData.id);
                }}
                style={{color:"white"}}
                text="Rate Now"
              />,
              <RBButton
                key={RBButtonType.cancel}
                buttonType={RBButtonType.cancel}
                onClick={() => {
                    setShow(false);
                    history.push(RoutePathConstant.Shifts);
                }}
                buttonBackgroundColor={primaryColor}
                style={{color:"white"}}
                text="Rate Later"
              />
            ],
          })
          setShow(true);

        }
      }
      //history.goBack();
    } catch (err) {
      debugger
      const errorMessage = err.response ? err.response.data.message[0]?.constraints?.isRelative : err.message;
      // setSubmitErrorMessage(
      //   err.response ? err.response.data.message : err.message
      // );
      if (err.response) {
        if(err.response?.data?.message)
        {

          setShowErrorModal(true);
          setErrorAlertInfo({
            title: 'An Error Occured',
            message: `${err.response.data.message}`,
            buttons: [
              <RBButton
                key={RBButtonType.cancel}
                buttonBackgroundColor= {primaryColor}
                style={{color: "white"}}
                buttonType={RBButtonType.cancel}
                onClick={() => {
                    setShowErrorModal(false);
                    history.push('/payment',{
                      tabType: 'Shift',
                    });
                }}
                text="Go To Payment Settings"
              />,
            ],
          })
        }
      }
      
      if(errorMessage?.includes("must come"))
      {
        setShowErrorModal(true);
        setErrorAlertInfo({
          title: 'An Error Occured',
          message: `${err.response.data.message}`,
          buttons: [
            <RBButton
              key={RBButtonType.cancel}
              buttonBackgroundColor= {primaryColor}
              style={{color: "white"}}
              buttonType={RBButtonType.cancel}
              onClick={() => {
                  setShowErrorModal(false);
                  history.push(RoutePathConstant.DashBoard)
              }}
              text="Go Home"
            />,
          ],
        })
      }
      if(errorMessage?.includes("must come"))
      {
        setShowErrorModal(true);
        setErrorAlertInfo({
          title: 'An Error Occured',
          message: 'Unable to submit at this time. Please try again later.',
          buttons: [
            <RBButton
              key={RBButtonType.cancel}
              buttonBackgroundColor= {primaryColor}
              style={{color: "white"}}
              buttonType={RBButtonType.cancel}
              onClick={() => {
                  setShowErrorModal(false);
                  history.push(RoutePathConstant.DashBoard)
              }}
              text="Go Home"
            />,
          ],
        })
      }
    } finally {
      setIsButtonLoading(false);
    }
  };

  return (
    <>
      <RBAlert
        show={showModal}
        alertTitle={alertSInfo.title}
        alertMessage={alertSInfo.message}
        buttons={alertSInfo.buttons}
      />
      <RBAlert
        show={showErrorModal}
        alertTitle= {errorAlertInfo.title}
        alertMessage={errorAlertInfo.message}
        buttons={errorAlertInfo.buttons}
      />
      {renderBlockUserDialog()}
       
      {/* <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={JSON.stringify(submitErrorMessage, null, 2)}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      /> */}
      <RBCardView
        style={{
          margin: Spacing.large,
          padding: Spacing.large,
        }}
      >
        <div style={{ fontSize: Font.large }}>
          <strong>{workerName} </strong>has completed their shift.
          <br />
          <div>
            They worked on {dateString} from {startTime} -{' '}
            {endTime} { }
            for a total of{' '}
            <strong>{actualHours}</strong>.
          </div>
          
          {attention &&
            <div>
              Note: This shift
              originally was to take place on {originalDateString} between{' '}
              {originalStartTime.format('hh:mm a')} and{' '}
              {originalEndTime.format('hh:mm a')} for a total of{' '}

              <strong>{hours}</strong>.
            </div>
          }

            <div>
            The payment method on file for this location is {formatPaymentType(shiftData.pharmacy.paymentType)}. 
            Please go to the payment information section to review your payment
                 settings or change your payment method for this location.
            </div>
          
        </div>
      </RBCardView>

      <RBCardView
        marginHorizontal={Spacing.large}
        style={{
          marginBottom: Spacing.large,
          padding: Spacing.large,
        }}
      >
        <>
          {showUpdateDateTime ? (
            renderUpdateDateTime()
          ) : (
            <>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: Font.larger,
                }}
              >
                Is this correct?
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: Spacing.large,
                  marginTop: Spacing.large,
                }}
              >
                <RBButton
                  buttonBackgroundColor={primaryColor}
                  onClick={() => setShowUpdateDateTime(true)}
                  text="No"
                  style={{ width: 200 }}
                />
                <RBButton
                  buttonBackgroundColor={primaryColor}
                  isLoading={isButtonLoading}
                  text="Yes"
                  onClick={async () => await onSubmitHours()}
                  style={{ width: 200 }}
                />
              </div>
            </>
          )}
        </>
      </RBCardView>
    </>
  );
};
