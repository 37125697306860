import React, { useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import { buttonStyle } from "../theme/ComponentTheme";
import { Colors } from "../theme/Theme";

import {
  NotAvailableString,
  adjustColor,
  darkenColorPercentageConstant,
  darkerColorPercentageConstant,
} from "../util/Common";

export enum RBButtonType {
  default,
  cancel,
}
interface RBButtonProps extends ButtonProps {
  text?: string;
  buttonBackgroundColor?: string;
  isLoading?: boolean;
  buttonType?: RBButtonType;
}

export const RBButton: React.FC<RBButtonProps> = ({
  buttonType = RBButtonType.default,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const {
    style,
    buttonBackgroundColor,
    isLoading,
    text,
    disabled,
    ...rest
  } = props;

  const disabledBackgroundColor = Colors.darkGrey;
  let backgroundColor = buttonStyle.backgroundColor ?? NotAvailableString;
  let color = buttonStyle.color;
  switch (buttonType) {
    case RBButtonType.cancel:
      backgroundColor = Colors.defaultGrey;
      color = Colors.black;
      break;
  }

  if (disabled) {
    backgroundColor = disabledBackgroundColor;
  } else {
    if (buttonBackgroundColor) {
      backgroundColor = buttonBackgroundColor;
    }
    // isHovered only works when the button is enabled.
    if (isHovered) {
      backgroundColor = adjustColor(
        backgroundColor,
        darkenColorPercentageConstant
      );
    }
  }

  return (
    <Button
      // onMouseEnter={() => {
      //   setIsHovered(true);
      // }}
      // onMouseLeave={() => {
      //   setIsHovered(false);
      // }}
      style={{
        ...buttonStyle,

        color,
        ...style,
        backgroundColor,
      }}
      {...rest}
      disabled={isLoading ? true : disabled ?? false}
    >
      {isLoading && (
        <>
          Loading
          <CircularProgress
            size={20}
            style={{ color: Colors.white, marginLeft: 5 }}
          />
        </>
      )}
      {!isLoading && text}
    </Button>
  );
};
