export const Colors = {
  themeBlue: "#513491",
  //themeGreen: "rgb(52, 175, 71)",
  themeGreen: "#34af47",
  themeLightBlue: '#6EC9CD',
  themeDarkBlue: '#2B3270',
  white: "#FFFFFF",
  black: "#000000",
  red: "red",
  blue: "blue",

  bgGrey: "rgb(244, 243, 249)",
  darkGrey: "#808080",
  defaultGrey: "#E6E6E6",
  lighterGrey: "#f2f2f2",
  darkerGray: "#202020",
  mediumGrey: "rgb(223, 223, 223)",
  placeholder: "#C7C7CD",
};

export const Spacing = {
  smallest: 2,
  smaller: 4,
  small: 8,
  medium: 12,
  mid: 14,
  large: 16,
  larger: 18,
  xlarge: 20,
};

export const FontWeight = {
  light: "lighter",
  normal: "400",
  bold: "bold",
  bolder: "800",
};

export const FontSize = {
  smallest: 10,
  small: 12,
  default: 14,
  large: 16,
  larger: 18,
  largest: 20,
  xlarge: 22,
  xlarger: 24,
  xlargest: 26,
};

export const detailAllowanceIconStyle = {
  size: 15,
  color: Colors.themeBlue,
};

export const defaultAllowanceIconStyle = {
  size: 20,
  color: Colors.themeBlue,
};

export const techOrAssistOnSiteStyle = {
  size: 25,
  colorForYes: Colors.themeGreen,
  colorForNo: "red",
};

export const defaultAllowOffersIconStyle = {
  size: 16,
  color: Colors.themeLightBlue,
};

export const AppBackgroundColor = Colors.defaultGrey;
export const AppContainerWidth = 900;
export const AppContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  flexShrink: 0,
  flexGrow: 0,
  width: AppContainerWidth,
  borderColor: Colors.darkerGray,

  borderLeftWidth: 1,
  borderLeftStyle: "solid",
  borderRightWidth: 1,
  borderRightStyle: "solid",
  //height: "100%",
  backgroundColor: "inherit",
};

const ButtonBorderRadius = 10;
const SkillStyleBorderRadisu = 5;
const CardBorderRadius = 10;
const DialogBorderRadius = 15;
const DialogWidth = 325;
const RBButtonMinWidth = 100;

export const UIRelatedConstants = {
  ButtonBorderRadius,
  CardBorderRadius,
  SkillStyleBorderRadisu,
  DialogBorderRadius,
  DialogWidth,
  RBButtonMinWidth,
};

export const FontFamily = {
  family: 'SofiaProRegular'
}

export const Font = {
  smallest: 10,
  small: 12,
  default: 14,
  large: 16,
  larger: 18,
  largest: 20,
  xlarge: 22,
  xlarger: 24,
  xlargest: 26,
};