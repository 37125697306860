import React,{useContext} from "react";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, Colors, FontSize } from "../../theme/Theme";
import { RBModal } from "../../components/RBModal";
import {RBShiftAgreementModal } from "../../components/RBShiftAgreementModal";
import {
  Select,
  MenuItem,
  Typography,
  Checkbox,
  ListItemText,
  FormControlLabel,
  FormControl,
  IconButton,
  Link,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { formatWorkerType } from "../../util/FormattingService";
import {
  EmptyString,
  Claims,
  ErrorMessages,
  NotAvailableString,
  IndustryModeEnum,
} from "../../util/Common";
import { ColumnStyle, skillTextStyle } from "../../theme/ComponentTheme";
import { LabelText } from "../../util/TextConstant";
import {
  RBMoneyAmountTextField,
  RBTextField,
} from "../../components/RBTextField";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { RBButton, RBButtonType } from "../../components/RBButton";
import {
  RenderTextWithTitle,
  renderTextWithTitleForSkills,
  renderTextWithTitleAndChildComponent,
  titleHeaderTextStyle,
  detailTextStyle,
} from "../../components/TextWithHeader";
import {
  detailKmAllowanceIcon,
  detailAccomodationAllowanceIcon,
  techOnSiteIcon,
  assistantOnSiteIcon,
  allowOtherProvinceIcon,
  allowofferIcon
} from "../../util/Icons";
import { RBDateTimePicker } from "../../components/RBDateTimePicker";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  workerTypeEnum,
  HelperPageTitleEnum,
  SkillType,
} from "../../util/Enumeration";
import moment from "moment-timezone";
import { Add, Remove } from "@material-ui/icons";
import { RBExternalHelpLink } from "../../components/RBExternalHelpLink";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { AuthService } from "../../api/AuthService";
import { PharmacyService } from "../../api/PharmacyService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { SkillResponseDto, SkillPayloadDto } from "../../dtos/Skill.dto";
import { ShiftRelatedConstants } from "../../util/ShiftConstants";
import { CreateShiftDto, ShiftTimeDto, CreateJobDto } from "../../dtos/CreateShift.dto";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { RBContext } from '../../components/RBContext';
import { ShiftService} from '../../api/ShiftService'
import './CreateJobStyle.css';

import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import RBPlacementFeeModal from "../../components/RBPlacementFeeModal";
import PDFViewer from "../../components/PdfViewer";
import SignaturePad  from "../../components/SignaturePad"


interface CreateDentalJobPageProps {
  history: any;
  location: any;
}
enum CreateDentalJobState {
  Create,
  Confirm,
}

interface IState {
  alertInfo:AlertInfoProps;
    isAlertShowing:boolean;
    isPageLoading: boolean;
    jobCategories:any;
    workerTypesAndRates:any;
    workerTypes:any;
    specializations:any;
    selectedSoftware:any;
    pharmacy:any;
    selectedSpecializations:any;
    wage: any;
    wageInvalidErrorText: string;
    showModal: boolean;
    submitErrorMessage?: ErrorMessageProps;
    isDiscardAlertShowing: boolean;
    discardAlertSInfo: AlertInfoProps;
    currentCreateJobState: CreateDentalJobState;
    isCreateJobSubmitInfo: boolean;
    createJobSubmitInfo: AlertInfoProps;
    workerType: string;
    AllSpecializationSkills: SkillResponseDto[];
    dateObject:any;
    selectedJobCategory:any;
    rateObject:any;
    agreeTOS: boolean;
    newFile:any;
    signature?: string;
    pharmacyName:string;
    softwareName: string;
    softwareRequired: boolean;
    jobTitle:any;
    pharmacyTimezoneOffset:any;
    comment:any;
    ignoreProv:boolean;
    rxCount: string;
    resumeModal:boolean;
    showContract:boolean;
    networkId:string;
    ownerData:ObjectLiteral;
    showTermOfService:boolean;
    pharmacyId:string;
    isSubmitting: boolean;
    pharmacySoftware?: SkillResponseDto;
    workertypeInvalidErrorText: string;
}

export class CreateDentalJobPage extends React.PureComponent<
    CreateDentalJobPageProps,
    IState
> {

  state: IState;
  static contextType = RBContext;

  constructor(props: CreateDentalJobPageProps) {
    super(props);
    this.state = {
      alertInfo:{},
        isAlertShowing:false,
        isPageLoading: true,
        jobCategories:[],
        workerTypesAndRates:[],
        workerTypes:[],
        specializations:[],
        selectedSoftware:{},
        pharmacy:{},
        selectedSpecializations: [],
        wage: "0",
        wageInvalidErrorText: EmptyString,
        showModal: false,
        isDiscardAlertShowing: false,
        discardAlertSInfo: {},
        currentCreateJobState: CreateDentalJobState.Create,
        isCreateJobSubmitInfo:false,
        createJobSubmitInfo:{},
        workerType: EmptyString,
        AllSpecializationSkills: [],
        dateObject:{
          estStartDate: moment().add(1, 'days').hours(12),
          estEndDate: null,},
        selectedJobCategory:EmptyString,
        rateObject:{
          hourlyRate: '0',
          hourlyRateDisplayValue: '0.00',
          isRateValid: true}  ,
        agreeTOS: false,
        newFile:null,
        signature:undefined,
        pharmacyName:EmptyString,
        softwareName: EmptyString,
        softwareRequired: false,
        jobTitle:null,
        pharmacyTimezoneOffset:null,
        comment:EmptyString,
        ignoreProv:false,
        rxCount: EmptyString,
        resumeModal:false,
        showContract:false,
        networkId:'',
        ownerData:{},
        showTermOfService:false,
        pharmacyId:EmptyString,
        isSubmitting: false,
        workertypeInvalidErrorText: EmptyString
      
    }
    
  }
  async componentDidMount() {
    await this.fetchCreateJobStatus();
    await this.loadContent();
  }
  fetchCreateJobStatus = async () => {
    const account = await AuthService.loadAccountInfo();
    const dentalOffice = account.pharmacies.filter((pharmacy: any) =>
      pharmacy.id === account.owner.defaultPharmacyId
    )[0];
    const jobCard = dentalOffice.creditCards.find((x:any) => x.category == 'job')
    const jobPostFee = dentalOffice?.jobPostFee ? dentalOffice.jobPostFee : account.networks[0].paymentConfig.jobPostFeeDefault;
    

    if (dentalOffice.approval.status !== 'approved') {
      this.setState({ isAlertShowing: true });
      this.setState({alertInfo:{
        title: "This dental practice is under review",
        message: `You can post jobs under this dental practice once it gets approved.`,
        buttons: [

          <RBButton
            key={"confirm"}
            onClick={async () => {
              this.setState({ isAlertShowing: false });
              this.props.history.push('/dashboard');
            }}
            buttonBackgroundColor={this.context.primaryColor}
            color="primary"
            text="Ok"
          />,
        ],
      }});
      return;
    }
    if (!dentalOffice.jobPostFlag) {
        this.setState({ isAlertShowing: true });
        this.setState({alertInfo:{
          title: "Contact Network Admin",
          message:  `Please contact network admin ${this.context.contactEmail} or ${this.context.contactPhone}  to post job.`,
          buttons: [
  
            <RBButton
              key={"confirm"}
              onClick={async () => {
                this.setState({ isAlertShowing: false });
                this.props.history.push('/dashboard');
              }}
              buttonBackgroundColor={this.context.primaryColor}
              color="primary"
              text="Ok"
            />,
          ],
        }});
        return;
    }
    if(jobCard === undefined){
      this.setState({ isAlertShowing: true });
      this.setState({
        alertInfo: {
          title: "Payment Info Required",
          message: `You must set up your payment information before you can post a job. Would you like to do this now?`,
          buttons: [

            <RBButton
              key={"confirm"}
              onClick={async () => {
                this.setState({ isAlertShowing: false });
                this.props.history.push('/payment/',{
                  tabType: 'Job'
                });
              }}
              buttonBackgroundColor={this.context.primaryColor}
              color="primary"
              text="Yes"
            />,
            <RBButton
              key={"confirm"}
              onClick={async () => {
                this.setState({ isAlertShowing: false });
                //setAlertShowing(false);
                this.props.history.push('/dashboard');
              }}
              buttonBackgroundColor={this.context.primaryColor}
              color="primary"
              text="No"
            />,
          ],
        }
      });
      return;
  }

    if (!dentalOffice.flags.isDentalPracticeCompanyInfoSetup) {
        this.setState({ isAlertShowing: true });
        this.setState({alertInfo:{
          title: "Company Info Required",
          message:  `You must set up your company overview, services and benefits before you can post a job. Would you like to do this now?`,
          buttons: [
  
            <RBButton
              key={"confirm"}
              onClick={async () => {
                this.setState({ isAlertShowing: false });
                this.props.history.push('/profile');
              }}
              buttonBackgroundColor={this.context.primaryColor}
              color="primary"
              text="Yes"
            />,
            <RBButton
              key={"confirm"}
              onClick={async () => {
                this.setState({ isAlertShowing: false });
                this.props.history.push('/dashboard');
              }}
              buttonBackgroundColor={this.context.primaryColor}
              color="primary"
              text="No"
            />,
          ],
        }});
        return;
    }

    this.setState({ isAlertShowing: true });
    this.setState({
      alertInfo: {
        title: "Note",
        message: `The job posting will be active for 30 days and a fee of $${jobPostFee} + tax will be charged to the card on file.`,
        buttons: [

          <RBButton
            key={"confirm"}
            onClick={async () => {
              this.setState({ isAlertShowing: false })
              this.setState({showModal:true})

            }}
            buttonBackgroundColor={this.context.primaryColor}
            color="primary"
            text="Ok"
          />,
        ],
      }
    });

  }

  loadContent = async () => {
    try {
      this.setState({isPageLoading: true});
      const account = await AuthService.loadAccountInfo();
      
      let pharmacy = null;
      if (account.pharmacies) {
        pharmacy = account.pharmacies.filter((pharmacy:any) => pharmacy.id === account.owner.defaultPharmacyId)[0];
        this.setState({pharmacy})
        this.setState(({
          signature:account.owner.signature,
          pharmacyName:pharmacy.name,
          pharmacySoftware:
          pharmacy.skills.filter((skill: any) => skill.type === "dentalSoftware")
            .length > 0
            ? pharmacy.skills.filter(
                (skill: any) => skill.type === "dentalSoftware"
              )[0]
            : null,
          softwareName:
          pharmacy.skills.filter((skill: any) => skill.type === "dentalSoftware")
            .length > 0
            ? pharmacy.skills.filter(
                (skill: any) => skill.type === "dentalSoftware"
              )[0].name
            : "None Specified",
            pharmacyTimezoneOffset:moment().tz(pharmacy.address.timezone).utcOffset(),
            rxCount: pharmacy.rxCount,
            networkId:account.networks[0].id,
            ownerData:account.owner,
            pharmacyId:pharmacy.id
          
        }))
      }


      // TODO check if we need download software and language
      // const {
      //   software,
      //   specialization,
      //   language,
      // } = await PharmacyService.fetchSkills();
      const fetchSkillsData = await PharmacyService.fetchSkills();
      this.setState({AllSpecializationSkills: fetchSkillsData.data.filter(
        (skill: ObjectLiteral) => skill.type === SkillType.specialization
      )});

      const workerTypesAndRates = await AuthService.getRBDentalJobWorkerTypeAndRate();
      const workerTypes = await AuthService.getAllWorkers('Dentistry');
      const sortedDataArray = workerTypes;
      sortedDataArray.sort((a:any, b:any) => a.orderSort - b.orderSort);

      const jobCategories = await AuthService.getRBDentalJobCategory();
      this.setState({jobCategories: jobCategories});
      this.setState({workerTypesAndRates: workerTypesAndRates});
      this.setState({workerTypes: sortedDataArray});
      //this.setState({specializations: specialization});
      const firstSoftwareSkill = pharmacy.skills.filter(
        (skill:any) => skill.type === 'dentalSoftware',
      )[0];
      this.setState({selectedSoftware: firstSoftwareSkill});
    } catch (error) {
    } finally {
      this.setState({isPageLoading: false});
    }
  };
 
  onSpecializationSkillSelectedChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    // this is a multiple select, so event.target.value will be an array
    // console.log("onSpecializationSkillSelectedChange", event.target.value);

    this.setState({
      selectedSpecializations: event.target.value as string[],
    });
  };

  updateWorkerType = (event: any) => {
    console.log(`updateWorkerType, value is ${event.target.value}`);
    this.setState({
      workerType: event.target.value,
      // reload the specializations options based on workerType
      specializations: this.state.AllSpecializationSkills.filter(
        (skill:any) => skill.pharmacistType === event.target.value
      ),
      // clear the old selectedSpecializations
      selectedSpecializations: [],

      //reset wage and its error text
      wage: "0",
      wageInvalidErrorText: EmptyString,
      workertypeInvalidErrorText: EmptyString
    });
    this.setState({rateObject:{
      hourlyRate: '0',
      hourlyRateDisplayValue: '0.00',
      isRateValid: true,
    }});
  };

  onResumeClose= () => {
    this.setState({resumeModal :false})
   };
  updateJobType = (event: any) => {
    this.setState({ selectedJobCategory: event.target.value })
  };

  goBackToPreviousLocation = () => {
    if (this.props.location.state && this.props.location.state.background) {
      this.setState({ showModal: false }, () => this.props.history.goBack());
    } else {
      this.setState({ showModal: false }, () =>
        this.props.history.push("/dashboard")
      );
    }
  };

  onClose = () => {
    // if there is a background(previous path), go back to the previous path
    const { currentCreateJobState } = this.state;

    if (currentCreateJobState === CreateDentalJobState.Create) {
      this.goBackToPreviousLocation();
    } else {
      this.setState({
        discardAlertSInfo: {
          title: "Discard Job",
          message: "Are you sure you want to discard the change?",
          buttons: [
            <RBButton
              key={RBButtonType.cancel}
              buttonType={RBButtonType.cancel}
              onClick={() => {
                this.setState({ isDiscardAlertShowing: false });
              }}
              text="Cancel"
            />,
            <RBButton
            buttonBackgroundColor={this.context.primaryColor}
              key={"Discard"}
              onClick={() => {
                this.setState({ isDiscardAlertShowing: false }, () =>
                  this.goBackToPreviousLocation()
                );
              }}
              color="primary"
              text="Discard"
            />,
          ],
        },
        isDiscardAlertShowing: true,
      });
    }
  };

  getMinimumHourlyRate = () => {
    const { workerTypesAndRates,workerType } = this.state;
    return workerTypesAndRates.filter((w:any) => w.workerType === workerType)
      .length > 0
      ? workerTypesAndRates.filter((w:any) => w.workerType === workerType)[0]
          .minimumRate / 100
      : 100;
  };

  isWageValid = () => {
    const { workerType } = this.state;
    let errorMessage = '';
    switch (workerType) {
      case workerTypeEnum.dentist:
      case workerTypeEnum.denturist:
      case workerTypeEnum.dentistRDH:
      case workerTypeEnum.dentistRDA:
      case workerTypeEnum.dentistAdministrator:
      case workerTypeEnum.dentistSterilizationAssistant:
      case workerTypeEnum.dentistCDA:
      case workerTypeEnum.dentistCDA2:
      case workerTypeEnum.dentistRDT:
      case workerTypeEnum.dentistDT:
      case workerTypeEnum.dentistChairSideAssistant:
        const lowerBound = this.getMinimumHourlyRate();
        errorMessage = `The minimum rate is $${lowerBound.toFixed(2)}`;
        break;
      default:
        errorMessage = 'Please select a worker type';
        break;
    }

    this.setState({
      wageInvalidErrorText:errorMessage,
    });

  };

  isFormValid = () => {
    const { dateObject,rateObject, workerType, selectedJobCategory } = this.state;
    const isEstDatesValid = dateObject.estEndDate
      ? moment(dateObject.estStartDate).diff(dateObject.estEndDate) < 0
      : true;
    return (
      rateObject.isRateValid &&
      workerType !== '' &&
      selectedJobCategory !== '' &&
      isEstDatesValid
    );
  };


  isPreviewFormValid = () => {
    const { agreeTOS,newFile,signature } = this.state;
    return (agreeTOS && (signature || newFile));
  };
  onSubmittingCreateJob = async () => {

    const {
      pharmacyId,
      pharmacySoftware,
      softwareName,
      softwareRequired,
      wage,
      workerType,
      selectedSpecializations,
      specializations,
      comment,
      pharmacy,
      pharmacyName,
      newFile,
      jobCategories,
      selectedJobCategory,
      jobTitle,
      dateObject,
      ignoreProv
    } = this.state;
    try {
      this.setState({ isSubmitting: true });

      let skills: SkillPayloadDto[] = specializations
        .filter((skill:any) => selectedSpecializations.indexOf(skill.id) > -1)
        .map((skill:any) => {
          return {
            skillId: skill.id,
            name: skill.name,
            required: true,
            pharmacistType: skill.pharmacistType,
            type: skill.type,
          };
        });
      if (pharmacySoftware) {
        skills.push({
          skillId: pharmacySoftware.id,
          name: pharmacySoftware.name,
          required: softwareRequired,
          type: pharmacySoftware.type,
          pharmacistType: pharmacySoftware.pharmacistType,
        });
      }
      const payload: CreateJobDto = {
        pharmacyId: pharmacyId,
        pharmacyName: pharmacyName,
        jobCategoryId:jobCategories.filter(
          (category:any) => category.name === selectedJobCategory,
        )[0].id,
        jobCategoryName: selectedJobCategory,
        comment: comment,
        jobTitle: jobTitle,
        technicianOnSite: false,
        assistantOnSite: false,
        estHourlyRate: (Number(wage) * 100),
        estStartDate: moment(dateObject.estStartDate),
        estEndDate: moment(dateObject.estEndDate),
        skills: skills,
        pharmacistType: workerType as workerTypeEnum,
        allowOtherProvince: ignoreProv

      };



      if (newFile) {
        await AuthService.updateOwner({ signature: newFile });
      }

      console.log("create job on submit payload", payload);
      const newJob = await PharmacyService.createJob(payload);
      console.log("create job on submit new job", newJob);
      this.setState({ isSubmitting: false });
      this.setState({
        createJobSubmitInfo: {
          title: "Your job has been created.",
          message: "Your job posting is now live and will be active for 30 days. At the end of 30 days, you can extend the posting to keep it active.\nYou will be notified when there are new applicants and can search for available candidates by selecting “Find Candidates” in the job details section.",
          buttons: [
            <RBButton
              key={RBButtonType.cancel}
              buttonType={RBButtonType.cancel}
              onClick={() => {
                this.setState({ showModal: false })
                this.setState({ isCreateJobSubmitInfo: false });
                this.props.history.push("/job/detail/"+ newJob.id)
              }}
              style={{color:"white"}}
              text="View Job"
              buttonBackgroundColor={this.context.primaryColor}
            />,
            <RBButton
            buttonBackgroundColor={this.context.primaryColor}
              key={"Discard"}
              onClick={() => {
                this.setState({ showModal: false })
                this.setState({ isCreateJobSubmitInfo: false }, () =>
                  this.props.history.push("/dashboard")
                );
              }}
              color="primary"
              text="Home"
            />,
          ],
        },
        isCreateJobSubmitInfo: true,
      });
    
      } catch (err) {
        //const errorMessage = err.response ? err.response.data.message : err.message;
        const unprocessableError = err.response.data;
        this.setState({ isAlertShowing: true });
        this.setState({ isSubmitting: false });
        if (unprocessableError.error.includes('address')) {

          this.setState({alertInfo:{
            title: "Invalid address",
            message: `Your address must be validated before performing this action, please update your address. If the problem persists please contact support.`,
            buttons: [
    
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  this.setState({isAlertShowing:false})
                  this.props.history.push("/dashboard")
                }}
                buttonBackgroundColor={Colors.themeDarkBlue}
                color="primary"
                text="Home"
              />,
            ],
          }})
        } else {

          this.setState({alertInfo:{
            title: "An Error Occurred",
            message: `${unprocessableError.error}`,
            buttons: [
    
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  this.setState({isAlertShowing:false})
                  this.props.history.push("/dashboard")
                }}
                buttonBackgroundColor={Colors.themeDarkBlue}
                color="primary"
                text="Home"
              />,
            ],
          }})
        }
       
      } finally {
        this.setState({ isSubmitting: false });
      }

  }
  renderCreateShiftActions = () => {
    const { currentCreateJobState, isSubmitting } = this.state;
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          alignItems: "flex-end",
        }}
      >
        {currentCreateJobState === CreateDentalJobState.Create && (
          <RBButton
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
            }}
            buttonBackgroundColor={this.context.secondaryColor}
            onClick={() => {
              if (this.isRateValid()) {
                this.setState({
                  currentCreateJobState: CreateDentalJobState.Confirm,
                });
              }
            }}
            disabled={!this.isFormValid()}
            text="Create Job"
          />
        )}
        {currentCreateJobState === CreateDentalJobState.Confirm && (
          <>
            <RBButton
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
              buttonBackgroundColor={this.context.secondaryColor}
              onClick={() => {
                this.setState({
                  currentCreateJobState: CreateDentalJobState.Create,
                });
              }}
              
              //disabled={true}
              text="Cancel"
            />
            <RBButton
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
              buttonBackgroundColor={this.context.secondaryColor}
              onClick={this.onSubmittingCreateJob}
              disabled={!this.isPreviewFormValid()}
              isLoading={isSubmitting}
              text="Confirm"
            />
          </>
        )}
      </div>
    );
  };

  renderPharmacyName = () => {
    const { pharmacyName } = this.state;
    return (
      <Typography variant="h6" style={{ textAlign: "left" }}>
        {pharmacyName}
      </Typography>
    );
  };


  renderTitle = () => {
    const { jobTitle } = this.state;
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" ,paddingTop: Spacing.medium}}>
          <Typography style={{ display: "flex", flex: 1 }}>
            Title
          </Typography>
          <RBEmptyBlock />
          <FormControl
            variant="outlined"
            size={"small"}
            style={{ display: "flex", flex: 1 }}
          >
            <RBTextField
              className="custom-focused-input"
              style={{
                display: "flex",
                flex: 1,
                maxHeight: "40px",
                borderColor:"black"
              }}
              
              placeholder={"Optional"}
              value={jobTitle}
              onChange={(event) => this.setState({ jobTitle: event.target.value })}
            />
          </FormControl>
        </div>
      </>
    );
  };
  tomorrow = moment()
  .add(1, 'day')
  .toDate();

  convertOffsetToString = (offset:any) => {
    let value = '';
    const absOffset = Math.abs(offset);
    const hr = absOffset / 60;
    const min = absOffset % 60;
    if (offset < 0) {
      value = value + '-';
    }
    else {
      value = value + '-';
    }
    if (hr < 10) {
      value = value + '0' + hr;
    }
    else {
      value = value + hr;
    }
    if (min < 10) {
      value = value + ':0' + min;
    }
    else {
      value = value + ':' + min;
    }
    return value;
  }

  renderJobTime = () => {
    const { dateObject,pharmacyTimezoneOffset } = this.state;
    return (
      <>
        {/* {multipleShifts.map((shift) => ( */}
            <div
              style={{
                ...ColumnStyle, alignItems: "center"
              }}
            >
               <Typography style={{ display: "flex", flex: 1 }}>
               Est. Start Date
              </Typography>
              <RBEmptyBlock />
               <DatePicker
                selected={moment(dateObject.estStartDate).toDate()}
                onChange={(date) => {
                  debugger
                  const offsetString = this.convertOffsetToString(pharmacyTimezoneOffset);
                  let currentDate = moment(date)
                  let year = currentDate.format('YYYY')
                  let month = currentDate.format('MM')
                  let day = currentDate.format('DD')


                  let convertedDate = moment(moment(year + '-' + month + '-' + day).format('YYYY-MM-DDTHH:mm:ss') + offsetString);
                  this.setState({dateObject : {
                    estStartDate: convertedDate.hour(12),
                    estEndDate:this.state.dateObject.estEndDate,
                  }});
                }}
                
                minDate={this.tomorrow}
                dateFormat="MMM do, yyyy"
                customInput={
                  <input
                    style={{
                      paddingTop: Spacing.medium,
                      paddingBottom: Spacing.medium,
                      paddingRight: "30%",
                      borderRadius:5,
                      borderColor:"rgb(220 220 220)",
                      width: 251,
                      fontSize:16,
                      paddingLeft:10
                    }}
                    key={`dateTime`}
                  />
                }
                withPortal
              />
            </div>
            <div
              style={{
                ...ColumnStyle, alignItems: "center"
              }}
            >
               <Typography style={{ display: "flex", flex: 1 }}>
               Est. End Date
              </Typography>
              <RBEmptyBlock />
              <DatePicker
                selected={dateObject.estEndDate !== null ? moment(dateObject.estEndDate).toDate(): null}
                onChange={(date) => {
                  const offsetString = this.convertOffsetToString(pharmacyTimezoneOffset);
                  let currentDate = moment(date)
                  let year = currentDate.format('YYYY')
                  let month = currentDate.format('MM')
                  let day = currentDate.format('DD')


                  let convertedDate = moment(moment(year + '-' + month + '-' + day).format('YYYY-MM-DDTHH:mm:ss') + offsetString);
                  this.setState({dateObject : {
                    estStartDate: this.state.dateObject.estStartDate,
                    estEndDate: convertedDate.hour(12)
                  }});
                }}
                minDate={moment(dateObject.estStartDate).toDate()}
                dateFormat="MMM do, yyyy"
                customInput={
                  <input
                    style={{
                      paddingTop: Spacing.medium,
                      paddingBottom: Spacing.medium,
                      paddingRight:"45%",
                      borderRadius:5,
                      borderColor:"rgb(220 220 220)",
                      width: 251,
                      fontSize:16,
                      paddingLeft:10
                    }}
                    key={`dateTime`}
                  />
                }
                placeholderText="Optional"
                withPortal
              />
            </div>
        {/* ))} */}
      </>
    );
  };

  renderJobType = () => {
    const { selectedJobCategory,jobCategories } = this.state;
    return (
      <div style={{ ...ColumnStyle, alignItems: "center" }}>
        <Typography style={{ display: "flex", flex: 1 }}>
          Job Type
        </Typography>
        <RBEmptyBlock />
        <FormControl
          variant="outlined"
          size={"small"}
          style={{ display: "flex", flex: 1 }}
        >
         <Select
          value={selectedJobCategory}
          displayEmpty
          renderValue={selectedJobCategory !== "" ? undefined : () => "Select"}
          onChange={this.updateJobType}>
          {jobCategories.map((value:any) => (
            <MenuItem value={value.name} key={value.name}>
              {value.name}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </div>
    );
  };


  renderWorkerType = () => {
    const { workerType,workerTypes,workertypeInvalidErrorText } = this.state;
    return (
      <div style={{ ...ColumnStyle, alignItems: "center" }}>
        <Typography style={{ display: "flex", flex: 1 }}>
          Worker Type
        </Typography>
        <RBEmptyBlock />
        <FormControl
          variant="outlined"
          size={"small"}
          style={{ display: "flex", flex: 1 }}
        >
         <Select
          value={workerType}
          displayEmpty
          renderValue={workerType !== "" ? undefined : () => "Select"}
          onChange={this.updateWorkerType}>
          {workerTypes.map((value:any) => (
            <MenuItem value={value.workerType} key={value.workerType}>
              {value.displayName}
            </MenuItem>
          ))}
        </Select>
        {workertypeInvalidErrorText !== EmptyString && <FormHelperText error>{workertypeInvalidErrorText}</FormHelperText>}
        </FormControl>
      </div>
    );
  };

  renderSkills = () => {
    const { selectedSpecializations, specializations } = this.state;
    return (
      <>
      <div style={{ ...ColumnStyle, alignItems: "center" }}>
        <Typography style={{ display: "flex", flex: 1 }}>
          Specialization Skills
        </Typography>
        <RBEmptyBlock />
        <FormControl
          variant="outlined"
          size={"small"}
          style={{ display: "flex", flex: 1 }}
        >
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedSpecializations}
            onChange={this.onSpecializationSkillSelectedChange}
            displayEmpty
            renderValue={(selected) => {
              if ((selected as string[]).length === 0) {
                return ("Add Skills");
              }
              return (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {(selected as string[]).map((skillID) => {
                    return (
                      <div key={skillID} style={{ ...skillTextStyle }}>
                        {
                          specializations.filter(
                            (specialization:any) => specialization.id === skillID
                          )[0].name
                        }
                      </div>
                    );
                  })}
                </div>
              );
            }}
          >
            {specializations.map((specialization:any) => (
              <MenuItem key={specialization.id} value={specialization.id}>
                <Checkbox
                  checked={
                    selectedSpecializations.indexOf(specialization.id) > -1
                  }
                  style={{
                    color:
                      selectedSpecializations.indexOf(specialization.id) > -1
                        ? this.context.secondaryColor
                        : Colors.darkGrey,
                  }}
                />
                <ListItemText primary={specialization.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div
      style={{
        ...ColumnStyle,
        alignItems: "center",
        marginTop: -Spacing.small,
      }}
    >
      <Typography style={{ display: "flex", flex: 1 }}></Typography>
      <RBEmptyBlock />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          fontSize: 12,
          textAlign: "start",
        }}
      >
        *Select only mandatory skills
      </div>
    </div>
    </>
    );
  };
  renderPharmacySpecific = () => {
    const {
      softwareName,
      softwareRequired,
      workerType,
    } = this.state;
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <Typography style={{ display: "flex", flex: 1 }}>
            Software
          </Typography>
          <RBEmptyBlock />
          <FormControlLabel
            style={{
              display: "flex",
              flex: 1,
              color: this.state.softwareRequired
                ? this.context.secondaryColor
                : Colors.darkGrey,
            }}
            control={
              <Checkbox
                checked={softwareRequired}
                style={{
                  color: this.state.softwareRequired
                    ? this.context.secondaryColor
                    : Colors.darkGrey,
                }}
                onChange={() =>
                  this.setState({
                    softwareRequired: !this.state.softwareRequired,
                  })
                }
              />
            }
            label={<Typography>{softwareName}</Typography>}
          />
        </div>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            marginTop: -Spacing.large,
          }}
        >
          <Typography style={{ display: "flex", flex: 1 }}></Typography>
          <RBEmptyBlock />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              fontSize: 12,
              textAlign: "start",
            }}
          >
            {Claims.pharmacySoftwareClaimJob}
          </div>
        </div>
        
      </>
    );
  };

  renderJobDesc = () => {
    const { comment,ignoreProv } = this.state;
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <Typography style={{ display: "flex", flex: 1 }}>Job Description*</Typography>
        </div>
        <RBTextField
          style={{
            display: "flex",
            flex: 1,
            paddingTop: Spacing.small,
            paddingBottom: Spacing.small,
          }}
          placeholder={"*Please include a description and important details about the job."}
          value={comment}
          onChange={(event) => this.setState({ comment: event.target.value })}
          multiline
          rows={3}
          rowsMax={8}
        />
         <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            paddingBottom: Spacing.smallest,
          }}
        >
          <Typography style={{ display: "flex", flex: 1 }}>
          Allow applicants from other provinces?
          </Typography>
          <RBEmptyBlock />
          <FormControlLabel
              style={{
                display: "flex",
                flex: 1,
              }}
              control={
                <Checkbox
                  checked={ignoreProv}
                  style={{
                    color: this.state.ignoreProv
                      ? this.context.secondaryColor
                      : Colors.darkGrey,
                  }}
                  onChange={() =>
                    this.setState({
                      ignoreProv: !this.state.ignoreProv,
                    })
                  }
                />
              }
              label={EmptyString}
            />
        </div>
      </>
    );
  };
  isRateValid = () => {
    const { wage } = this.state;
    const lowerBound = this.getMinimumHourlyRate();
    const rateAmount = parseFloat(wage);
    const result = rateAmount >= lowerBound && isFinite(rateAmount);
    this.setState({rateObject: {
      ...this.state.rateObject,
      isRateValid: result,
    }});
    return result;
  };
  renderWage = () => {
    const { wage, wageInvalidErrorText,workertypeInvalidErrorText} = this.state;
    return (
      <>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            paddingBottom: Spacing.smallest,
          }}
        >
          <Typography style={{ display: "flex", flex: 1 }}>
          Est. Hourly Rate
          </Typography>
          <RBEmptyBlock />
          <RBMoneyAmountTextField
            name="currency"
            value={wage}
            onChange={(event) =>
              {
              this.setState({
                wage:
                  event.target.value == "0.00" ? "0" : event.target.value,
              })
              this.isRateValid();
              if(this.state.workerType === EmptyString || this.state.workerType === "Select"){
                this.setState({workertypeInvalidErrorText :"Please select a worker type"})
              }
            }
            }
            error={wageInvalidErrorText.length > 0}
            errorText={wageInvalidErrorText}
          />
        </div>
       


      </>
    );
  };

  contractPreviewClick = () =>{
    this.setState({showContract:true})
  }
  onContractClose = () =>{
    this.setState({showContract:false})
  }

  setShowTermOfService = (showTermOfService:boolean) =>
    this.setState({ showTermOfService });

    onTermsClose = () =>{
      this.setState({showTermOfService:false})
    }

  previewContent = () => {
    const {
      pharmacyName,
      workerType,
      softwareName,
      selectedSpecializations,
      specializations,
      softwareRequired,
      wage,
      comment,
      agreeTOS,
      signature,
      pharmacy,
      selectedJobCategory,
      jobTitle,
      dateObject,
      rxCount,
      ignoreProv,
      resumeModal,
      networkId,
      ownerData,
      showTermOfService
    } = this.state;
    console.log("signature",signature)

    const software = softwareRequired
      ? [softwareName]
      : [`Not Required (${softwareName})`];

    const finalComment = comment.length > 0 ? comment : NotAvailableString;
    const estStartDateFormatted = dateObject.estStartDate
      ? moment(dateObject.estStartDate).format('MMM Do, YYYY')
      : 'N/A';
    const estEndDateFormatted = moment(dateObject.estEndDate).isValid()
      ? moment(dateObject.estEndDate).format('MMM Do, YYYY')
      : 'N/A';
    return (
      <>
         <RBModal
          open={resumeModal}
          onClose={this.onResumeClose}
          modalTitle={"Signature"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
          children={
              this.rendersignatureContent(signature)
          }
        />

        <div style={ColumnStyle}>
        {RenderTextWithTitle("Dental Practice", pharmacyName)}
        </div>
        <div style={ColumnStyle}>
        {RenderTextWithTitle("Job Title", jobTitle ?? 'N/A')}
        {RenderTextWithTitle("Job Type", selectedJobCategory)}
        </div>
        <div style={ColumnStyle}>
        {RenderTextWithTitle("Est. Start Date", estStartDateFormatted)}
        {RenderTextWithTitle("Est. End Date", estEndDateFormatted)}
        </div>
        <div style={ColumnStyle}>
        {RenderTextWithTitle(
            "Worker Type",
            formatWorkerType(workerType as workerTypeEnum)
          )}
        {RenderTextWithTitle("Est. Hourly Rate", '$'+wage)}
        </div>
        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills("Software", software)}
          {renderTextWithTitleForSkills(
            "Specializations",
            specializations
              .filter((skill:any) => selectedSpecializations.indexOf(skill.id) > -1)
              .map((skill:any) => skill.name)
          )}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Job Description",
            finalComment
          )}
        </div>
        <div style={ColumnStyle}>
          {renderTextWithTitleAndChildComponent(
            "Allow applicants from other provinces?",
            allowOtherProvinceIcon(ignoreProv,this.context.secondaryColor)
          )}
        </div>

        <div style={ColumnStyle}>
          
            {signature && renderTextWithTitleAndChildComponent("Signature", <Link
              // href={signature}
              onClick={() => this.setState({resumeModal :true})}
              style={{ ...detailTextStyle, color: this.context.primaryColor, cursor: 'pointer'}}
            >
              {"View"}
            </Link>)}
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowWrap: "anywhere",
              alignItems: "flex-start",
              alignContent: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflowWrap: "anywhere",

                ...titleHeaderTextStyle,
              }}
            >
             Job Agreement
            </div>

            <Link
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                ...detailTextStyle,
                color: this.context.primaryColor,
                cursor: 'pointer' 
              }}
              onClick={this.contractPreviewClick}
            >
              Preview
            </Link>
          </div>
          {/* {RenderTextWithTitle("Shift Agreement", "Preview")} */}
        </div>

        {!signature && 
        <>
        <SignaturePad
          isVisible={true}
          onLoad = {(file,dataURL) =>{
            this.setState({newFile :file})
          }}

          onSave={(file,dataURL) =>{
          }}
          onCancel={() =>{}}
          onClear={() => {
            localStorage.removeItem("workerSign")
            this.setState({newFile : null})
          }}
          userData = {ownerData}
          page="createShift"
        />
        <div style={{ fontSize: 10 }}>
          *This signature will be saved in your profile as default for
          future usage
        </div>
        </>
        }

        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            marginTop: Spacing.large,
          }}
        >
          <Checkbox
            checked={agreeTOS}
            style={{
              padding: 0,
              paddingRight: Spacing.large,
              // Why marginLeft -2, so  it will align with regular label or text field
              marginLeft: -2,
              color: agreeTOS ? this.context.secondaryColor : Colors.darkGrey,
            }}
            
            onChange={(_, checked) => {
              this.setState({ agreeTOS: checked });
            }}
          />
          <Typography variant="body2">
            I have read and understood the{" "}
            <Link onClick={() => this.setShowTermOfService(true)} style={{color:this.context.primaryColor, cursor: 'pointer',fontWeight:"bold"}}>{HelperPageTitleEnum.termOfService}
            .</Link>
          </Typography>
        </div>
        {showTermOfService && <RBShiftAgreementModal
          open={showTermOfService}
          onClose={this.onTermsClose}
          modalTitle={"Terms of Service"}
          pageName="terms-of-use"
          pageType="page"
          networkId = {networkId}
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
          />
        }
    
      </>
    );
  };

  rendersignatureContent = (signature:any) => {
    console.log("signature",signature)
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          userSelect:"none",
          pointerEvents:"none"

        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            <PDFViewer pdfUrl={signature} title="signature"></PDFViewer>
          </RBCardView>
       
      </div>
    );
  };

  renderCreateJobContent = () => {
    const { currentCreateJobState } = this.state;

    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
        }}
      >
        {currentCreateJobState === CreateDentalJobState.Create && (
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            {this.renderPharmacyName()}
            {this.renderTitle()}
            {this.renderJobType()}
            {this.renderJobTime()}
            
            {this.renderWorkerType()}
            <div style={{ marginTop: Spacing.small }}>
              {this.renderSkills()}
            </div>
            {this.renderPharmacySpecific()}
            {this.renderWage()}
            {this.renderJobDesc()}
          </RBCardView>
        )}
        {currentCreateJobState === CreateDentalJobState.Confirm && (
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              paddingLeft: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            {this.previewContent()}
          </RBCardView>
        )}
      </div>
    );
  };

  render() {
    const {
      isAlertShowing,
      alertInfo,
      showModal,
      isDiscardAlertShowing,
      discardAlertSInfo,
      isCreateJobSubmitInfo,
      createJobSubmitInfo,
      currentCreateJobState,
      isPageLoading,
      showContract,
      networkId,
      pharmacyName,
      dateObject,
      wage,
      workerType,
      pharmacyId
    } = this.state;

    const currentDate = moment().format('MMM-DD-YYYY');
    const currentYear = moment().year();

    const estStartDateFormatted = dateObject.estStartDate
    ? moment(dateObject.estStartDate).format('MMM Do, YYYY')
    : 'N/A';
    const estEndDateFormatted = moment(dateObject.estEndDate).isValid()
    ? moment(dateObject.estEndDate).format('MMM Do, YYYY')
    : 'N/A';

    return (
      <>
       <RBAlert
          show={isAlertShowing}
          alertTitle={alertInfo.title}
          alertMessage={alertInfo.message}
          buttons={alertInfo.buttons}
        />

        <RBErrorAlert
          show={this.state.submitErrorMessage ? true : false}
          errorMessage={JSON.stringify(this.state.submitErrorMessage, null, 2)}
          onClose={() => {
            this.setState({ submitErrorMessage: undefined });
          }}
        />
       
        
        <RBAlert
          show={isDiscardAlertShowing}
          alertTitle={discardAlertSInfo.title}
          alertMessage={discardAlertSInfo.message}
          buttons={discardAlertSInfo.buttons}
        />
        <RBAlert
          show={isCreateJobSubmitInfo}
          alertTitle={createJobSubmitInfo.title}
          alertMessage={createJobSubmitInfo.message}
          buttons={createJobSubmitInfo.buttons}
        />

        {showContract && <RBShiftAgreementModal
          open={showContract}
          onClose={this.onContractClose}
          modalTitle="Job Contract"
          pageName="job-contract-pharmacy"
          pageType="page"
          networkId = {networkId}
          locationId = {pharmacyId}
          type = 'create'
          substitutions={{
            currentDate,
            estEndDate: estEndDateFormatted,
            pharmacyDetails: pharmacyName,
            jobNumber: `xxxx-${currentYear}-x-xx`,
            estStartDate: estStartDateFormatted,
            estHourlyRate: `$${wage}`,
            pharmacistSignature: '',
            pharmacySignature: '',
            licenseNumber: '',
            pharmacistName: workerType,
            pharmacyName: pharmacyName,
            reliefBuddySignature: '',
            workerType: formatWorkerType(workerType as workerTypeEnum),
          }}
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
        />}

      <RBModal
          open={showModal}
          onClose={this.onClose}
          modalTitle={
            currentCreateJobState === CreateDentalJobState.Create
              ? "Create Job"
              : "Confirm Job"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
          actions={!isPageLoading && this.renderCreateShiftActions()}
          children={
            isPageLoading ? (
              <LoadingIndicator />
            ) : (
              this.renderCreateJobContent()
            )
          }
        />
      </>

      
    );
  }
}

