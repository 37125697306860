import React, { useState,useEffect,useRef } from "react";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, AppContainerWidth, Colors,FontSize } from "../../theme/Theme";
import { RBAppbar } from "../../components/RBAppbar";
import { Typography, IconButton } from "@material-ui/core";
import { RBTextField ,RBTextFieldForForm, RBTextFieldForFormFieldType} from "../../components/RBTextField";
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBButton } from "../../components/RBButton";
import { EmptyString } from "../../util/Common";
import { AuthService } from "../../api/AuthService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import { Formik, Form, ErrorMessageProps } from "formik";
import * as Yup from "yup";
import { RoutePathConstant } from "../../util/Common";
import { makeStyles, Link } from "@material-ui/core";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";

const useStyles = makeStyles({
  InputContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: Spacing.larger,
    marginLeft: 3 * Spacing.larger,

    marginRight: 3 * Spacing.larger,
  },
});

export const ForgetPasswordPage = () => {
  const classes = useStyles();
  const [email, setEmail] = useState(EmptyString);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(EmptyString);
  const [showAlertInfo,setShowAlertInfo] = useState(false)
  const [alertInfo,setAlertInfo]  = useState <any> ({title:"",message:"", buttons:[]})
  // const onSubmit = async () => {
  //   try {
  //     setIsSubmitting(true);
  //     await AuthService.forgotPassword(email);
  //   } catch (error) {
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const onArrowBackIconClick = () => {
    history.goBack();
  };
  return (
    <>
    <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={errorMessage}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      />

    <RBAlert
        show={showAlertInfo}
        alertTitle={alertInfo.title}
        alertMessage={alertInfo.message}
        buttons={alertInfo.buttons}
      />

    <RBCardView
      style={{
        padding: Spacing.large,

        marginTop: "10%",
        width: AppContainerWidth,

        display: "flex",
        flex: 1,
        height: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
          //  backgroundColor: "red",
        }}
      >
        <IconButton
          edge="start"
          style={{ color: Colors.themeDarkBlue }}
          onClick={onArrowBackIconClick}
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography variant="h5">Forgot Password</Typography>
      </div>
      <Typography
        variant={"h6"}
        style={{ textAlign: "center", color: Colors.themeDarkBlue }}
      >
        Please enter the email address associated with your account and we will
        send you a link to reset your password.
      </Typography>
      <Formik
        validateOnChange={true}
        initialValues={{
          email: ""
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required("Required"),
        })}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          try {
            setIsSubmitting(true);
            await AuthService.forgotPassword(data.email);
            setAlertInfo({
              title: "Email Sent",
              message: "Check your email for instructions on how to complete resetting your password.",
              buttons: [
                
                <RBButton
                buttonBackgroundColor={Colors.themeDarkBlue}
                  key={"Discard"}
                  onClick={() => {
                    setShowAlertInfo(false)
                    history.push(RoutePathConstant.SignIn)
                  }}
                  color="primary"
                  text="Ok"
                />,
              ],
            })
            setShowAlertInfo(true)

            //await new Promise((resolve) => setTimeout(resolve, 3000));
            //history.push(RoutePathConstant.SignIn);
          } catch (err) {
            console.log("forgot password err", err.response);
            setSubmitErrorMessage(
              err.response ? err.response.data.message : err.message
            );
            if(err.response && err.response.data.message == "The records requested were not found")
            {
              setErrorMessage("Invalid email address")
            }
          } finally {
            isMountedRef.current && setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleBlur,
          isValid,
          handleSubmit,
          handleChange,
          touched,
        }) => (
          <Form onSubmit={handleSubmit} style={{width:700,marginLeft:130}}>
           
            <div
              className={classes.InputContainer}
              style={{ marginBottom: Spacing.xlarge }}
            >
              <RBTextFieldForForm
                placeholder="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
{/*          
            {submitErrorMessage && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  marginTop: Spacing.medium,
                  marginBottom: Spacing.medium,
                  color: "red",
                  fontSize: FontSize.large,
                }}
              >
                {submitErrorMessage}
              </div>
            )} */}

            <div
              style={{
                display: "flex",
                marginTop: 2 * Spacing.larger,
                marginBottom: Spacing.larger,
                paddingLeft: 3 * Spacing.larger,
                paddingRight: 3 * Spacing.large,
              }}
            >
              

              <RBButton
                style={{
                  width: 594,
                  paddingLeft: Spacing.large,
                  paddingRight: Spacing.large,
                  paddingTop: Spacing.medium,
                  paddingBottom: Spacing.medium,
                }}
                buttonBackgroundColor={Colors.themeLightBlue}
                text="Submit"
                isLoading={isSubmitting}
                disabled={!isValid}
                variant="contained"
                type="submit"
              />
            </div>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>
    </RBCardView>
    </>
  );
};
