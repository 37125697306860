import React from "react";
import { Spacing } from "../theme/Theme";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface RBEmptyBlockProps {
  style?: CSSProperties;
}
export const RBEmptyBlock: React.FC<RBEmptyBlockProps> = ({ ...props }) => {
  return (
    <div
      style={{
        width: Spacing.large,
        ...props.style,
      }}
    />
  );
};
