import React, { useState, useRef, useEffect,useContext } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { FieldAttributes, useField, Formik, Form } from "formik";
import {
  EmptyString,
  CanadaProvinces,
  Claims,
  RBAdminEmail,
} from "../util/Common";
import {
  RBTextFieldForForm,
  RBTextField,
  RBSelectForForm,
  RBTextFieldForFormFieldType,
} from "./RBTextField";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { Grid, Typography } from "@material-ui/core";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import { renderTextWithTitleAndChildComponent } from "./TextWithHeader";
import { Spacing } from "../theme/Theme";
import formatService from "../util/FormattingService";
import { RBContext } from "./RBContext";

const getAddressComponentDetail = (
  addressComponent: any,
  componentName: string
) => {
  try {
    const result = addressComponent.filter(
      (component: any) =>
        component.types.filter((type: string[]) => type.includes(componentName))
          .length > 0
    )[0].long_name;
    console.log(`${componentName} is ${result}`);
    return result;
  } catch (error) {
    return null;
  }
};
export const convertGooglePlaceDetail = (details: any) => {
  let resultPlace: {
    street?: string;
    city?: string;
    postalCode?: string;
    country?: string;
    province?: string;
  } = {};
  try {
    const addressComponent = details.address_components;

    const streetNumber = getAddressComponentDetail(
      addressComponent,
      "street_number"
    );
    const route = getAddressComponentDetail(addressComponent, "route");
    const city = getAddressComponentDetail(addressComponent, "locality");
    const addressProvince = getAddressComponentDetail(
      addressComponent,
      "administrative_area_level_1"
    );
    const postalCode = getAddressComponentDetail(
      addressComponent,
      "postal_code"
    );
    const country = getAddressComponentDetail(addressComponent, "country");
    resultPlace = {
      street: streetNumber + " " + route,
      city: city,
      postalCode: postalCode,
      country: country,
    };
    if (
      country === "Canada" &&
      CanadaProvinces.filter(
        (province: any) => province.label === addressProvince
      ).length > 0
    ) {
      resultPlace.province = CanadaProvinces.filter(
        (province: any) => province.label === addressProvince
      )[0].abbr;
      console.log(`province ${resultPlace.province}`);
    }
    console.log("resultPlace :", resultPlace);
    return resultPlace;
  } catch (error) {
    console.log("resultPlace error:", error);
    return null;
  }
};

const renderOption = (option: any) => {
  return (
    <Grid container alignItems="center">
      <Grid item>
        <LocationOnIcon />
      </Grid>
      <Grid item xs>
        <Typography variant="body2" color="textSecondary">
          {/* {option.structured_formatting.secondary_text} */}
          {option}
        </Typography>
      </Grid>
    </Grid>
  );
};

type RBPlaceSearchForFormProps = {
  label?: string;
  setFieldValue: any;
} & FieldAttributes<{}> &
  TextFieldProps;

export const RBPlaceSearchForForm: React.FC<RBPlaceSearchForFormProps> = ({
  ...props
}) => {
  const [field, meta] = useField(props);
  const { value, onChange, ...rest } = field;
  const [street, updateAddress] = useState(props.value);

  const thisTextField = useRef();
  const errorText = meta.error && meta.touched ? meta.error : EmptyString;
  useEffect(() => {
    console.log("RBPlaceSearchForForm props value", props.value);
    updateAddress(props.value);
  }, [props.value]);
  const handleSelect = (address: string) => {
    geocodeByAddress(address)
      .then((results) => {
        console.log("results ", results);

        const addressObject = convertGooglePlaceDetail(results[0]);
        if (addressObject) {
          console.log("addressObject ", addressObject);
          updateAddress(addressObject.street ?? EmptyString);
          props.setFieldValue(addressObject);
          // props.setFieldValue("street", addressObject.street, true);
          // props.setFieldValue("city", addressObject.city, true);
          // props.setFieldValue("postalCode", addressObject.postalCode, true);
          // if (addressObject.province) {
          //   props.setFieldValue("province", addressObject.province, true);
          // }

          // props.setFieldValue('city',addressObject.city,true);
        } else {
          console.log("addressObject is empty ");
        }

        return getLatLng(results[0]);
      })
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
    console.log("handleSelect", address);
  };

  const placesAutocompleteOnChange = (address: string) => {
    console.log("placesAutocompleteOnChange ", address);
    updateAddress(address);
    //props.setFieldValue("street", address, true);
  };

  return (
    <PlacesAutocomplete
      value={street}
      onChange={placesAutocompleteOnChange}
      onSelect={handleSelect}
      searchOptions={{ componentRestrictions: { country: "ca" } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextField
            size="small"
            style={{ width: "100%" }}
            {...getInputProps({
              placeholder: "Search Places ...",
            })}
            {...rest}
            helperText={errorText}
            error={!!errorText}
            innerRef={thisTextField}
            label={props.label}
            //  placeholder="Search Places ..."
            variant="outlined"
          />

          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={uuidv4()}
                >
                  {/* <span>{suggestion.description}</span> */}
                  {renderOption(suggestion.description)}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export interface RBPlaceSearchForForm2ValueTypes {
  street: string;
  city: string;
  postalCode: string;
  province: string;
  apartmentSuiteNumber?: string | undefined;
}
interface RBPlaceSearchForForm2Props {
  innerRef?: any;
  onFormChange?: any;
  isProvinceEditable: boolean;
  initialValues: RBPlaceSearchForForm2ValueTypes;
}

const AddressSchema = Yup.object().shape({
  street: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  postalCode: Yup.string().required("Required"),
  province: Yup.string().required("Required"),
});
export const RBPlaceSearchForForm2: React.FC<RBPlaceSearchForForm2Props> = ({
  isProvinceEditable,
  ...props
}) => {
  console.log(
    "isProvinceEditable ",
    isProvinceEditable,
    props.initialValues.province
  );
  const { industryMode,primaryColor,secondaryColor, contactEmail } = useContext(RBContext);

  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={AddressSchema}
      validateOnBlur={true}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={AddressSchema.isValidSync(props.initialValues)}
      onSubmit={() => {}}
    >
      {({ values, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
        <div
        // onSubmit={handleSubmit}
        // basically call onFormChange to check if the form is valid
        // onKeyUp={props.onFormChange}
        // onMouseDown={props.onFormChange}
        >
          {renderTextWithTitleAndChildComponent(
            "Street",
            <RBPlaceSearchForForm
              name="street"
              value={values.street}
              setFieldValue={(addressObject: any) => {
                if (
                  isProvinceEditable ||
                  (isProvinceEditable === false &&
                    props.initialValues.province === addressObject.province)
                ) {
                  setFieldValue("street", addressObject.street, true);
                  setFieldValue("city", addressObject.city, true);
                  setFieldValue("postalCode", addressObject.postalCode, true);
                  setFieldValue("province", addressObject.province, true);
                } else {
                  console.log("not editable, ", props.initialValues.street);
                  setFieldValue("street", props.initialValues.street, true);
                }
              }}
              onBlur={handleBlur}
            />
          )}
          <div
            style={{
              marginBottom: Spacing.large,
            }}
          />
          {renderTextWithTitleAndChildComponent(
            "Apartment/Suite #",
            <RBTextFieldForForm
              placeholder="Optional"
              name="apartmentSuiteNumber"
              value={values.apartmentSuiteNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              size={"small"}
              style={{
                marginBottom: Spacing.large,
              }}
            />
          )}

          {renderTextWithTitleAndChildComponent(
            "City",
            <RBTextFieldForForm
              //label="City"
              name="city"
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
              size={"small"}
              style={{
                marginBottom: Spacing.large,
              }}
            />
          )}
          {renderTextWithTitleAndChildComponent(
            "Postal Code",
            <RBTextFieldForForm
              //label="Postal Code"
              FieldType={RBTextFieldForFormFieldType.postalCode}
              name="postalCode"
              value={values.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
              size={"small"}
              style={{
                marginBottom: Spacing.large,
              }}
            />
          )}

          {isProvinceEditable
            ? renderTextWithTitleAndChildComponent(
                "Province",
                <RBSelectForForm
                  name="province"
                  value={values.province}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  mappingValues={CanadaProvinces.map(
                    (province: any) => province.abbr
                  )}
                  style={{
                    marginBottom: Spacing.large,
                  }}
                />
              )
            : renderTextWithTitleAndChildComponent(
                "Province",
                <div
                  style={{
                    marginBottom: Spacing.large,
                  }}
                >
                  <RBTextFieldForForm
                    //label="Postal Code"
                    disabled
                    name="province"
                    value={values.province}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size={"small"}
                  />
                  {/* <Typography variant={"caption"}>
                    {Claims.changeProvince}
                  </Typography> */}
                  <Typography variant={"caption"}>
                    {Claims.changeProvince + " "}
                    <Typography
                      variant={"inherit"}
                      style={{ display: "inline" }}
                    >
                      {formatService.formatEmail(contactEmail, primaryColor)}
                    </Typography>
                  </Typography>
                </div>
              )}
        </div>
      )}
    </Formik>
  );
};
