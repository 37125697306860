import React, { useState, useEffect,useContext } from "react";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { Avatar, Link } from "@material-ui/core";
import { Spacing, FontSize, Colors } from "../../theme/Theme";
import { StarRatings } from "../../components/StarRatings";
import formatService, {
  getMonthAndYearComparedToNow,
  formatWorkerType,
  getMonthAndYearByMonth,
} from "../../util/FormattingService";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { NotAvailableString } from "../../util/Common";
import { ColumnStyle } from "../../theme/ComponentTheme";
import {
  RenderTextWithTitle,
  renderTextWithTitleForSkills,
  renderTextWithTitleAndChildComponent,
  detailTextStyle,
} from "../../components/TextWithHeader";
import { RBCardView } from "../../components/RBCardView";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { workerTypeEnum } from "../../util/Enumeration";
import { ShiftService } from "../../api/ShiftService";
import { ApplicantState } from "../../interfacesProps/SetApplicantStateByPharmacyProps";
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import {
  useHistory,
  RouteComponentProps,
  withRouter,
  useLocation,
} from "react-router";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { ShiftProps } from "../../interfacesProps/ShiftProps";
import { RBContext } from "../../components/RBContext";
import moment from 'moment';
import { IndustryModeEnum } from "../../api/constants";
import { AuthService } from "../../api/AuthService";
import RBPlacementFeeModal from "../../components/RBPlacementFeeModal";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import PDFViewer from "../../components/PdfViewer";

interface JobApplicantsPageWithoutRouterProps
  extends RouteComponentProps<{ jobId: string }> {}


  interface PdfDataUrls {
    [key: string]: string;
  }

const JobApplicantsPageWithoutRouter: React.FC<JobApplicantsPageWithoutRouterProps> = ({
  match,
}) => {
  const [jobId] = useState(match.params.jobId);
  const [jobData, setjobData] = useState<ShiftProps>({});
  const [reloadPage, setReloadPage] = useState(false);
  const [applicants, setApplicants] = useState<ObjectLiteral[]>([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [extendeds, setExtendeds] = useState<Set<string>>(new Set());
  const [isAlertShowing, setAlertShowing] = useState(false);
  const [alertInfo, setAlertInfo] = useState<AlertInfoProps>({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [account, setAccount] = useState<ObjectLiteral[]>([]);
  const [resumeModal, setReumeModal] = useState(false);
  const [pdfDataStatus, setPdfDataStatus] = useState<{ [key: number]: boolean }>({});
  const [selectedApplicant, setSelectedApplicant] = useState<any>(null); // Store the selected applicant
  const [numPages, setNumPages] = useState<any>(null);

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {

        localStorage.removeItem("downloadedPdf")
        setIsPageLoading(true);

        const userData = await AuthService.loadAccountInfo();

        const applicantsData = await ShiftService.getJobApplicantsByJobId(
          match.params.jobId,userData.networks[0].id
        );
        const jobDetailData = await ShiftService.getJobtDetailByJobId(
          match.params.jobId
        );
        setAccount(userData);
        setjobData(jobDetailData);
        setApplicants(applicantsData.data);
        
        
      } catch (err) {
        setSubmitErrorMessage(err.response ? err.response.data : err.message);
        console.log("JobApplicantsPageWithoutRouter error", err);
      } finally {
        setIsPageLoading(false);
      }
    };

    fetchData();
  }, [reloadPage]);

  // useEffect(() => {
  //   applicants.forEach(applicant => {
  //     if (!pdfDataStatus[applicant.id]) {
  //       fetchAndStorePdfData(applicant);
  //     }
  //   });
  // }, [applicants, pdfDataStatus]);


  const fetchAndStorePdfData = async (applicant:any) => {
    console.log(" fffff",applicant)
    const resumeUrl = applicant?.resume;
    
    axios.get(resumeUrl, { responseType: 'arraybuffer' })
      .then(response => {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfData = URL.createObjectURL(pdfBlob);
        
        setSelectedApplicant((prevApplicants:any) => {
              return {
                ...prevApplicants,
                pdfDataUrl: pdfData,
              }
        });
      })
      .catch(() => {
        console.log('Error fetching PDF data.');
      });
  };

  // useEffect(() => {
  //   // Loop through your applicants and fetch PDF data
  //   applicants.forEach(applicant => {
  //     fetchAndStorePdfData(applicant);
  //   });
  // }, [applicants]);

  const setExtendedsForPharmacist = (pharmacistId: string) => {
    //const { extendeds } = this.state;
    if (extendeds.has(pharmacistId)) {
      extendeds.delete(pharmacistId);
      //Since react will identify state changes only if the state property was replaced, and not mutated (shallow compare), you'll have to create a new Set from the old one, and apply the changes to it.

      setExtendeds(new Set(extendeds));
    } else {
      extendeds.add(pharmacistId);
      setExtendeds(new Set(extendeds));
    }
  };

  const renderSkills = (worker: ObjectLiteral) => {
    const skills = worker.skills || [];

    const softwareType =
      industryMode === IndustryModeEnum.Dentistry
        ? 'dentalSoftware'
        : 'software';

    const languages = skills
      .filter((skill: ObjectLiteral) => skill.type === "language")
      .map((skill: ObjectLiteral) => skill.name);
    const specializations = skills
      .filter((skill: ObjectLiteral) => skill.type === "specialization")
      .map((skill: ObjectLiteral) => skill.name);
    const software = skills
      .filter((skill: ObjectLiteral) => skill.type === softwareType)
      .map((skill: ObjectLiteral) => skill.name);

    return (
      <>
        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills("Languages", languages)}
        </div>
        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills("Software", software)}
        </div>
        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills("Specializations", specializations)}
        </div>
      </>
    );
  };
  const  renderRequestedWage = (requestedWage:any) => {

    const allowOffers = jobData.allowOffers;

    if (allowOffers) {
      if (jobData.pharmacy?.networkPaid) {
        //if (this.state.workerFee == null) {
          jobData.requestedWage = requestedWage;
          const workerFee = requestedWage/100 ;
         // this.setState({ workerFee: workerFee });
        //}

        return (
          RenderTextWithTitle(
            'Requested Rate',
            '$' + workerFee.toFixed(2),
          )
        )
      }
      else {
        return (
          RenderTextWithTitle(
            'Requested Rate',
            '$' + (requestedWage / 100).toFixed(2),
          )
        )
      }
    } else{
      return null
    }
  };
  
  const renderName = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    const name = `${pharmacist.firstName} ${pharmacist.lastName}`;
    const workerType = formatWorkerType(pharmacist.type);
    const rating = applicant.rating.averageRating ?? 0;
    const numberRatings = applicant.rating.numberRatings ?? 0;
    return (
      <>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <div
            style={{
              fontSize: FontSize.largest,

              fontWeight: "bold",
              flexWrap: "wrap",
            }}
          >
            {name}
          </div>

          <StarRatings rating={rating} numberReviews={numberRatings} page="jobDetails"/>
        </div>
      </div>
     
     </>
    );
  };

  const getAllowanceCostWithTax = (worker: any) => {
    const taxRate = (jobData.salesTaxRate ?? 0) / 100;
    const allowanceCost =
      ((Math.round((worker.distance * 2) / 1000) * jobData.kmAllowance) /
        100 +
        jobData.accomodationAllowance / 100) *
      (1 + taxRate);
    return allowanceCost;
  };
  const renderStudent = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    const showExtended = extendeds.has(applicant.id);
    const email = applicant.email;
    const phone = pharmacist.phone;

    return (
      <>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Type", formatWorkerType(pharmacist.type))}
          {RenderTextWithTitle(
            "Assist Experience",
            getMonthAndYearByMonth(pharmacist.pharmacyAssistantExperienceMonths)
          )}
          {RenderTextWithTitle("Academic Year", pharmacist.currentSchoolYear)}
         
          
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Institution", pharmacist.graduationInstitution)}
          {RenderTextWithTitle("Registration #", pharmacist.licenseNumber)}
          {RenderTextWithTitle("City", pharmacist.address.city)}
        
        </div>
        <div style={ColumnStyle}>
          {renderResume(pharmacist)}
          {RenderTextWithTitle("Contact Phone", formatService.formatPhoneNumber(phone))}
          {RenderTextWithTitle("", "")}

        </div>

        <>{showExtended && renderSkills(pharmacist)}</>
      </>
    );
  };
  const renderAssistant = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    const yearGraduated = pharmacist.graduationYear;
    const showExtended = extendeds.has(applicant.id);
    const email = applicant.email;
    const phone = pharmacist.phone;

    return (
      <>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Type", formatWorkerType(pharmacist.type))}
          {RenderTextWithTitle(
            "Assist Experience",
            getMonthAndYearByMonth(pharmacist.pharmacyAssistantExperienceMonths)
          )}
           {RenderTextWithTitle(
            "Assist Course",
            pharmacist.pharmacyAssistantCourseCompleted ? "Yes" : "No"
          )}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Graduate From",
            pharmacist.graduationInstitution
          )}
          {RenderTextWithTitle("Graduate In", yearGraduated)}
          {RenderTextWithTitle("City", pharmacist.address.city)}
          
        </div>
        <div style={ColumnStyle}>
          {renderResume(pharmacist)}
          {RenderTextWithTitle("Contact Phone", formatService.formatPhoneNumber(phone))}
          {RenderTextWithTitle("", "")}

        </div>

        <>{showExtended && renderSkills(pharmacist)}</>
      </>
    );
  };
  const renderPharmacistAndTechnician = (applicant: ObjectLiteral) => {
    console.log("renderPharmacistAndTechnician ", applicant);
    const { pharmacist } = applicant;
    const yearGraduated = pharmacist.graduationYear;
    const licensedDate = pharmacist.licensedDate
      ? getMonthAndYearComparedToNow(pharmacist.licensedDate)
      : NotAvailableString;
    const showExtended = extendeds.has(applicant.id);
    const email = applicant.email;
    const phone = pharmacist.phone;
    return (
      <>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("", formatWorkerType(pharmacist.type))}
          {RenderTextWithTitle("License Number", pharmacist.licenseNumber)}
          {RenderTextWithTitle("Years Licensed", licensedDate)}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Graduate From",
            pharmacist.graduationInstitution
          )}
          {RenderTextWithTitle("Graduate In", yearGraduated)}
          {renderResume(pharmacist)}

        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Licensed In", pharmacist.licenseProvince)}
          {RenderTextWithTitle("City", pharmacist.address.city)}

          {RenderTextWithTitle("Contact Phone", formatService.formatPhoneNumber(phone))}

        </div>



        <>{showExtended && renderSkills(pharmacist)}</>
      </>
    );
  };

  const renderLicensedDentistWorker = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    const showExtended = extendeds.has(applicant.id);
    const email = applicant.email;
    const phone = pharmacist.phone;

    const licenseExpiryDate = pharmacist.licenseExpiryDate
      ? moment(pharmacist.licenseExpiryDate).format('YYYY - MM')
      : 'N/A';
    const licensedDate = pharmacist.licensedDate
      ? getMonthAndYearComparedToNow(pharmacist.licensedDate)
      : 'N/A';


    return (
      <>
        <div style={ColumnStyle}>
          {RenderTextWithTitle('', formatWorkerType(pharmacist.type))}
          {RenderTextWithTitle("Registration #", pharmacist.licenseNumber)}
          {RenderTextWithTitle("Years Licensed", licensedDate)}
          
        </div>
        <div style={ColumnStyle}>
        {RenderTextWithTitle("Registered In", pharmacist.licenseProvince)}
          {RenderTextWithTitle("Expires On", licenseExpiryDate)}

          {RenderTextWithTitle(
            "City",
            pharmacist.address.city
          )}
          
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Graduated From", pharmacist.graduationInstitution,)}
          {RenderTextWithTitle(
            "Graduated In",
            pharmacist.graduationYear,
          )}
          {renderResume(pharmacist)}
          
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Contact Phone", formatService.formatPhoneNumber(phone))}

        </div>

        {showExtended && 
          <>
          {renderSkills(pharmacist)}</>
        }
      </>
    );
  };

  const renderNonLicensedDentistWorker = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    const showExtended = extendeds.has(applicant.id);
    const email = applicant.email;
    const phone = pharmacist.phone;


    return (
      <>
     
        <div style={ColumnStyle}>
        {RenderTextWithTitle('', formatWorkerType(pharmacist.type))}
          {RenderTextWithTitle("Graduated From", pharmacist.graduationInstitution,)}
          {RenderTextWithTitle(
            "Graduated In",
            pharmacist.graduationYear,
          )}
         
          
        </div>
        <div style={ColumnStyle}>
        {RenderTextWithTitle(
            "Experience",
            getMonthAndYearByMonth(
              pharmacist.pharmacyAssistantExperienceMonths,
            ),
          )}
          {RenderTextWithTitle(
            "City",
            pharmacist.address.city
          )}
          {renderResume(pharmacist)}
          
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Contact Phone", formatService.formatPhoneNumber(phone))}

        </div>

        {showExtended && 
        <>
        {renderSkills(pharmacist)}</>
        }
      </>
    );
  };

  const openResumeModal = (applicant: any) => {
    console.log("open",applicant)
    setSelectedApplicant(applicant);
    fetchAndStorePdfData(applicant); // Fetch and store PDF data when the modal is opened
    setReumeModal(true);
  };

  

  const renderResume = (worker: ObjectLiteral) => {
    console.log("renderResume ", worker.resume);
    return (
      <>
        {worker.resume
          ? renderTextWithTitleAndChildComponent(
              "Resume",
              <Link
                onClick={() => openResumeModal(worker)}
                style={{ ...detailTextStyle, color: primaryColor, cursor: 'pointer' }}
              >
                {"View"}
              </Link>
            )
          : RenderTextWithTitle("Resume", NotAvailableString)}
      </>
    );
  };
  const selectWorker = async (workerUserId: string) => {
    try {
      setIsButtonLoading(true);
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      await ShiftService.setJobApplicantStateByPharmacy({
        jobId: jobId,
        pharmacistId: workerUserId,
        state: ApplicantState.approved,
      });
      history.goBack();
    } catch (err) {
      setSubmitErrorMessage(err.response ? err.response.data : err.message);
    } finally {
      setIsButtonLoading(false);
    }
  };
  const rejectWorker = async (workerUserId: string) => {
    try {
      setIsButtonLoading(true);
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      // console.log("rejectWorker ", {
      //   shiftId: this.props.shiftId,
      //   pharmacistId: workerUserId,
      //   state: ApplicantState.denied,
      // });
      await ShiftService.setJobApplicantStateByPharmacy({
        jobId: jobId,
        pharmacistId: workerUserId,
        state: ApplicantState.denied,
      });
      history.goBack();
      //setReloadPage((pre) => !pre);
    } catch (err) {
      setSubmitErrorMessage(err.response ? err.response.data : err.message);
    } finally {
      setIsButtonLoading(false);
    }
  };
  const onSelection = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    const name = `${pharmacist.firstName} ${pharmacist.lastName}`;
    setAlertInfo({
      title: `Confirm ${formatWorkerType(pharmacist.type)}`,
      message: `Hire ${name} for job?`,
      buttons: [
        <RBButton
        key="Confirm"
        onClick={async () => {
          setAlertShowing(false);
          await selectWorker(applicant.id);
        }}
        text="Confirm"
        style={{background:primaryColor}}
      />,
        <RBButton
          key="Cancel"
          onClick={() => {
            setAlertShowing(false);
          }}
          style={{background:primaryColor}}
          text="Cancel"
        />,
       
      ],
    });
    setAlertShowing(true);
  };

  const onReject = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    const name = `${pharmacist.firstName} ${pharmacist.lastName}`;
    setAlertInfo({
      title: `Reject ${formatWorkerType(pharmacist.type)}`,
      message: `Candidate ${name} will no longer be eligible for this job.`,
      buttons: [
        <RBButton
          key={"Confirm"}
          onClick={async () => {
            setAlertShowing(false);
            await rejectWorker(applicant.id);
          }}
          text="Confirm"
          style={{background:primaryColor}}
        />,
        <RBButton
          key={"Cancel"}
          onClick={() => {
            setAlertShowing(false);
          }}
          style={{background:primaryColor}}
          text="Cancel"
        />,
        
      ],
    });
    setAlertShowing(true);
  };
  const renderAlerts = () => {
    return (
      <RBAlert
        show={isAlertShowing}
        alertTitle={alertInfo.title}
        alertMessage={alertInfo.message}
        buttons={alertInfo.buttons}
      />
    );
  };
  const renderApplicant = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    console.log("renderApplicant ", selectedApplicant);
    const showExtended = extendeds.has(applicant.id);
    let associationLogo = null;
    const userAssociationMember = applicant.userAssociationMember || [];
    if (userAssociationMember.length > 0) {
      associationLogo = userAssociationMember[0].association.logo;
    }
    const rbVerified = (applicant.pharmacist?.approval && applicant.pharmacist?.approval?.rbVerified) ? applicant.pharmacist?.approval?.rbVerified : false;


    return (
      <>
      {selectedApplicant && <RBModal
          open={resumeModal}
          onClose={onResumeClose}
          modalTitle={"Resume"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
          children={
              renderRequestedRateContent(selectedApplicant)
          }
        />}
      
     
      <div
        style={{
          display: "flex",
          width: "100%",
          paddingLeft: Spacing.large,
          paddingRight: Spacing.large,
          paddingTop: Spacing.large,
          paddingBottom: Spacing.large,
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* Avatar and association logo */}
          <div>
            <Avatar
              style={{ height: 124, width: 124 }}
              // src={pharmacist.profilePhoto}
              src={pharmacist.profilePhoto}
            >
              :(
            </Avatar>
            {associationLogo && (
              <>
              <img
                src={associationLogo}
                alt={"associationLogo"}
                style={{
                  width: 96,
                  height: 36,
                  marginTop: 4,
                  marginBottom: 4,
                }}
              />
              <br></br>
              </>
            )}

{
              (rbVerified === false
                && applicant.networks[0].id === "7b89c1df-df98-41e8-a06c-f3d1d748de84"
                && jobData.pharmacy?.networkId === "7b89c1df-df98-41e8-a06c-f3d1d748de84") ? null :
              <img
                src={
                  jobData.pharmacy?.networkId === applicant?.networks[0].id ?
                    require('../../img/50x50-01.png')
                    : require('../../img/100x100-02-01.png')
                }
                alt={"networkIcon"}
                style={{
                  // width: 70,
                  height: 50,
                  marginLeft: 37
                }}
              />
            }
              
            </div>
          <RBButton
            buttonBackgroundColor={primaryColor}
            onClick={() => setExtendedsForPharmacist(applicant.id)}
            text={`View ${showExtended ? "less" : "more"}`}
          />
        </div>
        {/* Applicant Detail */}
        <div style={{ flex: 1, marginLeft: Spacing.larger }}>
          {renderName(applicant)}
          {(pharmacist.type === workerTypeEnum.pharmacistStudent ||
            pharmacist.type === workerTypeEnum.technicianStudent) &&
            renderStudent(applicant)}
          {pharmacist.type === workerTypeEnum.assistant &&
            renderAssistant(applicant)}
          {(pharmacist.type === workerTypeEnum.pharmacist ||
            pharmacist.type === workerTypeEnum.technician) &&
            renderPharmacistAndTechnician(applicant)}

          {(pharmacist.type === workerTypeEnum.dentist ||
              pharmacist.type === workerTypeEnum.dentistRDA ||
              pharmacist.type === workerTypeEnum.dentistCDA ||
              pharmacist.type === workerTypeEnum.dentistCDA2 ||
              pharmacist.type === workerTypeEnum.dentistRDT ||
              pharmacist.type === workerTypeEnum.dentistRDH ||
              pharmacist.type === workerTypeEnum.denturist) &&
              renderLicensedDentistWorker(applicant)}
            {(pharmacist.type === workerTypeEnum.dentistAdministrator ||
              pharmacist.type === workerTypeEnum.dentistSterilizationAssistant ||
              pharmacist.type === workerTypeEnum.dentistDT ||
              pharmacist.type === workerTypeEnum.dentistChairSideAssistant) &&
              renderNonLicensedDentistWorker(applicant)}

          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              marginTop: Spacing.medium,
            }}
          >
            <RBButton
              isLoading={isButtonLoading}
              text="Select"
              onClick={() => onSelection(applicant)}
              style={{
                flex: 1,
                marginRight: 4,
                padding: 8,
                // marginVertical: 8,
              }}
              buttonBackgroundColor={secondaryColor}
            />
            <RBButton
              isLoading={isButtonLoading}
              text="Reject"
              onClick={() => onReject(applicant)}
              style={{
                flex: 1,
                marginLeft: 4,
                padding: 8,
                // marginVertical: 8,
              }}
              buttonBackgroundColor={primaryColor}
            />
            
          </div>
        </div>
      </div>
      </>
    );
  };
  const onResumeClose= () => {
    setReumeModal(false)
    setSelectedApplicant(null);
   };
   const renderRequestedRateContent =  (applicant:any) => {
    
    console.log("selectedApplicant",applicant)
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          userSelect:"none",
          pointerEvents:"none"

        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
              <Document  file={applicant.pdfDataUrl}
              onLoadSuccess={({ numPages })=>setNumPages(numPages)}
              onLoadError={(error) => console.error('PDF Load Error:', error)}>
                {/* <Page pageNumber={2} /> */}
                {Array.apply(null, Array(numPages))
                .map((x, i)=>i+1)
                .map(page => <Page pageNumber={page}/>)}
              </Document>
          </RBCardView>
       
      </div>
    );
  };

  return (
    <>
     
      {renderAlerts()}
      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={JSON.stringify(submitErrorMessage, null, 2)}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      />
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <div style={{ marginTop: Spacing.large, marginBottom: Spacing.large }}>
          {applicants.map((applicant) => (
            <div
              key={applicant.id}
              children={
                <RBCardView
                  marginHorizontal={Spacing.large}
                  style={{ marginBottom: Spacing.large }}
                  children={renderApplicant(applicant)}
                />
              }
            />
          ))}
        </div>
      )}
    </>
  );
};

export const JobApplicantsPage = withRouter(JobApplicantsPageWithoutRouter);
