import React, { CSSProperties,useContext,useState } from "react";
import { ObjectLiteral } from "../interfacesProps/ObjectLiteralProps";
import { FontSize, Colors, Spacing } from "../theme/Theme";
import { skillTextStyle } from "../theme/ComponentTheme";
import { RBContext } from "./RBContext";
import { IconButton } from "@material-ui/core";
import { AiFillQuestionCircle } from "react-icons/ai";
import { RBModal } from "./RBModal";
import { RBEmptyBlock } from "./RBEmptyBlock";
// import { RBPlacementFeeModal } from "./RBPlacementFeeModal";
import { RBAlert } from "./RBAlert";


export const titleHeaderTextStyle: CSSProperties = {
  color: Colors.darkGrey,
  fontSize: FontSize.default,
};
export const detailTextStyle: CSSProperties = {
  fontWeight: "bold",
  fontSize: FontSize.large,
};
export const RenderTextWithTitle = ( title:string, detail:string ) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflowWrap: "anywhere",
        alignItems: "flex-start",
        alignContent: "flex-start",
        justifyContent: "flex-start",
        width:100
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          overflowWrap: "anywhere",

          ...titleHeaderTextStyle,
        }}
      >
        {title}
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          whiteSpace: "pre-wrap",
          overflowWrap: "anywhere",
          ...detailTextStyle,
        }}
      >
        {detail}
      </div>
    </div>
  );
};










export const renderTextWithTitleAndChildComponent = (
  title: string,
  detail: ObjectLiteral
) => {
  return (
    <div style={{ flex: 1, alignItems: "flex-start" }}>
      <div
        style={{
          display: "flex",
          flex: 1,
          overflowWrap: "anywhere",
          ...titleHeaderTextStyle,
        }}
      >
        {title}
      </div>
      {detail}
    </div>
  );
};

export const renderTextWithTitleForSkills = (
  title: string,
  skills: string[]
) => {
  const skillsAvailable = skills.length > 0;
  return (
    <div style={{ flex: 1, alignItems: "flex-start" }}>
      {skillsAvailable ? (
        <div>
          <div
            style={{
              display: "flex",
              flex: 1,
              overflowWrap: "anywhere",
              ...titleHeaderTextStyle,
            }}
          >
            {title}
          </div>
          <div
            style={{
              flexDirection: "row",
              flex: 1,
              flexWrap: "wrap",
            }}
          >
            {skills.map((skillsName) => {
              return (
                <div key={JSON.stringify(skillsName)} style={skillTextStyle}>
                  {skillsName}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              flex: 1,
              overflowWrap: "anywhere",
              ...titleHeaderTextStyle,
            }}
          >
            {title}
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              overflowWrap: "anywhere",
              ...detailTextStyle,
            }}
          >
            N/A
          </div>
        </div>
      )}
    </div>
  );
};
