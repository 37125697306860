import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

type Props = RouteComponentProps<{ info: string }>;

export const ErrorPage: React.FC<Props> = ({ match }) => {
    console.log(match);

    // React.useEffect(() => {
    //     fetch(``);
    // }, [match.params.id]);
    return <div>rendering Errors {match.params.info}</div>;
};
