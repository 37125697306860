import React, { useState, useEffect ,useContext, useRef} from "react";
import { ShiftProps } from "../../interfacesProps/ShiftProps";
import { AuthService } from "../../api/AuthService";
import { IndustryModeEnum, roleNameEnum, RoutePathConstant } from "../../util/Common";
import { ShiftService } from "../../api/ShiftService";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Spacing, FontWeight, FontSize, Font } from "../../theme/Theme";
import { RBCardView } from "../../components/RBCardView";
import moment from "moment-timezone";
import { IconButton, Tabs, Typography,Paper } from "@material-ui/core";
import { ColumnStyle } from "../../theme/ComponentTheme";
import {
  detailKmAllowanceIcon,
  detailAccomodationAllowanceIcon,
} from "../../util/Icons";
import { useHistory } from "react-router";
import { RBContext } from '../../components/RBContext';
import { RBAppbar } from "../../components/RBAppbar";
import TuneIcon from "@material-ui/icons/Tune";
import { RBModal } from "../../components/RBModal";
import FormatListBulletted from "@material-ui/icons/FormatListBulleted";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { paymentTypeEnum } from "../../util/Enumeration";

const styles = {
  container: {
    flex: 1,
  },
  itemContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  itemColumns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  itemLeft: {
    flex: 1,
    marginRight: 5,
  },
  itemsRight: {
    flexDirection: "column",
    marginLeft: 5,
  },
};
const lastColumnWidth = 90;
export const PayrollPage = () => {
  const { primaryColor,secondaryColor,industryMode } = useContext(RBContext);
  const [shifts, setShifts] = useState<ShiftProps[]>([]);
  const [roleName] = useState<string>(AuthService.getRoleName());
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [monthlyValue, setMonthlyValue] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const history = useHistory();
  const anchorTuneRef = React.useRef<HTMLButtonElement>(null);
  const [openTuneSetting, setOpenTuneSetting] = useState(false);
  const [showAllShifts, setShowAllShifts] = useState(false);
  const [viewOption, setViewOption] = useState('List');

  const [account, setAccount] = useState(null);
  const didMountRef = useRef(false);

  const fetchData = async () => {
    setIsPageLoading(true);

    const userData = await AuthService.loadAccountInfo();
    setAccount(userData);
    if (roleName === roleNameEnum.owner) {
      const result = await ShiftService.getShiftList({
        state: "acknowledged",
        "sort[]": "-startTime",
        showAllShifts,
        limit: 10000,
      });
      console.log("owner payroll result ", result.data);
      setShifts(result.data);
      setShowAllShifts(
        localStorage.getItem("showAllShifts")
          ? localStorage.getItem("showAllShifts") === "Yes"
          : false
      );
    } else if (roleName === roleNameEnum.pharmacist) {
      const result = await ShiftService.getShiftList({
        state: "acknowledged",
        "sort[]": "-startTime",
        limit: 10000,
      });
      console.log("worker payroll result ", result.data);
      setShifts(result.data);
    }
    setIsPageLoading(false);
  };
  useEffect(() => {
    console.log("PayrollPage  shifts", shifts);

    fetchData();
    setViewOption('List');

    return () => {
      console.log("PayrollPage clean up");
    };
  }, []);
  useEffect(() => {
    if (didMountRef.current) {
      // const saveShowAllShifts = async () => {
      //    localStorage.setItem("showAllShifts", showAllShifts ? "Yes" : "No");
      // };
      // saveShowAllShifts();
      console.log("showAllShifts changed for shift list", showAllShifts);
      localStorage.setItem("showAllShifts", showAllShifts ? "Yes" : "No");
      fetchData();
    } else {
      didMountRef.current = true;
    }
  }, [showAllShifts, viewOption]);
  const setMonthlyAndTotalValue = async () => {
    if (roleName === roleNameEnum.owner) {
      const { monthlyPaid, totalPaid } = ShiftService.getPayoutsForShifts(
        shifts
      );
      setMonthlyValue(monthlyPaid);
      setTotalValue(totalPaid);
    } else if (roleName === roleNameEnum.pharmacist) {
      const userData = await AuthService.loadAccountInfo();
      setMonthlyValue(userData.pharmacist.earnings.monthly / 100);
      setTotalValue(userData.pharmacist.earnings.overall / 100);
    }
  };
  useEffect(() => {
    setMonthlyAndTotalValue();
  }, [shifts]);

  const renderAllowance = (shift: ShiftProps) => {
    const kmAllowanceAvailable = shift.kmAllowance > 0;
    const accommodationAllowanceAvailable = shift.accomodationAllowance > 0;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          // marginTop: spacing.small,
        }}
      >
        {kmAllowanceAvailable && (
          <div style={{ backgroundColor: "white", marginRight: 4 }}>
            {detailKmAllowanceIcon(primaryColor)}
          </div>
        )}
        {accommodationAllowanceAvailable && (
          <div style={{ backgroundColor: "white" }}>
            {detailAccomodationAllowanceIcon(primaryColor)}
          </div>
        )}
      </div>
    );
  };
  // const PaymentStatus = (isStripeInvoicePaid:any ) => {
  //   //isStripeInvoicePaid = false;
  //   console.log("isStripeInvoicePaid",isStripeInvoicePaid)
  //   const boxColor = isStripeInvoicePaid ? '#C8E6C9' : '#FFCDD2';
  //   const textColor = isStripeInvoicePaid ? '#00695C' : '#D32F2F';
  //   const iconName = isStripeInvoicePaid ? 'check' : 'times';
  
  //   return (
  //     <Typography style={{ backgroundColor: boxColor,width: lastColumnWidth }}>
  //       {/* <Icon name={iconName} size={10} color={textColor} style={styles.icon} /> */}
  //     <Typography style={{ color: textColor,paddingLeft:3,paddingRight:3,paddingTop:2,paddingBottom:2, textAlign:'center' ,width: lastColumnWidth }}>
  //       {isStripeInvoicePaid ? 'Succeeded' : 'Failed'}
  //     </Typography>
      
  //   </Typography>
  //   );
  // };
  const renderPayrollItemForOwner = (item: ShiftProps,account:any) => {
    // const actualStartTime = moment(item.actualStartTime).tz(item.shiftTimeZone);
    // const actualEndTime = moment(item.actualEndTime).tz(item.shiftTimeZone);
    const officialStartTime = moment(item.officialStartTime).tz(item.shiftTimeZone);
    const officialEndTime = moment(item.officialEndTime).tz(item.shiftTimeZone);

    const actualDateString = officialStartTime.format("ddd, MMM Do YYYY");
    const actualHoursDisplay = ShiftService.getShiftOfficialLengthDisplay(item);
    const shiftNetworkPaid = item.networkPaid? item.networkPaid : item.pharmacy.networkPaid;
    let wage = 0;
    let ownerTotalWage = 0;
    if (item.actualCommission) {
      if (shiftNetworkPaid == true) {
        if (roleName === roleNameEnum.owner) {
          ownerTotalWage = (item.wage / 100) + ShiftService.getPlacementFee(item);
          wage = item.wage / 100;
        }
      }
      else {
        ownerTotalWage = ShiftService.getWage(item, account) / 100;
        wage = ShiftService.getWage(item, account) / 100;
      }
    }
    else {
     wage = ShiftService.getWage(item, account) / 100;
    }


    //const wage = ShiftService.getWage(item) / 100;

    let minutesWorked = moment(item.officialEndTime).diff(
      moment(item.officialStartTime),
      'minutes',
    );
    if(item.actualCommission){
      if(item.disputeResolution === null)
      {
        if(item.unpaidBreakMinutes){
          minutesWorked = minutesWorked - item.unpaidBreakMinutes
        }
        if(item.disputeUnpaidBreakMinutes){
          minutesWorked = minutesWorked - item.disputeUnpaidBreakMinutes
        }
      }
      else{
        if(item.disputeResolution === 'pharmacy'){
          minutesWorked = minutesWorked - item.disputeUnpaidBreakMinutes
        }
        if(item.disputeResolution === 'pharmacist'){
          minutesWorked = minutesWorked - item.unpaidBreakMinutes
        }
      }
    }


    let totalAmount = '';
    const allowanceAvailable =
      item.kmAllowance > 0 || item.accomodationAllowance > 0;
    const kmAllowance = item.kmAllowance ?? 0;
    const distance = Math.round(((item.shiftDistance ?? 0) * 2) / 1000);
    const allowanceAmount =
      kmAllowance * distance + item.accomodationAllowance ?? 0;
    
      if (industryMode === IndustryModeEnum.Pharmaceutical){
        totalAmount = ((item.paidIn + item.paidTax) / 100).toFixed(2);
        if(item.surchargeAmount !== null && item.surchargeAmount > 0){
          totalAmount = ((item.paidIn + item.paidTax + (item.surchargeAmount * 100)) / 100).toFixed(2);
        }
      }
      else {
        let newWage = item.actualCommission ? ownerTotalWage : wage;
        totalAmount = (newWage * (minutesWorked / 60) + (allowanceAmount / 100)).toFixed(2);
      }

   // const totalAmount = ((item.paidIn + item.paidTax) / 100).toFixed(2);
      

    return (
      <div>
        <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
          <Typography style={{ fontWeight: "bold" }}>
            {item.pharmacy.name}
          </Typography>

        </div>
        <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
          <Typography style={styles.itemLeft}>{actualDateString}</Typography>

          <Typography style={{ fontWeight: "bold", width: lastColumnWidth }}>
            ${totalAmount}
          </Typography>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
          <Typography style={styles.itemLeft}>
            {officialStartTime.format("hh:mm a")} -
            {officialEndTime.format("hh:mm a")} ({actualHoursDisplay})
          </Typography>

          <Typography style={{ fontWeight: "bold", width: lastColumnWidth }}>
            ${wage.toFixed(2)}/hr
          </Typography>
        </div>

        <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
          {allowanceAvailable && (
            <Typography style={styles.itemLeft}>Allowance</Typography>
          )}
          {allowanceAvailable && (
            <Typography
              style={{ alignItems: "flex-start", width: lastColumnWidth }}
            >
              {renderAllowance(item)}
            </Typography>
          )}
        </div>

        { industryMode === IndustryModeEnum.Pharmaceutical && 
          <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
            <Typography style={styles.itemLeft}>Applicable Taxes</Typography>
            <Typography style={{ fontWeight: "bold", width: lastColumnWidth }}>
              ${(item.paidTax / 100).toFixed(2)}
            </Typography>
          </div>
        }
      </div>
    );
  };
  const renderPayrollItemForWorker = (item: ShiftProps,account:any) => {

    const officialStartTime = moment(item.officialStartTime).tz(item.shiftTimeZone);
    const officialEndTime = moment(item.officialEndTime).tz(item.shiftTimeZone);
    const actualDateString = officialStartTime.format("ddd, MMM Do YYYY");
    const actualHoursDisplay = ShiftService.getShiftActualLengthDisplay(item);
    //const wage = ShiftService.getWage(item) / 100;
    const shiftNetworkPaid = item.networkPaid? item.networkPaid : item.pharmacy.networkPaid;

    let wage = 0;
    let ownerTotalWage = 0;


    if (item.actualCommission) {
      if (shiftNetworkPaid == true) {
        if (roleName === roleNameEnum.pharmacist) {
          wage = item.wage / 100;
          if (item.associationId) {
            let commissionWage = ShiftService.getPlacementFee(item);
            if (item.commissionType === 'percentage') {
              let workerBenefit = commissionWage * (item.association?.percentageOnCommission/100);
              //wage = (item.wage / 100) + workerBenefit;
              wage = (item.wage / 100);
            }
            else {
              let workerBenefit = item.actualCommission * (item.association?.percentageOnCommission/100);
              //wage = (item.wage / 100) + workerBenefit;
              wage = (item.wage / 100);
            }
          }
        }
      }
      else {
        ownerTotalWage = ShiftService.getWage(item, account) / 100;
        wage = ShiftService.getWage(item, account) / 100;
      }
    }
    else {
      wage = ShiftService.getWage(item, account) / 100;
    }
    let minutesWorked = moment(item.officialEndTime).diff(
      moment(item.officialStartTime),
      'minutes',
    );
    if(item.actualCommission){

      if(item.disputeResolution === 'pharmacy'){
        minutesWorked = minutesWorked - item.disputeUnpaidBreakMinutes
      }
      else{
          minutesWorked = minutesWorked - item.unpaidBreakMinutes
      }
      
    }


    const allowanceAvailable =
      item.kmAllowance > 0 || item.accomodationAllowance > 0;
    let totalAmount = "";
    const kmAllowance = item.kmAllowance ?? 0;
    const distance = Math.round(((item.shiftDistance ?? 0) * 2) / 1000);
    const allowanceAmount =
    kmAllowance * distance + item.accomodationAllowance ?? 0;
    let taxForWorker = 0;
    let workerWageAmount = 0;
    let hourlyRate = 0;

    const isPharmacist = !!account.pharmacist;
    if (isPharmacist) {

      if(!item.actualCommission)
      {
        workerWageAmount = Math.floor(
          ShiftService.getWage(item, account) * (minutesWorked / 60),
        );
      }
      else{
        workerWageAmount = Math.ceil(
          item.wage * (minutesWorked / 60)
        );
    
        if(roleName === roleNameEnum.pharmacist)
        {
          if(item.associationId)
          {
            let commissionWage = Math.ceil(ShiftService.getPlacementFee(item)*100);
            if(item.commissionType === 'percentage')
            {
              let workerBenefit = Math.ceil(commissionWage * (item.association?.percentageOnCommission/100))/100;
              workerWageAmount = Math.ceil(item.wage * (minutesWorked / 60)) + workerBenefit*100;
            }
            else{
              let workerBenefit = item.actualCommission * (item.association?.percentageOnCommission/100);
              hourlyRate = (item.wage / 100) + workerBenefit;
              workerWageAmount = Math.ceil(item.wage * (minutesWorked / 60)) + workerBenefit*100;
            }
          }
        }
  
      }
      const subTotalAmountForWorker = workerWageAmount + allowanceAmount;
      taxForWorker = item.actualCommission ? Math.ceil(
        subTotalAmountForWorker * (item.salesTaxRate / 100)
      ):
      Math.floor(
        subTotalAmountForWorker * (item.salesTaxRate / 100)
      );
  
      if (industryMode === IndustryModeEnum.Pharmaceutical){
        totalAmount = (item.paidOut / 100).toFixed(2);
      } else {
        // paidOut has taxes applied, while subTotal does not
        totalAmount = (subTotalAmountForWorker / 100).toFixed(2);
      }
  
    }

    //totalAmount = (item.paidOut / 100).toFixed(2);
    // const minutesWorked = moment(item.actualEndTime).diff(
    //   moment(item.actualStartTime),
    //   "minutes"
    // );
    // const workerWageAmount = Math.floor(
    //   ShiftService.getWage(item) * (minutesWorked / 60)
    // );

    // const kmAllowance = item.kmAllowance ?? 0;
    // const distance = Math.round(((item.shiftDistance ?? 0) * 2) / 1000);
    // const allowanceAmount =
    //   kmAllowance * distance + item.accomodationAllowance ?? 0;
    // const subTotalAmountForWorker = workerWageAmount + allowanceAmount;
    // taxForWorker = Math.floor(
    //   subTotalAmountForWorker * (item.salesTaxRate / 100)
    // );
    return (
      <div>
        <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
          <Typography style={styles.itemLeft}>{actualDateString}</Typography>

          <Typography style={{ fontWeight: "bold", width: lastColumnWidth }}>
            ${totalAmount}
          </Typography>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
          <Typography style={styles.itemLeft}>
            {officialStartTime.format("hh:mm a")} -
            {officialEndTime.format("hh:mm a")} ({actualHoursDisplay})
          </Typography>

          <Typography style={{ fontWeight: "bold", width: lastColumnWidth }}>
            ${wage.toFixed(2)}/hr
          </Typography>
        </div>

        <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
          {allowanceAvailable && (
            <Typography style={styles.itemLeft}>Allowance</Typography>
          )}
          {allowanceAvailable && (
            <Typography
              style={{ alignItems: "flex-start", width: lastColumnWidth }}
            >
              {renderAllowance(item)}
            </Typography>
          )}
        </div>

        { industryMode === IndustryModeEnum.Pharmaceutical && 
                <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
                  <Typography style={styles.itemLeft}>Applicable Taxes</Typography>
                  <Typography style={{ fontWeight: "bold", width: lastColumnWidth }}>
                    $
                    {item.offloadedSalesTax
                      ? (taxForWorker / 100).toFixed(2)
                      : (0).toFixed(2)}
                  </Typography>
                </div>
        }
      </div>
    );
  };
  const renderPayrollItem = (shift: ShiftProps) => {
    return roleName === roleNameEnum.owner
      ? renderPayrollItemForOwner(shift,account)
      : renderPayrollItemForWorker(shift,account);
  };

  const renderPayrollHeader = () => {
    if (roleName === roleNameEnum.owner ) {
      return (
        <div>
          <div style={ColumnStyle}>
            <Typography
              style={{ fontWeight: "bold", fontSize: Font.largest }}
            >
             This Month (total)
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: Font.largest,
                marginLeft: 4,
              }}
            >
              ${monthlyValue.toFixed(2)}
            </Typography>
          </div>
          <div style={ColumnStyle}>
            <Typography
              style={{ fontWeight: "bold", fontSize: Font.largest }}
            >
              Cummulative (total)
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: Font.largest,
                marginLeft: 4,
              }}
            >
              ${totalValue.toFixed(2)}
            </Typography>
          </div>
        </div>
      );
    } else if (roleName === roleNameEnum.pharmacist) {
      return (
        <div>
          <div style={ColumnStyle}>
            <Typography
              style={{ fontWeight: "bold", fontSize: Font.largest }}
            >
            This Month (total)
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: Font.largest,
                marginLeft: 4,
              }}
            >
              ${monthlyValue.toFixed(2)}
            </Typography>
          </div>
          <div style={ColumnStyle}>
            <Typography
              style={{ fontWeight: "bold", fontSize: Font.largest }}
            >
              Cummulative (total)
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: Font.largest,
                marginLeft: 4,
              }}
            >
              ${totalValue.toFixed(2)}
            </Typography>
          </div>
        </div>
      );
    }
  };
  const renderAppBar = () => {
    // const pharmacyName = AuthService.getOwnerAccountInfo();
    // console.log('ownerAccountInfo', pharmacy)
    return (
      <>
        <RBAppbar
          searchable={false}
          barName={'Financials'}
          barChildren={
            <>
              {roleName === roleNameEnum.owner && (
                <IconButton
                  style={{ color: primaryColor }}
                  onClick={() => setOpenTuneSetting((prevOpen) => !prevOpen)}
                  ref={anchorTuneRef}
                >
                  <TuneIcon />
                </IconButton>
               )} 
            </>
          }
        />
      </>
    );
  };
  const renderTuneSettingContent = () => {
    const pharmacyName = AuthService.getOwnerAccountInfo().pharmacyName;
    return (
      <>
      <Paper style={{ margin: Spacing.medium }}>
        {roleName === roleNameEnum.owner &&
          (<RBCardView
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: Spacing.small,
            }}
            clickable={true}
            onClick={async () => {
              setOpenTuneSetting(false);

              setShowAllShifts((pre) => !pre);
            }}
          >
            <SwapHorizIcon fontSize={"large"} />
            <div style={{ paddingLeft: Spacing.larger }}>
              <Typography>
                {showAllShifts ? "View limited shifts" : "View all shifts"}
              </Typography>
              <Typography variant={"caption"}>
                {showAllShifts
                  ? `Select here to view: ${pharmacyName}`
                  : "Select here to view: Shifts for all"}
              </Typography>
            </div>
          </RBCardView>
          ) } 
        </Paper>
      </>
    );
  };
  const renderViews = () => {
    const anchorStyles = {
      cursor: 'pointer',
      // filter:`brightness(0) invert(1) saturate(100%) hue-rotate(${secondaryColor}deg)`,

    }

    return (
      <div style={{flex:1 , textAlign: 'center'}}>
        <a
          style={anchorStyles}
          onClick={() => {
            setViewOption('List');
            setOpenTuneSetting(false);
            // setShowAllShifts(() => true)
          }}
        >
          <FormatListBulletted
            style={{
              width: 35,
              height: 35,
              marginTop: 10,
              marginRight: 20,
              color: viewOption === 'List' ? 
                     secondaryColor : 
                     'grey'
            }}
          />
        </a>
       
      </div>
    )
  }
  const renderTuneSetting = () => {
    // const pharmacyName = AuthService.getOwnerAccountInfo().pharmacyName;
    const allOfficeText = industryMode === IndustryModeEnum.Dentistry ?
                          'Dental Practices' :
                          'Pharmacies'
    return (
      <RBModal
        open={openTuneSetting}
        backDropEnabled={true}
        style={{ minHeight: 0, height: roleName === roleNameEnum.owner ? 300 : 200}}
        onClose={() => setOpenTuneSetting((prevOpen) => !prevOpen)}
        modalTitle={roleName === roleNameEnum.owner &&
          showAllShifts
            ? 'Current display: All Locations'
            : `Current display: ${localStorage.getItem("pharmacyName")}` 
        }
        children={renderTuneSettingContent()}
      />
    );
  };
  return (
    <>
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <div style={{ paddingTop: Spacing.large }}>
          {renderAppBar()}
          {renderTuneSetting()}
          {((roleName === roleNameEnum.pharmacist && industryMode === IndustryModeEnum.Dentistry )  || 
          (roleName === roleNameEnum.pharmacist && industryMode === IndustryModeEnum.Pharmaceutical ) ||
          (roleName === roleNameEnum.owner && industryMode === IndustryModeEnum.Pharmaceutical ) )&&
            <RBCardView
              key={"payrollHeader"}
              marginHorizontal={Spacing.large}
              marginVertical={Spacing.medium}
              paddingHorizontal={Spacing.large}
              paddingVertical={Spacing.medium}
            >

              {renderPayrollHeader()}
            </RBCardView>
          }
          {shifts.map((shift) => (
            <RBCardView
              key={shift.id}
              marginHorizontal={Spacing.large}
              marginVertical={Spacing.medium}
              paddingHorizontal={Spacing.large}
              paddingVertical={Spacing.medium}
              clickable={true}
              onClick={() =>
                history.push(`${RoutePathConstant.ShiftDetail}${shift.id}`)
              }
            >
              {renderPayrollItem(shift)}
            </RBCardView>
          ))}
          {shifts.length == 0 && 
              <RBCardView
              key={"payrollHeader"}
              marginHorizontal={Spacing.large}
              marginVertical={Spacing.medium}
              paddingHorizontal={Spacing.large}
              paddingVertical={Spacing.medium}
              >
                <div>
                  <div style={{textAlign:"center"}}>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: Font.largest,}}
                    >
                     No items found.
                    </Typography>
                  </div>
                  
                </div>
              </RBCardView>}
        </div>
      )}
    </>
  );
};
