import { useState, useEffect,useContext } from "react";
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Colors, UIRelatedConstants } from "../theme/Theme";
import { FontSize, Spacing } from "../theme/Theme";
import { RBButton } from "./RBButton";
import { RBContext } from "./RBContext";

interface RBAlertProps {
  show: boolean;
  alertTitle?: string;
  alertMessage: string;
  backDropEnabled?: boolean;
  buttons: any[];
  alertWidth?: number;
}

const alertDefaultStyle = {
  borderRadius: UIRelatedConstants.DialogBorderRadius,
  width: UIRelatedConstants.DialogWidth,
};
const jobInvitealertDefaultStyle = {
  borderRadius: UIRelatedConstants.DialogBorderRadius,
  width: 400,
};

export const RBAlert: React.FC<RBAlertProps> = ({ ...props }) => {
  const [open, setOpen] = useState(props.show);
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);

  const handleClose = () => {
    setOpen(false);
  };
  const {
    backDropEnabled,
    alertTitle,
    alertMessage,
    buttons,
    alertWidth,
  } = props;
  return (
    <Dialog
      open={props.show}
      fullWidth={true}
      onClose={backDropEnabled ? handleClose : () => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: alertMessage?.includes("Remaining Requests") ? jobInvitealertDefaultStyle : alertDefaultStyle,
      }}
    >
      {alertTitle && (
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: Spacing.large,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: alertTitle !== "Your job has been created." ? FontSize.xlarge: '',
              marginTop: alertMessage?.includes("Remaining Requests") ? "10px" : ''
            }}
          >
            {alertTitle}
          </div>
        </DialogTitle>
      )}
      <DialogContent
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <DialogContentText
         style={{ textAlign: "center", whiteSpace: "pre-line", wordBreak: "break-word" }}
        >
          {alertMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-around",
          paddingTop: Spacing.large,
          paddingBottom: Spacing.large,
        }}
      >
        {buttons}
      </DialogActions>
    </Dialog>
  );
};

interface RBErrorAlertProps {
  show: boolean;
  errorMessage: string;
  onClose: any;
}
export const RBErrorAlert: React.FC<RBErrorAlertProps> = ({ ...props }) => {
  const { show, errorMessage, onClose } = props;
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  console.log("primaryColor",primaryColor)
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);
  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: alertDefaultStyle,
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: Spacing.large,
        }}
      >
        {/* <div
          style={{
            fontWeight: "bold",
            fontSize: FontSize.xlarge,
          }}
        >
          Something is wrong
        </div> */}
      </DialogTitle>

      <DialogContent
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <DialogContentText style={{ textAlign: "center" , color:"red"}}>
          {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-around",
          paddingTop: Spacing.large,
          paddingBottom: Spacing.large,
        }}
      >
        <RBButton text="Ok" onClick={handleClose} buttonBackgroundColor={primaryColor? primaryColor: Colors.themeDarkBlue} />
      </DialogActions>
    </Dialog>
  );
};
