import React, { createContext, useState, useEffect, useContext } from 'react';
import { RBButton, RBButtonType } from './RBButton';
import { RBAlert } from './RBAlert';
import { ObjectLiteral } from '../interfacesProps/ObjectLiteralProps';
import { AiFillQuestionCircle } from "react-icons/ai";
import { colors } from '@material-ui/core';
import { Colors } from '../theme/Theme';
import { RBContext } from './RBContext';

interface RBPlacementFeeModaleProps {
    title: any;
    detail: string;
    commissionType: string;
  }

const RBPlacementFeeModal : React.FC<RBPlacementFeeModaleProps> = ({title,detail,commissionType}) => {

    console.log('here')
    const [showModal, setShow] = useState(false);
    const [discardAlertSInfo,setdiscardAlertSInfo] =  useState<ObjectLiteral>({})
    const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  
    useEffect(() => {
     
    }, []);
    const renderPopup = () =>{
        setdiscardAlertSInfo({
            title: `\n`,
            message: 'Placement Fees are based on a % of the hourly rate and considerd an "estimate" until the shift has been completed, since the actual time may vary from the time originally scheduled, and a different hourly rate may be accepted if rate offers are turned on. Placement Fees may be subject to a minimum and a maximum. '+`\n\n`+
            'Check with your Network Adminstrator if you have any questions about how your Placement Fee is calculated.',
            buttons: [
              <RBButton
                key={RBButtonType.cancel}
                buttonType={RBButtonType.cancel}
                onClick={() => {
                    setShow(false);
                }}
                text="Close"
                style={{color:"white"}}
                buttonBackgroundColor={primaryColor}
              />
            ],
          })
          setShow(true)
      console.log('hiiii')
    
    
    }
  
    return (
        <>
        <RBAlert
        show={showModal}
        alertTitle={discardAlertSInfo.title}
        alertMessage={discardAlertSInfo.message}
        buttons={discardAlertSInfo.buttons}
      />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflowWrap: "anywhere",
          alignItems: "flex-start",
          alignContent: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            overflowWrap: "anywhere",
            color:Colors.darkGrey
          }}
        >
          {title}
          {(title == 'Placement Fee' || title == 'Total Placement Fee' ) && commissionType === 'percentage' && 
              <AiFillQuestionCircle
              style={{marginTop:4,marginLeft:4}}
              size={13}
              onClick={() => renderPopup()} 
            />
            }
        </div>
  
        <div
          style={{
            display: "flex",
            flex: 1,
            whiteSpace: "pre-wrap",
            overflowWrap: "anywhere",
            fontWeight: "bold",
          }}
        >
          {detail}
        </div>
      </div>
      </>
    );
  };
  export default RBPlacementFeeModal;
  
// export const RBPlacementFeeModal = (  ) => {
//     console.log('here')
//   const [show, setShow] = useState(true);
//   const [discardAlertSInfo,setdiscardAlertSInfo] =  useState<ObjectLiteral>({})

//   useEffect(() => {
//     setdiscardAlertSInfo({
//         title: "Discard Shift",
//         message: "Are you sure you want to discard the change?",
//         buttons: [
//           <RBButton
//             key={RBButtonType.cancel}
//             buttonType={RBButtonType.cancel}
//             onClick={() => {
//                 setShow(false);
//             }}
//             text="Close"
//           />
//         ],
//       })
//   }, []);

  

//   return (
//     <RBAlert
//     show={show}
//     alertTitle={discardAlertSInfo.title}
//     alertMessage={discardAlertSInfo.message}
//     buttons={discardAlertSInfo.buttons}
//   />
//   );
// };
