import React, { useState,useContext, useEffect } from "react";
import {
  CssBaseline,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  withStyles,
  makeStyles,
  createStyles,
  Avatar,
} from "@material-ui/core";
import { RoutesForOwner, RoutesForWorker, RoutesForDentalOwner, RoutesForDentalWorker, RoutesForDentalOwnerWithoutPayment, RoutesForOwnerWithoutPayment} from "./Routes";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { AuthService } from "../api/AuthService";
import { IoIosCreate } from "react-icons/io";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Spacing, Colors } from "../theme/Theme";
import { RBAlert } from "./RBAlert";
import { AlertInfoProps } from "../interfacesProps/AlertInfoProps";
import { RBButton, RBButtonType } from "./RBButton";
import { v4 as uuidv4 } from "uuid";
import { ColumnStyle, TopAppBarHeight } from "../theme/ComponentTheme";
import {
  RoutePathConstant,
  roleNameEnum,
  adjustColor,
  darkenColorPercentageConstant,
  lightenColorPercentageConstant,
} from "../util/Common";
import { RBContext } from "./RBContext";
import { IndustryModeEnum } from "../api/constants";
import { DefaultAvatar } from "../pages/profile/components/DefaultAvatar";
import { LoadingIndicator } from "./LoadingIndicator";
import { StarRatings } from "./StarRatings";
import { formatWorkerType } from "../util/FormattingService";

import { AccountInfoContext } from './AccountInfoContext';

const SignOutMenuButton = {
  sidebarName: "Sign Out",

  icon: <ExitToAppIcon />,
};
const CreateShiftMenuButton = {
  path: RoutePathConstant.CreateShift,
  sidebarName: "Create Shift",

  icon: <PostAddIcon />,
};

const CreateDentalShiftMenuButton = {
  path: RoutePathConstant.CreateDentalShift,
  sidebarName: "Create Shift",

  icon: <PostAddIcon />,
};

const CreateJobMenuButton = {
  path: RoutePathConstant.CreateJob,
  sidebarName: "Create Job",

  icon: <PostAddIcon />,
};

const CreateDentalJobMenuButton = {
  path: RoutePathConstant.CreateDentalJob,
  sidebarName: "Create Job",

  icon: <PostAddIcon />,
};


const SearchShiftMenuButton = {
  path: RoutePathConstant.SearchShifts,
  sidebarName: "Search Shifts",

  icon: <SearchIcon />,
};

const SearchJobMenuButton = {
  path: RoutePathConstant.SearchJobs,
  sidebarName: "Search Jobs",

  icon: <SearchIcon />,
};



export const SideMenu = () => {
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const { accountInformation } = useContext(AccountInfoContext);
  console.log("accountInformation",accountInformation)
  

  const [isSignOutAlertShowing, setSignOutAlertShowing] = useState(false);
  const [alertInfo, setAlertInfo] = useState<AlertInfoProps>({});
  const [accountInfo, setAccountInfo] = useState<any>({});
  const [isPageLoading, setIsPageLoading] = useState(true);
  // const routes =
  //   AuthService.getRoleName() === roleNameEnum.owner
  //     ? RoutesForOwner
  //     : RoutesForWorker;
  let sidebar = null;
  if(industryMode === IndustryModeEnum.Dentistry && AuthService.getRoleName() === roleNameEnum.owner){
    const pharmacy = accountInformation && accountInformation.pharmacies?.find((pharmacy:any) => pharmacy.id === accountInformation.owner?.defaultPharmacyId);
    sidebar = localStorage.getItem("paymentEnabled") === "true" || pharmacy?.jobPostFlag  ? RoutesForDentalOwner : RoutesForDentalOwnerWithoutPayment;
  } else if(industryMode === IndustryModeEnum.Dentistry && AuthService.getRoleName() === roleNameEnum.pharmacist) {
    sidebar = RoutesForDentalWorker
  } else if(industryMode === IndustryModeEnum.Pharmaceutical && AuthService.getRoleName() === roleNameEnum.owner) {
    console.log("accountInformation",accountInformation)
    const pharmacy = accountInformation && accountInformation.pharmacies?.find((pharmacy:any) => pharmacy.id === accountInformation.owner?.defaultPharmacyId);
    sidebar  =  localStorage.getItem("paymentEnabled") === "true" || pharmacy?.jobPostFlag ? RoutesForOwner: RoutesForOwnerWithoutPayment;
  } else{
    sidebar  = RoutesForWorker
  }
  const routes = sidebar;
  const location = useLocation();
  const history = useHistory();


  const useStyle = makeStyles(() =>
    createStyles({
      root: {
        color: "black",
        marginBottom: Spacing.medium,
        borderWidth: 1,
        borderRadius: 20,
        "&:hover": {
          color: primaryColor,
          // borderWidth: 1,
          //  borderStyle: "solid",
          // borderRadius: 20,
          // elevation: 0,
        },
      },
      selected: {
        color: primaryColor,
        backgroundColor: `${Colors.defaultGrey} !important`,
      },
    })
  );
  const classes = useStyle();
  useEffect(() => {
    const fetchData = async () => {
    const userData = await AuthService.loadAccountInfo();
      console.log('userData ', userData)
      setAccountInfo(userData);
      setIsPageLoading(false)
     

    }
    fetchData();
    }, []);

  const activeRoute = (routeName: any) => {
    return history.location.pathname === routeName ? true : false;
  };

  const handleSignOut = () => {
    setAlertInfo({
      title: "Sign out of Relief Buddy",
      message: "Are you sure you want to sign out?",
      buttons: [
        <RBButton
          key={uuidv4()}
          buttonType={RBButtonType.cancel}
          onClick={() => {
            setSignOutAlertShowing(false);
          }}
          text="Cancel"
        />,
        <RBButton
          key={uuidv4()}
          onClick={() => {
            setSignOutAlertShowing(false);
            AuthService.removeAuth();
            history.push(RoutePathConstant.SignIn);
          }}

          buttonBackgroundColor={primaryColor}
          color="primary"
          text="Confirm"
        />,
      ],
    });
    setSignOutAlertShowing(true);
  };


  const avatarProps = {
    borderWidth: 5,
    borderColor: secondaryColor,
    size: 100,
    shape: 'circle',
    borderStyle: 'solid'
  };

  const renderAlerts = () => {
    return (
      <RBAlert
        show={isSignOutAlertShowing}
        alertTitle={alertInfo.title}
        alertMessage={alertInfo.message}
        buttons={alertInfo.buttons}
      />
    );
  };

  const rating = accountInfo?.rating?.averageRating ?? 0;
  const numberRatings = accountInfo?.rating?.numberRatings ?? 0;
  let associationLogo = null;
  if (accountInfo?.userAssociationMember?.length > 0) {
    associationLogo = accountInfo.userAssociationMember[0].association.logo;
  }
  const profilePicture = accountInfo?.pharmacist?.profilePhoto;
  
  return (
    <>
      {renderAlerts()}
      <div
        style={{
          marginTop: 30,
          width: 325,
          position: "fixed",
          maxHeight: "calc(100vh - 30px)", 
          overflowY: "scroll",
        }}
      >
        <CssBaseline />
        <img
          src={localStorage.getItem("logo") || ""}
          alt="logo"
          style={{
            display: "flex",
            flex: 1,
            maxWidth: "90%",
            maxHeight: "100%",
            marginBottom: Spacing.large,
          }} 
        />
        <>
          {AuthService.getRoleName() === roleNameEnum.owner ?
            <div>
              {
                <>
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    //paddingTop: Spacing.smaller,
                    paddingBottom: Spacing.medium, paddingLeft: "35%"}}>
                    <DefaultAvatar
                      {...avatarProps}
                      name={`${localStorage.getItem("firstName")} ${localStorage.getItem("lastName")}`}
                    />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {localStorage.getItem("pharmacyName")}
                  </div>
                </>}
            </div>
            : 
           
              <>
                <div style={{display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    //paddingTop: Spacing.smaller,
                    paddingBottom: Spacing.medium, paddingLeft: "35%"}}>

                  <Avatar
                    style={{ height: 96, width: 96, marginRight: Spacing.large, borderColor: secondaryColor, borderWidth: '5px', borderStyle: 'solid' }}
                    src={profilePicture}
                  >

                  </Avatar>
                </div>
                <div style={{textAlign:"center"}}>
                {localStorage.getItem("firstName")} {localStorage.getItem("lastName")}
                </div>
                <div style={{textAlign:"center"}}>
                {formatWorkerType(accountInfo?.pharmacist?.type)}
                </div>
                <div style={{textAlign:"center"}}>
                  <StarRatings rating={rating} numberReviews={numberRatings} />
                </div>
              </>

          }
        <Divider
            style={{ marginTop: Spacing.larger, marginBottom: Spacing.larger }}
          />
        
          {routes.map((prop, key) => {
            return (
              <NavLink
                to={prop.path}
                style={{ textDecoration: "none" }}
                key={key}
              >
                <MenuItem
                  classes={{ root: classes.root, selected: classes.selected }}
                  selected={activeRoute(prop.path)}
                  onClick={() =>{
                    if(prop.sidebarName === 'Shifts' || prop.sidebarName === 'Jobs'){
                      localStorage.removeItem("ShiftState")
                      localStorage.removeItem("JobState")
                    }
                  }}
                >
                  {prop.icon}
                  <Typography
                    variant="h6"
                    style={{
                      marginLeft: Spacing.large,
                      whiteSpace:"pre-wrap"
                    }}
                  >
                    {prop.sidebarName}
                  </Typography>
                </MenuItem>
              </NavLink>
            );
          })}
          <Divider
            style={{ marginTop: Spacing.larger, marginBottom: Spacing.larger }}
          />
          {AuthService.getRoleName() === roleNameEnum.owner && (
            <NavLink
              to={{
                pathname: industryMode === IndustryModeEnum.Pharmaceutical? CreateShiftMenuButton.path : CreateDentalShiftMenuButton.path,
                state: { background: location },
              }}
              style={{ textDecoration: "none" }}
              key={"createShift"}
            >
              <MenuItem
                classes={{ root: classes.root, selected: classes.selected }}
                selected={activeRoute(industryMode === IndustryModeEnum.Pharmaceutical? CreateShiftMenuButton.path : CreateDentalShiftMenuButton.path)}
              >
                {industryMode === IndustryModeEnum.Pharmaceutical ? CreateShiftMenuButton.icon : CreateDentalShiftMenuButton.icon}

                <Typography variant="h6" style={{ marginLeft: Spacing.large }}>
                  {industryMode === IndustryModeEnum.Pharmaceutical ? CreateShiftMenuButton.sidebarName : CreateDentalShiftMenuButton.sidebarName}
                </Typography>
              </MenuItem>
            </NavLink>
          )}
          {AuthService.getRoleName() === roleNameEnum.owner && (
            <NavLink
              to={{
                pathname: industryMode === IndustryModeEnum.Pharmaceutical? CreateJobMenuButton.path : CreateDentalJobMenuButton.path,
                state: { background: location },
              }}
              style={{ textDecoration: "none" }}
              key={"createShift"}
            >
              <MenuItem
                classes={{ root: classes.root, selected: classes.selected }}
                selected={activeRoute(industryMode === IndustryModeEnum.Pharmaceutical? CreateJobMenuButton.path : CreateDentalJobMenuButton.path)}
              >
                {industryMode === IndustryModeEnum.Pharmaceutical ? CreateJobMenuButton.icon : CreateDentalJobMenuButton.icon}

                <Typography variant="h6" style={{ marginLeft: Spacing.large }}>
                  {industryMode === IndustryModeEnum.Pharmaceutical ? CreateJobMenuButton.sidebarName : CreateDentalJobMenuButton.sidebarName}
                </Typography>
              </MenuItem>
            </NavLink>
          )}

          {AuthService.getRoleName() === roleNameEnum.pharmacist && (
            <NavLink
              to={{
                pathname: SearchShiftMenuButton.path,
                state: { background: location },
              }}
              style={{ textDecoration: "none" }}
              key={"searchShift"}
            >
              <MenuItem
                classes={{ root: classes.root, selected: classes.selected }}
                selected={activeRoute(SearchShiftMenuButton.path)}
              >
                {SearchShiftMenuButton.icon}

                <Typography variant="h6" style={{ marginLeft: Spacing.large }}>
                  {SearchShiftMenuButton.sidebarName}
                </Typography>
              </MenuItem>
            </NavLink>
          )}

          {AuthService.getRoleName() === roleNameEnum.pharmacist && (
            <NavLink
              to={{
                pathname: SearchJobMenuButton.path,
                state: { background: location },
              }}
              style={{ textDecoration: "none" }}
              key={"searchJob"}
            >
              <MenuItem
                classes={{ root: classes.root, selected: classes.selected }}
                selected={activeRoute(SearchJobMenuButton.path)}
              >
                {SearchJobMenuButton.icon}

                <Typography variant="h6" style={{ marginLeft: Spacing.large }}>
                  {SearchJobMenuButton.sidebarName}
                </Typography>
              </MenuItem>
            </NavLink>
          )}

          <MenuItem
            classes={{ root: classes.root, selected: classes.selected }}
            onClick={handleSignOut}
          >
            {SignOutMenuButton.icon}

            <Typography variant="h6" style={{ marginLeft: Spacing.large }}>
              {SignOutMenuButton.sidebarName}
            </Typography>
          </MenuItem>
        </>
      </div>
    </>
  );
};
