import React, { useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import { RBEmptyBlock } from "../../../components/RBEmptyBlock";
import {
  makeStyles,
  createStyles,
  Typography,
  Checkbox,
  Link,
} from "@material-ui/core";
import { Spacing, FontSize, Colors } from "../../../theme/Theme";
import { Form, Formik, ErrorMessage } from "formik";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { Schema } from "../YupSchema";
import { RBExternalHelpLink } from "../../../components/RBExternalHelpLink";
import { HelperPageTitleEnum, workerTypeEnum } from "../../../util/Enumeration";
import { RBDropFile } from "../../../components/RBDropFile";
import { RBShiftAgreementModal } from "../../../components/RBShiftAgreementModal";

export interface PhotoInformationValueTypes {
  agreePrivacyPolicy: boolean;
  frontSideID: File | undefined;
  backSideID: File | undefined;
  profileImage: File | undefined;
}

interface PhotoInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: PhotoInformationValueTypes;
  workerType?: workerTypeEnum;
  account?:any;
}

export const PhotoInformation: React.FC<PhotoInformationProps> = ({
  ...props
}) => {
  let profileTitle = 'Profile photo will be used to verify your ID and is visible by locations when you apply for shifts.';
  if (props.workerType?.includes('entist')) {
    profileTitle = `Profile photo will be used to verify your ID and is visible by locations when you apply for shifts`;
  }
  const [showTermOfService, setShowTermOfService] = useState(false) 
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false) 

  const setShowTermAndService = (showTermOfService:boolean) =>
    setShowTermOfService(showTermOfService);

  const setShowPrivacyAndPolicy = (showPrivacyPolicy:boolean) =>
  setShowPrivacyPolicy(showPrivacyPolicy);

  const onTermsClose = () =>{
    setShowTermOfService(false);
    }
  const onPrivacyClose = () =>{
    setShowPrivacyPolicy(false);
    }
  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={Schema.PhotoInformation}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={Schema.PhotoInformation.isValidSync(props.initialValues)}
      onSubmit={() => {}}
    >
      {({ values, handleChange, handleSubmit, setFieldValue, errors }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <Typography variant="body1" style={{ marginBottom: Spacing.large }}>
            Please provide a photo of the front and back of your government
            issued ID along with a profile photo to allow us to verify your
            identity
          </Typography>
          <div style={{ ...ColumnStyle, marginBottom: Spacing.large }}>
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <RBDropFile
                dropzoneText={
                  "Drag and drop a front of your ID image here or click"
                }
                acceptedFiles={[".png", ".jpeg" ,".jpg"]}
                initialFiles={values.frontSideID ? [values.frontSideID] : []}
                // onChange
                showPreviews={true}
                showFileNamesInPreview={true}
                showPreviewsInDropzone={false}
                onChange={(file) => {
                  console.log("on change ", file);
                  if (file.length === 1) {
                    setFieldValue("frontSideID", file[0], true);
                  }
                }}
                onDelete={(file) => {
                  setFieldValue("frontSideID", undefined, true);
                }}
              />

              <ErrorMessage
                component="div"
                name="frontSideID"
                className="invalid-feedback"
              >
                {(msg) => <div style={{ color: "red" }}>{msg}</div>}
              </ErrorMessage>
            </div>

            <RBEmptyBlock />
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <RBDropFile
                dropzoneText={
                  "Drag and drop a back of your ID image here or click"
                }
                acceptedFiles={[".png", ".jpeg" , ".jpg"]}
                initialFiles={values.backSideID ? [values.backSideID] : []}
                // onChange
                showPreviews={true}
                showPreviewsInDropzone={false}
                onChange={(file) => {
                  console.log("on change ", file);
                  if (file.length === 1) {
                    setFieldValue("backSideID", file[0], true);
                  }
                }}
                onDelete={(file) => {
                  setFieldValue("backSideID", undefined, true);
                }}
              />

              <ErrorMessage
                component="div"
                name="backSideID"
                className="invalid-feedback"
              >
                {(msg) => <div style={{ color: "red" }}>{msg}</div>}
              </ErrorMessage>
            </div>
          </div>
          <Typography variant="body1" style={{ marginBottom: Spacing.large }}>
            {profileTitle}
          </Typography>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: Spacing.large,
            }}
          >
            <RBDropFile
              dropzoneText={"Drag and drop your profile image here or click"}
              acceptedFiles={[".png", ".jpeg", ".jpg"]}
              initialFiles={values.profileImage ? [values.profileImage] : []}
              // onChange
              showPreviews={true}
              showPreviewsInDropzone={false}
              onChange={(file) => {
                console.log("on change ", file);
                if (file.length === 1) {
                  setFieldValue("profileImage", file[0], true);
                }
              }}
              onDelete={(file) => {
                setFieldValue("profileImage", undefined, true);
              }}
            />

            <ErrorMessage
              component="div"
              name="profileImage"
              className="invalid-feedback"
            >
              {(msg) => <div style={{ color: "red" }}>{msg}</div>}
            </ErrorMessage>
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={values.agreePrivacyPolicy}
              style={{
                padding: 0,
                paddingRight: Spacing.large,
                // Why marginLeft -2, so  it will align with regular label or text field
                marginLeft: -2,
                color: values.agreePrivacyPolicy
                  ? localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue
                  : Colors.darkGrey,
              }}
              onChange={(_, checked) => {
                setFieldValue("agreePrivacyPolicy", checked, true);
              }}
            />
            <Typography variant="body2">
              I have read and understood the{" "}
              <Link onClick={() => setShowTermAndService(true)} style={{color:localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue , cursor: 'pointer'}}>{HelperPageTitleEnum.termOfService}{" "}
            </Link>{" "}
            and{" "}
            <Link onClick={() => setShowPrivacyAndPolicy(true)} style={{color:localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue, cursor: 'pointer'}}>{HelperPageTitleEnum.privacyPolicy}
            </Link>.
            </Typography>
          </div>
          {showTermOfService && <RBShiftAgreementModal
            open={showTermOfService}
            onClose={onTermsClose}
            modalTitle={"Terms of Service"}
            pageName="terms-of-use"
            pageType="page"
            networkId= ""
            header={
              <RBEmptyBlock
                style={{
                  width: "100%",
                  height: Spacing.large,
                  backgroundColor: Colors.defaultGrey,
                  flexShrink: 0,
                }}
              />
            }
          />
          }
          {showPrivacyPolicy && <RBShiftAgreementModal
            open={showPrivacyPolicy}
            onClose={onPrivacyClose}
            modalTitle={"Privacy Policy"}
            pageName="privacy-policy"
            pageType="page"
            networkId= ""
            header={
              <RBEmptyBlock
                style={{
                  width: "100%",
                  height: Spacing.large,
                  backgroundColor: Colors.defaultGrey,
                  flexShrink: 0,
                }}
              />
            }
          />
          }
          <ErrorMessage
            component="div"
            name="agreePrivacyPolicy"
            className="invalid-feedback"
          >
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>
        </Form>
      )}
    </Formik>
  );
};
