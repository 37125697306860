import React, { useState, useRef, useEffect,useContext } from "react";
import { ProfileCardSection } from "./ProfileCardSection";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import { Typography } from "@material-ui/core";
import { RBButton, RBButtonType } from "../../../components/RBButton";
import { Formik, Form, ErrorMessageProps } from "formik";
import { EmptyString, CanadaProvinces } from "../../../util/Common";
import { Schema } from "../YupSchema";
import {
  RBTextFieldForForm,
  RBSelectForForm,
} from "../../../components/RBTextField";
import { Spacing } from "../../../theme/Theme";
import { RBPlaceSearchForForm2 } from "../../../components/RBPlaceSearch";
import { renderTextWithTitleAndChildComponent } from "../../../components/TextWithHeader";
import { AuthService } from "../../../api/AuthService";
import NotificationService, {
  NotificationServiceTopic,
} from "../../../api/NotificationService";
import { RBMap } from "../../../components/RBMap";
import { RBContext } from "../../../components/RBContext";

interface AddressSectionPagePageProps {
  address: ObjectLiteral;
}

export const AddressSectionForWorkerPage: React.FC<AddressSectionPagePageProps> = ({
  address,
}) => {
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();

  const [isSaving, setIsSaving] = useState(false);
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  const setIsSavingValue = (value: boolean) => {
    isMountedRef.current && setIsSaving(value);
  };
  const addressFormat = address.apartmentSuiteNumber
    ? address.apartmentSuiteNumber +
      " - " +
      address.streetAddress +
      " " +
      address.city +
      " " +
      address.province +
      " " +
      address.postalCode
    : address.streetAddress +
      " " +
      address.city +
      " " +
      address.province +
      " " +
      address.postalCode;
  const [isEdit, setIsEdit] = useState(false);
  const formRef = useRef<any>();
  const addressRef = useRef<any>();
  const editDetail = () => {
    return (
      <Formik
        innerRef={formRef as any}
        initialValues={{
          street: address.streetAddress,
          city: address.city,
          postalCode: address.postalCode,
          province: address.province,
          apartmentSuiteNumber: address.apartmentSuiteNumber,
        }}
        validationSchema={Schema.AddressSection}
        validateOnBlur={false}
        validateOnMount={true}
        validateOnChange={true}
        isInitialValid={true}
        onSubmit={() => {
          console.log("address on submit  values ", address.streetAddress);
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            {submitErrorMessage && (
              <div style={{ color: "red" }}>
                {JSON.stringify(submitErrorMessage, null, 2)}
              </div>
            )}
            <RBPlaceSearchForForm2
              innerRef={addressRef}
              initialValues={{
                street: address.streetAddress,
                city: address.city,
                postalCode: address.postalCode,
                province: address.province,
                apartmentSuiteNumber: address.apartmentSuiteNumber,
              }}
              isProvinceEditable={false}
            />
          </Form>
        )}
      </Formik>
    );
  };
  const renderDetail = () => {
    console.log("address", address);
    const [lng, lat] = address.coordinate.coordinates;
    return (
      <>
        <div>
          <Typography
            variant={"body1"}
            style={{
              display: "flex",
              flex: 1,
              overflowWrap: "anywhere",
              whiteSpace: "pre-wrap",
            }}
          >
            {addressFormat}
          </Typography>

          <RBMap
            style={{
              display: "flex",
              flex: 1,
              height: 200,
              marginTop: Spacing.medium,
            }}
            lat={lat}
            lng={lng}
          />
        </div>
      </>
    );
  };
  const onSaveChange = async () => {
    try {
      if (formRef.current) {
        formRef.current.handleSubmit();
        if (!formRef.current.isValid) {
          return;
        } else if (!addressRef.current.isValid) {
          return;
        } else {
          console.log(
            "AddressSectionForWorkerPage onSaveChange ",
            formRef.current.values
          );
          setIsSavingValue(true);
          const result = await AuthService.updateWorker({
            address: {
              apartmentSuiteNumber:
                addressRef.current.values.apartmentSuiteNumber,
              streetAddress: addressRef.current.values.street,
              city: addressRef.current.values.city,
              province: addressRef.current.values.province,
              postalCode: addressRef.current.values.postalCode,
            },
          });
          console.log("AddressSectionForWorkerPage updatedWorker", result);
          setIsEdit(false);
          NotificationService.publish(
            NotificationServiceTopic.AccountChanged,
            "account changed "
          );

          // update
        }
      }
    } catch (err) {
      const errorMessage = err.response ? err.response.data : err.message;
      setSubmitErrorMessage(errorMessage);
      console.log("onSaveChange err ", errorMessage);
    } finally {
      setIsSavingValue(false);
    }
  };

  const onCancelChange = () => {
    setIsEdit(false);
  };
  const renderAction = () => {
    return (
      <>
        {isEdit ? (
          <>
            <RBButton
              text="Cancel"
              buttonType={RBButtonType.cancel}
              style={{ marginRight: Spacing.medium }}
              onClick={onCancelChange}
            />
            <RBButton text="Save" buttonBackgroundColor={secondaryColor}  isLoading={isSaving} onClick={onSaveChange} />
          </>
        ) : (
          <RBButton text="Edit"  buttonBackgroundColor={secondaryColor}  onClick={() => setIsEdit(true)} />
        )}
      </>
    );
  };
  return (
    <ProfileCardSection
      sectionHeader="Address"
      detail={isEdit ? editDetail() : renderDetail()}
      action={renderAction()}
    />
  );
};
