import React, { useState,useContext,useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { ShiftProps } from "../interfacesProps/ShiftProps";
import { ShiftService } from "../api/ShiftService";
import { ShiftRelatedConstants, ShiftRelatedStylingConstants } from "../util/ShiftConstants";
import { FontSize, FontWeight, Spacing } from "../theme/Theme";
import moment from "moment-timezone";
import { capitalize, ButtonBase } from "@material-ui/core";
import {
  defaultKmAllowanceIcon,
  defaultAccomodationAllowanceIcon,
} from "../util/Icons";
import { RBJobLayoutButton } from "./RBJobLayoutButton";
import { formatWorkerType,getDatesDuration } from "../util/FormattingService";
import { IndustryModeEnum, roleNameEnum } from "../util/Common";
import { UIRelatedConstants } from "../theme/Theme";
import { RBContext } from './RBContext';
import { AuthService } from "../api/AuthService";
import { ObjectLiteral } from "../interfacesProps/ObjectLiteralProps";
import { defaultAllowOffersIcon } from '../components/RBShiftRelatedIcons';
const useStyles = makeStyles({
  Card: {
    "&:hover": {
      backgroundColor: "#E5E5E5",
      cursor: "pointer",
    },
  },
  CardContent: {
    paddingTop: 8,
    "&:last-child": {
      paddingBottom: 8,
    },
  },
});
interface RBJobLayoutCardProps {
  job: any;
  rolename: string;
  onClick: (shiftId: string) => void;
  account:ObjectLiteral
}

const renderStateHeaderForWorker = (shift: ShiftProps,primaryColor:string,secondaryColor:string,rolename:string) => {
  const state = ShiftService.getStatusForShiftAsWorker(shift,rolename);
  let shiftStateStatus = state;
    console.log("shiftStateStatus",shiftStateStatus)
    if ((shift.pharmacistType.includes('entist') && shift?.workerAutoAcknowledged && shift?.workerAutoAcknowledged === true)) {
      if(shift.flags.isAssigned && shift.flags.isCompleted ){
        
        if(!shift.flags.isCompletionAcknowledged){
          if (shift.flags.isDisputed) {
            shiftStateStatus =  ShiftRelatedConstants.ShiftStateText.InDispute;
          }
          shiftStateStatus =ShiftRelatedConstants.ShiftStateText.AwaitingHoursConfirmation;
        }
        if(shift.flags.isCompletionAcknowledged){
          shiftStateStatus = ShiftRelatedConstants.ShiftStateText.ShiftCompleted
        }
      }
    }
    
  let stateColor =
    ShiftRelatedStylingConstants.Colors[
      ShiftService.getStatusForShiftAsWorker(shift,rolename)
    ];
  if(stateColor === '#2B3270')
  {
    stateColor = primaryColor;
  }
  else if(stateColor === '#6EC9CD'){
    stateColor = secondaryColor;
  }
  return (
    <div
      style={{
        fontSize: FontSize.large,
        fontWeight: "bold",
        color: stateColor,
      }}
    >
      {shiftStateStatus}
    </div>
  );
};
const renderJobStateHeaderForWorker = (shift: ShiftProps,primaryColor:string,secondaryColor:string) => {
  const state = ShiftService.getStatusForJobAsWorker(shift);
  let stateColor =
    ShiftRelatedStylingConstants.Colors[
      ShiftService.getStatusForJobAsWorker(shift)
    ];
  if(stateColor === '#2B3270')
  {
    stateColor = primaryColor;
  }
  else if(stateColor === '#6EC9CD'){
    stateColor = secondaryColor;
  }
  return (
    <div
      style={{
        fontSize: FontSize.large,
        fontWeight: "bold",
        color: stateColor,
      }}
    >
      {state}
    </div>
  );
};
const renderStateHeaderForPharmacy = (job: any,primaryColor:string,secondaryColor:string,rolename:string) => {
  const state = ShiftService.getStatusForJobAsPharmacy(job);
  let stateText = state;

  let stateColor =
    ShiftRelatedStylingConstants.Colors[
      ShiftService.getStatusForJobAsPharmacy(job)
    ];

    if(stateColor === '#2B3270')
    {
      stateColor = primaryColor;
    }
    else if(stateColor === '#6EC9CD'){
      stateColor = secondaryColor;
    }

  
  return (
    <div
      style={{
        fontSize: FontSize.large,
        fontWeight: "bold",
        color: stateColor,
      }}
    >
      {stateText}
    </div>
  );
};

const renderAllowance = (shift: ShiftProps,primaryColor:string) => {
  const kmAllowanceAvailable = shift.kmAllowance > 0;
  const accomodationAllowanceAvailable = shift.accomodationAllowance > 0;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        // marginTop: spacing.small,
      }}
    >
      {kmAllowanceAvailable && (
        <div style={{ marginRight: 4 }}>{defaultKmAllowanceIcon(primaryColor)}</div>
      )}
      {accomodationAllowanceAvailable && (
        <div>{defaultAccomodationAllowanceIcon(primaryColor)}</div>
      )}
    </div>
  );
};

const renderJobContentForWorker = (
  job: any,
  setCardActionAreaClickable: (actionAreaClickable: boolean) => void,
  rolename: string,
  primaryColor:string,
  account:any
) => {
  const {
      pharmacistType,
      estHourlyRate,
      estStartDate,
      estEndDate,
      jobCategory,
  } = job;
  const estStartDateValue = estStartDate
    ? moment(estStartDate).format('MMM Do, YYYY')
    : 'N/A';
  const estEndDateValue = estEndDate
    ? moment(estEndDate).format('MMM Do, YYYY')
    : 'N/A';
  const x = estStartDate
    ? capitalize(moment(estStartDate).fromNow())
    : 'N/A';
  const jobDuration = getDatesDuration(estStartDate, estEndDate);

  const jobCity = job.jobCity ?? "N/A";
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
        }}
      >
        <div style={{ flex: 3 }}>
          {estEndDateValue == "N/A" ?
              <div>Est. Start Date</div>
              :
              <div>Est. Start - End Date</div>
          }
         {
            estEndDateValue == "N/A" ?
            <div>
            {estStartDateValue}
            </div>
            :
          <div>
            {estStartDateValue} - {estEndDateValue}
          </div>
          }
          {estEndDate !== null ? 
            <div>{jobDuration}</div> : (
              <div>
                <div>In {jobCity}</div>
              </div>
          )}
        </div>
        <div
          style={{
            flex: 2,
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              // marginTop: spacing.small,
            }}
          >
            <div >{jobCategory.name}</div>
            
          </div>


          <div style={{ textAlign: "right" }}>
            ${(estHourlyRate / 100).toFixed(2)}/hr
          </div>
          <div style={{ textAlign: "right" }}>
          {formatWorkerType(pharmacistType)}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: 10,
          justifyContent: "space-between",
          minHeight: 25,
        }}
      >
         <div style={{ justifyContent: "center" }}>
        {estEndDate !== null && <div>
            <label style={{ fontWeight: "bold" }}>
              In <label style={{ fontWeight: "normal" }}> {jobCity}</label>
            </label>
          </div>
        }
        </div>

        <div
          style={{ alignItems: "flex-end" }}
          onMouseEnter={() => {
            setCardActionAreaClickable(false);
          }}
          onMouseLeave={() => {
            setCardActionAreaClickable(true);
          }}
        >
          <RBJobLayoutButton job={job} rolename={rolename} />
        </div>
      </div>
    </div>
  );
};

const renderContentForPharmacy = (
  job: any,
  setCardActionAreaClickable: (actionAreaClickable: boolean) => void,
  rolename: string,
  account:any,
  industryMode:string,
  secondaryColor:string
) => {
  const {
      pharmacistType,
      estHourlyRate,
      estStartDate,
      estEndDate,
      jobCategory,
  } = job;
  const estStartDateValue = estStartDate
    ? moment(estStartDate).format('MMM Do, YYYY')
    : 'N/A';
  const estEndDateValue = estEndDate
    ? moment(estEndDate).format('MMM Do, YYYY')
    : 'N/A';
  const jobDuration =
    estStartDate && estEndDate
      ? getDatesDuration(estStartDate, estEndDate)
      : 'N/A';

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
        <div style={{ flex: 3 }}>
          {estEndDateValue == "N/A" ?
              <div>Est. Start Date</div>
              :
              <div>Est. Start - End Date</div>
          }
         {
            estEndDateValue == "N/A" ?
            <div>
            {estStartDateValue}
            </div>
            :
          <div>
            {estStartDateValue} - {estEndDateValue}
          </div>
          }
          {estEndDate !== null ? 
            <div>{jobDuration}</div> : (
              <div>
                <div>At {job.pharmacy.name}</div>
              </div>
          )}
        </div>
        <div
          style={{
            flex: 2,
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              // marginTop: spacing.small,
            }}
          >
            <div >{jobCategory.name}</div>
            
          </div>


          <div style={{ textAlign: "right" }}>
            ${(estHourlyRate / 100).toFixed(2)}/hr
          </div>
          <div style={{ textAlign: "right" }}>
          {formatWorkerType(pharmacistType)}
          </div>
        </div>

      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: 10,
          justifyContent: "space-between",
          minHeight: 45,
        }}
      >
        <div style={{ justifyContent: "center" }}>
        {estEndDate !== null && <div>
            <label style={{ fontWeight: "bold" }}>
              At <label style={{ fontWeight: "normal" }}> {job.pharmacy.name}</label>
            </label>
          </div>
        }
        </div>

        <div
          style={{ alignItems: "flex-end" }}
          onMouseEnter={() => {
            setCardActionAreaClickable(false);
          }}
          onMouseLeave={() => {
            setCardActionAreaClickable(true);
          }}
        >
          <RBJobLayoutButton job={job} rolename={rolename} />
        </div>
      </div>
    </div>
  );
};

const RenderJobLayoutForWorker = (
  job: any,
  onClick: (jobId: string) => void,
  rolename: string,
  account:any
) => {
  const classes = useStyles();
  const [CardActionAreaClickable, SetCardActionAreaClickable] = useState(true);
  const state = ShiftService.getStatusForJobAsWorker(job);
  const [stateColor,setStateColor] = useState<any>( ShiftRelatedStylingConstants.Colors[
    ShiftService.getStatusForJobAsWorker(job)
  ])
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);

    useEffect(() => {
      const stateGlobalColor = ShiftRelatedStylingConstants.Colors[
        ShiftService.getStatusForJobAsWorker(job)
      ];
      if(stateGlobalColor === '#2B3270')
      {
        setStateColor(primaryColor)
      }
      else if(stateGlobalColor === '#6EC9CD'){
        setStateColor(secondaryColor)
      }
    }, []);
  return (
    <Card
      variant="outlined"
      className={classes.Card}
      style={{
        borderRadius: UIRelatedConstants.CardBorderRadius,
        borderWidth: 3,
        borderColor: stateColor,
      }}
    >
      <div
        onClick={() => {
          if (CardActionAreaClickable) {
            onClick(job.id);
          } else {
            console.log("CardActionAreaClickable is false");
          }
        }}
      >
        <CardContent className={classes.CardContent}>
          {renderJobStateHeaderForWorker(job,primaryColor,secondaryColor)}
          {renderJobContentForWorker(job, SetCardActionAreaClickable, rolename,primaryColor,account)}
        </CardContent>
      </div>
    </Card>
  );
};
const RenderJobLayoutForPharmacy = (
  job: any,
  onClick: (jobtId: string) => void,
  rolename: string,
  account:any
) => {
  const classes = useStyles();
  const [CardActionAreaClickable, SetCardActionAreaClickable] = useState(true);

  const [stateColor,setStateColor] = useState<any>( ShiftRelatedStylingConstants.Colors[
    ShiftService.getStatusForJobAsPharmacy(job)
  ])
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const state = ShiftService.getStatusForJobAsPharmacy(job);

  useEffect(() => {
    const stateGlobalColor = ShiftRelatedStylingConstants.Colors[
      ShiftService.getStatusForJobAsPharmacy(job)
    ]
    if(stateGlobalColor === '#2B3270')
    {
      setStateColor(primaryColor)
    }
    else if(stateGlobalColor === '#6EC9CD'){
      setStateColor(secondaryColor)
    }
    console.log('stateColor',stateColor)
  }, []);
  
  return (
    <Card
      variant="outlined"
      className={classes.Card}
      style={{
        borderRadius: UIRelatedConstants.CardBorderRadius,
        borderWidth: 3,
        borderColor: stateColor,
      }}
    >
      <div
        onClick={() => {
          if (CardActionAreaClickable) {
            onClick(job.id);
          } else {
            console.log("CardActionAreaClickable is false");
          }
        }}
      >
        <CardContent className={classes.CardContent}>
          {renderStateHeaderForPharmacy(job,primaryColor,secondaryColor,rolename)}
          {renderContentForPharmacy(
            job,
            SetCardActionAreaClickable,
            rolename,
            account,
            industryMode,
            secondaryColor
          )}
        </CardContent>
      </div>
    </Card>
  );
};

export const RBJobLayoutCard: React.FC<RBJobLayoutCardProps> = ({
  ...props
}) => {
  const { job, onClick, rolename ,account} = props;
  if (rolename === roleNameEnum.pharmacist) {
      return (
        <div
          style={{
            width: "100%",
            paddingLeft: Spacing.large,
            paddingRight: Spacing.large,
            paddingBottom: Spacing.medium,
          }}
        >
          {RenderJobLayoutForWorker(job, onClick, rolename,account)}
        </div>
      );
  }
  if (rolename === roleNameEnum.owner) {
    return (
      <div
        style={{
          paddingLeft: Spacing.large,
          paddingRight: Spacing.large,
          paddingBottom: Spacing.medium,
        }}
      >
        {RenderJobLayoutForPharmacy(job, onClick, rolename,account)}
      </div>
    );
  }
  return <></>;
};
