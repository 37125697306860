import React, { useState,useContext,useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { ShiftProps } from "../interfacesProps/ShiftProps";
import { ShiftService } from "../api/ShiftService";
import { ShiftRelatedConstants, ShiftRelatedStylingConstants } from "../util/ShiftConstants";
import { Colors, FontSize, FontWeight, Spacing } from "../theme/Theme";
import moment from "moment-timezone";
import { capitalize, ButtonBase, Checkbox } from "@material-ui/core";
import {
  defaultKmAllowanceIcon,
  defaultAccomodationAllowanceIcon,
} from "../util/Icons";
import { RBShiftLayoutButton } from "./RBShiftLayoutButton";
import { formatWorkerType } from "../util/FormattingService";
import { IndustryModeEnum, roleNameEnum } from "../util/Common";
import { UIRelatedConstants } from "../theme/Theme";
import { RBContext } from './RBContext';
import { AuthService } from "../api/AuthService";
import { ObjectLiteral } from "../interfacesProps/ObjectLiteralProps";
import { defaultAllowOffersIcon } from '../components/RBShiftRelatedIcons';
const useStyles = makeStyles({
  Card: {
    "&:hover": {
      backgroundColor: "#E5E5E5",
      cursor: "pointer",
    },
  },
  CardContent: {
    paddingTop: 8,
    "&:last-child": {
      paddingBottom: 8,
    },
  },
});
interface RBShiftLayoutCardForApplicantProps {
  shift: ShiftProps;
  rolename: string;
  onClick: (shiftId: string) => void;
  account:ObjectLiteral
  selected:any
  toggleShiftSelection: (index: any, shiftId: any) => void; // Add this prop
}

const  renderRequestedWage = (requestedWage:any, shift:any) => {

  const allowOffers = shift.allowOffers;

  if (allowOffers) {
    if (shift.pharmacy?.networkPaid) {
        shift.requestedWage = requestedWage;
        const workerFee = requestedWage/100 ;
      
      return (
        <div style={{ fontWeight: "bold", textAlign: "right" }}>
            Requested Rate: {'$' + workerFee.toFixed(2)}
          </div>
      )
    }
    else {
      return (
        <div style={{ fontWeight: "bold", textAlign: "right" }}>
          Requested Rate: {'$' + (requestedWage / 100).toFixed(2)}
        </div>
      )
    }
  } else{
    return null
  }
};
const getAllowanceCostWithTax = (shift: any,account:any) => {
  let pharmacy = account.pharmacies.filter((pharmacy:any) => pharmacy.id === account.owner.defaultPharmacyId)[0];
  const taxRate = (pharmacy.salesTaxRate ??
    0) / 100;
  const allowanceCost =
    ((Math.round((shift.distance * 2) / 1000) * shift.kmAllowance) /
      100 +
      shift.accomodationAllowance / 100) *
    (1 + taxRate);
  return allowanceCost;
};

const renderAllowance = (shift: ObjectLiteral,account:any) => {
  const allowanceCost = getAllowanceCostWithTax(shift,account);
 
  return (
    <div>
      Allowance Cost: { "$" + allowanceCost.toFixed(2)}
    </div>
  )
   
};

const renderContentForPharmacy = (
  shift: ShiftProps,
  setCardActionAreaClickable: (actionAreaClickable: boolean) => void,
  selected:any,
  rolename: string,
  account:any,
  industryMode:string,
  secondaryColor:string,
  toggleShiftSelection: (index: any, shiftId: any) => void
) => {
  const startTime = moment(shift.officialStartTime).tz(shift.shiftTimeZone);
  const endTime = moment(shift.officialEndTime).tz(shift.shiftTimeZone);
  const hoursDisplay = ShiftService.getShiftLengthDisplayForOriginal(shift);
  //const wage = ShiftService.getWage(shift,account);
  const wage = industryMode === IndustryModeEnum.Dentistry ?(ShiftService.getWage(shift,account)).toFixed(2) : (shift.actualCommission? shift.wage  : (ShiftService.getWage(shift,account)).toFixed(2));


  const workerType = shift.pharmacistType;
  //example: Wed, Nov 6th 2019
  const dateDisplay = startTime.format("ddd, MMM Do YYYY");
  const pharmacyName = shift.pharmacy.name ?? "N/A";


  const { isAssigned } = shift.flags;
  const { allowOffers } = shift;
  const requestedWage = (shift.shiftToRequests && shift.shiftToRequests.length > 0) ? shift.shiftToRequests[0].requestedWage : null;
  console.log("requestedWage",requestedWage)

  return (
    <div>
      {shift.shiftToRequests && shift.shiftToRequests.length > 0 && 
      <div style={{ display: "flex", flexDirection: "row", flex: 1, marginLeft:-10 }}>
      <div
          style={{ alignItems: "flex-end" }}
          onMouseEnter={() => {
            setCardActionAreaClickable(false);
          }}
          onMouseLeave={() => {
            setCardActionAreaClickable(true);
          }}
        >
         <Checkbox
          checked={selected}
          style={{
            color: selected ? secondaryColor : Colors.darkGrey,
          }}
          onChange={(event) => {
            event.stopPropagation(); // Stop the event from propagating to the card's onClick
            toggleShiftSelection(shift.index, shift.id); // Call the parent's toggleShiftSelection
          }}
        />
        </div>
        
      </div>}
      <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
        <div style={{ flex: 3 }}>
          <div style={{ fontWeight: "bold" }}>
            {capitalize(startTime.fromNow())}
          </div>
          <div>{dateDisplay}</div>
          <div>
            {startTime.format("hh:mm a")} - {endTime.format("hh:mm a")}
          </div>
          {requestedWage && renderAllowance(shift,account)}
        </div>
        <div
          style={{
            flex: 2,
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              // marginTop: spacing.small,
            }}
          >
            <div style={{ marginRight: 4,fontWeight: "bold" }}>${(wage / 100).toFixed(2)}/hr</div>
            { (allowOffers && !isAssigned) && 
              <div style={{paddingTop:2}}>
                 {defaultAllowOffersIcon(secondaryColor)}
              </div>
            }
          </div>


          <div style={{ fontWeight: "bold", textAlign: "right" }}>
            {formatWorkerType(workerType)}
          </div>
          <div style={{ fontWeight: "bold", textAlign: "right" }}>
            {hoursDisplay}
          </div>
            {requestedWage && renderRequestedWage(requestedWage,shift)}
        </div>
      </div>
    </div>
  );
};



const RenderShiftLayoutForPharmacy = (
  shift: ShiftProps,
  onClick: (shiftId: string) => void,
  selected:any,
  rolename: string,
  account:any,
  toggleShiftSelection: (index: any, shiftId: any) => void
) => {
  const classes = useStyles();
  const [CardActionAreaClickable, SetCardActionAreaClickable] = useState(true);

  const [stateColor,setStateColor] = useState<any>( ShiftRelatedStylingConstants.Colors[
    ShiftService.getStatusForShiftAsPharmacy(shift,rolename)
  ])
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const state = ShiftService.getStatusForShiftAsPharmacy(shift,rolename);

  useEffect(() => {
    const stateGlobalColor = ShiftRelatedStylingConstants.Colors[
      ShiftService.getStatusForShiftAsPharmacy(shift,rolename)
    ]
    if(stateGlobalColor === '#2B3270')
    {
      setStateColor(primaryColor)
    }
    else if(stateGlobalColor === '#6EC9CD'){
      setStateColor(secondaryColor)
    }
    console.log('stateColor',stateColor)
  }, []);
  
  return (
    <Card
      variant="outlined"
      className={classes.Card}
      style={{
        borderRadius: UIRelatedConstants.CardBorderRadius,
        borderWidth: 3,
        borderColor: stateColor,
        backgroundColor: shift.shiftToRequests && shift.shiftToRequests.length === 0 ? Colors.defaultGrey : "white"
      }}
    >
      <div
        onClick={() => {
          if (CardActionAreaClickable) {
            onClick(shift.id);
          } else {
            console.log("CardActionAreaClickable is false");
          }
        }}
      >
        <CardContent className={classes.CardContent}>
          {renderContentForPharmacy(
            shift,
            SetCardActionAreaClickable,
            selected,
            rolename,
            account,
            industryMode,
            secondaryColor,
            toggleShiftSelection
          )}
        </CardContent>
      </div>
    </Card>
  );
};

export const RBShiftLayoutCardForApplicant: React.FC<RBShiftLayoutCardForApplicantProps> = ({
  ...props
}) => {
  const { shift, onClick, rolename ,account, selected, toggleShiftSelection} = props;
  if (rolename === roleNameEnum.owner) {
    return (
      <div
        style={{
          paddingLeft: Spacing.large,
          paddingRight: Spacing.large,
          paddingBottom: Spacing.medium,
        }}
      >
        {RenderShiftLayoutForPharmacy(shift, onClick,selected, rolename,account,toggleShiftSelection)}
      </div>
    );
  }
  return <></>;
};
