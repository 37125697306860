
import React, { useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import { buttonStyle } from "../theme/ComponentTheme";
import { Colors } from "../theme/Theme";

import {
  NotAvailableString,
  adjustColor,
  darkenColorPercentageConstant,
  darkerColorPercentageConstant,
} from "../util/Common";

export enum CustomButtonType {
  default,
  cancel,
}
interface CustomButtonProps extends ButtonProps {
  text?: string;
  buttonBackgroundColor?: string;
  isLoading?: boolean;
  buttonType?: CustomButtonType;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  buttonType = CustomButtonType.default,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const {
    style,
    buttonBackgroundColor,
    isLoading,
    text,
    disabled,
    ...rest
  } = props;

  const disabledBackgroundColor = Colors.darkGrey;
  let backgroundColor = buttonStyle.backgroundColor ?? NotAvailableString;
  let color = buttonStyle.color;
  let hoverBackgroundColor = "";

  switch (buttonType) {
    case CustomButtonType.cancel:
      backgroundColor = Colors.defaultGrey;
      color = Colors.black;
      break;
  }

  if (disabled) {
    backgroundColor = disabledBackgroundColor;
  } else {
    if (buttonBackgroundColor) {
      backgroundColor = buttonBackgroundColor;
    }
  }
    
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };

     if (isHovered) {
    hoverBackgroundColor = "blue";
  }

  return (
    <Button
    style={{
      ...buttonStyle,
      color,
      ...style,
      backgroundColor,
      width: 200,
      height: 200,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 50,
      borderRadius: 200,
      position: 'absolute',
      backgroundImage: 'url(POST SHIFT (2).png)',
      border: '.25px solid',
      fontSize: 25, fontWeight: 'bold',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    
    }}
    {...rest}
    disabled={isLoading ? true : disabled ?? false}
  >
    {isLoading && (
      <>
        Loading
        <CircularProgress
          size={20}
          style={{ color: '#ffffff', marginLeft: 5 }}
        />
      </>
    )}
    {!isLoading && text}
  </Button>
)}


export default CustomButton;
