export enum workerTypeEnum {
  pharmacist = "pharmacist",
  technician = "technician",
  assistant = "assistant",
  pharmacistStudent = "pharmacist_student",
  technicianStudent = "technician_student",

  dentist = 'dentist_dentist',
  denturist = 'dentist_denturist',
  dentistRDH = 'dentist_rdh',
  dentistRDA = 'dentist_rda',
  dentistAdministrator = 'dentist_administrator',
  dentistSterilizationAssistant = 'dentist_sterilizationAssistant',
  dentistCDA = 'dentist_cda', 
  dentistCDA2 = 'dentist_cda_2',
  dentistRDT = 'dentist_rdt',
  dentistDT = 'dentist_technician',
  dentistChairSideAssistant = 'dentist_chairSideAssistant'
}

export enum paymentTypeEnum {
  creditCard = 'credit_card',
  pad = 'pad',
  invoice= 'invoice'
}

export enum FormattedWorkerTypeEnum {
  pharmacist = 'Pharmacist',
  technician = 'Technician',
  assistant = 'Assistant',
  pharmacistStudent = 'Pharmacy Student',
  technicianStudent = 'Technician Student',
  pharmacistConditionalLicense = 'Pharmacist - Conditional License',
  pharmacistIntern2020Grad = 'Intern - 2020 Grad',

  dentist = 'Dentist',
  denturist = 'Denturist',
  dentistRDH = 'RDH: Reg. Dental Hygienist',
  dentistRDA = 'RDA: Reg. Dental Assistant',
  dentistAdministrator = 'Dental Administrator',
  dentistSterilizationAssistant = 'Sterilization/Floater',
  dentistCDA2 = 'CDA II: Cert. Dental Asst. LII',
  dentistRDT = 'RDT: Reg. Dental Tech.',
  dentistDT = 'Dental Technician',
  dentistCDA = 'CDA: Cert. Dental Assistant', 
  dentistChairSideAssistant = 'Chairside Assistant'
}

export enum locationTypeEnum {
  pharmacy = '',
  dentalPratcice = 'dental_practice', 
  dentalProvider = 'dental_provider'
}

export enum formattedLocationTypeEnum {
  pharmacy = 'Pharmacy',
  dentalPratcice = 'Dental Practice', 
  dentalProvider = 'Dental Provider'
} 

export enum SkillType {
  software = "software",
  language = "language",
  specialization = "specialization",
  dentalSoftware = "dentalSoftware"
}

export enum HelperPageTitleEnum {
  termOfService = "Terms of Service",
  faq = "FAQ",
  privacyPolicy = "Privacy Policy",
  aboutReliefBuddy = "About",
}
