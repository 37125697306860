import React, { useState, useRef, useEffect } from "react";
import { RBModal } from "../../components/RBModal";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { RBAlert } from "../../components/RBAlert";
import { useLocation, useHistory } from "react-router";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { Spacing, Colors } from "../../theme/Theme";
import { RBStepper } from "../../components/RBStepper";
import { RBCardView } from "../../components/RBCardView";
import { formatWorkerType } from "../../util/FormattingService";
import {
  UserAccountInformation,
  UserAccountInformationValueTypes,
} from "./components/UserAccountInformation";
import {
  UserLocationInformation,
  UserLocationInformationValueTypes,
} from "./components/UserLocation";
import { EmptyString, RoutePathConstant } from "../../util/Common";
import {
  PharmacistInformation,
  PharmacistInformationValueTypes,
} from "./components/PharmacistInformation";
import {
  PharmacistProfileValueTypes,
  PharmacistProfile,
} from "./components/PharmacistProfile";
import { Skills, SkillsValueTypes } from "./components/Skills";
import {
  PhotoInformation,
  PhotoInformationValueTypes,
} from "./components/PhotoInformation";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { AuthService } from "../../api/AuthService";
import { workerTypeEnum } from "../../util/Enumeration";
import { AssistantProfile } from "./components/AssistantProfile";
import { StudentProfile } from "./components/StudentProfile";
import {
  AssistantInformationValueTypes,
  AssistantInformation,
} from "./components/AssistantInformation";
import {
  StudentInformation,
  StudentInformationValueTypes,
} from "./components/StudentInformation";
import { LicensedDentistInformation, LicensedDentistInformationValueTypes } from "./components/LicensedDentistInformation";
import { LicensedDentistProfile, LicensedDentistProfileValueTypes } from "./components/LicensedDentistProfile";
import { NonLicensedDentistInformation, NonLicensedDentistInformationValueTypes } from "./components/NonLicensedDentistInformation";
import { NonLicensedDentistProfile } from "./components/NonLicensedDentistProfile";


function getSteps(workerType: workerTypeEnum) {
  switch (workerType) {
    case workerTypeEnum.pharmacist:
      return [
        "User Information",
        "User Location",
        "Pharmacist Information",
        "Pharmacist Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.technician:
      return [
        "User Information",
        "User Location",
        "Technician Information",
        "Technician Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.assistant:
      return [
        "User Information",
        "User Location",
        "Assistant Information",
        "Assistant Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.pharmacistStudent:
    case workerTypeEnum.technicianStudent:
      return [
        "User Information",
        "User Location",
        "Student Information",
        "Student Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.dentist:
      return [
        "User Information",
        "User Location",
        "Dentist Information",
        "Dentist Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.denturist:
      return [
        "User Information",
        "User Location",
        "Denturist Information",
        "Denturist Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.dentistRDA:
      return [
        "User Information",
        "User Location",
        "RDA: Reg. Dental Assistant Information",
        "RDA: Reg. Dental Assistant Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.dentistRDH:
      return [
        "User Information",
        "User Location",
        "RDH: Reg. Dental Hygienist Information",
        "RDH: Reg. Dental Hygienist Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.dentistAdministrator:
      return [
        "User Information",
        "User Location",
        "Dental Administrator Information",
        "Dental Administrator Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.dentistSterilizationAssistant:
      return [
        "User Information",
        "User Location",
        "Sterilization/Floater Information",
        "Sterilization/Floater Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.dentistDT:
      return [
        "User Information",
        "User Location",
        "Dental Technician Information",
        "Dental Technician Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.dentistCDA:
      return [
        "User Information",
        "User Location",
        "CDA: Cert. Dental Assistant Information",
        "CDA: Cert. Dental Assistant Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.dentistCDA2:
      return [
        "User Information",
        "User Location",
        "CDA II: Cert. Dental Asst. LII Information",
        "CDA II: Cert. Dental Asst. LII Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.dentistRDT:
      return [
        "User Information",
        "User Location",
        "CRDT: Reg. Dental Tech. Information",
        "CRDT: Reg. Dental Tech. Profile",
        "Skills",
        "Photo Information",
      ];
    case workerTypeEnum.dentistChairSideAssistant:
      return [
        "User Information",
        "User Location",
        "Chairside Assistant Information",
        "Chairside Assistant Profile",
        "Skills",
        "Photo Information",
      ];
  }
}

export const RegisterWorkerPage = () => {
  const location = useLocation<{
    background?: any;
    workerType: workerTypeEnum;
    industryMode: string;
    account:any;
  }>();
  const history = useHistory();
  const [showModal, setShowModal] = useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isDiscardAlertShowing, setIsDiscardAlertShowing] = useState(false);
  const [isCurrentFormValid, setIsCurrentFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAlertShowing, setIsAlertShowing] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [alertInfo, setalertInfo] = useState<AlertInfoProps>(
    {}
  );
  const [userAccountInfo, setUserAccountInfo] = useState<
    UserAccountInformationValueTypes
  >({
    email: EmptyString,
    password: EmptyString,
    confirmPassword: EmptyString,
    firstName: EmptyString,
    lastName: EmptyString,
    phoneNumber: EmptyString,
  });

  const [userLocationInfo, setUserLocationInfo] = useState<
    UserLocationInformationValueTypes
  >({
    street: EmptyString,
    city: EmptyString,
    postalCode: EmptyString,
    province: EmptyString,
    apartmentSuiteNumber: null,
    shiftDistance: 500,
  });

  const [pharmacistInfo, setPharmacistInfo] = useState<
    PharmacistInformationValueTypes
  >({
    licensedDate: EmptyString,
    registrationNumber: EmptyString,
    provinceOfRegistration: EmptyString,
    graduationYear: EmptyString,
    schoolName: EmptyString,
  });
  const [assistantInfo, setAssistantInfo] = useState<
    AssistantInformationValueTypes
  >({
    graduationYear: EmptyString,
    schoolName: EmptyString,
    pharmacyAssistantCourseCompleted: false,
    pharmacyAssistantExperienceMonths: 0,
  });
  const [studentInfo, setStudentInfo] = useState<StudentInformationValueTypes>({
    currentSchoolYear: EmptyString,
    schoolName: EmptyString,
    registrationNumber: EmptyString,
    pharmacyAssistantExperienceMonths: 0,
  });
  const [licensedDentistInfo, setLicensedDentistInfo] = useState<
  LicensedDentistInformationValueTypes
  >({
    licenseNumber: EmptyString,
    licenseProvince: EmptyString,
    graduationYear: EmptyString,
    graduationInstitution: EmptyString,
    licensedDate: EmptyString,
    licenseExpiryDate: EmptyString,
    showToggle:false,
    toggleFlag:true,
    licenseOptional:false,
    experienceMonth: 0,
  });
  const [licensedDentistProfile, setLicensedDentistProfile] = useState<
  LicensedDentistProfileValueTypes
  >({
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false,
    q6: false
  });
  const [nonLicensedDentistInfo, setNonLicensedDentistInfo] = useState<
  NonLicensedDentistInformationValueTypes
  >({
    experienceMonth: 0,
    graduationYear: EmptyString,
    graduationInstitution: EmptyString
  })
  const [pharmacistProfile, setPharmacistProfile] = useState<
    PharmacistProfileValueTypes
  >({
    q1: false,
    q2: false,
    q3: false,
    q4: false,
    q5: false,
    q6: false,
  });
  const [skills, setSkills] = useState<SkillsValueTypes>({
    selectedSoftware: [],
    selectedSpecializations: [],
    selectedLanguages: [],
    resume: undefined,
    signature: undefined
  });

  // const [skills, setSkills] = useState<string>([])

  const [photoInfos, setPhotoInfos] = useState<PhotoInformationValueTypes>({
    agreePrivacyPolicy: false,
    frontSideID: undefined,
    backSideID: undefined,
    profileImage: undefined,
  });
  const [discardAlertSInfo, setDiscardAlertSInfo] = useState<AlertInfoProps>(
    {}
  );
  const formRef = useRef<any>();
  const invalidAccountStates = ['pending', 'denied'];
  useEffect(() => {
    // after photoInfos updated, then submit
    
    if (activeStep === 5 && (formRef.current.values.agreePrivacyPolicy === true)) {
      onSubmit();
    }
  }, [photoInfos]);

  useEffect(() => {
    localStorage.removeItem("workerSign")
    console.log("RegisterOwnerAndPharmacyPage useEffect");

    setPhotoInfos({
      ...photoInfos,
      agreePrivacyPolicy:false
    });
  }, [userAccountInfo,pharmacistInfo,assistantInfo,studentInfo,licensedDentistInfo,nonLicensedDentistInfo,pharmacistProfile,licensedDentistProfile]);


  const steps = getSteps(location.state.workerType) as string[];

  const goBackToPreviousLocation = () => {
    if (location.state && location.state.background) {
      setShowModal(false);
      history.goBack();
    } else {
      setShowModal(false);
      history.push("/");
    }
  };

  const onClose = () => {
    // if there is a background(previous path), go back to the previous path
    setDiscardAlertSInfo({
      title: "Discard Registration",
      message: 'Are you sure you want to quit? If "yes", your information will not be saved.',
      buttons: [
        <RBButton
          key={RBButtonType.cancel}
          buttonType={RBButtonType.cancel}
          onClick={() => {
            setIsDiscardAlertShowing(false);
          }}
          text="No"
        />,
        <RBButton
          key={"Discard"}
          onClick={() => {
            setIsDiscardAlertShowing(false);
            localStorage.removeItem("workerSign")
            AuthService.removeAuth();
            history.push(RoutePathConstant.SignIn);
          }}
          style ={{background: localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}}
          color="primary"
          text="Yes"
        />,
      ],
    });
    setIsDiscardAlertShowing(true);
  };
  const isUserPending = (userData: any) => {
    if (userData.owner) {
      return (
        invalidAccountStates.indexOf(userData.owner.approval.status) >= 0
      );
    }

    if (userData.pharmacist) {
      return (
        invalidAccountStates.indexOf(userData.pharmacist.approval.status) >=
        0
      );
    }

    return false;
  };
  const getUserAccountStatus = (userData: any) => {
    if (userData.owner) {
      return userData.owner.approval.status;
    }
    if (userData.pharmacist) {
      return userData.pharmacist.approval.status;
    }
    return 'approved';
  };
  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      let workerInfo;
      let workerProfile;
      switch (location.state.workerType) {
        case workerTypeEnum.pharmacist:
        case workerTypeEnum.technician:
          workerInfo = pharmacistInfo;
          workerProfile = pharmacistProfile;
          break;
        case workerTypeEnum.assistant:
          workerInfo = assistantInfo;
          workerProfile = pharmacistProfile;
          break;
        case workerTypeEnum.pharmacistStudent:
        case workerTypeEnum.technicianStudent:
          workerInfo = studentInfo;
          workerProfile = pharmacistProfile;
          break;
        case workerTypeEnum.dentist:
        case workerTypeEnum.denturist:
        case workerTypeEnum.dentistRDA:
        case workerTypeEnum.dentistCDA:
        case workerTypeEnum.dentistCDA2:
        case workerTypeEnum.dentistRDT:
        case workerTypeEnum.dentistRDH:
          workerInfo = licensedDentistInfo;
          workerProfile = licensedDentistProfile;
          break;
        case workerTypeEnum.dentistAdministrator:
        case workerTypeEnum.dentistChairSideAssistant:
        case workerTypeEnum.dentistSterilizationAssistant:
        case workerTypeEnum.dentistDT:
          workerInfo = nonLicensedDentistInfo;
          workerProfile = licensedDentistProfile;
          break;
      } 
      const payload = {
        userInfo: userAccountInfo,
        userLocation: userLocationInfo,
        pharmacistInfo: workerInfo,
        pharmacistProfile: [
          workerProfile.q1,
          workerProfile.q2,
          workerProfile.q3,
          workerProfile.q4,
          workerProfile.q5,
          workerProfile.q6,
        ],
        photoInfo: photoInfos,
        workerType: location.state.workerType,
        industryMode: location.state.industryMode,
        skills: skills,
        resume: null,
        signature: null,
      };
      console.log("register new worker onSubmit ", payload);
      await AuthService.completeRegistrationWorker(payload);
      setIsSubmitting(false);
      setalertInfo({
        title: "",
        message: `\n Profile Setup Completed Successfully!`,
        buttons: [

          <RBButton
            key={"ok"}
            onClick={async () => {
              const userData = await AuthService.loadAccountInfo();
              setIsAlertShowing(false);
              if (isUserPending(userData)) {
                history.push(RoutePathConstant.PendingApproval + (getUserAccountStatus(userData)));
        
              }
              else {
                history.push(RoutePathConstant.DashBoard);
              }
            }}
            buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
            color="primary"
            text="Ok"
          />,
        ],
      });
      setIsAlertShowing(true);

      
    } catch (err) {
      setIsSubmitting(false);
      setIsAlertShowing(true)
      // console.log("register new pharmacy", err);
      // const errorMessage = err.response ? err.response.data : err.message;
      // setSubmitErrorMessage(errorMessage);
      console.log("worker registraion error",err?.response?.data)
      const unprocessableError = err?.response?.data;
        const violations = err?.response?.data?.violations;
        let alertShown = false;
      if(unprocessableError)
      {
        if (unprocessableError?.errorStatusCode === 422 && (unprocessableError?.status === 'pending_verification' || unprocessableError?.status === 'registered')) {
         
          setalertInfo({
            title: "Registration Error",
            message: `${unprocessableError.error}`,
            buttons: [
    
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  setIsAlertShowing(false);
                }}
                buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
                color="primary"
                text="Ok"
              />,
            ],
          });
          alertShown = true;
        }


        if (unprocessableError.error.includes('The specified file')) {
         
          setalertInfo({
            title: "Invalid Inputs",
            message: `Please double check ${unprocessableError.fileName} and try again. If the problem persists please contact support.`,
            buttons: [
    
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  setIsAlertShowing(false);
                }}
                buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
                color="primary"
                text="Ok"
              />,
            ],
          });
          alertShown = true;
        }
        if (unprocessableError.error.includes('oversized')) {
          
          setalertInfo({
            title: `${unprocessableError.message}`,
            message: `Please double check ${unprocessableError.fileName} and try again. If the problem persists please contact support.`,
            buttons: [
    
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  setIsAlertShowing(false);
                }}
                buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
                color="primary"
                text="Ok"
              />,
            ],
          });
          alertShown = true;
        }
        if (
          unprocessableError.error.includes('Unprocessable Entity') &&
          Array.isArray(unprocessableError.message)
        ) {
          const problematicProperties = unprocessableError.message.reduce(
            (acc:any, item:any) => item.property + ' ' + acc,
            '',
          );
          setalertInfo({
            title: `Invalid Inputs`,
            message: `Please double check ${problematicProperties} and try again. If the problem persists please contact support.`,
            buttons: [
    
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  setIsAlertShowing(false);
                }}
                buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
                color="primary"
                text="Ok"
              />,
            ],
          });
          alertShown = true;
        }
      }

        if (Array.isArray(violations)) {
          if (violations.includes('email')) {
            
           
            setalertInfo({
              title: "Email In Use",
              message: `This email address is in use by another user.  Please select a different email and try again.`,
              buttons: [
      
                <RBButton
                  key={"confirm"}
                  onClick={async () => {
                    setIsAlertShowing(false);
                    setActiveStep(0);
                  }}
                  buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
                  color="primary"
                  text="Ok"
                />,
              ],
            });
            alertShown = true;
          }
        }

        if (!alertShown) {
          setalertInfo({
            title: "Registration Error",
            message: `There was a problem registering your account.  Please check your input and try again.  If the problem persists please contact support.`,
            buttons: [
    
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  setIsAlertShowing(false);
                  //setAlertShowing(false);
                  //history.goBack();
                }}
                buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
                color="primary"
                text="Ok"
              />,
            ],
          });
        }

    } finally {
      setIsSubmitting(false);
    }
  };
  const onFormChange = () => {
    setIsCurrentFormValid(formRef.current.isValid);
  };
  const renderForms = () => {
    if (activeStep === 0) {
      return (
        <UserAccountInformation
          innerRef={formRef}
          onFormChange={onFormChange}
          initialValues={userAccountInfo}
          account= {location.state.account}
        />
      );
    } else if (activeStep === 1) {
      return (
        <UserLocationInformation
          innerRef={formRef}
          onFormChange={onFormChange}
          initialValues={userLocationInfo}
          account= {location.state.account}
        />
      );
    } else if (activeStep === 2) {
      switch (location.state.workerType) {
        case workerTypeEnum.pharmacist:
        case workerTypeEnum.technician:
          return (
            <PharmacistInformation
              innerRef={formRef}
              onFormChange={onFormChange}
              initialValues={pharmacistInfo}
              account= {location.state.account}
            />
          );
        case workerTypeEnum.assistant:
          return (
            <AssistantInformation
              innerRef={formRef}
              onFormChange={onFormChange}
              initialValues={assistantInfo}
              account= {location.state.account}
            />
          );
        case workerTypeEnum.pharmacistStudent:
        case workerTypeEnum.technicianStudent:
          return (
            <StudentInformation
              innerRef={formRef}
              onFormChange={onFormChange}
              initialValues={studentInfo}
              account= {location.state.account}
            />
          );
        case workerTypeEnum.dentist:
        case workerTypeEnum.denturist:
        case workerTypeEnum.dentistRDA:
        case workerTypeEnum.dentistCDA:
        case workerTypeEnum.dentistCDA2:
        case workerTypeEnum.dentistRDT:
        case workerTypeEnum.dentistRDH:
          return (
            <LicensedDentistInformation
              innerRef={formRef}
              onFormChange={onFormChange}
              initialValues={licensedDentistInfo}
              workerType = {location.state.workerType}
              account= {location.state.account}
            />
          )
        case workerTypeEnum.dentistAdministrator:
        case workerTypeEnum.dentistChairSideAssistant:
        case workerTypeEnum.dentistSterilizationAssistant:
        case workerTypeEnum.dentistDT:
          return (
            <NonLicensedDentistInformation
              innerRef={formRef}
              onFormChange={onFormChange}
              initialValues={nonLicensedDentistInfo}
              workerType = {location.state.workerType}
              account= {location.state.account}
            />
          )
      }
    } else if (activeStep === 3) {
      switch (location.state.workerType) {
        case workerTypeEnum.pharmacist:
        case workerTypeEnum.technician:
          return (
            <PharmacistProfile
              innerRef={formRef}
              onFormChange={onFormChange}
              initialValues={pharmacistProfile}
              account= {location.state.account}
            />
          );
        case workerTypeEnum.assistant:
          return (
            <AssistantProfile
              innerRef={formRef}
              onFormChange={onFormChange}
              initialValues={pharmacistProfile}
              account= {location.state.account}
            />
          );
        case workerTypeEnum.pharmacistStudent:
        case workerTypeEnum.technicianStudent:
          return (
            <StudentProfile
              innerRef={formRef}
              onFormChange={onFormChange}
              initialValues={pharmacistProfile}
              account= {location.state.account}
            />
          );
        case workerTypeEnum.dentist:
        case workerTypeEnum.denturist:
        case workerTypeEnum.dentistRDA: 
        case workerTypeEnum.dentistCDA:
        case workerTypeEnum.dentistCDA2:
        case workerTypeEnum.dentistRDT:
        case workerTypeEnum.dentistRDH:
          return (
            <LicensedDentistProfile
              innerRef={formRef}
              onFormChange={onFormChange}
              initialValues={licensedDentistProfile}
              previousFormData = {licensedDentistInfo}
              workerType = {location.state.workerType}
              account= {location.state.account}
            />
          )
        case workerTypeEnum.dentistAdministrator:
        case workerTypeEnum.dentistChairSideAssistant:
        case workerTypeEnum.dentistSterilizationAssistant:
        case workerTypeEnum.dentistDT:
          return (
            <NonLicensedDentistProfile
              innerRef={formRef}
              onFormChange={onFormChange}
              initialValues={licensedDentistProfile}
              account= {location.state.account}
            />
          )
      }
    } else if (activeStep === 4) {
      return (
        <Skills
          innerRef={formRef}
          onFormChange={onFormChange}
          previousFormData = {userAccountInfo}
          initialValues={skills}
          workerType={location.state.workerType}
          account= {location.state.account}
        />
      );
    } else if (activeStep === 5) {
      console.log("photoInfos",photoInfos)
      return (
        <PhotoInformation
          innerRef={formRef}
          onFormChange={onFormChange}
          initialValues={photoInfos}
          workerType={location.state.workerType}
          account= {location.state.account}
        />
      );
    }

    return <>This should not happen</>;
  };

  const renderRegisterContent = () => {
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
        }}
      >
        <RBCardView
          style={{
            flex: 1,
            marginLeft: Spacing.large,
            paddingLeft: Spacing.large,
            paddingTop: Spacing.large,
            marginRight: Spacing.large,
            paddingRight: Spacing.large,
            paddingBottom: Spacing.large,
          }}
        >
          {submitErrorMessage && (
            <div style={{ color: "red", marginBottom: Spacing.large }}>
              {JSON.stringify(submitErrorMessage, null, 2)}
            </div>
          )}
          {renderForms()}
        </RBCardView>
      </div>
    );
  };

  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log("handleNext");
    if (formRef.current) {
      formRef.current.handleSubmit();
      console.log(
        " formRef.current.handleSubmit result ",
        formRef.current.values,
        formRef.current.errors,
        formRef.current.isSubmitting,
        formRef.current
      );
      if (formRef.current.isValid) {
        // account owner page
        if (activeStep === 0) {
          console.log("sda");
          setUserAccountInfo(formRef.current.values);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        // pharmacy info page
        else if (activeStep === 1) {
          setUserLocationInfo(formRef.current.values);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 2) {
          switch (location.state.workerType) {
            case workerTypeEnum.pharmacist:
            case workerTypeEnum.technician:
              setPharmacistInfo(formRef.current.values);
              break;
            case workerTypeEnum.assistant:
              setAssistantInfo(formRef.current.values);
              break;
            case workerTypeEnum.pharmacistStudent:
            case workerTypeEnum.technicianStudent:
              setStudentInfo(formRef.current.values);
              break;
            case workerTypeEnum.dentist:
            case workerTypeEnum.denturist:
            case workerTypeEnum.dentistRDA:
            case workerTypeEnum.dentistCDA:
            case workerTypeEnum.dentistCDA2:
            case workerTypeEnum.dentistRDT:
            case workerTypeEnum.dentistRDH:
              setLicensedDentistInfo(formRef.current.values);
              break;
            case workerTypeEnum.dentistAdministrator:
            case workerTypeEnum.dentistChairSideAssistant:
            case workerTypeEnum.dentistSterilizationAssistant:
            case workerTypeEnum.dentistDT:
              setNonLicensedDentistInfo(formRef.current.values);
              break;
          }

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 3) {
          switch (location.state.workerType){
            case workerTypeEnum.pharmacist:
            case workerTypeEnum.technician:
              setPharmacistProfile(formRef.current.values);
              break;
            case workerTypeEnum.assistant:
              setPharmacistProfile(formRef.current.values);
              break;
            case workerTypeEnum.pharmacistStudent:
            case workerTypeEnum.technicianStudent:
              setPharmacistProfile(formRef.current.values);
              break;
            case workerTypeEnum.dentist:
            case workerTypeEnum.denturist:
            case workerTypeEnum.dentistRDA:
            case workerTypeEnum.dentistCDA:
            case workerTypeEnum.dentistCDA2:
            case workerTypeEnum.dentistRDT:
            case workerTypeEnum.dentistRDH:
              setLicensedDentistProfile(formRef.current.values);
              break;
            case workerTypeEnum.dentistAdministrator:
            case workerTypeEnum.dentistChairSideAssistant:
            case workerTypeEnum.dentistSterilizationAssistant:
            case workerTypeEnum.dentistDT:
              setLicensedDentistProfile(formRef.current.values);
              break;
          }
          // setPharmacistProfile(formRef.current.values);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 4) {
          setSkills(formRef.current.values);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 5) {
          setPhotoInfos(formRef.current.values);
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderHeader = () => {
    console.log("renderHeader ", steps, location.state.workerType);
    return <RBStepper 
    steps={steps} 
    activeStep={activeStep} 
    primaryColor={location.state.account.networks[0].brand.primaryColor} 
    secondaryColor={location.state.account.networks[0].brand.secondaryColor}/>;
  };
  const renderRegisterActions = () => {
   
    if (activeStep === 0) {
      return (
        <div
          style={{
            backgroundColor: Colors.defaultGrey,
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}
        >
          <RBButton
            key={"next"}
            // disabled={!isCurrentFormValid}
            onClick={handleNext}
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
              backgroundColor:location.state.account.networks[0].brand.primaryColor
            }}
            buttonBackgroundColor={location.state.account.networks[0].brand.primaryColor}
            text="Next"
          />
        </div>
      );
    } else if (activeStep === steps.length - 1) {
      
      return (
        <div
          style={{
            backgroundColor: Colors.defaultGrey,
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}
        >
          <RBButton
            key={"back"}
            onClick={() => {
              handleBack();
            }}
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
              background:localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue
            }}
            text="Back"
          />
          <RBEmptyBlock />
          <RBButton
            key={"submit"}
            onClick={handleNext}
            isLoading={isSubmitting}
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
              background:localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue
            }}
            //disabled={!isCurrentFormValid}
            text="Submit"
          />
        </div>
      );
    }
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          alignItems: "flex-end",
        }}
      >
        <RBButton
          key={"back"}
          onClick={() => {
            handleBack();
          }}
          style={{
            display: "flex",
            flex: 1,
            padding: Spacing.medium,
            margin: Spacing.large,
            background:localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue
          }}
          text="Back"
        />
        <RBEmptyBlock />
        <RBButton
          key={"Next"}
          onClick={handleNext}
          style={{
            display: "flex",
            flex: 1,
            padding: Spacing.medium,
            margin: Spacing.large,
            background:localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue
          }}
          // disabled={!isCurrentFormValid}
          text="Next"
        />
      </div>
    );
  };
  const isMobile = window.innerWidth <= 600;
  return (
    <>
      <RBAlert
        show={isDiscardAlertShowing}
        alertTitle={discardAlertSInfo.title}
        alertMessage={discardAlertSInfo.message}
        buttons={discardAlertSInfo.buttons}
      />
       <RBAlert
        show={isAlertShowing}
        alertTitle={alertInfo.title}
        alertMessage={alertInfo.message}
        buttons={alertInfo.buttons}
      />
      <RBModal
        open={showModal}
        modalWidth={isMobile ? 400 : 800}
        onClose={onClose}
        modalTitle={`Register ${formatWorkerType(location.state.workerType)}`}
        header={renderHeader()}
        actions={renderRegisterActions()}
        children={renderRegisterContent()}
      />
    </>
  );
};
