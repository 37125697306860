import React from "react";
import { Formik, Form } from "formik";
import { RBTextFieldForForm, RBTextFieldForFormFieldType } from "../../../components/RBTextField";
import { Spacing } from "../../../theme/Theme";
import { Schema } from "../YupSchema";
import { RBSwitch } from "../../../components/RBSwitch";
import { RBMonthYearPicker } from "../../../components/RBMonthYearPicker";

export interface AssistantInformationValueTypes {
  graduationYear: string;
  schoolName: string;
  pharmacyAssistantCourseCompleted: boolean;
  pharmacyAssistantExperienceMonths: number;
}

interface AssistantInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: AssistantInformationValueTypes;
  account?:any;
}

export const AssistantInformation: React.FC<AssistantInformationProps> = ({
  ...props
}) => {
  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={Schema.AssistantInformation}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={Schema.AssistantInformation.isValidSync(
        props.initialValues
      )}
      onSubmit={() => {}}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <RBTextFieldForForm
            FieldType={RBTextFieldForFormFieldType.yearGraduation}
            label="Year of Graduation"
            placeholder="Required"
            name="graduationYear"
            value={values.graduationYear}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Institution Name"
            placeholder="Required"
            name="schoolName"
            value={values.schoolName}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBSwitch
            //labelPlacement="start"
            checked={values.pharmacyAssistantCourseCompleted}
            onChange={handleChange}
            name={"pharmacyAssistantCourseCompleted"}
            label={"Did you complete Pharmacy Assistant Course"}
            formControlStyle={{ marginBottom: Spacing.large }}
          />

          <RBMonthYearPicker
            numberOfMonths={values.pharmacyAssistantExperienceMonths}
            onValueChange={(value: number) =>
              setFieldValue("pharmacyAssistantExperienceMonths", value, true)
            }
          />
        </Form>
      )}
    </Formik>
  );
};
