// import { IoIosCash } from "react-icons/io";
import { defaultAllowOffersIconStyle } from "../theme/Theme";
import React from "react";
import { FaCommentDollar } from 'react-icons/fa';



export const defaultAllowOffersIcon = (secondaryColor: string | undefined) => {
    return (
      <FaCommentDollar
        size={defaultAllowOffersIconStyle.size }
        name="FaCommentDollar"
        color={secondaryColor}
      />
    );
};