import React from "react";
import { ShiftListController } from "../pages/shiftList/ShiftListController";
import { DashboardController } from "../pages/dashboard/DashboardController";

import { FiSettings } from "react-icons/fi";
import { TiHomeOutline } from "react-icons/ti";
import { IoIosListBox } from "react-icons/io";
import { AiOutlineLogout } from "react-icons/ai";
import {
  Home,
  ListAlt,
  AccountCircle,
  SettingsOutlined,
  AccountCircleOutlined,
  Help,
  AccountBalanceWallet,
  Info,
} from "@material-ui/icons";
import { RoutePathConstant } from "../util/Common";
import { SettingPage } from "../pages/settings/SettingPage";
import { HelperPageTitleEnum } from "../util/Enumeration";
import { LabelText } from "../util/TextConstant";
export const RoutesForWorker = [
  {
    path: RoutePathConstant.DashBoard,
    sidebarName: "Dashboard",
    // component: DashboardController,
    // icon: <TiHomeOutline size={25} color={"black"} />,
    icon: (
      <Home
      //style={{ color: "red" }}
      />
    ),
  },
  {
    path: RoutePathConstant.Shifts,
    sidebarName: "Shifts",
    //  component: ShiftListController,
    // icon: <IoIosListBox size={25} color={"black"} />,
    icon: <ListAlt />,
  },
  {
    path: RoutePathConstant.Jobs,
    sidebarName: "Jobs",
    //  component: ShiftListController,
    // icon: <IoIosListBox size={25} color={"black"} />,
    icon: <ListAlt />,
  },
  {
    path: RoutePathConstant.Payroll,
    sidebarName: "Financials",
    icon: <AccountBalanceWallet />,
  },
  {
    path: RoutePathConstant.Profile,
    sidebarName: "Profile",
    //  component: ProfileController,
    icon: <AccountCircleOutlined />,
  },
  {
    path: RoutePathConstant.Setting,
    sidebarName: "Payment Settings",
    // component: SettingPage,
    // icon: <FiSettings size={25} color={"black"} />,
    icon: <SettingsOutlined />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.faq,
    sidebarName: LabelText.HelpFAQ,
    icon: <Help />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.termOfService,
    sidebarName: LabelText.TermsOfService,
    icon: <Info />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.privacyPolicy,
    sidebarName: LabelText.PrivacyPolicy,
    icon: <Info />,
  },
  {
    path: RoutePathConstant.ContactUs,
    sidebarName: LabelText.About,
    icon: <Info />,
  },
];
export const RoutesForDentalWorker = [
  {
    path: RoutePathConstant.DashBoard,
    sidebarName: "Dashboard",
    // component: DashboardController,
    // icon: <TiHomeOutline size={25} color={"black"} />,
    icon: (
      <Home
      //style={{ color: "red" }}
      />
    ),
  },
  {
    path: RoutePathConstant.Shifts,
    sidebarName: "Shifts",
    //  component: ShiftListController,
    // icon: <IoIosListBox size={25} color={"black"} />,
    icon: <ListAlt />,
  },
  {
    path: RoutePathConstant.Jobs,
    sidebarName: "Jobs",
    //  component: ShiftListController,
    // icon: <IoIosListBox size={25} color={"black"} />,
    icon: <ListAlt />,
  },
  {
    path: RoutePathConstant.Payroll,
    sidebarName: "Financials",
    icon: <AccountBalanceWallet />,
  },
  {
    path: RoutePathConstant.Profile,
    sidebarName: "Profile",
    //  component: ProfileController,
    icon: <AccountCircleOutlined />,
  },
  // {
  //   path: RoutePathConstant.Setting,
  //   sidebarName: "Settings",
  //   // component: SettingPage,
  //   // icon: <FiSettings size={25} color={"black"} />,
  //   icon: <SettingsOutlined />,
  // },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.faq,
    sidebarName: LabelText.HelpFAQ,
    icon: <Help />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.termOfService,
    sidebarName: LabelText.TermsOfService,
    icon: <Info />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.privacyPolicy,
    sidebarName: LabelText.PrivacyPolicy,
    icon: <Info />,
  },
  {
    path: RoutePathConstant.ContactUs,
    sidebarName: LabelText.About,
    icon: <Info />,
  },
];
export const RoutesForOwner = [
  {
    path: RoutePathConstant.DashBoard,
    sidebarName: "Dashboard",
    // component: DashboardController,
    // icon: <TiHomeOutline size={25} color={"black"} />,
    icon: (
      <Home
      //style={{ color: "red" }}
      />
    ),
  },
  {
    path: RoutePathConstant.Shifts,
    sidebarName: "Shifts",
    //  component: ShiftListController,
    // icon: <IoIosListBox size={25} color={"black"} />,
    icon: <ListAlt />,
  },
  {
    path: RoutePathConstant.Jobs,
    sidebarName: "Jobs",
    //  component: ShiftListController,
    // icon: <IoIosListBox size={25} color={"black"} />,
    icon: <ListAlt />,
  },
  {
    path: RoutePathConstant.Payroll,
    sidebarName: "Financials",
    icon: <AccountBalanceWallet />,
  },
  {
    path: RoutePathConstant.Profile,
    sidebarName: "Profile",
    //  component: ProfileController,
    icon: <AccountCircleOutlined />,
  },
  {
    path: RoutePathConstant.StripePayment,
    sidebarName: "Payment Settings",
    icon: <SettingsOutlined />,
  },
  {
    path: RoutePathConstant.ChangePharmacy,
    sidebarName: "Change/Add Pharmacy",
    // component: SettingPage,
    // icon: <FiSettings size={25} color={"black"} />,
    icon: <SettingsOutlined />,
  },
  // {
  //   path: RoutePathConstant.Setting,
  //   sidebarName: "Payment Settings",
  //   // component: SettingPage,
  //   // icon: <FiSettings size={25} color={"black"} />,
  //   icon: <SettingsOutlined />,
  // },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.faq,
    sidebarName: LabelText.HelpFAQ,
    icon: <Help />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.termOfService,
    sidebarName: LabelText.TermsOfService,
    icon: <Info />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.privacyPolicy,
    sidebarName: LabelText.PrivacyPolicy,
    icon: <Info />,
  },
  {
    path: RoutePathConstant.ContactUs,
    sidebarName: LabelText.About,
    icon: <Info />,
  },
];

export const RoutesForOwnerWithoutPayment = [
  {
    path: RoutePathConstant.DashBoard,
    sidebarName: "Dashboard",
    // component: DashboardController,
    // icon: <TiHomeOutline size={25} color={"black"} />,
    icon: (
      <Home
      //style={{ color: "red" }}
      />
    ),
  },
  {
    path: RoutePathConstant.Shifts,
    sidebarName: "Shifts",
    //  component: ShiftListController,
    // icon: <IoIosListBox size={25} color={"black"} />,
    icon: <ListAlt />,
  },
  {
    path: RoutePathConstant.Jobs,
    sidebarName: "Jobs",
    //  component: ShiftListController,
    // icon: <IoIosListBox size={25} color={"black"} />,
    icon: <ListAlt />,
  },
  {
    path: RoutePathConstant.Payroll,
    sidebarName: "Financials",
    icon: <AccountBalanceWallet />,
  },
  {
    path: RoutePathConstant.Profile,
    sidebarName: "Profile",
    //  component: ProfileController,
    icon: <AccountCircleOutlined />,
  },
  {
    path: RoutePathConstant.ChangePharmacy,
    sidebarName: "Change/Add Pharmacy",
    // component: SettingPage,
    // icon: <FiSettings size={25} color={"black"} />,
    icon: <SettingsOutlined />,
  },
  // {
  //   path: RoutePathConstant.Setting,
  //   sidebarName: "Payment Settings",
  //   // component: SettingPage,
  //   // icon: <FiSettings size={25} color={"black"} />,
  //   icon: <SettingsOutlined />,
  // },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.faq,
    sidebarName: LabelText.HelpFAQ,
    icon: <Help />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.termOfService,
    sidebarName: LabelText.TermsOfService,
    icon: <Info />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.privacyPolicy,
    sidebarName: LabelText.PrivacyPolicy,
    icon: <Info />,
  },
  {
    path: RoutePathConstant.ContactUs,
    sidebarName: LabelText.About,
    icon: <Info />,
  },
];

export const RoutesForDentalOwner = [
  {
    path: RoutePathConstant.DashBoard,
    sidebarName: "Dashboard",
    // component: DashboardController,
    // icon: <TiHomeOutline size={25} color={"black"} />,
    icon: (
      <Home
      //style={{ color: "red" }}
      />
    ),
  },
  {
    path: RoutePathConstant.Shifts,
    sidebarName: "Shifts",
    //  component: ShiftListController,
    // icon: <IoIosListBox size={25} color={"black"} />,
    icon: <ListAlt />,
  },
  {
    path: RoutePathConstant.Jobs,
    sidebarName: "Jobs",
    //  component: ShiftListController,
    // icon: <IoIosListBox size={25} color={"black"} />,
    icon: <ListAlt />,
  },
  {
    path: RoutePathConstant.Payroll,
    sidebarName: "Financials",
    icon: <AccountBalanceWallet />,
  },
  {
    path: RoutePathConstant.Profile,
    sidebarName: "Profile",
    //  component: ProfileController,
    icon: <AccountCircleOutlined />,
  },
  {
    path: RoutePathConstant.StripePayment,
    sidebarName: "Payment Settings",
    icon: <SettingsOutlined />,
  },
  {
    path: RoutePathConstant.ChangePharmacy,
    sidebarName: "Change/Add Dental Practice",
    // component: SettingPage,
    // icon: <FiSettings size={25} color={"black"} />,
    icon: <SettingsOutlined />,
  },
  // {
  //   path: RoutePathConstant.Setting,
  //   sidebarName: "Settings",
  //   // component: SettingPage,
  //   // icon: <FiSettings size={25} color={"black"} />,
  //   icon: <SettingsOutlined />,
  // },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.faq,
    sidebarName: LabelText.HelpFAQ,
    icon: <Help />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.termOfService,
    sidebarName: LabelText.TermsOfService,
    icon: <Info />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.privacyPolicy,
    sidebarName: LabelText.PrivacyPolicy,
    icon: <Info />,
  },
  {
    path: RoutePathConstant.ContactUs,
    sidebarName: LabelText.About,
    icon: <Info />,
  },
];

export const RoutesForDentalOwnerWithoutPayment = [
  {
    path: RoutePathConstant.DashBoard,
    sidebarName: "Dashboard",
    // component: DashboardController,
    // icon: <TiHomeOutline size={25} color={"black"} />,
    icon: (
      <Home
      //style={{ color: "red" }}
      />
    ),
  },
  {
    path: RoutePathConstant.Shifts,
    sidebarName: "Shifts",
    //  component: ShiftListController,
    // icon: <IoIosListBox size={25} color={"black"} />,
    icon: <ListAlt />,
  },
  {
    path: RoutePathConstant.Jobs,
    sidebarName: "Jobs",
    //  component: ShiftListController,
    // icon: <IoIosListBox size={25} color={"black"} />,
    icon: <ListAlt />,
  },
  {
    path: RoutePathConstant.Payroll,
    sidebarName: "Financials",
    icon: <AccountBalanceWallet />,
  },
  {
    path: RoutePathConstant.Profile,
    sidebarName: "Profile",
    //  component: ProfileController,
    icon: <AccountCircleOutlined />,
  },
  {
    path: RoutePathConstant.ChangePharmacy,
    sidebarName: "Change/Add Dental Practice",
    // component: SettingPage,
    // icon: <FiSettings size={25} color={"black"} />,
    icon: <SettingsOutlined />,
  },
  // {
  //   path: RoutePathConstant.Setting,
  //   sidebarName: "Settings",
  //   // component: SettingPage,
  //   // icon: <FiSettings size={25} color={"black"} />,
  //   icon: <SettingsOutlined />,
  // },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.faq,
    sidebarName: LabelText.HelpFAQ,
    icon: <Help />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.termOfService,
    sidebarName: LabelText.TermsOfService,
    icon: <Info />,
  },
  {
    path: RoutePathConstant.Help + HelperPageTitleEnum.privacyPolicy,
    sidebarName: LabelText.PrivacyPolicy,
    icon: <Info />,
  },
  {
    path: RoutePathConstant.ContactUs,
    sidebarName: LabelText.About,
    icon: <Info />,
  },
];
