import moment from "moment";
import { workerTypeEnum,paymentTypeEnum } from "./Enumeration";
import { EmptyString } from "./Common";
import React from "react";
import { Colors } from "../theme/Theme";

export const formatWorkerType = (workerType: workerTypeEnum) => {
  switch (workerType) {
    case workerTypeEnum.pharmacist:
      return "Pharmacist";
    case workerTypeEnum.technician:
      return "Technician";
    case workerTypeEnum.assistant:
      return "Assistant";
    case workerTypeEnum.pharmacistStudent:
      return "Pharmacy Student";
    case workerTypeEnum.technicianStudent:
      return "Technician Student";
    case workerTypeEnum.dentist:
      return "Dentist";
    case workerTypeEnum.denturist:
      return "Denturist";
    case workerTypeEnum.dentistRDH:
      return "RDH: Reg. Dental Hygienist";
    case workerTypeEnum.dentistRDA:
      return "RDA: Reg. Dental Assistant";
    case workerTypeEnum.dentistAdministrator:
      return "Dental Administrator";
    case workerTypeEnum.dentistSterilizationAssistant:
      return "Sterilization/Floater";
    case workerTypeEnum.dentistCDA:
      return "CDA: Cert. Dental Assistant";
    case workerTypeEnum.dentistCDA2:
      return "CDA II: Cert. Dental Asst. LII";
    case workerTypeEnum.dentistRDT:
      return "RDT: Reg. Dental Tech.";
    case workerTypeEnum.dentistDT:
      return "Dental Technician";
    case workerTypeEnum.dentistChairSideAssistant:
      return "Chairside Assistant";
    default:
      return "Something is wrong";
  }
};


export const formatPaymentType = (paymentType:paymentTypeEnum) =>{
  switch (paymentType) {
    case paymentTypeEnum.creditCard:
      return 'Credit Card';
    case paymentTypeEnum.pad:
      return 'Pre-Authorised Debit (PAD)';
    case paymentTypeEnum.invoice:
      return 'Traditional Invoice';
    default:
      return 'Something is wrong';
  }
}

export const getMonthAndYearByMonth = (totalMonths: number) => {
  const year = Math.floor(totalMonths / 12);
  const month = totalMonths % 12;
  const yearText = year > 1 ? " yrs" : " yr";
  const monthText = month > 1 ? " mos" : " mo";
  if (year === 0) {
    return month + monthText;
  } else if (month === 0) {
    return year + yearText;
  } else {
    return year + yearText + " " + month + monthText;
  }
};

export const getMonthAndYearComparedToNow = (licensedDate: string) => {
  const currentMonths = Number(moment().format("MM"));
  const currentYears = Number(moment().format("YYYY"));
  const licensedMonths = Number(moment(licensedDate).format("MM"));
  const licensedYears = Number(moment(licensedDate).format("YYYY"));
  const totalMonths =
    currentMonths + currentYears * 12 - licensedYears * 12 - licensedMonths;
  const year = Math.floor(totalMonths / 12);
  const month = totalMonths % 12;
  const yearText = year > 1 ? " yrs" : " yr";
  const monthText = month > 1 ? " mos" : " mo";
  if (year === 0) {
    return month + monthText;
  } else if (month === 0) {
    return year + yearText;
  } else {
    return year + yearText + " " + month + monthText;
  }
};

function formatPhoneNumber(phoneNumber: string) {
  var cleaned = ("" + phoneNumber).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return EmptyString;
}

function formatBusinessNumber(number:string){
  var cleaned = ("" + number).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{9})(\d{4})$/);
  if (match) {
    return  match[1] + "RT" + match[2] ;
  }
  return EmptyString;
}
function formatEmail(email: string,primaryColor:string) {
  return (
    <a href={`mailto:${email}`} style={{ color: primaryColor }}>
      {email}
    </a>
  );
}


export const getDatesDuration = (startDate:any, endDate:any) => {
  const totalNumberWeeks = Math.ceil(
    moment(endDate).diff(startDate, 'weeks', true),
  );
  const numberMonths = Math.floor(totalNumberWeeks / 4);
  const numberLeftWeeks = totalNumberWeeks % 4;

  const numberWeeksDisplay =
    numberLeftWeeks === 1 ? '1 week' : `${numberLeftWeeks} weeks`;
  if (numberMonths === 0) {
    return numberWeeksDisplay;
  } else {
    const numberMonthsDisplay =
      numberMonths === 1 ? '1 month' : `${numberMonths} months`;
    if (numberLeftWeeks === 0) {
      return `${numberMonthsDisplay}`;
    } else {
      return `${numberMonthsDisplay}, ${numberWeeksDisplay}`;
    }
  }
};

const formatService = {
  formatPhoneNumber,
  formatEmail,
  formatBusinessNumber
};

export default formatService;
