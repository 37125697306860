import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, ErrorMessageProps } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import {
  RBTextFieldForForm,
  RBTextFieldForFormFieldType,
} from "../../components/RBTextField";
import { RBButton } from "../../components/RBButton";
import * as Yup from "yup";
import { makeStyles, Link, IconButton, Grid, Typography,RadioGroup, Radio, FormControl, FormLabel, FormControlLabel } from "@material-ui/core";
import {
  Spacing,
  AppContainerWidth,
  Colors,
  FontSize,
} from "../../theme/Theme";
import { RoutePathConstant, roleNameEnum , RegistrationStatus} from "../../util/Common";
import { RBCardView } from "../../components/RBCardView";
import { AuthService } from "../../api/AuthService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  InputContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: Spacing.larger,
    marginLeft: 4 * Spacing.larger,

    marginRight: 4 * Spacing.larger,
  },
  card: {
    padding: Spacing.larger,
    maxWidth: 700,
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: Spacing.medium,
    },
  },
  cardContainer: {
   
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%', // Reduce width on small screens
      padding: Spacing.medium,
    },
  },
  formContainer: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
    marginLeft:"10%"
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 2 * Spacing.larger,
    marginBottom: Spacing.larger,
    paddingLeft: 3 * Spacing.large,
    paddingRight: 3 * Spacing.large,
  },
  button:{
      width: "80%",
      paddingLeft: Spacing.large,
      paddingRight: Spacing.large,
      paddingTop: Spacing.medium,
      paddingBottom: Spacing.medium,
      marginBottom:10,
        marginTop:10,

      minHeight:50,
      margin:"auto"
  },
  submitError: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    marginTop: Spacing.medium,
    marginBottom: Spacing.medium,
    color: "red",
    fontSize: FontSize.large,
  },
  logo: {
    display: "flex",
    flex: 1,
    width: "60%",
    margin: '10px auto',
    paddingBottom: Spacing.large,
     marginBottom: 2 * Spacing.large,
  },
  radio: {
    '&$checked': {
      color: Colors.themeDarkBlue,
    },
  },
  checked: {},
}));

interface LoginProps {}

export const LoginVerificationPage: React.FC<LoginProps> = () => {
  const classes = useStyles();
  const history = useHistory();

  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [selectedMethod, setSelectedMethod] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const isMountedRef = useRef(false);
  const location = useLocation<{
    userData: any;
  }>();
  const verificationMethods =  [
    {
      type: "phone",
      description: `Text message to ${location.state.userData.phoneNumber}`,
    },
    {
      type: "email",
      description: `Email to ${location.state.userData.email}`,
    },
  ];
  

  useEffect(() => {
    setSelectedMethod(verificationMethods[0].type)
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);


  const onArrowBackIconClick = () => {
    history.push(RoutePathConstant.Login);
  };
  const onNext = () => {
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={12} sm={10} md={8} lg={6}>
      <RBCardView  className={classes.card}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
            marginLeft: 5
          }}
        >
          <IconButton
            edge="start"
            style={{ color: Colors.themeBlue }}
            onClick={onArrowBackIconClick}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>

        <Formik
          validateOnChange={true}
          validateOnBlur={true} 
          initialValues={{
            verificationMethod: verificationMethods[0].type,
          }}
          validationSchema={Yup.object().shape({
            verificationMethod: Yup.string().required("Required"),
          })}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            try {
              let payload = {userId:location.state.userData?.userId,receiveMode:selectedMethod}
              const response = await AuthService.generateActivationCode(payload);
              if(response && response.data){
                setSubmitting(false);
                history.push(`${RoutePathConstant.LogincodeVerification}`, {method:selectedMethod,userData:location.state.userData})
              }
              
            } catch (err) {
              debugger
              setSubmitting(false);
              setSubmitErrorMessage(
                err.response ? err.response.data.message : err.message
              );
            } finally {
              isMountedRef.current && setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            handleBlur,
            isValid,
            handleSubmit,
            handleChange,
            touched,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit} className={classes.formContainer}>
              <img
                 src={location.state.userData.networks[0].brand.emailLogo}
                 alt="logo"
                 className={classes.logo}
              />
              <Typography variant="h5" gutterBottom style={{textAlign:'center'}}>Account Verification</Typography>
              <Typography variant="body1" gutterBottom style={{textAlign:'center'}} >
              To verify your account, we need to send you a one-time verification code. 
              </Typography>
              <Typography variant="body1" gutterBottom style={{textAlign:'center'}}>
              Please choose your preferred method to receive this code.
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="verification method"
                  name="verificationMethod"
                  value={values.verificationMethod}
                  onChange={(event) => {
                    handleChange(event); // Properly call handleChange with event
                    setSelectedMethod(event.target.value); // Update local state
                  }}
                >
                  {verificationMethods.map((method) => (
                    <FormControlLabel
                      key={method.type}
                      value={method.type}
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={method.description}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {submitErrorMessage && (
                <div className={classes.submitError}>
                  {submitErrorMessage}
                </div>
              )}
              <div
             className={classes.InputContainer}
             style={{ marginBottom: Spacing.xlarge }}
            >
                <RBButton
               style={{
                width: "100%",
                paddingLeft: Spacing.large,
                paddingRight: Spacing.large,
                paddingTop: Spacing.medium,
                paddingBottom: Spacing.medium,
                marginBottom: 30
              }}
                  buttonBackgroundColor={location.state.userData.networks[0].brand.primaryColor}
                  text="Next"
                  isLoading={isSubmitting}
                  disabled={!isValid}
                  variant="contained"
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>
      </RBCardView>
      </Grid>
      </Grid>
  );
};
