import { useState, useEffect,useContext } from "react";
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { UIRelatedConstants } from "../theme/Theme";
import { FontSize, Spacing } from "../theme/Theme";
import { RBButton } from "./RBButton";
import { RBContext } from "./RBContext";

interface RBNetworkCancelAlertProps {
  show: boolean;
  alertTitle?: string;
  phone: string;
  email: string;
  backDropEnabled?: boolean;
  buttons: any[];
  alertWidth?: number;
  page?:string;
}

const alertDefaultStyle = {
  borderRadius: UIRelatedConstants.DialogBorderRadius,
  width: 450,
};
export const RBNetworkCancelAlert: React.FC<RBNetworkCancelAlertProps> = ({ ...props }) => {
  const [open, setOpen] = useState(props.show);
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);

  const handleClose = () => {
    setOpen(false);
  };
  const {
    backDropEnabled,
    alertTitle,
    phone,
    email,
    buttons,
    alertWidth,
    page
  } = props;
  return (
    <Dialog
      open={props.show}
      fullWidth={true}
      onClose={backDropEnabled ? handleClose : () => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: alertDefaultStyle,
      }}
    >
      {alertTitle && (
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: Spacing.large,
            wordBreak: "break-word"
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: FontSize.xlarge,
            }}
          >
            {alertTitle}
          </div>
        </DialogTitle>
      )}
      <DialogContent
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <DialogContentText
          style={{ textAlign: "justify", whiteSpace: "pre-line" }}
        >
          {page? 
          <>
          Please contact us immediately at {phone} or {email} if you need to cancel your job.
         
          </>
        :
        <>
          Please contact the Network Administrator immediately if you need to cancel your shift.​
          <br></br>
          <br></br>
          Phone: <b style={{color:primaryColor}}>{phone}</b>
          <br></br>
          Email: <b style={{color:primaryColor}}><a href={`mailto:${email}`} style={{ color: primaryColor, textDecoration: "none" }}>{email}</a></b>
        </>
        }
          
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-around",
          paddingTop: Spacing.large,
          paddingBottom: Spacing.large,
        }}
      >
        {buttons}
      </DialogActions>
    </Dialog>
  );
};

