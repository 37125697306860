import React,{useContext, useState, useEffect} from "react";
import { ShiftService } from "../../../api/ShiftService";
import { ShiftProps } from "../../../interfacesProps/ShiftProps";
import {
  detailKmAllowanceIcon,
  detailAccomodationAllowanceIcon,
  techOnSiteIcon,
  assistantOnSiteIcon,
  allowOtherProvinceIcon,
} from "../../../util/Icons";
import { Colors, FontSize, Spacing, FontWeight } from "../../../theme/Theme";
import {
  RenderTextWithTitle,
  renderTextWithTitleForSkills,
  renderTextWithTitleAndChildComponent,
  detailTextStyle,
  titleHeaderTextStyle,
} from "../../../components/TextWithHeader";
import moment from "moment-timezone";
import { formatWorkerType , getDatesDuration} from "../../../util/FormattingService";
import { LabelText } from "../../../util/TextConstant";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import { workerTypeEnum } from "../../../util/Enumeration";
import { RBContext } from '../../../components/RBContext';
import RBPlacementFeeModal from "../../../components/RBPlacementFeeModal";
import { AiTwotoneMessage } from "react-icons/ai";
import { IoIosCash } from "react-icons/io";

import { defaultAllowOffersIcon } from '../../../components/RBShiftRelatedIcons';
import { RBEmptyBlock } from "../../../components/RBEmptyBlock";
import { RBAlert } from "../../../components/RBAlert";
import { RBShiftAgreementModal } from "../../../components/RBShiftAgreementModal";
import { AuthService } from "../../../api/AuthService";
import { Link } from "@material-ui/core";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";


 
interface JobInformationProps {
  jobData: ShiftProps;
  account:ObjectLiteral;
}




const renderOnSite = (shift: ShiftProps,secondaryColor:string) => {
  const { technicianOnSite, assistantOnSite, pharmacistType } = shift;
  return (
    <div style={ColumnStyle}>
      {renderTextWithTitleAndChildComponent(
        LabelText.TECHNICIAN_ON_SITE,
        techOnSiteIcon(technicianOnSite,secondaryColor)
      )}
      {pharmacistType !== workerTypeEnum.assistant &&
        renderTextWithTitleAndChildComponent(
          LabelText.ASSISTANT_ON_SITE,
          assistantOnSiteIcon(assistantOnSite,secondaryColor)
        )}
    </div>
  );
};

export const JobInformation: React.FC<JobInformationProps> = ({
  ...props
}) => {
  const { primaryColor,secondaryColor } = useContext(RBContext);
  const { jobData,account } = props;

  const {
      jobTitle,
      jobNumber,
      jobCategory,
      jobCity,
      pharmacistType,
      estStartDate,
      estEndDate,
      estHourlyRate,
      comment,
      technicianOnSite,
      assistantOnSite,
      allowOtherProvince,
      expireAt,
      pharmacy: { name },
  } = jobData;
  const workerType = formatWorkerType(pharmacistType);
  const estStartDateValue = estStartDate
    ? moment(estStartDate).format('MMM Do YYYY')
    : "N/A";
  const estEndDateValue = estEndDate
    ? moment(estEndDate).format('MMM Do YYYY')
    : "N/A";
  const duration = 
    estStartDateValue !== "N/A" &&
    estEndDateValue !== "N/A"
      ? getDatesDuration(estStartDate, estEndDate)
      : 'N/A';
  const { software, specializations } = jobData.skills.reduce(
    (acc: any, value: any) => {
      if (value.type === "software") acc.software.push(value);
      else acc.specializations.push(value);
      return acc;
    },
    { software: [], specializations: [] }
  );
  const softwareValue =
      software.length > 0
        ? software.map((software:any) =>
            software.required
              ? software.name
              : ShiftService.getUnrequiredSoftwareName(software.name),
          )
        : [];
  const extraSkills =
    specializations.length > 0 ? specializations.map((s: any) => s.name) : [];
  const rxCount =
    typeof jobData.expectedRxCount === "string"
      ? jobData.expectedRxCount
      : jobData.pharmacy
      ? jobData.pharmacy.rxCount
      : "N/A";
  const city = jobData.shiftCity;
  const hoursDisplay = ShiftService.getShiftOfficialLengthDisplay(jobData);
  const pharmacyName = jobData.pharmacy.name;
  const commissionType = jobData.commissionType;

  const [showContract,setShowContract] = useState(false);
  


  useEffect(() => {
  }, []);




  const onContractClose = () =>{
    setShowContract(false)
  }


 


  const currentDate = moment().format('MMM Do, YYYY');
  const currentYear = moment().year();
  const wage = (estHourlyRate / 100).toFixed(2);
  const pharmacistDetails =
    account?.pharmacist?.firstName + ' ' + account?.pharmacist?.lastName;
  const licenseNumber = account?.pharmacist?.licenseNumber ?? 'N/A';
  return (
    <>
      
        {(account.roleName === 'owner') ? (showContract &&  <RBShiftAgreementModal
          open={showContract}
          onClose={onContractClose}
          modalTitle="Job Contract"
          pageName={jobData.id}
          pageType="contract"
          networkId = {account.networks[0].id}
          job="true"
         
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
        />):
        (
          showContract && (jobData.flags.isAssigned ?
            <RBShiftAgreementModal
              open={showContract}
              onClose={onContractClose}
              modalTitle="Job Contract"
              pageName={jobData.id}
              pageType="contract"
              networkId = {jobData.pharmacy.networkId}
              job="true"
            
              header={
                <RBEmptyBlock
                  style={{
                    width: "100%",
                    height: Spacing.large,
                    backgroundColor: Colors.defaultGrey,
                    flexShrink: 0,
                  }}
                />
              }
            />:
            <RBShiftAgreementModal
              open={showContract}
              onClose={onContractClose}
              modalTitle="Job Contract"
              pageName="job-contract-pharmacist"
                pageType="page"
                networkId = {jobData.pharmacy.networkId}
                jobId = {jobData.id}
                userId= { props.account.id }
                type = 'job'
                substitutions={{
                  currentDate,
                  pharmacyDetails: '[Pharmacy]',
                  jobNumber: `xxxx-${currentYear}-x-xx`,
                  pharmacistSignature: '',
                  pharmacySignature: '',
                  licenseNumber: licenseNumber,
                  pharmacistName: pharmacistDetails,
                  reliefBuddySignature: '',
                  workerType: workerType,
                  estStartDate: estStartDateValue,
                  estEndDate: estEndDateValue,
                  estHourlyRate: '$' + wage,
                  pharmacyName: name,
                }}
              header={
                <RBEmptyBlock
                  style={{
                    width: "100%",
                    height: Spacing.large,
                    backgroundColor: Colors.defaultGrey,
                    flexShrink: 0,
                  }}
                />
              }
            />
          )
        )}

      {/* {renderSpecific()} */}
      {account.roleName === 'owner' &&<> 
      <div style={{ display: "flex", flex: 1, alignItems: "baseline" }}>
        <div className={"section-header-text"}>Pharmacy</div>
        <div
          style={{
            fontWeight: "normal",
            fontSize: FontSize.default,
            marginLeft: Spacing.small,
          }}
        >
          {jobData.pharmacy.name}
        </div>
      </div>
      <br/>
      <div style={ColumnStyle}>
            {RenderTextWithTitle("Job Posting Expiry Date", expireAt ? moment(expireAt).format('MMM Do, YYYY') : 'N/A')}
          </div>
        </>}
      <div style={ColumnStyle}>
      {RenderTextWithTitle("Job Title", jobTitle && jobTitle.length > 0 ? jobTitle : 'N/A')}
      </div>
      <div style={ColumnStyle}>
      {RenderTextWithTitle("Job Type", jobCategory.name)}
      {RenderTextWithTitle("Worker Type",workerType)}
      </div>

      <div style={ColumnStyle}>
        {RenderTextWithTitle("Job Number", jobNumber)}
        {RenderTextWithTitle("Est. Hourly Rate",  '$' + (estHourlyRate / 100).toFixed(2))}
      </div>
      <div style={ColumnStyle}>
        {RenderTextWithTitle("Est. Start Date", estStartDateValue)}
        {RenderTextWithTitle("Est. End Date", estEndDateValue)}
      </div>
      <div style={ColumnStyle}>
        {RenderTextWithTitle("Est. Duration", duration)}
        {renderTextWithTitleAndChildComponent(
          `Job Agreement`,
          <Link style={{color:primaryColor,fontWeight:"bold", cursor: 'pointer' }} onClick={() => setShowContract(true)}>View</Link>
        )}
      </div>


      {renderOnSite(jobData,secondaryColor)}
      <div style={ColumnStyle}>
        {renderTextWithTitleForSkills("Software", softwareValue)}
        {RenderTextWithTitle(LabelText.DailyAvgRxCount, rxCount)}

      </div>
      <div style={ColumnStyle}>
        {renderTextWithTitleForSkills("Specializations", extraSkills)}
      </div>
      <div style={ColumnStyle}>

        {RenderTextWithTitle("Job Description",  comment ||  "N/A")}
      </div>
      <div style={ColumnStyle}>
      {renderTextWithTitleAndChildComponent(
        'Allow applicants from other provinces?',
        allowOtherProvinceIcon(allowOtherProvince,secondaryColor)
      )}
      </div>

      <div
        style={{
          height: 1,
          backgroundColor: Colors.defaultGrey,
          marginTop: Spacing.medium,
          marginBottom: Spacing.medium,
        }}
      />
    </>
  );
};
