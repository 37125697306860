import React, { createContext, useState, useEffect } from 'react';
import { getIndustryMode } from '../api/constants';
export const RBContext = createContext<any>(null);


export const RBProvider = ( {...props} ) => {
  const [industryMode, setIndustryMode] = useState(localStorage.getItem('industryMode'));
  const [primaryColor, setprimaryColor] = useState(localStorage.getItem('primaryColor')
  )
  const [secondaryColor, setSecondaryColor] = useState(localStorage.getItem('secondaryColor'))
  const [contactEmail, setContactEmail] = useState(
    localStorage.getItem('contactEmail')
  )
  const [contactPhone, setcontactPhone] = useState(
    localStorage.getItem('contactPhone')
  )
  const updateMode = (value:any) => setIndustryMode(value);
  const updateprimaryColor = (value:any) => setprimaryColor(value);
  const updateSecondaryColor = (value:any) => setSecondaryColor(value);
  const updateContactEmail= (value:any) => setContactEmail(value);
  const updateContactPhone = (value:any) => setcontactPhone(value);

  return (
    <RBContext.Provider value={{ industryMode, updateMode,primaryColor,updateprimaryColor,secondaryColor,updateSecondaryColor,contactEmail,contactPhone,updateContactEmail,updateContactPhone }}>
      {props.children}
    </RBContext.Provider>
  );
};
