import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, ErrorMessageProps } from "formik";
import {
    RBQuickRegSelectForForm,
    RBTextFieldForForm,
    RBTextFieldForFormFieldType,
    RBQuickRegTextFieldForForm
} from "../../components/RBTextField";
import { RBButton } from "../../components/RBButton";
import * as Yup from "yup";
import { makeStyles, Link, IconButton, Typography, colors, Select, MenuItem } from "@material-ui/core";
import {
    Spacing,
    AppContainerWidth,
    Colors,
    FontSize,
} from "../../theme/Theme";
import { RoutePathConstant, roleNameEnum, UserTypes, IndustryModeEnum, RegistrationStatus } from "../../util/Common";
import { RBCardView } from "../../components/RBCardView";
import { RouteComponentProps, useLocation, withRouter } from "react-router";
import { useRouteMatch, useHistory,useParams } from "react-router-dom";
import { AuthService } from "../../api/AuthService";
import { Schema } from "./YupSchema";
import { CircularProgress } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { RBAlert } from "../../components/RBAlert";
import queryString from 'query-string';
const useStyles = makeStyles((theme) => ({
    InputContainer: {
        //display: "flex",
        flexWrap: "wrap",
        marginTop: Spacing.larger,
        marginLeft: 3 * Spacing.larger,

        marginRight: 3 * Spacing.larger,
    },
    container:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: Spacing.larger,
        maxWidth: 600,
       
    },
    formContainer: {

        padding: 50,
        marginTop: "10%",
        width: "90%",
        maxWidth: 600,
        //margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", justifyContent: "flex-start",
        '@media (min-width: 768px)': {
            marginTop: "10%",
            width: "70%",
        },
        '@media (min-width: 1024px)': {
            marginTop: "15%",
            width: 700,
        },   

    },
    logo: {
        display: "flex",
        flex: 1,
        width: "60%",
        margin: "10px auto",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 2 * Spacing.larger,
        marginBottom: Spacing.larger,
        paddingLeft: 3 * Spacing.large,
        paddingRight: 3 * Spacing.large,
        '@media (max-width: 768px)': {
            flexDirection: "column",
            alignItems: "center",
            '& > *:not(:last-child)': {
                marginBottom: Spacing.medium,
            },
        },
        '@media (min-width: 769px)': {
            '& > *:not(:last-child)': {
                marginRight: Spacing.medium,  // Add margin-right between buttons on larger screens
            },
        },
    },
    button: {
        minWidth: 200,
        paddingLeft: Spacing.large,
        paddingRight: Spacing.large,
        paddingTop: Spacing.medium,
        paddingBottom: Spacing.medium,
        marginBottom: Spacing.medium,
        '@media (max-width: 768px)': {
            width: "100%",
        },
    },
    messageContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: Spacing.small,
        width: "100%", // Ensure the container takes full width of its parent
    },
    successMessage: {
        color: "green",
        fontSize: FontSize.large,
        textAlign: "center",
        marginLeft: 3 * Spacing.larger,
        marginRight: 3 * Spacing.larger,
        '@media (max-width: 768px)': {
            fontSize: FontSize.default,
            //padding: Spacing.medium,
            textAlign:'left',
        },
    },
    errorMessage: {
        color: "red",
        fontSize: FontSize.large,
        textAlign: "center",
        maxWidth: 500, // Limit the width of the message
        //margin: '0 auto', // Center the message within the container
        marginLeft: 3 * Spacing.larger,

        marginRight: 3 * Spacing.larger,
        '@media (max-width: 768px)': {
            fontSize: FontSize.default,
            textAlign:'left',
        },
    },

}));

export const QuickRegistration = ({...props}) => {
    const match = useRouteMatch<{ networkId: string, code:string }>();
    const location = useLocation<{
        email: any;
    }>();
  
    // Parse query parameters
    const queryParams = queryString.parse(location.search);
    const { code } = queryParams;

    const history = useHistory();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [industryType, setIndustryType] = useState<any[]>([]);
    const [userRoles, setUserRoles] = useState<any[]>([]);
    const [isAlertShowing, setIsAlertShowing] = useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState<any>();
    const [submitSuccessMessage, setSubmitSuccessMessage] = useState<any>();
    const [alertInfo, setalertInfo] = useState<AlertInfoProps>(
        {}
    );

    const [brandDetail, setBrandDetail] = useState<any>(null);
    const [primaryColor, setPrimaryColor] = useState('');
    const [secondaryColor, setSecondaryColor] = useState('');
    const [networkId, setNetworkId] = useState(match.params.networkId !== undefined ? match.params.networkId : '7b89c1df-df98-41e8-a06c-f3d1d748de84');
    const [resendLoader, setResendLoader] = useState(false);
    const onArrowBackIconClick = () => {
        history.push(RoutePathConstant.SignIn);
    };

   
    useEffect(() => {
        loadNetworks();
    }, []);

    useEffect(() => {
        const fetchBrandDetail = async () => {
            try {
                setIsLoading(true);
                const detail = await AuthService.brandDetail(networkId);
                setBrandDetail(detail);
                setPrimaryColor(detail.primaryColor);
                setSecondaryColor(detail.secondaryColor);
            } catch (error) {
                console.error("Failed to fetch brand detail", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchBrandDetail();
    }, [networkId]);

    const loadNetworks = async () => {
        try {
            const allNetwork = await AuthService.getAllNetworks();
            setIndustryType(allNetwork.data)
        } catch (error) {
            console.log('loadNetworks error', error);
        }
    }


    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <RBAlert
                show={isAlertShowing}
                alertTitle={alertInfo.title}
                alertMessage={alertInfo.message}
                buttons={alertInfo.buttons}
            />
            <div className={classes.container}>
                <RBCardView
                    className={classes.formContainer}
                >
                    {!history.location.pathname.includes('/invite') && <div
                        style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "flex-start",
                            marginLeft: 5
                        }}
                    >
                        <IconButton
                            edge="start"
                            style={{ color: Colors.themeBlue }}
                            onClick={onArrowBackIconClick}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </div>}

                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            firstName: "",
                            lastName: "",
                            email: location.state?.email ? location.state.email : "",
                            phoneNumber: "",
                            helathcareProfession: '',
                            type: "",
                            role: "",
                            referralCode:code ? code : ""
                        }}
                        validationSchema={Schema.UserAccountInformation}
                        validateOnBlur={false}
                        validateOnMount={true}
                        isInitialValid={Schema.UserAccountInformation.isValidSync({
                            firstName: "",
                            lastName: "",
                            email: "",
                            phoneNumber: "",
                            helathcareProfession: '',
                            type: "",
                            role: "",
                            referralCode:""
                        })}
                        onSubmit={async (data, { setSubmitting, resetForm }) => {
                            debugger
                            setSubmitting(true);
                            let getResponse = null
                            try {
                                let payload = {
                                    firstName: data.firstName,
                                    lastName: data.lastName,
                                    email: data.email,
                                    phoneNumber: data.phoneNumber,
                                    healthcareProfession: data.helathcareProfession,
                                    userType: data.type,
                                    roleOpted: data.role
                                } as any;
                                if (match && match.params && match.params.networkId) {
                                    payload.networkId = match.params.networkId;
                                }
                                if (data.referralCode !== "" && typeof data.referralCode === 'string') {
                                    payload.referralCode = data.referralCode.toUpperCase();
                                }

                                getResponse = await AuthService.preRegister(payload);
                                if (!history.location.pathname.includes('/invite')) {
                                    if (getResponse && getResponse.data) {
                                        setSubmitting(false);
                                        history.push(`${RoutePathConstant.RegisterSuccess}`,{email: data.email,networkDetails:getResponse?.data?.network});
                                    }
                                }
                                else {
                                    if (getResponse && getResponse?.data?.verificationUrl) {
                                        setSubmitErrorMessage("");
                                        setSubmitSuccessMessage(
                                            <>
                                              <p>An invitation email has been sent to {data.email}. <br></br>Check your email (and Junk/ Spam) folders for an invitation.
                                              <br></br>Click 'accept' to verify your email address and follow the instructions to get started.</p>
                                            </>
                                          );
                                        resetForm();
                                    }
                                }

                            } catch (error) {
                                setSubmitting(false);
                                if (!history.location.pathname.includes('/invite')) {
                                    setIsAlertShowing(true)
                                    if (error && error?.response && error?.response?.data) {
                                        let message = '';
                                        let buttonName = '';
                                        let redirectPath: (() => void) | null = null;
                                        if (error.response?.data?.status === RegistrationStatus.PendingVerification) {
                                            message = `This email has already been used for sign up and is pending verification.\n\n Please check your email Junk/ Spam folder.`;
                                            redirectPath = () => history.push(`${RoutePathConstant.Login}`,{email:data.email});
                                            buttonName = "OK"

                                        }
                                        else if (error.response?.data?.status === RegistrationStatus.Registered) {
                                            message = `An account is already registered under this email address. \n\nPress 'continue' to proceed to your profile.` ;
                                            redirectPath = () => history.push(`${RoutePathConstant.Login}`,{email:data.email});
                                            buttonName = "Continue"
                                        }
                                        else if (error.response?.data?.status === RegistrationStatus.PreRegistered) {
                                            message = `You have already registered. \n\nPress 'continue' to proceed to completing your profile to get started.`;
                                            const response = await AuthService.getUserRegistrationStatus(data.email);
                                            if (response && response.data) {
                                                redirectPath = () => history.push(`${RoutePathConstant.AccountVerification}`, { userData: response.data });
                                            }
                                            buttonName = "Continue"
                                        }
                                        else {
                                            message = error.response?.data?.error;
                                            redirectPath = null;
                                            buttonName = "OK";
                                        }

                                        setalertInfo({
                                            title: "",
                                            message: `\n${message}`,
                                            buttons: [
                                                error.response?.data?.status === RegistrationStatus.PendingVerification ?
                                                    (
                                                        <>
                                                            <RBButton
                                                                key={"confirm"}
                                                                onClick={async () => {
                                                                    setIsAlertShowing(false);
                                                                    if (redirectPath) {
                                                                        redirectPath();
                                                                    }
                                                                }}
                                                                buttonBackgroundColor={Colors.themeDarkBlue}
                                                                color="primary"
                                                                text="OK"
                                                            />
                                                            <RBButton
                                                                key={"confirm"}
                                                                onClick={async () => {
                                                                    setResendLoader(true)
                                                                    try {

                                                                        setIsAlertShowing(false);
                                                                        const response = await AuthService.resendInviteEmail(data.email);
                                                                        setIsAlertShowing(true)
                                                                        setResendLoader(false)
                                                                        setalertInfo({
                                                                            title: "",
                                                                            message: `\nEmail sent successfully! \n\n Please click 'accept' to verify your email address and return here to login.`,
                                                                            buttons: [
                                                                                <RBButton
                                                                                    key={"confirm"}
                                                                                    onClick={async () => {
                                                                                        setIsAlertShowing(false);
                                                                                        if (redirectPath) {
                                                                                            redirectPath();
                                                                                        }
                                                                                    }}
                                                                                    buttonBackgroundColor={Colors.themeDarkBlue}
                                                                                    color="primary"
                                                                                    text="OK"
                                                                                />
                                                                            ],
                                                                        });
                                                                    }
                                                                    catch (err) {
                                                                        setIsAlertShowing(false);
                                                                        setResendLoader(false)
                                                                        setSubmitErrorMessage(
                                                                            err.response ? err.response?.data?.message : err.message
                                                                        );
                                                                    }

                                                                }}
                                                                buttonBackgroundColor={Colors.themeDarkBlue}
                                                                color="primary"
                                                                text="RESEND"
                                                                isLoading={resendLoader}
                                                            />
                                                        </>
                                                    )
                                                :
                                               ( <><RBButton
                                                    key={"confirm"}
                                                    onClick={async () => {
                                                        setIsAlertShowing(false);
                                                    }}
                                                    buttonBackgroundColor={Colors.themeDarkBlue}
                                                    color="primary"
                                                    text="Cancel"
                                                />,
                                                <RBButton
                                                    key={"confirm"}
                                                    onClick={async () => {
                                                        setIsAlertShowing(false);
                                                        if (redirectPath) {
                                                            redirectPath();
                                                        }
                                                    }}
                                                    buttonBackgroundColor={Colors.themeDarkBlue}
                                                    color="primary"
                                                    text={buttonName}
                                                /></>)
                                            ],
                                        });
                                    }
                                }
                                else {
                                    setSubmitSuccessMessage("");
                                    if (error.response?.data?.errorStatusCode) {
                                        if (error.response?.data?.status === RegistrationStatus.PendingVerification) {
                                            setSubmitErrorMessage(`Email verification is pending. Check your email (Junk/Spam folders) for an invitation email sent. Click 'accept' to verify your email and get started.​`);
                                        }
                                        else if (error.response?.data?.status === RegistrationStatus.Registered) {
                                         setSubmitErrorMessage(
                                            <>
                                              <p>This email is already setup on the platform. <br></br> Please open the Relief Buddy mobile app or desktop poral to sign in to your account.</p>
                                            </>
                                          );
                                        }
                                        else if (error.response?.data?.status === RegistrationStatus.PreRegistered) {
                                            setSubmitErrorMessage(
                                                <>
                                                  <p>You have already registered. <br></br>Please open the Relief Buddy mobile app or desktop app to complete your profile so you can get started.</p>
                                                </>
                                              );
                                        }
                                        else {
                                            setSubmitErrorMessage(`${error.response?.data?.error}`);
                                        }

                                    }
                                }


                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            values,
                            isSubmitting,
                            handleBlur,
                            isValid,
                            handleSubmit,
                            handleChange,
                            resetForm,
                            setFieldValue
                        }) => {
                            const handleFieldChange = (e: any) => {
                                setSubmitErrorMessage("");
                                setSubmitSuccessMessage("");
                                handleChange(e);
                            };
                            return (
                                <Form onSubmit={handleSubmit}>
                                    
                                    <img
                                        src={brandDetail !== null ? brandDetail.emailLogo : null}
                                        alt="logo"
                                        className={classes.logo}
                                    />
                                    {history.location.pathname.includes('/invite') ?
                                        <div
                                            className={classes.InputContainer}
                                            style={{ marginBottom: (submitSuccessMessage || submitErrorMessage)? 0: Spacing.xlarge, textAlign: "left" }}
                                        >
                                            Please enter your information to receive an email invitation to join our network.
                                        </div> :
                                        <div >
                                            <Typography variant="h6" style={{ textAlign: "center", fontSize: FontSize.largest }}>Profile Setup</Typography>

                                        </div>}
                                    {submitSuccessMessage && (
                                        <div className={classes.messageContainer}>
                                            <Typography className={classes.successMessage}>{submitSuccessMessage}</Typography>
                                        </div>
                                    )}


                                    {submitErrorMessage && (
                                        <div className={classes.messageContainer}>
                                            <Typography className={classes.errorMessage}>{submitErrorMessage}</Typography>
                                        </div>
                                    )}

                                    <div
                                        className={classes.InputContainer}
                                        style={{ marginBottom: Spacing.xlarge }}
                                    >
                                        <RBQuickRegTextFieldForForm
                                            placeholder="First Name"
                                            name="firstName"
                                            value={values.firstName}
                                            onChange={handleFieldChange}
                                            onBlur={handleBlur}
                                            label="First Name"
                                        />
                                    </div>
                                    <div
                                        className={classes.InputContainer}
                                        style={{ marginBottom: Spacing.xlarge }}
                                    >
                                        <RBQuickRegTextFieldForForm
                                            placeholder="Last Name"
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleFieldChange}
                                            onBlur={handleBlur}
                                            label="Last Name"
                                        />
                                    </div>
                                    <div
                                        className={classes.InputContainer}
                                        style={{ marginBottom: Spacing.xlarge }}
                                    >
                                        <RBQuickRegTextFieldForForm
                                            placeholder="Email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleFieldChange}
                                            onBlur={handleBlur}
                                            label="Email"
                                        />
                                    </div>
                                    <div
                                        className={classes.InputContainer}
                                        style={{ marginBottom: Spacing.xlarge }}
                                    >
                                        <RBQuickRegTextFieldForForm
                                            placeholder="Phone Number"
                                            FieldType={RBTextFieldForFormFieldType.phone}
                                            name="phoneNumber"
                                            onChange={handleFieldChange}
                                            value={values.phoneNumber}
                                            onBlur={handleBlur}
                                            label="Phone Number"
                                        />
                                    </div>
                                    <div
                                        className={classes.InputContainer}
                                        style={{ marginBottom: Spacing.xlarge }}
                                    >
                                        <RBQuickRegSelectForForm
                                            margin={"dense"}
                                            name="helathcareProfession"
                                            value={values.helathcareProfession}
                                            style={{ marginBottom: Spacing.large }}
                                            onChange={async (event: any) => {
                                                handleFieldChange(event); // Update the field value for helathcareProfession
                                                await setFieldValue('type', ''); // Clear the type field
                                                await setFieldValue('role', ''); // Clear the role field
                                            }}
                                            onBlur={handleBlur}
                                            label="Healthcare Profession"
                                        >
                                            {industryType.map((value) => (
                                                <MenuItem value={value.industryMode} key={value.industryMode}>
                                                    {value.displayName}
                                                </MenuItem>
                                            ))}
                                        </RBQuickRegSelectForForm>

                                    </div>
                                    <div
                                        className={classes.InputContainer}
                                        style={{ marginBottom: Spacing.xlarge }}
                                    >
                                        <RBQuickRegSelectForForm
                                            margin={"dense"}
                                            name="type"
                                            value={values.type}
                                            style={{ marginBottom: Spacing.large }}
                                            onChange={async (event: any) => {
                                                const selectedType = event.target.value;

                                                // Update the type field and clear the role field immediately
                                                await setFieldValue('type', selectedType);
                                                await setFieldValue('role', '');

                                                handleFieldChange(event);

                                                if (selectedType === 'owner') {

                                                    const getlocations = await AuthService.getLocationTypesBasedonIndustry(values.helathcareProfession);
                                                    const getNewlocations = getlocations.filter((item: any) => item?.locationType === "Dental Practice")
                                                    setUserRoles(values.helathcareProfession === IndustryModeEnum.Dentistry ? getNewlocations : getlocations)
                                                }
                                                else {
                                                    const getAllWorkers = await AuthService.getAllWorkers(values.helathcareProfession);
                                                    const sortedDataArray = getAllWorkers;
                                                    sortedDataArray.sort((a: any, b: any) => a.orderSort - b.orderSort);
                                                    setUserRoles(sortedDataArray)
                                                }

                                            }}
                                            onBlur={handleBlur}
                                            label="Type"
                                        >
                                            {UserTypes.map((value) => (
                                                <MenuItem value={value.id} key={value.id}>
                                                    {value.value}
                                                </MenuItem>
                                            ))}
                                        </RBQuickRegSelectForForm>

                                    </div>
                                    <div
                                        className={classes.InputContainer}
                                        style={{ marginBottom: Spacing.xlarge }}
                                    >
                                        <RBQuickRegSelectForForm
                                            margin={"dense"}
                                            name="role"
                                            value={values.role}
                                            style={{ marginBottom: Spacing.large }}
                                            onChange={handleFieldChange}
                                            onBlur={handleBlur}
                                            label="Role"
                                        >
                                            {userRoles.map((value) => (
                                                values.type === 'owner' ?
                                                    <MenuItem value={value.locationType} key={value.locationType}>
                                                        {value.locationType}
                                                    </MenuItem> :
                                                    <MenuItem value={value.workerType} key={value.workerType}>
                                                        {value.displayName}
                                                    </MenuItem>
                                            ))}
                                        </RBQuickRegSelectForForm>

                                    </div>
                                    <div
                                        className={classes.InputContainer}
                                        style={{ marginBottom: Spacing.xlarge }}
                                    >
                                        <RBQuickRegTextFieldForForm
                                            placeholder="Referral Code"
                                            name="referralCode"
                                            value={typeof values.referralCode === 'string' ? values.referralCode.toUpperCase() : values.referralCode}
                                            onChange={handleFieldChange}
                                            onBlur={handleBlur}
                                            label="Referral Code"
                                        />
                                    </div>
                                    <div className={classes.buttonContainer}>
                                    <RBButton
                                            style={{
                                                minWidth: 200,
                                                paddingLeft: Spacing.large,
                                                paddingRight: Spacing.large,
                                                paddingTop: Spacing.medium,
                                                paddingBottom: Spacing.medium,
                                            }}
                                            buttonBackgroundColor={Colors.themeDarkBlue}
                                            text="Submit"
                                            type="submit"
                                            name="Register"
                                            variant="contained"
                                            disabled={!isValid}
                                            isLoading={isSubmitting}
                                        />        
                                        <RBButton
                                            style={{
                                                minWidth: 200,
                                                paddingLeft: Spacing.large,
                                                paddingRight: Spacing.large,
                                                paddingTop: Spacing.medium,
                                                paddingBottom: Spacing.medium,
                                            }}
                                            buttonBackgroundColor={Colors.themeLightBlue}
                                            onClick={() => resetForm()}
                                            text="Clear"
                                            variant="contained"
                                            type="button"
                                        />
                                        
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                    {!history.location.pathname.includes('/invite') && <div
                        style={{

                            textAlign: "right",
                            //justifyContent: "flex-end",
                            paddingTop: 50,
                            marginRight: 20,
                            marginBottom: 5
                            //  backgroundColor: "red",
                        }}
                    >
                        Powered by Relief Buddy

                    </div>}
                </RBCardView>
            </div>
        </>
    );
};