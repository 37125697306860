import { Spacing, FontSize, Colors } from "./Theme";
import { UIRelatedConstants } from "../theme/Theme";

export const ColumnStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  //paddingTop: Spacing.smaller,
  paddingBottom: Spacing.medium,
};

export const skillTextStyle: React.CSSProperties = {
  display: "inline-block",
  fontSize: FontSize.small,
  fontWeight: "bold",
  borderColor: Colors.defaultGrey,
  backgroundColor: Colors.defaultGrey,
  overflow: "hidden",
  borderRadius: UIRelatedConstants.SkillStyleBorderRadisu,
  borderWidth: 1,
  padding: Spacing.smallest,
  margin: Spacing.smallest,
};
export const pageStyle: React.CSSProperties = {
  display: "flex",
  flex: 1,
};

export const containerStyle: React.CSSProperties = {
  minWidth: 475,

  backgroundColor: Colors.white,
  margin: Spacing.small,
  marginLeft: -20,
};

export const TopAppBarHeight = 60;
export const BottomAppBarHeight = 60;
export const buttonStyle: React.CSSProperties = {
  paddingTop: Spacing.small,
  paddingBottom: Spacing.small,
  paddingRight: Spacing.larger,
  paddingLeft: Spacing.larger,
  minWidth: UIRelatedConstants.RBButtonMinWidth,
  color: Colors.white,
  alignItems: "center",
  justifyContent: "center",
  borderRadius: UIRelatedConstants.ButtonBorderRadius,
  backgroundColor: Colors.themeBlue,
  borderColor: Colors.themeBlue,
  textTransform: "none",
};
