// import React, {useContext } from 'react';
// import PropTypes, { Requireable, Validator } from 'prop-types';

// import { FontWeight, Font, Spacing, Colors, FontFamily } from '../theme/Theme';
// import { RBContext } from '../components/RBContext';

// interface StatBlockProps {
//     loading?: boolean;
//     value: string | number;
//     title: string;
//     subtitle: string;
//   }

//   type FlexDirectionType = 'row' | 'row-reverse' | 'column' | 'column-reverse';

// const styles = {
//   container: {
//     alignSelf: 'center',
//     padding: Spacing.large,
//     backgroundColor: Colors.white,
//     alignItems: 'center',
//     justifyContent: 'center',
//     margin: Spacing.small,
//     boxShadow: '0 2px 3.84px 0 rgba(0, 0, 0, 0.25)',
//     borderRadius: '5px',
//     width: '200px',
//   },
//   top: {
//     display: 'flex',
//     flexDirection: 'row'as FlexDirectionType,
//     alignItems: 'center',
//   },
//   value: {
//     fontSize: Font.xlarge,
//     fontWeight: FontWeight.bolder,
//     color: Colors.themeDarkBlue,
//   },
//   title: {
//     fontSize: Font.default,
//     marginLeft: Spacing.small,
//     fontWeight: FontWeight.bold,
//   },
//   subtitle: {
//     fontSize: Font.large,
//     fontWeight: FontWeight.bold,
//   },
// };

// const StatBlock: React.FC<StatBlockProps> = ({ loading, value, title, subtitle }) => {
//   const { industryMode, primaryColor } = useContext(RBContext);
//   return (
//     <div style={styles.container}>
//       <div style= {styles.top}>
//         {loading ? (
//           <div className="activity-indicator" style={{ color: primaryColor }}>
//             Loading...
//           </div>
//         ) : (
//           <span style={{ ...styles.value, fontFamily: FontFamily.family, color: primaryColor }}>{value}</span>
//         )}
//         <span style={{ ...styles.title, fontFamily: FontFamily.family }}>{title}</span>
//       </div>
//       <p style={{ ...styles.subtitle, fontFamily: FontFamily.family }}>{subtitle}</p>
//     </div>
//   );
// };

// // StatBlock.defaultProps = {
// //   loading: false,
// // };

// // StatBlock.propTypes = {
// //   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) as Requireable<string | number>,
// //   loading: PropTypes.bool,
// //   title: PropTypes.string.isRequired,
// //   subtitle: PropTypes.string.isRequired,
// // };

// export default StatBlock;

import React, { useContext } from 'react';
import { RBContext } from '../components/RBContext';
import '../components/StatBlockStyle.css';

interface StatBlockProps {
  loading?: boolean;
  value: string | number;
  title: string;
  subtitle: string;
}

const StatBlock: React.FC<StatBlockProps> = ({ loading = false, value, title, subtitle }) => {
  const { industryMode, primaryColor } = useContext(RBContext);
  return (
    <div className="statcontainer">
      <div className="stattop">
        {loading ? (
          <div className="activity-indicator" style={{ color: primaryColor }}>
            Loading...
          </div>
        ) : (
          <span className="statvalue" style={{ color: primaryColor }}>{value}</span>
        )}
        <span className="stattitle">{title}</span>
      </div>
      <p className="statsubtitle">{subtitle}</p>
    </div>
  );
};

export default StatBlock;