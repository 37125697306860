import Api from "./API";
import { AxiosError, AxiosResponse } from "axios";
import { CreateShiftDto,CreateJobDto } from "../dtos/CreateShift.dto";
import { ObjectLiteral } from "../interfacesProps/ObjectLiteralProps";
import { AuthService } from "./AuthService";

type JobDetailProps = ObjectLiteral;
type ServerError = { code: string; description: string; response: object };

const fetchSkills = async (pharmacistType?: string) => {
  try {
    let workerType = pharmacistType;
    console.log('pharmacistType ', pharmacistType)
    if (pharmacistType == 'Pharmacy' || pharmacistType == 'Dental Practice') {
      workerType = undefined;
    }
    let type = ["specialization", "software", "language"] ;
    if ( pharmacistType?.includes('ental') || pharmacistType?.includes('entist')){
      type = ["specialization", "dentalSoftware", "language"]
    }
    
    const response = await Api.axios.get("skill", {
      params: {
        limit: 2000,
        pharmacistType: workerType,
          // typeof pharmacistType === "string" ? pharmacistType : undefined,
        // Currently we do not download 'institution' at all, maybe later
        type: type,
        pharmacistTypeIsStrict: false,
      },
    });
    console.log("fetchSkills response ", response);

    return response.data;
  } catch (err) {
    if (err && err.response) {
      //   const axiosError = err as AxiosError<ServerError>;
      //   console.log("getShiftList in AxiosError", axiosError);
      //   console.log("getShiftList in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("fetchSkills in error", err);
    throw err;
  }
};

const createShift = async (payload: CreateShiftDto) => {
  try {
    const response = await Api.axios.post("shift", payload);
    return response.data;
  } catch (err) {
    if (err && err.response) {
      //   const axiosError = err as AxiosError<ServerError>;
      //   console.log("getShiftList in AxiosError", axiosError);
      //   console.log("getShiftList in AxiosError response", err.response);
      //return axiosError.response.data;
      console.log("createShift error.response.data", err.response.data);
    }
    console.log("createShift in error", err);
    throw err;
  }
};

const createJob = async (payload:any) => {
  try {
    const response = await Api.axios.post("job/v2", payload);
    return response.data;
  } catch (err) {
    if (err && err.response) {
      //   const axiosError = err as AxiosError<ServerError>;
      //   console.log("getShiftList in AxiosError", axiosError);
      //   console.log("getShiftList in AxiosError response", err.response);
      //return axiosError.response.data;
      console.log("createShift error.response.data", err.response.data);
    }
    console.log("createShift in error", err);
    throw err;
  }
};

const updateJob = async (payload :any)=> {
  const response =  await Api.axios.patch(`job/${payload.id}`, {
    ...(payload || {}),
  });

  return response;
};

const getJobDetailByShiftId = async (jobId: string) => {
  try {
    const response = await Api.axios.get<JobDetailProps, AxiosResponse<any>>(
      `job/${jobId}`
    );
    //    console.log("get shift detail response ", response);

    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("get shift detail in AxiosError", axiosError);
      console.log("get shift detail in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("get shift detail in error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
    //throw err;
  }
};


const getShiftContract = (id:any) => Api.axios.get(`shift/${id}/contract`);
const getShiftsByApplicant = async (pharmacyId:any,userId:any) => {
  return Api.axios.get(`pharmacy/${pharmacyId}/applicant/${userId}/shifts`);
};

const getJobContract = (id:any) => Api.axios.get(`job/${id}/contract`);

const updateShift = async (payload: ObjectLiteral) => {
  try {
    const response = await Api.axios.patch(`shift/${payload.id}`, {
      ...(payload || {}),
    });
    return response;
  } catch (err) {
    if (err && err.response) {
      //   const axiosError = err as AxiosError<ServerError>;
      //   console.log("getShiftList in AxiosError", axiosError);
      //   console.log("getShiftList in AxiosError response", err.response);
      //return axiosError.response.data;
      console.log("updateShift error.response.data", err.response.data);
    }
    console.log("updateShift in error", err);
    throw err;
  }
};

const updateShiftFeedback = async (payload: ObjectLiteral) => {
  try {
    const response = await Api.axios.patch(`shift/${payload.id}/feedback`, {
      ...(payload || {}),
    });
    return response;
  } catch (err) {
    if (err && err.response) {
      //   const axiosError = err as AxiosError<ServerError>;
      //   console.log("getShiftList in AxiosError", axiosError);
      //   console.log("getShiftList in AxiosError response", err.response);
      //return axiosError.response.data;
      console.log("updateShift error.response.data", err.response.data);
    }
    console.log("updateShift in error", err);
    throw err;
  }
};
const  createPAD = async (payload:any) => {   
  try {
    const response = await Api.axios.post('pharmacy/paymentInfo/update', null, { params: payload });

    return response;
  } catch (err) {
    if (err && err.response) {
      throw err;
    }
  };
}
const getPadUrl = async (locationId:any) => {
  const response = await Api.axios.post('payment-setup/bank-account/session',null, {
    params: {
      locationId: locationId
    }});
  return response.data;
}

const createCreditCard = async (token:any, selectedLocations:any,paymentSetupType:any) => { 
  let response;
    let paymentArr = [];
    console.log("selectedLocations service",selectedLocations)
    if(selectedLocations !== undefined)
    {
      for (let index = 0; index < selectedLocations.length; index++) {
        let paymentObject = {paymentType:'credit_card',pharmacyId:selectedLocations[index].id,token: token}
        paymentArr.push(paymentObject)
      }
      response = await Api.axios.post(`payment/credit-card/location/add?paymentSetupType=${paymentSetupType.toLowerCase()}`, paymentArr);
      console.log('PaymentService create CC');
    }
    else{
      let paymentObject = {paymentType:'credit_card',pharmacyId:localStorage.getItem("stripePharmacyId"),token: token.tokenId}
      paymentArr.push(paymentObject)
      response = await Api.axios.post(`payment/credit-card/location/add?paymentSetupType=${paymentSetupType.toLowerCase()}`, paymentArr);
      console.log('PaymentService create CC');
    }

  return response;
};


const acceptApplicantBulk = async (shiftIds:any,pharmacistId:any) => {
  const response =await Api.axios.patch(
    `shift/bulk-request/${pharmacistId}`,
    {
      shiftIds:shiftIds,
      state: 'approved',
      
    },
  );

  return response;
};

const rejectApplicantBulk = async (shiftIds:any,pharmacistId:any) => {
  const response =await Api.axios.patch(
    `shift/bulk-request/${pharmacistId}`,
    {
      shiftIds:shiftIds,
      state: 'denied',
    },
  );

  return response;
};

const requestShiftCancel = async (payload:any) => {
  const response = await Api.axios.post('shift/request/cancellation', payload);
  return response.data;
};



export const PharmacyService = {
  fetchSkills,
  createShift,
  updateShift,
  getShiftContract,
  createJob,
  updateJob,
  updateShiftFeedback,
  getJobContract,
  getShiftsByApplicant,
  acceptApplicantBulk,
  rejectApplicantBulk,
  createPAD,
  getPadUrl,
  createCreditCard,
  requestShiftCancel
};
