import React, { useState,useContext, useEffect } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import { Close, ArrowBack } from "@material-ui/icons";
import { IoIosClose } from "react-icons/io";

import Typography from "@material-ui/core/Typography";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Colors, Spacing, AppBackgroundColor } from "../theme/Theme";
import { positions } from "react-alert";
import { Modal, DialogTitle } from "@material-ui/core";
import { UIRelatedConstants } from "../theme/Theme";
import { RBContext } from "./RBContext";
import { PharmacyService } from "../api/PharmacyService";
import { PageService} from "../api/PageService"
import { isEmpty } from "lodash";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: "white",
    },
    closeButton: {
      position: "absolute",
      left: Spacing.small,
      // top: Spacing.smaller,
      color: Colors.themeBlue,
      padding: Spacing.smallest,
      margin: 0,
      //size: 2,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  backArrowIcon?: boolean;
  onClose: () => void;
}

const RBDialogTitle = withStyles(styles)((props: DialogTitleProps) => {

  const { children, classes, onClose, backArrowIcon, ...other } = props;
  const { primaryColor,secondaryColor } = useContext(RBContext);
  return (
    <DialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{
        display: "flex",
        height: 50,
        justifyContent: "center",
        alignItems: "center",
        //   backgroundColor: "red",
      }}
    >
      {onClose ? (
        <IconButton
          className={classes.closeButton}
          //  size="small"
          onClick={onClose}
        >
          {!!backArrowIcon ? (
            <ArrowBack style={{ fontSize: 30,color:Colors.themeDarkBlue }} />
          ) : (
            <Close style={{ fontSize: 30,color:Colors.themeDarkBlue }} />
          )}
        </IconButton>
      ) : null}
      <Typography style={{ textAlign: "center",color:primaryColor}} variant="h6">
        {children}
      </Typography>
    </DialogTitle>
  );
});

interface RBShiftAgreementModalProps extends DialogProps {
  onClose: () => void;
  backDropEnabled?: boolean;
  backArrowIcon?: boolean;
  modalTitle: string;
  pageName:string;
  pageType:string;
  networkId:string;
  substitutions?:Object;
  modalWidth?: number;
  actions?: any;
  header?: any;
  job?:string;
  shiftId?:any;
  type?:any;
  jobId?: any;
  locationId?:any;
  userId?:any;
}
const alertDefaultStyle = {
  borderRadius: UIRelatedConstants.DialogBorderRadius,
};
export const RBShiftAgreementModal: React.FC<RBShiftAgreementModalProps> = ({ ...props }) => {

    const [content,setContent] = useState('');
    useEffect(() => {
         fetchContent();
    }, []);
    const fetchContent = async () => {
        const { pageType } = props;
        try {
            if (pageType === 'contract') {
                await fetchContract();
            } else {
                await fetchPage();
            }
        } catch (error) {
            setContent('')
        }

    };
    const fetchContract = async () => {
        // pageName here is the shift id
        const { pageName, job } = props;
        const page = job? 
        await PharmacyService.getJobContract(pageName)
        : await PharmacyService.getShiftContract(pageName);
        setContent(page.data.content);
    };

    const fetchPage = async () => {
        let { pageName, substitutions, networkId, type, locationId, shiftId, jobId, userId } = props;

        if (pageName == 'kdm-help') {
            pageName = 'help'
        }
        else if (pageName == 'kdm-terms-of-use') {
            pageName = 'terms-of-use'
        }
        else if (pageName == 'kdm-privacy-policy') {
            pageName = 'privacy-policy'
        }
        else if (pageName == 'kdm-contact-us') {
            pageName = 'contact-us'
        }

        let page = await PageService.name(pageName, networkId);
        if (typeof substitutions === 'object' && !isEmpty(substitutions) ) {
            if(type == 'create')
            {
              page = await PageService.substituteOwner(page[0].id, substitutions, locationId);
            }else if(type == 'job')
            {
              page = await PageService.substituteJob(page[0].id, substitutions, jobId,userId);
            }else if(type == 'shift')
            {
              page = await PageService.substituteShift(page[0].id, substitutions, shiftId,userId);
            }else{}

        }
        if (page.length) {
            setContent(page[0].content);
        }
        else {
            setContent(page.content);
        }

    };
      
  const {
    backDropEnabled,
    modalTitle,
    pageName,
    pageType,
    networkId,
    substitutions,
    modalWidth,
    header,
    onClose,
    style,
    actions,
    backArrowIcon,
    ...rest
  } = props;
  const dataUrl = `data:text/html;charset=UTF-8,${encodeURIComponent(content)}`;
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={backDropEnabled ? onClose : () => {}}
      aria-labelledby="customized-dialog-title"
      style={style}
      // This is to set the dialog width and position,
      PaperProps={{
        style: {
          ...alertDefaultStyle,
          width: modalWidth ?? 600,
          maxHeight: "90%",
          minHeight: "70%",
          // height: "90%",
          position: "absolute",
          //   top: 50,
          top: "2%",
        },
      }}
      {...rest}
    >
      <RBDialogTitle
        id="customized-dialog-title"
        onClose={onClose}
        backArrowIcon={backArrowIcon}
      >
        {modalTitle}
      </RBDialogTitle>
      {header}
      <div
        style={{
          backgroundColor: AppBackgroundColor,
          flexGrow: 1,
        }}
      >
        <iframe src={dataUrl} style={{ height: 400,width :600 }}></iframe>
        {/* {children} */}
      </div>
      {actions}
    </Dialog>
  );
};
