import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  AccountSectionForOwnerPage,
  AccountSectionForWorkerPage,
} from "./components/AccountSectionPage";
import {
  // AddressSectionPage,
  AddressSectionForWorkerPage,
} from "./components/AddressSectionPage";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { Spacing } from "../../theme/Theme";
import {
  roleNameEnum,
  EmptyString,
  NotAvailableString,
} from "../../util/Common";
import { SecuritySection } from "./components/SecuritySectionPage";
import { WorkerDetailSection } from "./components/WorkerDetailSectionPage";
import { AuthService } from "../../api/AuthService";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { PharmacyDetailSection } from "./components/PharmacyDetailSectionPage";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum } from "../../api/constants";
import { AccountSectionForDentalOwnerPage, AccountSectionForDentalWorkerPage } from "./components/DentalAccountSectionPage";
import { DentalPracticeDetailSection } from "./components/DentalPracticeDetailSectionPage";
import { DentalWorkerDetailSection } from "./components/DentalWorkerDetailSectionPage";

interface ProfilePageWithoutRouteProps extends RouteComponentProps<any> {}

const renderOwnerProfile = (accountInfo: ObjectLiteral, industryMode: string) => {
  console.log("renderOwnerProfile ", accountInfo);
  const defaultPharmacyId =
    accountInfo.owner.defaultPharmacyId ?? accountInfo.pharmacies[0].id;
  const address = accountInfo.pharmacies.filter(
    (pharmacy: any) => pharmacy.id === defaultPharmacyId
  )[0].address;
  return (
    <div
      style={{
        paddingBottom: Spacing.large,
      }}
    >
      {industryMode === IndustryModeEnum.Pharmaceutical ? 
        <>
          <AccountSectionForOwnerPage accountInfo={accountInfo} />
          <SecuritySection email={accountInfo.email} />
          <PharmacyDetailSection accountInfo={accountInfo} />
        </> :
        <>
          <AccountSectionForDentalOwnerPage accountInfo={accountInfo} />
          <SecuritySection email={accountInfo.email} />
          <DentalPracticeDetailSection accountInfo={accountInfo} />
        </>
      } 
    </div>
  );
};
const renderWorkerProfile = (accountInfo: ObjectLiteral, licenseOptional:boolean) => {
  console.log("renderWorkerProfile ", accountInfo);
  const { pharmacist } = accountInfo;
  pharmacist.licenseOptional = licenseOptional;
  

  const address = pharmacist.address;
  return (
    <div
      style={{
        paddingBottom: Spacing.large,
      }}
    >
      {console.log('workerType ', pharmacist.type)}
      {pharmacist.type.includes('entist') ? 
        <>
          <AccountSectionForDentalWorkerPage accountInfo={accountInfo} />
          <SecuritySection email={accountInfo.email} />
          <AddressSectionForWorkerPage address={address} />
          <DentalWorkerDetailSection worker={pharmacist} account={accountInfo}/>
        </> :
        <>
          <AccountSectionForWorkerPage accountInfo={accountInfo} />
          <SecuritySection email={accountInfo.email} />
          <AddressSectionForWorkerPage address={address} />
          <WorkerDetailSection worker={pharmacist} account={accountInfo}/>
        </>
      }
      
      {/* <FileSection worker={pharmacist} fileType={ProfileFileType.resume} />
      <FileSection worker={pharmacist} fileType={ProfileFileType.signature} /> */}
    </div>
  );
};
const ProfilePageWithoutRoute: React.FC<ProfilePageWithoutRouteProps> = ({
  ...props
}) => {
  const [accountInfo, setAccountInfo] = useState({});
  const [roleName] = useState<string>(AuthService.getRoleName());
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { industryMode,primaryColor,secondaryColor, updateMode } = useContext(RBContext);
  const [selectedPharmacyId, setSelectedPharmacyId] = useState(EmptyString);
  const [licenseOptional, setLicenseOptional] = useState(false);
  const onAccountChanged = (data: string) => {
    console.log("onAccountChanged", data);
    setRefresh((pre) => !pre);
  };

  const loadWorkerTypeCategory = async (userData:any) => {
    console.log("accountInfo",userData)
    const workerTypes = await AuthService.getActiveWorkerdetails(userData.pharmacist.type);
    if(workerTypes.licnsedFieldFalseProvinces && workerTypes.licnsedFieldFalseProvinces.length >0){
      if(workerTypes.licnsedFieldFalseProvinces.includes(userData.pharmacist.licenseProvince)){
        setLicenseOptional(true)
      }
      else{
        setLicenseOptional(false)
      }
    }
  }

  useEffect(() => {
    setIsPageLoading(true);
    const unsub = NotificationService.subscribe(
      NotificationServiceTopic.AccountChanged,
      onAccountChanged
    );
    const fetchData = async () => {
      const userData = await AuthService.loadAccountInfo();
      console.log('userData ', userData)
      setAccountInfo(userData);
      console.log("ProfileController fetchData : ", userData);
      if (userData.owner) {
        if (userData.owner.defaultPharmacyId) {
          setSelectedPharmacyId(userData.owner.defaultPharmacyId);
          console.log('selectedPharmacyId ', userData.owner.defaultPharmacyId)
          const locationInfo = await AuthService.getLocation(userData.owner.defaultPharmacyId);
          console.log('locationInfo ', locationInfo);
          if ( locationInfo.data.companyType == null ) {
            updateMode(IndustryModeEnum.Pharmaceutical);
          } else {
            updateMode(IndustryModeEnum.Dentistry);
          }
        } else {
          setSelectedPharmacyId(userData.pharmacies[0].id);
          const locationInfo = await AuthService.getLocation(userData.pharmacies[0].id);
          console.log('locationInfo ', locationInfo);
          if ( locationInfo.data.companyType == null ) {
            updateMode(IndustryModeEnum.Pharmaceutical);
          } else {
            updateMode(IndustryModeEnum.Dentistry);
          }
        }
      }
      else{
        loadWorkerTypeCategory(userData);
      }
      console.log('industryMode', industryMode);
      setIsPageLoading(false);
    };
    fetchData();
    return () => {
      console.log("ProfilePageWithoutRoute clean up");
      
      unsub.unsubscribe();
    };
  }, [refresh]);

  return (
    <>
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {roleName === roleNameEnum.pharmacist &&
            renderWorkerProfile(accountInfo,licenseOptional)}
          {roleName === roleNameEnum.owner && renderOwnerProfile(accountInfo, industryMode)}
        </>
      )}
    </>
  );
};

export const ProfilePage = withRouter(ProfilePageWithoutRoute);
