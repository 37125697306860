import React, { useState, useEffect, Component, useRef ,useContext} from "react";
import { ShiftListPage } from "./ShiftListPage";
import { ShiftService } from "../../api/ShiftService";
import { ShiftProps } from "../../interfacesProps/ShiftProps";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { AuthService } from "../../api/AuthService";
import {
  Tabs,
  Tab,
  makeStyles,
  createStyles,
  IconButton,
  Paper,
  Typography,
  Icon,
} from "@material-ui/core";
import { roleNameEnum } from "../../util/Common";
import { ShiftRelatedConstants } from "../../util/ShiftConstants";
import { Colors, Spacing } from "../../theme/Theme";
import { RBAppbar } from "../../components/RBAppbar";
import moment from "moment";
import TuneIcon from "@material-ui/icons/Tune";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import FormatListBulletted from "@material-ui/icons/FormatListBulleted";
import CalendarToday from "@material-ui/icons/CalendarToday";
import CalendarTodayOutlined from "@material-ui/icons/CalendarTodayOutlined";
import CalendarViewDayOutlined from "@material-ui/icons/CalendarViewDayOutlined";
import {ReactComponent as CalendarIconSvg} from '../../img/CalendarMonthViewGrey.svg';
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { RBModal } from "../../components/RBModal";
import { RBCardView } from "../../components/RBCardView";
import { RBCalendar } from "../../components/RBCalendar";
import { RBContext } from "../../components/RBContext";
import { CalendarViewDayTwoTone } from "@material-ui/icons";
import { IndustryModeEnum } from "../../api/constants";
import { useLocation } from "react-router";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";

interface ShiftListControllerProps {}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textTransform: "none",
      // color: Colors.black,
      "&:hover": {
        color: Colors.themeBlue,
        opacity: 1,
      },
      "&$selected": {
        color: Colors.black,
        fontWeight: "600",
      },
      "&:focus": {
        color: Colors.black,
      },
    },
    selected: {
      //color: Colors.themeBlue,
    },
    indicator: {
      backgroundColor: Colors.black,
    },
  })
);

enum ShiftStateForPharmacy {
  Active = "Active",
  Past = "Past",
  Applicants = "Applicants"
}
enum ShiftStateForWorker {
  Accepted = "Accepted",
  Request = "Applied",
}
export const ShiftListController: React.FC<ShiftListControllerProps> = ({
  ...props
}) => {
  const [shifts, setShifts] = useState<ShiftProps[]>([]);
  const [applicants, setApplicants] = useState([]);
  const [calendarShifts, setCalendarShifts] = useState<ShiftProps[]>([]);
  const [refresh, setRefetch] = useState(false);
  const [shiftState, setShiftState] = useState(0);
  const [roleName] = useState<string>(AuthService.getRoleName());
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [openTuneSetting, setOpenTuneSetting] = useState(false);
  const anchorTuneRef = React.useRef<HTMLButtonElement>(null);
  const [showAllShifts, setShowAllShifts] = useState(false);
  const didMountRef = useRef(false);
  const [pharmacy, setPharmacyInfo] = useState<any>([]);
  const [viewOption, setViewOption] = useState('List');
  const [listShifts, setListShifts] = useState<ShiftProps[]>([]);
  const location = useLocation<{
    background?: any;
    selectedPharmacyIndex: any;
  }>();
  const { industryMode,primaryColor,secondaryColor,updateprimaryColor,updateSecondaryColor,updateContactEmail,updateContactPhone,updateMode  } = useContext(RBContext);

  const onShiftChanged = (data: string) => {
    console.log("onShiftChanged", data);
    setRefetch((pre) => !pre);
  };

  const fetchData = async () => {
    const userData = await AuthService.loadAccountInfo();
    setIsPageLoading(true);
    if (roleName === roleNameEnum.owner) {
      const defaultPharmacyId = userData.owner.defaultPharmacyId;
      const indexOfDefaultPharmacy = userData.pharmacies.findIndex((obj: ObjectLiteral) => obj.id == defaultPharmacyId)
      console.log('indexOfDefaultPharmacy', indexOfDefaultPharmacy)
      const pharmacy = userData.pharmacies[indexOfDefaultPharmacy ?? 0];
      console.log('pharmacy selected ', pharmacy);
      setPharmacyInfo(pharmacy);
      console.log("shiftState11",shiftState)
      switch (shiftState) {
        case 0: {
          //pharmacy upComing
          const shiftListData = await ShiftService.getShiftList({
            byPriority: true,
            showAllShifts,
            limit: 1000,
            networkId:pharmacy.networkId
          });
          setShifts(
            shiftListData.data.filter((shift: any) => {
              const actionNeededStates = [
                ShiftRelatedConstants.ShiftStateText.AwaitingHiring,
                ShiftRelatedConstants.ShiftStateText.ReadyForShift,
                ShiftRelatedConstants.ShiftStateText.ActiveShift,
                ShiftRelatedConstants.ShiftStateText.AwaitingWorkerConfirmation,
                ShiftRelatedConstants.ShiftStateText.AwaitingHoursConfirmation,
                ShiftRelatedConstants.ShiftStateText.InDispute,
                ShiftRelatedConstants.ShiftStateText.ReadyForShiftReqCancel,
                //ShiftRelatedConstants.ShiftStateText.RateShift,
              ];
              if (shift.completionAcknowledgedAt && shift.pharmacy?.ratingOptionTimer) {
                let currentTime = moment().utc();
                let completionAcknowledgedAt = moment(shift.completionAcknowledgedAt).utc().add(shift.pharmacy.ratingOptionTimer, 'minutes');

                if (currentTime.isBefore(completionAcknowledgedAt)) {

                  if (shift.pharmacy?.companyType !== null && shift?.locationAutoAcknowledged && shift?.locationAutoAcknowledged === true) {
                    actionNeededStates.push(ShiftRelatedConstants.ShiftStateText.FeedbackShift);
                  }
                  if (shift.pharmacy.companyType === null) {
                    actionNeededStates.push(ShiftRelatedConstants.ShiftStateText.RateShift);
                  }
                }

              }

              return actionNeededStates.includes(
                ShiftService.getStatusForShiftAsPharmacy(shift,roleName)
              );
            })
          );
          break;
        }
        case 1: {
          //pharmacy
          const shiftListData = await ShiftService.getShiftList({
            limit: 1000,
            showAllShifts,
            sort: ["-startTime"],
            networkId:pharmacy.networkId
          });
          setShifts(
            shiftListData.data
              .filter((shift: any) => {
                const noActionNeededStates = [
                  ShiftRelatedConstants.ShiftStateText.ShiftExpired,
                  ShiftRelatedConstants.ShiftStateText.Cancelled,
                  ShiftRelatedConstants.ShiftStateText.ShiftCompleted,
                ];
                if(shift.completionAcknowledgedAt && shift.pharmacy?.ratingOptionTimer)
                {
                  let currentTime = moment().utc();
                  let completionAcknowledgedAt = moment(shift.completionAcknowledgedAt).utc().add(shift.pharmacy.ratingOptionTimer, 'minutes');
                  if(currentTime.isAfter(completionAcknowledgedAt)){
                    
                    if(shift.pharmacy?.companyType !== null && shift?.locationAutoAcknowledged && shift?.locationAutoAcknowledged === true)
                    {
                      noActionNeededStates.push(ShiftRelatedConstants.ShiftStateText.FeedbackShift);
                    }
                    if(shift.pharmacy.companyType === null){
                      noActionNeededStates.push(ShiftRelatedConstants.ShiftStateText.RateShift);
                    }
                  }
                }
                return noActionNeededStates.includes(
                  ShiftService.getStatusForShiftAsPharmacy(shift,roleName)
                );
              })
              .sort(
                (shift1: any, shift2: any) =>
                  moment.utc(shift2.startTime).unix() -
                  moment.utc(shift1.startTime).unix()
              )
          );
          break;
        }
        case 2: {
          console.log("shiftState12",shiftState)
          const shiftListData = await ShiftService.getApplicantsByPharmacyId(
            pharmacy.id
          );
          setApplicants(shiftListData.data);
          break;
        }
      }
      console.log('list',shifts)
      setShowAllShifts(
        localStorage.getItem("showAllShifts")
          ? localStorage.getItem("showAllShifts") === "Yes"
          : false
      );
    }
    if (roleName === roleNameEnum.pharmacist) {
      let data = [];
      switch (shiftState) {
        case 0: {
          // worker accepted
          const shiftListData = await ShiftService.getShiftList({
            limit: 1000,
            sort: ["-startTime"],
            state: "assigned",
            networkId:userData.networks[0].id
          });
          const oldTime = moment().subtract(3,'days')
          let newData = shiftListData.data;
          if (viewOption === 'List'){
            const oldTime = moment().subtract(3,'days')
            newData = newData.filter((shift:any) => {
              return !(shift.flags 
                && ((shift.completionAcknowledgedAt && moment(shift.completionAcknowledgedAt) < oldTime) || shift.flags.isCancelled) 
                && moment(shift.startTime) < oldTime)
            })
          }
      
            setShifts(newData.filter((shift:any) => {

              if(shift.pharmacistType.includes('entist'))
              {
                if(shift.completionAcknowledgedAt && shift?.workerAutoAcknowledged && 
                  shift?.workerAutoAcknowledged === true && shift.pharmacy?.ratingOptionTimer)
                {
                
                  let currentTime = moment().utc();
                  let completionAcknowledgedAt = moment(shift.completionAcknowledgedAt).utc().add(shift.pharmacy.ratingOptionTimer, 'minutes');
                  if(currentTime.isBefore(completionAcknowledgedAt)){
                    return shift.flags && shift.flags.isAssigned;
                  }
                  else{
                    return false;
                  }
                
                }
              }
              
              return shift.flags && shift.flags.isAssigned;
            }));


          break;
        }
        case 1: {
          // worker applied
          const shiftListData = await ShiftService.getShiftList({
            limit: 1000,
            sort: ["-startTime"],
            state: "requested",
            networkId:userData.networks[0].id
          });
          let newData = shiftListData.data;
          if (viewOption === 'List'){
            const oldTime = moment().subtract(3,'days')
            newData = newData.filter((shift:any) => {
              return !(shift.flags 
                && ((shift.completionAcknowledgedAt && moment(shift.completionAcknowledgedAt) < oldTime) || shift.flags.isCancelled) 
                && moment(shift.startTime) < oldTime)
            })
          }

          setShifts(
            newData.filter((shift: any) => {
              return !(shift.flags && shift.flags.isAssigned);
            })
          );

          break;
        }
      }
    }
    setCalendarShifts(shifts);
    setIsPageLoading(false);
  };
  useEffect(() => {

    (async () => {
        await updateprimaryColor( localStorage.getItem('primaryColor')); 
        await updateSecondaryColor( localStorage.getItem('secondaryColor')); 
        await updateContactEmail( localStorage.getItem('contactEmail')); 
        await updateContactPhone( localStorage.getItem('contactPhone')); 
        await updateMode(localStorage.getItem('industryMode')); 
        
    })();  

    
    const unsub = NotificationService.subscribe(
      NotificationServiceTopic.ShiftChanged,
      onShiftChanged
    );
    

    fetchData();

    return () => {
      console.log("shift list controller clean up");
      unsub.unsubscribe();
    };
  }, [shiftState, refresh]);
  useEffect(() => {
    console.log("worker shift",shifts)
    setCalendarShifts(shifts);
  }, [shifts]);
  useEffect(() => {
    if (didMountRef.current) {
      console.log("showAllShifts changed for shift list", showAllShifts);
      localStorage.setItem("showAllShifts", showAllShifts ? "Yes" : "No");
      fetchData();
    } else {
      didMountRef.current = true;
    }
  }, [showAllShifts, viewOption]);
  useEffect(() => {
    setViewOption('List');
    if(localStorage.getItem('ShiftState'))
    {
      setShiftState(Number(localStorage.getItem('ShiftState')));
    }
  }, [])

  const handleShiftStateChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setShiftState(newValue);
    localStorage.setItem('ShiftState',newValue.toString())
  };
  const classes = useStyles();
  const renderAppBar = () => {
    // const pharmacyName = AuthService.getOwnerAccountInfo();
    // console.log('ownerAccountInfo', pharmacy)
    const allOfficeText = industryMode === IndustryModeEnum.Dentistry ?
                          'Dental Practices' :
                          'Pharmacies'
    console.log('showAllShift', showAllShifts)
    console.log("shiftState",shiftState)
    return (
      <>
        <RBAppbar
          searchable={false}
          barName={roleName === roleNameEnum.owner 
            ? showAllShifts 
            ? 'All Locations' 
            : pharmacy.name 
            : 'Schedule'}
          barChildren={
            <>
              <Tabs
                value={shiftState}
                onChange={handleShiftStateChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                classes={{ indicator: classes.indicator }}
                style={{
                  marginRight: Spacing.small,
                }}
              >
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  label={
                    roleName === roleNameEnum.owner
                      ? ShiftStateForPharmacy.Active
                      : ShiftStateForWorker.Accepted
                  }
                />
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  label={
                    roleName === roleNameEnum.owner
                      ? ShiftStateForPharmacy.Past
                      : ShiftStateForWorker.Request
                  }
                />
                {roleName === roleNameEnum.owner &&
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  label={ShiftStateForPharmacy.Applicants
                  }
                />}
              </Tabs>
              {/* {roleName === roleNameEnum.owner && ( */}
                {shiftState !== 2 &&
                <IconButton
                  style={{ color: primaryColor }}
                  onClick={() => setOpenTuneSetting((prevOpen) => !prevOpen)}
                  ref={anchorTuneRef}
                >
                  <TuneIcon />
                </IconButton>}
              {/* // )} */}
            </>
          }
        />
      </>
    );
  };
  const renderTuneSettingContent = () => {
    const pharmacyName = AuthService.getOwnerAccountInfo().pharmacyName;
    return (
      <>
      <Paper style={{ margin: Spacing.medium }}>
        {roleName === roleNameEnum.owner &&
          (<RBCardView
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: Spacing.small,
            }}
            clickable={true}
            onClick={async () => {
              setOpenTuneSetting(false);

              setShowAllShifts((pre) => !pre);
            }}
          >
            <SwapHorizIcon fontSize={"large"} />
            <div style={{ paddingLeft: Spacing.larger }}>
              <Typography>
                {showAllShifts ? "View limited shifts" : "View all shifts"}
              </Typography>
              <Typography variant={"caption"}>
                {showAllShifts
                  ? `Select here to view: ${pharmacyName}`
                  : "Select here to view: Shifts for all"}
              </Typography>
            </div>
          </RBCardView>
          ) } 
        </Paper>
        <Paper style={{ margin: Spacing.medium }}>
          <RBCardView
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: Spacing.small,
            }}
            // clickable={true}
            // onClick={async () => {
            //   setOpenTuneSetting(false);

            //   // setShowAllShifts((pre) => !pre);
            // }}
          >
            <div style={{ paddingLeft: Spacing.larger, flex: 1, textAlign: 'center' }}>
              <Typography>
                Shifts display as
              </Typography>
            </div>
            {renderViews()}
          </RBCardView>
      </Paper>
      </>
    );
  };

  const renderViews = () => {
    const anchorStyles = {
      cursor: 'pointer',
      // filter:`brightness(0) invert(1) saturate(100%) hue-rotate(${secondaryColor}deg)`,

    }

    return (
      <div style={{flex:1 , textAlign: 'center'}}>
        <a
          style={anchorStyles}
          onClick={() => {
            setViewOption('List');
            setOpenTuneSetting(false);
            // setShowAllShifts(() => true)
          }}
        >
          <FormatListBulletted
            style={{
              width: 35,
              height: 35,
              marginTop: 10,
              marginRight: 20,
              color: viewOption === 'List' ? 
                     secondaryColor : 
                     'grey'
            }}
          />
        </a>
        <a
           style={anchorStyles}
           onClick={() => {
            setViewOption('Calendar');
            setOpenTuneSetting(false);
          }}
        >
       <CalendarIconSvg
          style={{
            width: 35,
            height: 35,
            marginTop: 10,
            marginRight: 20,
            fill: viewOption === 'Calendar'?
                  secondaryColor :
                  'grey'
          }}    
        />
        </a>
       
      </div>
    )
  }
  const renderTuneSetting = () => {
    // const pharmacyName = AuthService.getOwnerAccountInfo().pharmacyName;
    const allOfficeText = industryMode === IndustryModeEnum.Dentistry ?
                          'Dental Practices' :
                          'Pharmacies'
    return (
      <RBModal
        open={openTuneSetting}
        backDropEnabled={true}
        style={{ minHeight: 0, height: roleName === roleNameEnum.owner ? 300 : 200}}
        onClose={() => setOpenTuneSetting((prevOpen) => !prevOpen)}
        modalTitle={roleName === roleNameEnum.owner ?
          showAllShifts
            ? `Current display: All Locations`
            : `Current display: ${pharmacy.name}` :
            `Current filter: ${viewOption}`
        }
        children={renderTuneSettingContent()}
      />
    );
  };
  const scrollRestore = window.history.scrollRestoration;
  // console.log("scrollRestore", scrollRestore);

  const onActiveDayChanged = (activeDate: Date) => {
    setCalendarShifts(
      shifts.filter(
        (shift) =>
          moment(shift.startTime).format("YYYY-MM-DD") ===
          moment(activeDate).format("YYYY-MM-DD")
      )
    );
    console.log(
      "calendarShifts ",
      calendarShifts.length,
      moment(activeDate).format("YYYY-MM-DD")
    );
  };
  return (
    <>
      {renderAppBar()}
      {/* {roleName === roleNameEnum.owner && renderTuneSetting()} */}
      {renderTuneSetting()}
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
          <>
          {viewOption === 'Calendar' && shiftState !== 2 && (
          <>
            <div style={{ margin: Spacing.large }}>
              <RBCalendar
                shifts={shifts}
                onActiveDayChanged={onActiveDayChanged}
              />
            </div>
            <ShiftListPage shifts={calendarShifts} roleName={roleName} />
          </>
          )}
          
          {viewOption === 'List' && shiftState !== 2 && (
          <ShiftListPage shifts={shifts} roleName={roleName} />
          )}
          {
            shiftState === 2 &&
            <ShiftListPage applicants={applicants} roleName={roleName} pharmacy={pharmacy}/>
          }
        </>
      )}
    </>
  );
};
