import React, { useState, useEffect,useContext } from "react";
import moment from "moment";
//import InfiniteCalendar from "react-infinite-calendar";
import "react-infinite-calendar/styles.css"; // only needs to be imported once
// Render the Calendar
import Calendar from "react-calendar";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../theme/Theme";
import { ObjectLiteral } from "../interfacesProps/ObjectLiteralProps";
import { RBContext } from './RBContext';

const useStyles = makeStyles((_theme) => ({
  calendar: {
    borderWidth: `0 !important`,
    maxWidth: "100%",

    "& .react-calendar__viewContainer": {
      "& .react-calendar__tile": {
        borderWidth: "1px",
        borderRadius: "5",
        borderStyle: "solid",
        borderColor: Colors.white,
        //   backgroundColor: Colors.white,
      },
      "& .react-calendar__tile--active": {
        //borderRadius: "5px",
        // borderWidth: "0px",

        padding: "0px",
        margin: "0px",
        color: `#FFFFFF`,
        backgroundColor: `${localStorage.getItem('secondaryColor')} !important`,
      },
      "& .react-calendar__tile--now": {
        padding: "0px",
        margin: "0px",
        color: `#FFFFFF`,
        backgroundColor: `#808080`,
      },

      "& .react-calendar__tile--occupied": {
        // borderRadius: "5px",

        // paddingRight: "-1px",
        // paddingLeft: "-1px",

        //  borderWidth: "0px",

        color: `#FFFFFF !important`,
        backgroundColor: `${localStorage.getItem('primaryColor')} !important`,
      },

      // "& .react-calendar__tile--now": {
      //   color: `#34af47 !important`,
      //   backgroundColor: `rgba(0,0,0,0) !important`,
      // },
    },
    "& .react-calendar__navigation": {
      height: "40px",
      "& .react-calendar__navigation__arrow": {
        color: "#000",
        fontSize: "2em",
        backgroundColor: `#FFFFFF !important`,
        "&:enabled": {
          "&:hover, &:focus": {
            backgroundColor: `${localStorage.getItem('secondaryColor')} !important`,
          },
        },
      },
      "& .react-calendar__navigation__label": {
        color: "#000",
        fontSize: "2em",
        backgroundColor: `#FFFFFF !important`,
        "&:enabled": {
          "&:hover, &:focus": {
            backgroundColor: `${localStorage.getItem('secondaryColor')} !important`,
          },
        },
      },
    },
    "& abbr": {
      border: `none !important`,
      cursor: `inherit !important`,
      textDecoration: `none`,
    },
    "& .react-calendar__month-view__weekdays ": {
      fontSize: "1.5em",
      textAlign: "center",
      textDecoration: "none",
      border: "none",
    },
    "& .react-calendar__month-view__days__day ": {
      fontSize: "1.5em",
      textAlign: "center",
      textDecoration: "none",
    },
    "& .react-calendar__month-view__days__day--neighboringMonth": {
      color: "#757575",
    },
    "& .react-calendar__year-view__months__month": {
      fontSize: "2em",
      textAlign: "center",
      textDecoration: "none",
    },
  },
}));

interface RBCalendarProps {
  shifts: ObjectLiteral[];
  onActiveDayChanged: any;
}

export const RBCalendar: React.FC<RBCalendarProps> = ({ ...props }) => {
  const [currentActiveDate, setCurrentActiveDate] = useState(new Date());
  const [currentShifts, setCurrentShifts] = useState<ObjectLiteral[]>([]);

  const {primaryColor,secondaryColor } = useContext(RBContext);

  useEffect(() => {
    setCurrentShifts(props.shifts);
  }, [props.shifts]);

  useEffect(() => {
    props.onActiveDayChanged(currentActiveDate);
  }, [currentActiveDate]);
  const onChange = (date: any) => {
    console.log("onChange ", date);
    //setValue(nextValue);
    //return "calendar-unselected";
  };

  const onClickDay = (date: any) => {
    // if (dateInList(date, datesCopy)) {
    //   datesCopy = remove(date, datesCopy);
    // } else {
    //   datesCopy.push(date);
    // }
    console.log("onClickDay ", date);
    setCurrentActiveDate(date);
  };
  const onViewChange = (activeStartDate: any) => {
    console.log("onViewChange ", activeStartDate);
  };
  const onClickMonth = (date: any) => {
    console.log("onClickMonth ", date);
  };
  // const tileContent = () => {
  //   return <p style={{ backgroundColor: "red" }}>ss</p>;
  // };

  const highlightedDates = [new Date()];
  const getClassName = (date: Date) => {
    //   shifts[0].startTime

    if (
      currentShifts.filter(
        (shift) =>
          moment(shift.startTime).format("YYYY-MM-DD") ===
          moment(date).format("YYYY-MM-DD")
      ).length > 0 &&
      moment(date).format("YYYY-MM-DD") !==
        moment(currentActiveDate).format("YYYY-MM-DD")
    ) {
      //   console.log("getClassName ", moment(date).format("YYYY-MM-DD"));
      // console.log("highlightedDates includes");
      return "react-calendar__tile--occupied";
    } else {
      // console.log("highlightedDates does not include");
      return "calendar-unselected";
    }
  };
  const classes = useStyles();
  return (
    <div style={{}}>
      <Calendar
        locale="en-US"
        minDetail={"month"}
        className={classes.calendar}
        onChange={onChange}
        onClickMonth={onClickMonth}
        onActiveStartDateChange={onViewChange}
        onViewChange={onViewChange}
        // value={value}
        onClickDay={onClickDay}
        tileClassName={(props) => getClassName(props.date)}
        defaultValue={new Date()}
      />
    </div>
  );
};
