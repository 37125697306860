import React, { useState, useRef, useEffect, createRef ,useContext} from "react";
import { Formik, Form, ErrorMessageProps } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { RBTextFieldForForm, RBTextFieldForFormFieldType } from "../../components/RBTextField";
import { RBButton } from "../../components/RBButton";
import * as Yup from "yup";
import { makeStyles, Link, IconButton, Grid, Typography, Box, Button, Container, TextField } from "@material-ui/core";
import { Spacing, AppContainerWidth, Colors, FontSize } from "../../theme/Theme";
import { RoutePathConstant, roleNameEnum, RegistrationStatus } from "../../util/Common";
import { RBCardView } from "../../components/RBCardView";
import { AuthService } from "../../api/AuthService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import OtpInput from 'react-otp-input';
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { RBContext } from "../../components/RBContext";

const useStyles = makeStyles((theme) => ({
  InputContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: Spacing.larger,
    marginLeft: 4 * Spacing.larger,

    marginRight: 4 * Spacing.larger,
  },
  card: {
    padding: Spacing.larger,
    maxWidth: 700,
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: Spacing.medium,
    },
  },
  formContainer: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft:"10%"
      },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 2 * Spacing.larger,
    marginBottom: Spacing.larger,
    paddingLeft: 3 * Spacing.large,
    paddingRight: 3 * Spacing.large,
  },
  button:{
      width: "80%",
      paddingLeft: Spacing.large,
      paddingRight: Spacing.large,
      paddingTop: Spacing.medium,
      paddingBottom: Spacing.medium,
      marginBottom:10,
        marginTop:10,

      minHeight:50,
      margin:"auto"
  },
  logo: {
    display: "flex",
    flex: 1,
    width: "60%",
    margin: '10px auto',
    paddingBottom: Spacing.large,
    marginBottom: 2 * Spacing.large,
  },
  successMessage: {
    display: "flex",
    justifyContent: "center",
    marginTop: Spacing.medium,
    marginBottom: Spacing.medium,
    color: "green",
    fontSize: FontSize.large,
    textAlign: "center",
    '@media (max-width: 768px)': {
      fontSize: FontSize.default,
      padding: Spacing.medium,
    },
  },
  submitError: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    marginTop: Spacing.medium,
    marginBottom: Spacing.medium,
    color: "red",
    fontSize: FontSize.large,
  },
  resendContainer: {
    display: 'flex', // Ensure this is a flex container
    justifyContent: 'center', // Center the content
    alignItems: 'center', // Center the content vertically
    flexDirection: 'row',
  },
  resendText: {
    color: Colors.blue,
    marginLeft: Spacing.small,
  },
}));


interface LoginProps { }

export const LoginCodeVerificationPage: React.FC<LoginProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{
    method: any,
    userData: any;
  }>();

  const [submitErrorMessage, setSubmitErrorMessage] = useState<ErrorMessageProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const isMountedRef = useRef(false);
  const [otp, setOtp] = useState('');
  const invalidAccountStates = ['pending', 'denied'];
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState<any>();
  const [resending,setResending] = useState(false);
  const { primaryColor,secondaryColor, industryMode,contactEmail,contactPhone ,updateprimaryColor,updateSecondaryColor,updateContactEmail,updateContactPhone,updateMode } = useContext(RBContext);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const handleOtpChange = (otp: any) => {
    setOtp(otp);
    setSubmitErrorMessage(undefined)
    setSubmitSuccessMessage('')
  }




  const onArrowBackIconClick = () => {
    history.push(RoutePathConstant.AccountVerification, { userData: location.state.userData });
  };

  const handleResendCode = async () => {
    try {
      setResending(true)
      let payload = {userId:location.state.userData?.userId,receiveMode:location.state.method}
      const response = await AuthService.generateActivationCode(payload);
      if(response && response.data){
        setSubmitErrorMessage(undefined);
        setSubmitSuccessMessage(`Verification Code Resent!`);
        setResending(false)
      }
      
    } catch (err) {
      setResending(false)
      setSubmitSuccessMessage('')
      setSubmitErrorMessage(
        err.response ? err.response.data.message : err.message
      );
    } 
  };

  const isRegistrationPending = async () => {
    const userData = await AuthService.loadAccountInfo();
    return RegistrationStatus.PreRegistered === userData?.registrationStatus;
  };
  


  const isMobile = window.innerWidth <= 600;
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={12} sm={10} md={8} lg={6}>
     <RBCardView
       className={classes.card}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
            marginLeft: 5
          }}
        >
          <IconButton
            edge="start"
            style={{ color: Colors.themeBlue }}
            onClick={onArrowBackIconClick}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>

        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={{
          }}
          validationSchema={Yup.object().shape({
          })}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            try {
              let payload = {userId:location.state.userData.userId,activationCode:otp}
              await AuthService.loginWithCode(payload);

              if (await isRegistrationPending()) {
                await updateprimaryColor( localStorage.getItem('primaryColor')); 
                await updateSecondaryColor( localStorage.getItem('secondaryColor')); 
                await updateContactEmail( localStorage.getItem('contactEmail')); 
                await updateContactPhone( localStorage.getItem('contactPhone')); 
                await updateMode(localStorage.getItem('industryMode')); 
                const userData = await AuthService.loadAccountInfo();
                if (userData.roleName === 'owner') {
                    history.push(RoutePathConstant.RegisterOwnAndPharmacy, {
                        background: location,
                        ownerType: userData.roleOpted,
                        industryMode: userData?.healthcareProfession,
                        account:userData
                    })
                }
                else {
                    history.push(
                        `${RoutePathConstant.RegisterWorker}${userData.roleOpted}`,
                        {
                            background: location,
                            workerType: userData.roleOpted,
                            industryMode: userData?.healthcareProfession,
                            account:userData
                        }
                    )
                }
              }
            } catch (err) {
              setSubmitting(false);
              let message = err.response ? err.response.data.message : err.message;
              if(err.response?.data?.error.includes('Invalid/Expired')){
                message = `Verification Code is either Invalid or Expired. \n\n Please try again.`
              }
              setSubmitErrorMessage(message);
            } finally {
              isMountedRef.current && setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            handleBlur,
            isValid,
            handleSubmit,
            handleChange,
            touched,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit} className={classes.formContainer}>
              <img
                 src={location.state.userData.networks[0].brand.emailLogo}
                 alt="logo"
                className={classes.logo}
              />
              <Typography variant="h5" gutterBottom style={{textAlign:'center'}}>Verification</Typography>
              {location.state.method === 'phone' ?
                <Typography variant="body1" gutterBottom style={{textAlign:'center'}}>
                  We sent you a text message with a 6-digit verification code.
                </Typography> :
                <Typography variant="body1" gutterBottom style={{textAlign:'center'}}>
                  We sent you an email with a 6-digit verification code.
                </Typography>
              }
              <>
              {submitErrorMessage && (
                <div className={classes.submitError}>
                  {submitErrorMessage}
                </div>
              )}
              {submitSuccessMessage && (
                    <div
                    className={classes.successMessage}
                    >
                        {submitSuccessMessage}
                    </div>
                )}
              </>

              <div style={{ display: 'flex', justifyContent: 'center', margin: '0 auto', padding: Spacing.medium }}>
              <OtpInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  separator={<span> &nbsp; </span>}
                  inputStyle={{
                    width: isMobile ? '2rem' : '3rem',
                    height: isMobile ? '2rem' : '3rem',
                    fontSize: isMobile ? '1rem' : '1.5rem',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: Colors.themeDarkBlue,
                    borderRadius: '4px',
                    textAlign: 'center',
                    color: 'black',
                    backgroundColor: 'white',
                    outline: 'none',
                    boxShadow: 'none',
                    margin: isMobile ? '0 0.3rem' : '0 0.1rem',
                  }}
                  focusStyle={{
                    outline: 'none',
                    boxShadow: 'none',
                    borderColor: Colors.themeDarkBlue,
                  }}
                  containerStyle={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'nowrap', // Prevents wrapping
                    '@media (max-width: 600px)': {
                      flexWrap: 'wrap', // Allow wrapping on very small screens if necessary
                    },
                  }}
                  shouldAutoFocus = {true}
                  isInputNum = {true}
                />
              </div>

              <div   className={classes.InputContainer}
             style={{ marginBottom: Spacing.xlarge }}>
                <RBButton
                   style={{
                    width: "100%",
                    paddingLeft: Spacing.large,
                    paddingRight: Spacing.large,
                    paddingTop: Spacing.medium,
                    paddingBottom: Spacing.medium,
                    marginBottom: 30
                  }}
                  buttonBackgroundColor={location.state.userData.networks[0].brand.primaryColor}
                  text="Submit"
                  isLoading={isSubmitting}
                  disabled={(otp .length !== 6) || !isValid}
                  variant="contained"
                  type="submit"
                />
              </div>
              <div style={{marginBottom: 20}}>
                <Box className={classes.resendContainer}>
                  <Typography variant="body2">Didn't receive a code? {resending ? <LoadingIndicator /> : <Button onClick={handleResendCode} className={classes.resendText}>Resend</Button>}</Typography>
                  
                </Box>
              </div>
            </Form>
          )}
        </Formik>
      </RBCardView>
    </Grid>
    </Grid>
  );
};
