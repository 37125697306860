import { DropzoneArea, DropzoneAreaProps } from "material-ui-dropzone";
import { makeStyles, createStyles } from "@material-ui/core";
import { Spacing, FontSize } from "../theme/Theme";
import React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    smDropzone: {
      minHeight: 20,
    },
    text: {
      marginBottom: 0,
      marginTop: Spacing.medium,
      marginLeft: Spacing.medium,
      marginRight: Spacing.medium,
      fontSize: FontSize.large,
    },
    fileDropzone: {
      "& .MuiDropzonePreviewList-removeButton": {
        top: "5%",
        left: "80%",
      },

    },
  })
);

interface RBDropFileProps extends DropzoneAreaProps {}
export const RBDropFile: React.FC<RBDropFileProps> = ({ ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.fileDropzone}>
      <DropzoneArea
        filesLimit={1}
        previewGridProps={{ container: { spacing: 5 }, item: { xs: 12 } }}
        inputProps={{ style: { backgroundColor: "green" } }}
        classes={{
          root: classes.smDropzone,
          text: classes.text,
        }}
        {...props}
        onDelete={(deleteFileObj) => {
          console.log("onDelete", deleteFileObj);
        }}
      />
    </div>
  );
};
