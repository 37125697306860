import React, { useState,useContext, useEffect } from "react";
import { ShiftProps } from "../../interfacesProps/ShiftProps";
import { RBCardView } from "../../components/RBCardView";
import {Font, Spacing, Colors } from "../../theme/Theme";
import moment from "moment-timezone";
import { ShiftService } from "../../api/ShiftService";
import { Typography, IconButton, InputAdornment } from "@material-ui/core";
import { RBButton, RBButtonType } from "../../components/RBButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RBDateTimePicker } from "../../components/RBDateTimePicker";
import { useHistory } from "react-router-dom";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum } from "../../api/constants";
import { AuthService } from "../../api/AuthService";
import { RoutePathConstant } from "../../util/Common";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { RBTextField, RBTextFieldForForm, RBTextFieldForFormFieldType } from "../../components/RBTextField";
import { formatWorkerType } from "../../util/FormattingService";
import InputMask from "react-input-mask";

interface ConfirmShiftHourPageForWorkerProps {
  shiftData: ShiftProps;
}

function UnpaidBreakFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;
  return (
    <InputMask
      {...other}
      inputRef={inputRef}
      mask="999"
      alwaysShowMask={false}
      onChange={onChange}
      maskChar=" "
    />
  );
}

export const ConfirmShiftHourPageForWorker: React.FC<ConfirmShiftHourPageForWorkerProps> = ({
  ...props
}) => {
  const { shiftData } = props;
  const history = useHistory();
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);

  const [showUpdateDateTime, setShowUpdateDateTime] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [actualStartTime, setActualStartTime] = useState<any>(
    moment(shiftData.startTime).tz(shiftData.shiftTimeZone)
  );
  const [actualEndTime, setActualEndTime] = useState<any>(
    moment(shiftData.endTime).tz(shiftData.shiftTimeZone)
  );
  const pharmacyName = shiftData.pharmacy.name;
  const dateString = moment(shiftData.startTime)
    .tz(shiftData.shiftTimeZone)
    .format("ddd, MMM Do YYYY");
  const startTime = moment(shiftData.startTime)
    .tz(shiftData.shiftTimeZone)
    .format("h:mm a");
  const endTime = moment(shiftData.endTime)
    .tz(shiftData.shiftTimeZone)
    .format("h:mm a");
  const hours = ShiftService.getShiftLengthDisplayForOriginal(shiftData);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();

  const [alertSInfo,setAlertSInfo] =  useState<ObjectLiteral>({})
  const [errorAlertInfo,setErrorAlertInfo] =  useState<ObjectLiteral>({})
  const [showModal, setShow] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [account,setAccount] =  useState<ObjectLiteral>({})
  const [blockUserDialogVisible, setBlockUserDialogVisible] = useState(false);
  const [blockUserComment, setBlockUserComment] = useState('');
  const [unpaidBreakMinutes, setUnpaidBreakMinutes] = useState<any>(null);
  const [error, setError] = useState<any>("");
  const getDateTime = (date: any) => {
    let currentDate = moment(date).tz(shiftData.shiftTimeZone)
    let year = currentDate.format('YYYY')
    let month = currentDate.format('MM')
    let day = currentDate.format('DD')
    let hour = currentDate.format('HH')
    let minute = currentDate.format('mm')
    let second = currentDate.format('ss')
    let convertedDate = moment(year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second);
    return convertedDate;
  }
  const convertOffsetToString = (offset: any) => {
    let value = '';
    const absOffset = Math.abs(offset);
    const hr = absOffset / 60;
    const min = absOffset % 60;
    if (offset < 0) {
      value = value + '-';
    }
    else {
      value = value + '-';
    }
    if (hr < 10) {
      value = value + '0' + hr;
    }
    else {
      value = value + hr;
    }
    if (min < 10) {
      value = value + ':0' + min;
    }
    else {
      value = value + ':' + min;
    }
    return value;
  }
  useEffect(() => {
    (async () => {
      const userData = await AuthService.loadAccountInfo();
      setAccount(userData);
    })();  
  }, [])
  const onUnpaidBreakMinutes = (value: any) => {
    console.log("setUnpaidBreakMinutes", value)
    const sanitizedValue = value? value.replace(/\s+/g, ''): null;
    setUnpaidBreakMinutes(sanitizedValue)
    setError("")

    if (value !== '') {
      validateTime(value);
    }

  }
  const validateTime = (inputTime: any) => {

    // Calculate the time difference in milliseconds
    const timeDifferenceMinutes = actualEndTime.diff(actualStartTime, 'minutes');

    if (inputTime < 0 || inputTime >= timeDifferenceMinutes) {
      setError("The unpaid time cannot be equal or greater than the shift duration");
      return false;
    }

    setError("")
    return true;
  };
  const renderUpdateDateTime = () => {
    return (
      <>
        <Typography
          style={{
            textAlign: "center",
            fontSize: 25,
          }}
        >
          Update Shift Time
        </Typography>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            paddingLeft: "20%",
            paddingRight: "20%",
            paddingTop: Spacing.large,
          }}
        >
           <div style={{alignItems: "center", paddingTop:'3%' }}>
            <Typography style={{ display: "flex", flex: 1 }}>
              Start Time
            </Typography>
          </div>
          <div style={{ display: "flex", flex: 1 }}>
            <DatePicker
              selected={getDateTime(moment(actualStartTime).tz(shiftData.shiftTimeZone)).toDate()}
              onChange={(date) => {
                const offsetString1 = convertOffsetToString(moment(date).tz(shiftData.shiftTimeZone).utcOffset())
                setActualStartTime(
                  date
                    ? moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1)
                    : moment()
                );
              }}
              showTimeSelect
              minDate={moment().tz(shiftData.shiftTimeZone).toDate()}
              timeFormat="h:mm aa"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MM/dd/yyyy h:mm aa"
              customInput={
                <input
                  style={{
                    paddingTop: Spacing.large,
                    paddingBottom: Spacing.large,
                    paddingRight: "30%",
                    borderRadius: 5,
                    borderColor: "rgb(229 224 224)",
                    width: 500
                  }}
                  key={`dateTime-${shiftData.shiftTimeZone}`}
                />
              }
              withPortal
            />
          </div>
          <div style={{alignItems: "center", paddingTop:'3%' }}>
            <Typography style={{ display: "flex", flex: 1 }}>
              End Time
            </Typography>
          </div>
          <div style={{ display: "flex", flex: 1, paddingTop: '3%' }}>
            <DatePicker
              selected={getDateTime(moment(actualEndTime).tz(shiftData.shiftTimeZone)).toDate()}

              onChange={(date) => {
                const offsetString1 = convertOffsetToString(moment(date).tz(shiftData.shiftTimeZone).utcOffset())
                setActualEndTime(
                  date
                    ? moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1)
                    : moment()
                );
              }}
              showTimeSelect
              minDate={
                moment.max(
                  moment().tz(shiftData.shiftTimeZone),
                  moment((actualStartTime))
                ).toDate()
              }
              maxDate={moment(getDateTime(actualStartTime))
                .tz(shiftData.shiftTimeZone)
                .add(24, "hours")
                .toDate()}
              timeFormat="h:mm aa"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MM/dd/yyyy h:mm aa"
              customInput={
                <input
                  style={{
                    paddingTop: Spacing.large,
                    paddingBottom: Spacing.large,
                    paddingRight: "45%",
                    borderRadius: 5,
                    borderColor: "rgb(229 224 224)",
                    width: 500
                  }}
                  key={`dateTime-${shiftData.shiftTimeZone}`}
                />
              }
              withPortal
            />
          </div>
          <div style={{alignItems: "center", paddingTop:'3%' }}>
            <Typography style={{ display: "flex", flex: 1 }}>
            Unpaid Breaks (Min)
            </Typography>
          </div>
          <div style={{ display: "flex", flex: 1, paddingTop: '3%' }}>
            <RBTextField
                margin={"dense"}
                value={unpaidBreakMinutes}
                inputComponent={UnpaidBreakFormatCustom as any}
                onChange={(e) => onUnpaidBreakMinutes(e.target.value)}
                error={error.length > 0}
                errorText={error}
              />

          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: Spacing.large,
            marginTop: Spacing.large,
            marginLeft:"30%",
            marginRight:"30%"
          }}
        >
          <RBButton
            buttonBackgroundColor={secondaryColor}
            onClick={() => {
              setShowUpdateDateTime(false)
              setUnpaidBreakMinutes(null)
            }}
            text="Cancel"
            style={{ width: 150 }}
          />
          <RBButton
            buttonBackgroundColor={secondaryColor}
            isLoading={isButtonLoading}
            disabled = {error}
            text="Submit hours"
            onClick={async () => await onSubmitHours()}
            style={{ width: 150 }}
          />
        </div>
      </>
    );
  };
  const clearEphemeral = () =>  setIsButtonLoading(false);;
  const onClose = () => {
    setBlockUserDialogVisible(true);
  }
  const  onBlockUser = async () => {
    try {
      await AuthService.blockUser({
        comment: blockUserComment.length > 0 ? blockUserComment : null,
        shiftId: shiftData.id,
      });
    } catch (error) {
      console.log('onBlockUser ', error);
    }

    setBlockUserDialogVisible(false);
    history.goBack();
  };
  const renderCreateShiftContent = () => {

    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
           <div>
           How did your shift go with {shiftData.pharmacy.name}? Would you consider
            working at this {shiftData.pharmacy.companyType !== null ? shiftData.pharmacy.companyType: 'Pharmacy'} again?
           </div>
           <br></br>
           <div>
            <RBTextField
              style={{
                display: "flex",
                flex: 1,
                paddingTop: Spacing.small,
                paddingBottom: Spacing.small,
              }}
              placeholder={"*Please leave a comment if you decide no."}
              value={blockUserComment}
              onChange={(event) => setBlockUserComment(event.target.value)}
              multiline
              rows={3}
              rowsMax={8}
            />
           </div>
           <br></br>
           <div style={{color:'red'}}>
           *If you select "No", you will never match with that locations
            shifts/jobs again.
           </div>
           <div
           style={{
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}>
                <RBButton
                  buttonBackgroundColor={primaryColor}
                  isLoading={isButtonLoading}
                  disabled={blockUserComment.length === 0}
                  text="No"
                  onClick={() => onBlockUser()}
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: Spacing.medium,
                    margin: Spacing.large,
                  }}
                />
                <RBButton
                  buttonBackgroundColor={primaryColor}
                  isLoading={isButtonLoading}
                  text="Yes"
                  onClick={() => {
                    setBlockUserDialogVisible(false)
                    history.goBack()
                    }
                  }
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: Spacing.medium,
                    margin: Spacing.large,
                  }}
                />
           </div>
          </RBCardView>
       
      </div>
    );
  };

  const renderBlockUserDialog = () => {
    
    return (

      <RBModal
          open={blockUserDialogVisible}
          onClose={onClose}
          modalTitle={"Shift Feedback"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              renderCreateShiftContent()
          }
        />

    );
  };

  
  const onSubmitHours = async () => {
    try {
      if (
        industryMode === IndustryModeEnum.Pharmaceutical &&
        !account.pharmacist.flags.isSinSetup
      ) {
        setAlertSInfo({
          title: 'SIN/Business Info Required',
          message: 'You must set up your SIN/Business Registration before you can complete a shift. Would you like to do this now?',
          buttons: [
            <RBButton
              key={RBButtonType.cancel}
              buttonType={RBButtonType.cancel}
              onClick={() => {
                  setShow(false);
                  history.push(RoutePathConstant.SINBusinessSetting)
              }}
              text="Yes"
            />,
            <RBButton
              key={RBButtonType.cancel}
              buttonType={RBButtonType.cancel}
              onClick={() => {
                  setShow(false);
                  history.goBack();
              }}
              text="No"
            />
          ],
        })
        setShow(true);
        return;
      }
      if (isButtonLoading) {
        return;
      }

      setIsButtonLoading(true);
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      console.log(
        "onSubmitHours ",

        {
          shiftId: shiftData.id,
          actualStartTime: actualStartTime.toISOString(),
          actualEndTime: actualEndTime.toISOString(),
        }
      );
      let payload = {};
    if(unpaidBreakMinutes !== null && unpaidBreakMinutes !== "0" && unpaidBreakMinutes !== "00" && unpaidBreakMinutes !== "000")
    {
      // const [hours, minutes] = unpaidBreakMinutes.split(':');
      // let totalUnpaidBreakMin = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
      payload = {
        shiftId: shiftData.id,
        actualStartTime: actualStartTime.toISOString(),
        actualEndTime: actualEndTime.toISOString(),
        unpaidBreakMinutes:unpaidBreakMinutes
      }
    }
    else{
      payload = {
        shiftId: shiftData.id,
        actualStartTime: actualStartTime.toISOString(),
        actualEndTime: actualEndTime.toISOString(),
        unpaidBreakMinutes:null

      }
    }
      ShiftService.completeShiftByWorker(payload)
      .then(() => {
        if (industryMode === IndustryModeEnum.Dentistry) {
          setAlertSInfo({
            title: 'Shift Hours Submitted',
            message: 'Your hours have been submitted. Once the location confirms your payment will be on its way!',
            buttons: [
              <RBButton
                key={RBButtonType.cancel}
                buttonType={RBButtonType.cancel}
                onClick={() => {
                  setShow(false);
                  setBlockUserDialogVisible(true);
                }}
                text="OK"
                style={{color:"white"}}
                buttonBackgroundColor={primaryColor}
              />
            ],
          })
          setShow(true);

        } else {

          setAlertSInfo({
            title: 'Shift Hours Submitted',
            message: 'Your hours have been submitted. Once the location confirms your payment will be on its way!',
            buttons: [
              <RBButton
                key={RBButtonType.cancel}
                buttonType={RBButtonType.cancel}
                onClick={() => {
                  setShow(false);
                  // history.push(RoutePathConstant.Shifts);
                  setBlockUserDialogVisible(true);
                }}
                buttonBackgroundColor={primaryColor}
                style={{color:"white"}}
                text="OK"
              />
            ],
          })
          setShow(true);
          
        }
      })
      .catch((err) => {
        const errorMessage = err.response ? err.response.data.message[0]?.constraints?.isRelative : err.message;
        setSubmitErrorMessage(
          err.response ? err.response.data.message : err.message
        );
        if(errorMessage.includes("must come"))
        {
          setShowErrorModal(true);
          // setErrorMessage("Your hours could not be submitted at this time. Please try again later.")
          setErrorAlertInfo({
            title: 'An Error Occured',
            message: 'Your hours could not be submitted at this time. Please try again later.',
            buttons: [
              <RBButton
                key={RBButtonType.cancel}
                buttonBackgroundColor= {primaryColor}
                style={{color: "white"}}
                buttonType={RBButtonType.cancel}
                onClick={() => {
                    setShowErrorModal(false);
                    history.push(RoutePathConstant.DashBoard)
                }}
                text="Go Home"
              />,
            ],
          })
        }
      })
      .then(clearEphemeral);
    } catch (err) {
      const errorMessage = err.response ? err.response.data.message : err.message;
      setSubmitErrorMessage(errorMessage);
    } finally {
      setIsButtonLoading(false);
    }
  };
  return (
    <>

      <RBAlert
        show={showModal}
        alertTitle={alertSInfo.title}
        alertMessage={alertSInfo.message}
        buttons={alertSInfo.buttons}
      />
      <RBAlert
        show={showErrorModal}
        alertTitle= {errorAlertInfo.title}
        alertMessage={errorAlertInfo.message}
        buttons={errorAlertInfo.buttons}
      />
       {renderBlockUserDialog()}

      {/* <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={errorMessage}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      /> */}
      <RBCardView
        style={{
          margin: Spacing.large,
          padding: Spacing.large,
        }}
      >
        <div style={{ fontSize: Font.large }}>
          <div>
            Shift at <strong>{pharmacyName} </strong> is finished!
          </div>
          <div>
          Your shift was scheduled for {" "}
              {dateString} from {startTime} - {endTime} {" "}
            for a total of <strong>{hours}</strong>.
          </div>
          <div>
            <strong>Unpaid Breaks: </strong>Please enter the cumulative unpaid break time in minutes.
          </div>
        </div>
      </RBCardView>

      <RBCardView
        marginHorizontal={Spacing.large}
        style={{
          marginBottom: Spacing.large,
          padding: Spacing.large,
        }}
      >
        <>
          {showUpdateDateTime ? (
            renderUpdateDateTime()
          ) : (
            <>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: Font.larger,
                }}
              >
                Submit scheduled hours?
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: Spacing.large,
                  marginTop: Spacing.large,
                }}
              >
                <RBButton
                  buttonBackgroundColor={primaryColor}
                  onClick={() => setShowUpdateDateTime(true)}
                  text="No, Update Hours"
                  style={{ width: 200 }}
                />
                <RBButton
                  isLoading={isButtonLoading}
                  buttonBackgroundColor={primaryColor}
                  text="Yes, Submit Hours"
                  onClick={async () => await onSubmitHours()}
                  style={{ width: 200 }}
                />
              </div>
            </>
          )}
        </>
      </RBCardView>
    </>
  );
};
