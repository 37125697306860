import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  RBTextFieldForForm,
  RBSelectForForm,
  RBTextFieldForFormFieldType,
} from "../../../components/RBTextField";
import { Spacing, Colors } from "../../../theme/Theme";
import {
  PharmacyOwnerPosition,
  CanadaProvinces,
  Claims,
} from "../../../util/Common";
import { Schema } from "../YupSchema";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import { RBDropFile } from "../../../components/RBDropFile";
import { RBEmptyBlock } from "../../../components/RBEmptyBlock";
import { RBButton } from "../../../components/RBButton";
import { RBModal } from "../../../components/RBModal";
import { RBCardView } from "../../../components/RBCardView";
import SignaturePad  from "../../../components/SignaturePad"

export interface DentalAccountOwnerInformationValueTypes {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  signature?: File;
}
interface DentalAccountOwnerInformationProps {
  innerRef?: any;
  onFormChange: any;

  initialValues: DentalAccountOwnerInformationValueTypes;
  account?: any;
}

export const DentalAccountOwnerInformation: React.FC<DentalAccountOwnerInformationProps> = ({
  ...props
}) => {
  const [savedSignatureDataURL, setSavedSignatureDataURL] = useState('' || localStorage.getItem('workerSign'));
  const [showSignModal, setShowSignModal] = useState(false);
  const onSignClose= () => {
    setShowSignModal(false)
   };
   const initialValues = {
    ...props.initialValues,
    firstName: props.account?.owner?.firstName || props.initialValues.firstName,
    lastName: props.account?.owner?.lastName || props.initialValues.lastName,
    phoneNumber: props.account?.owner?.phone || props.initialValues.firstName,
    email: props.account?.email || props.initialValues.lastName,
  };

  return (
    <Formik
      initialValues={initialValues}
      innerRef={props.innerRef}
      validationSchema={Schema.DentalAccountOwnerInformation}
      validateOnBlur={true}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={Schema.DentalAccountOwnerInformation.isValidSync(
        props.initialValues
      )}
      onSubmit={() => {}}
    >
      {({ values, handleBlur, handleChange, handleSubmit, setFieldValue }) => 
      {
          const rendersignatureContent = () => {
            console.log('hiii',showSignModal)
            return (
              <div
                style={{
                  backgroundColor: Colors.defaultGrey,
                  display: "flex",
                  flex: 1,
        
                }}
              >
                
                  <RBCardView
                    style={{
                      flex: 1,
                      marginLeft: Spacing.small,
                      marginRight: Spacing.small,
                      marginBottom: Spacing.smallest,
                      paddingLeft: Spacing.large,
                      paddingTop: Spacing.large,
                      paddingRight: Spacing.large,
                      paddingBottom: Spacing.large,
                    }}
                  >
                    <SignaturePad
                      isVisible={true}
                      onLoad = {(file,dataURL) =>{
                        setSavedSignatureDataURL(dataURL);
                        setFieldValue("signature", file, true);
                      }}
                      onSave={(file,dataURL) =>{
                        setShowSignModal(false);
                        setSavedSignatureDataURL(dataURL);
                        setFieldValue("signature", file, true);
                      }}
                      onCancel={() =>{}}
                      onClear={() => {
                        localStorage.removeItem("workerSign")
                      }}
                      userData = {values}
                      page="ownerRegistration"
                    />
              </RBCardView>
               
              </div>
            );
          };
          const isMobile = window.innerWidth <= 600;
        return(
          <>
            <RBModal
            modalWidth={isMobile ? 400 : 600}
            open={showSignModal}
            onClose={onSignClose}
            modalTitle={"Signature"
            }
            header={
              <RBEmptyBlock
                style={{
                  width: "100%",
                  height: Spacing.large,
                  backgroundColor: Colors.defaultGrey,
                  flexShrink: 0,
                }}
              />
            }
           // actions={this.renderCreateShiftActions()}
            children={
                rendersignatureContent()
            }
          />
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <Typography variant="body1" style={{ marginBottom: Spacing.large }}>
            Please provide us with the primary contact information for your
            account.
          </Typography>
          <RBTextFieldForForm
            label="Email"
            placeholder="Email"
            name="email"
            value={values.email}
            //   onChange={handleChange}
            ///  onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
            disabled={true}
          />

          <RBTextFieldForForm
            FieldType={RBTextFieldForFormFieldType.password}
            label="Password"
            name="password"
            value={values.password}
            // onChange={handleChange}
            // onBlur={handleBlur}
            size={"small"}
          />
          <div
            style={{
              marginBottom: Spacing.large,
            }}
          >
            <Typography variant="caption">{Claims.password}</Typography>
          </div>

          <RBTextFieldForForm
            FieldType={RBTextFieldForFormFieldType.password}
            label="Confirm Password"
            name="confirmPassword"
            value={values.confirmPassword}
            // onChange={handleChange}
            // onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="First Name"
            name="firstName"
            value={values.firstName}
            // onChange={handleChange}
            // onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            label="Last Name"
            name="lastName"
            value={values.lastName}
            // onChange={handleChange}
            // onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            label="Phone Number"
            name="phoneNumber"
            FieldType={RBTextFieldForFormFieldType.phone}
            value={values.phoneNumber}
            // onChange={handleChange}
            // onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <div style={{ flex: 1, alignItems: "flex-start" }}>
            <div
              style={{ ...ColumnStyle, alignItems: "flex-start" }}
            >
              Signature (Optional)
              <RBEmptyBlock />
              {savedSignatureDataURL ?
                <RBButton
                  text="Update"
                  onClick={() => { setShowSignModal(true) }}
                  buttonBackgroundColor={props.account.networks[0].brand.primaryColor}
                /> :
                <RBButton
                  text="Add"
                  onClick={() => { setShowSignModal(true) }}
                  buttonBackgroundColor={props.account.networks[0].brand.primaryColor}
                />}
            </div>


          </div>
          {savedSignatureDataURL && (
            <div style={{ maxWidth: '100%', width: '100%', overflow: 'hidden', border: '1px solid black' }}>
              <img src={savedSignatureDataURL} alt="Saved Signature" style={{ width: '100%', height: 'auto' }} />
            </div>
          )}
         
        </Form>
       </>
       )}}
    </Formik>
  );
};
