import React from "react";
import { useState } from "react";
import { renderTextWithTitleAndChildComponent } from "./TextWithHeader";
import { ColumnStyle } from "../theme/ComponentTheme";
import { RBSelect } from "./RBTextField";
import { RBEmptyBlock } from "./RBEmptyBlock";
import { FormControl, FormLabel } from "@material-ui/core";
import { FormattedWorkerTypeEnum, workerTypeEnum } from "../util/Enumeration";

const generateListOfItemsForPicker = (endAt: number) => {
  let result = [];
  for (let index = 0; index <= endAt; index++) {
    result.push(index);
  }
  return result;
};
interface RBMonthYearPickerProps {
  numberOfMonths: number;
  onValueChange: any;
  workerType?: string;
}
export const RBMonthYearPicker: React.FC<RBMonthYearPickerProps> = ({
  numberOfMonths,
  onValueChange,
  workerType
}) => {
  const [experienceYear, setExperienceYear] = useState(
    Math.floor(numberOfMonths / 12)
  );
  const [experienceMonth, setExperienceMonth] = useState(numberOfMonths % 12);

  const experienceYearText = experienceYear > 1 ? " Years" : " Year";
  const experienceMonthText = experienceMonth > 1 ? " Months" : " Month";

  const onExperienceYearChange = (year: number) => {
    setExperienceYear(year);
    onValueChange(year * 12 + experienceMonth);
  };
  const onExperienceMonthChange = (month: number) => {
    setExperienceMonth(month);
    onValueChange(experienceYear * 12 + month);
  };
  let experienceText = "Experience as pharmacy assistant";
  if (workerType && workerType?.includes('entist')) {
    switch (workerType) {
      case workerTypeEnum.dentistAdministrator:
        experienceText = `Experience as ${FormattedWorkerTypeEnum.dentistAdministrator}`;
        break;
      case workerTypeEnum.dentistChairSideAssistant:
        experienceText = `Experience as ${FormattedWorkerTypeEnum.dentistChairSideAssistant}`;
        break;
      case workerTypeEnum.dentistSterilizationAssistant:
        experienceText = `Experience as ${FormattedWorkerTypeEnum.dentistSterilizationAssistant}`;
        break;
    }
  }else{
    switch (workerType) {
      case workerTypeEnum.pharmacistStudent:
        experienceText = `Experience as ${FormattedWorkerTypeEnum.pharmacistStudent}`;
        break;
      case workerTypeEnum.technicianStudent:
        experienceText = `Experience as ${FormattedWorkerTypeEnum.technicianStudent}`;
        break;
      case workerTypeEnum.assistant:
        experienceText = `Experience as ${FormattedWorkerTypeEnum.assistant}`;
        break;
    }
  }
  return (
    <>
      {renderTextWithTitleAndChildComponent(
        experienceText,
        <div style={ColumnStyle}>
          <RBSelect
            value={experienceYear}
            onChange={(e, value) =>
              onExperienceYearChange(e.target.value as number)
            }
            renderValue={(value) => {
              return (
                <div>
                  {value as number}
                  {experienceYearText}
                </div>
              );
            }}
            mappingValues={generateListOfItemsForPicker(100)}
          />

          <RBEmptyBlock />
          <RBSelect
            value={experienceMonth}
            renderValue={(value) => {
              return (
                <div>
                  {value as number}
                  {experienceMonthText}
                </div>
              );
            }}
            onChange={(e, value) =>
              onExperienceMonthChange(e.target.value as number)
            }
            mappingValues={generateListOfItemsForPicker(12)}
          />
        </div>
      )}
    </>
  );
};
