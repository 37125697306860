import React, { useContext } from "react";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { Spacing, Colors, FontSize } from "../../../theme/Theme";
import { RenderTextWithTitle } from "../../../components/TextWithHeader";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import formatService from "../../../util/FormattingService";
import { RBMap } from "../../../components/RBMap";
import { RBContext } from "../../../components/RBContext";

interface PDentalPracticeInfoProps {
  pharmacy:ObjectLiteral;
  jobData: ObjectLiteral;
  owner:any;
  showPartialInfo?:boolean;
  ownerEmail:any;
  account:ObjectLiteral;
  page:string;
  applied?:Boolean;

}

const NoDentalPracticeInfo = () => {
  return (
    <div>
      <div className={"section-header-text"}>Dental Practice Information</div>
      <div>
        <div>
        You&apos;ll be able to view the dental practice information after you
        are {}
        confirmed for the shift.
        </div>
      </div>
      <div
        style={{
          height: 1,
          backgroundColor: Colors.defaultGrey,
          marginTop: Spacing.medium,
          marginBottom: Spacing.medium,
        }}
      />
    </div>
  );
};

export const DentalPracticeInfo: React.FC<PDentalPracticeInfoProps> = ({
  ...props
}) => {
  const { jobData,owner,showPartialInfo,ownerEmail,account,page,applied } = props;
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);

  // const showPartialInfo =
  //   jobData.flags.isCompletionAcknowledged || jobData.flags.isCancelled;
  const pharmacy = jobData.pharmacy;
  const workerNetworkId = account.networks[0].id;
  //const owner = jobData.creator ? jobData.creator.owner : false;
  if (!jobData.pharmacy) {
    return <>{NoDentalPracticeInfo()}</>;
  }
  if (showPartialInfo) {
    return (
      <div>
        <div className={"section-header-text"}>Dental Practice Information</div>
        <div style={{ fontWeight: "bold", marginBottom: 4 }}>
          {pharmacy.name}
        </div>
        <div
          style={{
            height: 1,
            backgroundColor: Colors.defaultGrey,
            marginTop: Spacing.medium,
            marginBottom: Spacing.medium,
          }}
        />
      </div>
    );
  }
  const { address } = pharmacy;
  const coordinate = {
    latitude: address.coordinate.coordinates[1],
    longitude: address.coordinate.coordinates[0],
  };
  const addressFormat = address.apartmentSuiteNumber
    ? address.apartmentSuiteNumber +
      " - " +
      address.streetAddress +
      "\n" +
      address.city +
      " " +
      address.province +
      "\n" +
      address.postalCode
    : address.streetAddress +
      "\n" +
      address.city +
      " " +
      address.province +
      "\n" +
      address.postalCode;
  return (
    <div>
      {account.roleName === 'owner' &&<div
        className="section-header-text"
        style={{
          paddingBottom: Spacing.small,
          marginBottom: Spacing.smallest,
        }}
      >
        Dental Practice Information
      </div>}
      <div style={ColumnStyle}>
        {RenderTextWithTitle(pharmacy.companyType, pharmacy.name)}
        {page && page == "applyShift" && 
        (account.networks[0].isTrustNetwork === true
          && account.pharmacist.approval?.rbVerified === true && 
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowWrap: "anywhere",
              alignItems: "center",
              alignContent: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflowWrap: "anywhere",
                fontSize: FontSize.default,

              }}
            >
              {pharmacy?.networkId === workerNetworkId ? 'In-Network' : 'Out-of-Network'}
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontWeight: "bold",
                fontSize: FontSize.large,
              }}
            >
              <img
                src={
                  pharmacy?.networkId === workerNetworkId ?
                    require('../../../img/50x50-01.png')
                      : require('../../../img/100x100-02-01.png')
                }
                style={{
                  height: 50,

                }}
              />
            </div>
          </div>
        )
        }
      </div>
      <div style={ColumnStyle}>
        {RenderTextWithTitle("Practice Address", addressFormat)}
        <RBMap
          style={{
            display: "flex",
            flex: 1,
            height: 150,
            marginTop: Spacing.medium,
          }}
          lat={coordinate.latitude}
          lng={coordinate.longitude}
        />
      </div>
      
      {owner && (<>
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Primary Contact",
            owner.firstName + " " + owner.lastName
          )}
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowWrap: "anywhere",
              alignItems: "flex-start",
              alignContent: "flex-start",
              justifyContent: "flex-start",
              width: 100
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflowWrap: "anywhere",
                color: Colors.darkGrey,
                fontSize: FontSize.default,
              }}
            >
              Primary Contact Phone
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontWeight: "bold",
                fontSize: FontSize.large,
                color: primaryColor
              }}
            >
              {formatService.formatPhoneNumber(owner.phone)}
            </div>
          </div>
        </div>
      
      </>
      )}
      {
        page === 'applyJob' || page === 'jobDetails' && (<>
           <div style={ColumnStyle}>
          {/* {RenderTextWithTitle(
            "Company Website",
            pharmacy.dentalOfficeCompanyInfo?.website ? pharmacy.dentalOfficeCompanyInfo.website : 'N/A'
          )} */}
            <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowWrap: "anywhere",
              alignItems: "flex-start",
              alignContent: "flex-start",
              justifyContent: "flex-start",
              width: 100
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflowWrap: "anywhere",
                color: Colors.darkGrey,
                fontSize: FontSize.default,
              }}
            >
              Company Website
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontWeight: "bold",
                fontSize: FontSize.large,
                color: pharmacy.dentalOfficeCompanyInfo?.website ? primaryColor : ''
              }}
            >
              {pharmacy.dentalOfficeCompanyInfo?.website ? pharmacy.dentalOfficeCompanyInfo.website : 'N/A'}
            </div>
          </div>
          </div>
          <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Company Overview",
            pharmacy.dentalOfficeCompanyInfo?.overview ? pharmacy.dentalOfficeCompanyInfo.overview: 'N/A'
          )}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Company Services",
            pharmacy.dentalOfficeCompanyInfo?.services ? pharmacy.dentalOfficeCompanyInfo.services : 'N/A'
          )}
          </div>
          <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Company Benefits",
            pharmacy.dentalOfficeCompanyInfo?.benefits ? pharmacy.dentalOfficeCompanyInfo.benefits : 'N/A'
          )}
        </div>
        </>)
      }
      <div
        style={{
          height: 1,
          backgroundColor: Colors.defaultGrey,
          marginTop: Spacing.medium,
          marginBottom: Spacing.medium,
        }}
      />
    </div>
  );
};
