import React, { useRef } from "react";
import {
  RBTextFieldForForm,
  RBSelectForForm,
  RBTextFieldForFormFieldType,
} from "../../../components/RBTextField";
import { Form, Formik } from "formik";
import { Schema } from "../YupSchema";
import { Colors, Spacing } from "../../../theme/Theme";
import {
  RBPlaceSearchForForm2,
  RBPlaceSearchForForm,
} from "../../../components/RBPlaceSearch";
import { CanadaProvinces, EmptyString } from "../../../util/Common";
import { Slider, Typography } from "@material-ui/core";
import { renderTextWithTitleAndChildComponent } from "../../../components/TextWithHeader";

export interface UserLocationInformationValueTypes {
  street: string;
  city: string;
  postalCode: string;
  province: string;
  apartmentSuiteNumber: string | null;
  shiftDistance: number;
}

interface UserLocationInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: UserLocationInformationValueTypes;
  account?:any;
}
export const UserLocationInformation: React.FC<UserLocationInformationProps> = ({
  ...props
}) => {
  console.log('initial values ', props.initialValues)
  return (
    // <RBPlaceSearchForForm2
    //   innerRef={props.innerRef}
    //   initialValues={props.initialValues}
    //   isProvinceEditable={true}
    //   onFormChange={props.onFormChange}
    // />
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={Schema.UserLocation}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={Schema.UserLocation.isValidSync(props.initialValues)}
      onSubmit={() => {
        console.log("sdasdas");
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <div
            style={{
              marginBottom: Spacing.large,
            }}
          >
            <RBPlaceSearchForForm
              name="street"
              label={"Street Address"}
              value={values.street}
              setFieldValue={(addressObject: any) => {
                setFieldValue("street", addressObject.street, true);
                setFieldValue("city", addressObject.city, true);
                setFieldValue("postalCode", addressObject.postalCode, true);
                setFieldValue("province", addressObject.province, true);
              }}
              onBlur={handleBlur}
            />
          </div>

          <RBTextFieldForForm
            label="Apartment/Suite #"
            placeholder="Optional"
            name="apartmentSuiteNumber"
            value={values.apartmentSuiteNumber ?? EmptyString}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="City"
            name="city"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Postal Code"
            name="postalCode"
            FieldType={RBTextFieldForFormFieldType.postalCode}
            value={values.postalCode}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label="Province"
            name="province"
            value={values.province}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={"Province"}
            mappingValues={CanadaProvinces.map(
              (province: any) => province.abbr
            )}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          {renderTextWithTitleAndChildComponent(
            "Shift Distance",
            <>
              <p>This number represents the maximum distance you will travel for
                  shifts.</p>
              <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                <Slider
                  style={{
                    marginRight: Spacing.large,
                    display: "flex",
                    flex: 1,
                    color: localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue
                  }}
                  value={
                    typeof values.shiftDistance === "number"
                      ? values.shiftDistance / 10
                      : 0
                  }
                  onChange={(e, newValue) =>
                    setFieldValue(
                      "shiftDistance",
                      (newValue as number) * 10,
                      true
                    )
                  }
                  step={2.5}
                  min={2}
                />

                <Typography variant={"h6"}>
                  {values.shiftDistance + " km"}
                </Typography>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
