import { RBCardView } from "../../components/RBCardView";
import React, { useState, useEffect ,useContext} from "react";
import { StarRatings, EditableStarRatings } from "../../components/StarRatings";
import { OpenInNewSharp } from "@material-ui/icons";
import { Spacing, Colors } from "../../theme/Theme";
import { Typography } from "@material-ui/core";
import { EmptyString, RoutePathConstant } from "../../util/Common";
import { RBSwitch } from "../../components/RBSwitch";
import { RBButton } from "../../components/RBButton";
import { ShiftService } from "../../api/ShiftService";
import { useRouteMatch, useHistory } from "react-router-dom";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { RBContext } from "../../components/RBContext";

const QUESTIONS = [
  "Did the candidate work well with other staff members?",
  "Did the candidate have good customer service skills?",
  "How was the candidate in completing their tasks?",
  "Would you considering hiring this candidate again?",
];

export const ShiftSurveyPage = () => {
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [isPunctual, setIsPunctual] = useState(true);
  const [answers, setAnswers] = useState([0, 0, 0, 0]);
  const [starTexts, setStarTexts] = useState([
    EmptyString,
    EmptyString,
    EmptyString,
    EmptyString,
    EmptyString,
  ]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const match = useRouteMatch<{ shiftId: string }>();
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  useEffect(() => {
    let tempStarTexts: string[] = [];
    starTexts.forEach((starText) => tempStarTexts.push(starText));

    console.log("useEffect tempStarTexts ", tempStarTexts);
    for (let index = 0; index < answers.length; index++) {
      switch (answers[index]) {
        case 0:
          tempStarTexts[index] = EmptyString;
          break;
        case 1:
          tempStarTexts[index] = "Poor";
          break;
        case 2:
          tempStarTexts[index] = "Below Expectations";
          break;
        case 3:
          tempStarTexts[index] = "Good";
          break;
        case 4:
          tempStarTexts[index] = "Exceeds Expectations";
          break;
        case 5:
          tempStarTexts[index] = "Exceptional";
          break;
      }
    }
    for (let index = 0; index < answers.length; index++) {
      if (answers[index] === 0) {
        // make the submit button invalid
        setIsFormValid(false);
        break;
      }
      setIsFormValid(true);
    }

    setStarTexts(tempStarTexts);
  }, [JSON.stringify(answers)]);

  const submitShiftSurvey = async () => {
    try {
      setIsSubmitting(true);

      const payload = {
        q1: isPunctual,
        q2: answers[0],
        q3: answers[1],
        q4: answers[2],
        q5: answers[3],
      };
      await ShiftService.updateShiftRating(match.params.shiftId, payload);
      history.replace(RoutePathConstant.Shifts);
    } catch (err) {
      setSubmitErrorMessage(err.response ? err.response.data.message : err.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <RBCardView style={{ margin: Spacing.large, padding: Spacing.large }}>
        {submitErrorMessage && (
          <div style={{ color: "red", marginBottom: Spacing.large }}>
            {JSON.stringify(submitErrorMessage, null, 2)}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: Spacing.large,
          }}
        >
          <Typography
            variant={"h6"}
            style={{
              color: Colors.themeDarkBlue,
              marginRight: Spacing.large,
            }}
          >
            Was the candidate punctual?
          </Typography>
          <RBSwitch
            checked={isPunctual}
            onChange={(event, checked) => setIsPunctual(checked)}
            label={isPunctual ? "Yes" : "No"}
            formControlStyle={{ width: 50 }}
          />
        </div>
        <div
          style={{
            height: 1,
            backgroundColor: Colors.defaultGrey,
            marginBottom: Spacing.large,
          }}
        />

        {QUESTIONS.map((question: any, index: number) => {
          return (
            <div key={index}>
              <div style={{ textAlign: "center", marginBottom: Spacing.large }}>
                <Typography
                  variant={"h6"}
                  style={{
                    color: Colors.themeDarkBlue,
                    marginBottom: Spacing.small,
                  }}
                >
                  {question}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ color: '#ffb400' }}>
                    {starTexts[index]}
                  </Typography>
                  <EditableStarRatings
                    name={index.toString()}
                    // defaultRating={answers[index]}
                    onValueChange={(value: number) => {
                      let newAnswer = [];
                      answers.forEach((answer) => newAnswer.push(answer));
                      newAnswer[index] = value;
                      setAnswers(newAnswer);
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  height: 1,
                  backgroundColor: Colors.defaultGrey,
                  marginBottom: Spacing.large,
                }}
              />
            </div>
          );
        })}
      </RBCardView>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: Spacing.large,
          marginTop: 0,
        }}
      >
        <RBButton
          isLoading={isSubmitting}
          onClick={submitShiftSurvey}
          disabled={!isFormValid}
          text="Submit"
          style={{ flex: 1, padding: Spacing.large }}
          buttonBackgroundColor={primaryColor}
        />
      </div>
    </>
  );
};
