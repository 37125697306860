import React, { useEffect, useState } from "react";
import { PageService } from "../api/PageService";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { RBCardView } from "../components/RBCardView";
import { Spacing, AppContainerStyle, AppContainerWidth } from "../theme/Theme";
import { RouteComponentProps, withRouter } from "react-router";
import { HelperPageTitleEnum } from "../util/Enumeration";
import { EmptyString } from "../util/Common";

interface HelperPageWithoutRouterProps
  extends RouteComponentProps<{ pageTitle: HelperPageTitleEnum }> {}

export const HelperPageWithoutRouter: React.FC<HelperPageWithoutRouterProps> = ({
  match,
}) => {
  const [content, setContent] = useState<string>();
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsPageLoading(true);

      let pageName = EmptyString;
      switch (match.params.pageTitle) {
        case HelperPageTitleEnum.termOfService:
          pageName = "terms-of-use";
          break;
        case HelperPageTitleEnum.privacyPolicy:
          pageName = "privacy-policy";
          break;
        case HelperPageTitleEnum.aboutReliefBuddy:
          pageName = "about-us";
          break;
        case HelperPageTitleEnum.faq:
          pageName = "help";
          break;
      }
      // console.log(
      //   "HelperPageWithoutRouter page ",
      //   match.params.pageTitle,
      //   pageName
      // );
      const response = await PageService.getContentByName(pageName);
      //console.log("HelperPageWithoutRouter ", response);
      setContent(response.content);
      setIsPageLoading(false);
    };
    fetchData();
  }, [match.params.pageTitle]);

  const createMarkUp = () => {
    return { __html: content ? content : "This is empty " };
  };
  return (
    <div
      style={{
        width: AppContainerWidth,
        minHeight: window.innerHeight,
      }}
    >
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <RBCardView
            style={{
              margin: Spacing.large,
              padding: Spacing.large,
            }}
          >
            <div dangerouslySetInnerHTML={createMarkUp()}></div>
          </RBCardView>
        </>
      )}
    </div>
  );
};
export const HelperPage = withRouter(HelperPageWithoutRouter);
