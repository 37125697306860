import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Typography } from "@material-ui/core";
import {
  RBTextFieldForForm,
  RBTextFieldForFormFieldType,
} from "../../../components/RBTextField";
import { Colors, Spacing } from "../../../theme/Theme";
import { Claims } from "../../../util/Common";
import { Schema } from "../YupSchema";
import { AlertInfoProps } from "../../../interfacesProps/AlertInfoProps";
import { RBButton } from "../../../components/RBButton";
import { RBAlert } from "../../../components/RBAlert";

export interface UserAccountInformationValueTypes {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

interface UserAccountInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: UserAccountInformationValueTypes;
  account?:any;
}
export const UserAccountInformation: React.FC<UserAccountInformationProps> = ({
  ...props
}) => {
  const initialValues = {
    ...props.initialValues,
    firstName: props.account?.pharmacist?.firstName || props.initialValues.firstName,
    lastName: props.account?.pharmacist?.lastName || props.initialValues.lastName,
    phoneNumber: props.account?.pharmacist?.phone || props.initialValues.firstName,
    email: props.account?.email || props.initialValues.lastName,
  };
  const [fileWarning, setFileWarning] = useState(true);
  const [fileWarningInfo, setFileWarningInfo] = useState<AlertInfoProps>(
    {}
  );
  useEffect(() =>{
    setFileWarningInfo({
      title: "",
      message: `\n To complete your registration please have the following available: \n\n Government ID (JPEG)\nProfessional Profile Photo (JPEG)`,
      buttons: [

        <RBButton
          key={"confirm"}
          onClick={async () => {
            setFileWarning(false);
          }}
          buttonBackgroundColor={localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue}
          color="primary"
          text="Ok"
        />,
      ],
    });
  },[])

  return (
    <>
    <RBAlert
    show={fileWarning}
    alertTitle={fileWarningInfo.title}
    alertMessage={fileWarningInfo.message}
    buttons={fileWarningInfo.buttons}
  /> 
    <Formik
      initialValues={initialValues}
      innerRef={props.innerRef}
      validationSchema={Schema.UserAccountInformation}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={Schema.UserAccountInformation.isValidSync(
        props.initialValues
      )}
      onSubmit={() => {}}
    >
      {({ values, handleBlur, handleChange, handleSubmit }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <RBTextFieldForForm
            label="Email"
            placeholder="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
            disabled={true}
          />

          <RBTextFieldForForm
            FieldType={RBTextFieldForFormFieldType.password}
            label="Password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
          />
          <div
            style={{
              marginBottom: Spacing.large,
            }}
          >
            <Typography variant="caption">{Claims.password}</Typography>
          </div>

          <RBTextFieldForForm
            FieldType={RBTextFieldForFormFieldType.password}
            label="Confirm Password"
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="First Name"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            label="Last Name"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            FieldType={RBTextFieldForFormFieldType.phone}
            label="Phone Number"
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
        </Form>
      )}
    </Formik>
    </>
  );
};
