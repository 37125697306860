import { useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { PDFDocumentProxy } from "pdfjs-dist";
import React from "react";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface RBPdfProps {
  fileUrl: string;
  height?: number;
}
export const RBPdf: React.FC<RBPdfProps> = ({ fileUrl, height }) => {
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    setPageNumber(numPages);
  };

  return (
    <Document
      file={fileUrl}
      onLoadError={console.error}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <Page height={height} pageNumber={pageNumber} />
    </Document>
  );
};
