import React, { useState, useEffect ,useContext} from "react";
import { AuthService } from "../../api/AuthService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, Colors, FontSize } from "../../theme/Theme";
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioProps,
  withStyles,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router";
import { RoutePathConstant } from "../../util/Common";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum } from "../../api/constants";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement,useStripe, useElements } from '@stripe/react-stripe-js';
import "./style.css";
import {CreditCardForm} from "./CreditCardForm";
import { RBAppbar } from "../../components/RBAppbar";

export const PaymentCreditInput = () => {
  const history = useHistory();
  const location = useLocation();
  const { industryMode,primaryColor,secondaryColor, updateMode } = useContext(RBContext);
  const storedStripePromise = localStorage.getItem("stripePromise");
  const stripePromise = storedStripePromise !== null ? loadStripe(storedStripePromise) : undefined;
  const appearance = {
    theme: 'stripe' as const,
  };
  const options = {
    appearance,
  };
  useEffect(() => {
    
  }, []);
  const renderTabHeader = () => {
    return (
      <RBAppbar
        deeperLevel={true}
        barName={"Payment Setup"}
      />
    );
  };

  const renderCard = () => {
    return (
          <div className="App">
            {renderTabHeader()}
         {stripePromise !== undefined &&
         <Elements stripe={stripePromise} options={options} >
            <CreditCardForm/>
        </Elements>}
        </div>
    );
  };
  return <>{renderCard()}</>;
};