export const A = 1;
export const LabelText = {
  BACK: "Back",
  TECHNICIAN_ON_SITE: "Technician On-site",
  ASSISTANT_ON_SITE: "Assistant On-site",
  SHIFT_AGREEMENT: "Shift Agreement",
  HelpFAQ: "Help/FAQ",
  TermsOfService: "Terms of Service",
  PrivacyPolicy: "Privacy Policy",
  About: "Contact Us",
  DailyAvgRxCount: "Daily Avg Rx Count",
};
