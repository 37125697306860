import Api from "./API";

const getList = async (category:any,networkId:any) => {
  try {

    // debugger
    const response = await Api.axios.get(`page?networkId=${networkId}&category=${category}`);
    console.log("getList response ", response);
    return response.data.data;
  } catch (err) {
    if (err && err.response) {
      //   const axiosError = err as AxiosError<ServerError>;
      //   console.log("getShiftList in AxiosError", axiosError);
      //   console.log("getShiftList in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("getList in error", err);
    throw err;
  }
};

const list = (category:any,networkId:any) => networkId == '' ? 
  Api.axios.get(`page?category=${category}`).then(res => res.data.data.find((p:any) => p.network.id === '7b89c1df-df98-41e8-a06c-f3d1d748de84')) : 
  Api.axios.get(`page?networkId=${networkId}&category=${category}`).then(res => res.data.data);

const name = (category:any,networkId:any) => list(category,networkId);

const substitute = (name: any, substitutions: any) =>
  Api.axios
    .post(`page/${name}/fill`, substitutions)
    .then(response => response.data);

const substituteOwner = (name: any, substitutions: any, locationId:any) =>
  Api.axios
    .post(`page/${name}/fill/v2?locationId=${locationId}`, substitutions)
    .then(response => response.data);
const substituteJob = (name: any, substitutions: any, jobId:any, userId:any) =>
  Api.axios
    .post(`page/${name}/fill/v2?jobId=${jobId}&userId=${userId}`, substitutions)
    .then(response => response.data);
const substituteShift = (name: any, substitutions: any, shiftId:any,userId:any) =>
  Api.axios
    .post(`page/${name}/fill/v2?shiftId=${shiftId}&userId=${userId}`, substitutions)
    .then(response => response.data);


const getContentByName = async (name: string) => {
  try {
    const networkId = localStorage.getItem("networkId")
    debugger
    const response = await getList(name,networkId);
    return response[0];
    console.log("Response", response)
  } catch (err) {
    if (err && err.response) {
      //   const axiosError = err as AxiosError<ServerError>;
      //   console.log("getShiftList in AxiosError", axiosError);
      //   console.log("getShiftList in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("getContentByName in error", err);
    throw err;
  }
};

// const get = (id) => await Api.axios.get(`page/${id}`);

// const substitute = (name, substitutions) =>
//   this.axios
//     .post(`page/${name}/fill`, substitutions)
//     .then((response) => response.data);

export const PageService = {
  getContentByName,
  name,
  substitute,
  substituteOwner,
  substituteJob,
  substituteShift
};
