//import { IconName } from "react-icons/ai";
import { IoIosCar, IoIosCash } from "react-icons/io";
import { MdDone, MdClear } from "react-icons/md";
import { AiOutlineDollarCircle } from "react-icons/ai";
// import FoundationIcons from "react-icons/Foundation";
import React from "react";
import {
  detailAllowanceIconStyle,
  defaultAllowanceIconStyle,
  techOrAssistOnSiteStyle,
} from "../theme/Theme";

export const detailKmAllowanceIcon = (primaryColor:string) => {
  return (
    <IoIosCar
      size={detailAllowanceIconStyle.size}
      color={primaryColor}
    />
  );
};

export const detailAccomodationAllowanceIcon = (primaryColor:string) => {
  return (
    <IoIosCash
      size={detailAllowanceIconStyle.size}
      color={primaryColor}
    />
  );
};

export const defaultKmAllowanceIcon = (primaryColor:string) => {
  return (
    <IoIosCar
      size={defaultAllowanceIconStyle.size}
      color={primaryColor}
    />
  );
};

export const defaultAccomodationAllowanceIcon = (primaryColor:string) => {
  return (
    <IoIosCash
      size={defaultAllowanceIconStyle.size}
      color={primaryColor}
    />
  );
};


export const allowOtherProvinceIcon = (allowOtherProvince: any,secondaryColor:string) => {
  return (
    <>
    {allowOtherProvince ? (
      <MdDone
        size={techOrAssistOnSiteStyle.size}
        color={secondaryColor}
      />
    ) : (
      <MdClear
        size={techOrAssistOnSiteStyle.size}
        color={techOrAssistOnSiteStyle.colorForNo}
      />
    )}
  </>
  );
};

export const techOnSiteIcon = (technicianOnSite: boolean,secondaryColor:string) => {
  return (
    <>
      {technicianOnSite ? (
        <MdDone
          size={techOrAssistOnSiteStyle.size}
          color={secondaryColor}
        />
      ) : (
        <MdClear
          size={techOrAssistOnSiteStyle.size}
          color={techOrAssistOnSiteStyle.colorForNo}
        />
      )}
    </>
  );
};


export const allowofferIcon = (allowoffer: boolean,secondaryColor:string) => {
  return (
    <>
      {allowoffer ? (
        <MdDone
          size={techOrAssistOnSiteStyle.size}
          color={secondaryColor}
        />
      ) : (
        <MdClear
          size={techOrAssistOnSiteStyle.size}
          color={techOrAssistOnSiteStyle.colorForNo}
        />
      )}
    </>
  );
};


export const assistantOnSiteIcon = (assistantOnSite: boolean,secondaryColor:string) => {
  return (
    <>
      {assistantOnSite ? (
        <MdDone
          size={techOrAssistOnSiteStyle.size}
          color={secondaryColor}
        />
      ) : (
        <MdClear
          size={techOrAssistOnSiteStyle.size}
          color={techOrAssistOnSiteStyle.colorForNo}
        />
      )}
    </>
  );
};
