import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RBButton } from "../../components/RBButton";
import { makeStyles, Grid, Typography, Box, Link } from "@material-ui/core";
import { Spacing, Colors } from "../../theme/Theme";
import { RBCardView } from "../../components/RBCardView";
import { RoutePathConstant } from "../../util/Common";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: Spacing.larger,
    marginLeft: 3 * Spacing.larger,
    marginRight: 3 * Spacing.larger,
  },
  card: {
    padding: Spacing.larger,
    maxWidth: 700,
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: Spacing.medium,
    },
  },
  logo: {
    display: "flex",
    flex: 1,
    maxWidth: "100%",
    maxHeight: "100%",
    paddingLeft: 3 * Spacing.large,
    paddingRight: 3 * Spacing.large,
    paddingTop: Spacing.large,
    paddingBottom: Spacing.large,
    marginBottom: 2 * Spacing.large,
  },
  buttonContainer: {
    display: "flex",
   // flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 2 * Spacing.larger,
    paddingLeft: 3 * Spacing.large,
    paddingRight: 3 * Spacing.large,
  },
  welcomeText: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: 10,
  },
  subText: {
    textAlign: "center",
  },
  button: {
    width: "75%",
    paddingLeft: Spacing.large,
    paddingRight: Spacing.large,
    paddingTop: Spacing.medium,
    paddingBottom: Spacing.medium,
    marginBottom: 20,
    minHeight:50,
    margin:"auto"
  },
  loginButton: {
    width: "75%",
    paddingLeft: Spacing.large,
    paddingRight: Spacing.large,
    paddingTop: Spacing.medium,
    paddingBottom: Spacing.medium,
    minHeight:50,
    margin:"auto",
    backgroundColor: 'white', // Set background color to white
    color: 'black', // Set text color to black
    borderColor: 'black', // Set border color to black
    border: '1px solid', // Ensure the border is visible
  },
  registerContainer: {
    textAlign: "center",
    paddingBottom: Spacing.larger,
    // marginTop: Spacing.small,
  },
  registerLink: {
    color: Colors.themeDarkBlue,
    textDecoration: "none",
    fontWeight: "bold",
  },
}));

interface SignInPageProps {}

export const SignInPage: React.FC<SignInPageProps> = () => {
  const classes = useStyles();
  const history = useHistory();

  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: "100vh", width: "100%" }}
    >
      <Grid item xs={12} sm={10} md={8} lg={6} style={{width:"100%"}}>
        <RBCardView className={classes.card}>
          <img
            src={require("./RB_new_logo.png")}
            alt="logo"
            className={classes.logo}
          />
          <Typography className={classes.welcomeText}>
            Welcome to Relief Buddy
          </Typography>
          <Typography className={classes.subText}>
            Healthcare Staffing in Real-Time
          </Typography>
          <div className={classes.buttonContainer}>
            <RBButton
              className={classes.button}
              buttonBackgroundColor={Colors.themeDarkBlue}
              text="Login"
              variant="contained"
              type="submit"
              onClick={() => history.push("/Login")}
            />
            <Box mt={6} /> 
          </div>
          <div className={classes.registerContainer}>
            <Typography variant="body1">
            New to Relief Buddy?{" "}
              <Link
                className={classes.registerLink}
                component="button"
                type="button"
                variant="body2"

                onClick={() => {
                  history.push(RoutePathConstant.QuickRegistration);
                }}
              >
                Register Now
              </Link>
              {/* <Link to="/register" className={classes.registerLink}>
                Register Now
              </Link> */}
            </Typography>
          </div>
        </RBCardView>
      </Grid>
    </Grid>
  );
};
