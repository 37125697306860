import React, { useState, useEffect,useContext } from "react";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { Avatar, Link, Typography } from "@material-ui/core";
import { Spacing, FontSize, Colors, Font } from "../../theme/Theme";
import { StarRatings } from "../../components/StarRatings";
import formatService, {
  getMonthAndYearComparedToNow,
  formatWorkerType,
  getMonthAndYearByMonth,
} from "../../util/FormattingService";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { NotAvailableString } from "../../util/Common";
import { ColumnStyle } from "../../theme/ComponentTheme";
import {
  RenderTextWithTitle,
  renderTextWithTitleForSkills,
  renderTextWithTitleAndChildComponent,
  detailTextStyle,
} from "../../components/TextWithHeader";
import { RBCardView } from "../../components/RBCardView";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { workerTypeEnum } from "../../util/Enumeration";
import { ShiftService } from "../../api/ShiftService";
import { ApplicantState } from "../../interfacesProps/SetApplicantStateByPharmacyProps";
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import {
  useHistory,
  RouteComponentProps,
  withRouter,
  useLocation,
} from "react-router";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { ShiftProps } from "../../interfacesProps/ShiftProps";
import { RBContext } from "../../components/RBContext";
import moment from 'moment';
import { IndustryModeEnum } from "../../api/constants";
import { AuthService } from "../../api/AuthService";
import RBPlacementFeeModal from "../../components/RBPlacementFeeModal";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import PDFViewer from "../../components/PdfViewer";
import { RBTextField } from "../../components/RBTextField";

interface JobCandidatesPageWithoutRouterProps
  extends RouteComponentProps<{ jobId: string }> {}


  interface PdfDataUrls {
    [key: string]: string;
  }

const JobCandidatesPageWithoutRouter: React.FC<JobCandidatesPageWithoutRouterProps> = ({
  match,
}) => {
    const [jobID] = useState(match.params.jobId);
    const [jobData, setJobData] = useState<any>({});
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [applicants, setApplicants] = useState<ObjectLiteral[]>([]);
       
    const [extendeds, setExtendeds] = useState<Set<string>>(new Set());
//   const [isAlertShowing, setAlertShowing] = useState(false);
//   const [alertInfo, setAlertInfo] = useState<AlertInfoProps>({});
//   const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState<
        ErrorMessageProps
    >();
    const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
    const [account, setAccount] = useState<ObjectLiteral[]>([]);
    const [resumeModal, setReumeModal] = useState(false);
//   const [pdfDataStatus, setPdfDataStatus] = useState<{ [key: number]: boolean }>({});
   const [selectedApplicant, setSelectedApplicant] = useState<any>(null); // Store the selected applicant
   const [numPages, setNumPages] = useState<any>(null);
   const [appliedWorkersData, setAppliedWorkersData] = useState([]);
   const [showModal, setShow] = useState(false);
   const [alertSInfo,setAlertSInfo] =  useState<ObjectLiteral>({})
   const [inviteRequest,setInviteRequest] = useState<any>('');
   const [purchaseModalShow, setPurchaseModalShow] = useState(false);
   const [inviteRequestTotal,setInviteRequestTotal] = useState('');
   const [inviteRequestRemaining,setInviteRequestRemaining] = useState<any>('');
   const [isLoading, setIsLoading] = useState(false);
   const [inviteMaxReached, setInviteMaxReached] = useState(true);
   const [clickedWorkerType, setClickedWorkerType] = useState('');
   const [clickedApplicant, setClickedApplicant] = useState<any>({});
   const [showInviteWorkerModal, setShowInviteWorkerModal] = useState(false);
   const [isAlertShowing, setAlertShowing] = useState(false);
   const [successAlertInfo, setSuccessAlertInfo] = useState<AlertInfoProps>({});
   const [inviteAlert, setInviteAlert] = useState(false);
   const [inviteAlertInfo, setInviteAlertInfo] = useState<AlertInfoProps>({});
  const [isPopupLoading, setPopupLoading,] = useState(false);

  const [isPurchaseLoading, setPurchaseIsLoading] = useState(false);

  const history = useHistory();
  const fetchData = async () => {
    try {

      localStorage.removeItem("downloadedPdf")
      setIsPageLoading(true);

      const userData = await AuthService.loadAccountInfo();
      const applicantsData = await ShiftService.getAllPotentialJobApplicants(
        match.params.jobId,userData.networks[0].id
      );
      setAccount(userData);
      console.log("applicantsData",applicantsData)
      setApplicants(applicantsData.data.data);

      const job = await ShiftService.getJobDetailByJobId( match.params.jobId);
      setJobData(job)

      const response = await ShiftService.getJobApplicantsByJobId(
        match.params.jobId,userData.networks[0].id
      );
      setAppliedWorkersData(response.data)

      setInviteRequestRemaining(job.inviteMaxCount - job.invitedCount)
      
      
    } catch (err) {
      setSubmitErrorMessage(err.response ? err.response.data : err.message);
      console.log("JobCandidatesPageWithoutRouter error", err);
    } finally {
      setIsPageLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  const setExtendedsForPharmacist = (pharmacistId: string) => {
    //const { extendeds } = this.state;
    if (extendeds.has(pharmacistId)) {
      extendeds.delete(pharmacistId);
      //Since react will identify state changes only if the state property was replaced, and not mutated (shallow compare), you'll have to create a new Set from the old one, and apply the changes to it.

      setExtendeds(new Set(extendeds));
    } else {
      extendeds.add(pharmacistId);
      setExtendeds(new Set(extendeds));
    }
  };

  const renderSkills = (worker: ObjectLiteral) => {
    const skills = worker.skills || [];

    const softwareType =
      industryMode === IndustryModeEnum.Dentistry
        ? 'dentalSoftware'
        : 'software';

    const languages = skills
      .filter((skill: ObjectLiteral) => skill.type === "language")
      .map((skill: ObjectLiteral) => skill.name);
    const specializations = skills
      .filter((skill: ObjectLiteral) => skill.type === "specialization")
      .map((skill: ObjectLiteral) => skill.name);
    const software = skills
      .filter((skill: ObjectLiteral) => skill.type === softwareType)
      .map((skill: ObjectLiteral) => skill.name);

    return (
      <>
        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills("Languages", languages)}
        </div>
        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills("Software", software)}
        </div>
        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills("Specializations", specializations)}
        </div>
      </>
    );
  };

  const renderName = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    const name = `${pharmacist.firstName} ${pharmacist.lastName}`;
    const workerType = formatWorkerType(pharmacist.type);
    const rating = applicant.rating.averageRating ?? 0;
    const numberRatings = applicant.rating.numberRatings ?? 0;
    return (
      <div style={ColumnStyle}>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <div
            style={{
              fontSize: FontSize.largest,

              fontWeight: "bold",
              flexWrap: "wrap",
            }}
          >
            {name}
          </div>

          <StarRatings rating={rating} numberReviews={numberRatings} page="jobDetails"/>
        </div>
        
      </div>
      {/* <div>
            <RBButton
              text="Invite to Apply"
              onClick={() => inviteClick(workerType,applicant)}
              isLoading={isLoading}
              buttonBackgroundColor={secondaryColor}
            />
        </div> */}
     
     </div>
    );
  };

  const fetchAndStorePdfData = async (applicant:any) => {
    console.log("applicant",applicant)
    const resumeUrl = applicant?.resume;
    
    axios.get(resumeUrl, { responseType: 'arraybuffer' })
      .then(response => {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfData = URL.createObjectURL(pdfBlob);
        
        setSelectedApplicant((prevApplicants:any) => {
              return {
                ...prevApplicants,
                pdfDataUrl: pdfData,
              }
        });
      })
      .catch(() => {
        console.log('Error fetching PDF data.');
      });
  };
  const openResumeModal = (applicant: any) => {
    setSelectedApplicant(applicant);
    fetchAndStorePdfData(applicant);
    setReumeModal(true);
  };

 

  const renderResume = (worker: ObjectLiteral, applicant: any) => {
    console.log("renderResume ", worker.resume);
    return (
      <>
        {worker.resume
          ? renderTextWithTitleAndChildComponent(
              "Resume",
              <Link
                onClick={() => {
                  if(applicant?.isCandidateInRequests){
                    openResumeModal(worker)
                  }
                  else{
                    setAlertSInfo({
                      title: ' ',
                      message: 'To view a candidates resume, you need to invite them to apply and if they apply, their contact information and resume will be viewable.',
                      buttons: [
                        <RBButton
                          key={RBButtonType.cancel}
                          buttonType={RBButtonType.cancel}
                          onClick={() => {
                              setShow(false);
                          }}
                          text="Ok"
                          buttonBackgroundColor={primaryColor}
                          style={{color:"white"}}
                        />
                      ],
                    })
                    setShow(true);
                  }
                }}
                style={{ ...detailTextStyle, color: Colors.themeBlue, cursor: 'pointer' }}
              >
                {"View"}
              </Link>
            )
          : RenderTextWithTitle("Resume", NotAvailableString)}
      </>
    );
  };
 
  const renderStudent = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    const showExtended = extendeds.has(applicant.id);
    const email = applicant.email;
    const phone = pharmacist.phone;

    return (
      <>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Type", formatWorkerType(pharmacist.type))}
          {RenderTextWithTitle(
            "Assist Experience",
            getMonthAndYearByMonth(pharmacist.pharmacyAssistantExperienceMonths)
          )}
          {RenderTextWithTitle("Academic Year", pharmacist.currentSchoolYear)}
         
          
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Institution", pharmacist.graduationInstitution)}
          {RenderTextWithTitle("Registration #", pharmacist.licenseNumber)}
          {RenderTextWithTitle("City", pharmacist.address.city)}
        
        </div>
        <div style={ColumnStyle}>
          {renderResume(pharmacist,applicant)}
          {applicant?.isCandidateInRequests ?
            RenderTextWithTitle("Contact Phone", formatService.formatPhoneNumber(phone)) :
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflowWrap: "anywhere",
                alignItems: "flex-start",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                width: 100
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  overflowWrap: "anywhere",
                  color: Colors.darkGrey,
                  fontSize: FontSize.default,
                }}
              >
                Contact Phone
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  whiteSpace: "pre-wrap",
                  overflowWrap: "anywhere",
                  fontWeight: "bold",
                  fontSize: FontSize.large,
                  filter:'blur(3px)'
                }}
              >
                {formatService.formatPhoneNumber(phone)}
              </div>
            </div>

          }
          {RenderTextWithTitle("", "")}

        </div>

        <>{showExtended && renderSkills(pharmacist)}</>
      </>
    );
  };
  const renderAssistant = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    const yearGraduated = pharmacist.graduationYear;
    const showExtended = extendeds.has(applicant.id);
    const email = applicant.email;
    const phone = pharmacist.phone;

    return (
      <>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Type", formatWorkerType(pharmacist.type))}
          {RenderTextWithTitle(
            "Assist Experience",
            getMonthAndYearByMonth(pharmacist.pharmacyAssistantExperienceMonths)
          )}
           {RenderTextWithTitle(
            "Assist Course",
            pharmacist.pharmacyAssistantCourseCompleted ? "Yes" : "No"
          )}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Graduate From",
            pharmacist.graduationInstitution
          )}
          {RenderTextWithTitle("Graduate In", yearGraduated)}
          {RenderTextWithTitle("City", pharmacist.address.city)}
          
        </div>
        {/* if worker is invited and applied, then show email and phone */}
            <div style={ColumnStyle}>
              {renderResume(pharmacist,applicant)}
              {applicant?.isCandidateInRequests ?
            RenderTextWithTitle("Contact Phone", formatService.formatPhoneNumber(phone)) :
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflowWrap: "anywhere",
                alignItems: "flex-start",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                width: 100
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  overflowWrap: "anywhere",
                  color: Colors.darkGrey,
                  fontSize: FontSize.default,
                }}
              >
                Contact Phone
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  whiteSpace: "pre-wrap",
                  overflowWrap: "anywhere",
                  fontWeight: "bold",
                  fontSize: FontSize.large,
                  filter:'blur(3px)'
                }}
              >
                {formatService.formatPhoneNumber(phone)}
              </div>
            </div>

          }
              {RenderTextWithTitle("", "")}

            </div>
        <>{showExtended && renderSkills(pharmacist)}</>
      </>
    );
  };
  const renderPharmacistAndTechnician = (applicant: ObjectLiteral) => {
    console.log("renderPharmacistAndTechnician ", applicant);
    const { pharmacist } = applicant;
    const yearGraduated = pharmacist.graduationYear;
    const licensedDate = pharmacist.licensedDate
      ? getMonthAndYearComparedToNow(pharmacist.licensedDate)
      : NotAvailableString;
    const showExtended = extendeds.has(applicant.id);
    const email = applicant.email;
    const phone = pharmacist.phone;
    return (
      <>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("", formatWorkerType(pharmacist.type))}
          {RenderTextWithTitle("License Number", pharmacist.licenseNumber)}
          {RenderTextWithTitle("Years Licensed", licensedDate)}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Graduate From",
            pharmacist.graduationInstitution
          )}
          {RenderTextWithTitle("Graduate In", yearGraduated)}
          {renderResume(pharmacist,applicant)}

        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Licensed In", pharmacist.licenseProvince)}
          {RenderTextWithTitle("City", pharmacist.address.city)}
            
          {applicant?.isCandidateInRequests ?
            RenderTextWithTitle("Contact Phone", formatService.formatPhoneNumber(phone)) :
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflowWrap: "anywhere",
                alignItems: "flex-start",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                width: 100
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  overflowWrap: "anywhere",
                  color: Colors.darkGrey,
                  fontSize: FontSize.default,
                }}
              >
                Contact Phone
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  whiteSpace: "pre-wrap",
                  overflowWrap: "anywhere",
                  fontWeight: "bold",
                  fontSize: FontSize.large,
                  filter:'blur(3px)'
                }}
              >
                {formatService.formatPhoneNumber(phone)}
              </div>
            </div>

          }

        </div>



        <>{showExtended && renderSkills(pharmacist)}</>
      </>
    );
  };

  const renderLicensedDentistWorker = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    const showExtended = extendeds.has(applicant.id);
    const email = applicant.email;
    const phone = pharmacist.phone;

    const licenseExpiryDate = pharmacist.licenseExpiryDate
      ? moment(pharmacist.licenseExpiryDate).format('YYYY - MM')
      : 'N/A';
    const licensedDate = pharmacist.licensedDate
      ? getMonthAndYearComparedToNow(pharmacist.licensedDate)
      : 'N/A';


    return (
      <>
        <div style={ColumnStyle}>
          {RenderTextWithTitle('', formatWorkerType(pharmacist.type))}
          {RenderTextWithTitle("Registration #", pharmacist.licenseNumber)}
          {RenderTextWithTitle("Years Licensed", licensedDate)}
          
        </div>
        <div style={ColumnStyle}>
        {RenderTextWithTitle("Registered In", pharmacist.licenseProvince)}
          {RenderTextWithTitle("Expires On", licenseExpiryDate)}

          {RenderTextWithTitle(
            "City",
            pharmacist.address.city
          )}
          
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Graduated From", pharmacist.graduationInstitution,)}
          {RenderTextWithTitle(
            "Graduated In",
            pharmacist.graduationYear,
          )}
          {renderResume(pharmacist,applicant)}
          
        </div>
        <div style={ColumnStyle}>
          {applicant?.isCandidateInRequests ?
            RenderTextWithTitle("Contact Phone", formatService.formatPhoneNumber(phone)) :
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflowWrap: "anywhere",
                alignItems: "flex-start",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                width: 100
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  overflowWrap: "anywhere",
                  color: Colors.darkGrey,
                  fontSize: FontSize.default,
                }}
              >
                Contact Phone
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  whiteSpace: "pre-wrap",
                  overflowWrap: "anywhere",
                  fontWeight: "bold",
                  fontSize: FontSize.large,
                  filter:'blur(3px)'
                }}
              >
                {formatService.formatPhoneNumber(phone)}
              </div>
            </div>

          }

        </div>

        {showExtended && 
          <>
          {renderSkills(pharmacist)}</>
        }
      </>
    );
  };

  const renderNonLicensedDentistWorker = (applicant: ObjectLiteral) => {
    const { pharmacist } = applicant;
    const showExtended = extendeds.has(applicant.id);
    const email = applicant.email;
    const phone = pharmacist.phone;


    return (
      <>
     
        <div style={ColumnStyle}>
        {RenderTextWithTitle('', formatWorkerType(pharmacist.type))}
          {RenderTextWithTitle("Graduated From", pharmacist.graduationInstitution,)}
          {RenderTextWithTitle(
            "Graduated In",
            pharmacist.graduationYear,
          )}
         
          
        </div>
        <div style={ColumnStyle}>
        {RenderTextWithTitle(
            "Experience",
            getMonthAndYearByMonth(
              pharmacist.pharmacyAssistantExperienceMonths,
            ),
          )}
          {RenderTextWithTitle(
            "City",
            pharmacist.address.city
          )}
          {renderResume(pharmacist,applicant)}
          
        </div>
       
        <div style={ColumnStyle}>
          {applicant?.isCandidateInRequests ?
            RenderTextWithTitle("Contact Phone", formatService.formatPhoneNumber(phone)) :
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflowWrap: "anywhere",
                alignItems: "flex-start",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                width: 100
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  overflowWrap: "anywhere",
                  color: Colors.darkGrey,
                  fontSize: FontSize.default,
                }}
              >
                Contact Phone
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  whiteSpace: "pre-wrap",
                  overflowWrap: "anywhere",
                  fontWeight: "bold",
                  fontSize: FontSize.large,
                  filter:'blur(3px)'
                }}
              >
                {formatService.formatPhoneNumber(phone)}
              </div>
            </div>

          }

        </div>
         

        {showExtended && 
        <>
        {renderSkills(pharmacist)}</>
        }
      </>
    );
  };

  const renderApplicant = (applicant: ObjectLiteral) => {
    if(appliedWorkersData.length > 0)
    {
      const isCandidateInRequests = appliedWorkersData.some((request:any) => request.pharmacist.id === applicant.pharmacist.id);
      applicant.isCandidateInRequests = isCandidateInRequests;
    }
   

    const { pharmacist } = applicant;
    const userAssociationMember = applicant.userAssociationMember || [];
    const showExtended = extendeds.has(applicant.id);
    let associationLogo = null;
    if (userAssociationMember.length > 0) {
      associationLogo = userAssociationMember[0].association.logo;
    }
    const rbVerified = (applicant.pharmacist?.approval && applicant.pharmacist?.approval?.rbVerified) ? applicant.pharmacist?.approval?.rbVerified : false;
    const workerType = formatWorkerType(pharmacist.type);
    return (
      <>

     
     
     <div
        style={{
          display: "flex",
          width: "100%",
          paddingLeft: Spacing.large,
          paddingRight: Spacing.large,
          paddingTop: Spacing.large,
          paddingBottom: Spacing.large,
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* Avatar and association logo */}
          <div>
            <Avatar
              style={{ height: 124, width: 124 }}
              // src={pharmacist.profilePhoto}
              src={pharmacist.profilePhoto}
            >
              :(
            </Avatar>
            {associationLogo && (
              <>
              <img
                src={associationLogo}
                alt={"associationLogo"}
                style={{
                  width: 96,
                  height: 36,
                  marginTop: 4,
                  marginBottom: 4,
                }}
              />
              <br></br>
              </>
            )}
            {
              (rbVerified === false
                && applicant.networks[0].id === "7b89c1df-df98-41e8-a06c-f3d1d748de84"
                && jobData.pharmacy?.networkId === "7b89c1df-df98-41e8-a06c-f3d1d748de84") ? null :
              <img
                src={
                  jobData.pharmacy?.networkId === applicant?.networks[0].id ?
                    require('../../img/50x50-01.png')
                    : require('../../img/100x100-02-01.png')
                }
                alt={"networkIcon"}
                style={{
                  // width: 70,
                  height: 50,
                  marginLeft: 37
                }}
              />
            }
            
          </div>
          <RBButton
            buttonBackgroundColor={primaryColor}
            onClick={() => setExtendedsForPharmacist(applicant.id)}
            text={`View ${showExtended ? "less" : "more"}`}
          />
        </div>
        {/* Applicant Detail */}
        <div style={{ flex: 1, marginLeft: Spacing.larger }}>
          {renderName(applicant)}
          {(pharmacist.type === workerTypeEnum.pharmacistStudent ||
            pharmacist.type === workerTypeEnum.technicianStudent) &&
            renderStudent(applicant)}
          {pharmacist.type === workerTypeEnum.assistant &&
            renderAssistant(applicant)}
          {(pharmacist.type === workerTypeEnum.pharmacist ||
            pharmacist.type === workerTypeEnum.technician) &&
            renderPharmacistAndTechnician(applicant)}

          {(pharmacist.type === workerTypeEnum.dentist ||
              pharmacist.type === workerTypeEnum.dentistRDA ||
              pharmacist.type === workerTypeEnum.dentistCDA ||
              pharmacist.type === workerTypeEnum.dentistCDA2 ||
              pharmacist.type === workerTypeEnum.dentistRDT ||
              pharmacist.type === workerTypeEnum.dentistRDH ||
              pharmacist.type === workerTypeEnum.denturist) &&
              renderLicensedDentistWorker(applicant)}
            {(pharmacist.type === workerTypeEnum.dentistAdministrator ||
              pharmacist.type === workerTypeEnum.dentistSterilizationAssistant ||
              pharmacist.type === workerTypeEnum.dentistDT ||
              pharmacist.type === workerTypeEnum.dentistChairSideAssistant) &&
              renderNonLicensedDentistWorker(applicant)}
            {!applicant?.isCandidateInRequests &&
              <div style={{ alignItems: "flex-end",display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",}}>
                <RBButton
                  text="Invite to Apply"
                  onClick={() => inviteClick(workerType, applicant)}
                  isLoading={isLoading}
                  buttonBackgroundColor={secondaryColor}
                />
              </div>}
        </div>

      </div>
      </>
    );
  };

  

  const onResumeClose= () => {
    setReumeModal(false)
    setSelectedApplicant(null);
   };

   const renderRequestedRateContent =  (applicant:any) => {
    console.log("selectedApplicant",applicant)
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          userSelect:"none",
          pointerEvents:"none"

        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
              {applicant.pdfDataUrl ? 
              <Document  file={applicant.pdfDataUrl}
              onLoadSuccess={({ numPages })=>setNumPages(numPages)}
              onLoadError={(error) => console.error('PDF Load Error:', error)}>
                {Array.apply(null, Array(numPages))
                .map((x, i)=>i+1)
                .map(page => <Page pageNumber={page}/>)}
              </Document>:
               <LoadingIndicator />}
          </RBCardView>
       
      </div>
    );
  };

  const purchaseModalClose= () => {
    if(!isLoading){
      setPurchaseModalShow(false)
    }
  };
  const inviteWorkerModalClose = () =>{
    if(!isPopupLoading)
    {
      setShowInviteWorkerModal(false)
    }
  }
  const setInviteCount = (count:any) => {
    console.log("invite count", count)
    setInviteRequest(count)
    let totalInviteAmount:any = count*(jobData?.perInviteAmount ? jobData.perInviteAmount: jobData.pharmacy.jobPerInviteAmount);
    setInviteRequestTotal(parseFloat(totalInviteAmount).toFixed(2))
  };

  const purchase = async () => {
    try{
      setPurchaseIsLoading(true);
      let inviteCount = inviteRequest;
      const response = await ShiftService.purchaseInvite(inviteCount,match.params.jobId);
      setInviteRequestTotal('')
     
      // if(inviteMaxReached){
      //   setPurchaseModalShow(false)
      //   console.log("inviteMaxReached",inviteMaxReached)
      //   await inviteClick(clickedWorkerType,clickedApplicant)
      // }
      // else{
        setSuccessAlertInfo({
          title: "Success",
          message: `\n Additional invitations purchased successfully!`,
          buttons: [
           
            <RBButton
              key={"confirm"}
              onClick={async () => {
                
                setPurchaseModalShow(false)
                setAlertShowing(false);
                await fetchData()
               
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Ok"
            />,
          ],
        });
        setAlertShowing(true);
     // }
     
    }
    catch (error) {
      if(error?.response?.data?.error)
      {
        setPurchaseModalShow(false)
        setSuccessAlertInfo({
          title: "An Error Occurred",
          message: `${error.response?.data?.message}`,
          buttons: [
          
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setAlertShowing(false);
                history.goBack();
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Ok"
            />,
          ],
        });
        setAlertShowing(true);
      }
    }
    finally {
      setPurchaseIsLoading(false);
    }
  };

  const inviteClick =  async (workerType:any,applicant:any) => {
    let reminingRequest; 
    setIsLoading(true)
    //const job = await ShiftService.getJobDetailByJobId(match.params.jobId);
    setIsLoading(false)
    setClickedWorkerType(workerType)
    setClickedApplicant(applicant)
    setInviteRequest('')
    setInviteRequestTotal('')
    if(jobData.inviteMaxCount > jobData.invitedCount )
    {
      setInviteMaxReached(false)
      setShowInviteWorkerModal(true)

    }
    else{
      console.log('purchase')
      setPurchaseModalShow(true)
      setInviteMaxReached(true)
     
    }
  }
  const inviteWorkers = async () => {
    try{
      setPopupLoading(true);
      const response = await ShiftService.inviteWorker(clickedApplicant.id,match.params.jobId);
      let reminingRequest = response.inviteMaxCount - response.invitedCount;
      await fetchData()
      if(response)
      {
        setInviteAlertInfo({
          title: `${clickedWorkerType} invited successfully!`,
          message: `\n Remaining Requests : ${reminingRequest}`,
          buttons: [
           
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setShowInviteWorkerModal(false)
                setInviteAlert(false)
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Ok"
            />,
          ],
        });
        setInviteAlert(true);

      }
    }
    catch (error) {
      
      if(error?.response?.data?.error)
      {
        setInviteAlertInfo({
          title: `An Error Occurred`,
          message: `${error.response?.data?.message}`,
          buttons: [
           
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setShowInviteWorkerModal(false)
                setInviteAlert(false)
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Ok"
            />,
          ],
        });
        setInviteAlert(true);

      }
    }
    finally {
      setPopupLoading(false);
    }
  }

  const renderInviteWorkerContent = () =>{
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: "30px",
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            <div style={{textAlign:'center'}}>
              Invite this {clickedWorkerType} to apply?
           </div>
           <br></br>
           <div style={{
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}>
            <RBButton
              buttonBackgroundColor={primaryColor}
              disabled={isPopupLoading}
              text="No"
              onClick={ () =>{ 
                setShowInviteWorkerModal(false)
               // await fetchData();
              }}
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
            />
            <RBButton
              buttonBackgroundColor={primaryColor}
              text="Yes"
              onClick={() => {
                setPopupLoading(true);
                inviteWorkers()
              }}
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
              isLoading={isPopupLoading}
            />
           </div>
          </RBCardView>
       
      </div>
    );
  }

  const renderPurchaseContent = () => {

    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
        }}
      >

        <RBCardView
          style={{
            flex: 1,
            marginLeft: Spacing.large,
            marginRight: Spacing.large,
            marginBottom: Spacing.smallest,
            paddingLeft: Spacing.large,
            paddingTop: Spacing.large,
            paddingRight: Spacing.large,
            paddingBottom: Spacing.large,
          }}
        >
          {inviteRequestRemaining === 0 && <div>
            You have reached the maximum number of invitations for this job.
          </div>}
          <br></br>
          <div>
          You can purchase additional invitations for an additional ${jobData?.perInviteAmount ? jobData.perInviteAmount: jobData.pharmacy?.jobPerInviteAmount} per invitation, plus applicable taxes.
          </div>
          <br></br>

          <div style={{display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            paddingBottom: Spacing.medium,}}>

              <div
                style={{
                  fontWeight: "bold",
                  fontSize: FontSize.large,
                  paddingTop:10,

                  paddingLeft:"30%"
                }}
              >
                {jobData?.perInviteAmount ? jobData.perInviteAmount + ' X ' : jobData.pharmacy?.jobPerInviteAmount + ' X '}
              </div>

              <div
                style={{
                  fontWeight: "bold",
                  fontSize: FontSize.large,
                  paddingLeft:"5%"
                }}
              >
                <RBTextField
                  style={{ width: 100 }}
                  type="number"
                  margin={"dense"}
                  value={inviteRequest}
                  placeholder="Invites"
                  onChange={(e) => setInviteCount(e.target.value)}
                />
              </div>

              <div
                style={{
                  fontWeight: "bold",
                  fontSize: FontSize.large,
                  paddingTop:10,
                  paddingLeft:"5%"
                }}
              >
                {' = $'+inviteRequestTotal}
              </div>
          </div>

          <br></br>

          <div style={{
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}>
            <RBButton
              buttonBackgroundColor={primaryColor}
              disabled={isPurchaseLoading}
              text="Cancel"
              onClick={() => {
                setInviteRequest('')
                setInviteRequestTotal('')
                setPurchaseModalShow(false)
              }}
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
            />
            <RBButton
              buttonBackgroundColor={primaryColor}
              text="Purchase"
              isLoading={isPurchaseLoading}
              onClick={() => { purchase() }}
              disabled={inviteRequest == '' || inviteRequest <= 0}
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
            />
          </div>
        </RBCardView>

      </div>
    );
  };


  return (
    <>
     
      {/* {renderAlerts()} */}
      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={JSON.stringify(submitErrorMessage, null, 2)}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      />
      <RBAlert
        show={showModal}
        alertTitle={alertSInfo.title}
        alertMessage={alertSInfo.message}
        buttons={alertSInfo.buttons}
      />
       <RBAlert
          show={isAlertShowing}
          alertTitle={successAlertInfo.title}
          alertMessage={successAlertInfo.message}
          buttons={successAlertInfo.buttons}
        />
        <RBAlert
          show={inviteAlert}
          alertTitle={inviteAlertInfo.title}
          alertMessage={inviteAlertInfo.message}
          buttons={inviteAlertInfo.buttons}
        />


{selectedApplicant && <RBModal
          open={resumeModal}
          onClose={onResumeClose}
          modalTitle={"Resume"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.larger,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
          children={
              renderRequestedRateContent(selectedApplicant)
          }
        />}
         <RBModal
          open={purchaseModalShow}
          onClose={purchaseModalClose}
          modalTitle={"Purchase Invitations"}
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              renderPurchaseContent()
          }
        />
        <RBModal
          open={showInviteWorkerModal}
          onClose={inviteWorkerModalClose}
          modalTitle={"invitejob"}
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              renderInviteWorkerContent()
          }
        />

      {isPageLoading ? (
         <LoadingIndicator />
      ) : (
        <> 
        {applicants.length === 0 ? 
        <div style={{ marginTop: Spacing.large, marginBottom: Spacing.large }}>
                There are no candidates available that match the job requirements at
                this time. You can edit the job details at any time in order to
                increase the number of candidates available.
        </div>
        :
        <>
        <RBCardView
              key={"payrollHeader"}
              marginHorizontal={Spacing.large}
              marginVertical={Spacing.medium}
              paddingHorizontal={Spacing.large}
              paddingVertical={Spacing.medium}
            >
               <div>
          <div style={ColumnStyle}>
            <Typography
              style={{ fontWeight: "bold", fontSize: Font.largest }}
            >
            Remaining Invites
            </Typography>
            {inviteRequestRemaining !== '' && <Typography
              style={{
                fontWeight: "bold",
                fontSize: Font.largest,
                marginRight:65
              }}
            >
              {inviteRequestRemaining}
            </Typography>}
          </div>
          <div style={ColumnStyle}>
            <Typography
              style={{ fontWeight: "bold", fontSize: Font.largest }}
            >
              Purchase Invites
            </Typography>
            <RBButton
            style={{width:130}}
              text="Purchase"
              onClick={async () => {
                setPurchaseModalShow(true)
                  setInviteMaxReached(false)
                  setClickedWorkerType('')
                  setClickedApplicant('')
                  setInviteRequest('')
              }}
              buttonBackgroundColor={secondaryColor}
          />
          </div>
        </div>
        </RBCardView>
        <div style={{ marginTop: Spacing.large, marginBottom: Spacing.large }}>
          {applicants.map((applicant) => (
            <div
              key={applicant.id}
              children={
                <RBCardView
                  marginHorizontal={Spacing.large}
                  style={{ marginBottom: Spacing.large }}
                  children={renderApplicant(applicant)}
                />
              }
            />
          ))}
        </div>
        </>
        }
        </>
      )}
    </>
  );
};

export const JobCandidatesPage = withRouter(JobCandidatesPageWithoutRouter);
