import React, { useState,useEffect,useRef, useContext } from "react";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, AppContainerWidth, Colors,FontSize, Font } from "../../theme/Theme";
import { RBAppbar } from "../../components/RBAppbar";
import { Typography, IconButton, Grid } from "@material-ui/core";
import { RBTextField ,RBTextFieldForForm, RBTextFieldForFormFieldType} from "../../components/RBTextField";
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBButton } from "../../components/RBButton";
import { EmptyString } from "../../util/Common";
import { AuthService } from "../../api/AuthService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Formik, Form, ErrorMessageProps } from "formik";
import * as Yup from "yup";
import { RoutePathConstant } from "../../util/Common";
import { makeStyles, Link } from "@material-ui/core";
import { RBContext } from "../../components/RBContext";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { useRouteMatch, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    inputContainer: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: Spacing.larger,
      marginLeft: 3 * Spacing.larger,
      marginRight: 3 * Spacing.larger,
    },
    card: {
      padding: Spacing.larger,
      maxWidth: 700,
      width: "100%",
      [theme.breakpoints.down('sm')]: {
        padding: Spacing.medium,
      },
    },
    logo: {
      display: "flex",
      flex: 1,
      maxWidth: "100%",
      maxHeight: "100%",
      paddingLeft: 3 * Spacing.large,
      paddingRight: 3 * Spacing.large,
      paddingTop: Spacing.large,
      paddingBottom: Spacing.large,
      marginBottom: 2 * Spacing.large,
    },
    subText: {
      textAlign: "center",
      fontSize: Font.large,
       [theme.breakpoints.down('sm')]: {
        paddingLeft: 3 * Spacing.medium,
      paddingRight: 3 * Spacing.medium,
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 2 * Spacing.larger,
      marginBottom: Spacing.larger,
      paddingLeft: 3 * Spacing.large,
      paddingRight: 3 * Spacing.large,
      paddingBottom : 3 * Spacing.large,
      '@media (max-width: 768px)': {
          flexDirection: "column",
          alignItems: "center",
          '& > *:not(:last-child)': {
              marginBottom: Spacing.medium,
          },
      },
      '@media (min-width: 769px)': {
          '& > *:not(:last-child)': {
              marginRight: Spacing.medium,  // Add margin-right between buttons on larger screens
          },
      },
  },
  button: {
      minWidth: 200,
      paddingLeft: Spacing.large,
      paddingRight: Spacing.large,
      paddingTop: Spacing.medium,
      paddingBottom: Spacing.medium,
      marginBottom: Spacing.medium,
      '@media (max-width: 768px)': {
          width: "100%",
      },
  },
  InputContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: Spacing.larger,
    marginLeft: 4 * Spacing.larger,

    marginRight: 4 * Spacing.larger,
  },
   
  }));

export const PendingApprovalPage = () => {
  const classes = useStyles();
//   const [email, setEmail] = useState(EmptyString);
   const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const { primaryColor,secondaryColor, industryMode,contactEmail,contactPhone ,updateprimaryColor,updateSecondaryColor,updateContactEmail,updateContactPhone,updateMode } = useContext(RBContext);
  
  //const [reason,setReason] =  useState<any>(null)
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const isMountedRef = useRef(false);
  const match = useRouteMatch<{ reason: string }>();
  useEffect(() => {

    (async () => {
        await updateprimaryColor( localStorage.getItem('primaryColor')); 
        await updateSecondaryColor( localStorage.getItem('secondaryColor')); 
        await updateContactEmail( localStorage.getItem('contactEmail')); 
        await updateContactPhone( localStorage.getItem('contactPhone')); 
        await updateMode(localStorage.getItem('industryMode')); 
    })(); 

    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <Grid
    container
    justify="center"
    alignItems="center"
    style={{ minHeight: "100vh", width: "100%" }}
  >
    <Grid item xs={12} sm={10} md={8} lg={6} style={{width:"100%"}}>
      <RBCardView className={classes.card}>
            <Typography variant="h5" style={{ textAlign: "center" }}>Account Status {match.params.reason === "pending"?  "Pending" : "Denied"} </Typography>
        
          {match.params.reason === "pending" ?
              <Typography
                  variant={"h6"}
                  style={{ textAlign: "center" }}
              >
                  Our admins are reviewing your profile.​<br></br><br></br>
                  You will be notified by email once your account has been approved.​<br></br><br></br>
                  Please contact us at {contactEmail} or call us at {contactPhone} if you have any questions regarding the status of your registration.

              </Typography>
              :
              <Typography
                  variant={"h6"}
                  style={{ textAlign: "center", minWidth:"100%" }}
              >
                  Access to your account has been denied.<br></br><br></br>
                  Please contact {contactEmail} for further instructions.
              </Typography>
          }
            <div   className={classes.InputContainer}
             style={{ marginBottom: Spacing.xlarge }}>
                <RBButton
                   style={{
                    width: "100%",
                    paddingLeft: Spacing.large,
                    paddingRight: Spacing.large,
                    paddingTop: Spacing.medium,
                    paddingBottom: Spacing.medium,
                    marginBottom: 30
                  }}
                  buttonBackgroundColor={primaryColor}
                  text="Logout"
                  isLoading={isSubmitting}
                  variant="contained"
                  type="submit"
                  onClick={() => {
                      AuthService.removeAuth();
                      history.push(RoutePathConstant.SignIn);
                  }}
                />
              </div>

          </RBCardView>
      </Grid>
    </Grid>
  );
};
