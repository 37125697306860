import React, { useState, useRef, useEffect,useContext} from "react";
import {Colors} from '../../../theme/Theme'

interface DefaultAvatarProps {
    borderWidth: number,
    borderColor: string,
    size: number,
    name: string,
    shape: string,
    borderStyle: string
}

export const DefaultAvatar : React.FC<DefaultAvatarProps> = ({...props}) => {
    const getInitials = (name:any) =>
        name
            .split(' ')
            .map((word:any) => word.substr(0, 1).toUpperCase())
            .join('');

    const styles = {
        container: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
        },
        empty: {
            backgroundColor: '#bebebe',
        },
        text: {
            color: '#ffffff',
            fontSize: 22,
        },
    };

    function getBorderRadius(size:any, shape:any) {
        switch (shape) {
            case 'circle':
              return { borderRadius: size / 2 };
            case 'roundedSquare':
              return { borderRadius: size / 8 };
            default:
              return { borderRadius: 0 };
          }
    }

    function DefaultAvatar(props:any) {
        console.log('style props', props)
        const { borderColor, borderWidth, name, shape, size, borderStyle } = props;
        const sizeStyles = { width: size, height: size };
        const borderStyles = {
          borderColor,
          borderWidth,
          borderStyle,
          ...getBorderRadius(size, shape),
        };
        const fontStyles = { fontSize: size / 2 };
      
        return (
          <div style={Object.assign(styles.container, styles.empty, sizeStyles, borderStyles)}>
            <span style={Object.assign(styles.text, fontStyles) }>
              {name ? getInitials(name) : null}
            </span>
          </div>
          
        );
    }

    // DefaultAvatar.defaultProps = {
    //     size: 24,
    //     borderColor: Colors.mediumGrey,
    //     borderWidth: 3,
    //     shape: 'circle',
    //     name: 'RB',
    // };
    
    return <>{DefaultAvatar(props)}</>;
}