import { useState, useEffect } from "react";
import React from "react";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { ShiftService } from "../../api/ShiftService";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ConfirmShiftHourPageForWorker } from "./ConfirmShiftHourPageForWorker";
import { AuthService } from "../../api/AuthService";
import { roleNameEnum } from "../../util/Common";
import { ConfirmShiftHourPageForPharmacy } from "./ConfirmShiftHourPageForPharmacy";
import { ShiftProps } from "../../interfacesProps/ShiftProps";

interface ConfirmShiftHourControllerProps
  extends RouteComponentProps<{ shiftId: string }> {}

const ConfirmShiftHourControllerWithoutRouter: React.FC<ConfirmShiftHourControllerProps> = ({
  match,
}) => {
  const [shiftDetail, setShiftDetail] = useState<ShiftProps>({});
  const [roleName] = useState(AuthService.getRoleName());
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    setIsPageLoading(true);
    const fetchData = async () => {
      try {
        console.log("ConfirmShiftHourControllerWithoutRouter ");
        const shiftDetailData = await ShiftService.getShiftDetailByShiftId(
          match.params.shiftId
        );
        console.log(
          "ConfirmShiftHourControllerWithoutRouter shiftDetailData ",
          shiftDetailData
        );
        setShiftDetail(shiftDetailData);
        setIsPageLoading(false);
        console.log("ConfirmShiftHourControllerWithoutRouter ", shiftDetail);
      } catch (error) {
        setIsPageLoading(false);
        console.log("ConfirmShiftHourControllerWithoutRouter error", error);
      }
    };

    fetchData();
  }, [match.params.shiftId]);

  const renderConfirmShiftHourPage = () => {
    return (
      <>
        {roleName === roleNameEnum.pharmacist ? (
          <ConfirmShiftHourPageForWorker shiftData={shiftDetail} />
        ) : (
          <ConfirmShiftHourPageForPharmacy shiftData={shiftDetail} />
        )}
      </>
    );
  };
  return (
    <>{isPageLoading ? <LoadingIndicator /> : renderConfirmShiftHourPage()}</>
  );
};

export const ConfirmShiftHourController = withRouter(
  ConfirmShiftHourControllerWithoutRouter
);
