import React, { useEffect, useState } from "react";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { Spacing, Colors } from "../../../theme/Theme";
import { RenderTextWithTitle } from "../../../components/TextWithHeader";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import formatService from "../../../util/FormattingService";
import { RBMap } from "../../../components/RBMap";
import { AuthService } from "../../../api/AuthService";


interface NetworkInformationProp {
    pharmacy: ObjectLiteral;
    account:ObjectLiteral;
    networkAdminInfo:ObjectLiteral;
}

const ShiftNetworkInformationProp = () => {
    return (
        <div>
            <div className={"section-header-text"}>Network Information</div>

            <div
                style={{
                    height: 1,
                    backgroundColor: Colors.defaultGrey,
                    marginTop: Spacing.medium,
                    marginBottom: Spacing.medium,
                }}
            />
        </div>
    );
};

export const ShiftNetworkInformation: React.FC<NetworkInformationProp> = ({
    ...props
}) => {
    const {network} = props.pharmacy
    const workerNetworkId = props.account.networks[0].id;
 
    return(
        <div>
            <div
                className="section-header-text"
                style={ColumnStyle}
            >
                Network Information
                {
                    (props.account.networks[0].isTrustNetwork === true  && 
                     props.account.pharmacist.approval?.rbVerified === true &&
                        <img
                            src={props.pharmacy?.networkId === workerNetworkId ? require('../../../img/50x50-01.png') : require('../../../img/100x100-02-01.png')}
                            alt="Network Logo"
                            style={{

                                height: 50,
                                marginRight:500
                            }}
                        />
                    )
                }
            </div>
            <div style={ColumnStyle}>
                {RenderTextWithTitle('Contact', props.networkAdminInfo?.userToNetwork[0]?.user?.admin ? props.networkAdminInfo?.userToNetwork[0]?.user?.admin?.firstName +' '+ props.networkAdminInfo?.userToNetwork[0]?.user?.admin?.lastName : '')}
               
            </div>
            <div style={ColumnStyle}>
                {RenderTextWithTitle(
                    "Phone",
                    formatService.formatPhoneNumber(network.contactPhone)
                )}
            </div>

            <div
                style={{
                    height: 1,
                    backgroundColor: Colors.defaultGrey,
                    marginTop: Spacing.medium,
                    marginBottom: Spacing.medium,
                }}
            />
        </div>
    )

    
  };

