import React, { useState} from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { LoadingIndicator } from "../../components/LoadingIndicator";
interface ContactUsPageWithoutRouteProps extends RouteComponentProps<any> {}
const ContactUsPageWithoutRoute: React.FC<ContactUsPageWithoutRouteProps> = ({
  ...props
}) => {
  const contactEmail = localStorage.getItem("contactEmail");
  const contactPhone = localStorage.getItem("contactPhone");
  return (
    <>
        <>
          <div>
            <p style={{ paddingLeft: 15, fontSize: 14, fontWeight: 'bold' }}>
              If you require assistance or have any questions,
              please contact us and we would be happy to help!
            </p>
            <p style={{ paddingLeft: 15, fontSize: 14, fontWeight: 'bold' }}>
              Email: <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </p>
            <p style={{ paddingLeft: 15, fontSize: 14, fontWeight: 'bold' }}>Phone: {contactPhone}</p>
          </div>
        </>
    </>
  );
};

export const ContactUsPage = withRouter(ContactUsPageWithoutRoute);
