import React, { useState, useEffect,useContext } from "react";
import { ShiftService } from "../../api/ShiftService";
import { useRouteMatch, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { AuthService } from "../../api/AuthService";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, Colors } from "../../theme/Theme";
import { orderBy, map } from "lodash";
import { ColumnStyle, skillTextStyle } from "../../theme/ComponentTheme";
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
} from "@material-ui/core";
import {
  RBTextField,
  RBMoneyAmountTextField,
} from "../../components/RBTextField";
import { RBDateTimePicker } from "../../components/RBDateTimePicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { ErrorMessages, Claims, EmptyString } from "../../util/Common";
import { formatWorkerType } from "../../util/FormattingService";
import { workerTypeEnum, SkillType } from "../../util/Enumeration";
import { SkillResponseDto } from "../../dtos/Skill.dto";
import { PharmacyService } from "../../api/PharmacyService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { LabelText } from "../../util/TextConstant";
import {
  detailKmAllowanceIcon,
  detailAccomodationAllowanceIcon,
} from "../../util/Icons";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { RBContext } from '../../components/RBContext';

import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";

export const EditJobPage = (props:any) => {
  const { primaryColor,secondaryColor } = useContext(RBContext);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pharmacy, setPharmacy] = useState<any>(EmptyString);
  const [originalJobData, setOriginalJobData] = useState<ObjectLiteral>({});
  const [selectedWorkerType, setSelectedWorkerType] = useState(EmptyString);
  const [jobTitle, setJobTitle] = useState(EmptyString);
  const [workerTypes, setWorkerTypes] = useState([]);
  const [jobCategories, setJobCategories] = useState<any>([]);
  const [selectedJobCategory, setSelectedJobCategory] = useState<any>(EmptyString);

  const [wage, setWage] = useState<string>("0");
  const [wageInvalidErrorText, setWageInvalidErrorText] = useState(EmptyString);
  const [technicianOnSite, setTechnicianOnSite] = useState<boolean>(true);
  const [assistantOnSite, setAssistantOnSite] = useState<boolean>(true);
  const [softwareName, setSoftwareName] = useState<string>();
  const [selectedSoftware, setSelectedSoftware] = useState<SkillResponseDto>();
  const [softwareRequired, setSoftwareRequired] = useState<boolean>(false);
  const [workerType, setWorkerType] = useState<any>("pharmacist");
  const [specializations, setSpecializations] = useState<SkillResponseDto[]>(
    []
  );
  const [AllSpecializationSkills, setAllSpecializationSkills] = useState<any>([]);
  const [selectedSpecializations, setSelectedSpecializations] = useState<
    string[]
  >([]);
  const [workerTypesAndRates, setWorkerTypesAndRates] = useState<ObjectLiteral>([]);
  // update button is loading or not
  const [loading, setIsLoading] = useState(false);

  const [isEdited, setIsEdited] = useState(false);
  const match = useRouteMatch<{ jobId: string }>();
  const history = useHistory();
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [isCreateShiftSubmitInfo,setIsCreateShiftSubmitInfo] = useState(false)
  const [createShiftSubmitInfo,setCreateShiftSubmitInfo] = useState <any>({ title: "", message: "", buttons: [] })

  const [rateObject, setRateObject] = useState({
    hourlyRate: '0',
    hourlyRateDisplayValue: '0.00',
    isRateValid: true
  });
  const [comments, setComments] = useState('');
  const [dateObject, setDateObject] = useState<any>({
    estStartDate: moment().add(1, 'days').hours(12),
    estEndDate: null,
  });
  const [allowOtherProvince, setAllowOtherProvince] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pharmacyTimezoneOffset, setpharmacyTimezoneOffset] = useState<any>('');
  const [pharmacyTimezone, setpharmacyTimezone] = useState('');
  const getDateTime = (date: any) => {
    let currentDate = moment(date).tz(pharmacyTimezone)
    let year = currentDate.format('YYYY')
    let month = currentDate.format('MM')
    let day = currentDate.format('DD')
    let hour = currentDate.format('HH')
    let minute = currentDate.format('mm')
    let second = currentDate.format('ss')
    let convertedDate = moment(year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second);
    return convertedDate;
  }

  const convertDatetoLocationsTimezone = (currentDate:any,offsetString:any) =>{
    let year = currentDate.format('YYYY')
    let month = currentDate.format('MM')
    let day = currentDate.format('DD')
    let convertedDate = moment(moment(year + '-' + month + '-' + day).format('YYYY-MM-DDTHH:mm:ss') + offsetString);
    return convertedDate

  }

  const convertOffsetToString = (offset: any) => {
    let value = '';
    const absOffset = Math.abs(offset);
    const hr = absOffset / 60;
    const min = absOffset % 60;
    if (offset < 0) {
      value = value + '-';
    }
    else {
      value = value + '-';
    }
    if (hr < 10) {
      value = value + '0' + hr;
    }
    else {
      value = value + hr;
    }
    if (min < 10) {
      value = value + ':0' + min;
    }
    else {
      value = value + ':' + min;
    }
    return value;
  }

  useEffect(() => {
    const fetchWorkerTypeMinRates = async () => {
      try{
        const workerTypesAndRates = await AuthService.getRBJobWorkerTypeAndRate();
        console.log('workerTypesAndRates - 11', workerTypesAndRates)
        setWorkerTypesAndRates(workerTypesAndRates);
      }
      catch(err){
        setSubmitErrorMessage(err.response ? err.response.data : err.message);
      }
    }
    fetchWorkerTypeMinRates()
    
  }, [])
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsPageLoading(true);
        const jobDetailData = await ShiftService.getJobtDetailByJobId(
          match.params.jobId
        );

        setOriginalJobData(jobDetailData);
        const fetchSkillsData = await PharmacyService.fetchSkills(
            jobDetailData.pharmacistType
        );
        const workerTypesAndRates = await AuthService.getRBJobWorkerTypeAndRate();
        const jobCategories = await AuthService.getRBJobCategory();
        const workerTypes = await AuthService.getAllWorkers('Pharmaceutical');
        console.log('workerTypesAndRates2', workerTypesAndRates)
        setPharmacy(jobDetailData.pharmacy)
        setSelectedWorkerType(jobDetailData.pharmacistType);
        setSelectedJobCategory(jobDetailData.jobCategory.name)
        setAllowOtherProvince(jobDetailData.allowOtherProvince)
        setComments(jobDetailData.comment)
        setJobTitle(jobDetailData.jobTitle)

        setRateObject({
            hourlyRate: (jobDetailData.estHourlyRate / 100).toString(),
            hourlyRateDisplayValue: (jobDetailData.estHourlyRate / 100).toFixed(2),
            isRateValid: true,
          })
        setWage((jobDetailData.estHourlyRate / 100).toFixed(2))
        setDateObject({
            estStartDate: jobDetailData.estStartDate,
            estEndDate: jobDetailData.estEndDate,
          });

        setJobCategories(jobCategories);
        setWorkerTypesAndRates(workerTypesAndRates);
        setWorkerTypes(workerTypes)

        const fetchFullSkillsData = await PharmacyService.fetchSkills();
        setAllSpecializationSkills(fetchFullSkillsData.data.filter(
          (skill: ObjectLiteral) => skill.type === SkillType.specialization
        ))

        
        const pharmacyDetails = await AuthService.fetchPharmacy(jobDetailData.pharmacy.id);
     
        setpharmacyTimezoneOffset(moment().tz(pharmacyDetails.address.timezone).utcOffset());
        setpharmacyTimezone(pharmacyDetails.address.timezone);

        setWorkerType(jobDetailData.pharmacistType);
        setTechnicianOnSite(jobDetailData.technicianOnSite);
        setAssistantOnSite(jobDetailData.assistantOnSite);
        setSpecializations(
          fetchSkillsData.data.filter(
            (skill: ObjectLiteral) => skill.type === SkillType.specialization
          )
        );
        console.log("specializations",specializations)

        setSelectedSpecializations(
          jobDetailData.skills
            .filter(
              (skill: ObjectLiteral) => skill.type === SkillType.specialization
            )
            .map((skill: SkillResponseDto) => skill.id)
        );
        setSoftwareRequired(
          jobDetailData.skills
            .filter((skill: ObjectLiteral) => skill.type === SkillType.software)
            .map((skill: SkillResponseDto) => skill.required)[0]
        );
        setSelectedSoftware(
          jobDetailData.skills.filter(
            (skill: ObjectLiteral) => skill.type === SkillType.software
          ).length > 0
            ? jobDetailData.skills.filter(
              (skill: ObjectLiteral) => skill.type === SkillType.software
            )[0]
            : {}
        );
        setSoftwareName(
          jobDetailData.skills
            .filter((skill: ObjectLiteral) => skill.type === SkillType.software)
            .map((skill: SkillResponseDto) => skill.name)[0]
        );
        setWage((jobDetailData.estHourlyRate / 100).toFixed(2));
        setWorkerTypesAndRates(workerTypesAndRates);
        setIsPageLoading(false);
      } catch (err) {
        setSubmitErrorMessage(err.response ? err.response.data : err.message);
      } finally {
      }
    };

    fetchData();
  }, [match.params.jobId]);

  useEffect(() => {
    isWageValid();
  }, [wage]);
  useEffect(() => {
    if (Object.keys(originalJobData).length > 0) {
      checkIsJobEdited();
    }
  }, [
    wage,
    comments,
    softwareRequired,
    rateObject,
    selectedSpecializations,
    technicianOnSite,
    assistantOnSite,
    dateObject,
    allowOtherProvince,
    jobTitle,
    selectedJobCategory,
  ]);

  const checkIsJobEdited = () => {
    const originalHourlyRate = (originalJobData.estHourlyRate / 100).toFixed(2);
    const originalJobTitle = originalJobData.jobTitle;

    const originalSelectedSoftware = originalJobData.skills.filter(
      (skill:any) => skill.type === 'software',
    );
    let originalSoftwareRequired = false;
    if (originalSelectedSoftware.length > 0) {
      const software = originalSelectedSoftware[0];
      originalSoftwareRequired = software.required;
    }
    // const originalSelectedSpecializations = originalJobData.skills.filter(
    //   (skill:any) => skill.type === 'specialization',
    // );
    // const originalSelectedSpecializationsString = JSON.stringify(
    //   map(orderBy(originalSelectedSpecializations, 'id'), 'id'),
    // );
    // const selectedSpecializationsString = JSON.stringify(
    //   map(orderBy(selectedSpecializations, 'id'), 'id'),
    // );

    const originalSelectedSpecializations = originalJobData.skills.filter(
        (skill: SkillResponseDto) => skill.type === "specialization"
      );
      const originalSelectedSpecializationsString = JSON.stringify(
        map(orderBy(originalSelectedSpecializations, "id"), "id")
      );
      const selectedSpecializationsString = JSON.stringify(
        selectedSpecializations.sort()
      );

    const originalComment = originalJobData.comment;
    const originalEstStartDate = originalJobData.estStartDate;

    const originalEstEndDate = originalJobData.estEndDate;
    const isEdited2 =
      originalJobTitle !== jobTitle ||
      comments !== originalComment ||
      selectedJobCategory !== originalJobData.jobCategory.name ||
      technicianOnSite !== originalJobData.technicianOnSite ||
      assistantOnSite !== originalJobData.assistantOnSite ||
      allowOtherProvince !== originalJobData.allowOtherProvince ||
      wage !== originalHourlyRate ||
      softwareRequired !== originalSoftwareRequired ||
      selectedSpecializationsString !== originalSelectedSpecializationsString ||
      selectedWorkerType !== originalJobData.pharmacistType ||
      dateObject.estStartDate !== originalEstStartDate ||
      dateObject.estEndDate !== originalEstEndDate;

    console.log("originalJobTitle",originalEstStartDate)
    console.log("originalJobTitle1",dateObject.estStartDate)

    setIsEdited(isEdited2);
    return isEdited2;
  };
  const renderJobTime = () => {
    return (
      <>
        {/* {multipleShifts.map((shift) => ( */}
            <div
              style={{
                ...ColumnStyle, alignItems: "center"
              }}
            >
               <Typography style={{ display: "flex", flex: 1 }}>
               Est. Start Date
              </Typography>
              <RBEmptyBlock />
              <FormControl
                variant="outlined"
                size={"small"}
                style={{ display: "flex", flex: 1 }}
                >
                <DatePicker
                    selected={moment(dateObject.estStartDate).toDate()}
                    onChange={(date) => {
                    const offsetString = convertOffsetToString(pharmacyTimezoneOffset);
                    let currentDate = moment(date)
                    let year = currentDate.format('YYYY')
                    let month = currentDate.format('MM')
                    let day = currentDate.format('DD')


                    let convertedDate = moment(moment(year + '-' + month + '-' + day).format('YYYY-MM-DDTHH:mm:ss') + offsetString);
                    setDateObject({
                        estStartDate: convertedDate.hour(12),
                        estEndDate:dateObject.estEndDate,
                    });
                    }}
                    
                    minDate={tomorrow}
                    dateFormat="MMM do, yyyy"
                    customInput={
                    <input
                        style={{
                        paddingTop: Spacing.medium,
                        paddingBottom: Spacing.medium,
                        paddingRight: "30%",
                        borderRadius:5,
                        borderColor:"rgb(220 220 220)",
                        width: 409,
                        fontSize:16,
                        paddingLeft:10 
                        }}
                        key={`dateTime`}
                    />
                    }
                    withPortal
                />
              </FormControl>
            </div>
            <div
              style={{
                ...ColumnStyle, alignItems: "center"
              }}
            >
               <Typography style={{ display: "flex", flex: 1 }}>
               Est. End Date
              </Typography>
              <RBEmptyBlock />
              <FormControl
                variant="outlined"
                size={"small"}
                style={{ display: "flex", flex: 1 }}
                >
              <DatePicker
                selected={dateObject.estEndDate !== null ? moment(dateObject.estEndDate).toDate(): null}
                onChange={(date) => {
                  const offsetString = convertOffsetToString(pharmacyTimezoneOffset);
                  let currentDate = moment(date)
                  let year = currentDate.format('YYYY')
                  let month = currentDate.format('MM')
                  let day = currentDate.format('DD')


                  let convertedDate = moment(moment(year + '-' + month + '-' + day).format('YYYY-MM-DDTHH:mm:ss') + offsetString);
                  setDateObject({
                    estStartDate: dateObject.estStartDate,
                    estEndDate: convertedDate.hour(12)
                  });
                }}
                minDate={moment(dateObject.estStartDate).toDate()}
                dateFormat="MMM do, yyyy"
                customInput={
                  <input
                    style={{
                      paddingTop: Spacing.medium,
                      paddingBottom: Spacing.medium,
                      paddingRight:"45%",
                      borderRadius:5,
                      borderColor:"rgb(220 220 220)",
                      width: 409,
                      fontSize:16,
                      paddingLeft:10
                    }}
                    key={`dateTime`}
                  />
                }
                placeholderText="Optional"
                withPortal
              />
             </FormControl>
            </div>
        {/* ))} */}
      </>
    );
  };

  const renderPharmacyName = () => {
    const pharmacyName = pharmacy ? pharmacy.name : 'N/A';
    return (
      <Typography variant="h6" style={{ textAlign: "left" }}>
        {pharmacyName}
      </Typography>
    );
  };


  const renderTitle = () => {
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" ,paddingTop: Spacing.medium}}>
          <Typography style={{ display: "flex", flex: 1 }}>
            Title
          </Typography>
          <RBEmptyBlock />
          <FormControl
            variant="outlined"
            size={"small"}
            style={{ display: "flex", flex: 1 }}
          >
            <RBTextField
              className="custom-focused-input"
              style={{
                display: "flex",
                flex: 1,
                maxHeight: "40px",
                borderColor:"black"
              }}
              
              placeholder={"Optional"}
              value={jobTitle}
              onChange={(event) => setJobTitle(event.target.value)}
            />
          </FormControl>
        </div>
      </>
    );
  };

  const  renderJobType = () => {
    return (
      <div style={{ ...ColumnStyle, alignItems: "center" }}>
        <Typography style={{ display: "flex", flex: 1 }}>
          Job Type
        </Typography>
        <RBEmptyBlock />
        <FormControl
          variant="outlined"
          size={"small"}
          style={{ display: "flex", flex: 1 }}
        >
         <Select
          value={selectedJobCategory}
          displayEmpty
          renderValue={selectedJobCategory !== "" ? undefined : () => "Select"}
          onChange={(event) => setSelectedJobCategory(event.target.value)}>
          {jobCategories.map((value:any) => (
            <MenuItem value={value.name} key={value.name}>
              {value.name}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </div>
    );
  };

  const updateWorkerType = async (event: any) => {
    setWorkerType(event.target.value);
    setWage("0")
    setSpecializations(
        AllSpecializationSkills.filter(
          (skill:any) => skill.pharmacistType === event.target.value
        )
      );
    setSelectedSpecializations([])
    setRateObject({
      hourlyRate: '0',
      hourlyRateDisplayValue: '0.00',
      isRateValid: true,
    });
  };

  const renderWorkerType = () => {
    return (
      <div style={{ ...ColumnStyle, alignItems: "center" }}>
        <Typography style={{ display: "flex", flex: 1 }}>
          Worker Type
        </Typography>
        <RBEmptyBlock />
        <FormControl
          variant="outlined"
          size={"small"}
          style={{ display: "flex", flex: 1 }}
        >
         <Select
          value={workerType}
          displayEmpty
          renderValue={workerType !== "" ? undefined : () => "Select"}
          onChange={updateWorkerType}>
          {workerTypes.map((value:any) => (
            <MenuItem value={value.workerType} key={value.workerType}>
              {value.displayName}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </div>
    );
  };

  const onSpecializationSkillSelectedChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    // this is a multiple select, so event.target.value will be an array
    // console.log("onSpecializationSkillSelectedChange", event.target.value);
    setSelectedSpecializations(event.target.value as string[])
  };
  const renderSkills = () => {
    return (
      <>
      <div style={{ ...ColumnStyle, alignItems: "center" }}>
        <Typography style={{ display: "flex", flex: 1 }}>
          Specialization Skills
        </Typography>
        <RBEmptyBlock />
        <FormControl
          variant="outlined"
          size={"small"}
          style={{ display: "flex", flex: 1 }}
        >
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedSpecializations}
            onChange={onSpecializationSkillSelectedChange}
            displayEmpty
            renderValue={(selected) => {
              if ((selected as string[]).length === 0) {
                return <em>Add Skills</em>;
              }
              return (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {(selected as string[]).map((skillID) => {
                    return (
                      <div key={skillID} style={{ ...skillTextStyle }}>
                        {
                          specializations.filter(
                            (specialization) => specialization.id === skillID
                          )[0]?.name
                        }
                      </div>
                    );
                  })}
                </div>
              );
            }}
          >
            {specializations.map((specialization) => (
              <MenuItem key={specialization.id} value={specialization.id}>
                <Checkbox
                  checked={
                    selectedSpecializations.indexOf(specialization.id) > -1
                  }
                  style={{
                    color:
                      selectedSpecializations.indexOf(specialization.id) > -1
                        ? secondaryColor
                        : Colors.darkGrey,
                  }}
                />
                <ListItemText primary={specialization.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div
      style={{
        ...ColumnStyle,
        alignItems: "center",
        marginTop: -Spacing.small,
      }}
    >
      <Typography style={{ display: "flex", flex: 1 }}></Typography>
      <RBEmptyBlock />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          fontSize: 12,
          textAlign: "start",
        }}
      >
        *Select only mandatory skills
      </div>
    </div>
    </>
    );
  };
  const renderPharmacySpecific = () => {
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <Typography style={{ display: "flex", flex: 1 }}>
            Pharmacy Software
          </Typography>
          <RBEmptyBlock />
          <FormControlLabel
            style={{
              display: "flex",
              flex: 1,
              color: softwareRequired
                ? secondaryColor
                : Colors.darkGrey,
            }}
            control={
              <Checkbox
                checked={softwareRequired}
                style={{
                  color: softwareRequired
                    ? secondaryColor
                    : Colors.darkGrey,
                }}
                onChange={() =>
                  setSoftwareRequired(!softwareRequired)
                }
              />
            }
            label={<Typography>{softwareName}</Typography>}
          />
        </div>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            marginTop: -Spacing.large,
          }}
        >
          <Typography style={{ display: "flex", flex: 1 }}></Typography>
          <RBEmptyBlock />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              fontSize: 12,
              textAlign: "start",
            }}
          >
            {Claims.pharmacySoftwareClaimJob}
          </div>
        </div>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
          }}
        >
          <Typography style={{ display: "flex", flex: 1 }}>
            {LabelText.TECHNICIAN_ON_SITE}
          </Typography>
          <RBEmptyBlock />
          <FormControlLabel
            style={{
              display: "flex",
              flex: 1,
            }}
            control={
              <Checkbox
                checked={technicianOnSite}
                style={{
                  color: technicianOnSite
                    ? secondaryColor
                    : Colors.darkGrey,
                }}
                onChange={() =>
                    setTechnicianOnSite(!technicianOnSite)
                }
              />
            }
            label={EmptyString}
          />
        </div>
        {workerType !== workerTypeEnum.assistant && (
          <>
          <div
            style={{
              ...ColumnStyle,
              alignItems: "center",
            }}
          >
            <Typography style={{ display: "flex", flex: 1 }}>
              {LabelText.ASSISTANT_ON_SITE}
            </Typography>
            <RBEmptyBlock />
            <FormControlLabel
              style={{
                display: "flex",
                flex: 1,
              }}
              control={
                <Checkbox
                  checked={assistantOnSite}
                  style={{
                    color: assistantOnSite
                      ? secondaryColor
                      : Colors.darkGrey,
                  }}
                  onChange={() =>
                    setAssistantOnSite(!assistantOnSite)
                  }
                />
              }
              label={EmptyString}
            />
          </div>
          
          </>
        )}
        
      </>
    );
  };

  const isRateValid = () => {
    const lowerBound = getMinimumHourlyRate();
    const rateAmount = parseFloat(wage);
    const result = rateAmount >= lowerBound && isFinite(rateAmount);
    setRateObject({
      ...rateObject,
      isRateValid: result,
    });
    return result;
  };
  const renderWage = () => {
    return (
      <>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            paddingBottom: Spacing.smallest,
          }}
        >
          <Typography style={{ display: "flex", flex: 1 }}>
          Est. Hourly Rate
          </Typography>
          <RBEmptyBlock />
          <RBMoneyAmountTextField
            name="currency"
            value={wage}
            onChange={(event) =>
                {
                    setWage( event.target.value == "0.00" ? "0" : event.target.value)
                    isRateValid();
                }
            }
            error={wageInvalidErrorText.length > 0}
            errorText={wageInvalidErrorText}
          />
        </div>
       


      </>
    );
  };

  const renderJobDesc = () => {
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <Typography style={{ display: "flex", flex: 1 }}>Job Description*</Typography>
        </div>
        <RBTextField
          style={{
            display: "flex",
            flex: 1,
            paddingTop: Spacing.small,
            paddingBottom: Spacing.small,
          }}
          placeholder={"*Please include a description and important details about the job."}
          value={comments}
          onChange={(event) => setComments(event.target.value )}
          multiline
          rows={3}
          rowsMax={8}
        />
         <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            paddingBottom: Spacing.smallest,
          }}
        >
          <Typography style={{ display: "flex", flex: 1 }}>
          Allow applicants from other provinces?
          </Typography>
          <RBEmptyBlock />
          <FormControlLabel
              style={{
                display: "flex",
                flex: 1,
              }}
              control={
                <Checkbox
                  checked={allowOtherProvince}
                  style={{
                    color: allowOtherProvince
                      ? secondaryColor
                      : Colors.darkGrey,
                  }}
                  onChange={() =>
                    setAllowOtherProvince(!allowOtherProvince)
                  }
                />
              }
              label={EmptyString}
            />
        </div>
      </>
    );
  };
  const getMinimumHourlyRate = () => {
    return workerTypesAndRates.filter((w:any) => w.workerType === workerType)
      .length > 0
      ? workerTypesAndRates.filter((w:any) => w.workerType === workerType)[0]
          .minimumRate / 100
      : 100;
  };
  const isWageValid = () => {
    const getMinimumWage = () => {
        return workerTypesAndRates.filter((w: any) => w.workerType === workerType)
          .length > 0
          ? workerTypesAndRates.filter((w: any) => w.workerType === workerType)[0]
            .minimumRate / 100
          : 100;
      };
      const lowerBound = getMinimumWage();
  
      setWageInvalidErrorText(
        Number(wage) >= lowerBound
          ? EmptyString
          : `The minimum rate is $${lowerBound.toFixed(2)}`
      );
  };

 
  const isFormValid = () => {
    const isEstDatesValid = dateObject.estEndDate
      ? moment(dateObject.estStartDate).diff(dateObject.estEndDate) < 0
      : true;
    return (
      rateObject.isRateValid &&
      selectedWorkerType !== '' &&
      selectedJobCategory !== '' &&
      isEstDatesValid
    );
  };
  const onUpdateJob = async () => {
    const originalEstStartDate = originalJobData.estStartDate;

    const originalEstEndDate = originalJobData.estEndDate;

    
    if(originalEstStartDate === dateObject.estStartDate)
    {
      const offsetString = convertOffsetToString(pharmacyTimezoneOffset)
      let convertedDate = convertDatetoLocationsTimezone(moment(dateObject.estStartDate),offsetString); 
      dateObject.estStartDate = convertedDate.hours(12);
    }
    if(originalEstEndDate !== null && originalEstEndDate === dateObject.estEndDate){
      const offsetString = convertOffsetToString(pharmacyTimezoneOffset)
      let convertedDate = convertDatetoLocationsTimezone(moment(dateObject.estEndDate),offsetString); 
      dateObject.estEndDate = convertedDate.hours(12);
    }

    if (!isFormValid()) {
        setCreateShiftSubmitInfo({
            title: "Unable to update Job",
            message: "Please double check the dates",
            buttons: [
    
              <RBButton
                buttonBackgroundColor={primaryColor}
                key={"Discard"}
                onClick={() => {
                  setIsCreateShiftSubmitInfo(false)
                }}
                color="primary"
                text="Ok"
              />,
            ],
          })
          setIsCreateShiftSubmitInfo(true)

        return;
    }
    if (!isWageValid) {
      return;
    }
    setIsLoading(true);
    setIsSubmitting(true);
    // Build the skills list and add the software to the end
    const skills = selectedSpecializations.map((skillId) => {
      const skill = specializations.filter(
        (specialization) => specialization.id === skillId
      )[0];
      return {
        skillId: skill.id,
        pharmacistType: skill.pharmacistType,
        required: true,
        name: skill.name,
        type: skill.type,
      };
    });
    if (selectedSoftware && Object.keys(selectedSoftware).length > 0) {
      skills.push({
        skillId: selectedSoftware.id,
        pharmacistType: selectedSoftware.pharmacistType,
        required: softwareRequired,
        name: selectedSoftware.name,
        type: selectedSoftware.type,
      });
    }

   
    const payload = {
      id: originalJobData.id,
      jobTitle,
      jobCategoryId:
        jobCategories.filter((category:any) => category.name === selectedJobCategory)
          .length > 0
          ? jobCategories.filter(
              (category:any) => category.name === selectedJobCategory,
            )[0].id
          : null,
      jobCategoryName: selectedJobCategory,
      comment: comments,
      technicianOnSite,
      assistantOnSite,
      allowOtherProvince,
      estHourlyRate: (Number(wage) * 100),
      estStartDate: moment(dateObject.estStartDate),
      estEndDate: moment(dateObject.estEndDate),
      skills: skills,
      pharmacistType: workerType,
    };

    try {
      const updatedJob =  await PharmacyService.updateJob(payload);
      setIsLoading(false);

      setCreateShiftSubmitInfo({
        title: "Job Updated",
        message: "Your job has been updated.",
        buttons: [

          <RBButton
            buttonBackgroundColor={primaryColor}
            key={"Discard"}
            onClick={() => {
              setIsCreateShiftSubmitInfo(false)
              history.push("/Job/list")
            }}
            color="primary"
            text="Ok"
          />,
        ],
      })
      setIsCreateShiftSubmitInfo(true)
    } catch (err) {
    //   const errorMessage = err.response ? err.response.data.message : err.message;
    //   setSubmitErrorMessage(errorMessage);
    //   console.log("update shift on submit err", JSON.stringify(err, null, 2));

    if (err && err.response && err.response.data) {
        const unprocessableError = err.response.data;

        if (
          unprocessableError.error?.includes(
            'There are requests for this job already',
          )
        ) {

            setCreateShiftSubmitInfo({
                title: "Unable to update Job",
                message: "There are requests for this job already. You can not edit this job anymore.",
                buttons: [
        
                  <RBButton
                    buttonBackgroundColor={primaryColor}
                    key={"Discard"}
                    onClick={() => {
                      setIsCreateShiftSubmitInfo(false)
                      history.push("/Job/list")
                    }}
                    color="primary"
                    text="Ok"
                  />,
                ],
              })
              setIsCreateShiftSubmitInfo(true)

        } else {

            setCreateShiftSubmitInfo({
                title: "Unable to update Job",
                message: "An error has occurred updating job, please try again later.",
                buttons: [
        
                  <RBButton
                    buttonBackgroundColor={primaryColor}
                    key={"Discard"}
                    onClick={() => {
                      setIsCreateShiftSubmitInfo(false)
                      history.push("/Job/list")
                    }}
                    color="primary"
                    text="Ok"
                  />,
                ],
              })
              setIsCreateShiftSubmitInfo(true)

        }
      } else {
        setCreateShiftSubmitInfo({
            title: "Unable to update Job",
            message: `${JSON.stringify(err, null, 2)}.`,
            buttons: [
    
              <RBButton
                buttonBackgroundColor={primaryColor}
                key={"Discard"}
                onClick={() => {
                  setIsCreateShiftSubmitInfo(false)
                  history.push("/Job/list")
                }}
                color="primary"
                text="Ok"
              />,
            ],
          })
          setIsCreateShiftSubmitInfo(true)

      }

    } finally {
      setIsLoading(false);
    }
  };
  const renderActions = () => {
    console.log("edit shift renderActions");
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <RBButton
          // onClick={() => {
          //   doAction(ShiftRelatedConstants.ShiftButtonText.CancelShift);
          // }}
          onClick={() => onUpdateJob()}
          isLoading={loading}
        //   disabled={!isFormValid()}
        disabled={!isEdited}
          text="Update Job"
          style={{
            flex: 1,
            padding: Spacing.large,
            marginLeft: Spacing.large,
            marginRight: Spacing.large,
          }}
          buttonBackgroundColor={primaryColor}
        />
      </div>
    );
  };
  const tomorrow = moment()
  .add(1, 'day')
  .toDate();
  return (
    <>
      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={JSON.stringify(submitErrorMessage, null, 2)}
        onClose={() => {
          setSubmitErrorMessage({ submitErrorMessage: undefined });
        }}
      />
      <RBAlert
        show={isCreateShiftSubmitInfo}
        alertTitle={createShiftSubmitInfo.title}
        alertMessage={createShiftSubmitInfo.message}
        buttons={createShiftSubmitInfo.buttons}
      />
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <RBCardView style={{ margin: Spacing.large, padding: Spacing.large }}>
            {renderPharmacyName()}
            {renderTitle()}
            {renderJobType()}
            {renderJobTime()}
            {renderWorkerType()}
            <div style={{ marginTop: Spacing.small }}>
              {renderSkills()}
            </div>
            {renderPharmacySpecific()}
            {renderWage()}
            {renderJobDesc()}
          </RBCardView>
          {renderActions()}
        </>
      )}
    </>
  );
};
