import React, { useState, useEffect ,useContext} from "react";

import { useLocation, useHistory } from "react-router";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum, formatPaymentType, paymentTypeEnum } from "../../api/constants";
import { Button, Card, CardContent, createStyles, makeStyles } from "@material-ui/core";
import { AuthService } from "../../api/AuthService";
import { Colors, Font, FontSize, FontWeight, Spacing, UIRelatedConstants } from "../../theme/Theme";
import { CreditCard, AccountBalance } from "@material-ui/icons";
import { RBAppbar } from "../../components/RBAppbar";
import { PharmacyService } from "../../api/PharmacyService";
import { RBAlert } from "../../components/RBAlert";
import { RBButton } from "../../components/RBButton";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { RBCardView } from "../../components/RBCardView";


interface PaymentPageProps {
    account?: any;
    paymentSetup?: any;
    editMode?:any;
    newPharmacy?:any;
}

interface LocationState {
    account?: string;
    editMode?: boolean;
    newPharmacy?: boolean;
  }

export const AddmultiplelocationsJobPayment: React.FC<PaymentPageProps> = ({
    ...props
  }) => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { account, editMode, newPharmacy } = location.state || {};

  const { industryMode, primaryColor, secondaryColor } = useContext(RBContext);
  const [locationsVisible, setLocationsVisible] = useState(false);
  const [locationArr, setLocationArr] = useState<any>([]);
  const [selectedLoc, setSelectedLoc] = useState<any>(null);
  const [newPaymentType, setNewPaymentType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pharmacyDetails, setPharmacyDetails] = useState<any>([]);
  const [isAlertShowing, setIsAlertShowing] = useState(false);
  const [alertInfo,setAlertInfo]  = useState <any> ({title:"",message:"", buttons:[]})
  const [showPopup, setShowPopup] = useState(false);
  const [availablePaymentInfo, setAvailablePaymentInfo] = useState<any>(null);

  const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textTransform: "none",
      // color: Colors.black,
      "&:hover": {
        color: Colors.themeBlue,
        opacity: 1,
      },
      "&$selected": {
        color: Colors.black,
        fontWeight: "600",
      },
      "&:focus": {
        color: Colors.black,
      },
    },
    selected: {
      //color: Colors.themeBlue,
    },
    indicator: {
      backgroundColor: Colors.black,
    },
    sectionHeader: {
      fontSize: Font.largest, fontWeight:"bold"
      //  backgroundColor: "red",
    },
    Card: {
      // "&:hover": {
      //   backgroundColor: "#E5E5E5",
      // },
    },
    CardContent: {
      paddingTop: 8,
      "&:last-child": {
        paddingBottom: 8,
      },
    },
    button: {
      borderRadius: UIRelatedConstants.ButtonBorderRadius,
      borderWidth: 1,
      backgroundColor: "#FFF",
      "&:hover": {
        backgroundColor: "#CDCDCD",
      },
    },
    // Custom radio button styles
    radioContainer: {
        position: 'relative',
        padding: '10px', // Adjust padding as needed
        cursor: 'pointer',
      },
      radioInput: {
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
      },
      radioCheckmark: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        width: '20px', // Adjust the size of the tick mark
        height: '20px', // Adjust the size of the tick mark
        border: '2px solid #000', // Set the tick mark color
        borderTop: 'none',
        borderRight: 'none',
        boxSizing: 'border-box',
      },
      radioChecked: {
        backgroundColor: '#000', // Set the tick mark color when checked
      },
  })
  );

  const classes = useStyles();
  
  useEffect(() => {
    (async () => {

    let accountData = props.account? props.account : account;
      let pharmaDetails = await AuthService.fetchPharmacy(accountData.owner.defaultPharmacyId);
      setPharmacyDetails(pharmaDetails)
      setSelectedLoc(pharmaDetails)

      let selectedLocation:any;
      selectedLocation = pharmaDetails;
      const filteredLocations = accountData.pharmacies.filter((location:any) => location.id !== selectedLocation.id);
      const reorderedLocations = [selectedLocation, ...filteredLocations];

      reorderedLocations.forEach((value, index) => {
        reorderedLocations[index].checked = false;
        if(reorderedLocations[index] === selectedLocation){
          reorderedLocations[index].checked = true;
        }
      });

      setLocationArr(reorderedLocations)
      if(!editMode)
      {
        setNewPaymentType(true)
      }
      
    })();

  }, [])
  useEffect(() => {
    (async () => {
      if(props?.account?.newPharmacy  === true || newPharmacy === true)
      {

        let accountData = props.account? props.account : account;
        setSelectedLoc(accountData.pharmacies[accountData.pharmacies.length-1])
        const selectedLocation = accountData.pharmacies[accountData.pharmacies.length-1];
        const filteredLocations = accountData.pharmacies.filter((location:any) => location !== selectedLocation);
        const reorderedLocations = [selectedLocation, ...filteredLocations];

        reorderedLocations.forEach((value, index) => {
          reorderedLocations[index].checked = false;
          if(reorderedLocations[index] === selectedLocation){
            reorderedLocations[index].checked = true;
          }
        });

        setNewPaymentType(true)
        setLocationArr(reorderedLocations)
      }
    })();

  }, [props?.account?.newPharmacy || newPharmacy])




  const handleRadioButtonChange = (index:number) => {
    setSelectedLoc(locationArr[index]);
  }
  const choosePaymentOption = (type:any) =>{
    const { account } = props;
    let editMode = type == 'add' ? false : true;
    history.push('/paymentOption',{
        editMode: editMode,
        selectedLoc:selectedLoc
      });
  }

  const saveInvoicePayment = async (type:any,copyPayment:any) => {
    const pharmacy = selectedLoc;
    try {
      //setLoading(true);
      if(copyPayment){
        await PharmacyService.createPAD({ locationId: pharmacy.id, paymentType: type,paymentSetupType:'job',copyPayment:copyPayment });
      }
      else{
        await PharmacyService.createPAD({ locationId: pharmacy.id, paymentType: type,paymentSetupType:'job'});
      }
      setIsAlertShowing(true);
      setAlertInfo({
        title: "Payment info Saved",
        message: `Your payment information has been saved`,
        buttons: [
          <RBButton
            key={"confirm"}
            onClick={async () => {
              setIsAlertShowing(false);
              history.push('/dashboard');
            }}
            buttonBackgroundColor={primaryColor}
            color="primary"
            text="Go Home"
          />,
        ],
      });
     
    } catch (error) {
      setIsAlertShowing(true);
      setAlertInfo({
        title: "Payment info Error",
        message: `Unable to Validate Payment Information`,
        buttons: [
          <RBButton
            key={"confirm"}
            onClick={async () => {
              setIsAlertShowing(false);
              history.push('/dashboard');
            }}
            buttonBackgroundColor={primaryColor}
            color="primary"
            text="Go Home"
          />,
        ],
      });
    } finally {
      setLoading(false);
    }
    
  }
  const closePopup = () =>{
    setShowPopup(false)
  }
  const renderPaymentPopup = () => {
    const pharmacy =  selectedLoc;
    return(
      <>
        <RBModal open={showPopup}
           onClose={closePopup}
           modalTitle={""}
           header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
          children={
              renderPaymentPopupModal()
          }
        />
      </>
    )
}

const goToJobCreditCardSetup = () => {
    let accountData = props.account? props.account : account;
    accountData.payType = "Job";

    const selectedLocation = [selectedLoc];
    const pharmacy = selectedLoc;
    if (accountData.owner) {
      //Actions.paymentCreditInput({ account });
      if(pharmacy && pharmacy.creditCards && pharmacy.creditCards.length > 0){
        
        if(pharmacy.creditCards.length > 1)
        {
          if (pharmacy.creditCards[0].paymentCardId === pharmacy.creditCards[1].paymentCardId) {
            setShowPopup(false)
           // Actions.paymentCreditInput({ account,selectedLocation });
           history.push('/paymentCreditInput',{
            payType: 'Job',
            selectedLocation:selectedLocation
          });
          } 
          else {
            let paymentInfoArr = pharmacy.creditCards.find((x:any) => x.category == 'shift')
            if(paymentInfoArr){
              setShowPopup(true)
              setAvailablePaymentInfo(paymentInfoArr)
            }
            else{
              setShowPopup(false)
              history.push('/paymentCreditInput',{
                payType: 'Job',
                selectedLocation:selectedLocation
              });
            }
          }
        }
        else{
          if(pharmacy.creditCards[0].category == 'shift')
          {
            setShowPopup(true)
            setAvailablePaymentInfo(pharmacy.creditCards.find((x:any) =>x.isDefault));
          }
          else{
            setShowPopup(false)
            history.push('/paymentCreditInput',{
              payType: 'Job',
              selectedLocation:selectedLocation
            });
          }
         
        }
      }
      else{
        setShowPopup(false)
        history.push('/paymentCreditInput',{
          payType: 'Job',
          selectedLocation:selectedLocation
        });
      }
    }
}

const renderPaymentPopupModal = () => {

    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
  
        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
                <div>
                  You have the credit card ending in {availablePaymentInfo?.lastFourDigits} set up. Do you want to use this card?
                
                </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "flex-end",
            }}
          >
  
            <RBButton
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
              buttonBackgroundColor={secondaryColor}
              onClick={() => {
                setShowPopup(false)
                saveInvoicePayment(paymentTypeEnum.creditCard, availablePaymentInfo?.category == 'shift' ? true : false)
              }}
              text="Yes"
            />
            <RBButton
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
              buttonBackgroundColor={secondaryColor}
              onClick={() => {
                setShowPopup(false);
                const selectedLocation = [selectedLoc];
                history.push('/paymentCreditInput', {
                  selectedLocation: selectedLocation,
                  payType: 'Job'
                });
  
              }}
              text="No, Add New"
            />
          </div>
            
          </RBCardView>
       
      </div>
    );
  };
  

  const renderLocations = () =>{
    return(
      <div style={{ flex: 1 }}>
              {locationArr.map((location:any, index:number) => {
                let defaultPayment = null;
                  if (location.creditCards && location.creditCards.length > 0) {
                    defaultPayment = location.creditCards.find((x:any) => x.category == 'job');
                  }
                return (
                    
                <div
                style={{
                  width: "100%",
                  paddingLeft: Spacing.large,
                  paddingRight: Spacing.large,
                  paddingBottom: Spacing.medium,
                  paddingTop: Spacing.large
                }}
                onClick={() => handleRadioButtonChange(index)}
              >
                <Card
                  variant="outlined"
                  className={classes.Card}
                  style={{
                    borderRadius: UIRelatedConstants.CardBorderRadius,
                    borderWidth: 3,
                    borderColor: primaryColor,
                  }}
                >
                     <CardContent className={classes.CardContent}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingTop: 10,
                            justifyContent: "space-between",
                            minHeight: 45,
                        }}
                    >
                        <div style={{ justifyContent: "center" }}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {locationArr.length > 1 &&
                                     <label>
                                        <input
                                        type="radio"
                                        id={`paymentOptionId-${index}`}
                                        name="paymentOption"
                                        value={index}
                                        checked={selectedLoc === locationArr[index]}
                                        required
                                        onChange={() => handleRadioButtonChange(index)}
                                        className={classes.radioInput}
                                        style={{ display: 'none' }}
                                    />
                                     <div style={{color:secondaryColor,fontSize:18, fontWeight:"bold"}}>
                                        {selectedLoc === locationArr[index] ? '✓' : ''}
                                    </div>
                                    </label>}

                                <label htmlFor={`paymentOptionId-${index}`} style={{ marginLeft: 8, fontWeight: 'bold', fontSize: 16, paddingLeft: locationArr.length > 1 ? 0 : Spacing.medium }}>
                                    {location.name}
                                </label>
                            </div>
                        </div>

                        <div
                            style={{ alignItems: "flex-end" }}

                        >
                            <div>
                                {defaultPayment == null ?
                                    <Button
                                        className={classes.button}
                                        disabled={selectedLoc !== locationArr[index]}
                                        variant="outlined"
                                        style={{
                                            borderColor: secondaryColor,
                                            color: Colors.white,
                                            textTransform: "none",
                                            background: secondaryColor
                                        }}
                                        onClick={() => goToJobCreditCardSetup()}
                                    >
                                        {"Add"}
                                    </Button> :
                                    <Button
                                        className={classes.button}
                                        disabled={selectedLoc !== locationArr[index]}
                                        variant="outlined"
                                        style={{
                                            borderColor: secondaryColor,
                                            color: Colors.white,
                                            textTransform: "none",
                                            background: secondaryColor
                                        }}
                                        onClick={goToJobCreditCardSetup}
                                    >
                                        {"Edit"}
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                     
                    {defaultPayment &&
                    <div style={{ marginLeft: 50,marginBottom:15 }}>
                        <>
                           <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
                            <CreditCard />
                            <div style={{ flex: 1, fontWeight: 'bold', paddingLeft: 10 }}>
                              {formatPaymentType(paymentTypeEnum.creditCard)}
                            </div>
                          </div>

                          <div style={{  paddingLeft: 34, }}>
                            {defaultPayment.type} ending with {defaultPayment.lastFourDigits}
                          </div>
                          <div style={{ marginTop: 4, paddingLeft: 34 }}>
                            Expires: {defaultPayment.expiryMonth}/{defaultPayment.expiryYear}
                          </div>
                        </>
                      

                    </div>}
                    </CardContent>

                  </Card>
                  </div>
                )
              })}
            </div>
    )
  }
  const renderContent = () => {
    const { account } = props;
    return (
      <>
      {!props.paymentSetup ? 
        <div>
        <div style={{ padding: 16 }}>
          <div
            style={{ flex: 1, flexDirection: 'row', alignContent: 'center' }}
          >

            {renderLocations()}
          </div>
        </div>
      </div>
      :
      <div>
        {renderLocations()}
      </div>

      }
      </>
    );
  }

  
  return (

      <div style={{ flex: 1 }}>
            <RBAlert
            show={isAlertShowing}
            alertTitle={alertInfo.title}
            alertMessage={alertInfo.message}
            buttons={alertInfo.buttons}
            />
          {!props.paymentSetup ?

              <div>
                   <RBAppbar
                    deeperLevel={true}
                    barName={"Choose Location to Set-up Payment"}
                  />
                  {renderContent()}
              </div>
              :
              renderContent()

          }
          {renderPaymentPopup()}
      </div>
  );
}
