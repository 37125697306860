import React from "react";
import { ShiftProps } from "../../../interfacesProps/ShiftProps";
import { ShiftService } from "../../../api/ShiftService";
import moment from "moment";
import { RenderTextWithTitle } from "../../../components/TextWithHeader";
import { Colors, Font, FontSize, Spacing } from "../../../theme/Theme";
import { roleNameEnum } from "../../../util/Common";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import RBPlacementFeeModal from "../../../components/RBPlacementFeeModal";
import { paymentTypeEnum } from "../../../util/Enumeration";


interface BillInformationProps {
  account:ObjectLiteral;
  industryMode:string;
  shiftData: ShiftProps;
  roleName: string;
}

export const BillInformation: React.FC<BillInformationProps> = ({
  ...props
}) => {
  const { shiftData, roleName, account } = props;
  const kmAllowanceAvailable = shiftData.kmAllowance > 0;
  const accomodationAllowanceAvailable = shiftData.accomodationAllowance > 0;

  let wageAmount = 0;
  let allowanceAmount = 0;
  let commissionAmount = 0;
  let ownerWageAmount = 0;
  let workerWageAmount = 0;
  let hourlyRate = '';
  let workerBenefit = 0;

  const renderWageInformation = () => {
   // const hourlyRate = (ShiftService.getWage(shiftData) / 100).toFixed(2);
    let actualhoursDisplay = ShiftService.getShiftOfficialLengthDisplay(
      shiftData
    );
    if(shiftData.actualCommission && (shiftData.unpaidBreakMinutes || shiftData.disputeUnpaidBreakMinutes)){
      actualhoursDisplay = ShiftService.getShiftUnpaidBreakLengthDisplay(shiftData);
    }
    let minutesWorked = moment(shiftData.officialEndTime).diff(
      moment(shiftData.officialStartTime),
      'minutes',
    );
      // debugger
    if(!shiftData.actualCommission)
    {
      ownerWageAmount = Math.ceil(
        ShiftService.getWage(shiftData, account) * (minutesWorked / 60)
      );
      workerWageAmount = Math.floor(
        ShiftService.getWage(shiftData, account) * (minutesWorked / 60),
      );
      hourlyRate = (ShiftService.getWage(shiftData, account) / 100).toFixed(2);
    }
    else{
     
      if(shiftData.disputeResolution === 'pharmacy'){
        minutesWorked = minutesWorked - shiftData.disputeUnpaidBreakMinutes
      }
      else{
          minutesWorked = minutesWorked - shiftData.unpaidBreakMinutes
      }
      
      ownerWageAmount = 
          Math.ceil(
            shiftData.wage * (minutesWorked / 60)
          );
      workerWageAmount = Math.ceil(
        shiftData.wage * (minutesWorked / 60)
          );
      hourlyRate = (shiftData.wage / 100).toFixed(2);

    }
   

   wageAmount =
      account.roleName === 'owner' ? ownerWageAmount : workerWageAmount;



    // const wageAmount = (
    //   Math.ceil(ShiftService.getWage(shiftData) * (minutesWorked / 60)) / 100
    // ).toFixed(2);
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: 100 }}>
          {RenderTextWithTitle("Hourly Rate", "$" + hourlyRate)}
        </div>
        <div style={{ width: 120 }}>
          {RenderTextWithTitle("Duration Paid", actualhoursDisplay)}
        </div>
        <div
          style={{
            width: 100,
            alignItems: "flex-start",
          }}
        >
          {/* {RenderTextWithTitle("Amount", "$" + (wageAmount / 100).toFixed(2))} */}
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowWrap: "anywhere",
              alignItems: "flex-end",
              alignContent: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflowWrap: "anywhere",
                color: Colors.darkGrey,
                fontSize: FontSize.default,
              }}
            >
              Amount
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontWeight: "bold",
                fontSize: FontSize.large,
              }}
            >
              {"$" + (wageAmount / 100).toFixed(2)}
            </div>
          </div>

        </div>
      </div>
    );
  };
  const renderOPAInformation = () => {
    console.log("shiftData",shiftData)
    let commissionWage = Math.ceil(ShiftService.getPlacementFee(shiftData)*100);
    
    if(shiftData.commissionType === 'percentage')
    {
      workerBenefit = Math.ceil(commissionWage * (shiftData.association?.percentageOnCommission/100))/100;
    }
    else{
      workerBenefit = shiftData.actualCommission * (shiftData.association?.percentageOnCommission/100);
    }

    return (
      <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height:30
          }}
        >
          <div style={{color:Colors.darkGrey}}>
          {shiftData?.association?.associationName} Member Benefit
          </div>
          <div
            style={{
              width: 100,
              alignItems: "flex-start",
            }}
          >
           <div style={{fontWeight:"bold",fontSize:16,textAlign:"right"}}>${workerBenefit.toFixed(2)}</div>
          </div>
        </div>
      // <div
      //   style={{
      //     display: "flex",
      //     flexDirection: "row",
      //     justifyContent: "space-between",
      //   }}
      // >

      //   <div style={{ width: 120 }}>
      //     {RenderTextWithTitle("Member Benefit", "$" + workerBenefit.toFixed(2))}
      //   </div>
      // </div>
    );
  };
  const renderBillKMAllowance = () => {
    const kmAllowance = shiftData.kmAllowance ?? 0;
    const distance = Math.round(((shiftData.shiftDistance ?? 0) * 2) / 1000);
    const subTotalKMAllowance = kmAllowance * distance;
    allowanceAmount += subTotalKMAllowance;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: 100 }}>
          {RenderTextWithTitle("Per KM", "$" + (kmAllowance/100).toFixed(2))}
        </div>
        <div style={{ width: 120 }}>
          {RenderTextWithTitle("Distance", distance.toFixed(0) + "km")}
        </div>
        <div
          style={{
            width: 100,
            alignItems: "flex-start",
          }}
        >
          {/* {RenderTextWithTitle("Amount", "$" + (subTotalKMAllowance/100).toFixed(2))} */}
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowWrap: "anywhere",
              alignItems: "flex-end",
              alignContent: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflowWrap: "anywhere",
                color: Colors.darkGrey,
                fontSize: FontSize.default,
              }}
            >
              Amount
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontWeight: "bold",
                fontSize: FontSize.large,
              }}
            >
              {"$" + (subTotalKMAllowance/100).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderBillAccomodationAllowance = () => {
    const accomodationAllowance = shiftData.accomodationAllowance ?? 0;
    allowanceAmount += accomodationAllowance;
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: 100 }}>
            {RenderTextWithTitle(
              "Per Shift",
              "$" + (accomodationAllowance / 100).toFixed(2)
            )}
          </div>
          <div style={{ width: 120 }}>
            {RenderTextWithTitle("Quantity", "1")}
          </div>
          <div
            style={{
              width: 100,
              alignItems: "flex-start",
            }}
          >
            {/* {RenderTextWithTitle(
              "Amount",
              "$" + (accomodationAllowance / 100).toFixed(2)
            )} */}
             <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowWrap: "anywhere",
              alignItems: "flex-end",
              alignContent: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflowWrap: "anywhere",
                color: Colors.darkGrey,
                fontSize: FontSize.default,
              }}
            >
              Amount
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                fontWeight: "bold",
                fontSize: FontSize.large,
              }}
            >
              {"$" + (accomodationAllowance / 100).toFixed(2)}
            </div>
          </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCommissionInformation = () => {
    const actualhoursDisplay = ShiftService.getShiftOfficialLengthDisplay(shiftData);
    const minutesWorked = moment(shiftData.officialEndTime).diff(
      moment(shiftData.officialStartTime),
      'minutes',
    );
    // commissionAmount = Math.ceil(
    //   ShiftService.getCommission(shift, account) * (minutesWorked / 60),
    // );
    const shiftNetworkPaid = shiftData.networkPaid ? shiftData.networkPaid  : shiftData.pharmacy.networkPaid;
    commissionAmount = (shiftNetworkPaid === true ? (shiftData.actualCommission ? (Math.ceil((ShiftService.getPlacementFee(shiftData) * 100))/100) : (ShiftService.getCommission(shiftData, account) / 100) * (minutesWorked / 60)): 0);
    
    console.log('commission',ShiftService.getCommission(shiftData, account) / 100)
    const rbCommission = (ShiftService.getCommission(shiftData, account) / 100).toFixed(2);

    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height:30
          }}
        >
          <div>
          <RBPlacementFeeModal title={"Placement Fee"} detail={''} commissionType={shiftData.commissionType ? shiftData.commissionType :shiftData.pharmacy.commissionType}/>
         
          </div>
          <div
            style={{
              width: 100,
              alignItems: "flex-start",
            }}
          >
           <div style={{fontWeight:"bold",fontSize:16,textAlign:"right"}}>${commissionAmount.toFixed(2)}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderSurcharge = (account:any, shift:any) => {
  
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height:30
          }}
        >
          <div>
          <RBPlacementFeeModal title={"Processing Fee"} detail={''} commissionType={shiftData.commissionType ? shiftData.commissionType :shiftData.pharmacy.commissionType}/>
         
          </div>
          <div
            style={{
              width: 100,
              alignItems: "flex-start",
            }}
          >
           <div style={{fontWeight:"bold",fontSize:16,textAlign:"right"}}>${shift.surchargeAmount.toFixed(2)}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderFeeAmount = () => {
    let subTotal = shiftData.paidIn;
    let feeTax = subTotal * shiftData.salesTaxRate / 100;
    return (
      <div>
        <div
          style={{
            height: 1,
            backgroundColor: Colors.darkGrey,
          }}
        />
        <div className="section-header-text">Shift Fee Information</div>

        <div
          style={{
            display: "flex",
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ marginRight: 10 }}>
            <div style={{ fontWeight: "bold", fontSize:16  }}>Subtotal</div>
            <div style={{ fontWeight: "bold" , marginBottom: 4 , fontSize:16 }}>
              {shiftData.salesTaxSymbol + '(' + shiftData.salesTaxRate + '%)'}{' '}
            </div>
            <div style={{ fontWeight: "bold", fontSize:16 }}>Total</div>
          </div>

          <div
            style={{
              width: 80,
              alignItems: 'flex-start',
              paddingLeft: '1%'
            }}
          >
            <div style={{ fontWeight: "bold", fontSize:16, textAlign:"right"  }}>
              ${(subTotal / 100).toFixed(2)}
            </div>
            <div style={{ fontWeight: "bold", marginBottom: 4, fontSize:16 ,textAlign:"right"  }}>
              ${(feeTax / 100).toFixed(2)}
            </div>
            <div style={{ fontWeight: "bold", fontSize:16,textAlign:"right"  }}>
              ${((subTotal + feeTax) / 100).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderTotalAmount = () => {
    if (roleName === roleNameEnum.owner) {
      let subTotalWithTax = commissionAmount === 0 ? (wageAmount + allowanceAmount) : (wageAmount + allowanceAmount + (commissionAmount * 100));
      let feeTax = shiftData.actualCommission ?
          Math.ceil(subTotalWithTax * shiftData.salesTaxRate / 100) :
          subTotalWithTax * shiftData.salesTaxRate / 100;
      let subTotal = shiftData.surchargeAmount !== null ? (subTotalWithTax + (shiftData.surchargeAmount*100)) : subTotalWithTax;
      return (
        <div>
          <div
            style={{
              height: 1,
              backgroundColor: Colors.darkGrey,
              //T  marginVertical: 8,
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: 100 }} />
            <div style={{ width: 220 }}>
              <div style={{ fontWeight: "bold",fontSize:16 }}>Subtotal</div>
              {shiftData.pharmacy.networkPaid &&
                <>
                  <div style={{ fontWeight: "bold", marginBottom: 4 ,fontSize:16}}>
                  { shiftData.salesTaxSymbol + '(' + shiftData.salesTaxRate + '% on $' + (subTotalWithTax/100).toFixed(2) + ')'}{' '}
                  </div>
                  <div style={{ fontWeight: "bolder",fontSize:16 }}>Total</div>
                </>}
            </div>

            <div
              style={{
                width: 100,
                alignItems: "flex-start",
              }}
            >
              <div style={{ fontWeight: "bold" ,fontSize:16, textAlign:"right"}}>
                ${(subTotal / 100).toFixed(2)}
              </div>
              {shiftData.pharmacy.networkPaid &&
                <>
                  <div style={{ fontWeight: "bold", marginBottom: 4 ,fontSize:16,textAlign:"right"}}>
                  ${(feeTax / 100).toFixed(2)}
                  </div>
                  <div style={{ fontWeight: "bolder" ,fontSize:16,textAlign:"right"}}>
                  ${((subTotal + feeTax) / 100).toFixed(2)}
                  </div>
                </>}
            </div>
          </div>
          {!shiftData.pharmacy.networkPaid && renderFeeAmount() }
        </div>
      );
    } else {
      let saleTaxEnable = shiftData.offloadedSalesTax ? shiftData.offloadedSalesTax: null;
      let subTotal = wageAmount + allowanceAmount+ (workerBenefit*100);
      let tax = 0;
      if (saleTaxEnable) {
        tax = shiftData.actualCommission ? Math.ceil(subTotal * (shiftData.salesTaxRate / 100)) : Math.floor(subTotal * (shiftData.salesTaxRate / 100));
      }
      return (
        <div>
          <div
            style={{
              height: 1,
              backgroundColor: Colors.darkGrey,
              //marginVertical: 8,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: 100 }} />
            <div style={{ width: 120 }}>
              <div style={{ fontWeight: "bold" ,fontSize:16}}>Subtotal</div>
              {shiftData.pharmacy.networkPaid &&
                <><div style={{ fontWeight: "bold", marginBottom: 4,fontSize:16 }}>
                {shiftData.salesTaxSymbol + "(" + shiftData.salesTaxRate + "%)"}{" "}
              </div>
              <div style={{ fontWeight: "bolder",fontSize:16 }}>Total Earnings</div>
              </>}
            </div>

            <div
              style={{
                width: 100,
                alignItems: "flex-start",
              }}
            >
              <div style={{ fontWeight: "bold" ,fontSize:16, textAlign:"right"}}>
                ${(subTotal / 100).toFixed(2)}
              </div>
              {shiftData.pharmacy.networkPaid &&
                <>
                  <div style={{ fontWeight: "bold", marginBottom: 4 ,fontSize:16, textAlign:"right"}}>
                    {saleTaxEnable ? "$" + (tax / 100).toFixed(2) : "N/A"}
                  </div>
                  <div style={{ fontWeight: "bolder" ,fontSize:16,textAlign:"right"}}>
                    ${(shiftData.paidOut / 100).toFixed(2)}
                  </div>
                </>}
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div>
      {roleName === roleNameEnum.owner ? (
        <div style={{
          display: "flex",
          flexDirection: "row",
        }}>
          <div className="section-header-text">Billing Information </div>
          <div style={{ fontWeight: 'normal', fontSize: Font.small, paddingTop: 10 , paddingLeft:5}}>
            for information purposes only
          </div>
        </div> 
      ) : (
        <div style={{
          display: "flex",
          flexDirection: "row",
        }}>
          <div className="section-header-text">Earning Information </div>
          <div style={{ fontWeight: 'normal', fontSize: Font.small, paddingTop: 10, paddingLeft:5 }}>
            for information purposes only
          </div>
        </div> 
      )}
      {renderWageInformation()}
      {kmAllowanceAvailable && (
        <div>
          <div
            style={{
              height: 1,
              backgroundColor: Colors.defaultGrey,
              // marginVertical: 4,
            }}
          />
          {renderBillKMAllowance()}
        </div>
      )}

      {accomodationAllowanceAvailable && (
        <div>
          <div
            style={{
              height: 1,
              backgroundColor: Colors.defaultGrey,
              // marginVertical: 4,
            }}
          />
          {renderBillAccomodationAllowance()}
        </div>
      )}

      {shiftData.pharmacy.networkPaid && account.roleName !== 'owner' && shiftData.associationId &&
        <div>
          {shiftData.actualCommission &&
            <>
              <div
                style={{
                  height: 1,
                  backgroundColor: Colors.defaultGrey,
                  // marginVertical: 4,
                }}
              />
              <>{renderOPAInformation()}</>
            </>}
        </div>
      }

    {shiftData.pharmacy.networkPaid && roleName === roleNameEnum.owner && (
        <div>
          {shiftData.actualCommission && 
          <>
          <div
            style={{
              height: 1,
              backgroundColor: Colors.defaultGrey,
              // marginVertical: 4,
            }}
          />
          {renderCommissionInformation()}
          </>}
        </div>
      )}



    {roleName === roleNameEnum.owner && (
        <div>
          { shiftData.surchargeAmount !== null && shiftData.surchargeAmount > 0 && 
          <>
          <div
            style={{
              height: 1,
              backgroundColor: Colors.defaultGrey,
              // marginVertical: 4,
            }}
          />
         {renderSurcharge(account, shiftData)}
          </>}
        </div>
      )}


      {renderTotalAmount()}
      <div
        style={{
          height: 1,
          backgroundColor: Colors.defaultGrey,
          marginTop: Spacing.medium,
          marginBottom: Spacing.medium,
        }}
      />
    </div>
  );
};
