import React, { useState, useEffect ,useContext} from "react";
import { AuthService } from "../../api/AuthService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, Colors,} from "../../theme/Theme";
import {
  useTheme,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum,paymentTypeEnum,formatPaymentType } from "../../api/constants";
import {  ColumnStyle } from "../../theme/ComponentTheme";
import { RBAppbar } from "../../components/RBAppbar";
import { RBButton } from "../../components/RBButton";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import CreditCard from "@material-ui/icons/CreditCard";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { RBAlert } from "../../components/RBAlert";
import { PharmacyService }  from "../../api/PharmacyService";

interface PadSuccessPageProps {}
interface LocationState {
    tabType?: string;
    editMode?: boolean;
    newPharmacy?: boolean;
    selectedLoc?:ObjectLiteral;
  }
export const PadSuccess: React.FC<PadSuccessPageProps> = ({
    ...props
  }) => {
    
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [isAlertShowing, setIsAlertShowing] = useState(false);
  const [alertInfo,setAlertInfo]  = useState <any> ({title:"",message:"", buttons:[]})
  const [isPageLoading, setIsPageLoading] = useState(true);


  useEffect(() => {
    
      (async () => {
        setIsAlertShowing(true);
        setAlertInfo({
          title: "Payment Info Registered",
          message: `Your Pre-Authorized Debit (PAD) has successfully linked with your account.`,
          buttons: [
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false);
                if(localStorage.getItem('stripePharmacyId'))
                {
                  await PharmacyService.createPAD({locationId:localStorage.getItem('stripePharmacyId'),paymentType:"pad",paymentSetupType:'shift'});
                }
                history.push('/dashboard');
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Go Home"
            />,
          ],
        });
       setIsPageLoading(false);
      })();       
      
  }, [])

  return (
    <>
    {isPageLoading ? (
      <LoadingIndicator />
    ) : (
      <>
   
        <div>
        <RBAlert
          show={isAlertShowing}
          alertTitle={alertInfo.title}
          alertMessage={alertInfo.message}
          buttons={alertInfo.buttons}
        />

        </div>
        </>
      )}

    </>
    );
}

