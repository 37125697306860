import * as Yup from "yup";
import { Regexes } from "../../util/Default";
import phone from 'phone';
import validator from 'validator';

// const PharmacyLocationInfo = Yup.object().shape({
//   street: Yup.string().required("Required"),
//   city: Yup.string().required("Required"),
//   postalCode: Yup.string()
//     .required("Required")
//     .matches(Regexes.CanadaPostalCodeValidation, "Invalid postal code"),
//   province: Yup.string().required("Required"),
// });

const DesignatedManagerInformation = Yup.object().shape({
  designatedManagerFirstName: Yup.string().required("Required"),
  designatedManagerLastName: Yup.string().required("Required"),
  designatedManagerPhoneNumber: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    // .test("DOB", "must be a valid phone number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
  designatedManagerLicenseNumber: Yup.string().required("Required"),
  agreePrivacyPolicy: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});

const PharmacyInformation = Yup.object().shape({
  pharmacyName: Yup.string().required("Required"),
  pharmacyPhoneNumber: Yup.string()
    .required("Required")
   .matches(Regexes.PhoneValidation, "Invalid phone number"),
  //  .test("DOB", "must be a valid phone number", (value) => {
  //   return validator.isMobilePhone(value, 'en-CA');
  // }),
  pharmacyFaxNumber: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid fax number"),
    // .test("DOB", "must be a valid fax number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
  pharmacyAccreditationNumber: Yup.string().required("Required"),
  pharmacyAccreditationProvince: Yup.string().required("Required"),
  pharmacyRxCount: Yup.string().required("Required"),
  pharmacySoftware: Yup.string().required("Required"),
});

const DentalPracticeInformation = Yup.object().shape({
  pharmacyName: Yup.string().required("Required"),
  //  pharmacyStoreNumber: Yup.string().required("Required"),
  street: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  postalCode: Yup.string()
    .required("Required")
    .matches(Regexes.CanadaPostalCodeValidation, "Invalid postal code"),
  province: Yup.string().required("Required"),
  pharmacyPhoneNumber: Yup.string()
    .required("Required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    // .test("DOB", "must be a valid phone number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
  pharmacySoftware: Yup.string().required("Required"),
  companyType: Yup.string().required("Required")
});

const AdditionalCompanyInformation = Yup.object().shape({
  companyWebsite: Yup.string().optional(),
  companyOverview: Yup.string().optional(),
  companyServices: Yup.string().optional(),
  companyBenefits: Yup.string().optional(),
  agreePrivacyPolicy: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});

export const Schema = {
  //PharmacyLocationInfo,
  DesignatedManagerInformation,
  PharmacyInformation,
  DentalPracticeInformation,
  AdditionalCompanyInformation
};
