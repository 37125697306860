import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  DateTimePickerProps,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { InputAdornment, IconButton } from "@material-ui/core";
import { InsertInvitation } from "@material-ui/icons";
import { Spacing } from "../theme/Theme";
import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";
interface RBDateTimePickerProps extends DateTimePickerProps {}

class test extends MomentUtils {}
export const RBDateTimePicker: React.FC<RBDateTimePickerProps> = ({
  ...props
}) => {
  const { label, value, onChange, ...rest } = props;

  //moment.tz.setDefault("America/Vancouver");

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      <KeyboardDateTimePicker
        label={label}
        margin="dense"
        format="MM/DD/YYYY, hh:mm a"
        // format="MM/dd/yyyy HH:mm"
        InputLabelProps={{
          style: { color: "Black" },
        }}
        inputVariant="outlined"
        value={value}
        // InputProps={{
        //   style: {
        //     //  color: "red",
        //   },
        //   endAdornment: (
        //     <InputAdornment position="end">
        //       <IconButton>
        //         <InsertInvitation />
        //       </IconButton>
        //     </InputAdornment>
        //   ),
        // }}
        style={{ paddingBottom: Spacing.xlarge }}
        showTodayButton
        onChange={onChange}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

interface RBDatePickerProps extends KeyboardDatePickerProps {}
export const RBDatePicker: React.FC<RBDatePickerProps> = ({ ...props }) => {
  const { label, value, onChange, ...rest } = props;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      <KeyboardDatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        label={label}
        format="MM/DD/YYYY"
        margin="dense"
        value={value}
        InputAdornmentProps={{ position: "end" }}
        onChange={onChange}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};





interface RBDatePickerProps extends KeyboardDatePickerProps {}
export const RBDatePickerSearch: React.FC<RBDatePickerProps> = ({ ...props }) => {
  const { label, value, onChange, ...rest } = props;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      <KeyboardDatePicker
        autoOk
        variant="inline"
        inputVariant="outlined"
        label={label}
        format="MM/DD/YYYY"
        margin="dense"
        value={value}
        InputAdornmentProps={{ position: "end" }}
        onChange={onChange}
        minDate={new Date()}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};
