import React, { useState, useEffect,useContext } from "react";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { Spacing, Colors } from "../../theme/Theme";
import { useLocation, useHistory } from "react-router";
import { RBCardView } from "../../components/RBCardView";
import { RBDatePickerSearch } from "../../components/RBDateTimePicker";
import moment from "moment-timezone";
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBButton } from "../../components/RBButton";
import { ShiftService } from "../../api/ShiftService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { formatWorkerType } from "../../util/FormattingService";
import {
  defaultKmAllowanceIcon,
  defaultAccomodationAllowanceIcon,
} from "../../util/Icons";
import { Link, Typography } from "@material-ui/core";
import { RoutePathConstant } from "../../util/Common";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { RBErrorAlert } from "../../components/RBAlert";
import { AuthService } from "../../api/AuthService";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum } from "../../api/constants";
import { defaultAllowOffersIcon } from "../../components/RBShiftRelatedIcons";
import { detailTextStyle, titleHeaderTextStyle } from "../../components/TextWithHeader";
export const SearchMultipleShiftsByLocation = () => {
  const location = useLocation<{
    background?: any;
    startDate?: any;
    endDate?: any;
    account?: any;
    pharmacy?: any;
  }>();
  const history = useHistory();
  const [showModal, setShowModal] = useState(true);
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [searchedShifts, setSearchedShifts] = useState<ObjectLiteral[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  useEffect(() => {
    fetchShifts();
  }, []);

  const fetchShifts = async () => {
    try {
      setIsLoading(true);
      const responceData = await ShiftService.searchShiftsBylocation(
        location.state.startDate,
        location.state.endDate,
        location.state.account.networks[0].id,
        location.state.pharmacy.id
      );
      setSearchedShifts(responceData.data);
    } catch (err) {
      const errorMessage = err.response ? err.response.data.message : err.message;
      setSubmitErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };


  const renderSearchShiftResultDetail = (shift: ObjectLiteral) => {
    const startTime = moment(shift.startTime).tz(shift.shiftTimeZone);
    const endTime = moment(shift.endTime).tz(shift.shiftTimeZone);
    const hoursDisplay = ShiftService.getShiftLengthDisplayForOriginal(shift);
    //const dateString = startTime.format('dddd MMMM Do YYYY');
    const dateString = startTime.format("ddd, MMM Do YYYY");
    const wage = shift.actualCommission? shift.wage / 100 : ShiftService.getWage(shift,location.state.account) / 100;
    const shiftDistance = shift.distance;
    const city = shift.shiftCity;
    const kmAllowanceAvailable = shift.kmAllowance > 0;
    const accomodationAllowanceAvailable = shift.accomodationAllowance > 0;
    const workerType = formatWorkerType(shift.pharmacistType);
    const workerNetworkId = location.state.account.networks[0].id;


    return (
      <RBCardView
        key={shift.id}
        marginHorizontal={Spacing.large}
        paddingHorizontal={Spacing.large}
        paddingVertical={Spacing.small}
        onClick={() => {
          console.log("RBCardView onClick");
          history.push(`${RoutePathConstant.SearchShiftDetail}${shift.id}`, {
            //background: location,
            background: location.state?.background,
          });
        }}
        clickable={true}
        style={{
          //  padding: Spacing.large,
          marginTop: Spacing.medium,
        }}
      >
        <div style={{ ...ColumnStyle,flex:3 ,paddingBottom: Spacing.smaller,}}>
          <Typography style={{ fontWeight: "bold" }}>{dateString}</Typography>
          <Typography style={{ fontWeight: "bold" }}></Typography>
          <Typography style={{ fontWeight: "bold" }}>
              <div style={{ flex: 1, alignItems: "flex-start" }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >

                  <div
                    style={{
                      marginLeft: Spacing.smallest,
                      fontWeight: "bold"
                    }}
                  >
                    ${wage.toFixed(2)}/hr
                  </div>
                  {shift.allowOffers && defaultAllowOffersIcon(secondaryColor)}
                </div>

              </div>
          </Typography>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: Spacing.smaller,flex:3 }}>
          <Typography style={{ fontWeight: "bold", width:'45%' }}>
            {startTime.format("h:mm a")} - {endTime.format("h:mm a")} 
          </Typography>
          <Typography>{workerType}</Typography>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: Spacing.smaller,flex:3 }}>
           <Typography style={{ fontWeight: "bold", width:'45%' }}>
            ({hoursDisplay})
          </Typography>
          <div>
            {kmAllowanceAvailable && defaultKmAllowanceIcon(primaryColor)}
            {accomodationAllowanceAvailable &&
              defaultAccomodationAllowanceIcon(primaryColor)}
          </div>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: Spacing.smaller,flex:3}}>
        <Typography>
            {(shiftDistance / 1000).toFixed(0)} km away{" "}
            {city ? `in ${city}` : ""}
          </Typography>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
          <div>
          {
              (location.state.account.networks[0].isTrustNetwork === true &&
                location.state.account.pharmacist.approval?.rbVerified === true &&
                  <Typography style={{ width:'12%' }}>
                    <img
                    src={shift.pharmacy?.networkId === workerNetworkId ? require('../../img/50x50-01.png') : require('../../img/100x100-02-01.png')}
                    alt="Network Logo"
                    style={{

                      height: 50,
                      marginRight: 10,
                      marginBottom: -20
                    }}
                  />
                  </Typography>)
          }
          </div>
        </div>
      </RBCardView>
    );
  };
  const renderSearchShiftResults = () => {
    return (
      <>
        {searchedShifts.length === 0 && (
          <RBCardView
            marginHorizontal={Spacing.medium}
            style={{
              padding: Spacing.large,
              marginTop: Spacing.medium,
            }}
          >
            No shifts available during this period
          </RBCardView>
        )}
        {searchedShifts.length >= 1 &&
          searchedShifts.map((shift: ObjectLiteral) =>
            renderSearchShiftResultDetail(shift)
          )}
      </>
    );
  };
  const renderSearchShiftContent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: Spacing.medium,
        }}
      >
        {renderSearchShiftResults()}
      </div>
    );
  };

  const onClose = () => {
    goBackToPreviousLocation();
  };

  const goBackToPreviousLocation = () => {
      setShowModal(false);
      history.push(RoutePathConstant.SearchShifts);
  };
  return (
    <>
      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={JSON.stringify(submitErrorMessage, null, 2)}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      />
      <RBModal
        backArrowIcon
        open={showModal}
        onClose={onClose}
        modalTitle={location.state.pharmacy?.name}
        header={
          <RBEmptyBlock
            style={{
              width: "100%",
              height: Spacing.large,
              backgroundColor: Colors.defaultGrey,
              flexShrink: 0,
            }}
          />
        }
        children={renderSearchShiftContent()}
      />
    </>
  );
};
