import React, { useState, useEffect ,useContext} from "react";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { Spacing, Colors, FontSize } from "../../theme/Theme";
import {
  useLocation,
  useHistory,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import { RBCardView } from "../../components/RBCardView";
import moment from "moment-timezone";
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBButton } from "../../components/RBButton";
import { ShiftService } from "../../api/ShiftService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { formatWorkerType, getDatesDuration } from "../../util/FormattingService";
import {
  detailKmAllowanceIcon,
  detailAccomodationAllowanceIcon,
  techOnSiteIcon,
  assistantOnSiteIcon,
} from "../../util/Icons";
import { Typography, Checkbox, Link } from "@material-ui/core";
import { RoutePathConstant, NotAvailableString, EmptyString } from "../../util/Common";
import {
  RenderTextWithTitle,
  titleHeaderTextStyle,
  detailTextStyle,
  renderTextWithTitleAndChildComponent,
  renderTextWithTitleForSkills,
} from "../../components/TextWithHeader";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { LabelText } from "../../util/TextConstant";
import { workerTypeEnum, HelperPageTitleEnum } from "../../util/Enumeration";
import { RBExternalHelpLink } from "../../components/RBExternalHelpLink";
import { AuthService } from "../../api/AuthService";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { RBContext } from '../../components/RBContext';
import { PharmacyInformation } from "../shiftDetail/components/PharmacyInformation";
import { RBShiftAgreementModal } from "../../components/RBShiftAgreementModal";
import { RBDropFile } from "../../components/RBDropFile";
import { RBMoneyAmountTextField, RBTextField } from "../../components/RBTextField";
import { IoIosCash } from "react-icons/io";
import { IndustryModeEnum } from "../../api/constants";
import { DentalPracticeInfo } from "../shiftDetail/components/DentalPracticeInfo";
import { RequestedRatetDto } from "../../dtos/RequestedRate.dto";

import { defaultAllowOffersIcon } from '../../components/RBShiftRelatedIcons';
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import PDFViewer from "../../components/PdfViewer";
import SignaturePad  from "../../components/SignaturePad"
interface ApplyJobPageProps
  extends RouteComponentProps<{ jobId: string }> {}




const ApplyJobPageWithoutRouter: React.FC<ApplyJobPageProps> = ({
  match,
}) => {
  const { primaryColor,secondaryColor, industryMode } = useContext(RBContext);
  const location = useLocation<{ background: string }>();
  const history = useHistory();
  const [showModal, setShowModal] = useState(true);

  const [searchedJobDetail, setSearchedJobDetail] = useState<ObjectLiteral>(
    {}
  );
  const [isLoading, setIsLoading] = useState(true);
  const [agreeTOS, setAgreeTOS] = useState(false);
  const [totalEarning, setTotalEarning] = useState(NotAvailableString);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [account, setAccount] = useState<any>([]);

  const [showContract,setShowContract] = useState(false);
  const [showTermOfService,setshowTermOfService] = useState(false);
  const [defaultSignature,setDefaultSignature] = useState('');

  const [newFile, setNewFile] = useState<File>();
  const [wageDisplayValue,setwWgeDisplayValue] = useState<any>(null);
  const [wageRequest,setwageRequest] = useState<any>("0");

  const[ isWageValid,setIsWageValid] = useState(false);
  
  const [isAlertShowing, setAlertShowing] = useState(false);
  const [applyAlertInfo, setApplyAlertInfo] = useState<AlertInfoProps>({});
  const [errorMessage, setErrorMessage] = useState(EmptyString);
  const [resumeModal, setReumeModal] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [isSubmittingDecline, setIsSubmittingDecline] = useState(false);
  const [isSubmitDisabledDecline, setIsSubmitDisabledDecline] = useState(false);

  useEffect(() => {
    localStorage.removeItem("workerSign")
    fetchJobDetailByJobID(match.params.jobId);
  }, []);

  useEffect(() => {
    // true -> disable 'confirm' button
    // false -> enable 'confirm' button
    setIsSubmitDisabled(
      !(
        agreeTOS &&
        (newFile ||
            defaultSignature) &&
        !isSubmitting &&  !isSubmittingDecline
      ),
    );
  }, [agreeTOS, newFile || defaultSignature]);

  const fetchJobDetailByJobID = async (jobId: string) => {
    try {
      setIsLoading(true);
      const userData = await AuthService.loadAccountInfo();
      setAccount(userData);
      setDefaultSignature(userData.pharmacist.signature);
      const jobDetail = await ShiftService.getJobtDetailByJobId(jobId);
      console.log("ApplyShiftPage ", jobDetail);
      setSearchedJobDetail(jobDetail);
      //setTotalEarning(ShiftService.getTotalEarningForApplyShift(jobDetail,userData));
    } catch (err) {
      setSubmitErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const setShowTermOfService = (showTermOfService:boolean) =>
  setshowTermOfService(showTermOfService);


  const onTermsClose = () =>{
    setshowTermOfService(false);
  }
  const onResumeClose= () => {
    setReumeModal(false)
   };

  const   checkForm = (wage:any) => {
    if(wage !== null){
      return !(
        agreeTOS &&
        (newFile || defaultSignature) &&   wage !== "0.00"
      );
    }
    else{
      return !(
        agreeTOS &&
        (newFile || defaultSignature)
      );
    }
    
  };


  const renderSearchedJobDetail = () => {
    const {
        jobNumber,
        jobTitle,
        jobCategory,
        jobCity,
        pharmacistType,
        estStartDate,
        estEndDate,
        estHourlyRate,
        comment,
        technicianOnSite,
        assistantOnSite,
        pharmacy,
      } = searchedJobDetail;
      const { rxCount } = pharmacy;
      const workerType = formatWorkerType(pharmacistType);
      const estStartDateValue = estStartDate
        ? moment(estStartDate).format('MMM Do, YYYY')
        : 'N/A';
      const estEndDateValue = estEndDate
        ? moment(estEndDate).format('MMM Do, YYYY')
        : 'N/A';
      const duration =
        estStartDateValue !== 'N/A' &&
        estEndDateValue !== 'N/A'
          ? getDatesDuration(estStartDate, estEndDate)
          : 'N/A';
      const softwareType =
        industryMode === IndustryModeEnum.Dentistry
          ? 'dentalSoftware'
          : 'software';
      const { software, specializations } = searchedJobDetail.skills.reduce(
        (acc:any, value:any) => {
          if (value.type === softwareType) acc.software.push(value);
          else acc.specializations.push(value);
          return acc;
        },
        { software: [], specializations: [] },
      );
      const softwareValue =
        software.length > 0
          ? software.map((software:any) =>
              software.required
                ? software.name
                : ShiftService.getUnrequiredSoftwareName(software.name),
            )
          : [];
      const extraSkills =
        specializations.length > 0 ? specializations.map((s:any) => s.name) : [];
  
      const currentDate = moment().format('MMM-DD-YYYY');
      const currentYear = moment().year();
      const wage = (estHourlyRate / 100).toFixed(2);
      const owner = searchedJobDetail.creator ? searchedJobDetail.creator.owner : false;
      const ownerEmail = searchedJobDetail.creator ? searchedJobDetail.creator.email : false;
      const pharmacistDetails =
        account.pharmacist.firstName +
        ' ' +
        account.pharmacist.lastName;
      const licenseNumber = account.pharmacist.licenseNumber ?? 'N/A';


    const contractPreviewClick = () =>{
      setShowContract(true);
    }

    const onContractClose = () =>{
      setShowContract(false);
    }
    const invitedStatus = (searchedJobDetail.jobToInvites && searchedJobDetail.jobToInvites.length > 0 && searchedJobDetail.jobToInvites.find((obj:any) => obj.status === "pending" && obj.workerId === account.id)) ? true : false;

    return (
      <>
        <RBAlert
          show={isAlertShowing}
          alertTitle={applyAlertInfo.title}
          alertMessage={applyAlertInfo.message}
          buttons={applyAlertInfo.buttons}
        />

        {showContract && <RBShiftAgreementModal
          open={showContract}
          onClose={onContractClose}
          modalTitle="Job Contract"
          pageName="job-contract-pharmacist"
          pageType="page"
          networkId = {pharmacy.networkId}
          jobId = {searchedJobDetail.id}
          userId= { account.id }
          type = 'job'
          substitutions={{
            currentDate,
            estStartDate: estStartDateValue,
            estEndDate: estEndDateValue,
            estHourlyRate: '$' + wage,
            pharmacyDetails: '[Pharmacy]',
            jobNumber: `xxxx-${currentYear}-x-xx`,
            pharmacistSignature: '',
            pharmacySignature: '',
            licenseNumber: licenseNumber,
            pharmacistName: pharmacistDetails,
            pharmacyName: '[Pharmacy]',
            reliefBuddySignature: '',
            workerType: workerType,
          }}
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
        />}
        {
        industryMode === IndustryModeEnum.Dentistry ? 
            (<>
             
              <RBCardView
                marginHorizontal={Spacing.large}
                style={{
                  padding: Spacing.large,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={ColumnStyle}>
                  {RenderTextWithTitle("Job Title", jobTitle && jobTitle.length > 0 ? jobTitle : 'N/A',)}
                  {RenderTextWithTitle("Worker Type", workerType)}
                </div>
                <div style={ColumnStyle}>
                  {RenderTextWithTitle("Job Type", jobCategory.name,)}
                  {RenderTextWithTitle("Practice Type", pharmacy.companyType)}
                </div>
                <div style={ColumnStyle}>
                  {RenderTextWithTitle("Job Number", jobNumber)}
                </div>
                <div style={ColumnStyle}>
                    {RenderTextWithTitle("Est. Start Date", estStartDateValue)}
                    {RenderTextWithTitle("Est. End Date", estEndDateValue)}

                </div>
                <div style={ColumnStyle}>
                    {RenderTextWithTitle("Est. Duration", duration)}
                    {RenderTextWithTitle("Est. Hourly Rate", '$' + (estHourlyRate / 100).toFixed(2),)}

                </div>
                <div style={ColumnStyle}>
                  {renderTextWithTitleForSkills("Software", softwareValue)}
                  {renderTextWithTitleForSkills("Specializations", extraSkills)}
                </div>
                <div style={ColumnStyle}>{RenderTextWithTitle("Job Description", comment || 'N/A')}</div>
               
              </RBCardView>
              <br></br>
               <RBCardView
                marginHorizontal={Spacing.large}
                style={{
                  padding: Spacing.large,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <DentalPracticeInfo
                  pharmacy={searchedJobDetail.pharmacy}
                  shiftData={searchedJobDetail}
                  owner={owner}
                  ownerEmail={ownerEmail}
                  showPartialInfo={false}
                  account={account}
                  page="applyJob"
                  applied={false}
                />
              </RBCardView>
              <br></br>
              <RBCardView
              marginHorizontal={Spacing.large}
              style={{
                padding: Spacing.large,
                display: "flex",
                flexDirection: "column",
              }}>
                 {defaultSignature ?
                  <div style={ColumnStyle}>
                    {renderTextWithTitleAndChildComponent("Signature", <Link
                      //href={signature}
                      onClick={() => setReumeModal(true)}
                      style={{ ...detailTextStyle, color: primaryColor, cursor: 'pointer' }}
                    >
                      {"View"}
                    </Link>)
                    }
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        overflowWrap: "anywhere",
                        alignItems: "flex-start",
                        alignContent: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          overflowWrap: "anywhere",

                          ...titleHeaderTextStyle,
                        }}
                      >
                        Job Agreement
                      </div>

                      <Link
                        style={{
                          display: "flex",
                          flex: 1,
                          whiteSpace: "pre-wrap",
                          overflowWrap: "anywhere",
                          ...detailTextStyle,
                          color: primaryColor,
                          cursor: 'pointer'
                        }}
                        onClick={contractPreviewClick}
                      >
                        Preview
                      </Link>
                    </div>
                  </div>
                  :
                  (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          overflowWrap: "anywhere",
                          alignItems: "flex-start",
                          alignContent: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            overflowWrap: "anywhere",

                            ...titleHeaderTextStyle,
                          }}
                        >
                          Job Agreement
                        </div>

                        <Link
                          style={{
                            display: "flex",
                            flex: 1,
                            whiteSpace: "pre-wrap",
                            overflowWrap: "anywhere",
                            ...detailTextStyle,
                            color: primaryColor,
                            cursor: 'pointer'
                          }}
                          onClick={contractPreviewClick}
                        >
                          Preview
                        </Link>
                      </div>

                      <SignaturePad
                        isVisible={true}
                        onLoad = {(file,dataURL) =>{
                          setNewFile(file)
                        }}

                        onSave={(file,dataURL) =>{
                        }}
                        onCancel={() =>{}}
                        onClear={() => {
                          localStorage.removeItem("workerSign")
                          setNewFile(undefined)
                        }}
                        userData = {account.pharmacist}
                        page="applyShift"
                      />
                      
                      <div style={{ fontSize: 10 }}>
                        *This signature will be saved in your profile as default for
                        future usage
                      </div>
                    </>

                  )
                }


                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: Spacing.large,
                  }}
                >
                  <Checkbox
                    checked={agreeTOS}
                    style={{
                      padding: 0,
                      paddingRight: Spacing.large,
                      // Why marginLeft -2, so  it will align with regular label or text field
                      marginLeft: -2,
                      color: agreeTOS ? secondaryColor : Colors.darkGrey,
                    }}
                    onChange={(_, checked) => {
                      setAgreeTOS(checked);
                    }}
                  />
                  <Typography variant="body2">
                    I have read and understood the{" "}

                    <Link onClick={() => setShowTermOfService(true)} style={{ color: primaryColor, cursor: 'pointer',fontWeight:"bold" }}>{HelperPageTitleEnum.termOfService}
                      .</Link>
                  </Typography>
                </div>
                {showTermOfService && <RBShiftAgreementModal
                  open={showTermOfService}
                  onClose={onTermsClose}
                  modalTitle={"Terms of Service"}
                  pageName="terms-of-use"
                  pageType="page"
                  networkId={pharmacy.networkId}
                  header={
                    <RBEmptyBlock
                      style={{
                        width: "100%",
                        height: Spacing.large,
                        backgroundColor: Colors.defaultGrey,
                        flexShrink: 0,
                      }}
                    />
                  }
                />
                }

                {invitedStatus == true ?
                  <>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "flex-end",
                    }}
                  >
                    <RBButton
                      style={{
                        display: "flex",
                        flex: 1,
                        padding: Spacing.medium,
                        marginBottom: 16,
                        marginTop: 16
                      }}
                      buttonBackgroundColor={primaryColor}
                      onClick={onApplyJob}
                      isLoading={isSubmitting}
                      disabled={isSubmitDisabled}
                      text={'Apply for Job'}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "flex-end",
                    }}
                  >
                    <RBButton
                      style={{
                        display: "flex",
                        flex: 1,
                        padding: Spacing.medium,
                      }}
                      buttonBackgroundColor={secondaryColor}
                      onClick={onInvitedJobDecline}
                      isLoading={isSubmittingDecline}
                      text={'Decline Request'}
                      disabled={isSubmitDisabledDecline}
                    />
                  </div>
                  </>
                  :
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "flex-end",
                    }}
                  >
                    <RBButton
                      style={{
                        display: "flex",
                        flex: 1,
                        padding: Spacing.medium,
                        margin: Spacing.large,
                      }}
                      buttonBackgroundColor={primaryColor}
                      onClick={onApplyJob}
                      isLoading={isSubmitting}
                      disabled={isSubmitDisabled}
                      text={'Apply for Job'}
                    />
                  </div>}
              </RBCardView>
              
            </>) :
            
            (<>

                <RBCardView
                  marginHorizontal={Spacing.large}
                  style={{
                    padding: Spacing.large,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <PharmacyInformation
                  shiftData={searchedJobDetail}
                  pharmacy={searchedJobDetail.pharmacy}
                  owner={owner}
                  showPartialInfo={true}
                  account={account}
                  page="applyJob"
                  applied={false}
                />
                </RBCardView>
                <br></br>
             
                <RBCardView
                  marginHorizontal={Spacing.large}
                  style={{
                    padding: Spacing.large,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={ColumnStyle}>
                    {RenderTextWithTitle("Job Type", jobCategory.name,)}
                    {RenderTextWithTitle("Worker Type", workerType)}
                  </div>
                  <div style={ColumnStyle}>
                      {RenderTextWithTitle("Est. Start Date", estStartDateValue)}
                      {RenderTextWithTitle("Est. End Date", estEndDateValue)}
  
                  </div>
                  <div style={ColumnStyle}>
                      {RenderTextWithTitle("Est. Duration", duration)}
                      {RenderTextWithTitle("Est. Hourly Rate", '$' + (estHourlyRate / 100).toFixed(2),)}
  
                  </div>
                  <div style={ColumnStyle}>
                  {renderTextWithTitleAndChildComponent(
                    LabelText.TECHNICIAN_ON_SITE,
                    techOnSiteIcon(technicianOnSite, secondaryColor)
                  )}
                  {pharmacistType !== workerTypeEnum.assistant &&
                    renderTextWithTitleAndChildComponent(
                      LabelText.ASSISTANT_ON_SITE,
                      assistantOnSiteIcon(assistantOnSite, secondaryColor)
                    )}
                </div>
                  <div style={ColumnStyle}>
                    {renderTextWithTitleForSkills("Software", softwareValue)}
                    {RenderTextWithTitle(LabelText.DailyAvgRxCount, rxCount)}
                  </div>
                  <div style={ColumnStyle}>{renderTextWithTitleForSkills("Specializations", extraSkills)}</div>
                  <div style={ColumnStyle}>{RenderTextWithTitle("Job Description", comment || 'N/A')}</div>
                 
                   {defaultSignature ?
                    <div style={ColumnStyle}>
                      {renderTextWithTitleAndChildComponent("Signature", <Link
                        //href={signature}
                        onClick={() => setReumeModal(true)}
                        style={{ ...detailTextStyle, color: primaryColor, cursor: 'pointer' }}
                      >
                        {"View"}
                      </Link>)
                      }
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          overflowWrap: "anywhere",
                          alignItems: "flex-start",
                          alignContent: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            overflowWrap: "anywhere",
  
                            ...titleHeaderTextStyle,
                          }}
                        >
                          Job Agreement
                        </div>
  
                        <Link
                          style={{
                            display: "flex",
                            flex: 1,
                            whiteSpace: "pre-wrap",
                            overflowWrap: "anywhere",
                            ...detailTextStyle,
                            color: primaryColor,
                            cursor: 'pointer'
                          }}
                          onClick={contractPreviewClick}
                        >
                          Preview
                        </Link>
                      </div>
                    </div>
                    :
                    (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            overflowWrap: "anywhere",
                            alignItems: "flex-start",
                            alignContent: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              overflowWrap: "anywhere",
  
                              ...titleHeaderTextStyle,
                            }}
                          >
                            Job Agreement
                          </div>
  
                          <Link
                            style={{
                              display: "flex",
                              flex: 1,
                              whiteSpace: "pre-wrap",
                              overflowWrap: "anywhere",
                              ...detailTextStyle,
                              color: primaryColor,
                              cursor: 'pointer'
                            }}
                            onClick={contractPreviewClick}
                          >
                            Preview
                          </Link>
                        </div>
  
                        <SignaturePad
                          isVisible={true}
                          onLoad = {(file,dataURL) =>{
                            setNewFile(file)
                          }}
  
                          onSave={(file,dataURL) =>{
                          }}
                          onCancel={() =>{}}
                          onClear={() => {
                            localStorage.removeItem("workerSign")
                            setNewFile(undefined)
                          }}
                          userData = {account.pharmacist}
                          page="applyShift"
                        />
                        
                        <div style={{ fontSize: 10 }}>
                          *This signature will be saved in your profile as default for
                          future usage
                        </div>
                      </>
  
                    )
                  }
  
  
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: Spacing.large,
                    }}
                  >
                    <Checkbox
                      checked={agreeTOS}
                      style={{
                        padding: 0,
                        paddingRight: Spacing.large,
                        // Why marginLeft -2, so  it will align with regular label or text field
                        marginLeft: -2,
                        color: agreeTOS ? secondaryColor : Colors.darkGrey,
                      }}
                      onChange={(_, checked) => {
                        setAgreeTOS(checked);
                      }}
                    />
                    <Typography variant="body2">
                      I have read and understood the{" "}
  
                      <Link onClick={() => setShowTermOfService(true)} style={{ color: primaryColor, cursor: 'pointer',fontWeight:"bold" }}>{HelperPageTitleEnum.termOfService}
                        .</Link>
                    </Typography>
                  </div>
                  {showTermOfService && <RBShiftAgreementModal
                    open={showTermOfService}
                    onClose={onTermsClose}
                    modalTitle={"Terms of Service"}
                    pageName="terms-of-use"
                    pageType="page"
                    networkId={pharmacy.networkId}
                    header={
                      <RBEmptyBlock
                        style={{
                          width: "100%",
                          height: Spacing.large,
                          backgroundColor: Colors.defaultGrey,
                          flexShrink: 0,
                        }}
                      />
                    }
                  />
                  }
  
                  {invitedStatus == true ?
                  <>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "flex-end",
                    }}
                  >
                    <RBButton
                      style={{
                        display: "flex",
                        flex: 1,
                        padding: Spacing.medium,
                        marginBottom: 16,
                        marginTop: 16
                      }}
                      buttonBackgroundColor={primaryColor}
                      onClick={onApplyJob}
                      isLoading={isSubmitting}
                      disabled={isSubmitDisabled}
                      text={'Apply for Job'}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "flex-end",
                    }}
                  >
                    <RBButton
                      style={{
                        display: "flex",
                        flex: 1,
                        padding: Spacing.medium,
                      }}
                      buttonBackgroundColor={secondaryColor}
                      onClick={onInvitedJobDecline}
                      isLoading={isSubmittingDecline}
                      text={'Decline Request'}
                      disabled={isSubmitDisabledDecline}
                    />
                  </div>
                  </>
                  :
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "flex-end",
                    }}
                  >
                    <RBButton
                      style={{
                        display: "flex",
                        flex: 1,
                        padding: Spacing.medium,
                        margin: Spacing.large,
                      }}
                      buttonBackgroundColor={primaryColor}
                      onClick={onApplyJob}
                      isLoading={isSubmitting}
                      disabled={isSubmitDisabled}
                      text={'Apply for Job'}
                    />
                  </div>}
                </RBCardView>
                
              </>)
        }
      </>
    );
  };

  const onApplyJob = async () => {
    if (!agreeTOS || isSubmitting) {
      return;
    }
      try {
          setIsSubmitting(true);
          setIsSubmitDisabledDecline(true)
          await createApplication();
      }
      catch (err) {
          setIsSubmitting(false);
          const errorMessage = err.response ? err.response.data.error : err.message;
          setSubmitErrorMessage(errorMessage);
      } 
   
  };

  const onInvitedJobDecline = async() =>{
    try{
      setIsSubmittingDecline(true);
      await AuthService.jobInviteAcceptorDecline(account,match.params.jobId,'worker-declined');
      setApplyAlertInfo({
        title: "Success",
        message: `Invitation declined successfully!`,
        buttons: [
            
            <RBButton
            key={"confirm"}
            onClick={async () => {
                setAlertShowing(false);
                history.push("/dashboard");
            }}
            buttonBackgroundColor={primaryColor}
            color="primary"
            text="Go Back"
            />,
        ],
        });
        setIsSubmitting(false);
        setAlertShowing(true);

    }
    catch(error){
      const message = error.response.data.error;
      console.log(error);
      setApplyAlertInfo({
          title: "An error occurred",
          message: `${message}`,
          buttons: [
              
              <RBButton
              key={"confirm"}
              onClick={async () => {
                  setAlertShowing(false);
                  history.push("/dashboard");
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Ok"
              />,
          ],
          });
          setAlertShowing(true);
    }
    finally{
      setIsSubmittingDecline(false);
    }
  }

const createApplication = async () => {
    setIsSubmitting(true);
    if (newFile) {
        await AuthService.updateWorker({ signature: newFile });
    }

    const employerName =
        industryMode === IndustryModeEnum.Dentistry
            ? 'dental practice'
            : 'pharmacy';
    ShiftService.requestJob(searchedJobDetail.id)
        .then(() => {

            setApplyAlertInfo({
                title: "Job applied",
                message: `Your information has been provided to the ${employerName}, so they may be contacting you. The job will be removed from your ‘applied’ list if the ${employerName} chooses another applicant.
                \n If you become unavailable for this job, please withdraw your application in the job details right away.
                \nThe ${employerName}'s contact information is in the job details. We encourage you to contact them directly to discuss the details of the position to see if it’s a good fit for you.`,
                buttons: [
                    
                    <RBButton
                    key={"confirm"}
                    onClick={async () => {
                        setAlertShowing(false);
                        history.push("/dashboard");
                    }}
                    buttonBackgroundColor={primaryColor}
                    color="primary"
                    text="Go Back"
                    />,
                ],
                });
                setIsSubmitting(false);
                setAlertShowing(true);
        })
        .catch(error => {
            const message = error.response.data.error;
            console.log("apply job error",error);
            console.log("apply job error",error.response);
            setApplyAlertInfo({
                title: "An error occurred",
                message: `${message}`,
                buttons: [
                    
                    <RBButton
                    key={"confirm"}
                    onClick={async () => {
                        setAlertShowing(false);
                        history.push("/dashboard");
                    }}
                    buttonBackgroundColor={primaryColor}
                    color="primary"
                    text="Ok"
                    />,
                ],
                });
              setAlertShowing(true);
        })
        .then(() => {
            setIsSubmitting(false);
            setIsSubmitDisabledDecline(false)
        });



};





  const onClose = () => {
    // goBackToPreviousLocation();
    history.goBack();
  };


  const rendersignatureContent = () => {

    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          userSelect:"none",
          pointerEvents:"none"

        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            <PDFViewer pdfUrl={defaultSignature} title="signature"></PDFViewer>
          </RBCardView>
       
      </div>
    );
  };

  const goBackToPreviousLocation = () => {
    if (location.state && location.state.background) {
      setShowModal(false);
      history.goBack();
    } else {
      setShowModal(false);
      history.push("/dashboard");
    }
  };
  return (
    <>
      {/* <RBAlert
            show={isDiscardAlertShowing}
            alertTitle={discardAlertSInfo.title}
            alertMessage={discardAlertSInfo.message}
            buttons={discardAlertSInfo.buttons}
          /> */}
    
      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={errorMessage}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      />
      <RBModal
          open={resumeModal}
          onClose={onResumeClose}
          modalTitle={"Signature"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              rendersignatureContent()
          }
        />

      <RBModal
        backArrowIcon
        open={showModal}
        onClose={onClose}
        modalTitle={
          "Job Details"
        }
        header={
          <RBEmptyBlock
            style={{
              width: "100%",
              height: Spacing.large,
              backgroundColor: Colors.defaultGrey,
              flexShrink: 0,
            }}
          />
        }
        children={
          isLoading ? <LoadingIndicator /> : renderSearchedJobDetail()
        }
      />
    </>
  );
};

export const ApplyJobPage = withRouter(ApplyJobPageWithoutRouter);
