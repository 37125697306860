import * as Yup from "yup";
import { Regexes } from "../../util/Default";
import moment from "moment";
import phone from 'phone';
import validator from 'validator';
const ReferralCodePattern = /^[A-Za-z0-9]{6}$/;

const UserAccountInformation = Yup.object().shape({
  email: Yup.string()
        .required("Required")
        .matches(Regexes.emailPattern, "must be a valid email address"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    helathcareProfession: Yup.string().required("Healthcare Profession is required"),
    type:Yup.string().required("Type is required"),
    role:Yup.string().required("Role is required"),
    referralCode: Yup.string()
    .matches(ReferralCodePattern, "Enter valid code"),
});


export const Schema = {
  UserAccountInformation,
};
