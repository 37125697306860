import React, { useEffect, useState, useContext } from 'react';
import { formatWorkerType } from "../../util/FormattingService";
import { Typography, IconButton ,Select,MenuItem, } from "@material-ui/core";
import { Colors, Spacing, AppContainerWidth } from "../../theme/Theme";
import { RBCardView } from "../../components/RBCardView";
import { RBButton } from "../../components/RBButton";
import { useHistory, useLocation } from "react-router";
import { RoutePathConstant } from "../../util/Common";
import { workerTypeEnum } from "../../util/Enumeration";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AuthService } from "../../api/AuthService";
import { IndustryModeEnum } from '../../api/constants';



export const RegisterPage = () => {
  console.log("RegisterPage");
  const history = useHistory();
  const location = useLocation();
  const onArrowBackIconClick = () => {
    history.push(RoutePathConstant.SignIn);
  };

  const [availableTypes, setAvailableTypes] = useState<any[]>([]);
  const [networkTypes, setNetworkTypes] = useState<any[]>([]);
  const [selectedRegisterCategory, setSelectedRegisterCategory] = useState('Select');
  const [selectedRegisterCategoryIndex, setSelectedRegisterCategoryIndex] = useState(0);
  const [registerButton, setRegisterButton] = useState(false);
  const [registerType, setRegisterType] = useState(null);
  const [networkSelected, setNetworkSelected] = useState(null);
  const [registerAs, setRegisterAs] = useState(null);
  

  const loadNetworks = async () => {
    try {
      setRegisterType(null)
      const allNetwork = await AuthService.getAllNetworks();
      setNetworkTypes(allNetwork.data)
      console.log("ALL NETWORKS", allNetwork.data)
    } catch (error) {
      console.log('loadNetworks error', error);
    } 
  }
  useEffect(() => {
    loadNetworks();
  }, [selectedRegisterCategoryIndex]);

  const selectedRegisterType = (value:any) => {
    if(value)
    {
      
      setRegisterType(value)
      const index = availableTypes.map(item => item.locationType ? item.locationType : item.workerType).indexOf(value);
      if (selectedRegisterCategoryIndex > 0)
        setSelectedRegisterCategoryIndex(0)
      else
        setSelectedRegisterCategoryIndex(index)

      if ('locationType' in availableTypes[index]) {
        history.push(RoutePathConstant.RegisterOwnAndPharmacy, {
          background: location,
          ownerType: value,
          industryMode: availableTypes[index].industryMode
        })
        setRegisterType(null)
      }
      else {
        history.push(
          `${RoutePathConstant.RegisterWorker}${value}`,
          {
            background: location,
            workerType: value,
            industryMode: availableTypes[index].industryMode
          }
        )
        setRegisterType(null)
      }
    }
  }
  const selectNetworkType = async (value:any, index:any) => {
    try{
      if(value)
      {
        setNetworkSelected(value)
        const displayName = networkTypes
        const showDisplay = displayName.filter((item:any) =>item.industryMode === value)
        setRegisterButton(true);
        setSelectedRegisterCategory(showDisplay[0].displayName);

        // const getAllWorkerandOwner = await AuthService.getAllWorkerAndOwner(value);
        // const allOwnersAndWorkers = getAllWorkerandOwner.filter((item:any)=>item?.locationType !== "Dental Provider")
        // setAvailableTypes(allOwnersAndWorkers);
        // console.log("AVAILABLE TYPES", allOwnersAndWorkers)
        // // console.log("DISPLAY NAME", showDisplay)
        const getAllWorkers = await AuthService.getAllWorkers(value);
        
        const sortedDataArray = getAllWorkers;
        sortedDataArray.sort((a:any, b:any) => a.orderSort - b.orderSort);

        const getlocations = await AuthService.getLocationTypesBasedonIndustry(value);
        let allOwnersAndWorkers = [];
        if(value == IndustryModeEnum.Dentistry){
          const getNewlocations = getlocations.filter((item :any)=>item?.locationType === "Dental Practice")
          allOwnersAndWorkers = [...getNewlocations, ...sortedDataArray];
          setAvailableTypes(allOwnersAndWorkers);
        }
        else{
          allOwnersAndWorkers = [...getlocations, ...sortedDataArray];
          setAvailableTypes(allOwnersAndWorkers);
        }

      }
    } catch (error) {
      console.log('selectNetworkType error', error);
      //setErrorMessage('Please Check your network');
    } finally {
    }
  }

  return (
    <RBCardView
      style={{
        padding: 50,
        //  margin: Spacing.larger,
        marginTop: "10%",
        width: 700,
        display: "flex",

        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
          //  backgroundColor: "red",
        }}
      >
        <IconButton
          edge="start"
          style={{ color: Colors.themeBlue }}
          onClick={onArrowBackIconClick}
        >
          <ArrowBackIcon />
        </IconButton>
        
       
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4" style={{fontWeight:"bold", textAlign:"center"}}>Register Your Profile</Typography>
       
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom:20,
          paddingTop:50
        }}
      >
         <Typography variant="h6">Healthcare Profession</Typography>
      </div>
      
      <Select
        disableUnderline
        displayEmpty
        renderValue={networkSelected !== null ? undefined : () => "Select"}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        style={{width:350,background:Colors.themeDarkBlue,textAlign:'center',color:'white',borderRadius:4,fontSize:12,height:40}}
        onChange={(e, index) =>
          selectNetworkType(e.target.value, index)
        }>
        {networkTypes.map((value) => (
          <MenuItem value={value.industryMode} key={value.industryMode}>
            {value.displayName}
          </MenuItem>
        ))}
      </Select>
      {registerButton &&
      <>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom:15,
          paddingTop:50
        }}
      >
         <Typography variant="h6">Register As</Typography>
      </div>
        <Select
          disableUnderline
          displayEmpty
          renderValue={registerType !== null ? undefined : () => "Select"}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
          value={registerType}
          style={{width:350,background:Colors.themeDarkBlue,textAlign:'center',color:'white',borderRadius:4,fontSize:12,height:40}}
          onChange={(e) =>
            selectedRegisterType(e.target.value)
          }>
          {availableTypes.map((value) => {
            if ('locationType' in value) {
              return (<MenuItem value={value.locationType} key={value.locationType}>
                {value.locationType}
              </MenuItem>)
            }
            else {
              return (<MenuItem value={value.workerType} key={value.workerType}>
                {value.displayName}
              </MenuItem>)
            }
          })}
        </Select>
      </>
      }

      <div
        style={{
          display: "flex",
          flex: 1,
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingTop:80,
          //  backgroundColor: "red",
        }}
      >
          Powered by Relief Buddy 
       
      </div>
    

      {/* <RBButton
        style={{
          marginTop: Spacing.large,
          marginBottom: Spacing.large,
          minWidth: 400,
        }}
        onClick={() =>
          history.push(RoutePathConstant.RegisterOwnAndPharmacy, {
            background: location,
          })
        }
        text={"Pharmacy"}
      />

      <RBButton
        buttonBackgroundColor={Colors.themeGreen}
        style={{
          marginBottom: Spacing.large,
          minWidth: 400,
        }}
        onClick={() =>
          history.push(
            `${RoutePathConstant.RegisterWorker}${formatWorkerType(
              workerTypeEnum.pharmacist
            )}`,
            {
              background: location,
              workerType: workerTypeEnum.pharmacist,
            }
          )
        }
        text={formatWorkerType(workerTypeEnum.pharmacist)}
      />

      <RBButton
        buttonBackgroundColor={Colors.themeGreen}
        style={{
          marginBottom: Spacing.large,
          minWidth: 400,
        }}
        onClick={() =>
          history.push(
            `${RoutePathConstant.RegisterWorker}${formatWorkerType(
              workerTypeEnum.technician
            )}`,
            {
              background: location,
              workerType: workerTypeEnum.technician,
            }
          )
        }
        text={formatWorkerType(workerTypeEnum.technician)}
      />

      <RBButton
        buttonBackgroundColor={Colors.themeGreen}
        style={{
          marginBottom: Spacing.large,

          minWidth: 400,
        }}
        onClick={() =>
          history.push(
            `${RoutePathConstant.RegisterWorker}${formatWorkerType(
              workerTypeEnum.assistant
            )}`,
            {
              background: location,
              workerType: workerTypeEnum.assistant,
            }
          )
        }
        text={formatWorkerType(workerTypeEnum.assistant)}
      />

      <RBButton
        buttonBackgroundColor={Colors.themeGreen}
        style={{
          marginBottom: Spacing.large,
          minWidth: 400,
        }}
        onClick={() =>
          history.push(
            `${RoutePathConstant.RegisterWorker}${formatWorkerType(
              workerTypeEnum.pharmacistStudent
            )}`,
            {
              background: location,
              workerType: workerTypeEnum.pharmacistStudent,
            }
          )
        }
        text={formatWorkerType(workerTypeEnum.pharmacistStudent)}
      /> */}
      
    </RBCardView>
  );
};
