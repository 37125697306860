import React, { useState, useEffect ,useContext} from "react";
import { AuthService } from "../../api/AuthService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, Colors, FontSize } from "../../theme/Theme";
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioProps,
  withStyles,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { Claims, EmptyString } from "../../util/Common";
import { RBButton } from "../../components/RBButton";
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { useLocation, useHistory } from "react-router";
import { RoutePathConstant } from "../../util/Common";
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { RBContext } from "../../components/RBContext";
import { RBSelect } from "../../components/RBTextField";
import { IndustryModeEnum } from "../../api/constants";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { RBAlert } from "../../components/RBAlert";
import { AccountInfoContext } from "../../components/AccountInfoContext";

const GreenRadio = withStyles({
  root: {
    color: Colors.darkGrey,
    "&$checked": {
      color: Colors.black,
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

export const SwitchPharmacy = () => {
  const [availableTypes, setAvailableTypes] = useState<any[]>([]);
  const [selectedPharmacyId, setSelectedPharmacyId] = useState(EmptyString);
  const [accountInfo, setAccountInfo] = useState<ObjectLiteral>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [registerType, setRegisterType] = useState(null);
  const [isAlertShowing, setIsAlertShowing] = useState(false);
  const [alertInfo,setAlertInfo]  = useState <any> ({title:"",message:"", buttons:[]})
  const history = useHistory();
  const location = useLocation();
  const { industryMode,primaryColor,secondaryColor, updateMode } = useContext(RBContext);
  const onAccountChanged = (data: string) => {
    console.log("onAccountChanged", data);
    setRefresh((pre) => !pre);
  };
  const { setAccountInformation } = useContext(AccountInfoContext);
  useEffect(() => {
    const unsub = NotificationService.subscribe(
      NotificationServiceTopic.AccountChanged,
      onAccountChanged
    );
    const fetchData = async () => {
      const userData = await AuthService.loadAccountInfo();
      setAccountInfo(userData);
      console.log('userData switch location ', userData)
      if (userData.owner.defaultPharmacyId) {
        setSelectedPharmacyId(userData.owner.defaultPharmacyId);
        console.log('selectedPharmacyId ', userData.owner.defaultPharmacyId)
        const locationInfo = await AuthService.getLocation(userData.owner.defaultPharmacyId);
        console.log('locationInfo ', locationInfo);
        if ( locationInfo.data.companyType == null ) {
          updateMode(IndustryModeEnum.Pharmaceutical);
        } else {
          updateMode(IndustryModeEnum.Dentistry);
        }
      } else {
        setSelectedPharmacyId(userData.pharmacies[0].id);
        const locationInfo = await AuthService.getLocation(userData.pharmacies[0].id);
        console.log('locationInfo ', locationInfo);
        if ( locationInfo.data.companyType == null ) {
          updateMode(IndustryModeEnum.Pharmaceutical);
        } else {
          updateMode(IndustryModeEnum.Dentistry);
        }
      }

      const getAllLocationTypes = await AuthService.getAllLocationTypes();
        const allLocationTypes = getAllLocationTypes.filter((item:any) => (item?.locationType === "Dental Practice" || item?.locationType === "Pharmacy"))
        setAvailableTypes(allLocationTypes);
        console.log("AVAILABLE TYPES", allLocationTypes)
    };

    fetchData();
    return () => {
      console.log("SwitchPharmacy clean up");
      unsub.unsubscribe();
    };
  }, [refresh]);
  const onSave = async () => {
    try {
      setIsSubmitting(true);
      await AuthService.updateOwner({
        defaultPharmacyId: selectedPharmacyId,
      });
      const locationInfo = await AuthService.getLocation(selectedPharmacyId);
      localStorage.setItem("pharmacyName",locationInfo.data.name)
      const userData = await AuthService.loadAccountInfo();
      setAccountInformation(userData)
      console.log('accountInfo switch ', userData);
      if ( locationInfo.data.companyType == null ) {
        localStorage.setItem('industryMode',IndustryModeEnum.Pharmaceutical)
        updateMode(IndustryModeEnum.Pharmaceutical);
      } else {
        localStorage.setItem('industryMode',IndustryModeEnum.Dentistry)
        updateMode(IndustryModeEnum.Dentistry);
      }
      if (locationInfo.data.paymentType === null && accountInfo?.networks[0].paymentConfig.paymentEnabled) {
        setIsAlertShowing(true);
        setAlertInfo({
          title: "Payment Info Required",
          message: `Would you like to set up your payment information now?`,
          buttons: [
  
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false);
                //setAlertShowing(false);
                history.push('/paymentOption',{
                  tabType: 'Shift',
                  editMode: false,
                  newPharmacy: true
                });
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Yes"
            />,
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false);
                //setAlertShowing(false);
                history.push('/dashboard');
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="No"
            />,
          ],
        });
  
        
        
        return;
      }
      else{
        history.push(RoutePathConstant.DashBoard)
      }
    } catch (error) {
      console.log("switch pharmacy save  error ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderPharmacies = () => {
    // const secondaryColorFromNetwork = accountInfo?.networks[0].brand.secondaryColor;
    // console.log('secondaryColorFromNetwork ', seconda);
    return (
      <RBCardView
        //s   marginHorizontal={Spacing.large}
        style={{
          margin: Spacing.large,
          padding: Spacing.large,
          height: "100%",
        }}
      >
        {industryMode === IndustryModeEnum.Pharmaceutical ? 
          <Typography
            variant={"h6"}
            style={{ textAlign: "center", marginBottom: Spacing.larger }}
          >
            Select a pharmacy to edit its information or post a shift
          </Typography> : 
          <Typography
            variant={"h6"}
            style={{ textAlign: "center", marginBottom: Spacing.larger }}
          >
            Select a dental practice to edit its information or post a shift
          </Typography>
        }
        

        <FormControl
          component="fieldset"
          style={{ display: "flex", flex: 1, padding: Spacing.large }}
        >
          <RadioGroup
            value={selectedPharmacyId}
            onChange={(e) => setSelectedPharmacyId(e.target.value)}
          >
            {accountInfo?.pharmacies.map((pharmacy: any) => (
              <div
                key={pharmacy.id}
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  value={pharmacy.id}
                  control={<GreenRadio />}
                  label={pharmacy.name}
                />
                {pharmacy.approval.status !== "approved" && (
                  <div
                    style={{
                      fontSize: FontSize.small,
                      fontWeight: "lighter",
                      color: "red",
                    }}
                  >
                    Under Review
                  </div>
                )}
              </div>
            ))}
          </RadioGroup>
        </FormControl>
        {industryMode === IndustryModeEnum.Pharmaceutical ? 
          <Typography variant={"caption"} style={{ padding: Spacing.large }}>
          {'*Pharmacy ' + Claims.switchPharmacy}
          </Typography> :
          <Typography variant={"caption"} style={{ padding: Spacing.large }}>
          {'*Dental Practice ' + Claims.switchPharmacy}
          </Typography>
        }
        

        <div
          style={{
            ...ColumnStyle,
            padding: Spacing.large,
            marginTop: Spacing.xlarge,
            marginLeft: accountInfo?.networks[0].id === 'c7e5dab1-c3a2-4a18-9f97-7f652a25b9bd' ? "15%" :"20%",
            marginRight:accountInfo?.networks[0].id === 'c7e5dab1-c3a2-4a18-9f97-7f652a25b9bd' ? "15%" :"20%",
          }}
        >
          {accountInfo?.networks[0].id === 'c7e5dab1-c3a2-4a18-9f97-7f652a25b9bd' ? 
            <RBButton
              style={{ display: "flex"}}
              buttonBackgroundColor={secondaryColor}
              text="Register New Dental Practice"
              onClick={() =>
                history.push(RoutePathConstant.RegisterNewPharmacy, {
                  background: location,
                  ownerType: 'Dental Practice'
                })
            }
          /> :
          <Select
            disableUnderline
            displayEmpty
            renderValue={registerType !== null ? undefined : () => "Register New Location"}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            value={registerType}
            style={{width:"40%",
              background:secondaryColor,
              textAlign:'center',
              color:'white',
              borderRadius:9,
              fontSize:14,
              height:40,
            }}
            onChange={(event) => {
                debugger
                history.push(RoutePathConstant.RegisterNewPharmacy, {
                  background: location,
                  ownerType: event.target.value
                })
                // selectRegisterType(event)
              }
            }
          >
            {availableTypes.map((value) => (
              <MenuItem value={value.locationType} key={value.locationType}>
                {value.locationType}
              </MenuItem>
            ))}
          </Select>  
          }
          
    
          
          

          <RBEmptyBlock style={{ width: "20%" }} />
          <RBButton
            style={{ display: "flex", flex: 1 }}
            buttonBackgroundColor={secondaryColor}
            text="Save"
            isLoading={isSubmitting}
            onClick={onSave}
          />
        </div>
      </RBCardView>
    );
  };

  return <>
   <RBAlert
          show={isAlertShowing}
          alertTitle={alertInfo.title}
          alertMessage={alertInfo.message}
          buttons={alertInfo.buttons}
        />
  {renderPharmacies()}
  </>;
};
