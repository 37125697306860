import React from "react";
import {
  StepperProps,
  Step,
  StepLabel,
  Stepper,
  makeStyles,
  StepConnector,
  withStyles,
} from "@material-ui/core";
import { Colors, Spacing } from "../theme/Theme";

interface RBStepperProps {
  steps: string[];
  activeStep: number;
  primaryColor?: any;
  secondaryColor?: any;
}

// Connector styling


export const RBStepper: React.FC<RBStepperProps> = ({ ...props }) => {
  const RBConnector = withStyles({
    active: {
      "& $line": {
        borderColor: props.primaryColor,
      },
    },
    completed: {
      "& $line": {
        borderColor: props.secondaryColor,
      },
    },
    line: {
      borderColor: Colors.black,
      borderTopWidth: 2,
      borderRadius: 1,
    },
  })(StepConnector);
  
  const useStepIconStyles = makeStyles({
    // label text style
    label: { color: Colors.black },
    root: {
      // reduce the distance between label and icon
      marginBottom: -10,
    },
    active: {
      color: `${props.primaryColor} !important`,
    }, //needed so that the &$active tag works
    completed: {
      color: `${props.secondaryColor} !important`,
    },
    disabled: {},
  });

  const classes = useStepIconStyles();
  const { activeStep, steps } = props;
  return (
    <Stepper
      activeStep={activeStep}
      connector={<RBConnector />}
      alternativeLabel
      style={{
        display: "flex",
        flex: 1,
        flexGrow: 0,
        paddingTop: Spacing.large,
        paddingBottom: Spacing.large,
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: Colors.defaultGrey,
        //  backgroundColor: "red",
      }}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            //icon style props
            StepIconProps={{
              classes: {
                root: classes.root,
                completed: classes.completed,
                active: classes.active,
              },
            }}
            //label style props
            classes={{
              label: classes.label,
              active: classes.active,
              completed: classes.completed,
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
