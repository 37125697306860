import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
// The Main Subject/Stream to be listened on.
//const mainSubject = new Subject(); // This function is used to publish data to the Subject via next().

//export const publish = (topic: string, data: string) => mainSubject.next(data);

class NotificationService {
  private mainSubject: Subject<any>;
  constructor() {
    this.mainSubject = new Subject();
  }
  publish(topic: string, data: string) {
    this.mainSubject.next({ topic, data });
  }
  subscribe(topic: string, subscribeFunc: any) {
    return this.mainSubject
      .pipe(filter((f) => f.topic === topic))
      .subscribe(subscribeFunc);
  }
  unsubscribe() {
    this.mainSubject.unsubscribe();
  }
}

const singleton = new NotificationService();
export default singleton;

const ShiftChanged = "shift changed";
const AccountChanged = "account changed";

export const NotificationServiceTopic = { ShiftChanged, AccountChanged };
