import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, ErrorMessageProps } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import {
  RBTextFieldForForm,
  RBTextFieldForFormFieldType,
} from "../../components/RBTextField";
import { RBButton } from "../../components/RBButton";
import * as Yup from "yup";
import { makeStyles, Link, IconButton, Grid } from "@material-ui/core";
import {
  Spacing,
  AppContainerWidth,
  Colors,
  FontSize,
} from "../../theme/Theme";
import { RoutePathConstant, roleNameEnum } from "../../util/Common";
import { RBCardView } from "../../components/RBCardView";
import { AuthService } from "../../api/AuthService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  InputContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: Spacing.larger,
    marginLeft: 4 * Spacing.larger,
    marginRight: 4 * Spacing.larger,
    [theme.breakpoints.down('sm')]: {
     width:"80%"
    },
  },
  card: {
    padding: Spacing.larger,
    maxWidth: 700,
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: Spacing.medium,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 2 * Spacing.larger,
    marginBottom: Spacing.larger,
    paddingLeft: 3 * Spacing.large,
    paddingRight: 3 * Spacing.large,
  },
  button:{
      width: "80%",
      paddingLeft: Spacing.large,
      paddingRight: Spacing.large,
      paddingTop: Spacing.medium,
      paddingBottom: Spacing.medium,
      marginBottom:10,
        marginTop:10,

      minHeight:50,
      margin:"auto"
  },
  logo:{
    display: "flex",
    flex: 1,
    maxWidth: "100%",
    maxHeight: "100%",
    paddingLeft: 3 * Spacing.large,
    paddingRight: 3 * Spacing.large,
    paddingTop: Spacing.large,
    paddingBottom: Spacing.large,
    marginBottom: 2 * Spacing.large,
    [theme.breakpoints.down('sm')]: {
      marginLeft:"15%"
     },
  }
}));

interface LoginProps { }

export const LoginPasswordPage: React.FC<LoginProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const invalidAccountStates = ['pending', 'denied'];

  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();

  const location = useLocation<{
    email: any;
    userData: any;
  }>();

  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  const isUserPending = (userData: any) => {
    if (userData.owner) {
      return (
        invalidAccountStates.indexOf(userData.owner.approval.status) >= 0
      );
    }

    if (userData.pharmacist) {
      return (
        invalidAccountStates.indexOf(userData.pharmacist.approval.status) >=
        0
      );
    }

    return false;
  };
  const getUserAccountStatus = (userData: any) => {
    if (userData.owner) {
      return userData.owner.approval.status;
    }
    if (userData.pharmacist) {
      return userData.pharmacist.approval.status;
    }
    return 'approved';
  };

  const onArrowBackIconClick = () => {
    history.push(RoutePathConstant.Login);
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: '100vh', width:"100%" }}
    >
     <Grid item xs={12} sm={10} md={8} lg={6}>
      <RBCardView className={classes.card}>
      <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
            marginLeft: 5
          }}
        >
        <IconButton
          edge="start"
          style={{ color: Colors.themeBlue }}
          onClick={onArrowBackIconClick}
        >
          <ArrowBackIcon />
        </IconButton>
      </div>
      <Formik
        validateOnChange={true}
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required("Required"),
        })}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          try {

            await AuthService.signIn({
              email: location.state.email,
              password: data.password,
            });
            const userData = await AuthService.loadAccountInfo();
            if (isUserPending(userData)) {
              history.push(RoutePathConstant.PendingApproval + (getUserAccountStatus(userData)));
            }
            else {
              history.push(RoutePathConstant.DashBoard);
            }

            //await new Promise((resolve) => setTimeout(resolve, 3000));

          } catch (err) {
            console.log("sign in failed err", err.response);
            let message;
            if(err.response){
              if(err.response.data.message && err.response.data.message.includes('Invalid email address or password'))
              {
                message = 'Invalid password';
              }
              else{
                message = err.message
              }
              setSubmitErrorMessage(message);
            }
           
          } finally {
            isMountedRef.current && setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleBlur,
          isValid,
          handleSubmit,
          handleChange,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <img
              src={location.state.userData.networks[0].brand.emailLogo}
              alt="logo"
              className={classes.logo}
            />
            <div
                className={classes.InputContainer}
                style={{ marginBottom: Spacing.xlarge }}
              >
              <RBTextFieldForForm
                placeholder="Password"
                FieldType={RBTextFieldForFormFieldType.password}
                name="password"
                onChange={handleChange}
                value={values.password}
                onBlur={handleBlur}
              />
            </div>
            <div
              className={classes.InputContainer}
              style={{ justifyContent: "flex-end", marginTop: Spacing.medium }}
            >
              <Link
                style={{
                  color: Colors.themeDarkBlue
                }}
                component="button"
                type="button"
                variant="body2"

                onClick={() => {
                  history.push(RoutePathConstant.ForgetPassword);
                }}
              >
                Forgot Password?
              </Link>
            </div>
            {submitErrorMessage && (
              <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                marginTop: Spacing.medium,
                marginBottom: Spacing.medium,
                color: "red",
                fontSize: FontSize.large,
              }}
              >
                {submitErrorMessage}
              </div>
            )}

            <div  className={classes.InputContainer}
                style={{ marginBottom: Spacing.xlarge }}>

              <RBButton
                 style={{
                  width: "100%",
                  paddingLeft: Spacing.large,
                  paddingRight: Spacing.large,
                  paddingTop: Spacing.medium,
                  paddingBottom: Spacing.medium,
                  marginBottom: 30
                }}
                buttonBackgroundColor={location.state.userData.networks[0].brand.primaryColor}
                text="Sign In"
                isLoading={isSubmitting}
                disabled={!isValid}
                variant="contained"
                type="submit"
              />
            </div>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>
    </RBCardView>
    </Grid>
    </Grid>
  );
};
