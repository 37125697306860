import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";

import { RBSwitch } from "../../../components/RBSwitch";
import { Spacing } from "../../../theme/Theme";
import { AuthService } from "../../../api/AuthService";
import { workerTypeEnum } from "../../../util/Enumeration";

export const QUESTIONS = [
  "Are you legally entitled to work in Canada?",
  "Are you currently registered as an active member and in good standing with your provincial licensing authority?",
  "Do you have valid Personal Professional Liability insurance as required by your provincial licensing authority?",
  "Have you ever had your professional license restricted, suspended, or revoked by a provincial licensing authority?",
  "Have you ever been found guilty of professional malpractice, misconduct, or incapacitated by your provincial licensing authority?",
  "Have you ever been convinced of a felony or been charged with a criminal offense for which a pardon was not granted?",
];

export interface LicensedDentistProfileValueTypes {
  q1: boolean;
  q2: boolean;
  q3: boolean;
  q4: boolean;
  q5: boolean;
  q6: boolean;
}

interface LicensedDentistProfileProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: LicensedDentistProfileValueTypes;
  previousFormData?: any;
  workerType?: workerTypeEnum;
  account?:any;
}

export const LicensedDentistProfile: React.FC<LicensedDentistProfileProps> = ({
  ...props
}) => {
  const [licensedQN, setLicensedQN] = useState(true);
  useEffect(() => {
    (async () => {
      const workerTypeDetail = await AuthService.getActiveWorkerdetails(props.workerType);
      if (workerTypeDetail?.licnsedFieldFalseProvinces && workerTypeDetail.licnsedFieldFalseProvinces?.length > 0) {
        if (workerTypeDetail.licnsedFieldFalseProvinces.includes(props?.previousFormData?.licenseProvince)) {
          if(props?.previousFormData?.licensedDate == "")
            setLicensedQN(false)
          else
            setLicensedQN(true)
        }
        else {
          setLicensedQN(true)
        }
      }
    })();       
  },[])

  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      // validationSchema={Schema.UserLocation}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={true}
      onSubmit={() => {}}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
           {licensedQN ? 
           <>
            <RBSwitch
              checked={values.q1}
              onChange={handleChange}
              name="q1"
              label={QUESTIONS[0]}
              formControlStyle={{ marginBottom: Spacing.large }}
            />
            <RBSwitch
              checked={values.q2}
              onChange={handleChange}
              name="q2"
              label={QUESTIONS[1]}
              formControlStyle={{ marginBottom: Spacing.large }}
            />
            <RBSwitch
              checked={values.q3}
              onChange={handleChange}
              name="q3"
              label={QUESTIONS[2]}
              formControlStyle={{ marginBottom: Spacing.large }}
            />
            <RBSwitch
              checked={values.q4}
              onChange={handleChange}
              name="q4"
              label={QUESTIONS[3]}
              formControlStyle={{ marginBottom: Spacing.large }}
            />
            <RBSwitch
              checked={values.q5}
              onChange={handleChange}
              name="q5"
              label={QUESTIONS[4]}
              formControlStyle={{ marginBottom: Spacing.large }}
            />
            <RBSwitch
              checked={values.q6}
              onChange={handleChange}
              name="q6"
              label={QUESTIONS[5]}
              formControlStyle={{ marginBottom: Spacing.large }}
            />
          </>:
           <>
            <RBSwitch
            checked={values.q1}
            onChange={handleChange}
            name="q1"
            label={QUESTIONS[0]}
            formControlStyle={{ marginBottom: Spacing.large }}
          />

          <RBSwitch
            checked={values.q6}
            onChange={handleChange}
            name="q6"
            label={QUESTIONS[5]}
            formControlStyle={{ marginBottom: Spacing.large }}
          />
        </>
            
        }
        </Form>
      )}
    </Formik>
  );
};
