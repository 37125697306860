import React, { useEffect, useState, createContext,useContext } from "react";
import {
  withRouter,
  RouteComponentProps,
  useHistory,
  Link,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Colors, Spacing, UIRelatedConstants } from "../../theme/Theme";
import { RoutePathConstant, roleNameEnum } from "../../util/Common";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { useLastLocation } from "react-router-last-location";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum } from "../../api/constants";
import { AuthService } from "../../api/AuthService";
import { PharmacyService } from "../../api/PharmacyService";
import { RBShiftLayoutCardForApplicant } from "../../components/RBShiftLayoutCardForApplicant";
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBCardView } from "../../components/RBCardView";
import { RBAppbar } from "../../components/RBAppbar";
import { Checkbox } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";

const useStyles = makeStyles({
  Card: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  CardContent: {
    paddingTop: 8,
    "&:last-child": {
      paddingBottom: 8,
    },
  },
});

interface ApplicantsShiftPageWithoutRouteProps {}

const ApplicantsShiftPageWithoutRoute: React.FC<ApplicantsShiftPageWithoutRouteProps> = ({
  ...props
}) => {
  const {industryMode, primaryColor,secondaryColor,updateprimaryColor,updateSecondaryColor,updateContactEmail,updateContactPhone,updateMode } = useContext(RBContext);

  const [isAlertShowing, setShiftAlertShowing] = useState(false);
  const [shiftAlertInfo, setShiftAlertInfo] = useState<AlertInfoProps>({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const location = useLocation<{
    account: any;
    userId: any;
    pharmacist: any;
    pharmacy:any
  }>();
  const history = useHistory();
  const classes = useStyles();

  const lastLocation = useLastLocation();
  const [account, setAccount] = useState<any>([]);
  const [shifts, setShifts] = useState([]);
  const [roleName, _] = useState<string>(AuthService.getRoleName());
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedShifts, setSelectedShifts] = useState<any>({});
  const [disabledButtons, setDisabledButtons] = useState(true);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);
  const [selectedShiftIds, setSelectedShiftIds] = useState([]);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);




  const onClickShiftCard = (shiftId: string) => {
    const item = document.querySelector(".restore-" + shiftId);

    history.push(`${RoutePathConstant.ShiftDetail}${shiftId}`, {
      shiftId: shiftId,
      scrollY: window.scrollY,
    });
    console.log(
      "shift onClickShiftCard id ",
      shiftId,
      window.scrollY,
      item?.getBoundingClientRect()
    );
  };
 

  useEffect(() => {

    (async () => {
      await updateprimaryColor( localStorage.getItem('primaryColor')); 
      await updateSecondaryColor( localStorage.getItem('secondaryColor')); 
      await updateContactEmail( localStorage.getItem('contactEmail')); 
      await updateContactPhone( localStorage.getItem('contactPhone')); 
      await updateMode(localStorage.getItem('industryMode')); 
      const userData = await AuthService.loadAccountInfo();
      setAccount(userData);
      try {
       
      const data = await PharmacyService.getShiftsByApplicant(location.state.pharmacy.id,location.state.userId);
      const newData = data.data.data;
      newData.sort((a:any, b:any) => {
        return b.shiftToRequests.length - a.shiftToRequests.length;
      });

      setShifts(newData)
    } catch (err) {
        setSubmitErrorMessage(err.response ? err.response.data.message : err.message);
        console.log("LocationApplicantsPageWithoutRouter error", err);
      } finally {
        setIsPageLoading(false);
      }

  })(); 
    
  }, []);


 // Toggle selection of a shift
 const toggleShiftSelection = (index:any, shiftId:any) => {
  const newSelectedShifts:any = { ...selectedShifts };
  const newSelectedShiftIds:any = [...selectedShiftIds];

  newSelectedShifts[index] = !newSelectedShifts[index];

  if (newSelectedShifts[index]) {
    newSelectedShiftIds.push(shiftId);
  } else {
    const shiftIndex = newSelectedShiftIds.indexOf(shiftId);
    if (shiftIndex !== -1) {
      newSelectedShiftIds.splice(shiftIndex, 1);
    }
  }

  const disabledButtons = Object.values(newSelectedShifts).every((value) => !value); // Disable buttons if no shift is selected
  const shiftsWithRequests = shifts.filter((shift:any) => shift.shiftToRequests.length > 0);
const shiftsWithRequestsLength = shiftsWithRequests.length;

  const newSelectAll = newSelectedShiftIds.length === shiftsWithRequestsLength; // Check if all shifts are selected
  setSelectedShifts(newSelectedShifts)
  setSelectedShiftIds(newSelectedShiftIds)
  setDisabledButtons(disabledButtons)
  setSelectAll(newSelectAll)
 
};

// Toggle "Select All" checkbox
const toggleSelectAll = () => {

  const newSelectedShifts:any = {};
  const newSelectedShiftIds:any = [];

  // If "Select All" is currently checked, uncheck all and disable buttons
  if (selectAll) {
    Object.keys(selectedShifts).forEach((key) => {
      newSelectedShifts[key] = false;
    });
    setSelectedShifts(newSelectedShifts)
    setSelectedShiftIds([])
    setDisabledButtons(true)
    setSelectAll(false)
  } else { // Otherwise, check all and enable buttons
    shifts.forEach((shift:any, index:any) => {
      newSelectedShifts[index] = true;
      if (shift.shiftToRequests.length > 0) { // Ensure shiftToRequests.length > 0 before adding ID
        newSelectedShiftIds.push(shift.id); // Store the ID of each selected shift
      }
    });
    setSelectedShifts(newSelectedShifts)
    setSelectedShiftIds(newSelectedShiftIds)
    setDisabledButtons(false)
    setSelectAll(true)

  }
};

const selectPharmacist = async () => {

  if (acceptLoading) {
    return;
  }
  setAcceptLoading(true)
  setShowAcceptModal(false)
  try {
    console.log("selectedShiftIds",selectedShiftIds)
    await PharmacyService.acceptApplicantBulk(selectedShiftIds,location.state.userId);
    setShiftAlertInfo({
      title: "Success",
      message: "Successfully selected applicant!",
      buttons: [
        <RBButton
          key={"ok"}
          onClick={() => {
            setShiftAlertShowing(false);
            history.push("/dashboard");
          }}
          text="Home"
          buttonBackgroundColor={secondaryColor}
        />,
      ],
    });
    setShiftAlertShowing(true);
    
  } catch (error) {
    console.log('error: ', { ...error });
    const violation = error.response.data;
    if (
      violation.error.includes(
        'No request exists from this user for this shift',
      )
    ) {
      setShiftAlertInfo({
        title: "The worker is not available any more",
        message: "Please select a different worker and try again.",
        buttons: [
          <RBButton
            key={"ok"}
            onClick={() => {
              setShiftAlertShowing(false);
            }}
            text="Ok"
            buttonBackgroundColor={secondaryColor}
          />,
        ],
      });
      setShiftAlertShowing(true);
    }
    if (
      violation.error.includes('conflicted shift') 
    ) {
      setShiftAlertInfo({
        title: "Conflicted shift",
        message: "This worker has a conflicted shift.",
        buttons: [
          <RBButton
            key={"ok"}
            onClick={() => {
              setShiftAlertShowing(false);
            }}
            text="Ok"
            buttonBackgroundColor={secondaryColor}
          />,
        ],
      });
      setShiftAlertShowing(true);
    }
  } finally {
    setAcceptLoading(false)
  }
};

const rejectPharmacist = async() => {

  if (denyLoading) {
    return;
  }
  setDenyLoading(true)
  setShowRejectModal(false)
  try {
    console.log("selectedShiftIds",selectedShiftIds)
   let response = await PharmacyService.rejectApplicantBulk(selectedShiftIds,location.state.userId);
    setShiftAlertInfo({
      title: "Success",
      message: "Successfully rejected applicant!",
      buttons: [
        <RBButton
          key={"ok"}
          onClick={() => {
            setShiftAlertShowing(false);
            history.push("/dashboard");
          }}
          text="Home"
          buttonBackgroundColor={secondaryColor}
        />,
      ],
    });
    setShiftAlertShowing(true);
    
  } catch (error) {
    console.log('error: ', { ...error });
    const violation = error.response.data;
  } finally {
   setDenyLoading(false)
  }
};



const onAcceptClose = () => {
  setShowAcceptModal(false);
}
const onRejectClose= () => {
  setShowRejectModal(false);
}
const renderAcceptModalContent = () => {
  const name = `${location.state.pharmacist.firstName} ${location.state.pharmacist.lastName}`;
  return (
    <div
      style={{
        backgroundColor: Colors.defaultGrey,
        display: "flex",
        flex: 1,
      }}
    >
      
        <RBCardView
          style={{
            flex: 1,
            marginLeft: Spacing.large,
            marginRight: Spacing.large,
            marginBottom: Spacing.smallest,
            paddingLeft: Spacing.large,
            paddingTop: Spacing.large,
            paddingRight: Spacing.large,
            paddingBottom: Spacing.large,
          }}
        >
         <div>
         Hire {name} for {selectedShiftIds.length > 1 ? "shifts" : "shift"}?
         </div>
         <br></br>
         <div style={{
          display: "flex",
          flex: 1,
          alignItems: "flex-end",
        }}>
              <RBButton
                buttonBackgroundColor={primaryColor}
                text="Confirm"
                onClick={() => selectPharmacist()}
                style={{
                  display: "flex",
                  flex: 1,
                  padding: Spacing.medium,
                  margin: Spacing.large,
                }}
              />
              <RBButton
                buttonBackgroundColor={primaryColor}
                isLoading={isButtonLoading}
                text="Cancel"
                onClick={() => {
                  setShowAcceptModal(false)
                  }
                }
                style={{
                  display: "flex",
                  flex: 1,
                  padding: Spacing.medium,
                  margin: Spacing.large,
                }}
              />
         </div>
        </RBCardView>
     
    </div>
  );
};
const renderRejectModalContent = () => {
  const name = `${location.state.pharmacist.firstName} ${location.state.pharmacist.lastName}`;
  return (
    <div
      style={{
        backgroundColor: Colors.defaultGrey,
        display: "flex",
        flex: 1,
      }}
    >
      
        <RBCardView
          style={{
            flex: 1,
            marginLeft: Spacing.large,
            marginRight: Spacing.large,
            marginBottom: Spacing.smallest,
            paddingLeft: Spacing.large,
            paddingTop: Spacing.large,
            paddingRight: Spacing.large,
            paddingBottom: Spacing.large,
          }}
        >
         <div>
         Candidate {name} will no longer be eligible for {selectedShiftIds.length > 1 ? "these shifts" : "this shift"}.
         </div>
         <br></br>
         <div style={{
          display: "flex",
          flex: 1,
          alignItems: "flex-end",
        }}>
              <RBButton
                buttonBackgroundColor={primaryColor}
                text="Confirm"
                onClick={() => rejectPharmacist()}
                style={{
                  display: "flex",
                  flex: 1,
                  padding: Spacing.medium,
                  margin: Spacing.large,
                }}
              />
              <RBButton
                buttonBackgroundColor={primaryColor}
                isLoading={isButtonLoading}
                text="Cancel"
                onClick={() => {
                  setShowRejectModal(false)
                  }
                }
                style={{
                  display: "flex",
                  flex: 1,
                  padding: Spacing.medium,
                  margin: Spacing.large,
                }}
              />
         </div>
        </RBCardView>
     
    </div>
  );
};


 

  return (
    <>
      <RBAlert
        show={isAlertShowing}
        alertTitle={shiftAlertInfo.title}
        alertMessage={shiftAlertInfo.message}
        buttons={shiftAlertInfo.buttons}
      />
       <RBModal
          open={showAcceptModal}
          onClose={onAcceptClose}
          modalTitle={"Confirm Applicant"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
          children={
            renderAcceptModalContent()
          }
        />
        <RBModal
          open={showRejectModal}
          onClose={onRejectClose}
          modalTitle={"Reject Applicant"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
          children={
            renderRejectModalContent()
          }
        />

         {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <>
        {shifts.length > 0 && 
        <>
            <div style={{minHeight:"45%",maxHeight:"80%",overflowY: "auto", paddingTop: Spacing.large }}>
              <div
                style={{
                  paddingLeft: Spacing.large,
                  paddingRight: Spacing.large,
                  paddingBottom: Spacing.medium,
                }}
              >
                <Card
                  variant="outlined"
                  className={classes.Card}
                  style={{
                    borderRadius: UIRelatedConstants.CardBorderRadius,
                    borderWidth: 3,
                    borderColor: primaryColor,
                  }}
                >
                  <CardContent className={classes.CardContent} onClick={() =>
                        toggleSelectAll()
                      }>
                    <div style={{ display: "flex", flexDirection: "row",marginLeft:-10}}>
                      <Checkbox
                        checked={selectAll}
                        style={{
                          color: selectAll ? secondaryColor : Colors.darkGrey,
                        }}
                      onChange={() =>
                        toggleSelectAll()
                      }
                      /> 
                      <span style={{marginTop:10}}>Select All</span>
                    </div>
                  </CardContent>
                </Card>
              </div>
              {shifts?.map((shift: any,index:any) => (
                <>
                  <div key={shift.id} className={`restore-${shift.id}`}>
                    <RBShiftLayoutCardForApplicant
                      key={index}
                      shift={shift}
                      onClick={onClickShiftCard}
                      rolename={roleName}
                      account={account}
                      selected={!!selectedShifts[index]}
                      toggleShiftSelection={() => toggleShiftSelection(index,shift.id)}
                    />
                  </div>

                </>
              ))}

            </div>
          <div style={{ 
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              paddingBottom: Spacing.medium,bottom: 0}}>
              <RBButton
              isLoading={acceptLoading}
                style={{
                  display: "flex",
                  flex: 1,
                  padding: Spacing.medium,
                  margin: Spacing.large,
                }}
                disabled={disabledButtons || denyLoading}
                buttonBackgroundColor={secondaryColor}
                onClick={() => {
                  setShowAcceptModal(true)
                }}
                text="Select"
              />
              <RBButton
                style={{
                  display: "flex",
                  flex: 1,
                  padding: Spacing.medium,
                  margin: Spacing.large,
                }}
                isLoading={denyLoading}
                disabled={disabledButtons || acceptLoading}
                buttonBackgroundColor={primaryColor}
                onClick={() => {
                  setShowRejectModal(true)
                }}
                text="Reject"
              />
            </div>
        </>}
        {shifts.length === 0 && (
          <RBCardView
            marginHorizontal={Spacing.medium}
            style={{
              padding: Spacing.large,
              marginTop: Spacing.medium,
            }}
          >
           No items found.
          </RBCardView>
        )}
        </>
         
      )}
    </>
  );
};

export const ApplicantsShiftPage = withRouter(ApplicantsShiftPageWithoutRoute);
