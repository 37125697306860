import * as Yup from "yup";
import { Regexes } from "../../util/Default";
import moment from "moment";
import phone from 'phone';
import validator from 'validator';

const UserAccountInformation = Yup.object().shape({
  email: Yup.string()
        .required("Required")
        // .test("DOB", "must be a valid email address", (value) => {
        //   return validator.isEmail(value);
        // }),
        .matches(Regexes.emailPattern, "must be a valid email address"),
  password: Yup.string()
    .required("Required")
    .matches(Regexes.PasswordValidation, "Password does not pass validation"),
  confirmPassword: Yup.string()
    .required("Required")
    .matches(
      Regexes.PasswordValidation,
      "Confirm password does not pass validation"
    )
    .oneOf([Yup.ref("password")], "Passwords must match"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(Regexes.PhoneValidation, "Invalid phone number"),
    // .test("DOB", "must be a valid phone number", (value) => {
    //   return validator.isMobilePhone(value, 'en-CA');
    // }),
});

const UserLocation = Yup.object().shape({
  street: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  postalCode: Yup.string()
    .required("Required")
    .matches(Regexes.CanadaPostalCodeValidation, "Invalid postal code"),
  province: Yup.string().required("Required"),
});

const PharmacistInformation = Yup.object().shape({
  registrationNumber: Yup.string().required("Required"),
  provinceOfRegistration: Yup.string().required("Required"),
  graduationYear:  Yup.string()
  .required("Required")
  .matches(Regexes.yearPattern, "Invalid Date Format. Must be YYYY")
  .test("DOB", "Invalid graduation year", (value) => {
    console.log(
      "AssistantInformation graduationYear value yup ",
      value,
      moment(value, "YYYY").toString(),
      moment().diff(moment(value), "years")
    );
    return (
      moment(value, "YYYY").year() >= 1900 &&
      moment(value, "YYYY").year() <= moment().year()
    );
  }),
  schoolName: Yup.string().required("Required"),
  licensedDate: Yup.string()
                .required("Required")
                .matches(Regexes.yearMonthPattern, "must be a valid year/month")
                .test("DOB", "must be a valid year/month", (value) => {
                  console.log('value',value)
                  return (
                    moment().isAfter(moment(value))
                  );  
                }),
});
const AssistantInformation = Yup.object().shape({
  graduationYear: Yup.string()
    .required("Required")
    .matches(Regexes.yearPattern, "Invalid Date Format. Must be YYYY")
    .test("DOB", "Invalid graduation year", (value) => {
      console.log(
        "AssistantInformation graduationYear value yup ",
        value,
        moment(value, "YYYY").toString(),
        moment().diff(moment(value), "years")
      );
      return (
        moment(value, "YYYY").year() >= 1900 &&
        moment(value, "YYYY").year() <= moment().year()
      );
    }),
  schoolName: Yup.string().required("Required"),
});

const StudentInformation = Yup.object().shape({
  currentSchoolYear: Yup.string().required("Required"),
  schoolName: Yup.string().required("Required"),
  pharmacyAssistantExperienceMonths: Yup.number().required("Required"),
  registrationNumber: Yup.string().required("Required"),
});
const PhotoInformation = Yup.object().shape({
  agreePrivacyPolicy: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
  //frontSideID: Yup.object().nullable().required("Front Required"),
  frontSideID: Yup.mixed().required("Front side picture Required"),

  backSideID: Yup.mixed().required("Back Required"),
  profileImage: Yup.mixed().required("Profile Required"),
});

const LicensedDentistInformation = Yup.object().shape({

  licenseProvince: Yup.string().required("Required"),
  licenseNumber: Yup.string().required("Required"),
  graduationYear: Yup.string()
  .required("Required")
  .matches(Regexes.yearPattern, "Invalid Date Format. Must be YYYY")
  .test("DOB", "Invalid graduation year", (value) => {
    console.log(
      "AssistantInformation graduationYear value yup ",
      value,
      moment(value, "YYYY").toString(),
      moment().diff(moment(value), "years")
    );
    return (
      moment(value, "YYYY").year() >= 1900 &&
      moment(value, "YYYY").year() <= moment().year()
    );
  }),
  graduationInstitution: Yup.string().required("Required"),
  licensedDate: Yup.string().required("Required")
              .matches(Regexes.yearMonthPattern, "must be a valid year/month")
              .test("DOB", "must be a valid year/month", (value) => {
                console.log('value',value)
                return (
                  moment().isAfter(moment(value))
                );  
              }),

  licenseExpiryDate: Yup.string().required("Required")
                .matches(Regexes.yearMonthPattern, "must be a valid year/month")
                .test("DOB", "Must be a valid year/month", (value) => {
                  const currentDate = moment();
                  const selectedDate = moment(value, "YYYY-MM"); // Assuming "YYYY-MM" format

                  return selectedDate.isSameOrAfter(currentDate, "month");
                  // );
                })
});

const LicensedDentistInformationProvinceChange = Yup.object().shape({
  licenseNumber: Yup.string().notRequired(), // Add this line to relax validation
  licenseProvince: Yup.string().notRequired(), // Add this line to relax validation
  licensedDate: Yup.string().notRequired(), // Add this line to relax validation
  licenseExpiryDate: Yup.string().notRequired(), 
  graduationYear: Yup.string()
  .required("Required")
  .matches(Regexes.yearPattern, "Invalid Date Format. Must be YYYY")
  .test("DOB", "Invalid graduation year", (value) => {
    console.log(
      "AssistantInformation graduationYear value yup ",
      value,
      moment(value, "YYYY").toString(),
      moment().diff(moment(value), "years")
    );
    return (
      moment(value, "YYYY").year() >= 1900 &&
      moment(value, "YYYY").year() <= moment().year()
    );
  }),
  graduationInstitution: Yup.string().required("Required"),
});

const NonLicensedDentistInformation = Yup.object().shape({
  experienceMonth: Yup.string().optional(),
  graduationYear: Yup.string()
  .required("Required")
  .matches(Regexes.yearPattern, "Invalid Date Format. Must be YYYY")
  .test("DOB", "Invalid graduation year", (value) => {
    console.log(
      "AssistantInformation graduationYear value yup ",
      value,
      moment(value, "YYYY").toString(),
      moment().diff(moment(value), "years")
    );
    return (
      moment(value, "YYYY").year() >= 1900 &&
      moment(value, "YYYY").year() <= moment().year()
    );
  }),
  graduationInstitution: Yup.string().required("Required"),
});

export const Schema = {
  UserAccountInformation,
  UserLocation,
  PharmacistInformation,
  AssistantInformation,
  PhotoInformation,
  StudentInformation,
  LicensedDentistInformation,
  NonLicensedDentistInformation,
  LicensedDentistInformationProvinceChange
};
