import React, { useEffect, useState, createContext,useContext } from "react";
import {
  withRouter,
  RouteComponentProps,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
import {
  Grid,
  GridList,
  GridListTile,
  Paper,
  List,
  ListItem,
} from "@material-ui/core";
import { ShiftProps } from "../../interfacesProps/ShiftProps";
import { makeStyles } from "@material-ui/core/styles";
import { RBJobLayoutCard } from "../../components/RBJobLayoutCard";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Spacing } from "../../theme/Theme";
import { RoutePathConstant, roleNameEnum } from "../../util/Common";
import { RBPopover } from "../../components/RBPopOver";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { JobListContext } from "../../components/JobListContext";
import { ShiftRelatedConstants } from "../../util/ShiftConstants";
import { ShiftService } from "../../api/ShiftService";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { useLastLocation } from "react-router-last-location";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum } from "../../api/constants";
import { AuthService } from "../../api/AuthService";
import { RBCardView } from "../../components/RBCardView";


interface JobListPageWithoutRouteProps extends RouteComponentProps<any> {
  jobs: ShiftProps[];
  roleName: string;
}

const JobListPageWithoutRoute: React.FC<JobListPageWithoutRouteProps> = ({
  history,
  match,
  ...props
}) => {
  const { jobs, roleName } = props;
  const {industryMode, primaryColor,secondaryColor,updateprimaryColor,updateSecondaryColor,updateContactEmail,updateContactPhone,updateMode } = useContext(RBContext);

  const [isAlertShowing, setShiftAlertShowing] = useState(false);
  const [shiftAlertInfo, setShiftAlertInfo] = useState<AlertInfoProps>({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const location = useLocation<{ selectedJobId?: string }>();
  const lastLocation = useLastLocation();
  const [account, setAccount] = useState<any>([]);
  console.log("jobs",jobs)

  // when there is a need to talk to API
  const onActionRelatedToAPI = async (buttonText: string, jobId: string) => {
    try {
      setShiftAlertShowing(false);
      setIsButtonLoading(true);

      if (roleName === roleNameEnum.owner) {
        switch (buttonText) {
          case ShiftRelatedConstants.ShiftButtonText.CancelShift: {
            await ShiftService.cancelJobById(jobId);
            break;
          }
        }
      } else if (roleName === roleNameEnum.pharmacist) {
        switch (buttonText) {
          case ShiftRelatedConstants.ShiftButtonText.CancelShift: {
            await ShiftService.cancelJobById(jobId);
            break;
          }
          case ShiftRelatedConstants.ShiftButtonText.WithdrawJob: {
            console.log("worker  WithdrawShift");
            await ShiftService.withdrawRequestedJobById(jobId);
            break;
          }
        }
      }
      NotificationService.publish(
        NotificationServiceTopic.ShiftChanged,
        jobId
      );
    } catch (err) {
      setSubmitErrorMessage(err.response ? err.response.data : err.message);
      console.log("onActionRelatedToAPI error ", err);
    } finally {
      console.log("onActionRelatedToAPI finally ");
      setIsButtonLoading(false);
    }
  };

  const OnJobListButtonClick = (buttonText: string, jobData: any) => {
    console.log("OnShiftListButtonClick ", buttonText, jobData);
    if (roleName === roleNameEnum.owner) {
      switch (buttonText) {
        case ShiftRelatedConstants.ShiftButtonText.ViewApplications:
          history.push(RoutePathConstant.JobApplicant + jobData.id);
          break;
        case ShiftRelatedConstants.JobStateText.RateJob:
          history.push(RoutePathConstant.ShiftSurvey + jobData.id);
          break;
        case ShiftRelatedConstants.ShiftButtonText.EditJob:
          industryMode === IndustryModeEnum.Pharmaceutical ? history.push(RoutePathConstant.EditJob + jobData.id) : history.push(RoutePathConstant.EditeDentalJob + jobData.id);
          break;
      }
    } else if (roleName === roleNameEnum.pharmacist) {
      switch (buttonText) {

        case ShiftRelatedConstants.ShiftButtonText.WithdrawJob: {
          setShiftAlertInfo({
            title: "Withdraw from Job",
            message: "Are you sure you wish to withdraw from this job?",
            buttons: [
              <RBButton
              key={"confirm"}
              onClick={async () => {
                await onActionRelatedToAPI(
                  ShiftRelatedConstants.ShiftButtonText.WithdrawJob,
                  jobData.id
                );
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Yes"
            />,
              <RBButton
                key={"confirm"}
                buttonBackgroundColor={primaryColor}
                color="primary"
                onClick={() => {
                  setShiftAlertShowing(false);
                }}
                text="No"
              />,
             
            ],
          });
          setShiftAlertShowing(true);
          break;
        }
      }
    }
  };

  const onClickJobCard = (job: any) => {
    const item = document.querySelector(".restore-" + job.id);
    if(job?.jobState == 2){
       history.push(`${RoutePathConstant.SearchJobDetail}${job.id}`);
    }
    else{
      history.push(`${RoutePathConstant.JobDetail}${job.id}`, {
        jobId: job.id,
        scrollY: window.scrollY,
      });
    }
    console.log(
      "job onClickJobCard id ",
      job.id,
      window.scrollY,
      item?.getBoundingClientRect()
    );
  };
  // const onBackButtonEvent = (e: any) => {
  //   console.log("shift list onBackButtonEvent", e);
  //   console.log("shift list onBackButtonEvent location state", location);
  // };
  //window.onpopstate = onBackButtonEvent;

  useEffect(() => {

    (async () => {
      await updateprimaryColor( localStorage.getItem('primaryColor')); 
      await updateSecondaryColor( localStorage.getItem('secondaryColor')); 
      await updateContactEmail( localStorage.getItem('contactEmail')); 
      await updateContactPhone( localStorage.getItem('contactPhone')); 
      await updateMode(localStorage.getItem('industryMode')); 
      const userData = await AuthService.loadAccountInfo();
      setAccount(userData);
  })(); 
  
    console.log("shift list last location ", lastLocation);
    if (lastLocation?.state) {
      const item = document.querySelector(
        ".restore-" +
          (lastLocation?.state as { jobId?: string; scrollY?: number })
            .jobId
      );

      if (item) {
        console.log("item1", item);
        //  item.scrollIntoView({ block: "center" });
        //  item.scrollIntoView();
        //      item.scrollBy({ top: -70 });
        const scrollY =
          (lastLocation?.state as {
            jobId?: string;
            scrollY?: number;
          }).scrollY ?? 0;
        // window.scrollTo({
        //   top: (lastLocation?.state as { jobId?: string; scrollY?: number })
        //     .scrollY,
        //   behavior: "smooth",
        // });
        window.scrollTo(555.5, scrollY);
        console.log("item exist pharmacy ");
      } else {
        console.log(`item does not exist`);
        window.scrollTo(200, 0);
      }
    }
  }, []);

  return (
    <>
      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={JSON.stringify(submitErrorMessage, null, 2)}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      />
      <RBAlert
        show={isAlertShowing}
        alertTitle={shiftAlertInfo.title}
        alertMessage={shiftAlertInfo.message}
        buttons={shiftAlertInfo.buttons}
      />
      <JobListContext.Provider
        value={{
          onAction: OnJobListButtonClick,
          isButtonLoading: isButtonLoading,
        }}
      >
        <div style={{ paddingTop: Spacing.large }}>
          {jobs.map((job) => (
            <div key={job.id} className={`restore-${job.id}`}>
              <RBJobLayoutCard
                job={job}
                onClick={() => onClickJobCard(job)}
                rolename={roleName}
                account = {account}
              />
            </div>
          ))}
        </div>
        {jobs.length === 0 && (
          <RBCardView
            marginHorizontal={Spacing.medium}
            style={{
              padding: Spacing.large,
              marginTop: Spacing.medium,
            }}
          >
            No jobs found
          </RBCardView>
        )}
      </JobListContext.Provider>
    </>
  );
};

export const JobListPage = withRouter(JobListPageWithoutRoute);
