import React, { useEffect } from "react";
import { Paper, PaperProps, Card, makeStyles } from "@material-ui/core";
import { Colors } from "../theme/Theme";

interface RBCardViewProps extends PaperProps {
  marginVertical?: number;
  marginHorizontal?: number;
  paddingHorizontal?: number;
  paddingVertical?: number;
  clickable?: boolean;
  onClick?: any;
  children: any;

  //style?: React.CSSProperties;
}

const useStyles = makeStyles({
  Paper: {
    elevation: 0,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: Colors.white,
    "&:hover": {
      //borderColor: Colors.themeBlue,
      cursor: "pointer",
    },
  },
});

export const RBCardView: React.FC<RBCardViewProps> = ({
  clickable,
  onClick,
  ...props
}) => {
  const classes = useStyles();
  return (
    <>
      {clickable && (
        <Paper
          onClick={onClick}
          classes={{ root: classes.Paper }}
          style={{
            paddingTop: props.paddingVertical,
            paddingBottom: props.paddingVertical,
            paddingRight: props.paddingHorizontal,
            paddingLeft: props.paddingHorizontal,
            marginLeft: props.marginHorizontal,
            marginRight: props.marginHorizontal,
            marginTop: props.marginVertical,
            marginBottom: props.marginVertical,
            ...props.style,
          }}
        >
          {props.children}
        </Paper>
      )}
      {!clickable && (
        <Paper
          style={{
            paddingTop: props.paddingVertical,
            paddingBottom: props.paddingVertical,
            paddingRight: props.paddingHorizontal,
            paddingLeft: props.paddingHorizontal,
            marginLeft: props.marginHorizontal,
            marginRight: props.marginHorizontal,
            marginTop: props.marginVertical,
            marginBottom: props.marginVertical,
            ...props.style,
          }}
        >
          {props.children}
        </Paper>
      )}
    </>
    // <Paper
    //   classes={clickable ? { root: classes.Paper } : {}}
    //   style={{
    //     paddingTop: props.paddingVertical,
    //     paddingBottom: props.paddingVertical,
    //     paddingRight: props.paddingHorizontal,
    //     paddingLeft: props.paddingHorizontal,
    //     marginLeft: props.marginHorizontal,
    //     marginRight: props.marginHorizontal,
    //     marginTop: props.marginVertical,
    //     marginBottom: props.marginVertical,
    //     ...props.style,
    //   }}
    // >
    //   {props.children}
    // </Paper>
  );
};
