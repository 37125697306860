import { omit } from "lodash";

class FormDataBuilder {
  private formData: FormData;
  constructor() {
    this.formData = new FormData();
  }

  add = (name: string, data: any) => {
    this.formData.append(name, data);
  };

  /**
   * adds a json object to the form data.
   * handles nested objects and arrays
   * @param {Object} data
   * @param {Array<String>} exclude
   */
  fromJson = (data: any, exclude: string | string[]) => {
    data = omit(data, exclude);
    this.formData = new FormData();
    this.build(data);
  };

  build(data: any, parentKey?: string) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.build(data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      console.log("build append ", data, parentKey);
      const value = data == null ? "" : data;
      this.formData.append(parentKey ?? "", value);
    }
  }

  /**
   * Gets the FormData object
   * @returns {FormData}
   */
  get = () => this.formData;
}

export default FormDataBuilder;
