import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  RBTextFieldForForm,
  RBSelectForForm,
  RBTextFieldForFormFieldType,
} from "../../../components/RBTextField";
import { Spacing } from "../../../theme/Theme";
import { RBPlaceSearchForForm } from "../../../components/RBPlaceSearch";
import { CanadaProvinces, PharmacyRxCounts } from "../../../util/Common";
import { SkillResponseDto } from "../../../dtos/Skill.dto";
import { PharmacyService } from "../../../api/PharmacyService";
import { formattedLocationTypeEnum, SkillType } from "../../../util/Enumeration";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { MenuItem } from "@material-ui/core";
import { LabelText } from "../../../util/TextConstant";
import { Schema } from "../YupSchema";
import { AuthService } from "../../../api/AuthService";
import { IndustryModeEnum } from "../../../api/constants";

export interface DentalPracticeInformationValueTypes {
  pharmacyName: string;
  street: string;
  city: string;
  postalCode: string;
  province: string;
  pharmacyPhoneNumber: string;
  pharmacySoftware: string;
  apartmentSuiteNumber?: string;
  companyType: string;
}

interface DentalPracticeInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: DentalPracticeInformationValueTypes;
  ownerType?: formattedLocationTypeEnum;
  account?: any;
}

export const DentalPracticeInformation: React.FC<DentalPracticeInformationProps> = ({
  ...props
}) => {
  const [softwares, setSoftwares] = useState<SkillResponseDto[]>([]);
  const [DentalOwnerPosition, setDentalOwnerPosition] = useState<any>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchSkillsData = await PharmacyService.fetchSkills('Dental Practice');
        console.log('fetchSkillsData', fetchSkillsData);
        setSoftwares(
          fetchSkillsData.data.filter(
            (skill: ObjectLiteral) => skill.type === SkillType.dentalSoftware
          )
        );
        const dentalCompanyTypes = await AuthService.getLocationTypesBasedonIndustry(IndustryModeEnum.Dentistry);
        const DentalOfficeCompanyType = dentalCompanyTypes.map((item:any) => item.locationType);
        setDentalOwnerPosition(DentalOfficeCompanyType)
      } catch (err) {}
    };
    fetchData();
    console.log('dental software ', softwares);
  }, []);
  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={Schema.DentalPracticeInformation}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={Schema.DentalPracticeInformation.isValidSync(
        props.initialValues
      )}
      onSubmit={() => {
        console.log("sdasdas");
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <RBTextFieldForForm
            label="Practice Name"
            name="pharmacyName"
            value={values.pharmacyName}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          {/* <RBTextFieldForForm
            label="Practice Type"
            name="practiceType"
            value={values.pharmacyName}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          /> */}

          <RBSelectForForm
            label="Practice Type"
            name="companyType"
            value={values.companyType}
            mappingValues={DentalOwnerPosition}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <div
            style={{
              marginBottom: Spacing.large,
            }}
          >
            <RBPlaceSearchForForm
              label="Street Address"
              name="street"
              value={values.street}
              setFieldValue={(addressObject: any) => {
                setFieldValue("street", addressObject.street, true);
                setFieldValue("city", addressObject.city, true);
                setFieldValue("postalCode", addressObject.postalCode, true);
                setFieldValue("province", addressObject.province, true);
              }}
              onBlur={handleBlur}
            />
          </div>

          <RBTextFieldForForm
            label="Apartment/Suite #"
            placeholder="Optional"
            name="apartmentSuiteNumber"
            value={values.apartmentSuiteNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="City"
            name="city"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Postal Code"
            name="postalCode"
            FieldType={RBTextFieldForFormFieldType.postalCode}
            value={values.postalCode}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label="Province"
            name="province"
            value={values.province}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={"Province"}
            mappingValues={CanadaProvinces.map(
              (province: any) => province.abbr
            )}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Phone Number"
            name="pharmacyPhoneNumber"
            FieldType={RBTextFieldForFormFieldType.phone}
            value={values.pharmacyPhoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label="Software"
            name="pharmacySoftware"
            value={values.pharmacySoftware}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={"Please select ..."}
            style={{
              marginBottom: Spacing.large,
            }}
          >
            {softwares.map((skill) => (
              <MenuItem key={skill.id} value={skill.id}>
                {console.log('software map ', skill)}
                {/* <ListItemText primary={skill.name} /> */}
                <div>{skill.name}</div>
              </MenuItem>
            ))}
          </RBSelectForForm>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre>
          <pre>{JSON.stringify(errors, null, 2)}</pre> */}
        </Form>
      )}
    </Formik>
  );
};
