import React, { useRef } from "react";
import {
  RBTextFieldForForm,
  RBSelectForForm,
} from "../../../components/RBTextField";
import { Form, Formik } from "formik";
import { Spacing } from "../../../theme/Theme";
import { RBPlaceSearchForForm2 } from "../../../components/RBPlaceSearch";
import { Schema } from "../YupScheme";

export interface PharmacyLocationPropsValueTypes {
  street: string;
  city: string;
  postalCode: string;
  province: string;
  apartmentSuiteNumber?: string;
}

interface PharmacyLocationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: PharmacyLocationPropsValueTypes;
}
export const PharmacyLocation: React.FC<PharmacyLocationProps> = ({
  ...props
}) => {
  return (
    <RBPlaceSearchForForm2
      innerRef={props.innerRef}
      initialValues={props.initialValues}
      isProvinceEditable={true}
      onFormChange={props.onFormChange}
    />
  );
};
