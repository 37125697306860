import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { RenderTextWithTitle } from "../../../components/TextWithHeader";
import {Font, Spacing } from "../../../theme/Theme";
import { RBCardView } from "../../../components/RBCardView";
import { ColumnStyle } from "../../../theme/ComponentTheme";
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    display: "flex",
    flex: 1,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  sectionHeader: {
    fontSize: Font.largest, fontWeight:"bold"
    //  backgroundColor: "red",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  CardContent: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
});
interface ProfileCardSectionProps {
  sectionHeader: string;
  detail: object;
  action?: object;
}

export const ProfileCardSection: React.FC<ProfileCardSectionProps> = ({
  ...props
}) => {
  const classes = useStyles();
  const { sectionHeader, detail, action } = props;
  const renderSectionHeader = () => {
    return (
      <div
        className={classes.sectionHeader}
        color="primary"
        style={{
          display: "flex",
          flex: 1,
          overflowWrap: "anywhere",
        }}
      >
        {sectionHeader}
      </div>
    );
  };

  return (
    <RBCardView
      // marginHorizontal={Spacing.large}
      style={{
        margin: Spacing.large,
        padding: Spacing.large,
      }}
      children={
        // <Grid
        //   container
        //   style={{
        //     paddingLeft: Spacing.large,
        //     paddingRight: Spacing.large,
        //     paddingTop: Spacing.large,
        //     paddingBottom: Spacing.large,
        //   }}
        // >
        //   <Grid item xs={3}>
        //     {renderSectionHeader()}
        //   </Grid>
        //   <Grid item xs={7}>
        //     {detail}
        //   </Grid>
        //   <Grid item xs={2}>
        //     {action}
        //   </Grid>
        // </Grid>
        <>
          <div style={ColumnStyle}>
            {renderSectionHeader()}
            {action}
          </div>
          <div>{detail}</div>
        </>
      }
    />
  );
};
