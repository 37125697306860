import React, { useState, useEffect,useContext } from "react";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, Colors } from "../../theme/Theme";
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  withStyles,
  RadioProps,
} from "@material-ui/core";
import { RBButton } from "../../components/RBButton";
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBTextField } from "../../components/RBTextField";
import { renderTextWithTitleAndChildComponent } from "../../components/TextWithHeader";
import { AuthService } from "../../api/AuthService";
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { useHistory, useLocation } from "react-router";
import { EmptyString } from "../../util/Common";
import { RBAppbar } from "../../components/RBAppbar";
import { RBContext } from "../../components/RBContext";
import InputMask from "react-input-mask";


function BusinessNumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;
  return (
    <InputMask
      {...other}
      inputRef={inputRef}
      mask="999999999 RT 9999"
      alwaysShowMask={false}
      onChange={onChange}
      maskChar=" "
    />
  );
}

function SINFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;
  return (
    <InputMask
      {...other}
      inputRef={inputRef}
      mask="999 - 999 - 999"
      alwaysShowMask={false}
      onChange={onChange}
      maskChar=" "
    />
  );
}

const validCanadaSIN = (sinData:any) => {
  // https://en.wikipedia.org/wiki/Social_Insurance_Number
  console.log('sin data : ', sinData);
  const cleanSinData = sinData.replace(/[^\d]/g, '');
  const firstDigit = parseInt(cleanSinData[0], 10) * 1;
  const secondDigit = parseInt(cleanSinData[1], 10) * 2;
  const thirdDigit = parseInt(cleanSinData[2], 10) * 1;
  const fourthDigit = parseInt(cleanSinData[3], 10) * 2;
  const fifthDigit = parseInt(cleanSinData[4], 10) * 1;
  const sixthDigit = parseInt(cleanSinData[5], 10) * 2;
  const seventhDigit = parseInt(cleanSinData[6], 10) * 1;
  const eighthDigit = parseInt(cleanSinData[7], 10) * 2;
  const ninthDigit = parseInt(cleanSinData[8], 10) * 1;

  const summation =
    addDigit(firstDigit) +
    addDigit(secondDigit) +
    addDigit(thirdDigit) +
    addDigit(fourthDigit) +
    addDigit(fifthDigit) +
    addDigit(sixthDigit) +
    addDigit(seventhDigit) +
    addDigit(eighthDigit) +
    addDigit(ninthDigit);
  console.log(
    `${firstDigit} ${secondDigit} ${thirdDigit} ${fourthDigit} ${fifthDigit} ${sixthDigit} ${seventhDigit} ${eighthDigit} ${ninthDigit} ${summation}`,
  );
  return summation % 10 == 0;
};

// This is only used for validCanadaSIN, and the min number is 0, the max is 2 * 9 = 18
const addDigit = (number:any) => {
  if (number < 10) {
    return number;
  } else {
    return Math.floor(number / 10) + (number % 10);
  }
};

const GreenRadio = withStyles({
  root: {
    color: Colors.darkGrey,
    "&$checked": {
      color: Colors.black,
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

enum SinBusinessRegisterStyle {
  sin = "sin",
  business = "business",
}
export const SINBusinessInfoPage = () => {
  const [sinBusinessStyle, setSinBusinessStyle] = React.useState<
    SinBusinessRegisterStyle
  >();
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [businessName, setBusinessName] = useState<string>(EmptyString);
  const [businessNumber, setBusinessNumber] = useState<string>(EmptyString);
  const [sinNumber, setSINNumber] = useState<string>(EmptyString);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const [businessNumberErrorText, setBusinessNumberErrorText] = useState(EmptyString);
  const history = useHistory();
  const location = useLocation<{
    updateBusinessInfo: boolean;
    businessName: string;
    businessNumber: string;
  }>();

  useEffect(() => {
    if (location.state.updateBusinessInfo) {
      setSinBusinessStyle(SinBusinessRegisterStyle.business);
      setBusinessName(location.state.businessName);
      setBusinessNumber(location.state.businessNumber);
    }
  }, []);
  useEffect(() => {
    checkFormValid();
  }, [sinBusinessStyle, businessNumber, sinNumber]);
  const renderSetUpHeader = () => {
    return (
      <div
        style={{ paddingLeft: Spacing.xlarge, paddingRight: Spacing.xlarge }}
      >
        <Typography variant={"h5"} style={{ textAlign: "center" }}>
          Do you have a registered business?
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            //why ('|| false ') ?
            // to avoid a warning of 'A component is changing the uncontrolled value state of RadioGroup to be controlled'
            // since the initial value of sinBusinessStyle will be undefined and it will be considered as uncontrolled
            value={sinBusinessStyle || false}
            onChange={(e) =>
              setSinBusinessStyle(e.target.value as SinBusinessRegisterStyle)
            }
          >
            <FormControlLabel
              value={SinBusinessRegisterStyle.business}
              control={<GreenRadio  color={primaryColor}/>}
              label="Yes"
            />
            <FormControlLabel
              value={SinBusinessRegisterStyle.sin}
              control={<GreenRadio color={primaryColor} />}
              label="No"
            />
          </RadioGroup>
          <div
            style={{
              margin: Spacing.xlarge,
              backgroundColor: Colors.lighterGrey,
              padding: Spacing.medium,
            }}
          >
            <Typography style={{ padding: Spacing.medium }}>
              If you are making more than $30,000 in the year (4 consecutive
              quarters) or after the first quarter, the Canada Revenue Agency
              requires you to register for a GST/HST number. You are required to
              register for a GST/HST number within the first 29 days of making
              $30,000.
            </Typography>
          </div>
        </FormControl>
      </div>
    );
  };
  const renderBlurb = () => {
    // return (
    //   // <div
    //   //   style={{ margin: Spacing.xlarge, backgroundColor: Colors.lighterGrey }}
    //   // >
    //   //   <Typography style={{ padding: Spacing.medium }}>
    //   //     If you are making more than $30,000 in the year (4 consecutive
    //   //     quarters) or after the first quarter, the Canada Revenue Agency
    //   //     requires you to register for a GST/HST number. You are required to
    //   //     register for a GST/HST number within the first 29 days of making
    //   //     $30,000.
    //   //   </Typography>
    //   // </div>
    // );
  };

  const checkFormValid = () => {
    if (
      sinBusinessStyle === SinBusinessRegisterStyle.sin &&
      sinNumber &&
      sinNumber.length > 0
    ) {
      setFormValid(true);
    } else if (
      sinBusinessStyle === SinBusinessRegisterStyle.business &&
      businessNumber &&
      businessNumber.length > 0
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };
  const onSubmit = async () => {
    try {
      if (businessNumber.length !== 0 &&
        !/\d{9} RT \d{4}/g.test(businessNumber)) {
        setBusinessNumberErrorText("must be a valid hst number");
        return;
      }
      console.log(!validCanadaSIN(sinNumber) )
      console.log(!/\d{3} - \d{3} - \d{3}/g.test(sinNumber))
      if (sinNumber.length !== 0  &&
        (!validCanadaSIN(sinNumber) ||
          !/\d{3} - \d{3} - \d{3}/g.test(sinNumber))) {
        setBusinessNumberErrorText("must be a valid social insurance number");
        return;
      }
      setIsSubmitting(true);
      let payload = {};
      if (sinBusinessStyle === SinBusinessRegisterStyle.sin) {
        payload = { socialInsuranceNumber: sinNumber };
      } else if (sinBusinessStyle === SinBusinessRegisterStyle.business) {
        payload = {
          taxNumber: businessNumber,
        };
        if (businessName && businessName.length > 0) {
          payload = { ...payload, businessName: businessName };
        }
      }
      const result = await AuthService.updateWorker(payload);
      NotificationService.publish(
        NotificationServiceTopic.AccountChanged,
        "account changed "
      );
      history.goBack();
    } catch (err) {
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <RBAppbar
        deeperLevel={true}
        barName={
          location.state.updateBusinessInfo
            ? "Update Business Information"
            : "SIN/Business Information"
        }
      />
      <RBCardView
        style={{
          margin: Spacing.large,
          padding: Spacing.large,
          height: "100%",
        }}
      >
        {!location.state.updateBusinessInfo && renderSetUpHeader()}
        {sinBusinessStyle === SinBusinessRegisterStyle.business && (
          <div style={{ margin: Spacing.xlarge }}>
            {renderTextWithTitleAndChildComponent(
              "Business Name",
              <RBTextField
                margin={"dense"}
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
              />
            )}
            <div style={{ marginTop: Spacing.large }} />
            {renderTextWithTitleAndChildComponent(
              "Business Number",
              <RBTextField
                margin={"dense"}
                value={businessNumber}
                inputComponent={BusinessNumberFormatCustom as any}
                onChange={(e) => setBusinessNumber(e.target.value)}
                error={businessNumberErrorText.length > 0}
                errorText={businessNumberErrorText}
              />
            )}
          </div>
        )}
        {sinBusinessStyle === SinBusinessRegisterStyle.sin && (
          <>
            <div style={{ margin: Spacing.xlarge }}>
              {renderTextWithTitleAndChildComponent(
                "Social Insurance Number",
                <RBTextField
                  margin={"dense"}
                  value={sinNumber}
                  onChange={(e) => setSINNumber(e.target.value)}
                  inputComponent={SINFormatCustom as any}
                  error={businessNumberErrorText.length > 0}
                  errorText={businessNumberErrorText}
                />
              )}
            </div>
            {renderBlurb()}
          </>
        )}
        <div style={{ ...ColumnStyle,justifyContent: "center", padding: Spacing.xlarge }}>
          <RBButton
            buttonBackgroundColor={secondaryColor}
            style={{ minWidth: 150, marginRight:"5%" }}
            onClick={() => history.goBack()}
            text={"Cancel"}
          />
          
          <RBButton
            buttonBackgroundColor={secondaryColor}
            isLoading={isSubmitting}
            disabled={!formValid}
            style={{ minWidth: 150 }}
            onClick={onSubmit}
            text={"Submit"}
          />
        </div>
      </RBCardView>
    </>
  );
};
