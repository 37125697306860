import React, { useState, useEffect,useContext } from "react";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { Spacing, Colors } from "../../theme/Theme";
import { useLocation, useHistory } from "react-router";
import { RBCardView } from "../../components/RBCardView";
import { RBDatePickerSearch } from "../../components/RBDateTimePicker";
import moment from "moment-timezone";
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBButton } from "../../components/RBButton";
import { ShiftService } from "../../api/ShiftService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { formatWorkerType, getDatesDuration } from "../../util/FormattingService";
import {
  defaultKmAllowanceIcon,
  defaultAccomodationAllowanceIcon,
} from "../../util/Icons";
import { Typography } from "@material-ui/core";
import { RoutePathConstant } from "../../util/Common";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { AuthService } from "../../api/AuthService";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum } from "../../api/constants";
import { defaultAllowOffersIcon } from "../../components/RBShiftRelatedIcons";
import { detailTextStyle, titleHeaderTextStyle } from "../../components/TextWithHeader";
import { RBSwitchOutside } from "../../components/RBSwitchOutside";
export const SearchJobPage = () => {
  const location = useLocation<{ background: string }>();
  const history = useHistory();
  const [showModal, setShowModal] = useState(true);
  const [searchStartDate, setSearchStartDate] = useState<any>(
    moment().toISOString()
  );
  const [searchEndDate, setSearchEndDate] = useState<any>(
          moment()
          .add(1, 'months')
          .toISOString(),
  );
  
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [startDate, setStartDate] = useState(moment().subtract(30, 'years'));
  const [ignoreProv, setIgnoreProv] = useState(false);
  // months
  // const [shiftSearchRange, setShiftSearchRange] = useState(1);
  // const [searchedShifts, setSearchedShifts] = useState<ObjectLiteral[]>([]);
  const [isSearching, setIsSearching] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [account, setAccount] = useState<any>([]);
  const [jobs, setJobs] = useState([]);
  const [isSearchShiftSubmitInfo,setIsSearchShiftSubmitInfo] = useState(false)
  const [searchShiftSubmitInfo,setSearchShiftSubmitInfo] = useState <any>({ title: "", message: "", buttons: [] })


  useEffect(() => {
  
    loadingContent(null,ignoreProv);
  }, []);

  const loadingContent = async (startDate?:any, ignoreProv?:any) => {
    try {
      const userData = await AuthService.loadAccountInfo();
      setAccount(userData);
      setIsLoading(true);
      
      let payload:any = {};
      if (!startDate) {
        payload.startDate = moment().subtract(30, 'years').toISOString();
        payload.showOtherProvince = ignoreProv;
        payload.networkId = userData.networks[0].id
      } else {
        payload = {
          startDate: moment(startDate).toISOString(),
          showOtherProvince: ignoreProv,
          networkId:userData.networks[0].id
        };
      }
      const searchJobs = await ShiftService.searchJobs(payload.startDate,payload.showOtherProvince,payload.networkId);
      setJobs(searchJobs.data.data);
    } catch (error) {

      if (error.response?.data?.error?.includes('address')) {

        setSearchShiftSubmitInfo({
          title: "Invalid address",
          message: "Your address must be validated before performing this action, please update your address. If the problem persists please contact support.",
          buttons: [
  
            <RBButton
              buttonBackgroundColor={primaryColor}
              key={"Discard"}
              onClick={() => {
                setIsSearchShiftSubmitInfo(false)
                history.push("/Job/list")
              }}
              color="primary"
              text="Ok"
            />,
          ],
        })
        setIsSearchShiftSubmitInfo(true)
      }
      console.log('job search error', error);
    } finally {
      setIsLoading(false);
    }
  };

 
  const onToggleSwitch = (event:any) => {
    setIgnoreProv(!ignoreProv);
  }

  const onSearchChanged = async (startDate:any, ignoreProv:boolean) => {
    await loadingContent(startDate, ignoreProv);
  };

  const renderSearchJobBar = () => {
    return (
      <RBCardView
        marginHorizontal={Spacing.large}
        style={{
          padding: Spacing.large,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ ...ColumnStyle, marginBottom: Spacing.smallest }}>
            <RBSwitchOutside
              labelPlacement="start"
              checked={ignoreProv}
              onChange={onToggleSwitch}
              name="ignoreProv"
              label="Include jobs in other provinces"
              formControlStyle={{ marginBottom: Spacing.large }}
            />
        </div>

        <RBButton
          text="Search"
          isLoading={isLoading}
          onClick={() => onSearchChanged(null,ignoreProv)}
          style={{ display: "flex", flex: 1 }}
          buttonBackgroundColor={secondaryColor}
        />
      </RBCardView>
    );
  };
  const renderSearchJobResultDetail = (job: ObjectLiteral) => {


    const {
      estHourlyRate,
      estEndDate,
      distance,
      estStartDate,
      jobCity,
      pharmacistType,
      jobCategory,
    } = job;
    const jobType = jobCategory.name;
    const estStartDateValue = estStartDate
      ? moment(estStartDate).format('MMM Do, YYYY')
      : 'N/A';
    const estEndDateValue = estEndDate
      ? moment(estEndDate).format('MMM Do, YYYY')
      : 'N/A';
    const duration =
      estStartDateValue !== 'N/A' &&
      estEndDateValue !== 'N/A'
        ? getDatesDuration(estStartDate, estEndDate)
        : 'N/A';


    return (
      <RBCardView
        key={job.id}
        marginHorizontal={Spacing.large}
        paddingHorizontal={Spacing.large}
        paddingVertical={Spacing.small}
        onClick={() => {
          console.log("RBCardView onClick");
          history.push(`${RoutePathConstant.SearchJobDetail}${job.id}`, {
            //background: location,
            background: location.state?.background,
          });
        }}
        clickable={true}
        style={{
          //  padding: Spacing.large,
          marginTop: Spacing.medium,
        }}
      >
        <div style={{ ...ColumnStyle,flex:3 , paddingBottom: Spacing.smallest,}}>
          <Typography>{ estEndDateValue == "N/A" ? 'Est. Start Date' : 'Est. Start - End Date'}</Typography>
          <Typography></Typography>
          <Typography>
              <div style={{ flex: 1, alignItems: "flex-start" }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >

                  <div
                    style={{
                      marginLeft: Spacing.smallest,
                    }}
                  >
                   {jobType}
                  </div>
                </div>

              </div>
          </Typography>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: Spacing.smallest,flex:3 }}>
         
          {
            estEndDateValue == "N/A" ?
              <Typography>
                {estStartDateValue}
              </Typography>
              :
              <Typography>
                {estStartDateValue} - {estEndDateValue}
              </Typography>
          }
         
          <Typography>${(estHourlyRate / 100).toFixed(2)}/hr</Typography>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
          {estEndDate !== null ?
            <Typography>{duration}</Typography> : (
              <Typography>
                {(distance / 1000).toFixed(0)} km away{' '}
                {jobCity ? `in ${jobCity}` : ''}
              </Typography>
            )}
          <Typography style={{ textAlign: 'right' }}>
            {formatWorkerType(pharmacistType)}
          </Typography>
        </div>
        <div style={{ ...ColumnStyle, paddingBottom: 0 }}>
          {estEndDate !== null ? (
            <Typography>
              {(distance / 1000).toFixed(0)} km away{' '}
              {jobCity ? `in ${jobCity}` : ''}
            </Typography>
          ) : null}
        </div>
      </RBCardView>
    );
  };
  const renderSearchJobResults = () => {
    debugger
    return (
      <>
        {jobs.length === 0 && (
          <RBCardView
            marginHorizontal={Spacing.medium}
            style={{
              padding: Spacing.large,
              marginTop: Spacing.medium,
            }}
          >
            No items found.
          </RBCardView>
        )}
        {jobs.length >= 1 &&
          jobs.map((job: ObjectLiteral) =>
            renderSearchJobResultDetail(job)
          )}
      </>
    );
  };
  const renderSearchJobContent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: Spacing.medium,
        }}
      >
        {renderSearchJobBar()}
        {renderSearchJobResults()}
      </div>
    );
  };

  const onClose = () => {
    goBackToPreviousLocation();
  };

  const goBackToPreviousLocation = () => {
    if (location.state && location.state.background) {
      setShowModal(false);
      history.goBack();
    } else {
      setShowModal(false);
      history.push("/dashboard");
    }
  };
  return (
    <>
      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={JSON.stringify(submitErrorMessage, null, 2)}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      />
      <RBAlert
        show={isSearchShiftSubmitInfo}
        alertTitle={searchShiftSubmitInfo.title}
        alertMessage={searchShiftSubmitInfo.message}
        buttons={searchShiftSubmitInfo.buttons}
      />
      <RBModal
        backArrowIcon
        open={showModal}
        onClose={onClose}
        modalTitle={"Job Search"}
        header={
          <RBEmptyBlock
            style={{
              width: "100%",
              height: Spacing.large,
              backgroundColor: Colors.defaultGrey,
              flexShrink: 0,
            }}
          />
        }
        children={renderSearchJobContent()}
      />
    </>
  );
};
