import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Schema } from "../YupSchema";
import {
  RBTextFieldForForm,
  RBSelectForForm,
  RBTextFieldForFormFieldType,
} from "../../../components/RBTextField";
import { Spacing } from "../../../theme/Theme";
import { CanadaProvinces } from "../../../util/Common";
import { AuthService } from "../../../api/AuthService";
import { workerTypeEnum } from "../../../util/Enumeration";
import { RBSwitch } from "../../../components/RBSwitch";
import { RBMonthYearPicker } from "../../../components/RBMonthYearPicker";

export interface LicensedDentistInformationValueTypes {
  licenseNumber: string;
  licenseProvince: string;
  graduationYear: string;
  graduationInstitution: string;
  licensedDate: string;
  licenseExpiryDate: string;
   showToggle:boolean,
   toggleFlag:boolean,
  licenseOptional:boolean,
  experienceMonth: number;
}

interface LicensedDentistInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: LicensedDentistInformationValueTypes;
  workerType?: workerTypeEnum;
  account?:any;
}

export const LicensedDentistInformation: React.FC<LicensedDentistInformationProps> = ({
  ...props
}) => {
  const [licenseProvince, setLicenseProvince] = useState("");
  const [toggleFlag, setToggleFlag] = useState(true);
  const [showToggle, setShowToggle] = useState(false);
  const [licenseOptional, setLicenseOptional] = useState(false);
  useEffect(() => {
    console.log("onFormChange1",props)
    if(props.initialValues.licenseProvince !== ""){
      onProvinceChange(null)
    }
  },[])

  useEffect(() => {
    setToggleFlag(true)
  },[showToggle && props.initialValues.licensedDate !== ""])


  const onProvinceChange = async (event:any) => {
    const licenseProvince =  event?.target ? event.target.value : props.initialValues.licenseProvince;
    setLicenseProvince(licenseProvince);
    const workerTypeDetail = await AuthService.getActiveWorkerdetails(props.workerType);
    if (workerTypeDetail?.licnsedFieldFalseProvinces && workerTypeDetail.licnsedFieldFalseProvinces?.length > 0) {
      if (workerTypeDetail.licnsedFieldFalseProvinces.includes(licenseProvince)) {
        setLicenseOptional(true);
        setShowToggle(true)
        setToggleFlag(false)
      }
      else {
        setLicenseOptional(false);
        setShowToggle(false)
        setToggleFlag(true)
      }
    }
    
  };
  const onToggleChange = (event:any) =>{
    setToggleFlag((prevToggleFlag) => !prevToggleFlag);
  }

  

  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={toggleFlag ? Schema.LicensedDentistInformation : Schema.LicensedDentistInformationProvinceChange}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={toggleFlag ? Schema.LicensedDentistInformation.isValidSync(
        props.initialValues
      ): Schema.LicensedDentistInformationProvinceChange.isValidSync(
        props.initialValues
      )}
      onSubmit={() => {}}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <RBSelectForForm
            label="Province of Registration"
            name="licenseProvince"
            value={values.licenseProvince}
           // onChange={onProvinceChange}
           onChange={async (e: any) => {
            console.log(e);
            handleChange(e);
            await onProvinceChange(e);
            setFieldValue("licenseProvince",e.target.value,false)
            if(toggleFlag)
            {
            setFieldValue("licensedDate", "",true)
            setFieldValue("licenseNumber", "",true)
            setFieldValue("licenseExpiryDate", "",true)
            }
            setFieldValue("graduationYear", "",true)
            setFieldValue("graduationInstitution", "",true)
            setFieldValue("experienceMonth", 0,false)
            // console.log("toggleFlag",toggleFlag)
            // console.log("toggleFlag showToggle",showToggle)
            // console.log("toggleFlag licenseOptional",licenseOptional)
            // setFieldValue("showToggle",showToggle,false)
            // setFieldValue("toggleFlag",toggleFlag,false)
            
          }}
            onBlur={handleBlur}
            placeholder={"Province"}
            mappingValues={CanadaProvinces.map(
              (province: any) => province.abbr
            )}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBSwitch
          checked={values.licenseOptional}
          name="licenseOptional"
          formControlStyle={{ display: "none" }}
        />
        {showToggle && 
          <RBSwitch
          checked={toggleFlag}
          onLoad={()=>{}}
          onChange={(e:any) => {
            handleChange(e)
            //onToggleChange(e);
            setToggleFlag((prevToggleFlag) => !prevToggleFlag);
            console.log("toggleFlag",toggleFlag)
            console.log("toggleFlag showToggle",showToggle)
            if(toggleFlag)
            {
            setFieldValue("licensedDate", "",true)
            setFieldValue("licenseNumber", "",true)
            setFieldValue("licenseExpiryDate", "",true)
            }
            setFieldValue("graduationYear", "",true)
            setFieldValue("graduationInstitution", "",true)
            setFieldValue("experienceMonth", 0,false)
            // setFieldValue("showToggle",showToggle,false)
            // setFieldValue("toggleFlag",toggleFlag,false)

          }}
          name="toggleFlag"
          label="Are you licensed?"
          formControlStyle={{ marginBottom: Spacing.large }}
        />
         }
        {toggleFlag &&
          <>
            <RBTextFieldForForm
              label="First year registered in Canada (YYYY-MM)"
              FieldType={RBTextFieldForFormFieldType.yearMonth}
              placeholder="Required"
              name="licensedDate"
              value={values.licensedDate}
              onChange={(e:any) => {
                handleChange(e);
                setFieldValue("toggleFlag",toggleFlag,false)
              }}
              onBlur={handleBlur}
              size={"small"}
              style={{
                marginBottom: Spacing.large,
              }}
            />

            <RBTextFieldForForm
              label="Registration Number"
              placeholder="Required"
              name="licenseNumber"
              value={values.licenseNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              size={"small"}
              style={{
                marginBottom: Spacing.large,
              }}
            />  

            <RBTextFieldForForm
              label="Registration Expiry Date (YYYY-MM)"
              FieldType={RBTextFieldForFormFieldType.yearMonth}
              placeholder="Required"
              name="licenseExpiryDate"
              value={values.licenseExpiryDate}
              onChange={handleChange}
              onBlur={handleBlur}
              size={"small"}
              style={{
                marginBottom: Spacing.large,
              }}
            />
          </>
        }
          <RBTextFieldForForm
            FieldType={RBTextFieldForFormFieldType.yearGraduation}
            label="Year of Graduation"
            placeholder="Required"
            name="graduationYear"
            value={values.graduationYear}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Institution Name"
            placeholder="Required"
            name="graduationInstitution"
            value={values.graduationInstitution}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          {!toggleFlag && 
            <RBMonthYearPicker
            numberOfMonths={values.experienceMonth}
            onValueChange={(value: number) =>
              setFieldValue("experienceMonth", value, true)
            }
            workerType={props.workerType}
          />  
          }
        </Form>
      )}
    </Formik>
  );
};
