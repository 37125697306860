import { Colors } from "../theme/Theme";

export const ShiftRelatedConstants = {
  //Shift State
  ShiftStateText: {
    AwaitingHiring: "Awaiting Hiring",
    AwaitingWorkerConfirmation: "Awaiting Worker Confirmation",
    ReadyForShift: "Ready For Shift",
    ActiveShift: "Active Shift",
    AwaitingHoursConfirmation: "Awaiting Hours Confirmation",
    ShiftExpired: "Shift Expired",
    ShiftCompleted: "Shift Completed",
    InDispute: "In Dispute",
    RateShift: "Rate Shift",
    Cancelled: "Cancelled",
    Unknown: "Unknown",
    FeedbackShift: 'Shift Feedback',
    ReadyForShiftReqCancel: 'Ready For Shift (Cancellation Requested)',
  },
  JobStateText: {
    AwaitingHiring: 'Awaiting Hiring',
    Expired: 'Expired',
    ReadyForJob: 'Job Filled',
    ActiveJob: 'Active Job',
    JobExpired: 'Job Expired',
    JobCompleted: 'Job Completed',
    InDispute: 'In Dispute',
    RateJob: 'Rate Job',
    Cancelled: 'Cancelled',
    Unknown: 'Unknown',
    Invited:""
  },

  //Shift button text
  ShiftButtonText: {
    ViewApplications: "View Applicants",
    WithdrawShift: "Withdraw Shift",
    ConfirmHours: "Confirm Hours",
    EditShift: "Edit Shift",
    EditJob: "Edit Job",
    RateShift: "Rate Shift",
    CancelShift: "Cancel Shift",
    //ViewApplicant:"View Applicants"
    FeedbackShift: 'Shift Feedback',
    WithdrawJob: 'Withdraw Job',
    CancelJob: "Cancel Job",
    extendJob:"Extend Job Posting",
    jobCandidates:"Find Candidates",
    jobApplicant:"Review Applicants",
    RequestCancel:"Request to Cancel"
  },
};

const AwaitingHiring = ShiftRelatedConstants.ShiftStateText.AwaitingHiring;
const AwaitingHoursConfirmation =
  ShiftRelatedConstants.ShiftStateText.AwaitingHoursConfirmation;
const AwaitingWorkerConfirmation =
  ShiftRelatedConstants.ShiftStateText.AwaitingWorkerConfirmation;
const ActiveShift = ShiftRelatedConstants.ShiftStateText.ActiveShift;
const InDispute = ShiftRelatedConstants.ShiftStateText.InDispute;
const ReadyForShift = ShiftRelatedConstants.ShiftStateText.ReadyForShift;
const ShiftCompleted = ShiftRelatedConstants.ShiftStateText.ShiftCompleted;
const RateShift = ShiftRelatedConstants.ShiftStateText.RateShift;
const ShiftExpired = ShiftRelatedConstants.ShiftStateText.ShiftExpired;
const ShiftCancelled = ShiftRelatedConstants.ShiftStateText.Cancelled;
const FeedbackShift = ShiftRelatedConstants.ShiftStateText.FeedbackShift;
const ReadyForJob = ShiftRelatedConstants.JobStateText.ReadyForJob;
const Expired = ShiftRelatedConstants.JobStateText.Expired;
const Invited = ShiftRelatedConstants.JobStateText.Invited;
const ReadyForShiftReqCancel = ShiftRelatedConstants.ShiftStateText.ReadyForShiftReqCancel;

export const ShiftRelatedStylingConstants = {
  Colors: {
    [AwaitingHiring]: Colors.themeDarkBlue,
    [ReadyForShift]: Colors.themeLightBlue,
    [AwaitingHoursConfirmation]: "orange",
    [AwaitingWorkerConfirmation]: "orange",
    [ShiftCompleted]: Colors.themeLightBlue,
    [RateShift]: Colors.themeDarkBlue,
    [InDispute]: "red",
    [ShiftCancelled]: "grey",
    [ActiveShift]: Colors.themeDarkBlue,
    [FeedbackShift]: Colors.themeDarkBlue,
    [ReadyForJob]:Colors.themeLightBlue,
    [Expired]: "black",
    [Invited]:"black",
    [ReadyForShiftReqCancel]: 'grey',

  },
};

export const defaultWorkDays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
export const defaultStartTimeNew = "9:00 AM";
export const defaultEndTimeNew = "5:00 PM";
