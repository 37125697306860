import React from "react";
import { CssBaseline } from "@material-ui/core";
import { SideMenu } from "./SideMenu";
import { AppContainerStyle } from "../theme/Theme";
import { BottomAppBarHeight } from "../theme/ComponentTheme";
import { AccountInfoProvider } from "./AccountInfoContext";

interface RBAuthorizedContainer {}

export const RBAuthorizedContainer: React.FC<RBAuthorizedContainer> = ({
  ...props
}) => {
  console.log('props.children',props.children)
  return (
    <AccountInfoProvider>
    <div>
      <CssBaseline />
      <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
        <SideMenu />
        <div
          style={{
            ...AppContainerStyle,
            minHeight: window.innerHeight,
            paddingBottom: BottomAppBarHeight,
            flex: 1,
            marginLeft: 325,
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
    </AccountInfoProvider>
  );
};
