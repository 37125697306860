import React,{useContext} from "react";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  InputBase,
  Theme,
  makeStyles,
  createStyles,
  fade,
} from "@material-ui/core";
import {
  Colors,
  AppContainerStyle,
  AppBackgroundColor,
  Spacing,
} from "../theme/Theme";
import SearchIcon from "@material-ui/icons/Search";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TuneIcon from "@material-ui/icons/Tune";
import { useHistory } from "react-router-dom";
import { TopAppBarHeight } from "../theme/ComponentTheme";
import { RBContext } from "./RBContext";
import { IndustryModeEnum } from "../api/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: "white",
      height: TopAppBarHeight,
      borderBottomColor: AppContainerStyle.borderColor,
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    grow: {
      flexGrow: 1,
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      borderColor: Colors.darkGrey,
      borderStyle: "solid",
      borderWidth: 1,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: Spacing.small,
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: Colors.themeBlue,
    },
    inputRoot: {
      color: Colors.black,
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  })
);

interface RBAppbarProps {
  barName: any;
  deeperLevel?: boolean;
  searchable?: boolean;

  barChildren?: any;
}

export const RBAppbar: React.FC<RBAppbarProps> = ({ ...props }) => {
  const { primaryColor,secondaryColor, industryMode } = useContext(RBContext);
  const history = useHistory();
  const classes = useStyles();
  const onArrowBackIconClick = () => {
    history.goBack();
  };
  return (
    <AppBar position="sticky" elevation={0} className={classes.appBar}>
      <Toolbar>
        {props.deeperLevel && (
          <IconButton
            edge="start"
            style={{ color: primaryColor }}
            className={classes.menuButton}
            onClick={onArrowBackIconClick}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        {props.barName === 'Change Pharmacy' ? 
          <Typography variant="h6" noWrap style={{ color: primaryColor }}>
            {industryMode === IndustryModeEnum.Pharmaceutical ? 
              'Change Pharmacy' :
              'Change Dental Practice'
            }
          </Typography> :
          <Typography variant="h6" noWrap style={{ color: primaryColor }}>
            {props.barName}
          </Typography>
        }
        
        {props.searchable && (
          <div
            className={classes.search}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        )}
        <div className={classes.grow} />
        {props.barChildren}
      </Toolbar>
    </AppBar>
  );
};
