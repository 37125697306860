import React, { useState, useEffect,useContext } from "react";
import { ShiftService } from "../../api/ShiftService";
import { useRouteMatch, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { AuthService } from "../../api/AuthService";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, Colors } from "../../theme/Theme";
import { orderBy, map } from "lodash";
import { ColumnStyle, skillTextStyle } from "../../theme/ComponentTheme";
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
} from "@material-ui/core";
import {
  RBTextField,
  RBMoneyAmountTextField,
} from "../../components/RBTextField";
import { RBDateTimePicker } from "../../components/RBDateTimePicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { ErrorMessages, Claims, EmptyString } from "../../util/Common";
import { formatWorkerType } from "../../util/FormattingService";
import { workerTypeEnum, SkillType } from "../../util/Enumeration";
import { SkillResponseDto } from "../../dtos/Skill.dto";
import { PharmacyService } from "../../api/PharmacyService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { LabelText } from "../../util/TextConstant";
import {
  detailKmAllowanceIcon,
  detailAccomodationAllowanceIcon,
} from "../../util/Icons";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { RBButton, RBButtonType } from "../../components/RBButton";
import { RBContext } from '../../components/RBContext';

import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";

export const EditDentalShiftPage = () => {
  const { primaryColor,secondaryColor } = useContext(RBContext);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [originalShiftData, setOriginalShiftData] = useState<ObjectLiteral>({});
  const [wage, setWage] = useState<string>("0");
  const [wageValidity, setWageValidty] = useState(true);
  const [wageInvalidErrorText, setWageInvalidErrorText] = useState(EmptyString);
  const [technicianOnSite, setTechnicianOnSite] = useState(null);
  const [assistantOnSite, setAssistantOnSite] = useState(null);
  const [softwareName, setSoftwareName] = useState<string>();
  const [selectedSoftware, setSelectedSoftware] = useState<SkillResponseDto>();
  const [softwareRequired, setSoftwareRequired] = useState<boolean>(false);
  const [workerType, setWorkerType] = useState("Pharmacist");
  const [shiftTimeZone, setShiftTimeZone] = useState<string>("UTC");
  const [shiftStartTime, setShiftStartTime] = useState<any>();
  const [shiftEndTime, setShiftEndTime] = useState<any>();
  const [deviceShiftStartTime, setDeviceShiftStartTime] = useState<any>();
  const [deviceShiftEndTime, setDeviceShiftEndTime] = useState<any>();
  const [comment, setComment] = useState<string>();
  const [specializations, setSpecializations] = useState<SkillResponseDto[]>(
    []
  );
  const [selectedSpecializations, setSelectedSpecializations] = useState<
    string[]
  >([]);
  const [accomodationAllowance, setAccomodationAllowance] = useState<string>(
    "0"
  );
  const [kmAllowance, setKmAllowance] = useState<string>("0");
  const [minimumWage, setMinimumWage] = useState<ObjectLiteral>({});
  const [workerTypesAndRates, setWorkerTypesAndRates] = useState<ObjectLiteral>([]);
  const [workerTypes, setWorkerTypes] = useState<ObjectLiteral>([]);
  const [AllSpecializationSkills, setAllSpecializationSkills] = useState<any>([]);
  // update button is loading or not
  const [loading, setIsLoading] = useState(false);

  const [isEdited, setIsEdited] = useState(false);
  const match = useRouteMatch<{ shiftId: string }>();
  const history = useHistory();
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [isAllowanceEnabled,setIsAllowanceEnabled] = useState(false)
  const [allowOfferChangeOption,setAllowOfferChangeOption] = useState(false)
  const [allowOffer,setAllowOffer] = useState(false)
  const [isCreateShiftSubmitInfo,setIsCreateShiftSubmitInfo] = useState(false)
  const [createShiftSubmitInfo,setCreateShiftSubmitInfo] = useState <any> ({title:"",message:"", buttons:[]})
  const [startTimeValidation,setStartTimeValidation] = useState <any>(null) 
  const [endTimeValidation,setEndTimeValidation] = useState <any>(null)
  const getDateTime = (date: any) => {
      
    let currentDate = moment(date).tz(shiftTimeZone)
    let year = currentDate.format('YYYY')
    let month = currentDate.format('MM')
    let day = currentDate.format('DD')
    let hour = currentDate.format('HH')
    let minute = currentDate.format('mm')
    let second = currentDate.format('ss')
    let convertedDate = moment(year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second);
    return convertedDate;
  }
  const convertOffsetToString = (offset: any) => {
    let value = '';
    const absOffset = Math.abs(offset);
    const hr = absOffset / 60;
    const min = absOffset % 60;
    if (offset < 0) {
      value = value + '-';
    }
    else {
      value = value + '-';
    }
    if (hr < 10) {
      value = value + '0' + hr;
    }
    else {
      value = value + hr;
    }
    if (min < 10) {
      value = value + ':0' + min;
    }
    else {
      value = value + ':' + min;
    }
    return value;
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsPageLoading(true);
        
        const shiftDetailData = await ShiftService.getShiftDetailByShiftId(
          match.params.shiftId
        );
        const fetchSkillsData = await PharmacyService.fetchSkills(
          shiftDetailData.pharmacistType
        );
        const fetchFullSkillsData = await PharmacyService.fetchSkills();

        const workerTypesAndRates = await AuthService.getRBDentalShiftWorkerTypeAndRate();
        const workerTypes = await AuthService.getAllWorkers('Dentistry');
        const sortedDataArray = workerTypes;
        sortedDataArray.sort((a:any, b:any) => a.orderSort - b.orderSort);

        setAllSpecializationSkills(fetchFullSkillsData.data.filter(
          (skill: ObjectLiteral) => skill.type === SkillType.specialization
        ))
        setWorkerTypes(sortedDataArray)
        setOriginalShiftData(shiftDetailData);
        setShiftTimeZone(shiftDetailData.shiftTimeZone);
        setComment(shiftDetailData.comment);
        setShiftStartTime(shiftDetailData.startTime);
        setDeviceShiftStartTime(moment(shiftDetailData.startTime).tz(shiftTimeZone).toDate());
        setShiftEndTime(shiftDetailData.endTime);
        setDeviceShiftEndTime(moment(shiftDetailData.endTime).tz(shiftTimeZone).toDate());
        setWorkerType(shiftDetailData.pharmacistType);
        setTechnicianOnSite(shiftDetailData.technicianOnSite);
        setAssistantOnSite(shiftDetailData.assistantOnSite);
        setIsAllowanceEnabled(shiftDetailData.kmAllowance > 0 || shiftDetailData.accomodationAllowance > 0)
        setSpecializations(
          fetchSkillsData.data.filter(
            (skill: ObjectLiteral) => skill.type === SkillType.specialization
          )
        );
        setSelectedSpecializations(
          shiftDetailData.skills
            .filter(
              (skill: ObjectLiteral) => skill.type === SkillType.specialization
            )
            .map((skill: SkillResponseDto) => skill.id)
        );
        setSoftwareRequired(
          shiftDetailData.skills
            .filter((skill: ObjectLiteral) => skill.type === SkillType.dentalSoftware)
            .map((skill: SkillResponseDto) => skill.required)[0]
        );
        setSelectedSoftware(
          shiftDetailData.skills.filter(
            (skill: ObjectLiteral) => skill.type === SkillType.dentalSoftware
          ).length > 0
            ? shiftDetailData.skills.filter(
                (skill: ObjectLiteral) => skill.type === SkillType.dentalSoftware
              )[0]
            : {}
        );
        setSoftwareName(
          shiftDetailData.skills
            .filter((skill: ObjectLiteral) => skill.type === SkillType.dentalSoftware)
            .map((skill: SkillResponseDto) => skill.name)[0]
        );
        setWage((shiftDetailData.wage / 100).toFixed(2));
        setKmAllowance((shiftDetailData.kmAllowance / 100).toFixed(2));
        setAccomodationAllowance(
          (shiftDetailData.accomodationAllowance / 100).toFixed(2)
        );
        setMinimumWage(minimumWage);
        setWorkerTypesAndRates(workerTypesAndRates);
        setIsPageLoading(false);
        setAllowOfferChangeOption(shiftDetailData.pharmacy.allowOfferChangeOption)
        setAllowOffer(shiftDetailData.allowOffers)
      } catch (err) {
        setSubmitErrorMessage(err.response ? err.response.data.message : err.message);
      } finally {
      }
    };

    fetchData();
  }, [match.params.shiftId]);

  // useEffect(() => {
  //   isWageValid();
  // }, [wage]);
  useEffect(() => {
    if (Object.keys(originalShiftData).length > 0) {
      checkIsShiftEdited();
    }
  }, [
    wage,
    comment,
    kmAllowance,
    accomodationAllowance,
    technicianOnSite,
    assistantOnSite,
    softwareRequired,
    selectedSpecializations,
    shiftStartTime,
    shiftEndTime,
    allowOffer
  ]);

  const checkIsShiftEdited = () => {
    //   
  const originalWage = (originalShiftData.wage / 100).toFixed(2);
    const originalKmAllowance = (originalShiftData.kmAllowance / 100).toFixed(
      2
    );
    const originalAccomodationAllowance = (
      originalShiftData.accomodationAllowance / 100
    ).toFixed(2);
    const originalSelectedSoftware = originalShiftData.skills.filter(
      (skill: SkillResponseDto) => skill.type === "dentalSoftware"
    );
    let originalSoftwareRequired = false;
    if (originalSelectedSoftware.length > 0) {
      const software = originalSelectedSoftware[0];
      originalSoftwareRequired = software.required;
    }
    const originalSelectedSpecializations = originalShiftData.skills.filter(
      (skill: SkillResponseDto) => skill.type === "specialization"
    );
    const originalSelectedSpecializationsString = JSON.stringify(
      map(orderBy(originalSelectedSpecializations, "id"), "id")
    );
    const selectedSpecializationsString = JSON.stringify(
      selectedSpecializations.sort()
    );
    const isEdited =
      comment !== originalShiftData.comment ||
      technicianOnSite !== originalShiftData.technicianOnSite ||
      assistantOnSite !== originalShiftData.assistantOnSite ||
      kmAllowance !== originalKmAllowance ||
      accomodationAllowance !== originalAccomodationAllowance ||
      wage !== originalWage ||
      originalShiftData.startTime !== shiftStartTime ||
      originalShiftData.endTime !== shiftEndTime ||
      softwareRequired !== originalSoftwareRequired ||
      originalSelectedSpecializationsString !== selectedSpecializationsString ||
      originalShiftData.pharmacistType !== workerType||
      originalShiftData.allowOffers !== allowOffer;
    // console.log(
    //   "",
    //   originalShiftData,
    //   comment,
    //   wage,
    //   technicianOnSite,
    //   assistantOnSite,
    //   kmAllowance,
    //   accomodationAllowance,
    //   softwareRequired,
    //   originalSelectedSpecializationsString,
    //   workerType
    // );

    setIsEdited(isEdited);
    return isEdited;
  };
  const getNextWorkingDay = (date:any) => {
      
    let nextDate = moment(date).add(24, "hours");
    let nextDay = nextDate.format('dd');

    while (!originalShiftData.pharmacy.defaultWorkWeek.includes(nextDay)) {
      nextDate = moment(nextDate).add(24, "hours");
      nextDay = nextDate.format('dd');
    }

    return nextDate;
  };

  const isWeekday = (date:any) => {
    const { pharmacy } = originalShiftData.pharmacy;
    const dayOfWeek = moment(date).format('dd');
     
     // Check if the day of the week is in the defaultWorkDays array
     return originalShiftData.pharmacy.defaultWorkWeek.includes(dayOfWeek);
  };

  const excludedDates:any = [];

  const minDate = () => {
    const { pharmacy } = originalShiftData.pharmacy;
    let currentDate = moment().tz(shiftTimeZone);
    let currentDay  = currentDate.format('dd');

    while (!originalShiftData.pharmacy.defaultWorkWeek.includes(currentDay)) {
      currentDate = currentDate.add(1, 'day');
      currentDay  = currentDate.format('dd');

    }

    return moment(currentDate)
  }


  const maxDate = (startDate:any) => {
      
    let newDate = getNextWorkingDay(startDate);
    console.log("Max Date 1", moment(newDate).tz(shiftTimeZone).format())
    let currentDate = moment(startDate).add(1, 'day'); // Start date + 1 day

    while (currentDate.isBefore(newDate)) {
      if (!originalShiftData.pharmacy.defaultWorkWeek.includes(currentDate)) {
        excludedDates.push(currentDate.toDate());
      }
      currentDate = currentDate.add(1, 'day');
    }
    console.log("Max Date", moment(newDate).format())
    return moment(newDate)
  }

  const renderShiftTime = () => {
    return (
      <div style={{ marginBottom: Spacing.small }}>
        <div
          style={{
            ...ColumnStyle, alignItems: "center"
          }}
        >
          {/* <RBDateTimePicker
            inputProps={{
              style: {
                paddingTop: Spacing.medium,
                paddingBottom: Spacing.medium,
              },
            }}
            key={`dateTime-${shiftTimeZone}`}
            style={{ display: "flex", flex: 1 }}
            label={"Start Time"}
            minDate={moment().tz(shiftTimeZone).toDate()}
            value={moment(shiftStartTime).tz(shiftTimeZone)}
            margin="dense"
            onChange={(date) => {
              console.log('date1',shiftTimeZone)
              setShiftStartTime(
                date
                  ? moment(date).tz(shiftTimeZone).toISOString()
                  : moment().tz(shiftTimeZone).toISOString()
              );
            }}
          /> */}
          <div style={{ display: "flex", flex: 1 }}>
              <DatePicker
                selected={getDateTime(moment(shiftStartTime).tz(shiftTimeZone)).toDate()}
                onChange={(date) => {
                  const offsetString1 = convertOffsetToString(moment(shiftStartTime).tz(shiftTimeZone).utcOffset())
                  setShiftStartTime(
                    date
                      ? moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1)
                      : moment()
                  );
                  
                  setDeviceShiftStartTime(date ? moment(date) : moment())
                  setShiftStartTime(date ? moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1) :
                    moment(moment().format('YYYY-MM-DDTHH:mm:ss') + offsetString1))

                  const momentStartTime = moment(moment(shiftEndTime).tz(shiftTimeZone), 'h:mm A')
                  const newStartHour = momentStartTime.hours();
                  const newStartMin = momentStartTime.minutes(); 
                  console.log("formated Date", moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1).tz(shiftTimeZone).set('hour', newStartHour).set('minute', newStartMin).set('second', 0).format())
                  setShiftEndTime(moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1).tz(shiftTimeZone).set('hour', newStartHour).set('minute', newStartMin).set('second', 0));
                  //setShiftEndTime(moment(moment(moment(date).set('hour', newStartHour).set('minute', newStartMin).set('second', 0)).format('YYYY-MM-DDTHH:mm:ss')+ offsetString1))
                  setDeviceShiftEndTime(moment(date).set('hour', newStartHour).set('minute', newStartMin).set('second', 0));
                  

                  let minimumStartTime = minDate();
                  if (moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1).isBefore(minimumStartTime)) {
                    setStartTimeValidation("Enter valid start time");
                    setEndTimeValidation(null);
                  } else {
                    setStartTimeValidation(null);
                    setEndTimeValidation(null);
                  }

                  const maxEndTime = moment(maxDate(moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1)));
                  const minEndTime = moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1).add(5,"minutes");
                  // const maxEndTime = moment(maxDate(moment(shiftStartTime).tz(shiftTimeZone)));
                  // const minEndTime = moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1).add(5,"minutes");
                  const newShiftEndTime = moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1).tz(shiftTimeZone).set('hour', newStartHour).set('minute', newStartMin).set('second', 0);
                  if (moment(newShiftEndTime).isBefore(moment(newShiftEndTime))) {
                    // Show an error message or prevent the selection
                    setEndTimeValidation("Enter valid end time");
                  } else if (moment(shiftEndTime).isAfter(maxEndTime)) {
                    setEndTimeValidation("Enter valid end time");
                  } else {
                    setEndTimeValidation(null);
                  }
                }}
                showTimeSelect
                minDate={minDate().toDate()}
                timeFormat="h:mm aa"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="eee, MMM do yyyy h:mm aa"
                customInput={
                  <input
                    style={{
                      paddingTop: Spacing.medium,
                      paddingBottom: Spacing.medium,
                      paddingRight: "30%",
                      borderRadius: 5,
                      borderColor: "rgb(220 220 220)",
                      width: 356,
                      fontSize:16,
                      paddingLeft:10
                    }}
                    key={`dateTime-${shiftTimeZone}`}
                  />
                }
                withPortal
                filterDate={isWeekday}
              />
            </div>
          <RBEmptyBlock />
         
          <div style={{ display: "flex", flex: 1 }}>
              <DatePicker
                selected={getDateTime(moment(shiftEndTime).tz(shiftTimeZone)).toDate()}

                onChange={(date) => {
                  debugger
                  const offsetString1 = convertOffsetToString(moment(shiftEndTime).tz(shiftTimeZone).utcOffset())
                  setShiftEndTime(
                    date
                      ? moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1)
                      : moment()
                  );
                  setDeviceShiftEndTime(date ? moment(date) : moment())
                  setShiftEndTime(date ? moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1) :
                    moment(moment().format('YYYY-MM-DDTHH:mm:ss') + offsetString1))

                  const maxEndTime = moment(maxDate(moment(shiftStartTime).tz(shiftTimeZone)));
                  const minEndTime = moment(moment(shiftStartTime).tz(shiftTimeZone)).add(5,"minutes");
                  if (moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1).tz(shiftTimeZone).isBefore(moment(minEndTime))) {
                    // Show an error message or prevent the selection
                   setEndTimeValidation("Enter valid end time");
                  } else if ( moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1).tz(shiftTimeZone).isAfter(maxEndTime)) {
                    setEndTimeValidation("Enter valid end time");
                  } else {
                    setEndTimeValidation(null);;
                    }
                }}
                showTimeSelect
                minDate={
                  moment.max(
                    moment().tz(shiftTimeZone),
                    moment((shiftStartTime))
                  ).toDate()
                }
               maxDate={(maxDate(deviceShiftStartTime)).toDate()}
                // minTime= {moment(this.getDateTime(shift.deviceStartTime)).toDate()}
                // maxTime={moment(this.getDateTime(this.maxDate(shift.deviceStartTime))).toDate()}
                
                timeFormat="h:mm aa"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="eee, MMM do yyyy h:mm aa"
                customInput={
                  <input
                    style={{
                      paddingTop: Spacing.medium,
                      paddingBottom: Spacing.medium,
                      paddingRight: "42%",
                      borderRadius: 5,
                      borderColor: "rgb(220 220 220)",
                      width: 407,
                      fontSize:16,
                      paddingLeft:10
                    }}
                    key={`dateTime-${shiftTimeZone}`}
                  />
                }
                withPortal
                excludeDates={excludedDates}
              />
            </div>
        </div>
        <div
          style={{
            ...ColumnStyle, alignItems: "center"
          }}
        >
          <div style={{ display: "flex", flex: 1 }}>
            {startTimeValidation && (
              <Typography variant="caption" color="error" style={{ marginLeft: 20, marginBottom: "8px" }}>
                {startTimeValidation}
              </Typography>
            )}
          </div>
          <div style={{ display: "flex", flex: 1 }}>
            {endTimeValidation && (
              <Typography variant="caption" color="error" style={{ marginLeft: 20 }}>
                {endTimeValidation}
              </Typography>
            )}
          </div>

        </div>
      </div>
    );
  };
  const onSpecializationSkillSelectedChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    // this is a multiple select, so event.target.value will be an array
    // console.log("onSpecializationSkillSelectedChange", event.target.value);

    setSelectedSpecializations(event.target.value as string[]);
  };
  const renderSkills = () => {
    return (
      <>
      <div style={{ ...ColumnStyle, alignItems: "center" }}>
        <Typography style={{ display: "flex", flex: 1 }}>
          Specialization Skills
        </Typography>
        <RBEmptyBlock />
        <FormControl
          variant="outlined"
          size={"small"}
          style={{ display: "flex", flex: 1 }}
        >
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedSpecializations}
            onChange={onSpecializationSkillSelectedChange}
            displayEmpty
            renderValue={(selected) => {
              if ((selected as string[]).length === 0) {
                return <em>Add Skills</em>;
              }
              return (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {(selected as string[]).map((skillID) => {
                    return (
                      <div key={skillID} style={{ ...skillTextStyle }}>
                        {
                          specializations.filter(
                            (specialization) => specialization.id === skillID
                          )[0].name
                        }
                      </div>
                    );
                  })}
                </div>
              );
            }}
          >
            {specializations.map((specialization) => (
              <MenuItem key={specialization.id} value={specialization.id}>
                <Checkbox
                  checked={
                    selectedSpecializations.indexOf(specialization.id) > -1
                  }
                  style={{
                    color:
                      selectedSpecializations.indexOf(specialization.id) > -1
                        ? secondaryColor
                        : Colors.darkGrey,
                  }}
                />
                <ListItemText primary={specialization.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div
      style={{
        ...ColumnStyle,
        alignItems: "center",
        marginTop: -Spacing.small,
      }}
    >
      <Typography style={{ display: "flex", flex: 1 }}></Typography>
      <RBEmptyBlock />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          fontSize: 12,
          textAlign: "start",
        }}
      >
        *Select only mandatory skills
      </div>
    </div>
    </>
    );
  };

  const renderPharmacySpecific = () => {
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <Typography style={{ display: "flex", flex: 1 }}>
            Pharmacy Software
          </Typography>
          <RBEmptyBlock />
          <FormControlLabel
            style={{
              display: "flex",
              flex: 1,
              color: softwareRequired ? secondaryColor : Colors.darkGrey,
            }}
            control={
              <Checkbox
                checked={softwareRequired}
                style={{
                  color: softwareRequired ? secondaryColor : Colors.darkGrey,
                }}
                onChange={() => setSoftwareRequired((pre) => !pre)}
              />
            }
            label={<Typography>{softwareName}</Typography>}
          />
        </div>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            marginTop: -Spacing.large,
          }}
        >
          <Typography style={{ display: "flex", flex: 1 }}></Typography>
          <RBEmptyBlock />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              fontSize: 12,
              textAlign: "start",
            }}
          >
            {Claims.pharmacySoftwareClaim}
          </div>
        </div>
        
      </>
    );
  };
  const renderWorkerType = () => {
    return (
      <div style={{ ...ColumnStyle, alignItems: "center" }}>
        <Typography style={{ display: "flex", flex: 1 }}>
          Worker Type
        </Typography>
        <RBEmptyBlock />
        <FormControl
          variant="outlined"
          size={"small"}
          style={{ display: "flex", flex: 1 }}
        >
          <Select
          value={workerType}
          renderValue={workerType !== "" ? undefined : () => "Select"}
          onChange={updateWorkerType}>
          {workerTypes.map((value:any) => (
            <MenuItem value={value.workerType} key={value.workerType}>
              {value.displayName}
            </MenuItem>
          ))}
        </Select>
          {/* <Select
            value={workerType}
            onChange={() => {}}
            displayEmpty
            //disabled={true}
            renderValue={(selected) => {
              if ((selected as string).length === 0) {
                return <em>Select</em>;
              }
              return <div>{formatWorkerType(selected as workerTypeEnum)}</div>;
            }}
          >
            <MenuItem value={workerTypeEnum.pharmacist}>
              {formatWorkerType(workerTypeEnum.pharmacist)}
            </MenuItem>
            <MenuItem value={workerTypeEnum.technician}>
              {formatWorkerType(workerTypeEnum.technician)}
            </MenuItem>
            <MenuItem value={workerTypeEnum.assistant}>
              {formatWorkerType(workerTypeEnum.assistant)}
            </MenuItem>
            <MenuItem value={workerTypeEnum.pharmacistStudent}>
              {formatWorkerType(workerTypeEnum.pharmacistStudent)}
            </MenuItem>
          </Select> */}
        </FormControl>
      </div>
    );
  };

  const updateWorkerType = async (event: any) => {
   
    setWorkerType(event.target.value);
    setSpecializations(
      AllSpecializationSkills.filter(
        (skill:any) => skill.pharmacistType === event.target.value
      )
    );
    setSelectedSpecializations([])
    setWage("0")
    setWageInvalidErrorText(EmptyString)
    
  };

  const isWageValid = () => {
    // Returns the minimum (inclusive) that the wage must be set to based on the
    // currently selected pharmacist type.
    // Returns the minimum (inclusive) that the wage must be set to based on the
    // currently selected pharmacist type.
    const getMinimumWage = () => {
      return workerTypesAndRates.filter((w:any) => w.workerType === workerType)
        .length > 0
        ? workerTypesAndRates.filter((w:any) => w.workerType === workerType)[0]
            .minimumRate / 100
        : 100;
    };
    const lowerBound = getMinimumWage();

    setWageInvalidErrorText(
      Number(wage) >= lowerBound
        ? EmptyString
        : `The minimum rate is $${lowerBound.toFixed(2)}`
    );
    return Number(wage) >= lowerBound;
  };

  const renderWage = () => {
    return (
      <>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            paddingBottom: Spacing.smallest,
          }}
        >
          <Typography style={{ display: "flex", flex: 1,color:"red"  }}>
          Hourly Rate Offered
          </Typography>
          <RBEmptyBlock />
          <RBMoneyAmountTextField
            name="currency"
            value={wage}
            onChange={(event) =>
              setWage(
                event.target.value == "0.00" ? "0" : event.target.value,
              )
            }
            error={wageInvalidErrorText.length > 0}
            errorText={wageInvalidErrorText}
          />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            textAlign: "start",
            fontSize: 12,
            color:"red"
          }}
        >
          {Claims.shiftCommission}
        </div>
        {allowOfferChangeOption && <>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            paddingBottom: Spacing.smallest,
          }}
        >
          <Typography style={{ display: "flex", flex: 1}}>
          Allow rate offers?
          </Typography>
          <RBEmptyBlock />
            <FormControlLabel
              style={{
                display: "flex",
                flex: 1,
              }}
              control={
                <Checkbox
                  checked={allowOffer}
                  // style={{
                  //   color: allowOffer
                  //     ? secondaryColor
                  //     : Colors.darkGrey,
                  // }}
                  // onChange={() =>
                  //  setAllowOffer(!allowOffer)
                  // }
                />
              }
              label={EmptyString}
            />
          </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            textAlign: "start",
            fontSize: 12,
          }}
        >
          {Claims.Allow_Offers_Description}
        </div>
        </>}

      </>
    );
  };
  const renderAllowance = () => {
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <Typography style={{ display: "flex", flex: 1 }}>
            Commute Allowance
          </Typography>
          <RBEmptyBlock />
          <FormControlLabel
            style={{
              display: "flex",
              flex: 1,
            }}
            control={
              <Checkbox
                checked={isAllowanceEnabled}
                style={{
                  color: isAllowanceEnabled
                    ? secondaryColor
                    : Colors.darkGrey,
                }}
                onChange={() =>
                  setIsAllowanceEnabled(!isAllowanceEnabled)
                }
              />
            }
            label={EmptyString}
          />
        </div>
        {isAllowanceEnabled && (<> 
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {detailKmAllowanceIcon(primaryColor)}
            <div style={{ marginLeft: Spacing.small }}>Per Km</div>
          </div>
          <RBEmptyBlock />
          <RBMoneyAmountTextField
            name="currency"
            inputProps={{
              style: {
                paddingTop: Spacing.smaller,
                paddingBottom: Spacing.smaller,
              },
            }}
            onChange={(event) =>
              setKmAllowance(
                event.target.value == "0.00" ? "0" : event.target.value,
              )
            }
            value={kmAllowance}
          />
        </div>

        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 4,
              marginRight: -4,
            }}
          >
            {detailAccomodationAllowanceIcon(primaryColor)}
            <div style={{ marginLeft: Spacing.small }}>Per Shift</div>
          </div>
          <RBEmptyBlock />

          <RBMoneyAmountTextField
            inputProps={{
              style: {
                paddingTop: Spacing.smaller,
                paddingBottom: Spacing.smaller,
              },
            }}
            onChange={(event) =>
              setAccomodationAllowance(
                event.target.value == "0.00" ? "0" : event.target.value,
              )
            }
            value={accomodationAllowance}
          />
        </div>
        </>)}
      </>
    );
  };

  const renderComment = () => {
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <Typography style={{ display: "flex", flex: 1 }}>Comment</Typography>
        </div>
        <RBTextField
          style={{
            display: "flex",
            flex: 1,
            paddingTop: Spacing.small,
            paddingBottom: Spacing.small,
          }}
          placeholder={"*Please add any important details about the shift(s) so the temps can understand the expectations before applying."}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          multiline
          rows={3}
          rowsMax={8}
        />
      </>
    );
  };
  const isFormValid = () => {
      
    const durationAsHours = moment
      .duration(moment(shiftEndTime).diff(moment(shiftStartTime)))
      .asHours();
    return (
      wageValidity &&
      moment(shiftStartTime).isBefore(moment(shiftEndTime)) &&
      durationAsHours <=24 &&
      isEdited &&
      !loading
    );
  };
  const onUpdateShift = async () => {
    if (!isFormValid()) {
      return;
    }
    if (!isWageValid()) {
      return;
    }
    setIsLoading(true);

    // Build the skills list and add the software to the end
    const skills = selectedSpecializations.map((skillId) => {
      const skill = specializations.filter(
        (specialization) => specialization.id === skillId
      )[0];
      return {
        skillId: skill.id,
        pharmacistType: skill.pharmacistType,
        required: true,
        name: skill.name,
        type: skill.type,
      };
    });
    if (selectedSoftware && Object.keys(selectedSoftware).length > 0) {
      skills.push({
        skillId: selectedSoftware.id,
        pharmacistType: selectedSoftware.pharmacistType,
        required: softwareRequired,
        name: selectedSoftware.name,
        type: selectedSoftware.type,
      });
    }

    const payload = {
      id: originalShiftData.id,
      startTime: shiftStartTime,
      endTime: shiftEndTime,
      comment,
      wage: (parseFloat(wage) * 100).toFixed(2),
      allowOffers:allowOffer,
      kmAllowance: isAllowanceEnabled ?  (parseFloat(kmAllowance) * 100).toFixed(2): 0,
      accomodationAllowance: isAllowanceEnabled ? (parseFloat(accomodationAllowance) * 100).toFixed(2): 0,
      technicianOnSite,
      assistantOnSite,
      // User may have selected skills from both types of pharmacist.  We only
      // want to use the ones that are associated with the type that they ended
      // up posting for.
      skills: skills.filter(
        (skill) =>
          skill.pharmacistType === null || skill.pharmacistType === workerType
      ),
      pharmacistType:workerType,
      actualCommission : originalShiftData.actualCommission ?  originalShiftData.actualCommission : (originalShiftData.pharmacy.commissionType === 'percentage' ? originalShiftData.pharmacy.commissionPercentage : originalShiftData.pharmacy.commission),
      commissionType: originalShiftData.commissionType? originalShiftData.commissionType : originalShiftData.pharmacy.commissionType ,
      commissionMinimum:originalShiftData.commissionMinimum ? originalShiftData.commissionMinimum : originalShiftData.pharmacy.commissionMinimum,
      commissionMaximum:originalShiftData.commissionMaximum ? originalShiftData.commissionMaximum: originalShiftData.pharmacy.commissionMaximum,
      networkPaid:originalShiftData.networkPaid ? originalShiftData.networkPaid: originalShiftData.pharmacy.networkPaid
    

    };
    console.log("edit shift update payload", payload);

    try {
      const updatedShift = await PharmacyService.updateShift(payload);
      setIsLoading(false);
      NotificationService.publish(
        NotificationServiceTopic.ShiftChanged,
        originalShiftData.id
      );
      //history.goBack();
      setCreateShiftSubmitInfo({
        title: "Shift Updated",
        message: "Your shift has been updated.",
        buttons: [
          
          <RBButton
          buttonBackgroundColor={primaryColor}
            key={"Discard"}
            onClick={() => {
              setIsCreateShiftSubmitInfo(false)
              history.push("/shift/list")
            }}
            color="primary"
            text="Ok"
          />,
        ],
      })
      setIsCreateShiftSubmitInfo(true)

    } catch (err) {
      const errorMessage = err.response ? err.response.data.message : err.message;
      setSubmitErrorMessage(errorMessage);
      console.log("update shift on submit err", JSON.stringify(err, null, 2));
    } finally {
      setIsLoading(false);
    }
  };
  const renderActions = () => {
    console.log("edit shift renderActions");
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <RBButton
          // onClick={() => {
          //   doAction(ShiftRelatedConstants.ShiftButtonText.CancelShift);
          // }}
          onClick={() => onUpdateShift()}
          isLoading={loading}
          disabled={!isFormValid()}
          text="Update"
          style={{
            flex: 1,
            padding: Spacing.large,
            marginLeft: Spacing.large,
            marginRight: Spacing.large,
          }}
          buttonBackgroundColor={primaryColor}
        />
      </div>
    );
  };
  return (
    <>
      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={JSON.stringify(submitErrorMessage, null, 2)}
        onClose={() => {
          setSubmitErrorMessage({ submitErrorMessage: undefined });
        }}
      />
       <RBAlert
          show={isCreateShiftSubmitInfo}
          alertTitle={createShiftSubmitInfo.title}
          alertMessage={createShiftSubmitInfo.message}
          buttons={createShiftSubmitInfo.buttons}
        />
        
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <RBCardView style={{ margin: Spacing.large, padding: Spacing.large }}>
            {renderShiftTime()}
            {renderWorkerType()}
            <div style={{ marginTop: Spacing.small }}>{renderSkills()}</div>
            {renderPharmacySpecific()}
            {renderWage()}
            <div style={{ marginTop: Spacing.small }}>{renderAllowance()}</div>
            {renderComment()}
          </RBCardView>
          {renderActions()}
        </>
      )}
    </>
  );
};
