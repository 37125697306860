import React, { useState, useEffect, Component, useRef ,useContext} from "react";
import { JobListPage } from "./JobListPage";
import { ShiftService } from "../../api/ShiftService";
import { ShiftProps } from "../../interfacesProps/ShiftProps";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { AuthService } from "../../api/AuthService";
import {
  Tabs,
  Tab,
  makeStyles,
  createStyles,
  IconButton,
  Paper,
  Typography,
  Icon,
} from "@material-ui/core";
import { roleNameEnum } from "../../util/Common";
import { ShiftRelatedConstants } from "../../util/ShiftConstants";
import { Colors, Spacing } from "../../theme/Theme";
import { RBAppbar } from "../../components/RBAppbar";
import moment from "moment";
import TuneIcon from "@material-ui/icons/Tune";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import FormatListBulletted from "@material-ui/icons/FormatListBulleted";
import CalendarToday from "@material-ui/icons/CalendarToday";
import CalendarTodayOutlined from "@material-ui/icons/CalendarTodayOutlined";
import CalendarViewDayOutlined from "@material-ui/icons/CalendarViewDayOutlined";
import {ReactComponent as CalendarIconSvg} from '../../img/CalendarMonthViewGrey.svg';
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { RBModal } from "../../components/RBModal";
import { RBCardView } from "../../components/RBCardView";
import { RBCalendar } from "../../components/RBCalendar";
import { RBContext } from "../../components/RBContext";
import { CalendarViewDayTwoTone } from "@material-ui/icons";
import { IndustryModeEnum } from "../../api/constants";
import { useLocation } from "react-router";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";

interface JobListControllerProps {}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textTransform: "none",
      // color: Colors.black,
      "&:hover": {
        color: Colors.themeBlue,
        opacity: 1,
      },
      "&$selected": {
        color: Colors.black,
        fontWeight: "600",
      },
      "&:focus": {
        color: Colors.black,
      },
    },
    selected: {
      //color: Colors.themeBlue,
    },
    indicator: {
      backgroundColor: Colors.black,
    },
  })
);

enum JobStateForPharmacy {
  Active = "Active",
  Past = "Inactive",
}
enum JobStateForWorker {
  Accepted = "Accepted",
  Request = "Applied",
  Invited = "Invited"
}
export const JobListController: React.FC<JobListControllerProps> = ({
  ...props
}) => {
  const [jobs, setJobs] = useState<ShiftProps[]>([]);
  const [calendarJobs, setCalendarJobs] = useState<ShiftProps[]>([]);
  const [refresh, setRefetch] = useState(false);
  const [jobState, setJobState] = useState(0);
  const [roleName] = useState<string>(AuthService.getRoleName());
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [openTuneSetting, setOpenTuneSetting] = useState(false);
  const anchorTuneRef = React.useRef<HTMLButtonElement>(null);
  const [showAllJobs, setShowAllJobs] = useState(false);
  const didMountRef = useRef(false);
  const [pharmacy, setPharmacyInfo] = useState<any>([]);
  const [viewOption, setViewOption] = useState('List');
  const [listShifts, setListShifts] = useState<ShiftProps[]>([]);
  const location = useLocation<{
    background?: any;
    selectedPharmacyIndex: any;
  }>();
  const { industryMode,primaryColor,secondaryColor,updateprimaryColor,updateSecondaryColor,updateContactEmail,updateContactPhone,updateMode  } = useContext(RBContext);

  const onJobChanged = (data: string) => {
    console.log("onJobChanged", data);
    setRefetch((pre) => !pre);
  };

  const fetchData = async () => {
    const userData = await AuthService.loadAccountInfo();
    setIsPageLoading(true);
    if (roleName === roleNameEnum.owner) {
      const defaultPharmacyId = userData.owner.defaultPharmacyId;
      const indexOfDefaultPharmacy = userData.pharmacies.findIndex((obj: ObjectLiteral) => obj.id == defaultPharmacyId)
      console.log('indexOfDefaultPharmacy', indexOfDefaultPharmacy)
      const pharmacy = userData.pharmacies[indexOfDefaultPharmacy ?? 0];
      console.log('pharmacy selected ', pharmacy);
      setPharmacyInfo(pharmacy);
      const actionNeededStates = [
        ShiftRelatedConstants.JobStateText.AwaitingHiring,
        ShiftRelatedConstants.JobStateText.ReadyForJob,
        ShiftRelatedConstants.JobStateText.ActiveJob,
        ShiftRelatedConstants.JobStateText.RateJob,
      ];
      const noActionNeededStates = [
        ShiftRelatedConstants.JobStateText.Expired,
        ShiftRelatedConstants.JobStateText.Cancelled,
      ];
      switch (jobState) {
        case 0: {
          //pharmacy upComing
          const JobListData = await ShiftService.getJobList({
            byPriority: true,
            showAllJobs,
            networkId:pharmacy.networkId
          });
          setJobs(
            JobListData.data.filter((job: any) => {
              return actionNeededStates.includes(
                ShiftService.getStatusForJobAsPharmacy(job)
              );
            })
          );
          break;
        }
        case 1: {
          //pharmacy
          const JobListData = await ShiftService.getJobList({
            showAllJobs,
            networkId:pharmacy.networkId
          });
          setJobs(
            JobListData.data
              .filter((job: any) => {
                return noActionNeededStates.includes(
                  ShiftService.getStatusForJobAsPharmacy(job)
                );
              })
          );
          break;
        }
      }
      console.log('list',jobs)
      setShowAllJobs(
        localStorage.getItem("showAllJobs")
          ? localStorage.getItem("showAllJobs") === "Yes"
          : false
      );
    }
    if (roleName === roleNameEnum.pharmacist) {
      let data = [];
      switch (jobState) {
        case 0: {
          // worker accepted
          const JobListData = await ShiftService.getJobList({
            limit: 1000,
            state: "assigned",
            networkId:userData.networks[0].id
          });
          setJobs(JobListData.data.filter((job:any) => {
            job.jobState = jobState;
            return job.flags && job.flags.isAssigned;
          }));


          break;
        }
        case 1: {
          // worker applied
          const JobListData = await ShiftService.getJobList({
            limit: 1000,
            state: "requested",
            networkId:userData.networks[0].id
          });

          setJobs(JobListData.data.filter((job:any) => {
            job.jobState = jobState;
            return !(job.flags && job.flags.isAssigned);
          }));

          break;
        }
        case 2: {

          const JobListData = await ShiftService.getWorkerInvitedJobs({
            limit: 1000,
            state: "invited",
            networkId:userData.networks[0].id
          });

          setJobs(JobListData.data.filter((job:any) => {
            job.jobState = jobState;
            return !(job.flags && job.flags.isAssigned && job.flags.isCancelled);
          }));
          break;
        }
      }
    }
    setCalendarJobs(jobs);
    setIsPageLoading(false);
  };
  useEffect(() => {
    // debugger

    (async () => {
        await updateprimaryColor( localStorage.getItem('primaryColor')); 
        await updateSecondaryColor( localStorage.getItem('secondaryColor')); 
        await updateContactEmail( localStorage.getItem('contactEmail')); 
        await updateContactPhone( localStorage.getItem('contactPhone')); 
        await updateMode(localStorage.getItem('industryMode')); 
        
    })();  

    
    const unsub = NotificationService.subscribe(
      NotificationServiceTopic.ShiftChanged,
      onJobChanged
    );
    

    fetchData();

    return () => {
      console.log("shift list controller clean up");
      unsub.unsubscribe();
    };
  }, [jobState, refresh]);
  useEffect(() => {
    // debugger
    console.log("worker shift",jobs)
    setCalendarJobs(jobs);
  }, [jobs]);
  useEffect(() => {
    // debugger
    if (didMountRef.current) {
      console.log("showAllJobs changed for shift list", showAllJobs);
      localStorage.setItem("showAllJobs", showAllJobs ? "Yes" : "No");
      fetchData();
    } else {
      didMountRef.current = true;
    }
  }, [showAllJobs, viewOption]);
  useEffect(() => {
    // debugger
    setViewOption('List');
    if(localStorage.getItem('JobState'))
    {
      setJobState(Number(localStorage.getItem('JobState')));
    }
  }, [])

  const handleJobStateChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setJobState(newValue);
    localStorage.setItem('JobState',newValue.toString())
  };
  const classes = useStyles();
  const renderAppBar = () => {
    // const pharmacyName = AuthService.getOwnerAccountInfo();
    // console.log('ownerAccountInfo', pharmacy)
    const allOfficeText = industryMode === IndustryModeEnum.Dentistry ?
                          'Dental Practices' :
                          'Pharmacies'
    console.log('showAllShift', showAllJobs)
    return (
      <>
        <RBAppbar
          searchable={false}
          barName={roleName === roleNameEnum.owner 
            ? showAllJobs 
            ? 'All Locations' 
            : 'Job List' 
            : 'Job List'}
          barChildren={
            <>
              <Tabs
                value={jobState}
                onChange={handleJobStateChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                classes={{ indicator: classes.indicator }}
                style={{
                  marginRight: Spacing.small,
                }}
              >
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  label={
                    roleName === roleNameEnum.owner
                      ? JobStateForPharmacy.Active
                      : JobStateForWorker.Accepted
                  }
                />
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  label={
                    roleName === roleNameEnum.owner
                      ? JobStateForPharmacy.Past
                      : JobStateForWorker.Request
                  }
                />
                {roleName === roleNameEnum.pharmacist &&
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  label={
                    JobStateForWorker.Invited
                  }
                />}
              </Tabs>
              {roleName === roleNameEnum.owner && (
                <IconButton
                  style={{ color: primaryColor }}
                  onClick={() => setOpenTuneSetting((prevOpen) => !prevOpen)}
                  ref={anchorTuneRef}
                >
                  <TuneIcon />
                </IconButton>
              )} 
            </>
          }
        />
      </>
    );
  };
  const renderTuneSettingContent = () => {
    const pharmacyName = AuthService.getOwnerAccountInfo().pharmacyName;
    return (
      <>
      <Paper style={{ margin: Spacing.medium }}>
        {roleName === roleNameEnum.owner &&
          (<RBCardView
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: Spacing.small,
            }}
            clickable={true}
            onClick={async () => {
              setOpenTuneSetting(false);

              setShowAllJobs((pre) => !pre);
            }}
          >
            <SwapHorizIcon fontSize={"large"} />
            <div style={{ paddingLeft: Spacing.larger }}>
              <Typography>
                {showAllJobs ? "View limited jobs" : "View all jobs"}
              </Typography>
              <Typography variant={"caption"}>
                {showAllJobs
                  ? `Select here to view: ${pharmacyName}`
                  : "Select here to view: Jobs for all"}
              </Typography>
            </div>
          </RBCardView>
          ) } 
        </Paper>
      </>
    );
  };

  const renderViews = () => {
    const anchorStyles = {
      cursor: 'pointer',
      // filter:`brightness(0) invert(1) saturate(100%) hue-rotate(${secondaryColor}deg)`,

    }

    return (
      <div style={{flex:1 , textAlign: 'center'}}>
        <a
          style={anchorStyles}
          onClick={() => {
            setViewOption('List');
            setOpenTuneSetting(false);
            // setShowAllJobs(() => true)
          }}
        >
          <FormatListBulletted
            style={{
              width: 35,
              height: 35,
              marginTop: 10,
              marginRight: 20,
              color: viewOption === 'List' ? 
                     secondaryColor : 
                     'grey'
            }}
          />
        </a>
        <a
           style={anchorStyles}
           onClick={() => {
            setViewOption('Calendar');
            setOpenTuneSetting(false);
          }}
        >
       <CalendarIconSvg
          style={{
            width: 35,
            height: 35,
            marginTop: 10,
            marginRight: 20,
            fill: viewOption === 'Calendar'?
                  secondaryColor :
                  'grey'
          }}    
        />
        </a>
       
      </div>
    )
  }
  const renderTuneSetting = () => {
    // const pharmacyName = AuthService.getOwnerAccountInfo().pharmacyName;
    const allOfficeText = industryMode === IndustryModeEnum.Dentistry ?
                          'Dental Practices' :
                          'Pharmacies'
    return (
      <RBModal
        open={openTuneSetting}
        backDropEnabled={true}
        style={{ minHeight: 0, height: roleName === roleNameEnum.owner ? 300 : 200}}
        onClose={() => setOpenTuneSetting((prevOpen) => !prevOpen)}
        modalTitle={roleName === roleNameEnum.owner ?
          showAllJobs
            ? `Current display: All Locations`
            : `Current display: ${pharmacy.name}` :
            `Current filter: ${viewOption}`
        }
        children={renderTuneSettingContent()}
      />
    );
  };
  const scrollRestore = window.history.scrollRestoration;
  // console.log("scrollRestore", scrollRestore);

  const onActiveDayChanged = (activeDate: Date) => {
    setCalendarJobs(
      jobs.filter(
        (job) =>
          moment(job.startTime).format("YYYY-MM-DD") ===
          moment(activeDate).format("YYYY-MM-DD")
      )
    );
    console.log(
      "calendarJobs ",
      calendarJobs.length,
      moment(activeDate).format("YYYY-MM-DD")
    );
  };
  return (
    <>
      {renderAppBar()}
      {/* {roleName === roleNameEnum.owner && renderTuneSetting()} */}
      {renderTuneSetting()}
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {viewOption === 'List' && (
          <JobListPage jobs={jobs} roleName={roleName} />
          )}
        </>
      )}
    </>
  );
};
