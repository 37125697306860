import React, { useState, useEffect ,useContext} from "react";
import { AuthService } from "../../api/AuthService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, Colors,} from "../../theme/Theme";
import {
  useTheme,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum,paymentTypeEnum,formatPaymentType } from "../../api/constants";
import {  ColumnStyle } from "../../theme/ComponentTheme";
import { RBAppbar } from "../../components/RBAppbar";
import { RBButton } from "../../components/RBButton";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import CreditCard from "@material-ui/icons/CreditCard";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { RBAlert } from "../../components/RBAlert";
import { PharmacyService }  from "../../api/PharmacyService";


interface PadWebpageProps {
    history: any;
    location: any;
  }
  interface IState {
    content:any;
    url:any;
    isLoading: boolean;
    index:any;
    account:any;
    pharmacyDetails:any;
    isAlertShowing:boolean;
    alertInfo:any
  }

export class PadWebpage extends React.PureComponent<
    PadWebpageProps,
    IState
> {
    state: IState;
    static contextType = RBContext;
    constructor(props: PadWebpageProps) {
        super(props);
        this.state = {
            content: '',
             isLoading: false, 
             url:null, 
             index:0, 
             account:[],
             pharmacyDetails:{},
             isAlertShowing:false,
             alertInfo:{title:"",message:"", buttons:[]}
        }
        
      }

  async componentDidMount() {
    this.setState({isLoading:true})
    console.log("padprops",this.props)
    const userData = await AuthService.loadAccountInfo();
    this.setState({account:userData})
    let pharmaDetails = await AuthService.fetchPharmacy(userData.owner.defaultPharmacyId);
    this.setState({pharmacyDetails:pharmaDetails})
    //get pad stripe form url
    const pharmacy = this.props.location.state?.pharmacy ? this.props.location.state.pharmacy : pharmaDetails;
    const uri = await PharmacyService.getPadUrl(pharmacy.id);
    console.log("padurl",uri.sessionUrl)
    this.setState({url:uri.sessionUrl}); 
    this.setState({isLoading:false})
   
  }
  // handleIframeMessage = async (event: any) => {
  // };

  // handleIframeLoad = (event:any) => {
  // };

  componentDidUpdate() {
    const handleIframeMessage = (event:any) => {
    };

    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }

    

  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator />;
    }
    if(this.state.url) {
        window.location.href = this.state.url;
        return null;
      }
    return (
        <div style={{ flex: 1 }}>
        {/* {this.state.url && (
          <iframe
          title="Pad Webpage"
          src={this.state.url}
          onLoad={this.handleIframeLoad}
          style={{ width: '100%', height: '100vh', border: 'none' }}
          />
        )} */}
      </div>
    );
  }
}
