import Api from "./API";
import { AxiosResponse, AxiosError } from "axios";
import { ShiftProps } from "../interfacesProps/ShiftProps";
import { ShiftRelatedConstants } from "../util/ShiftConstants";
import moment from "moment-timezone";
import { ObjectLiteral } from "../interfacesProps/ObjectLiteralProps";
import { AuthService } from "./AuthService";
import { roleNameEnum } from "../util/Common";
import { CompleteShiftByWorkerProps } from "../interfacesProps/CompleteShiftByWorkerProps";
import { DisputeShiftByPharmacyProps } from "../interfacesProps/DisputeShiftByPharmacyProps";
import { SetApplicantStateByPharmacyProps } from "../interfacesProps/SetApplicantStateByPharmacyProps";
import {
  ShiftListForPharmacyDto,
  ShiftListForWorkerDto,
} from "../dtos/Shift.dto";
import { RequestedRatetDto } from "../dtos/RequestedRate.dto";

type ShiftListProps = { code: string; description: string; response: object };
type ShiftDetailProps = ObjectLiteral;

type ServerError = { code: string; description: string; response: object };

const getShiftApplicantsByShiftId = async (shiftId: string) => {
  try {
    const response = await Api.axios.get<ObjectLiteral, AxiosResponse<any>>(
      `shift/${shiftId}/request`
    );
    //    console.log("get shift applicants response ", response);

    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("get shift applicants in AxiosError", axiosError);
      console.log("get shift applicants in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("get shift applicant error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};

const getJobApplicantsByJobId = async (jobId: string,networkId:any) => {
  try {
    let response =await Api.axios.get<ObjectLiteral, AxiosResponse<any>>(
      `job/${jobId}/request`
    );
      
      if (response.data.data && Array.isArray(response.data.data)) {
        // Sort the array based on networkId
        response.data.data.sort((a:any, b:any) => {
          console.log("what is ", a)
          if (a.networks[0].id === networkId && b.networks[0].id !== networkId) {
            return -1;
          } else if (a.networks[0].id !== networkId && b.networks[0].id === networkId) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      console.log("full data",response.data)
      return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("get shift applicants in AxiosError", axiosError);
      console.log("get shift applicants in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("get shift applicant error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};


const getAllPotentialJobApplicants = async (id:any,networkId:any) => {
  try {
    let response = await Api.axios.get(`job/${id}/potential/v2`);
    
    if (response.data.data && Array.isArray(response.data.data)) {
      // Sort the array based on networkId
      response.data.data.sort((a:any, b:any) => {
        console.log("what is ", a)
        if (a.networks[0].id === networkId && b.networks[0].id !== networkId) {
          return -1;
        } else if (a.networks[0].id !== networkId && b.networks[0].id === networkId) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return response;
  } catch (error) {
    // Handle error
    console.error('Error fetching job applicants:', error);
    throw error;
  }
}

const cancelShiftById = async (shiftId: string) => {
  try {
    const response = await Api.axios.patch(`shift/${shiftId}`, {
      state: "cancel",
    });
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("cancelShift in AxiosError", axiosError);
      console.log("cancelShift in AxiosError response", err.response);
    }
    console.log("cancelShift error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};

const extendJob = async (id:any) => {

  try {
    const response = await Api.axios.patch(`job/${id}`, {
      state: "extend",
    });
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("cancelShift in AxiosError", axiosError);
      console.log("cancelShift in AxiosError response", err.response);
    }
    console.log("cancelShift error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }

  
};
const cancelJobById = async (jobId: string) => {
  try {
    const response = await Api.axios.patch(`job/${jobId}`, {
      state: "cancel",
    });
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("cancelShift in AxiosError", axiosError);
      console.log("cancelShift in AxiosError response", err.response);
    }
    console.log("cancelShift error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};
const withdrawRequestedShiftById = async (shiftId: string) => {
  try {
    const accountInfo = AuthService.getWorkerAccountInfo();
    const response = await Api.axios.delete(
      `shift/${shiftId}/request/${accountInfo.userId}`
    );
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("withdrawRequestedShiftById in AxiosError", axiosError);
      console.log(
        "withdrawRequestedShiftById in AxiosError response",
        err.response
      );
    }
    console.log("withdrawRequestedShiftById error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};
const withdrawRequestedJobById = async (jobId: string) => {
  try {
    const accountInfo = AuthService.getWorkerAccountInfo();
    const response = await Api.axios.delete(
      `job/${jobId}/request/${accountInfo.userId}`
    );
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("withdrawRequestedShiftById in AxiosError", axiosError);
      console.log(
        "withdrawRequestedShiftById in AxiosError response",
        err.response
      );
    }
    console.log("withdrawRequestedShiftById error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};
const getJobDetailByJobId = async (jobId: string) => {
  try {
    const response = await Api.axios.get<ShiftDetailProps, AxiosResponse<any>>(
      `job/${jobId}`
    );
    //    console.log("get shift detail response ", response);

    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("get Job detail in AxiosError", axiosError);
      console.log("get Job detail in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("get Job detail in error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
    //throw err;
  }
};


const getWorkerInvitedJobs = async (options: object) => {
  try {
    const response = await Api.axios.get<ShiftListProps, AxiosResponse<any>>(
      "job?status=pending",
      { params: options }
    );
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("getShiftList in AxiosError", axiosError);
      console.log("getShiftList in AxiosError response", err.response);
      //return axiosError.response.data;
    }

    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
    // throw err;
  }
};


const getJobList = async (options: object) => {
  try {
    console.log("getJobList API call ");
    const response = await Api.axios.get<ShiftListProps, AxiosResponse<any>>(
      "job",
      { params: options }
    );

    const role = AuthService.getRoleName();
    if (role === roleNameEnum.owner) {
      console.log(
        "getShiftList pharmacy ",
        response.data.data as ShiftListForPharmacyDto[]
      );
    } else {
      console.log(
        "getShiftList worker ",
        response.data.data as ShiftListForPharmacyDto[]
      );
    }
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("getShiftList in AxiosError", axiosError);
      console.log("getShiftList in AxiosError response", err.response);
      //return axiosError.response.data;
    }

    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
    // throw err;
  }
};
const getStatusForJobAsWorker = (shift: ShiftProps) => {
  const state = getStatusForJobAsPharmacy(shift);
  if (state === ShiftRelatedConstants.JobStateText.RateJob) {
    return ShiftRelatedConstants.JobStateText.JobCompleted;
  } else {
    return state;
  }
};
const getStatusForJobAsPharmacy = (job:any) => {
  debugger
  const { flags } = job;
  // console.log('getStatusForJobAsPharmacy', flags);
  if (flags.isCancelled) {
    return ShiftRelatedConstants.JobStateText.Cancelled;
  }
  if (!flags.isAssigned) {
    if (flags.isExpired) {
      return ShiftRelatedConstants.JobStateText.Expired;
    }
    if(job?.jobState == 2){
      return ShiftRelatedConstants.JobStateText.Invited;
    }
    return ShiftRelatedConstants.JobStateText.AwaitingHiring;
  }
  if (flags.isAssigned) {
    // console.log('is isAssigned');
    return ShiftRelatedConstants.JobStateText.ReadyForJob;
  }

  return 'getStatusForJobAsPharmacy';
};
const setApplicantStateByPharmacy = async (
  payload: SetApplicantStateByPharmacyProps
) => {
  try {
    const response = await Api.axios.patch(
      `shift/${payload.shiftId}/request/${payload.pharmacistId}`,
      {
        state: payload.state,
      }
    );
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("completeShiftByWorker in AxiosError", axiosError);
      console.log("completeShiftByWorker in AxiosError response", err.response);
    }
    console.log("completeShiftByWorker error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};

const setJobApplicantStateByPharmacy = async (
  payload: any
) => {
  try {
    const response = await Api.axios.patch(
      `job/${payload.jobId}/request/${payload.pharmacistId}`,
      {
        state: payload.state,
      }
    );
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("completeShiftByWorker in AxiosError", axiosError);
      console.log("completeShiftByWorker in AxiosError response", err.response);
    }
    console.log("completeShiftByWorker error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};


const searchShiftsByDateRange = async (startTime: string, endTime: string, networkId:string) => {
  try {
    const response = await Api.axios.post(
      `shift/search/v2?startTime=${startTime}&endTime=${endTime}&networkId=${networkId}&appVersion=3.0.0`
    );
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("searchShiftsByDateRange in AxiosError", axiosError);
      console.log(
        "searchShiftsByDateRange in AxiosError response",
        err.response
      );
    }
    console.log("searchShiftsByDateRange error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};
const searchShiftsBylocation = async (startTime: string, endTime: string, networkId:string,locationId:string) => {
  try {
    const response = await Api.axios.post(
      `shift/search/v2?startTime=${startTime}&endTime=${endTime}&networkId=${networkId}&appVersion=3.0.0&locationId=${locationId}`
    );
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("searchShiftsByDateRange in AxiosError", axiosError);
      console.log(
        "searchShiftsByDateRange in AxiosError response",
        err.response
      );
    }
    console.log("searchShiftsByDateRange error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};

const searchJobs = ( startDate:any, showOtherProvince:any, networkId:any ) => Api.axios.post(`job/search/v2?startDate=${startDate}&showOtherProvince=${showOtherProvince}&networkId=${networkId}&appVersion=3.0.0`);

const searchShiftDetailById = async (shiftId: string) => {
  try {
    const response = await Api.axios.get(`shift/${shiftId}/potential`);
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("searchShiftDetailById in AxiosError", axiosError);
      console.log("searchShiftDetailById in AxiosError response", err.response);
    }
    console.log("searchShiftDetailById error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};

const applyShiftById = async (shiftId: string) => {
  try {
    const response = await Api.axios.post(`shift/${shiftId}/request`, null, {
      headers: {
        "Content-Type": "multipart/form-data; charset=utf-8;",
      },
    });
    // console.log("applyShiftById response ", response);
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("applyShiftById in AxiosError", axiosError);
      console.log("applyShiftById in AxiosError response", err.response);
    }
    console.log("applyShiftById error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};


const requestJob = async (id:any) => {
  const response = await Api.axios.post(`job/${id}/request`);
  return response;
};


const requestShiftWithSignatureAndRate = async (id:string, payload: RequestedRatetDto) => {
 

  try {
    console.log('requestedWage',payload)
    const response = await Api.axios.post(`shift/${id}/request`, payload);
    // console.log("applyShiftById response ", response);
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("applyShiftById in AxiosError", axiosError);
      console.log("applyShiftById in AxiosError response", err.response);
    }
    console.log("applyShiftById error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};


// This is used for Apply shift, so use the original start/end time
// Currently return earning before tax
const getTotalEarningForApplyShift = (shift: ShiftProps,user:any) => {
  const kmAllowance = shift.kmAllowance ? shift.kmAllowance  : 0;
  const distance = Math.round((shift.distance * 2) / 1000 ?? 0);
  const accommodation = shift.accomodationAllowance  ?? 0;
  const wage = shift.actualCommission? shift.wage : shift.wage * (1 - user.pharmacist.commission / 100);;
  const salesTaxRate = shift.salesTaxRate ?? 13;
  const minutes = moment
    .duration(moment(shift.endTime).diff(moment(shift.startTime)))
    .asMinutes();
    const totalEarningBeforeTax =
    Math.floor(kmAllowance * distance + accommodation + (wage * minutes) / 60) /
    100;
  // const totalEarning = user.pharmacist.taxNumber
  //   ? Math.floor(totalEarningBeforeTax * (1 + salesTaxRate / 100) * 100) / 100
  //   : totalEarningBeforeTax;
  // const totalEarning = user.pharmacist.taxNumber
  //   ? totalEarningBeforeTax * (1 + salesTaxRate / 100)
  //   : totalEarningBeforeTax;
  return totalEarningBeforeTax.toFixed(2);
};

const completeShiftByWorker = async (payload: any) => {
  try {
    const response = await Api.axios.patch(`shift/${payload.shiftId}`, {
      ...payload,
      state: "complete",
    });
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("completeShiftByWorker in AxiosError", axiosError);
      console.log("completeShiftByWorker in AxiosError response", err.response);
    }
    console.log("completeShiftByWorker error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};

const acknowledgeShiftByPharmacy = async (shiftId: string) => {
  try {
    const response = await Api.axios.patch(`shift/${shiftId}`, {
      state: "acknowledge",
    });
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("completeShiftByPharmacy in AxiosError", axiosError);
      console.log(
        "completeShiftByPharmacy in AxiosError response",
        err.response
      );
    }
    console.log("completeShiftByPharmacy error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};

const disputeShiftByPharmacy = async (payload: any) => {
  try {
    const response = await Api.axios.patch(`shift/${payload.shiftId}`, {
      ...payload,
      state: "dispute",
    });
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("disputeShiftByPharmacy in AxiosError", axiosError);
      console.log(
        "disputeShiftByPharmacy in AxiosError response",
        err.response
      );
    }
    console.log("disputeShiftByPharmacy error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};

const getNetworkAdminInfo = async (id:any) => {
  //Api.get(`network/${id}/fullDetail`)
  try {
    const response = await Api.axios.get<ShiftDetailProps, AxiosResponse<any>>(
      `networkadmin/${id}/fetchAdminDetail`
    );
    //    console.log("get shift detail response ", response);

    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("get shift detail in AxiosError", axiosError);
      console.log("get shift detail in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("get shift detail in error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
    //throw err;
  }
}
const  getNetworkDetails = async (id:any) => {
  //Api.get(`network/${id}/fullDetail`)
  try {
    const response = await Api.axios.get<ShiftDetailProps, AxiosResponse<any>>(
      `network/${id}/fullDetail`
    );
    //    console.log("get shift detail response ", response);

    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("get shift detail in AxiosError", axiosError);
      console.log("get shift detail in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("get shift detail in error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
    //throw err;
  }
}


const getShiftDetailByShiftId = async (shiftId: string) => {
  try {
    const response = await Api.axios.get<ShiftDetailProps, AxiosResponse<any>>(
      `shift/${shiftId}`
    );
    //    console.log("get shift detail response ", response);

    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("get shift detail in AxiosError", axiosError);
      console.log("get shift detail in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("get shift detail in error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
    //throw err;
  }
};

const getApplicantsByPharmacyId = async (pharmacyId: string) => {
  try {
    const response = await Api.axios.get<ShiftDetailProps, AxiosResponse<any>>(
      `pharmacy/${pharmacyId}/shift-applicants`
    );
    //    console.log("get shift detail response ", response);

    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("get shift detail in AxiosError", axiosError);
      console.log("get shift detail in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("get shift detail in error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
    //throw err;
  }
};

const getJobtDetailByJobId = async (jobId: string) => {
  try {
    const response = await Api.axios.get<ShiftDetailProps, AxiosResponse<any>>(
      `job/${jobId}`
    );
    //    console.log("get shift detail response ", response);

    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("get shift detail in AxiosError", axiosError);
      console.log("get shift detail in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("get shift detail in error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
    //throw err;
  }
};

const getShiftList = async (options: object) => {
  try {
    console.log("getShiftList API call ");
    const response = await Api.axios.get<ShiftListProps, AxiosResponse<any>>(
      "shift",
      { params: options }
    );

    const role = AuthService.getRoleName();
    if (role === roleNameEnum.owner) {
      console.log(
        "getShiftList pharmacy ",
        response.data.data as ShiftListForPharmacyDto[]
      );
    } else {
      console.log(
        "getShiftList worker ",
        response.data.data as ShiftListForPharmacyDto[]
      );
    }
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("getShiftList in AxiosError", axiosError);
      console.log("getShiftList in AxiosError response", err.response);
      //return axiosError.response.data;
    }

    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
    // throw err;
  }
};

const getStatusForShiftAsWorker = (shift: ShiftProps, rolename:string) => {
  const state = getStatusForShiftAsPharmacy(shift,rolename);
  if (state === ShiftRelatedConstants.ShiftStateText.RateShift) {
    return ShiftRelatedConstants.ShiftStateText.ShiftCompleted;
  } else {
    return state;
  }
};
const getStatusForShiftAsPharmacy = (shift: ShiftProps, roleName:string) => {
  const { flags } = shift;
  const now = moment().utc();
  const startTime = moment(shift.startTime).utc();
  const endTime = moment(shift.endTime).utc();
  if (flags.isCancelled) {
    return ShiftRelatedConstants.ShiftStateText.Cancelled;
  }

  if (!flags.isAssigned) {
    return ShiftRelatedConstants.ShiftStateText.AwaitingHiring;
  }

  if (flags.isAssigned && !flags.isCompleted) {
    if (flags.isPast) {
      return ShiftRelatedConstants.ShiftStateText.AwaitingWorkerConfirmation;
    }
    if (now.isAfter(startTime) && now.isBefore(endTime)) {
        return ShiftRelatedConstants.ShiftStateText.ActiveShift;
    }
    if(shift.cancellationRequested)
    {
      return ShiftRelatedConstants.ShiftStateText.ReadyForShiftReqCancel;
    }
    else{
      return ShiftRelatedConstants.ShiftStateText.ReadyForShift;
    }
  }

  if (flags.isAssigned && flags.isCompleted) {
    if (!flags.isCompletionAcknowledged) {
      // flags.isCompletionAcknowledged === true when shift dispute is resolved
      if (flags.isDisputed) {
        return ShiftRelatedConstants.ShiftStateText.InDispute;
      }
      if ((shift.pharmacistType.includes('entist') && shift?.workerAutoAcknowledged ===  true)) {
        if(roleName !== "owner")
        {
          if(!shift?.workerFeedbackGiven)
            return ShiftRelatedConstants.ShiftStateText.FeedbackShift;
        }
    }
      return ShiftRelatedConstants.ShiftStateText.AwaitingHoursConfirmation;
    }
    // if (flags.isRated || shift.pharmacistType.includes('entist')) {
    //   return ShiftRelatedConstants.ShiftStateText.ShiftCompleted;
    // }
    if (flags.isRated || (shift.pharmacistType.includes('entist') )) {
      if(roleName === "owner" && shift.locationAutoAcknowledged === null)
      {
       
        return ShiftRelatedConstants.ShiftStateText.ShiftCompleted;
      }
      else{
        if(( shift.workerAutoAcknowledged ===  null))
        {
          return ShiftRelatedConstants.ShiftStateText.ShiftCompleted;
        }
      }
      
    }
    //dental worker/owner feedback flag check
    if (shift.pharmacistType.includes('entist')) {

      if(roleName === "owner" && shift.locationAutoAcknowledged === true)
      {
        if(!shift?.locationFeedbackGiven)
          return ShiftRelatedConstants.ShiftStateText.FeedbackShift;
        else
          return ShiftRelatedConstants.ShiftStateText.ShiftCompleted;

      }
      else{
        if((shift.workerAutoAcknowledged ===  true))
        {
          console.log("shiftState")
          if(!shift?.workerFeedbackGiven)
            return ShiftRelatedConstants.ShiftStateText.FeedbackShift;
          else
            return ShiftRelatedConstants.ShiftStateText.ShiftCompleted;
        }
      }
    }

    return ShiftRelatedConstants.ShiftStateText.RateShift;
  }

  if (
    flags.isAssigned &&
    flags.isPast &&
    flags.isCompleted &&
    !flags.isCompletionAcknowledged
  ) {
    return 'Awaiting Location Confirmation';
  }

  if (flags.isPast && !flags.isCompleted) {
    return ShiftRelatedConstants.ShiftStateText.ShiftExpired;
  }

  return ShiftRelatedConstants.ShiftStateText.Unknown;
};

const getTimePeriodLength = (start: string, end: string) => {
  const startTime = moment(start);
  const endTime = moment(end);

  const days = moment.duration(endTime.diff(startTime)).days();
  let hours = moment.duration(endTime.diff(startTime)).hours();
  const minutes = moment.duration(endTime.diff(startTime)).minutes();
  if (days !== 0) {
    hours = hours + days * 24;
  }
  const hourText = hours > 1 ? " hrs" : " hr";
  const minuteText = minutes > 1 ? " mins" : " min";
  if (hours === 0) {
    return minutes + minuteText;
  } else if (minutes === 0) {
    return hours + hourText;
  } else {
    return hours + hourText + " " + minutes + minuteText;
  }
};

// This is for original start/end time
const getShiftLengthDisplayForOriginal = (shift: ShiftProps) => {
  return getTimePeriodLength(shift.startTime, shift.endTime);
};

const getShiftActualLengthDisplay = (shift: ShiftProps) => {
  return getTimePeriodLength(shift.actualStartTime, shift.actualEndTime);
};

const getShiftOfficialLengthDisplay = (shift: ShiftProps) => {
  return getTimePeriodLength(shift.officialStartTime, shift.officialEndTime);
};
const getShiftUnpaidBreakLengthDisplay = (shift: ShiftProps) => {
  let endTime = shift.officialEndTime;

  if(shift.disputeResolution === 'pharmacy'){
    endTime = moment(endTime).subtract(shift.disputeUnpaidBreakMinutes,'minutes')
  }
  else{
      endTime = moment(endTime).subtract(shift.unpaidBreakMinutes,'minutes')
  }
  return getTimePeriodLength(shift.officialStartTime, endTime);
};

const getWage = (shift: ShiftProps,user?:any) => {
  const { wage } = shift;
  let commission = 0;

  if (typeof user?.pharmacist !== 'undefined' && 
    shift.pharmacy.networkPaid
  ){ 

    if (
      typeof shift.paidCommission !== "undefined" &&
      shift.paidCommission !== null
    ) {
      commission = shift.paidCommission;
    } else {
      commission = user.pharmacist.commission;
    }
  }


  const withheldWage = wage * (commission / 100);
  if (AuthService.getRoleName() === roleNameEnum.owner) {
    return Math.ceil(wage - withheldWage);
  } else if (AuthService.getRoleName() === roleNameEnum.pharmacist) {
    return Math.floor(wage - withheldWage);
  }

  return Math.round(wage - withheldWage);
};

const getOwnerWage = (shift:any, user:any) => {
  const { wage } = shift;
  let commission = 0;
  // dentists do not get wages withheld 
  if (typeof user.pharmacist !== 'undefined' && 
    shift.pharmacy.networkPaid
  ){ 
    // use the commission set for the shift, or the worker
    if (
      typeof shift.paidCommission !== 'undefined' &&
      shift.paidCommission !== null
    ) {
      commission = shift.paidCommission;
    } else 
    {
      commission = user.pharmacist.commission;
    }
  }
  else{
    if(shift.pharmacy.networkPaid)
    {
      commission = shift.pharmacy.commissionPercentage;
    }
  }
  const withheldWage = wage * (commission / 100);

  return user.roleName === 'owner'
    ? Math.ceil(wage - withheldWage)
    : Math.floor(wage - withheldWage);
};


const getWorkerFee = (shift:any, user:any) => { 
  
  console.log('shift',shift) 
  const { wage } = shift;
  let rbCommission = 0;
  let withheldWage = 0;
  let commission = 0;
  
  if ( shift.pharmacy.networkPaid){  
    rbCommission = shift.pharmacy.commissionPercentage;

    if(user.pharmacist && typeof user.pharmacist !== 'undefined')
    {
      if (
        typeof shift.paidCommission !== 'undefined' &&
        shift.paidCommission !== null
      ) {
        commission = shift.paidCommission;
      } else 
      {
        commission = user.pharmacist.commission;
        
      }
    }
  }
  if(shift?.requestedWage)
  {
     withheldWage = shift.requestedWage / (1+ (rbCommission/100));
     return Math.ceil(withheldWage);
  }
  else{
    if(shift.pharmacy.networkPaid)
    {
      withheldWage = wage * (rbCommission / 100);
    }
    else{
      withheldWage = wage * (commission / 100);
    }
     return Math.ceil(withheldWage);
  }
};

const getCommission = (shift:any, user:any) => {
  const { wage } = shift;
  let rbCommission = 0;
  
  let withheldWage = 0;
  
  if(shift.pharmacy.networkPaid)
  { 
        withheldWage = wage * (shift.pharmacy.commissionPercentage/100);
        rbCommission = withheldWage;
  }
  if(shift?.requestedWage)
  {
     withheldWage = shift.requestedWage / (1+ (shift.pharmacy.commissionPercentage/100));
     return user.roleName === 'owner'
    ? Math.ceil(shift.requestedWage - withheldWage)
    : Math.floor(shift.requestedWage - withheldWage);
  }

  return Math.floor(rbCommission);
};


const getShiftLengthInHours = (shift: ShiftProps) => {
  const startTime = moment(shift.startTime);
  const endTime = moment(shift.endTime);

  return moment.duration(endTime.diff(startTime)).asHours();
};

const getShiftHourInMinutes = (shift:any) =>{
  let minutesWorked = 0;
  
  if(shift.shiftTimes)
  {
    if(shift.shiftTimes.length > 1){
      shift.shiftTimes.forEach((element:any) => {
        const startTime = moment.utc(element.startTime).local();
        const endTime = moment.utc(element.endTime).local();
        minutesWorked = minutesWorked + endTime.diff(startTime, 'minutes');
      });
      console.log('minutesWorked',minutesWorked)
      return minutesWorked;
    }
    else
    {
      
      const startTime = moment.utc(shift.shiftTimes[0].startTime).local();
      const endTime = moment.utc(shift.shiftTimes[0].endTime).local();
      minutesWorked = endTime.diff(startTime, 'minutes');
      return minutesWorked;
    }
  }
  else{
    minutesWorked = moment(shift.officialEndTime).diff(
      moment(shift.officialStartTime),
      'minutes',
    );
    if(shift.actualCommission)
    {
      if(shift.disputeResolution === 'pharmacy'){
        minutesWorked = minutesWorked - shift.disputeUnpaidBreakMinutes
      }
      else{
          minutesWorked = minutesWorked - shift.unpaidBreakMinutes
      }
    }
    return minutesWorked;
  }
  
  

}
const getPlacementFee = (shift:any) => {
  
  let placementFee = 0;
  let minutesWorked = getShiftHourInMinutes(shift);
  
  let wage = shift.workerWage ? shift.workerWage : shift.wage;
  if(shift.requestedWage)
  {
    wage = shift.requestedWage;
  }
  if(shift.actualCommission)
  {
    
    if(shift.commissionType === 'percentage'){
      placementFee = ((wage/100 * minutesWorked)/60) * (shift.actualCommission/100);
      if(placementFee < shift.commissionMinimum)
      {
        placementFee = shift.commissionMinimum;
      }
      else if(placementFee > shift.commissionMaximum)
      {
        placementFee = shift.commissionMaximum;
      }
  
    }
    else{
     
      placementFee = shift.actualCommission;
    }
  }
  else
  {
    if(shift.pharmacy.commissionType === 'percentage'){
      placementFee = ((wage/100 * minutesWorked)/60) * (shift.pharmacy.commissionPercentage/100);
      if(placementFee < shift.pharmacy.commissionMinimum)
      {
        placementFee = shift.pharmacy.commissionMinimum;
      }
      else if(placementFee > shift.pharmacy.commissionMaximum)
      {
        placementFee = shift.pharmacy.commissionMaximum;
      }
  
    }
    else{
      placementFee = shift.pharmacy.commission;
    }
  }

  return placementFee
 

}

const getCancellationFee = (shift: ShiftProps) => {
  const now = moment();
  const startTime = moment.utc(shift.startTime).local();
  const endTime = moment.utc(shift.endTime).local();

  // SAME WITH WHAT HAPPEN IN API(shift.service) getCancellationFee
  // * - As a pharmacist
  //  *   - If cancelled within 72 hours of the shift a $200 fee is manually
  //  *     applied.
  //  *   - If cancelled between 3- 14 days, $100 is manually applied
  //  * - As a pharmacy owner
  //  *   - If cancelled >72 hours but <14 days then a $100 fee is automatically
  //  *     applied.
  //  *   - If cancelled <72 hours then the full rate is automatically applied.
  //  *

  if (now.isBefore(startTime) && shift.flags.isAssigned) {
    const hoursUntilStart = startTime.diff(now, "hours");
    // pharmacist , within 3 days , $200
    if (
      hoursUntilStart < 72 &&
      AuthService.getRoleName() === roleNameEnum.pharmacist
    ) {
      return 200 * 100;
    }
    // pharmacist , between 3 - 14 days , $100
    if (
      hoursUntilStart < 336 &&
      AuthService.getRoleName() === roleNameEnum.pharmacist
    ) {
      return 100 * 100;
    }
    // pharmacy , within 3 days, full shift charge
    else if (
      hoursUntilStart < 72 &&
      AuthService.getRoleName() === roleNameEnum.owner
    ) {
      const minutesWorked = endTime.diff(startTime, "minutes");
      return shift.wage * (minutesWorked / 60);
    } else if (
      hoursUntilStart >= 72 &&
      hoursUntilStart < 336 &&
      AuthService.getRoleName() === roleNameEnum.owner
    ) {
      return 100 * 100;
    }
  }

  return undefined;
};

const updateShiftRating = async (shiftId: string, payload: any) => {
  try {
    const response = await Api.axios.patch(
      `shift/${shiftId}/updateShiftRating/`,
      payload
    );
    return response;
  } catch (err) {
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};

const getUnrequiredSoftwareName = (softwareName:string) => {
  return 'Not Required' + ' (' + softwareName + ')';
};

const getPayoutsForShifts = (shifts: ShiftProps[]) => {
  return shifts.reduce(
    (acc, shift) => {
      let { totalPaid, monthlyPaid } = acc;
      const shiftTotal = (shift.paidIn + shift.paidTax) / 100;
      totalPaid += shiftTotal;
      if (
        moment(shift.endTime).isAfter(
          moment().tz(shift.shiftTimeZone).format("YYYY-MM-01")
        )
      ) {
        monthlyPaid += shiftTotal;
      }

      return { totalPaid, monthlyPaid };
    },
    { totalPaid: 0, monthlyPaid: 0 }
  );
};
// const getShifts = parameters => Api.get('shift', parameters);
const getShifts = async (state: any,networkId:string ) => {
  try {
    const response = await Api.axios.get(`shift?state=${state}&networkId=${networkId}`);
    //    console.log("get shift detail response ", response);

    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("get shift detail in AxiosError", axiosError);
      console.log("get shift detail in AxiosError response", err.response);
      //return axiosError.response.data;
    }
    console.log("get shift detail in error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
    //throw err;
  }
};
const purchaseInvite = async (totalRequests:any,jobId:any) => {
  try {
    const response = await Api.axios.post(`job/invite/purchase?jobId=${jobId}&totalRequests=${totalRequests}`);
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("applyShiftById in AxiosError", axiosError);
      console.log("applyShiftById in AxiosError response", err.response);
    }
    console.log("applyShiftById error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};

const inviteWorker = async (userId:any,jobId:any) => {
  try {
    const response = await Api.axios.post(`job/invite-worker?jobId=${jobId}&userId=${userId}`);
    return response.data;
  } catch (err) {
    if (err && err.response) {
      const axiosError = err as AxiosError<ServerError>;
      console.log("applyShiftById in AxiosError", axiosError);
      console.log("applyShiftById in AxiosError response", err.response);
    }
    console.log("applyShiftById error", err);
    const error401Result = await AuthService.handle401Error(err);
    return error401Result?.data;
  }
};
export const ShiftService = {
  getShiftList,
  getShiftDetailByShiftId,
  getNetworkDetails,
  getNetworkAdminInfo,
  getShiftApplicantsByShiftId,
  cancelShiftById,
  withdrawRequestedShiftById,
  completeShiftByWorker,
  acknowledgeShiftByPharmacy,
  disputeShiftByPharmacy,
  setApplicantStateByPharmacy,
  searchShiftsByDateRange,
  searchShiftDetailById,
  getTotalEarningForApplyShift,
  applyShiftById,
  requestShiftWithSignatureAndRate,
  updateShiftRating,
  getUnrequiredSoftwareName,
  getPayoutsForShifts,
  getStatusForShiftAsWorker,
  getStatusForShiftAsPharmacy,
  getShiftLengthInHours,
  getShiftLengthDisplayForOriginal,
  getShiftActualLengthDisplay,
  getWage,
  getCancellationFee,
  getPlacementFee,
  getCommission,
  getOwnerWage,
  getWorkerFee,
  getShiftOfficialLengthDisplay,
  getShifts,
  getJobtDetailByJobId,
  searchJobs,
  requestJob,
  getAllPotentialJobApplicants,
  getShiftUnpaidBreakLengthDisplay,
  getJobList,
  getJobDetailByJobId,
  withdrawRequestedJobById,
  cancelJobById,
  getStatusForJobAsWorker,
  getStatusForJobAsPharmacy,
  getJobApplicantsByJobId,
  setJobApplicantStateByPharmacy,
  extendJob,
  getApplicantsByPharmacyId,
  searchShiftsBylocation,
  purchaseInvite,
  inviteWorker,
  getWorkerInvitedJobs
};
