import React,{useContext} from "react";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, Colors, FontSize } from "../../theme/Theme";
import { RBModal } from "../../components/RBModal";
import {RBShiftAgreementModal } from "../../components/RBShiftAgreementModal";
import {
  Select,
  MenuItem,
  Typography,
  Checkbox,
  ListItemText,
  FormControlLabel,
  FormControl,
  IconButton,
  Link,
  TextField,
  FormHelperText
} from "@material-ui/core";
import { formatWorkerType } from "../../util/FormattingService";
import {
  EmptyString,
  Claims,
  ErrorMessages,
  NotAvailableString,
  IndustryModeEnum,
} from "../../util/Common";
import { ColumnStyle, skillTextStyle } from "../../theme/ComponentTheme";
import { LabelText } from "../../util/TextConstant";
import {
  RBMoneyAmountTextField,
  RBTextField,
} from "../../components/RBTextField";
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { RBButton, RBButtonType } from "../../components/RBButton";
import {
  RenderTextWithTitle,
  renderTextWithTitleForSkills,
  renderTextWithTitleAndChildComponent,
  titleHeaderTextStyle,
  detailTextStyle,
} from "../../components/TextWithHeader";
import {
  detailKmAllowanceIcon,
  detailAccomodationAllowanceIcon,
  techOnSiteIcon,
  assistantOnSiteIcon,
  allowofferIcon
} from "../../util/Icons";
import { RBDateTimePicker } from "../../components/RBDateTimePicker";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  workerTypeEnum,
  HelperPageTitleEnum,
  SkillType,
} from "../../util/Enumeration";
import moment from "moment-timezone";
import { Add, Remove } from "@material-ui/icons";
import { RBExternalHelpLink } from "../../components/RBExternalHelpLink";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { AuthService } from "../../api/AuthService";
import { PharmacyService } from "../../api/PharmacyService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { SkillResponseDto, SkillPayloadDto } from "../../dtos/Skill.dto";
import { ShiftRelatedConstants, defaultWorkDays,defaultEndTimeNew,defaultStartTimeNew } from "../../util/ShiftConstants";
import { CreateShiftDto, ShiftTimeDto } from "../../dtos/CreateShift.dto";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import { RBContext } from '../../components/RBContext';
import { ShiftService} from '../../api/ShiftService'


import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import RBPlacementFeeModal from "../../components/RBPlacementFeeModal";
import PDFViewer from "../../components/PdfViewer";
import SignaturePad  from "../../components/SignaturePad"


interface CreateShiftPageProps {
  history: any;
  location: any;
}
enum CreateShiftState {
  Create,
  Confirm,
}

interface multipleShiftsProps {
  id: number;
  startTime: moment.Moment;
  endTime: moment.Moment;
  offsets:{startTime:any,endTime:any};
  deviceStartTime: moment.Moment;
  deviceEndTime: moment.Moment;
  startTimeValidation:any;
  endTimeValidation:any;
}
interface IState {
  pharmacyName: string;
  pharmacyId: string;
  pharmacySoftware?: SkillResponseDto;
  softwareName: string;
  showModal: boolean;
  workerType: string;
  wage: string;
  wageInvalidErrorText: string;
  kmAllowance: string;
  accomodationAllowance: string;
  selectedSpecializations: string[];
  specializations: SkillResponseDto[];
  technicianOnSite: boolean;
  assistantOnSite: boolean;
  softwareRequired: boolean;
  isDiscardAlertShowing: boolean;
  isCreateShiftSubmitInfo: boolean;
  discardAlertSInfo: AlertInfoProps;
  alertInfo:AlertInfoProps;
  createShiftSubmitInfo: AlertInfoProps;
  currentCreateShiftState: CreateShiftState;
  comment: string;
  multipleShifts: multipleShiftsProps[];
  agreeTOS: boolean;
  AllSpecializationSkills: SkillResponseDto[];
  isPageLoading: boolean;
  isSubmitting: boolean;
  rxCount: string;
  submitErrorMessage?: ErrorMessageProps;
  pharmacyTimeZone: string;
  minimumWage: ObjectLiteral;
  allowOfferChangeOption:boolean;
  allowOffer:boolean;
  isAllowanceEnabled:boolean;
  signature?: string;
  showContract:boolean;
  networkId:string;
  commissionType:string;
  rbCommission:any;
  pharmacy:any;
  showTermOfService:boolean;
  workerTypesAndRates:ObjectLiteral;
  workerTypes:ObjectLiteral;
  isAlertShowing:boolean;
  resumeModal:boolean;
  newFile:any,
  ownerData:ObjectLiteral,
  workertypeInvalidErrorText: string;
}

export class CreateShiftPage extends React.PureComponent<
  CreateShiftPageProps,
  IState
> {

  state: IState;
  static contextType = RBContext;

  constructor(props: CreateShiftPageProps) {
    super(props);
    this.state = {
      pharmacyName: EmptyString,
      pharmacyId: EmptyString,
      softwareName: EmptyString,
      rxCount: EmptyString,
      showModal: true,
      wage: "0",
      wageInvalidErrorText: EmptyString,
      kmAllowance: "0",
      accomodationAllowance: "0",
      workerType: EmptyString,
      selectedSpecializations: [],
      specializations: [],
      AllSpecializationSkills: [],
      softwareRequired: false,
      technicianOnSite: false,
      assistantOnSite: false,
      isDiscardAlertShowing: false,
      isCreateShiftSubmitInfo:false,
      discardAlertSInfo: {},
      alertInfo:{},
      createShiftSubmitInfo:{},
      comment: EmptyString,
      pharmacyTimeZone: "UTC",
      currentCreateShiftState: CreateShiftState.Create,
      multipleShifts: [],
      agreeTOS: false,
      isPageLoading: true,
      isSubmitting: false,
      minimumWage: {},
      allowOfferChangeOption:false,
      allowOffer:false,
      isAllowanceEnabled:true,
      signature:undefined,
      showContract:false,
      networkId:'',
      commissionType:'',
      rbCommission:null,
      pharmacy:{},
      showTermOfService:false,
      workerTypesAndRates:[],
      workerTypes:[],
      isAlertShowing:false,
      resumeModal:false,
      newFile:null,
      ownerData:{},
      workertypeInvalidErrorText: EmptyString
    };
  }
  async componentDidMount() {
    await this.fetchCreateShiftStatus();
    await this.fetchAndSetData();
  }

  

  fetchCreateShiftStatus = async () => {
    this.setState({ showModal: false })
    const account = await AuthService.loadAccountInfo();
    const pharmacy = account.pharmacies.filter((pharmacy: any) =>
      pharmacy.id === account.owner.defaultPharmacyId
    )[0];
    if (pharmacy.approval.status !== 'approved') {
      this.setState({ isAlertShowing: true });
      this.setState({alertInfo:{
        title: "This pharmacy is under review",
        message: `You can post shifts under this pharmacy once it gets approved.`,
        buttons: [

          <RBButton
            key={"confirm"}
            onClick={async () => {
              this.setState({ isAlertShowing: false });
              //setAlertShowing(false);
              this.props.history.push('/dashboard');
            }}
            buttonBackgroundColor={this.context.primaryColor}
            color="primary"
            text="Ok"
          />,
        ],
      }});

      
      
      return;
    }


    if (pharmacy.paymentType === null && account.networks[0].paymentConfig.paymentEnabled) {
      this.setState({ isAlertShowing: true });
      this.setState({alertInfo:{
        title: "Payment Info Required",
        message: `You must set up your payment information before you can post a shift. Would you like to do this now?`,
        buttons: [

          <RBButton
            key={"confirm"}
            onClick={async () => {
              this.setState({ isAlertShowing: false });
              //setAlertShowing(false);
              this.props.history.push('/paymentOption/',{
                tabType: 'Shift'
              });
            }}
            buttonBackgroundColor={this.context.primaryColor}
            color="primary"
            text="Yes"
          />,
          <RBButton
            key={"confirm"}
            onClick={async () => {
              this.setState({ isAlertShowing: false });
              //setAlertShowing(false);
              this.props.history.push('/dashboard');
            }}
            buttonBackgroundColor={this.context.primaryColor}
            color="primary"
            text="No"
          />,
        ],
      }});

      
      return;
    }


    if (pharmacy.commissionType === 'percentage') {
      this.setState({ isAlertShowing: true });
      this.setState({alertInfo:{
        title: "Note",
        message: `Posting shifts is free of charge. However, if you accept an applicant for your shift(s), a placement fee of ${pharmacy.commissionPercentage}% per hour will be charged once the shift is completed. The placement fee is charged on a per-shift basis, with a maximum of $${pharmacy.commissionMaximum} and a minimum of $${pharmacy.commissionMinimum}.`,
        buttons: [

          <RBButton
            key={"confirm"}
            onClick={async () => {
              this.setState({ isAlertShowing: false });
              this.setState({ showModal: true })
              //this.props.history.push("/shift/create")
            }}
            buttonBackgroundColor={this.context.primaryColor}
            color="primary"
            text="Ok"
          />,
        ],
      }});
    }
    else {
      this.setState({ isAlertShowing: true });
      this.setState({
        alertInfo: {
          title: "Note",
          message: `Posting shifts is free of charge. However, if you accept an applicant for your shift(s), a placement fee of $${pharmacy.commission}/shift will be charged when the shift is completed. The placement fee is charged on a per shift basis.`,
          buttons: [

            <RBButton
              key={"confirm"}
              onClick={async () => {
                this.setState({ isAlertShowing: false });
                this.setState({ showModal: true })
              }}
              buttonBackgroundColor={this.context.primaryColor}
              color="primary"
              text="Ok"
            />,
          ],
        }
      });
      //this.setState({ isPageLoading: false })

      //this.setState({ showModal: true })
    }
  }
  fetchAndSetData = async () => {
    this.setState({ isPageLoading: true });
    const userData = await AuthService.loadAccountInfo();
    const fetchSkillsData = await PharmacyService.fetchSkills();
    const workerTypesAndRates = await AuthService.getRBShiftWorkerTypeAndRate();
    const workerTypes = await AuthService.getAllWorkers('Pharmaceutical');
    const pharmacy = userData.owner.defaultPharmacyId
      ? userData.pharmacies.filter(
          (pharmacy: any) => pharmacy.id === userData.owner.defaultPharmacyId
        )[0]
      : userData.pharmacies[0];

    const nextShifts = await this.getNextWorkDay(pharmacy);

    this.setState(
      {
        pharmacyId: pharmacy.id,
        pharmacySoftware:
          pharmacy.skills.filter((skill: any) => skill.type === "software")
            .length > 0
            ? pharmacy.skills.filter(
                (skill: any) => skill.type === "software"
              )[0]
            : null,
        pharmacyName: pharmacy.name,
        rxCount: pharmacy.rxCount,
        softwareName:
          pharmacy.skills.filter((skill: any) => skill.type === "software")
            .length > 0
            ? pharmacy.skills.filter(
                (skill: any) => skill.type === "software"
              )[0].name
            : "None Specified",
        technicianOnSite: pharmacy.technicianOnSite,
        assistantOnSite: pharmacy.assistantOnSite,
        AllSpecializationSkills: fetchSkillsData.data.filter(
          (skill: ObjectLiteral) => skill.type === SkillType.specialization
        ),
        multipleShifts: [nextShifts],
        pharmacyTimeZone: pharmacy.address.timezone,
        allowOfferChangeOption:pharmacy.allowOfferChangeOption,
        allowOffer:pharmacy.allowOffer,
        isAllowanceEnabled:
          pharmacy.defaultShiftAccomodationAllowance > 0 ||
          pharmacy.defaultShiftKmAllowance > 0,
        signature:userData.owner.signature,
        networkId:userData.networks[0].id,
        commissionType:pharmacy.commissionType,
        pharmacy:pharmacy,
        workerTypesAndRates,
        workerTypes,
        ownerData:userData.owner

      },
      () => this.setState({ isPageLoading: false })
    );
    
  };


  getNextWorkDay = async (data:any) => {
   // debugger
    const { multipleShifts } = this.state;
    //check current day is a working day and also check the time is greater than or less than 9 am, based on that set initial date and times
    let currentDate = moment().tz(data.address.timezone); // Current date and time
    //currentDate = moment('2023-11-13T17:01:00-0500');
    const currentDay = currentDate.format('dd'); // Current day (e.g., "Mon")
    let currentTime = currentDate.format('HH:mm');
    const isWorkday = data.defaultWorkWeek ? data.defaultWorkWeek.includes(currentDay) :defaultWorkDays.includes(currentDay);

    //default start and end time at location level
    const defaultStartTime = moment(data.defaultStartTime ? data.defaultStartTime :defaultStartTimeNew, 'h:mm A');
    const defaultEndTime = moment(data.defaultEndTime? data.defaultEndTime: defaultEndTimeNew, 'h:mm A');
    // Format the time as "09:00"
    const formattedDefaultStartTime = defaultStartTime.format('HH:mm');
    // Extract the hour and minutes separately
    const defaultStarthour = defaultStartTime.hour(); // Get the hour component
    const defaultStartminutes = defaultStartTime.minute(); // Get the minute component

    const defaultEndhour = defaultEndTime.hour();
    const defaultEndminutes = defaultEndTime.minute(); 


    
    let newStartHour, newStartMin,newEndHour, newEndMin, momentStartTime, momentEndTime;
    if(multipleShifts.length > 0){
      momentStartTime = moment(multipleShifts[multipleShifts.length - 1].deviceStartTime, 'h:mm A')
      newStartHour = momentStartTime.hours(); 
      newStartMin = momentStartTime.minutes(); 


      momentEndTime = moment( multipleShifts[multipleShifts.length - 1].deviceEndTime, 'h:mm A')
      newEndHour = momentEndTime.hours(); 
      newEndMin = momentEndTime.minutes(); 
    }
    // Get the last shift's end time and date
    let lastShiftEndDate:any = null;
    let lastShiftStartDate:any = null;
    if (multipleShifts.length > 0) {
      lastShiftStartDate = moment(multipleShifts[multipleShifts.length - 1].startTime).tz(data.address.timezone);
      lastShiftEndDate = moment(multipleShifts[multipleShifts.length - 1].endTime).tz(data.address.timezone);
    }

   
    let nextWorkStartday = lastShiftStartDate
        ? lastShiftStartDate.clone().add(1, 'days').format('dd')
        : currentDate.format('dd');
    let nextWorkEndday = lastShiftEndDate
        ? lastShiftEndDate.clone().add(1, 'days').format('dd')
        : currentDate.format('dd');

    let initialStartDate, initialEndDate;
    let newStartDate = moment().tz(data.address.timezone); 
    let newEndDate = moment().tz(data.address.timezone); 
    currentTime = newStartDate.format('HH:mm');

    let daysToStartAdd = multipleShifts.length === 0 ? 0 : 1;
    let daysToEndAdd = multipleShifts.length === 0 ? 0 : 1;
    if (isWorkday && multipleShifts.length === 0) {
      if (currentTime < formattedDefaultStartTime) {
        daysToStartAdd = 0;
        daysToEndAdd = 0;
      }
      else{
        daysToStartAdd = 1;
        daysToEndAdd = 1;
      }
    }
    // Check if the next workday is in the dynamic workweek
    while (data.defaultWorkWeek ? !data.defaultWorkWeek.includes(nextWorkStartday) :!defaultWorkDays.includes(nextWorkStartday)) {
      daysToStartAdd += 1;
      //Use the last entered end day to determine the next workday
      nextWorkStartday = moment(lastShiftStartDate ? lastShiftStartDate : currentDate).clone().add(daysToStartAdd, 'days').format('dd');
    }

    while (data.defaultWorkWeek ? !data.defaultWorkWeek.includes(nextWorkEndday) :!defaultWorkDays.includes(nextWorkEndday)) {
      daysToEndAdd += 1;
      //Use the last entered end day to determine the next workday
      nextWorkEndday = moment(lastShiftEndDate ? lastShiftEndDate : currentDate).clone().add(daysToEndAdd, 'days').format('dd');
    }

    
    if (isWorkday) {
      if (currentTime < formattedDefaultStartTime) {
        initialStartDate = multipleShifts.length !== 0 ?
          lastShiftStartDate.clone().add(daysToStartAdd, 'days').set('hour', newStartHour).set('minute', newStartMin).set('second', 0) :
          currentDate.set('hour', defaultStarthour).set('minute', defaultStartminutes).set('second', 0);
        initialEndDate = multipleShifts.length !== 0 ?
          lastShiftEndDate.clone().add(daysToEndAdd, 'days').set('hour', newEndHour).set('minute', newEndMin).set('second', 0) :
          currentDate.clone().set('hour', defaultEndhour).set('minute', defaultEndminutes).set('second', 0)

      } else {

        initialStartDate = multipleShifts.length === 0 ?
        newStartDate.add(daysToStartAdd, 'days').set('hour', defaultStarthour).set('minute', defaultStartminutes).set('second', 0) :
          lastShiftStartDate.clone().add(daysToStartAdd, 'days').set('hour', newStartHour).set('minute', newStartMin).set('second', 0);
        initialEndDate = multipleShifts.length === 0 ?
        newEndDate.clone().add(daysToEndAdd, 'days').set('hour', defaultEndhour).set('minute', defaultEndminutes).set('second', 0) :
          lastShiftEndDate.clone().add(daysToEndAdd, 'days').set('hour', newEndHour).set('minute', newEndMin).set('second', 0);

      }
    }
    else {

      initialStartDate = multipleShifts.length === 0 ?
      newStartDate.clone().add(daysToStartAdd, 'days').set('hour', defaultStarthour).set('minute', defaultStartminutes).set('second', 0) :
        lastShiftStartDate.clone().add(daysToStartAdd, 'days').set('hour', newStartHour).set('minute', newStartMin).set('second', 0);
      initialEndDate = multipleShifts.length === 0 ?
      newEndDate.clone().add(daysToEndAdd, 'days').set('hour', defaultEndhour).set('minute', defaultEndminutes).set('second', 0) :
        lastShiftEndDate.clone().add(daysToEndAdd, 'days').set('hour', newEndHour).set('minute', newEndMin).set('second', 0);

    }

    let startDateValidation = null;
    let endDateValidation = null;
    if (multipleShifts.length > 0)
    {
      let minimumStartTime = this.minDate();
      if ( moment(initialStartDate).isBefore(minimumStartTime)) {
        startDateValidation  = "Enter valid start time";
      } else {
        startDateValidation  = null;
      }

      let newStartTime =  initialStartDate;
      const minimumEndTime = newStartTime.clone().add(5, 'minutes');
      const maxEndTime = this.maxDate(newStartTime.clone());
      // Check if the selected end time is valid
      if ( moment(initialEndDate).isBefore(minimumEndTime)) {
        // Show an error message or prevent the selection
        endDateValidation = "Enter valid end time";
      } else if ( moment(initialEndDate).isAfter(maxEndTime)) {
        endDateValidation = "Enter valid end time";
      } else {
        endDateValidation = null;
      }
    }


    const newShiftEntry = { 
      id:multipleShifts.length == 0 ? 0 : multipleShifts[multipleShifts.length-1].id + 1,
      startTime: initialStartDate,
      endTime: initialEndDate,
      offsets: {
                startTime: moment(initialStartDate)
                  .tz(data.address.timezone)
                  .utcOffset(),
                endTime: moment(initialEndDate)
                  .tz(data.address.timezone)
                  .utcOffset(),
              },
      deviceStartTime: initialStartDate,
      deviceEndTime:initialEndDate,
      startTimeValidation:startDateValidation,
      endTimeValidation:endDateValidation,
    }
    //this.setState({multipleShifts : newShiftEntry});
    return newShiftEntry;
  };


  setShowTermOfService = (showTermOfService:boolean) =>
    this.setState({ showTermOfService });

  contractPreviewClick = () =>{
    this.setState({showContract:true})
  }
  onContractClose = () =>{
    this.setState({showContract:false})
  }
  onTermsClose = () =>{
    this.setState({showTermOfService:false})
  }
  onResumeClose= () => {
    this.setState({resumeModal :false})
   };
  onClose = () => {
    // if there is a background(previous path), go back to the previous path
    const { currentCreateShiftState } = this.state;

    if (currentCreateShiftState === CreateShiftState.Create) {
      this.goBackToPreviousLocation();
    } else {
      this.setState({
        discardAlertSInfo: {
          title: "Discard Shift",
          message: "Are you sure you want to discard the change?",
          buttons: [
            <RBButton
              key={RBButtonType.cancel}
              buttonType={RBButtonType.cancel}
              onClick={() => {
                this.setState({ isDiscardAlertShowing: false });
              }}
              text="Cancel"
            />,
            <RBButton
            buttonBackgroundColor={this.context.primaryColor}
              key={"Discard"}
              onClick={() => {
                this.setState({ isDiscardAlertShowing: false }, () =>
                  this.goBackToPreviousLocation()
                );
              }}
              color="primary"
              text="Discard"
            />,
          ],
        },
        isDiscardAlertShowing: true,
      });
    }
  };

  goBackToPreviousLocation = () => {
    if (this.props.location.state && this.props.location.state.background) {
      this.setState({ showModal: false }, () => this.props.history.goBack());
    } else {
      this.setState({ showModal: false }, () =>
        this.props.history.push("/dashboard")
      );
    }
  };

  onChange = (key: string, value: any) => {
    console.log(`key is ${key}, value is ${value}`);
    // this.setState({ [key]: value });
  };
  onSpecializationSkillSelectedChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    // this is a multiple select, so event.target.value will be an array
    // console.log("onSpecializationSkillSelectedChange", event.target.value);

    this.setState({
      selectedSpecializations: event.target.value as string[],
    });
  };
  updateWorkerType = (event: any) => {
    console.log(`updateWorkerType, value is ${event.target.value}`);
    this.setState({
      workerType: event.target.value,
      // reload the specializations options based on workerType
      specializations: this.state.AllSpecializationSkills.filter(
        (skill) => skill.pharmacistType === event.target.value
      ),
      // clear the old selectedSpecializations
      selectedSpecializations: [],

      //reset wage and its error text
      wage: "0",
      wageInvalidErrorText: EmptyString,
      workertypeInvalidErrorText: EmptyString
    });
  };
  isWageValid = () => {
    const { wage, workerType, workerTypesAndRates } = this.state;
    // Returns the minimum (inclusive) that the wage must be set to based on the
    // currently selected pharmacist type.
    const getMinimumWage = () => {
      const { workerType, workerTypesAndRates } = this.state;
      return workerTypesAndRates.filter((w:any) => w.workerType === workerType)
        .length > 0
        ? workerTypesAndRates.filter((w:any) => w.workerType === workerType)[0]
            .minimumRate / 100
        : 100;
    };
  
    const lowerBound = getMinimumWage();

    this.setState({
      wageInvalidErrorText:
        Number(wage) >= lowerBound
          ? EmptyString
          : `The minimum rate is $${lowerBound.toFixed(2)}`,
    });

    return Number(wage) >= lowerBound;
  };
  renderPharmacyName = () => {
    const { pharmacyName } = this.state;
    return (
      <Typography variant="h6" style={{ textAlign: "center" }}>
        {pharmacyName}
      </Typography>
    );
  };
  plusPressed = async() => {
    const { multipleShifts, pharmacyTimeZone,pharmacy } = this.state;
    let newMultipleShifts:any = [];
    for (let index = 0; index < multipleShifts.length; index++) {
      newMultipleShifts.push({
        id: index,
        startTime: multipleShifts[index].startTime,
        endTime: multipleShifts[index].endTime,
        offsets: {
          startTime: moment()
            .tz(pharmacyTimeZone)
            .utcOffset(),
          endTime: moment()
            .tz(pharmacyTimeZone)
            .utcOffset(),
        },
        deviceStartTime: multipleShifts[index].deviceStartTime,
        deviceEndTime: multipleShifts[index].deviceEndTime,
        startTimeValidation:multipleShifts[index].startTimeValidation,
        endTimeValidation:multipleShifts[index].endTimeValidation
      });
    }
    const nextShifts = await this.getNextWorkDay(pharmacy);
    newMultipleShifts.push(nextShifts)
    this.setState({ multipleShifts: newMultipleShifts });
  };
  removePressed(shiftNoId: number) {
    const { multipleShifts } = this.state;
    multipleShifts.splice(shiftNoId, 1);

    // Reload the shifts since the id is changed
    const newMultipleShifts = [];
    for (let index = 0; index < multipleShifts.length; index++) {
      newMultipleShifts.push({
        id: index,
        startTime: multipleShifts[index].startTime,
        endTime: multipleShifts[index].endTime,
        offsets: multipleShifts[index].offsets,
        deviceStartTime: multipleShifts[index].deviceStartTime,
        deviceEndTime: multipleShifts[index].deviceEndTime,
        startTimeValidation:multipleShifts[index].startTimeValidation,
        endTimeValidation:multipleShifts[index].endTimeValidation,
        
      });
    }
    this.setState({ multipleShifts: newMultipleShifts });
  }

  getDateTime(date:any) {
    let currentDate = moment(date)
    let year = currentDate.format('YYYY')
    let month = currentDate.format('MM')
    let day = currentDate.format('DD')
    let hour = currentDate.format('HH')
    let minute = currentDate.format('mm')
    let second = currentDate.format('ss')
    let convertedDate = moment(year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second);
    return convertedDate;
  }
  
  convertOffsetToString = (offset:any) => {
    let value = '';
    const absOffset = Math.abs(offset);
    const hr = absOffset / 60;
    const min = absOffset % 60;
    if (offset < 0) {
      value = value + '-';
    }
    else {
      value = value + '-';
    }
    if (hr < 10) {
      value = value + '0' + hr;
    }
    else {
      value = value + hr;
    }
    if (min < 10) {
      value = value + ':0' + min;
    }
    else {
      value = value + ':' + min;
    }
    return value;
  }

  getNextWorkingDay = (date:any) => {
    const { pharmacy } = this.state;
    let nextDate = moment(date).add(24, "hours");
    let nextDay = nextDate.format('dd');

    while (!pharmacy.defaultWorkWeek.includes(nextDay)) {
      nextDate = moment(nextDate).add(24, "hours");
      nextDay = nextDate.format('dd');
    }

    return nextDate;
  };

  isWeekday = (date:any) => {
    const { pharmacy } = this.state;
    const dayOfWeek = moment(date).format('dd');
     
     // Check if the day of the week is in the defaultWorkDays array
     return pharmacy.defaultWorkWeek.includes(dayOfWeek);
  };

  excludedDates:any = [];

  minDate = () => {
    const { pharmacy } = this.state;
    let currentDate = moment().tz(pharmacy.address.timezone);
    let currentDay  = currentDate.format('dd');

    while (!pharmacy.defaultWorkWeek.includes(currentDay)) {
      currentDate = currentDate.add(1, 'day');
      currentDay  = currentDate.format('dd');

    }

    return moment(currentDate)
  }


  maxDate = (startDate:any) => {
    const { pharmacy } = this.state;
    let newDate = this.getNextWorkingDay(startDate);
    let currentDate = moment(startDate).add(1, 'day'); // Start date + 1 day

    while (currentDate.isBefore(newDate)) {
      if (!pharmacy.defaultWorkWeek.includes(currentDate)) {
        this.excludedDates.push(currentDate.toDate());
      }
      currentDate = currentDate.add(1, 'day');
    }
    return moment(newDate)
  }

  renderMultipleShiftsTime = () => {
    const { multipleShifts, pharmacyTimeZone } = this.state;
    console.log("renderMultipleShiftsTime ", multipleShifts, pharmacyTimeZone);
    return (
      <>
        {multipleShifts.map((shift,index) => (
          <div key={shift.id} style={{ marginBottom: Spacing.small }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography style={{ display: "flex", flex: 1 }}>
                Shift No. {shift.id + 1}
              </Typography>
              <RBEmptyBlock />
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                }}
              >
                {index === 0 ? (
                  <IconButton size={"small"} onClick={() => this.plusPressed()}>
                    <Add style={{ color: Colors.themeBlue }} />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    onClick={() => this.removePressed(shift.id)}
                  >
                    <Remove style={{ color: Colors.themeGreen }} />
                  </IconButton>
                )}
              </div>
            </div>
            <div
              style={{
                ...ColumnStyle, alignItems: "center"
              }}
            >
               <div style={{display: "flex", flex: 1}}>
               <DatePicker
                selected={moment(this.getDateTime(shift.deviceStartTime)).toDate()}
                onChange={(date) => {
                  const { multipleShifts } = this.state;
                  const newMultipleShifts = [];
                  for (let index = 0; index < multipleShifts.length; index++) {
                    newMultipleShifts.push({
                      id: index,
                      startTime: multipleShifts[index].startTime,
                      endTime: multipleShifts[index].endTime,
                      offsets: multipleShifts[index].offsets,
                      deviceStartTime: multipleShifts[index].deviceStartTime,
                      deviceEndTime: multipleShifts[index].deviceEndTime,
                      startTimeValidation:multipleShifts[index].startTimeValidation,
                      endTimeValidation:multipleShifts[index].endTimeValidation,
                    });
                  }
                  let newShift = newMultipleShifts[shift.id];
                  const offsetString1 = this.convertOffsetToString(newShift.offsets.startTime)
                  newShift.deviceStartTime = date ? moment(date) : moment()
                  newShift.startTime = date ? moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1) :
                    moment(moment().format('YYYY-MM-DDTHH:mm:ss') + offsetString1)

                  const momentStartTime = moment(shift.deviceEndTime, 'h:mm A')
                  const newStartHour = momentStartTime.hours(); 
                  const newStartMin = momentStartTime.minutes(); 
                  newShift.endTime  = moment(moment(moment(date).set('hour', newStartHour).set('minute', newStartMin).set('second', 0)).format('YYYY-MM-DDTHH:mm:ss')+ offsetString1);
                  newShift.deviceEndTime  = moment(date).set('hour', newStartHour).set('minute', newStartMin).set('second', 0);

                  let UtcOffsetForCondition = moment.tz(newShift.startTime, pharmacyTimeZone)
                  if (multipleShifts[shift.id].offsets['startTime'] !== UtcOffsetForCondition.utcOffset()) {
                    const newOffset = multipleShifts[shift.id].offsets['startTime'] - UtcOffsetForCondition.utcOffset();
                    newShift.startTime.add(newOffset, 'minutes')
                    newShift.endTime.add(newOffset, 'minutes')
                    newShift.offsets['startTime'] = UtcOffsetForCondition.utcOffset();
                    newShift.offsets['endTime'] = moment.tz(newShift.endTime, pharmacyTimeZone).utcOffset()
                  }
                 

                  // Check if the selected start time is valid
                  let minimumStartTime = this.minDate();
                  if (moment(newShift.startTime).isBefore(minimumStartTime)) {
                    newShift.startTimeValidation = "Enter valid start time";
                    newShift.endTimeValidation = null;
                  } else {
                    newShift.startTimeValidation = null;
                    newShift.endTimeValidation = null;
                  }

                  const maxEndTime = moment(this.maxDate(newShift.startTime));
                  const minEndTime = moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString1).add(5,"minutes");
                  if (moment(newShift.endTime).isBefore(moment(minEndTime))) {
                    // Show an error message or prevent the selection
                   newShift.endTimeValidation = "Enter valid end time";
                  } else if (moment(newShift.endTime).isAfter(maxEndTime)) {
                    newShift.endTimeValidation = "Enter valid end time";
                  } else {
                    newShift.endTimeValidation = null;
                  }

                  this.setState({ multipleShifts: newMultipleShifts });
                }}
                showTimeSelect
                minDate={this.minDate().toDate()}
                // minTime= {moment(this.getDateTime(this.minDate())).toDate()}
                // maxTime={new Date(2100, 0, 1, 23, 59)}
                timeFormat="h:mm aa"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="eee, MMM do yyyy h:mm aa"
                customInput={
                  <input
                    style={{
                      paddingTop: Spacing.medium,
                      paddingBottom: Spacing.medium,
                      paddingRight: "20%",
                      borderRadius:5,
                      borderColor:"rgb(220 220 220)",
                      width: 260,
                      fontSize:15
                    }}
                    key={`dateTime-${pharmacyTimeZone}`}
                  />
                }
                withPortal
                filterDate={this.isWeekday}
              />
              
              </div>
              <RBEmptyBlock />
              <div style={{display: "flex", flex: 1}}>
              <DatePicker
                selected={moment(this.getDateTime(shift.deviceEndTime)).toDate()}
                onChange={(date) => {
                  console.log(
                    `RBDateTimePicker shift ${shift.id} end time ${date}`
                  );
                  const { multipleShifts } = this.state;
                  const newMultipleShifts = [];
                  for (let index = 0; index < multipleShifts.length; index++) {
                    newMultipleShifts.push({
                      id: index,
                      startTime: multipleShifts[index].startTime,
                      endTime: multipleShifts[index].endTime,
                      offsets: multipleShifts[index].offsets,
                      deviceStartTime: multipleShifts[index].deviceStartTime,
                      deviceEndTime: multipleShifts[index].deviceEndTime,
                      startTimeValidation:multipleShifts[index].startTimeValidation,
                      endTimeValidation:multipleShifts[index].endTimeValidation,
                    });
                  }
                  let newShift = newMultipleShifts[shift.id];
                  newShift.offsets['endTime'] = moment.tz(newShift.endTime, pharmacyTimeZone).utcOffset()
                  const offsetString2 = this.convertOffsetToString(newShift.offsets.endTime)
                  newShift.deviceEndTime = date ? moment(date) : moment()
                  newShift.endTime = date ? moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + offsetString2) :
                    moment(moment().format('YYYY-MM-DDTHH:mm:ss') + offsetString2)
                  let UtcOffsetForCondition = moment.tz(newShift.endTime, pharmacyTimeZone)
                  if(multipleShifts[shift.id].offsets['endTime'] !==  UtcOffsetForCondition.utcOffset())
                  {
                    newShift.offsets['endTime'] = newShift.endTime.utcOffset();
                  }
                  
                  const maxEndTime = moment(this.maxDate(shift.startTime));
                  const minEndTime = moment(shift.startTime).add(5,"minutes");
                  if (moment(newShift.endTime).isBefore(moment(minEndTime))) {
                    // Show an error message or prevent the selection
                   newShift.endTimeValidation = "Enter valid end time";
                  } else if (moment(newShift.endTime).isAfter(maxEndTime)) {
                    newShift.endTimeValidation = "Enter valid end time";
                  } else {
                    newShift.endTimeValidation = null;
                    }

                  this.setState({ multipleShifts: newMultipleShifts });
                }}
                showTimeSelect
                minDate={
                  moment(this.getDateTime(shift.deviceStartTime)).toDate()
                }
               maxDate={this.maxDate(shift.deviceStartTime).toDate()}
                // minTime= {moment(this.getDateTime(shift.deviceStartTime)).toDate()}
                // maxTime={moment(this.getDateTime(this.maxDate(shift.deviceStartTime))).toDate()}
                
                timeFormat="h:mm aa"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="eee, MMM do yyyy h:mm aa"
                customInput={
                  <input
                    style={{
                      paddingTop: Spacing.medium,
                      paddingBottom: Spacing.medium,
                      borderRadius:5,
                      borderColor:"rgb(220 220 220)",
                      width: 260,
                      fontSize:15
                    }}
                    key={`dateTime-${pharmacyTimeZone}`}
                  />
                }
                withPortal
                excludeDates={this.excludedDates}
              />
              </div>
            </div>
            <div
              style={{
                ...ColumnStyle, alignItems: "center"
              }}
            >
              <div style={{display: "flex", flex: 1}}>
              {shift.startTimeValidation && (
                              <Typography variant="caption" color="error" style={{ marginLeft: 20 ,marginBottom:"8px"}}>
                                {shift.startTimeValidation}
                              </Typography>
                            )}
              </div>
              <div style={{display: "flex", flex: 1}}>
              {shift.endTimeValidation && (
                              <Typography variant="caption" color="error" style={{ marginLeft: 20 }}>
                                {shift.endTimeValidation}
                              </Typography>
                            )}
              </div>

            </div>
            
          </div>
        ))}
      </>
    );
  };
  renderWorkerType = () => {
    const { workerType,workerTypes, workertypeInvalidErrorText } = this.state;
    return (
      <div style={{ ...ColumnStyle, alignItems: "center" }}>
        <Typography style={{ display: "flex", flex: 1 }}>
          Worker Type
        </Typography>
        <RBEmptyBlock />
        <FormControl
          variant="outlined"
          size={"small"}
          style={{ display: "flex", flex: 1 }}
        >
         <Select
          value={workerType}
          displayEmpty
          renderValue={workerType !== "" ? undefined : () => "Select"}
          onChange={this.updateWorkerType}>
          {workerTypes.map((value:any) => (
            <MenuItem value={value.workerType} key={value.workerType}>
              {value.displayName}
            </MenuItem>
          ))}
        </Select>
        {workertypeInvalidErrorText !== EmptyString && <FormHelperText error>{workertypeInvalidErrorText}</FormHelperText>}
        </FormControl>
      </div>
    );
  };
  renderPharmacySpecific = () => {
    const {
      softwareName,
      softwareRequired,
      workerType,
      assistantOnSite,
      technicianOnSite,
    } = this.state;
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <Typography style={{ display: "flex", flex: 1 }}>
            Pharmacy Software
          </Typography>
          <RBEmptyBlock />
          <FormControlLabel
            style={{
              display: "flex",
              flex: 1,
              color: this.state.softwareRequired
                ? this.context.secondaryColor
                : Colors.darkGrey,
            }}
            control={
              <Checkbox
                checked={softwareRequired}
                style={{
                  color: this.state.softwareRequired
                    ? this.context.secondaryColor
                    : Colors.darkGrey,
                }}
                onChange={() =>
                  this.setState({
                    softwareRequired: !this.state.softwareRequired,
                  })
                }
              />
            }
            label={<Typography>{softwareName}</Typography>}
          />
        </div>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            marginTop: -Spacing.large,
          }}
        >
          <Typography style={{ display: "flex", flex: 1 }}></Typography>
          <RBEmptyBlock />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              fontSize: 12,
              textAlign: "start",
            }}
          >
            {Claims.pharmacySoftwareClaim}
          </div>
        </div>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
          }}
        >
          <Typography style={{ display: "flex", flex: 1 }}>
            {LabelText.TECHNICIAN_ON_SITE}
          </Typography>
          <RBEmptyBlock />
          <FormControlLabel
            style={{
              display: "flex",
              flex: 1,
            }}
            control={
              <Checkbox
                checked={technicianOnSite}
                style={{
                  color: this.state.technicianOnSite
                    ? this.context.secondaryColor
                    : Colors.darkGrey,
                }}
                onChange={() =>
                  this.setState({
                    technicianOnSite: !this.state.technicianOnSite,
                  })
                }
              />
            }
            label={EmptyString}
          />
        </div>
        {workerType !== workerTypeEnum.assistant && (
          <>
          <div
            style={{
              ...ColumnStyle,
              alignItems: "center",
            }}
          >
            <Typography style={{ display: "flex", flex: 1 }}>
              {LabelText.ASSISTANT_ON_SITE}
            </Typography>
            <RBEmptyBlock />
            <FormControlLabel
              style={{
                display: "flex",
                flex: 1,
              }}
              control={
                <Checkbox
                  checked={assistantOnSite}
                  style={{
                    color: this.state.assistantOnSite
                      ? this.context.secondaryColor
                      : Colors.darkGrey,
                  }}
                  onChange={() =>
                    this.setState({
                      assistantOnSite: !this.state.assistantOnSite,
                    })
                  }
                />
              }
              label={EmptyString}
            />
          </div>
          
          </>
        )}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            textAlign: "start",
            fontSize: 12,
          }}
        >
          {Claims.assistantOnSiteClaim}
        </div>
      </>
    );
  };

  renderWage = () => {
    const { wage, wageInvalidErrorText,allowOfferChangeOption ,allowOffer,workertypeInvalidErrorText} = this.state;
    return (
      <>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            paddingBottom: Spacing.smallest,
          }}
        >
          <Typography style={{ display: "flex", flex: 1,color:"red" }}>
            Hourly Rate Offered
          </Typography>
          <RBEmptyBlock />
          <RBMoneyAmountTextField
            name="currency"
            value={wage}
            onChange={(event) =>
              {
              this.setState({
                wage:
                  event.target.value == "0.00" ? "0" : event.target.value,
              })
              if(this.state.workerType === EmptyString || this.state.workerType === "Select"){
                this.setState({workertypeInvalidErrorText :"Please select a worker type"})
              }

            }
            }
            error={wageInvalidErrorText.length > 0}
            errorText={wageInvalidErrorText}
          />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            textAlign: "start",
            fontSize: 12,
            color:"red"
          }}
        >
          {Claims.shiftCommission}
        </div>

        {allowOfferChangeOption && <>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            paddingBottom: Spacing.smallest,
          }}
        >
          <Typography style={{ display: "flex", flex: 1}}>
          Allow rate offers?
          </Typography>
          <RBEmptyBlock />
            <FormControlLabel
              style={{
                display: "flex",
                flex: 1,
              }}
              control={
                <Checkbox
                  checked={allowOffer}
                  style={{
                    color: this.state.allowOffer
                      ? this.context.secondaryColor
                      : Colors.darkGrey,
                  }}
                  onChange={() =>
                    this.setState({
                      allowOffer: !this.state.allowOffer,
                    })
                  }
                />
              }
              label={EmptyString}
            />
          </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            textAlign: "start",
            fontSize: 12,
          }}
        >
          {Claims.Allow_Offers_Description}
        </div>
        </>}

      </>
    );
  };
  renderAllowance = () => {
    const { kmAllowance, accomodationAllowance,isAllowanceEnabled } = this.state;
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <Typography style={{ display: "flex", flex: 1 }}>
            Commute Allowance
          </Typography>
          <RBEmptyBlock />
          <FormControlLabel
            style={{
              display: "flex",
              flex: 1,
            }}
            control={
              <Checkbox
                checked={isAllowanceEnabled}
                style={{
                  color: this.state.isAllowanceEnabled
                    ? this.context.secondaryColor
                    : Colors.darkGrey,
                }}
                onChange={() =>
                  this.setState({
                    isAllowanceEnabled: !this.state.isAllowanceEnabled,
                  })
                }
              />
            }
            label={EmptyString}
          />

        </div>
        {isAllowanceEnabled && <>
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {detailKmAllowanceIcon(this.context.primaryColor)}
            <div style={{ marginLeft: Spacing.small }}>Per Km</div>
          </div>
          <RBEmptyBlock />
          <RBMoneyAmountTextField
            name="currency"
            inputProps={{
              style: {
                paddingTop: Spacing.smaller,
                paddingBottom: Spacing.smaller,
              },
            }}
            onChange={(event) =>
              this.setState({
                kmAllowance:
                event.target.value == "0.00" ? "0" : event.target.value,
              })
            }
            value={kmAllowance}
          />
        </div>

        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 4,
              marginRight: -4,
            }}
          >
            {detailAccomodationAllowanceIcon(this.context.primaryColor)}
            <div style={{ marginLeft: Spacing.small }}>Per Shift</div>
          </div>
          <RBEmptyBlock />

          <RBMoneyAmountTextField
            inputProps={{
              style: {
                paddingTop: Spacing.smaller,
                paddingBottom: Spacing.smaller,
              },
            }}
            onChange={(event) =>
              this.setState({
                accomodationAllowance:
                event.target.value == "0.00" ? "0" : event.target.value,
              })
            }
            value={accomodationAllowance}
          />
        </div>
        </>
        }
      </>
    );
  };

  renderComment = () => {
    const { comment } = this.state;
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <Typography style={{ display: "flex", flex: 1 }}>Comments</Typography>
        </div>
        <RBTextField
          style={{
            display: "flex",
            flex: 1,
            paddingTop: Spacing.small,
            paddingBottom: Spacing.small,
          }}
          placeholder={"*Please include important details about the shift, including pace, support staff, pharmacist overlap, etc."}
          value={comment}
          onChange={(event) => this.setState({ comment: event.target.value })}
          multiline
          rows={3}
          rowsMax={8}
        />
      </>
    );
  };
  renderSkills = () => {
    const { selectedSpecializations, specializations } = this.state;
    return (
      <>
        <div style={{ ...ColumnStyle, alignItems: "center" }}>
          <Typography style={{ display: "flex", flex: 1 }}>
            Specialization Skills
          </Typography>
          <RBEmptyBlock />
          <FormControl
            variant="outlined"
            size={"small"}
            style={{ display: "flex", flex: 1 }}
          >
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedSpecializations}
              onChange={this.onSpecializationSkillSelectedChange}
              displayEmpty
              renderValue={(selected) => {
                if ((selected as string[]).length === 0) {
                  return ("Add Skills");
                }
                return (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {(selected as string[]).map((skillID) => {
                      return (
                        <div key={skillID} style={{ ...skillTextStyle }}>
                          {
                            specializations.filter(
                              (specialization) => specialization.id === skillID
                            )[0].name
                          }
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            >
              {specializations.map((specialization) => (
                <MenuItem key={specialization.id} value={specialization.id}>
                  <Checkbox
                    checked={
                      selectedSpecializations.indexOf(specialization.id) > -1
                    }
                    style={{
                      color:
                        selectedSpecializations.indexOf(specialization.id) > -1
                          ? this.context.secondaryColor
                          : Colors.darkGrey,
                    }}
                  />
                  <ListItemText primary={specialization.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            ...ColumnStyle,
            alignItems: "center",
            marginTop: -Spacing.small,
          }}
        >
          <Typography style={{ display: "flex", flex: 1 }}></Typography>
          <RBEmptyBlock />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              fontSize: 12,
              textAlign: "start",
            }}
          >
            *Select only mandatory skills
          </div>
        </div>
      </>
    );
  };

  previewAllowance = () => {
    const { kmAllowance, accomodationAllowance } = this.state;
    const kmAllowanceNumber = Number(kmAllowance);
    const accomodationAllowanceNumber = Number(accomodationAllowance);
    return (
      <div style={ColumnStyle}>
        {kmAllowanceNumber > 0 && (
          <div style={{ flex: 1, alignItems: "flex-start" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                ...titleHeaderTextStyle,
              }}
            >
              {detailKmAllowanceIcon(this.context.primaryColor)}
              <div
                style={{
                  marginLeft: Spacing.smallest,
                }}
              >
                KM Allowance
              </div>
            </div>

            <div style={detailTextStyle}>{"$" + kmAllowance + "/km"}</div>
          </div>
        )}
        {accomodationAllowanceNumber > 0 && (
          <div style={{ flex: 1, alignItems: "flex-start" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                ...titleHeaderTextStyle,
              }}
            >
              {detailAccomodationAllowanceIcon(this.context.primaryColor)}
              <div
                style={{
                  marginLeft: Spacing.smallest,
                }}
              >
                Allowance
              </div>
            </div>

            <div style={detailTextStyle}>{"$" + accomodationAllowance}</div>
          </div>
        )}
      </div>
    );
  };

  previewShiftTimes = () => {
    debugger
    const { multipleShifts, pharmacyTimeZone } = this.state;
    console.log("starttime", moment(multipleShifts[0].startTime).tz(pharmacyTimeZone).format("h:mm a"))
    return (
      <>
        {multipleShifts.map((shift) => {
          const startTimeFormat = shift.startTime
            ? moment(shift.startTime).tz(pharmacyTimeZone).format("h:mm a")
            : "N/A";
          const endTimeFormat = shift.endTime
            ? moment(shift.endTime).tz(pharmacyTimeZone).format("h:mm a")
            : "N/A";
          const dateString = moment(shift.startTime)
            .tz(pharmacyTimeZone)
            .format("ddd, MMMM Do YYYY");
          return (
            <div key={shift.id} style={{ marginBottom: Spacing.small }}>
              <div
                style={{ ...ColumnStyle, marginBottom: 0, paddingBottom: 0 }}
              >
                {RenderTextWithTitle(
                  `Shift No. ${shift.id + 1} Date`,
                  dateString
                )}
                {RenderTextWithTitle(
                  "Time",
                  `${startTimeFormat} - ${endTimeFormat}`
                )}
              </div>
              <div
                style={{
                  borderBottomColor: Colors.defaultGrey,
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                }}
              />
            </div>
          );
        })}
      </>
    );
  };
  previewContent = () => {
    const {
      pharmacyName,
      pharmacyId,
      workerType,
      softwareName,
      selectedSpecializations,
      specializations,
      technicianOnSite,
      assistantOnSite,
      kmAllowance,
      accomodationAllowance,
      softwareRequired,
      wage,
      comment,
      rxCount,
      agreeTOS,
      allowOffer,
      signature,
      allowOfferChangeOption,
      multipleShifts,
      commissionType,
      rbCommission,
      isAllowanceEnabled,
      pharmacy,
      showTermOfService,
      networkId,
      resumeModal,
      ownerData,
      newFile
    } = this.state;
    console.log("signature",signature)

    const software = softwareRequired
      ? [softwareName]
      : [`Not Required (${softwareName})`];

    const finalComment = comment.length > 0 ? comment : NotAvailableString;

    const payload = {
      pharmacyName,
      pharmacyId,
      shiftTimes: multipleShifts,
      comment,
      workerWage: (parseFloat(wage) * 100).toFixed(2),
      allowOffers: allowOffer,
      pharmacistType:workerType,
      isAllowanceEnabled,
      kmAllowance: isAllowanceEnabled ? (parseFloat(kmAllowance) * 100).toFixed(2) : 0,
      accomodationAllowance: isAllowanceEnabled
        ? (parseFloat(accomodationAllowance) * 100).toFixed(2)
        : 0,
      actualCommission: pharmacy.commissionType == 'fixed' ? pharmacy.commission : pharmacy.commissionPercentage,
      commissionType:pharmacy.commissionType,
      commissionMinimum:pharmacy.commissionMinimum,
      commissionMaximum:pharmacy.commissionMaximum,
      networkPaid:pharmacy.networkPaid
    };



    const allowanceAvailable =
    Number(accomodationAllowance) > 0 || Number(kmAllowance) > 0;
    let rbPlacement = ShiftService.getPlacementFee(payload).toFixed(2)
    this.setState({rbCommission:rbPlacement})

    return (
      <>
         <RBModal
          open={resumeModal}
          onClose={this.onResumeClose}
          modalTitle={"Signature"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              this.rendersignatureContent(signature)
          }
        />

        <div style={ColumnStyle}>
          {RenderTextWithTitle("Pharmacy", pharmacyName)}
        </div>
        {this.previewShiftTimes()}

        {allowOfferChangeOption ? 
        <>
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Worker Type",
            formatWorkerType(workerType as workerTypeEnum)
          )}
          {RenderTextWithTitle("Hourly Rate (per shift)", "$" + wage)}
        </div>

        <div style={ColumnStyle}>
          <RBPlacementFeeModal title={multipleShifts.length > 1 ? "Total Placement Fee" : "Placement Fee"} detail={"$"+rbCommission} commissionType={commissionType}/>
          {/* {RBPlacementFeeModal(
            multipleShifts.length > 1 ? "Total Placement Fee" : "Placement Fee",
            "$"+rbCommission,commissionType
          )} */}
           {renderTextWithTitleAndChildComponent(
            "Allow rate offers?",
            allowofferIcon(allowOffer,this.context.secondaryColor)
          )}
        </div>
        </>:
        <>
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Worker Type",
            formatWorkerType(workerType as workerTypeEnum)
          )}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Hourly Rate (per shift)", "$" + wage)}
          <RBPlacementFeeModal title={multipleShifts.length > 1 ? "Total Placement Fee" : "Placement Fee"} detail={"$"+rbCommission} commissionType={commissionType}/>
         
        </div>
        </>}


        {allowanceAvailable && this.previewAllowance()}

        <div style={ColumnStyle}>
          {renderTextWithTitleAndChildComponent(
            LabelText.TECHNICIAN_ON_SITE,
            techOnSiteIcon(technicianOnSite,this.context.secondaryColor)
          )}
          {workerType !== workerTypeEnum.assistant &&
            renderTextWithTitleAndChildComponent(
              LabelText.ASSISTANT_ON_SITE,
              assistantOnSiteIcon(assistantOnSite,this.context.secondaryColor)
            )}
        </div>

        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills("Software", software)}
          {RenderTextWithTitle("Daily Avg Rx Count", rxCount)}
        </div>

        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills(
            "Specializations",
            specializations
              .filter((skill) => selectedSpecializations.indexOf(skill.id) > -1)
              .map((skill) => skill.name)
          )}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Comment", finalComment)}
        </div>
        <div style={ColumnStyle}>
          
            {signature && renderTextWithTitleAndChildComponent("Signature", <Link
              // href={signature}
              onClick={() => this.setState({resumeModal :true})}
              style={{ ...detailTextStyle, color: this.context.primaryColor, cursor: 'pointer'}}
            >
              {"View"}
            </Link>)}
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowWrap: "anywhere",
              alignItems: "flex-start",
              alignContent: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                overflowWrap: "anywhere",

                ...titleHeaderTextStyle,
              }}
            >
             Shift Agreement
            </div>

            <Link
              style={{
                display: "flex",
                flex: 1,
                whiteSpace: "pre-wrap",
                overflowWrap: "anywhere",
                ...detailTextStyle,
                color: this.context.primaryColor,
                cursor: 'pointer' 
              }}
              onClick={this.contractPreviewClick}
            >
              Preview
            </Link>
          </div>
          {/* {RenderTextWithTitle("Shift Agreement", "Preview")} */}
        </div>

        {!signature && 
        <>
        <SignaturePad
          isVisible={true}
          onLoad = {(file,dataURL) =>{
            this.setState({newFile :file})
          }}

          onSave={(file,dataURL) =>{
          }}
          onCancel={() =>{}}
          onClear={() => {
            localStorage.removeItem("workerSign")
            this.setState({newFile : null})
          }}
          userData = {ownerData}
          page="createShift"
        />
        <div style={{ fontSize: 10 }}>
          *This signature will be saved in your profile as default for
          future usage
        </div>
        </>
        }
        
        



        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            marginTop: Spacing.large,
          }}
        >
          <Checkbox
            checked={agreeTOS}
            style={{
              padding: 0,
              paddingRight: Spacing.large,
              // Why marginLeft -2, so  it will align with regular label or text field
              marginLeft: -2,
              color: agreeTOS ? this.context.secondaryColor : Colors.darkGrey,
            }}
            
            onChange={(_, checked) => {
              this.setState({ agreeTOS: checked });
            }}
          />
          <Typography variant="body2">
            I have read and understood the{" "}
            <Link onClick={() => this.setShowTermOfService(true)} style={{color:this.context.primaryColor, cursor: 'pointer', fontWeight:"bold"}}>{HelperPageTitleEnum.termOfService}
            .</Link>
          </Typography>
        </div>
        {showTermOfService && <RBShiftAgreementModal
          open={showTermOfService}
          onClose={this.onTermsClose}
          modalTitle={"Terms of Service"}
          pageName="terms-of-use"
          pageType="page"
          networkId = {networkId}
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
          />
        }

    
      </>
    );
  };
  renderShiftContractContent = () =>{
    return(
      <div>text</div>
    )
  }
  renderCreateShiftContent = () => {
    const { currentCreateShiftState } = this.state;

    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
        }}
      >
        {currentCreateShiftState === CreateShiftState.Create && (
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            {this.renderPharmacyName()}
            {this.renderMultipleShiftsTime()}
            {this.renderWorkerType()}
            <div style={{ marginTop: Spacing.small }}>
              {this.renderSkills()}
            </div>
            {this.renderPharmacySpecific()}
            {this.renderWage()}
            <div style={{ marginTop: Spacing.small }}>
              {this.renderAllowance()}
            </div>
            {this.renderComment()}
          </RBCardView>
        )}
        {currentCreateShiftState === CreateShiftState.Confirm && (
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              paddingLeft: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            {this.previewContent()}
          </RBCardView>
        )}
      </div>
    );
  };

  isCreateShiftFormValid = () => {
    
    const { multipleShifts, workerType, pharmacyTimeZone } = this.state;
    //make sure every shift time is valid
    const reducer = (accumulator: any, currentValue: any) =>
      accumulator && currentValue;
    const isAllShiftTimesValid = multipleShifts
      .map((shift) => {
        const durationAsHours = moment
          .duration(moment(shift.startTime).diff(moment(shift.endTime)))
          .asHours();
        return (
          durationAsHours < 24 &&
          moment(shift.startTime).isBefore(moment(shift.endTime)) && shift.startTimeValidation === null && shift.endTimeValidation === null
        );
      })
      .reduce(reducer, true);
    return isAllShiftTimesValid && workerType !== EmptyString;
  };
  isPreviewFormValid = () => {
    const { agreeTOS,newFile,signature } = this.state;
    return (agreeTOS && (signature || newFile));
  };

  onSubmittingCreateShift = async () => {
    const {
      multipleShifts,
      pharmacyId,
      pharmacySoftware,
      softwareRequired,
      technicianOnSite,
      assistantOnSite,
      wage,
      kmAllowance,
      accomodationAllowance,
      workerType,
      selectedSpecializations,
      specializations,
      comment,
      pharmacy,
      allowOffer,
      isAllowanceEnabled,
      pharmacyName,
      newFile
    } = this.state;
    try {
      this.setState({ isSubmitting: true });

      let skills: SkillPayloadDto[] = specializations
        .filter((skill) => selectedSpecializations.indexOf(skill.id) > -1)
        .map((skill) => {
          return {
            skillId: skill.id,
            name: skill.name,
            required: true,
            pharmacistType: skill.pharmacistType,
            type: skill.type,
          };
        });
      if (pharmacySoftware) {
        skills.push({
          skillId: pharmacySoftware.id,
          name: pharmacySoftware.name,
          required: softwareRequired,
          type: pharmacySoftware.type,
          pharmacistType: pharmacySoftware.pharmacistType,
        });
      }
      const shiftTimes: ShiftTimeDto[] = multipleShifts.map((shift) => {
        return {
          id: shift.id,
          startTime: shift.startTime.toISOString(),
          endTime: shift.endTime.toISOString(),
          offsets:shift.offsets

        };
      });
      const payload: CreateShiftDto = {
        pharmacyId: pharmacyId,
        shiftTimes: shiftTimes,
        technicianOnSite: technicianOnSite,
        assistantOnSite: assistantOnSite,
        wage: (Number(wage) * 100).toFixed(2),
        kmAllowance: (Number(kmAllowance) * 100).toFixed(2),
        accomodationAllowance: (Number(accomodationAllowance) * 100).toFixed(2),
        comment: comment,
        pharmacistType: workerType as workerTypeEnum,
        skills: skills,
        actualCommission:pharmacy.commissionType == 'fixed' ? pharmacy.commission : pharmacy.commissionPercentage,
        allowOffers:allowOffer,
        commissionMaximum:pharmacy.commissionMaximum,
        commissionMinimum:pharmacy.commissionMinimum,
        commissionType:pharmacy.commissionType,
        isAllowanceEnabled:isAllowanceEnabled,
        networkPaid:pharmacy.networkPaid,
        pharmacy:pharmacy,
        pharmacyName:pharmacyName

      };

      if (newFile) {
        await AuthService.updateOwner({ signature: newFile });
      }

      console.log("create shift on submit payload", payload);
      const newShift = await PharmacyService.createShift(payload);
      this.setState({ isSubmitting: false });
      console.log("create shift on submit new shift", newShift);
      NotificationService.publish(
        NotificationServiceTopic.ShiftChanged,
        newShift.id
      );
      //this.goBackToPreviousLocation();
     // this.props.history.goBack();
    // this.setState({ showModal: false }, () => this.props.history.goBack());
    this.setState({
      createShiftSubmitInfo: {
        title: "Shift Created",
        message: "Your shift has been created.",
        buttons: [
          <RBButton
            key={RBButtonType.cancel}
            buttonType={RBButtonType.cancel}
            onClick={() => {
              this.setState({ showModal: false })
              this.setState({ isCreateShiftSubmitInfo: false });
              
              this.props.history.push("/shift/detail/"+ newShift.id)
              //history.push(`${RoutePathConstant.ShiftDetail}${shift.id}`)
            }}
            style={{color:"white"}}
            text="View Shift"
            buttonBackgroundColor={this.context.primaryColor}
          />,
          <RBButton
          buttonBackgroundColor={this.context.primaryColor}
            key={"Discard"}
            onClick={() => {
              this.setState({ showModal: false })
              this.setState({ isCreateShiftSubmitInfo: false }, () =>
                this.props.history.push("/dashboard")
              );
            }}
            color="primary"
            text="Home"
          />,
        ],
      },
      isCreateShiftSubmitInfo: true,
    });
   
    } catch (err) {
      this.setState({ isSubmitting: false });
      const errorMessage = err.response ? err.response.data.message : err.message;
      this.setState({
        submitErrorMessage: errorMessage,
      });
      console.log("create shift on submit err", JSON.stringify(err, null, 2));
    } finally {
      this.setState({ isSubmitting: false });
    }
  };
  renderCreateShiftActions = () => {
    const { currentCreateShiftState,isSubmitting } = this.state;
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          alignItems: "flex-end",
        }}
      >
        {currentCreateShiftState === CreateShiftState.Create && (
          <RBButton
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
            }}
            buttonBackgroundColor={this.context.secondaryColor}
            onClick={() => {
              if (this.isWageValid()) {
                this.setState({
                  currentCreateShiftState: CreateShiftState.Confirm,
                });
              }
            }}
            disabled={!this.isCreateShiftFormValid()}
            text="Create Shift"
          />
        )}
        {currentCreateShiftState === CreateShiftState.Confirm && (
          <>
            <RBButton
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
              buttonBackgroundColor={this.context.secondaryColor}
              onClick={() => {
                this.setState({
                  currentCreateShiftState: CreateShiftState.Create,
                });
              }}
              
              //disabled={true}
              text="Cancel"
            />
            <RBButton
              style={{
                display: "flex",
                flex: 1,
                padding: Spacing.medium,
                margin: Spacing.large,
              }}
              buttonBackgroundColor={this.context.secondaryColor}
              onClick={this.onSubmittingCreateShift}
              disabled={!this.isPreviewFormValid()}
              isLoading={isSubmitting}
              text="Confirm"
            />
          </>
        )}
      </div>
    );
  };
  rendersignatureContent = (signature:any) => {
    console.log("signature",signature)
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          userSelect:"none",
          pointerEvents:"none"

        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            <PDFViewer pdfUrl={signature} title="signature"></PDFViewer>
          </RBCardView>
       
      </div>
    );
  };
  render() {
    const {
      showModal,
      isDiscardAlertShowing,
      isCreateShiftSubmitInfo,
      discardAlertSInfo,
      currentCreateShiftState,
      isPageLoading,
      showContract,
      networkId,
      workerType,
      pharmacyName,
      wage,
      kmAllowance,
      accomodationAllowance,
      createShiftSubmitInfo,
      isAlertShowing,
      alertInfo,
      resumeModal,
      pharmacyId
    } = this.state;

    const currentDate = moment().format('MMM-DD-YYYY');
    const currentYear = moment().year();

    return (
      <>
       <RBAlert
          show={isAlertShowing}
          alertTitle={alertInfo.title}
          alertMessage={alertInfo.message}
          buttons={alertInfo.buttons}
        />
        <RBErrorAlert
          show={this.state.submitErrorMessage ? true : false}
          errorMessage={JSON.stringify(this.state.submitErrorMessage, null, 2)}
          onClose={() => {
            this.setState({ submitErrorMessage: undefined });
          }}
        />
       
        
        <RBAlert
          show={isDiscardAlertShowing}
          alertTitle={discardAlertSInfo.title}
          alertMessage={discardAlertSInfo.message}
          buttons={discardAlertSInfo.buttons}
        />
        <RBAlert
          show={isCreateShiftSubmitInfo}
          alertTitle={createShiftSubmitInfo.title}
          alertMessage={createShiftSubmitInfo.message}
          buttons={createShiftSubmitInfo.buttons}
        />


        {showContract && <RBShiftAgreementModal
          open={showContract}
          onClose={this.onContractClose}
          modalTitle="Shift Contract"
          pageName="contract-pharmacy"
          pageType="page"
          networkId = {networkId}
          locationId = {pharmacyId}
          type = 'create'
          substitutions={{
            currentDate,
            currentYear,
            endTime: 'endTime',
            pharmacistDetails: formatWorkerType(workerType as workerTypeEnum),
            pharmacyDetails: pharmacyName,
            shiftNumber: `xxxx-${currentYear}-x-xx`,
            startTime: 'startTime',
            wage: `$${wage}`,
            pharmacistSignature: '',
            pharmacySignature: '',
            licenseNumber: '',
            accredidationNumber: '',
            pharmacistName: workerType,
            pharmacyName: pharmacyName,
            reliefBuddySignature: '',
            workerType: formatWorkerType(workerType as workerTypeEnum),
            kmAllowance: kmAllowance
              ? kmAllowance
              : 0,
            accomodationAllowance: accomodationAllowance
              ? accomodationAllowance
              : 0,
          }}
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
        />}

        <RBModal
          open={showModal}
          onClose={this.onClose}
          modalTitle={
            currentCreateShiftState === CreateShiftState.Create
              ? "Create Shift"
              : "Confirm Shift"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
          actions={!isPageLoading && this.renderCreateShiftActions()}
          children={
            isPageLoading ? (
              <LoadingIndicator />
            ) : (
              this.renderCreateShiftContent()
            )
          }
        />
      </>
    );
  }
}
