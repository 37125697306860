import React, { useState } from "react";
import { RBCardView } from "../../components/RBCardView";
import { Spacing } from "../../theme/Theme";
import { useHistory } from "react-router";
import { RoutePathConstant, roleNameEnum } from "../../util/Common";
import { AuthService } from "../../api/AuthService";
import { Typography } from "@material-ui/core";

export const SettingPage = () => {
  const history = useHistory();
  return (
    <>
      {AuthService.getRoleName() === roleNameEnum.owner && <></>}
      {AuthService.getRoleName() === roleNameEnum.pharmacist && (
        <>
          <Typography variant="h6" style={{ marginLeft: "3%", marginTop:"3%" }}>
              Account Settings
          </Typography>
          
          <RBCardView
            clickable={true}
            onClick={() => history.push(RoutePathConstant.SINBusinessSetting)}
            marginHorizontal={Spacing.large}
            style={{ marginTop: Spacing.large, padding: Spacing.large }}
            children={<div>SIN/Business Registration Settings</div>}
          />

          {/* <RBCardView
            marginHorizontal={Spacing.large}
            style={{ marginTop: Spacing.large, padding: Spacing.large }}
            children={<div>Payment System Settings</div>}
          /> */}
        </>
      )}
    </>
  );
};
