import React from "react";
import { Formik, Form } from "formik";
import { Schema } from "../YupSchema";
import {
  RBTextFieldForForm,
  RBSelectForForm,
  RBTextFieldForFormFieldType,
} from "../../../components/RBTextField";
import { Spacing } from "../../../theme/Theme";
import { CanadaProvinces } from "../../../util/Common";

export interface PharmacistInformationValueTypes {
  registrationNumber: string;
  provinceOfRegistration: string;
  graduationYear: string;
  schoolName: string;
  licensedDate: string;
}

interface PharmacistInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: PharmacistInformationValueTypes;
  account?:any;
}

export const PharmacistInformation: React.FC<PharmacistInformationProps> = ({
  ...props
}) => {
  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={Schema.PharmacistInformation}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={Schema.PharmacistInformation.isValidSync(
        props.initialValues
      )}
      onSubmit={() => {}}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          <RBTextFieldForForm
            label="First year licensed in Canada (YYYY-MM)"
            FieldType={RBTextFieldForFormFieldType.yearMonth}
            placeholder="Required"
            name="licensedDate"
            value={values.licensedDate}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Registration Number"
            placeholder="Required"
            name="registrationNumber"
            value={values.registrationNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBSelectForForm
            label="Province of Registration"
            name="provinceOfRegistration"
            value={values.provinceOfRegistration}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={"Province"}
            mappingValues={CanadaProvinces.map(
              (province: any) => province.abbr
            )}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            FieldType={RBTextFieldForFormFieldType.yearGraduation}
            label="Year of Graduation"
            placeholder="Required"
            name="graduationYear"
            value={values.graduationYear}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Institution Name"
            placeholder="Required"
            name="schoolName"
            value={values.schoolName}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
        </Form>
      )}
    </Formik>
  );
};
