import React from "react";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { Spacing, Colors, FontSize } from "../../../theme/Theme";
import { RenderTextWithTitle } from "../../../components/TextWithHeader";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import formatService from "../../../util/FormattingService";
import { RBMap } from "../../../components/RBMap";

interface PharmacyInformationProps {
  shiftData: ObjectLiteral;
  pharmacy:ObjectLiteral;
  owner:boolean;
  showPartialInfo:boolean;
  account:ObjectLiteral;
  page:string;
  applied?:Boolean;
}

const NoPhaymacyInformation = () => {
  return (
    <div>
      <div className={"section-header-text"}>Pharmacy Information</div>
      <div>
        <div>
          You&apos;ll be able to div the pharmacy information after you are {}
          confirmed for the shift.
        </div>
      </div>
      <div
        style={{
          height: 1,
          backgroundColor: Colors.defaultGrey,
          marginTop: Spacing.medium,
          marginBottom: Spacing.medium,
        }}
      />
    </div>
  );
};

export const PharmacyInformation: React.FC<PharmacyInformationProps> = ({
  ...props
}) => {
  const { shiftData ,page,account,showPartialInfo, applied} = props;
  const workerNetworkId = account.networks[0].id;
  const pharmacy = shiftData.pharmacy;
  const owner = shiftData.creator ? shiftData.creator.owner : false;


  const { address } = pharmacy;
  const coordinate = {
    latitude: address.coordinate.coordinates[1],
    longitude: address.coordinate.coordinates[0],
  };
  const addressFormat = address.apartmentSuiteNumber
    ? address.apartmentSuiteNumber +
      " - " +
      address.streetAddress +
      "\n" +
      address.city +
      " " +
      address.province +
      "\n" +
      address.postalCode
    : address.streetAddress +
      "\n" +
      address.city +
      " " +
      address.province +
      "\n" +
      address.postalCode;

  const addressFormatWithOutStreet = address.city +
      ' ' +
      address.province +
      '\n' +
      address.postalCode;
  const blurAddress = address.apartmentSuiteNumber
      ? address.apartmentSuiteNumber +
        ' - ' +
        address.streetAddress +
        '\n'
      : address.streetAddress +
        '\n';

  if (!shiftData.pharmacy) {
    return <>{NoPhaymacyInformation()}</>;
  }
  if (showPartialInfo) {
    return (
      <div>
        <div className={"section-header-text"}>Pharmacy Information</div>
        <div style={ColumnStyle}>
          <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowWrap: "anywhere",
            alignItems: "flex-start",
            alignContent: "flex-start",
            justifyContent: "flex-start",
            width: 100
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              overflowWrap: "anywhere",
              fontWeight: "bold",
              fontSize: FontSize.large,
            }}
          >
            Pharmacy
          </div>

          {page == 'applyJob' && applied == false  ? 
          <div
          style={{
           filter:'blur(3px)'
          }}
        >
          {pharmacy.name}
        </div>
         :
         <div
         style={{
           display: "flex",
           flex: 1,
           whiteSpace: "pre-wrap",
           overflowWrap: "anywhere",
           fontSize: FontSize.large,
         }}
       >
         {pharmacy.name}
       </div>
           
}
        </div>
          {page && page == "applyShift" ?
          (account.networks[0].isTrustNetwork === true  &&
            account.pharmacist.approval?.rbVerified === true &&
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflowWrap: "anywhere",
                alignItems: "center",
                alignContent: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  overflowWrap: "anywhere",
                  fontSize: FontSize.default,

                }}
              >
                {pharmacy?.networkId === workerNetworkId ? 'In-Network' : 'Out-of-Network'}
              </div>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  whiteSpace: "pre-wrap",
                  overflowWrap: "anywhere",
                  fontWeight: "bold",
                  fontSize: FontSize.large,
                }}
              >
              <img
                src={
                  pharmacy?.networkId === workerNetworkId ?
                    require('../../../img/50x50-01.png')
                      : require('../../../img/100x100-02-01.png')
                }

                style={{
                  height: 50,

                }}
              />
              </div>
            </div>) : null
          }
      </div>
      <div style={ColumnStyle}>
        {page == 'applyJob' && applied == false  ? 
        <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflowWrap: "anywhere",
          alignItems: "flex-start",
          alignContent: "flex-start",
          justifyContent: "flex-start",
          width:100
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            overflowWrap: "anywhere",
            color: Colors.darkGrey,
            fontSize: FontSize.default,
          }}
        >
          Pharmacy Address
        </div>
  
        <div
          style={{
            display: "flex",
            flex: 1,
            whiteSpace: "pre-wrap",
            overflowWrap: "anywhere",
            fontSize: FontSize.large,
            filter:'blur(3px)'
          }}
        >
          {blurAddress}
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            whiteSpace: "pre-wrap",
            overflowWrap: "anywhere",
            fontWeight: "bold",
            fontSize: FontSize.large,
          }}
        >
          {addressFormatWithOutStreet}
        </div>
      </div>
        
        : 
        RenderTextWithTitle("Pharmacy Address",  addressFormat)}
        {page == 'applyJob' && applied == false  ?
        <RBMap
          style={{
            display: "flex",
            flex: 1,
            height: 150,
            marginTop: Spacing.medium,
          }}
          lat={coordinate.latitude}
          lng={coordinate.longitude}
          markerFlag={true}
        />:
        <RBMap
        style={{
          display: "flex",
          flex: 1,
          height: 150,
          marginTop: Spacing.medium,
        }}
        lat={coordinate.latitude}
        lng={coordinate.longitude}
      />}
      </div>
   
        <div
          style={{
            height: 1,
            backgroundColor: Colors.defaultGrey,
            marginTop: Spacing.medium,
            marginBottom: Spacing.medium,
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <div
        className="section-header-text"
        style={{
          paddingBottom: Spacing.small,
          marginBottom: Spacing.smallest,
        }}
      >
        Pharmacy Information
      </div>
      <div style={ColumnStyle}>
        {RenderTextWithTitle("Pharmacy", pharmacy.name)}
        {RenderTextWithTitle(
          "Pharmacy Phone",
          formatService.formatPhoneNumber(pharmacy.phone)
        )}
      </div>
      <div style={ColumnStyle}>
        {RenderTextWithTitle("Pharmacy Address", addressFormat)}
        <RBMap
          style={{
            display: "flex",
            flex: 1,
            height: 150,
            marginTop: Spacing.medium,
          }}
          lat={coordinate.latitude}
          lng={coordinate.longitude}
        />
      </div>
      <div style={ColumnStyle}>
        {RenderTextWithTitle(
          "Pharmacy Manager",
          pharmacy.contactFirstName + " " + pharmacy.contactLastName
        )}
        {RenderTextWithTitle(
          "Manager Phone",
          formatService.formatPhoneNumber(pharmacy.contactPhone)
        )}
      </div>
      <div style={ColumnStyle}>
        {RenderTextWithTitle(
          "Primary Contact",
          owner.firstName + " " + owner.lastName
        )}
        {RenderTextWithTitle(
          "Primary Contact Phone",
          formatService.formatPhoneNumber(owner.phone)
        )}
      </div>
      <div
        style={{
          height: 1,
          backgroundColor: Colors.defaultGrey,
          marginTop: Spacing.medium,
          marginBottom: Spacing.medium,
        }}
      />
    </div>
  );
};
