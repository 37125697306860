import React, { useState, useEffect ,useContext} from "react";

import { useLocation, useHistory } from "react-router";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum, formatPaymentType, paymentTypeEnum } from "../../api/constants";
import { Button, Card, CardContent, createStyles, makeStyles } from "@material-ui/core";
import { AuthService } from "../../api/AuthService";
import { Colors, Font, FontSize, FontWeight, Spacing, UIRelatedConstants } from "../../theme/Theme";
import { CreditCard, AccountBalance } from "@material-ui/icons";
import { RBAppbar } from "../../components/RBAppbar";


interface PaymentPageProps {
    account?: any;
    paymentSetup?: any;
    editMode?:any;
    newPharmacy?:any;
}

interface LocationState {
    account?: string;
    editMode?: boolean;
    newPharmacy?: boolean;
  }

export const AddmultiplelocationsPayment: React.FC<PaymentPageProps> = ({
    ...props
  }) => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { account, editMode, newPharmacy } = location.state || {};

  const { industryMode, primaryColor, secondaryColor } = useContext(RBContext);
  const [locationsVisible, setLocationsVisible] = useState(false);
  const [locationArr, setLocationArr] = useState<any>([]);
  const [selectedLoc, setSelectedLoc] = useState(null);
  const [newPaymentType, setNewPaymentType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pharmacyDetails, setPharmacyDetails] = useState<any>([]);

  const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textTransform: "none",
      // color: Colors.black,
      "&:hover": {
        color: Colors.themeBlue,
        opacity: 1,
      },
      "&$selected": {
        color: Colors.black,
        fontWeight: "600",
      },
      "&:focus": {
        color: Colors.black,
      },
    },
    selected: {
      //color: Colors.themeBlue,
    },
    indicator: {
      backgroundColor: Colors.black,
    },
    sectionHeader: {
      fontSize: Font.largest, fontWeight:"bold"
      //  backgroundColor: "red",
    },
    Card: {
      // "&:hover": {
      //   backgroundColor: "#E5E5E5",
      // },
    },
    CardContent: {
      paddingTop: 8,
      "&:last-child": {
        paddingBottom: 8,
      },
    },
    button: {
      borderRadius: UIRelatedConstants.ButtonBorderRadius,
      borderWidth: 1,
      backgroundColor: "#FFF",
      "&:hover": {
        backgroundColor: "#CDCDCD",
      },
    },
    // Custom radio button styles
    radioContainer: {
        position: 'relative',
        padding: '10px', // Adjust padding as needed
        cursor: 'pointer',
      },
      radioInput: {
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
      },
      radioCheckmark: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        width: '20px', // Adjust the size of the tick mark
        height: '20px', // Adjust the size of the tick mark
        border: '2px solid #000', // Set the tick mark color
        borderTop: 'none',
        borderRight: 'none',
        boxSizing: 'border-box',
      },
      radioChecked: {
        backgroundColor: '#000', // Set the tick mark color when checked
      },
  })
  );

  const classes = useStyles();
  
  useEffect(() => {
    (async () => {

    let accountData = props.account? props.account : account;
      let pharmaDetails = await AuthService.fetchPharmacy(accountData.owner.defaultPharmacyId);
      setPharmacyDetails(pharmaDetails)
      setSelectedLoc(pharmaDetails)

      let selectedLocation:any;
      selectedLocation = pharmaDetails;
      const filteredLocations = accountData.pharmacies.filter((location:any) => location.id !== selectedLocation.id);
      const reorderedLocations = [selectedLocation, ...filteredLocations];

      reorderedLocations.forEach((value, index) => {
        reorderedLocations[index].checked = false;
        if(reorderedLocations[index] === selectedLocation){
          reorderedLocations[index].checked = true;
        }
      });

      setLocationArr(reorderedLocations)
      if(!editMode)
      {
        setNewPaymentType(true)
      }
      
    })();

  }, [])
  useEffect(() => {
    (async () => {
      if(props?.account?.newPharmacy  === true || newPharmacy === true)
      {

        let accountData = props.account? props.account : account;
        setSelectedLoc(accountData.pharmacies[accountData.pharmacies.length-1])
        const selectedLocation = accountData.pharmacies[accountData.pharmacies.length-1];
        const filteredLocations = accountData.pharmacies.filter((location:any) => location !== selectedLocation);
        const reorderedLocations = [selectedLocation, ...filteredLocations];

        reorderedLocations.forEach((value, index) => {
          reorderedLocations[index].checked = false;
          if(reorderedLocations[index] === selectedLocation){
            reorderedLocations[index].checked = true;
          }
        });

        setNewPaymentType(true)
        setLocationArr(reorderedLocations)
      }
    })();

  }, [props?.account?.newPharmacy || newPharmacy])




  const handleRadioButtonChange = (index:number) => {
    setSelectedLoc(locationArr[index]);
  }
  const choosePaymentOption = (type:any) =>{
    const { account } = props;
    let editMode = type == 'add' ? false : true;
    history.push('/paymentOption',{
        editMode: editMode,
        selectedLoc:selectedLoc
      });
  }

  const renderLocations = () =>{
    return(
      <div style={{ flex: 1 }}>
              {locationArr.map((location:any, index:number) => {
                let defaultPayment = null;
                if (location.paymentType && location.paymentType === paymentTypeEnum.creditCard) {
                  if (location.creditCards && location.creditCards.length > 0) {
                    defaultPayment = location.creditCards.find((x:any) => x.category == 'shift');
                  }
                }
                else if (location.paymentType && location.paymentType === paymentTypeEnum.pad) {
                  if (location.bankAccounts && location.bankAccounts.length > 0) {
                    defaultPayment = location.bankAccounts.find((x:any) => x.isDefault);
                  }
                }
                else if (location.paymentType && location.paymentType === paymentTypeEnum.invoice) {
                  defaultPayment = formatPaymentType(paymentTypeEnum.invoice)
                } else {

                }
                return (
                    
                <div
                style={{
                  width: "100%",
                  paddingLeft: Spacing.large,
                  paddingRight: Spacing.large,
                  paddingBottom: Spacing.medium,
                  paddingTop: Spacing.large
                }}
                onClick={() => handleRadioButtonChange(index)}
              >
                <Card
                  variant="outlined"
                  className={classes.Card}
                  style={{
                    borderRadius: UIRelatedConstants.CardBorderRadius,
                    borderWidth: 3,
                    borderColor: primaryColor,
                  }}
                >
                     <CardContent className={classes.CardContent}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingTop: 10,
                            justifyContent: "space-between",
                            minHeight: 45,
                        }}
                    >
                        <div style={{ justifyContent: "center" }}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {locationArr.length > 1 &&
                                     <label>
                                        <input
                                        type="radio"
                                        id={`paymentOptionId-${index}`}
                                        name="paymentOption"
                                        value={index}
                                        checked={selectedLoc === locationArr[index]}
                                        required
                                        onChange={() => handleRadioButtonChange(index)}
                                        className={classes.radioInput}
                                        style={{ display: 'none' }}
                                    />
                                     <div style={{color:secondaryColor,fontSize:18, fontWeight:"bold"}}>
                                        {selectedLoc === locationArr[index] ? '✓' : ''}
                                    </div>
                                    </label>}

                                <label htmlFor={`paymentOptionId-${index}`} style={{ marginLeft: 8, fontWeight: 'bold', fontSize: 16, paddingLeft: locationArr.length > 1 ? 0 : Spacing.medium }}>
                                    {location.name}
                                </label>
                            </div>
                        </div>

                        <div
                            style={{ alignItems: "flex-end" }}

                        >
                            <div>
                                {defaultPayment == null ?
                                    <Button
                                        className={classes.button}
                                        disabled={selectedLoc !== locationArr[index]}
                                        variant="outlined"
                                        style={{
                                            borderColor: secondaryColor,
                                            color: Colors.white,
                                            textTransform: "none",
                                            background: secondaryColor
                                        }}
                                        onClick={() => choosePaymentOption('add')}
                                    >
                                        {"Add"}
                                    </Button> :
                                    <Button
                                        className={classes.button}
                                        disabled={selectedLoc !== locationArr[index]}
                                        variant="outlined"
                                        style={{
                                            borderColor: secondaryColor,
                                            color: Colors.white,
                                            textTransform: "none",
                                            background: secondaryColor
                                        }}
                                        onClick={() => choosePaymentOption('edit')}
                                    >
                                        {"Edit"}
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                     
                    {defaultPayment &&
                    <div style={{ marginLeft: 50,marginBottom:15 }}>
                      {locationArr[index].paymentType === paymentTypeEnum.pad &&
                        <>
                          <div style={{ display: 'flex',flexDirection: 'row', alignItems: 'center',marginBottom:4 }}>
                            <AccountBalance />
                            <div style={{ flex: 1, fontWeight: 'bold' , paddingLeft: 12}}>
                              {formatPaymentType(paymentTypeEnum.pad)}
                            </div>
                          </div>

                          <div style={{ paddingLeft: 34 }}>
                            Bank: {defaultPayment.bankName}
                          </div>
                          <div style={{ marginTop: 4, paddingLeft: 34 }}>
                            Acc No: XXXX XXXX {defaultPayment.accountNumberLastFour}
                          </div>
                        </>}
                     {(locationArr[index].paymentType === paymentTypeEnum.creditCard) &&
                        <>
                           <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
                            <CreditCard />
                            <div style={{ flex: 1, fontWeight: 'bold', paddingLeft: 10 }}>
                              {formatPaymentType(paymentTypeEnum.creditCard)}
                            </div>
                          </div>

                          <div style={{  paddingLeft: 34, }}>
                            {defaultPayment.type} ending with {defaultPayment.lastFourDigits}
                          </div>
                          <div style={{ marginTop: 4, paddingLeft: 34 }}>
                            Expires: {defaultPayment.expiryMonth}/{defaultPayment.expiryYear}
                          </div>
                        </>
                      }
                      {locationArr[index].paymentType === paymentTypeEnum.invoice &&
                        <>
                          <div style={{ display: 'flex',flexDirection: 'row', alignItems: 'center',marginBottom:4 }}>
                          <AccountBalance />
                            <div style={{ flex: 1,  fontWeight: 'bold', paddingLeft: 12, }}>
                            {formatPaymentType(paymentTypeEnum.invoice)}
                            </div>
                          </div>
                        </>
                      }

                    </div>}
                    </CardContent>

                  </Card>
                  </div>
                )
              })}
            </div>
    )
  }
  const renderContent = () => {
    const { account } = props;
    return (
      <>
      {!props.paymentSetup ? 
        <div>
        <div style={{ padding: 16 }}>
          <div
            style={{ flex: 1, flexDirection: 'row', alignContent: 'center' }}
          >

            {renderLocations()}
          </div>
        </div>
      </div>
      :
      <div>
        {renderLocations()}
      </div>

      }
      </>
    );
  }

  
  return (

      <div style={{ flex: 1 }}>
          {!props.paymentSetup ?

              <div>
                   <RBAppbar
                    deeperLevel={true}
                    barName={"Choose Location to Set-up Payment"}
                  />
                  {renderContent()}
              </div>
              :
              renderContent()

          }
      </div>
  );
}
