import React, { useState, useEffect ,useContext} from "react";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { Spacing, Colors, FontSize } from "../../theme/Theme";
import {
  useLocation,
  useHistory,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import { RBCardView } from "../../components/RBCardView";
import moment from "moment-timezone";
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBButton } from "../../components/RBButton";
import { ShiftService } from "../../api/ShiftService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { formatWorkerType } from "../../util/FormattingService";
import {
  detailKmAllowanceIcon,
  detailAccomodationAllowanceIcon,
  techOnSiteIcon,
  assistantOnSiteIcon,
} from "../../util/Icons";
import { Typography, Checkbox, Link } from "@material-ui/core";
import { RoutePathConstant, NotAvailableString, EmptyString } from "../../util/Common";
import {
  RenderTextWithTitle,
  titleHeaderTextStyle,
  detailTextStyle,
  renderTextWithTitleAndChildComponent,
  renderTextWithTitleForSkills,
} from "../../components/TextWithHeader";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { LabelText } from "../../util/TextConstant";
import { workerTypeEnum, HelperPageTitleEnum } from "../../util/Enumeration";
import { RBExternalHelpLink } from "../../components/RBExternalHelpLink";
import { AuthService } from "../../api/AuthService";
import { RBAlert, RBErrorAlert } from "../../components/RBAlert";
import { ErrorMessageProps } from "../../interfacesProps/ErrorMessageProps";
import NotificationService, {
  NotificationServiceTopic,
} from "../../api/NotificationService";
import { RBContext } from '../../components/RBContext';
import { PharmacyInformation } from "../shiftDetail/components/PharmacyInformation";
import { RBShiftAgreementModal } from "../../components/RBShiftAgreementModal";
import { RBDropFile } from "../../components/RBDropFile";
import { RBMoneyAmountTextField, RBTextField } from "../../components/RBTextField";
import { IoIosCash } from "react-icons/io";
import { IndustryModeEnum } from "../../api/constants";
import { DentalPracticeInfo } from "../shiftDetail/components/DentalPracticeInfo";
import { RequestedRatetDto } from "../../dtos/RequestedRate.dto";

import { defaultAllowOffersIcon } from '../../components/RBShiftRelatedIcons';
import { AlertInfoProps } from "../../interfacesProps/AlertInfoProps";
import PDFViewer from "../../components/PdfViewer";
import SignaturePad  from "../../components/SignaturePad"
interface ApplyShiftPageProps
  extends RouteComponentProps<{ shiftId: string }> {}




const ApplyShiftPageWithoutRouter: React.FC<ApplyShiftPageProps> = ({
  match,
}) => {
  const { primaryColor,secondaryColor, industryMode } = useContext(RBContext);
  const location = useLocation<{ background: string }>();
  const history = useHistory();
  const [showModal, setShowModal] = useState(true);

  const [searchedShiftDetail, setSearchedShiftDetail] = useState<ObjectLiteral>(
    {}
  );
  const [isLoading, setIsLoading] = useState(true);
  const [agreeTOS, setAgreeTOS] = useState(false);
  const [totalEarning, setTotalEarning] = useState(NotAvailableString);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [account, setAccount] = useState<any>([]);

  const [showContract,setShowContract] = useState(false);
  const [showTermOfService,setshowTermOfService] = useState(false);
  const [defaultSignature,setDefaultSignature] = useState('');

  const [isNewSignatureAvailable,setIsNewSignatureAvailable] = useState(false);
  const [isDefaultSignatureShown,setIsDefaultSignatureShown] = useState(false);
  const [biddingVisible,setBiddingVisible] = useState(false);
  const [newFile, setNewFile] = useState<File>();
  const [biddingRate,setBiddingRate] = useState('');
  const [wageDisplayValue,setwWgeDisplayValue] = useState<any>(null);
  const [wageRequest,setwageRequest] = useState<any>("0");

  const[ isWageValid,setIsWageValid] = useState(false);
  
  const [isAlertShowing, setAlertShowing] = useState(false);
  const [applyAlertInfo, setApplyAlertInfo] = useState<AlertInfoProps>({});
  const [errorMessage, setErrorMessage] = useState(EmptyString);
  const [resumeModal, setReumeModal] = useState(false);


  useEffect(() => {
    localStorage.removeItem("workerSign")
    fetchShiftDetailByShiftID(match.params.shiftId);
  }, []);
  const fetchShiftDetailByShiftID = async (shiftId: string) => {
    try {
      setIsLoading(true);
      const userData = await AuthService.loadAccountInfo();
      setAccount(userData);
      setDefaultSignature(userData.pharmacist.signature);
      const shiftDetail = await ShiftService.searchShiftDetailById(shiftId);
      console.log("ApplyShiftPage ", shiftDetail);
      setSearchedShiftDetail(shiftDetail);
      setTotalEarning(ShiftService.getTotalEarningForApplyShift(shiftDetail,userData));
    } catch (err) {
      setSubmitErrorMessage(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const setShowTermOfService = (showTermOfService:boolean) =>
  setshowTermOfService(showTermOfService);


  const onTermsClose = () =>{
    setshowTermOfService(false);
  }
  const onResumeClose= () => {
    setReumeModal(false)
   };

  const renderKMAllowance = (primaryColor:string) => {
    const { kmAllowance } = searchedShiftDetail;
    return (
      <div style={{ flex: 1, alignItems: "flex-start" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            ...titleHeaderTextStyle,
          }}
        >
          {detailKmAllowanceIcon(primaryColor)}
          <div
            style={{
              marginLeft: Spacing.smallest,
            }}
          >
            KM Allowance
          </div>
        </div>

        <div style={detailTextStyle}>
          {"$" + (kmAllowance / 100).toFixed(2) + "/km"}
        </div>
      </div>
    );
  };
  const   checkForm = (wage:any) => {
    if(wage !== null){
      return !(
        agreeTOS &&
        (newFile || defaultSignature) &&   wage !== "0.00"
      );
    }
    else{
      return !(
        agreeTOS &&
        (newFile || defaultSignature)
      );
    }
    
  };

  const renderAccomodationAllowance = () => {
    const { accomodationAllowance } = searchedShiftDetail;
    return (
      <div style={{ flex: 1, alignItems: "flex-start" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            ...titleHeaderTextStyle,
          }}
        >
          {detailAccomodationAllowanceIcon(primaryColor)}
          <div
            style={{
              marginLeft: Spacing.smallest,
            }}
          >
            Allowance
          </div>
        </div>

        <div style={detailTextStyle}>
          {"$" + (accomodationAllowance / 100).toFixed(2)}
        </div>
      </div>
    );
  };

  const renderSearchedShiftDetail = () => {
    const startTime = moment(searchedShiftDetail.startTime).tz(
      searchedShiftDetail.shiftTimeZone
    );
    const endTime = moment(searchedShiftDetail.endTime).tz(
      searchedShiftDetail.shiftTimeZone
    );
    const startTimeFormat = startTime ? startTime.format("h:mm a") : "N/A";
    const endTimeFormat = endTime ? endTime.format("h:mm a") : "N/A";
    const hoursDisplay = ShiftService.getShiftLengthDisplayForOriginal(
      searchedShiftDetail
    );
    const dateString = moment(searchedShiftDetail.startTime).format(
      "ddd, MMM Do YYYY"
    );
    const wage =
    searchedShiftDetail.actualCommission ? (searchedShiftDetail.wage/ 100).toFixed(2) :(ShiftService.getWage(searchedShiftDetail, account) / 100).toFixed(2);
      console.log("wage",wage)
    const shiftDistance = Math.round(searchedShiftDetail.distance / 1000);
    const shiftCity = searchedShiftDetail.shiftCity;
    const softwareType =
    industryMode === IndustryModeEnum.Dentistry
      ? 'dentalSoftware'
      : 'software';

    const { software, specializations } = searchedShiftDetail.skills.reduce(
      (acc: any, value: any) => {
        if (value.type === softwareType) acc.software.push(value);
        else acc.specializations.push(value);
        return acc;
      },
      { software: [], specializations: [] }
    );
    const softwareValue =
      software.length > 0
        ? software.map((software: any) =>
            software.required
              ? software.name
              : `Not Required (${software.name})`
          )
        : [];
    const extraSkills =
      specializations.length > 0 ? specializations.map((s: any) => s.name) : [];
    const rxCount = searchedShiftDetail.expectedRxCount
      ? searchedShiftDetail.expectedRxCount
      : NotAvailableString;

    const kmAllowance = (searchedShiftDetail.kmAllowance / 100).toFixed(2);
    const accomodationAllowance = (
      searchedShiftDetail.accomodationAllowance / 100
    ).toFixed(2);
    const kmAllowanceAvailable = kmAllowance !== "0.00";
    const accomodationAllowanceAvailable = accomodationAllowance !== "0.00";

    console.log("apply shift ", kmAllowance, accomodationAllowance);
    const workerType = searchedShiftDetail.pharmacistType;
    const techOnSite = searchedShiftDetail.technicianOnSite;
    const assistOnSite = searchedShiftDetail.assistantOnSite;
    const comment = searchedShiftDetail.comment
      ? searchedShiftDetail.comment
      : NotAvailableString;
    const signature = account.pharmacist.signature;
    const dentalType =  searchedShiftDetail.dentalType;


    const contractPreviewClick = () =>{
      setShowContract(true);
    }

    const onContractClose = () =>{
      setShowContract(false);
    }
    const currentDate = moment().format('MMM-DD-YYYY');
    const currentYear = moment().year();
    const pharmacistDetails =
      account.pharmacist.firstName + ' ' + account.pharmacist.lastName;
    const DATE_FORMAT = 'MMM-DD-YYYY h:mm a';
    let associationFlag = false;
    let associationName = null;
    if(account?.userAssociationMember && account.userAssociationMember.length >0){
      associationFlag = true;
      associationName = account.userAssociationMember[0].association.associationName;
    }

    return (
      <>
        <RBAlert
          show={isAlertShowing}
          alertTitle={applyAlertInfo.title}
          alertMessage={applyAlertInfo.message}
          buttons={applyAlertInfo.buttons}
        />

        {showContract && <RBShiftAgreementModal
          open={showContract}
          onClose={onContractClose}
          modalTitle="Shift Contract"
          pageName="contract-pharmacist"
          pageType="page"
          networkId = {searchedShiftDetail.pharmacy.networkId}
          shiftId = {searchedShiftDetail.id}
          userId= { account.id }
          type = 'shift'
          substitutions={{
            currentDate,
            currentYear,
            endTime: moment(endTime).format(DATE_FORMAT),
            pharmacistDetails: pharmacistDetails,
            pharmacyDetails: '[Pharmacy]',
            shiftNumber: `xxxx-${currentYear}-x-xx`,
            startTime: moment(startTime).format(DATE_FORMAT),
            wage: `$${wage}/hr`,
            pharmacistSignature: '',
            pharmacySignature: '',
            licenseNumber: '',
            accredidationNumber: '',
            pharmacistName: pharmacistDetails,
            reliefBuddySignature: '',
            workerType: formatWorkerType(workerType),
            kmAllowance: kmAllowanceAvailable ? `$${kmAllowance}` : 0,
            accomodationAllowance: accomodationAllowanceAvailable
              ? `$${accomodationAllowance}`
              : 0,
          }}
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
        />}
        {
        industryMode === IndustryModeEnum.Dentistry ? 
            (<>
              <RBCardView
                marginHorizontal={Spacing.large}
                style={{
                  padding: Spacing.large,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <DentalPracticeInfo
                  pharmacy={searchedShiftDetail.pharmacy}
                  shiftData={searchedShiftDetail}
                  owner={false}
                  ownerEmail={''}
                  showPartialInfo={false}
                  account={account}
                  page="applyShift"
                />
              </RBCardView>
              <br></br>
              <RBCardView
                marginHorizontal={Spacing.large}
                style={{
                  padding: Spacing.large,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={ColumnStyle}>
                  {RenderTextWithTitle("Practice Type", dentalType)}
                </div>

                <div style={ColumnStyle}>
                  {RenderTextWithTitle("Date", dateString)}
                  {RenderTextWithTitle("Time", `${startTimeFormat} - ${endTimeFormat}`)}
                </div>
                <div style={ColumnStyle}>
                  {RenderTextWithTitle("Duration", hoursDisplay)}
                  
                  {/* <div style={{ flex: 1, alignItems: "flex-start" }}>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "center",
                        ...titleHeaderTextStyle,
                      }}
                    >

                      <div
                        style={{
                          marginLeft: Spacing.smallest,
                        }}
                      >
                        Hourly Rate
                      </div>
                      {searchedShiftDetail.allowOffers && defaultAllowOffersIcon(secondaryColor)}
                    </div>


                    <div style={detailTextStyle}>
                      {"$" + wage}
                    </div>

                  </div> */}
                </div>

                {(kmAllowanceAvailable || accomodationAllowanceAvailable) && (
                  <div style={ColumnStyle}>
                    {kmAllowanceAvailable && renderKMAllowance(primaryColor)}
                    {accomodationAllowanceAvailable && renderAccomodationAllowance()}
                  </div>
                )}
                {/* <div style={ColumnStyle}>
                  {RenderTextWithTitle("City", shiftCity)}
                  {RenderTextWithTitle("Distance", shiftDistance + "km")}
                </div> */}
                <div style={ColumnStyle}>
                  {RenderTextWithTitle("Worker Type", formatWorkerType(workerType))}
                  {RenderTextWithTitle("Distance", shiftDistance + "km")}
                </div>


                <div style={ColumnStyle}>
                  {renderTextWithTitleForSkills("Software", softwareValue)}

                  {renderTextWithTitleForSkills("Specializations", extraSkills)}
                </div>
                <div style={ColumnStyle}>{RenderTextWithTitle("Comment", comment)}</div>


                {defaultSignature ?
                  <div style={ColumnStyle}>
                    {renderTextWithTitleAndChildComponent("Signature", <Link
                      //href={signature}
                      onClick={() => setReumeModal(true)}
                      style={{ ...detailTextStyle, color: primaryColor, cursor: 'pointer' }}
                    >
                      {"View"}
                    </Link>)
                    }
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        overflowWrap: "anywhere",
                        alignItems: "flex-start",
                        alignContent: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          overflowWrap: "anywhere",

                          ...titleHeaderTextStyle,
                        }}
                      >
                        Shift Agreement
                      </div>

                      <Link
                        style={{
                          display: "flex",
                          flex: 1,
                          whiteSpace: "pre-wrap",
                          overflowWrap: "anywhere",
                          ...detailTextStyle,
                          color: primaryColor,
                          cursor: 'pointer'
                        }}
                        onClick={contractPreviewClick}
                      >
                        Preview
                      </Link>
                    </div>
                  </div>
                  :
                  (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          overflowWrap: "anywhere",
                          alignItems: "flex-start",
                          alignContent: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            overflowWrap: "anywhere",

                            ...titleHeaderTextStyle,
                          }}
                        >
                          Shift Agreement
                        </div>

                        <Link
                          style={{
                            display: "flex",
                            flex: 1,
                            whiteSpace: "pre-wrap",
                            overflowWrap: "anywhere",
                            ...detailTextStyle,
                            color: primaryColor,
                            cursor: 'pointer'
                          }}
                          onClick={contractPreviewClick}
                        >
                          Preview
                        </Link>
                      </div>

                      <SignaturePad
                        isVisible={true}
                        onLoad = {(file,dataURL) =>{
                          setNewFile(file)
                        }}

                        onSave={(file,dataURL) =>{
                        }}
                        onCancel={() =>{}}
                        onClear={() => {
                          localStorage.removeItem("workerSign")
                          setNewFile(undefined)
                        }}
                        userData = {account.pharmacist}
                        page="applyShift"
                      />
                      
                      <div style={{ fontSize: 10 }}>
                        *This signature will be saved in your profile as default for
                        future usage
                      </div>
                    </>

                  )
                }

                {(wage !== "0.00") &&
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: Spacing.large,
                    }}
                  >
                    <Checkbox
                      checked={agreeTOS}
                      style={{
                        padding: 0,
                        paddingRight: Spacing.large,
                        // Why marginLeft -2, so  it will align with regular label or text field
                        marginLeft: -2,
                        color: agreeTOS ? secondaryColor : Colors.darkGrey,
                      }}
                      onChange={(_, checked) => {
                        setAgreeTOS(checked);
                      }}
                    />
                    <Typography variant="body2">
                      I have read and understood the{" "}

                      <Link onClick={() => setShowTermOfService(true)} style={{ color: primaryColor, cursor: 'pointer',fontWeight:"bold" }}>{HelperPageTitleEnum.termOfService}
                        .</Link>
                    </Typography>
                  </div>
                }
                {(searchedShiftDetail.allowOffers && wage === "0.00") &&
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: Spacing.large,
                    }}
                  >
                    <Checkbox
                      checked={agreeTOS}
                      style={{
                        padding: 0,
                        paddingRight: Spacing.large,
                        // Why marginLeft -2, so  it will align with regular label or text field
                        marginLeft: -2,
                        color: agreeTOS ? secondaryColor : Colors.darkGrey,
                      }}
                      onChange={(_, checked) => {
                        setAgreeTOS(checked);
                      }}
                    />
                    <Typography variant="body2">
                      I have read and understood the{" "}

                      <Link onClick={() => setShowTermOfService(true)} style={{ color: primaryColor, cursor: 'pointer',fontWeight:"bold" }}>{HelperPageTitleEnum.termOfService}
                        .</Link>
                    </Typography>
                  </div>
                }
                {showTermOfService && <RBShiftAgreementModal
                  open={showTermOfService}
                  onClose={onTermsClose}
                  modalTitle={"Terms of Service"}
                  pageName="terms-of-use"
                  pageType="page"
                  networkId={searchedShiftDetail.pharmacy.networkId}
                  header={
                    <RBEmptyBlock
                      style={{
                        width: "100%",
                        height: Spacing.large,
                        backgroundColor: Colors.defaultGrey,
                        flexShrink: 0,
                      }}
                    />
                  }
                />
                }

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "flex-end",
                  }}
                >
                  {!searchedShiftDetail.allowOffers && wage!="0.00" && <RBButton
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: Spacing.medium,
                      margin: Spacing.large,
                    }}
                    buttonBackgroundColor={primaryColor}
                    onClick={onSubmit}
                    isLoading={isSubmitting}
                    disabled={checkForm(wage)}
                    text={`Total : $${totalEarning}  |  Apply`}
                  />
                  }
                </div>
                
                {searchedShiftDetail.allowOffers && <>
                  {wage!="0.00" && 
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "flex-end",
                      }}
                    >
                      <RBButton
                        style={{
                          display: "flex",
                          flex: 1,
                          padding: Spacing.medium,
                          marginBottom: 16,
                          marginTop: 16
                        }}
                        buttonBackgroundColor={primaryColor}
                        onClick={async () => await onBidShift(wage)}
                        isLoading={isSubmitting}
                        disabled={checkForm(wage) }
                        text={`Total : $${totalEarning}  |   Apply Now ($${wage}/hr)`}
                      />
                    </div>
                  }
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "flex-end",
                    }}
                  >
                    <RBButton
                      style={{
                        display: "flex",
                        flex: 1,
                        padding: Spacing.medium,
                        marginBottom: wage === "0.00" ? 16 : 0,
                        marginTop:  wage === "0.00" ? 16 : 0
                      }}
                      buttonBackgroundColor={secondaryColor}
                      onClick={() => setBiddingVisible(true)}
                      isLoading={isSubmitting}
                      disabled={checkForm(null)}
                      text={`Request Rate & Apply`}
                    />
                  </div>
                </>
                }
              </RBCardView>
            </>) :

            <>
              <RBCardView
                marginHorizontal={Spacing.large}
                style={{
                  padding: Spacing.large,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <PharmacyInformation
                  shiftData={searchedShiftDetail}
                  pharmacy={searchedShiftDetail.pharmacy}
                  owner={false}
                  showPartialInfo={true}
                  account={account}
                  page="applyShift"
                />
              </RBCardView>
              <br></br>
              <RBCardView
                marginHorizontal={Spacing.large}
                style={{
                  padding: Spacing.large,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={ColumnStyle}>
                  {RenderTextWithTitle("Date", dateString)}
                  {RenderTextWithTitle("Time", `${startTimeFormat} - ${endTimeFormat}`)}
                </div>
                <div style={ColumnStyle}>
                  {RenderTextWithTitle("Duration", hoursDisplay)}
                 
                   <div style={{ flex: 1, alignItems: "flex-start" }}>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "center",
                        ...titleHeaderTextStyle,
                      }}
                    >

                      <div
                        style={{
                          marginLeft: Spacing.smallest,
                        }}
                      >
                        Hourly Rate
                      </div>
                      {searchedShiftDetail.allowOffers && defaultAllowOffersIcon(secondaryColor)}
                    </div>


                    <div style={detailTextStyle}>
                      {"$" + wage}
                    </div>

                  </div>
                </div>

                {(kmAllowanceAvailable || accomodationAllowanceAvailable) && (
                  <div style={ColumnStyle}>
                    {kmAllowanceAvailable && renderKMAllowance(primaryColor)}
                    {accomodationAllowanceAvailable && renderAccomodationAllowance()}
                  </div>
                )}


                {associationFlag && <div
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderWidth: 1,
                    borderColor: "red",

                  }}
                >
                  <div style={{ flex: 1, alignItems: 'flex-start' }}>
                    <div
                      style={{
                        fontWeight: 'bold',
                        fontSize: Spacing.medium,
                        color: 'red',
                      }}
                    >
                      *The Hourly Rate excludes your {associationName} membership benefit which is added upon shift completion.
                    </div>
                  </div>
                </div>
                }



                <div style={ColumnStyle}>
                  {RenderTextWithTitle("City", shiftCity)}
                  {RenderTextWithTitle("Distance", shiftDistance + "km")}
                </div>
                <div style={ColumnStyle}>
                  {RenderTextWithTitle("Worker Type", formatWorkerType(workerType))}
                  {RenderTextWithTitle("Daily Avg Rx Count", rxCount)}
                </div>

                <div style={ColumnStyle}>
                  {renderTextWithTitleAndChildComponent(
                    LabelText.TECHNICIAN_ON_SITE,
                    techOnSiteIcon(techOnSite, secondaryColor)
                  )}
                  {workerType !== workerTypeEnum.assistant &&
                    renderTextWithTitleAndChildComponent(
                      LabelText.ASSISTANT_ON_SITE,
                      assistantOnSiteIcon(assistOnSite, secondaryColor)
                    )}
                </div>

                <div style={ColumnStyle}>
                  {renderTextWithTitleForSkills("Software", softwareValue)}

                  {renderTextWithTitleForSkills("Specializations", extraSkills)}
                </div>
                <div style={ColumnStyle}>{RenderTextWithTitle("Comment", comment)}</div>


                {defaultSignature ?
                  <div style={ColumnStyle}>
                    {renderTextWithTitleAndChildComponent("Signature", <Link
                      // href={signature}
                      onClick={() => setReumeModal(true)}
                      style={{ ...detailTextStyle, color: primaryColor, cursor: 'pointer' }}
                    >
                      {"View"}
                    </Link>)
                    }
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        overflowWrap: "anywhere",
                        alignItems: "flex-start",
                        alignContent: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          overflowWrap: "anywhere",

                          ...titleHeaderTextStyle,
                        }}
                      >
                        Shift Agreement
                      </div>

                      <Link
                        style={{
                          display: "flex",
                          flex: 1,
                          whiteSpace: "pre-wrap",
                          overflowWrap: "anywhere",
                          ...detailTextStyle,
                          color: primaryColor,
                          cursor: 'pointer'
                        }}
                        onClick={contractPreviewClick}
                      >
                        Preview
                      </Link>
                    </div>
                  </div>
                  :
                  (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          overflowWrap: "anywhere",
                          alignItems: "flex-start",
                          alignContent: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            overflowWrap: "anywhere",

                            ...titleHeaderTextStyle,
                          }}
                        >
                          Shift Agreement
                        </div>

                        <Link
                          style={{
                            display: "flex",
                            flex: 1,
                            whiteSpace: "pre-wrap",
                            overflowWrap: "anywhere",
                            ...detailTextStyle,
                            color: primaryColor,
                            cursor: 'pointer'
                          }}
                          onClick={contractPreviewClick}
                        >
                          Preview
                        </Link>
                      </div>

                      <SignaturePad
                        isVisible={true}
                        onLoad = {(file,dataURL) =>{
                          setNewFile(file)
                        }}

                        onSave={(file,dataURL) =>{
                        }}
                        onCancel={() =>{}}
                        onClear={() => {
                          localStorage.removeItem("workerSign")
                          setNewFile(undefined)
                        }}
                        userData = {account.pharmacist}
                        page="applyShift"
                      />
                      
                      <div style={{ fontSize: 10 }}>
                        *This signature will be saved in your profile as default for
                        future usage
                      </div>
                    </>

                  )
                }


                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: Spacing.large,
                  }}
                >
                  <Checkbox
                    checked={agreeTOS}
                    style={{
                      padding: 0,
                      paddingRight: Spacing.large,
                      // Why marginLeft -2, so  it will align with regular label or text field
                      marginLeft: -2,
                      color: agreeTOS ? secondaryColor : Colors.darkGrey,
                    }}
                    onChange={(_, checked) => {
                      setAgreeTOS(checked);
                    }}
                  />
                  <Typography variant="body2">
                    I have read and understood the{" "}

                    <Link onClick={() => setShowTermOfService(true)} style={{ color: primaryColor, cursor: 'pointer',fontWeight:"bold" }}>{HelperPageTitleEnum.termOfService}
                      .</Link>
                  </Typography>
                </div>
                {showTermOfService && <RBShiftAgreementModal
                  open={showTermOfService}
                  onClose={onTermsClose}
                  modalTitle={"Terms of Service"}
                  pageName="terms-of-use"
                  pageType="page"
                  networkId={searchedShiftDetail.pharmacy.networkId}
                  header={
                    <RBEmptyBlock
                      style={{
                        width: "100%",
                        height: Spacing.large,
                        backgroundColor: Colors.defaultGrey,
                        flexShrink: 0,
                      }}
                    />
                  }
                />
                }

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "flex-end",
                  }}
                >
                  {!searchedShiftDetail.allowOffers && <RBButton
                    style={{
                      display: "flex",
                      flex: 1,
                      padding: Spacing.medium,
                      margin: Spacing.large,
                    }}
                    buttonBackgroundColor={primaryColor}
                    onClick={onSubmit}
                    isLoading={isSubmitting}
                    disabled={checkForm(wage)}
                    text={`Total : $${totalEarning}  |  Apply`}
                  />
                  }
                </div>

                {searchedShiftDetail.allowOffers && <>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "flex-end",
                    }}
                  >
                    <RBButton
                      style={{
                        display: "flex",
                        flex: 1,
                        padding: Spacing.medium,
                        marginBottom: 16,
                        marginTop: 16
                      }}
                      buttonBackgroundColor={primaryColor}
                      onClick={async () => await onBidShift(wage)}
                      isLoading={isSubmitting}
                      disabled={checkForm(wage)  }
                      text={`Total : $${totalEarning}  |   Apply Now ($${wage}/hr)`}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "flex-end",
                    }}
                  >
                    <RBButton
                      style={{
                        display: "flex",
                        flex: 1,
                        padding: Spacing.medium,
                      }}
                      buttonBackgroundColor={secondaryColor}
                      onClick={() => setBiddingVisible(true)}
                      isLoading={isSubmitting}
                      disabled={checkForm(null)}
                      text={`Request Rate & Apply`}
                    />
                  </div>
                </>
                }




              </RBCardView>
            </>
        }
      </>
    );
  };

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      await createApplication();
    } catch (err) {
      const errorMessage = err.response ? err.response.data.error : err.message;
      setSubmitErrorMessage(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onBidShift = async (rate:any) => {
    const numberRate = (parseFloat(rate) * 100).toFixed(2);
    setBiddingRate(numberRate);
    await createApplication(numberRate);
    
  };


  const createApplication = async (requestedRate?:any) => {
   // const { allowOffers, biddingRate } = this.state;
    try {
      if (newFile) {
        await AuthService.updateWorker({ signature: newFile });
      }
      if (requestedRate == 0 || requestedRate == null) {

        if (biddingRate != null) {
          requestedRate = biddingRate
        }
      }
      setBiddingVisible(false);
      setIsSubmitting(true);

      if (searchedShiftDetail.allowOffers) {
        const payload: RequestedRatetDto = {
          requestedWage:requestedRate
        };

        
        const response = await ShiftService.requestShiftWithSignatureAndRate(searchedShiftDetail.id, payload);
       
        console.log(response)
      } else {
        await ShiftService.applyShiftById(searchedShiftDetail.id);

      }
      const employerName =
      industryMode === IndustryModeEnum.Dentistry
        ? 'dental practice'
        : 'pharmacy';

      setApplyAlertInfo({
        title: "Shift applied",
        message: `You will receive a notification if the ${employerName} chooses you. The shift will be removed from your ‘applied’ list if the ${employerName} chooses another applicant. 
        \n If you become unavailable for this shift, please withdraw your application in the shift details right away.`,
        buttons: [
         
          <RBButton
            key={"confirm"}
            onClick={async () => {
              setAlertShowing(false);
              history.goBack();
            }}
            buttonBackgroundColor={primaryColor}
            color="primary"
            text="Go Back"
          />,
        ],
      });
      setIsSubmitting(false);
      setAlertShowing(true);
      

      NotificationService.publish(
        NotificationServiceTopic.ShiftChanged,
        searchedShiftDetail.id
      );
     // history.goBack();
    } catch (err) {
      let errorMessage = err.response ? err.response.data.message : err.message;
      // if (errorMessage.includes("This shift has already started.")) {
      //   errorMessage = "This shift has already started."
      // }
      setSubmitErrorMessage(
        err.response ? err.response.data.message : err.message
      );
      if(err.response && err.response.data.message == "This shift has already started.")
      {
        setErrorMessage("This shift has already started.")
      }
      // setSubmitErrorMessage(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };


  const renderSearchedShiftDetailActions = () => {
    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          alignItems: "flex-end",
        }}
      >
        <RBButton
          style={{
            display: "flex",
            flex: 1,
            padding: Spacing.medium,
            margin: Spacing.large,
          }}
          buttonBackgroundColor={primaryColor}
          onClick={onSubmit}
          isLoading={isSubmitting}
          disabled={!agreeTOS}
          text={`Total : $${totalEarning}  |  Apply`}
        />
      </div>
    );
  };
  const renderSearchShiftContent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: Spacing.medium,
        }}
      >
        <pre>{JSON.stringify(searchedShiftDetail, null, 2)}</pre>
      </div>
    );
  };

  const onClose = () => {
    // goBackToPreviousLocation();
    history.goBack();
  };

  const onBidClose= () => {
    setwageRequest("0");
    setwWgeDisplayValue("0");
    setBiddingVisible(false)
  };

  const isWageValidFn = (wage:any) => {
    if (!wage){
      return false;
    }
    const lowerBound = 1;
    const wageAmount = parseFloat(wage.replace('$', ''));
    const result = wageAmount >= lowerBound && isFinite(wageAmount);
    setIsWageValid(result);
    return result;
  };


  const renderRequestedRateContent = () => {

    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
           <div>
           Please input the hourly rate you are requesting for this shift.{' '}
           </div>
           <br></br>
           <div>
           If your application is approved, you will be paid the hourly rate you have requested.{' '}
           </div>
           <br></br>
           <div>
      
           <RBMoneyAmountTextField
            name="currency"
            value={wageRequest}
            onChange={(event) =>
              {
                setwageRequest(event.target.value === "" ? "" : event.target.value)
                if (!wageRequest){
                  setIsWageValid(false);
                }
                const lowerBound = 1;
                const wageAmount = parseFloat(event.target.value);
                const result = wageAmount >= lowerBound && isFinite(wageAmount);
                setIsWageValid(result);
              }
            }
          />
           </div>
           <br></br>
           
           <div style={{
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}>
                <RBButton
                  buttonBackgroundColor={primaryColor}
                  disabled={!isWageValid}
                  isLoading={isSubmitting}
                  text="Apply"
                  onClick={() => onBidShift(wageRequest)}
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: Spacing.medium,
                    margin: Spacing.large,
                  }}
                />
                <RBButton
                  buttonBackgroundColor={primaryColor}
                  text="Cancel"
                  onClick={() => {
                    setwageRequest("0");
                    setwWgeDisplayValue("0");
                    setBiddingVisible(false);
                    }
                  }
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: Spacing.medium,
                    margin: Spacing.large,
                  }}
                />
           </div>
          </RBCardView>
       
      </div>
    );
  };
  const rendersignatureContent = () => {

    return (
      <div
        style={{
          backgroundColor: Colors.defaultGrey,
          display: "flex",
          flex: 1,
          userSelect:"none",
          pointerEvents:"none"

        }}
      >
        
          <RBCardView
            style={{
              flex: 1,
              marginLeft: Spacing.large,
              marginRight: Spacing.large,
              marginBottom: Spacing.smallest,
              paddingLeft: Spacing.large,
              paddingTop: Spacing.large,
              paddingRight: Spacing.large,
              paddingBottom: Spacing.large,
            }}
          >
            <PDFViewer pdfUrl={defaultSignature} title="signature"></PDFViewer>
          </RBCardView>
       
      </div>
    );
  };

  const goBackToPreviousLocation = () => {
    if (location.state && location.state.background) {
      setShowModal(false);
      history.goBack();
    } else {
      setShowModal(false);
      history.push("/dashboard");
    }
  };
  return (
    <>
      {/* <RBAlert
            show={isDiscardAlertShowing}
            alertTitle={discardAlertSInfo.title}
            alertMessage={discardAlertSInfo.message}
            buttons={discardAlertSInfo.buttons}
          /> */}
      <RBErrorAlert
        show={submitErrorMessage ? true : false}
        errorMessage={errorMessage}
        onClose={() => {
          setSubmitErrorMessage(undefined);
        }}
      />
      <RBModal
          open={resumeModal}
          onClose={onResumeClose}
          modalTitle={"Signature"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              rendersignatureContent()
          }
        />



        <RBModal
          open={biddingVisible}
          onClose={onBidClose}
          modalTitle={"Apply with requested rate"
          }
          header={
            <RBEmptyBlock
              style={{
                width: "100%",
                height: Spacing.large,
                backgroundColor: Colors.defaultGrey,
                flexShrink: 0,
              }}
            />
          }
         // actions={this.renderCreateShiftActions()}
          children={
              renderRequestedRateContent()
          }
        />

      <RBModal
        backArrowIcon
        open={showModal}
        onClose={onClose}
        modalTitle={
          //   currentCreateShiftState === CreateShiftState.Create
          //     ? "Create Shift"
          //     : "Preview Shift"
          "Shift Details"
        }
        header={
          <RBEmptyBlock
            style={{
              width: "100%",
              height: Spacing.large,
              backgroundColor: Colors.defaultGrey,
              flexShrink: 0,
            }}
          />
        }
       // actions={!isLoading && renderSearchedShiftDetailActions()}
        children={
          isLoading ? <LoadingIndicator /> : renderSearchedShiftDetail()
        }
      />
    </>
  );
};

export const ApplyShiftPage = withRouter(ApplyShiftPageWithoutRouter);
