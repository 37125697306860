import React, { useState, useEffect ,useContext} from "react";
import { AuthService } from "../../api/AuthService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, Colors, FontSize, Font, UIRelatedConstants } from "../../theme/Theme";
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioProps,
  withStyles,
  Select,
  MenuItem,
  InputLabel,
  makeStyles,
  createStyles,
  Tabs,
  Tab,
  IconButton,
  Card,
  CardContent,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router";
import { RoutePathConstant, roleNameEnum } from "../../util/Common";
import { RBContext } from "../../components/RBContext";
import { IndustryModeEnum, formatPaymentType, paymentTypeEnum } from "../../api/constants";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement,useStripe, useElements } from '@stripe/react-stripe-js';
//import "./style.css";
import { pageStyle, containerStyle, ColumnStyle } from "../../theme/ComponentTheme";
import { RBAppbar } from "../../components/RBAppbar";
import { RBButton } from "../../components/RBButton";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { RBModal } from "../../components/RBModal";
import { RBEmptyBlock } from "../../components/RBEmptyBlock";
import { PharmacyService } from "../../api/PharmacyService";
import CreditCard from "@material-ui/icons/CreditCard";
import { Button } from "@material-ui/core";
import { AddmultiplelocationsPayment } from "./AddmultiplelocationsPayment";
import { RBAlert } from "../../components/RBAlert";
import { AddmultiplelocationsJobPayment } from "./AddmultiplelocationsJobPayment";

interface StripePaymentPageProps {}
interface LocationState {
  tabType?: string;
  editMode?: boolean;
  newPharmacy?: boolean;
}

export const StripePayment: React.FC<StripePaymentPageProps> = ({
  ...props
}) => {
  console.log("stripe",props)
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { industryMode,primaryColor,secondaryColor, updateMode } = useContext(RBContext);
  const TabType = {
      Shift: 'Shift',
      Job: 'Job',
  };
  const [tabTypes, setTabTypes] = useState(TabType.Shift);
  const [locPaymentType, setLocPaymentType] = useState(null);
  const storedStripePromise = localStorage.getItem("stripePromise");
  const [paymentState, setPaymentState] = useState(0);
  const [account, setAccount] = useState<any>([]);
  const [pharmacyDetails, setPharmacyDetails] = useState<any>([]);
  const [showShiftDetails, setShowShiftDetails] = useState(true);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [jobCard, setJobCard] = useState<any>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [availablePaymentInfo, setAvailablePaymentInfo] = useState<any>(null);
  const [availablePaymentInfoArr , setAvailablePaymentInfoArr] = useState([]);
  const [selectedPaymentInfo , setSelectedPaymentInfo] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const stripePromise = storedStripePromise !== null ? loadStripe(storedStripePromise) : undefined;
  const [loading, setLoading] = useState(false);
  const [isAlertShowing, setIsAlertShowing] = useState(false);
  const [alertInfo,setAlertInfo]  = useState <any> ({title:"",message:"", buttons:[]})

  const appearance = {
    theme: 'stripe' as const,
  };
  const options = {
    appearance,
  };
  const { tabType, editMode, newPharmacy } = location.state || {};

  useEffect (() =>{
   
    (async () => {
      console.log("history",history)
      console.log("history",location)
      const userData = await AuthService.loadAccountInfo();
      setAccount(userData);
      let pharmaDetails = await AuthService.fetchPharmacy(userData.owner.defaultPharmacyId);
      setTabTypes((userData.networks[0].paymentConfig.paymentEnabled === true && pharmaDetails?.jobPostFlag || userData.networks[0].paymentConfig.paymentEnabled === true && !pharmaDetails?.jobPostFlag )? TabType.Shift : TabType.Job)
      if(tabType){
        setTabTypes(tabType);
        if(tabType === TabType.Shift){
          setPaymentState(0)
          setShowShiftDetails(true);
          setShowJobDetails(false)
  
        }
        else{
          setPaymentState(1)
          setShowShiftDetails(false);
          setShowJobDetails(true)
  
        }
      }
      else{
        setShowShiftDetails((userData.networks[0].paymentConfig.paymentEnabled === true && pharmaDetails?.jobPostFlag || userData.networks[0].paymentConfig.paymentEnabled === true && !pharmaDetails?.jobPostFlag )? true:false)
        setShowJobDetails((userData.networks[0].paymentConfig.paymentEnabled === true && pharmaDetails?.jobPostFlag || userData.networks[0].paymentConfig.paymentEnabled === true && !pharmaDetails?.jobPostFlag )? false:true)
      }
      const jobCardDetails = pharmaDetails.creditCards.find((x:any) => x.category == 'job')
      setJobCard(jobCardDetails)
      setPharmacyDetails(pharmaDetails)
      setLocPaymentType(pharmaDetails.paymentType)
      setIsPageLoading(false);
      debugger
      console.log(showShiftDetails)
    })();     
  },[])



  useEffect(() => {
    if(tabType){
      setTabTypes(tabType);
      if(tabType === TabType.Shift){
        setPaymentState(0)
        setShowShiftDetails(true);
        setShowJobDetails(false)

      }
      else{
        setPaymentState(1)
        setShowShiftDetails(false);
        setShowJobDetails(true)

      }
    }
  }, [tabType]);


useEffect (() =>{
  if(account)
  {
    setLocPaymentType(pharmacyDetails.paymentType)
  }
},[pharmacyDetails])
 

  const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textTransform: "none",
      // color: Colors.black,
      "&:hover": {
        color: Colors.themeBlue,
        opacity: 1,
      },
      "&$selected": {
        color: Colors.black,
        fontWeight: "600",
      },
      "&:focus": {
        color: Colors.black,
      },
    },
    selected: {
      //color: Colors.themeBlue,
    },
    indicator: {
      backgroundColor: Colors.black,
    },
    sectionHeader: {
      fontSize: Font.largest, fontWeight:"bold"
      //  backgroundColor: "red",
    },
    Card: {
      // "&:hover": {
      //   backgroundColor: "#E5E5E5",
      // },
    },
    CardContent: {
      paddingTop: 8,
      "&:last-child": {
        paddingBottom: 8,
      },
    },
    button: {
      borderRadius: UIRelatedConstants.ButtonBorderRadius,
      borderWidth: 1,
      backgroundColor: "#FFF",
      "&:hover": {
        backgroundColor: "#CDCDCD",
      },
    },
  })
  );

  const classes = useStyles();
  const handleShiftStateChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setPaymentState(newValue);
    if(newValue == 0){
      setTabTypes(TabType.Shift);
      setShowJobDetails(false);
      setShowShiftDetails(true);
    }
    else{
      setTabTypes(TabType.Job);
      setShowJobDetails(true);
      setShowShiftDetails(false);
    }
  };

  const renderAppBar = () => {
    return (
      <>
        <RBAppbar
          searchable={false}
          barName={'Payment Settings'}
          barChildren={
            <>
              {account.networks[0].paymentConfig.paymentEnabled === true && pharmacyDetails?.jobPostFlag && <Tabs
                value={paymentState}
                onChange={handleShiftStateChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                classes={{ indicator: classes.indicator }}
                style={{
                  marginRight: Spacing.small,
                }}
              >
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  label="Shifts"
                />
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  label="Jobs"
                />
              </Tabs>
              }
               {account.networks[0].paymentConfig.paymentEnabled === true && !pharmacyDetails?.jobPostFlag && <Tabs
                value={0}
                //onChange={handleShiftStateChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                classes={{ indicator: classes.indicator }}
                style={{
                  marginRight: Spacing.small,
                }}
              >
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  label="Shifts"
                />
              </Tabs>
              }
               {account.networks[0].paymentConfig.paymentEnabled === false && pharmacyDetails?.jobPostFlag && <Tabs
                value={0}
                //onChange={handleShiftStateChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                classes={{ indicator: classes.indicator }}
                style={{
                  marginRight: Spacing.small,
                }}
              >
                <Tab
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                  label="Jobs"
                />
              </Tabs>
              }
            </>
          }
        />
      </>
    );
  };
  const goToLocations = () =>{
    history.push('/addmultiplelocationsPayment',{
      editMode: false,
      account:account,
      newPharmacy: false,
    });
  }
  const goToJobLocations = () =>{
    history.push('/addmultiplelocationsJobPayment',{
      editMode: false,
      account:account,
      newPharmacy: false,
    });
  }

  const goToSetup = async() => {
    history.push('/paymentOption',{
      editMode: false,
      newPharmacy: false,
      selectedLoc:pharmacyDetails
    });
  };


  const goToJobCreditCardSetup = () => {
    account.payType = "Job";

    const selectedLocation = [pharmacyDetails];
    const pharmacy = pharmacyDetails;
    if (account.owner) {
      //Actions.paymentCreditInput({ account });
      if(pharmacy && pharmacy.creditCards && pharmacy.creditCards.length > 0){
        
        if(pharmacy.creditCards.length > 1)
        {
          if (pharmacy.creditCards[0].paymentCardId === pharmacy.creditCards[1].paymentCardId) {
            setShowPopup(false)
           // Actions.paymentCreditInput({ account,selectedLocation });
           history.push('/paymentCreditInput',{
            payType: 'Job',
            selectedLocation:selectedLocation
          });
          } 
          else {
            let paymentInfoArr = pharmacy.creditCards.find((x:any) => x.category == 'shift')
            if(paymentInfoArr){
              setShowPopup(true)
              setAvailablePaymentInfo(paymentInfoArr)
            }
            else{
              setShowPopup(false)
              history.push('/paymentCreditInput',{
                payType: 'Job',
                selectedLocation:selectedLocation
              });
            }
          }
        }
        else{
          if(pharmacy.creditCards[0].category == 'shift')
          {
            setShowPopup(true)
            setAvailablePaymentInfo(pharmacy.creditCards.find((x:any) =>x.isDefault));
          }
          else{
            setShowPopup(false)
            history.push('/paymentCreditInput',{
              payType: 'Job',
              selectedLocation:selectedLocation
            });
          }
         
        }
      }
      else{
        setShowPopup(false)
        history.push('/paymentCreditInput',{
          payType: 'Job',
          selectedLocation:selectedLocation
        });
      }
    }
}
const closePopup = () =>{
  setShowPopup(false)
}
const handleCCPaymentInfoChange = (index:any) => {
  setSelectedPaymentInfo(availablePaymentInfoArr[index])
  setAvailablePaymentInfo(availablePaymentInfoArr[index])
}

const renderPaymentArrayInfo = () =>{
  return(
    <>
      {availablePaymentInfoArr.length > 0 && 
      <div style={{ flex: 1 }}>
        <div>
        You have the following credit cards set up. If you would like to use one of these, please select the applicable card and select “Yes”. If you would like to add a new one, please select “No, Add New”.
        </div>
        {availablePaymentInfoArr.map((payment:any, index:number) => {
          
          return (
            <div onClick={() => handleCCPaymentInfoChange(index)} >
              <div style={{
                borderWidth: 2,
                borderColor: primaryColor,
                borderRadius: 5,
              }}>
                <div
                  style={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingRight: 8,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <input type="radio" id="delivery" style={{fontSize:14}} name="orderType" value="delivery" checked={ selectedPaymentInfo === availablePaymentInfoArr[index] ? true : false} required onChange={() =>handleCCPaymentInfoChange(index)}  />
                    {/* <CreditCard/> */}
                      <div style={{ paddingLeft: 6, }}>
                      {payment.category == 'job' ? 'Job Post Payment' : 'Shift Fee Payment' }
                      </div>
                  </div>
                 
                </div>

                <div style={{ marginLeft: 50, marginBottom: 15 }}>
                      <div style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
                          <CreditCard/>
                          <div style={{ flex: 1, fontWeight: 'bold', paddingLeft: 12,}}>
                          {formatPaymentType(paymentTypeEnum.creditCard)}
                          </div>
                        </div>

                        <div style={{ paddingLeft: 32, }}>
                          {payment.type} ending with {payment.lastFourDigits}
                        </div>
                        <div style={{ marginTop: 4, paddingLeft: 32 }}>
                          Expires: {payment.expiryMonth}/{payment.expiryYear}
                        </div>
                  
                </div>

              </div>
            </div>
          )
        })}
      </div>}
    </>
  )
}

const renderPaymentPopupModal = () => {

  return (
    <div
      style={{
        backgroundColor: Colors.defaultGrey,
        display: "flex",
        flex: 1,

      }}
    >
      
        <RBCardView
          style={{
            flex: 1,
            marginLeft: Spacing.large,
            marginRight: Spacing.large,
            marginBottom: Spacing.smallest,
            paddingLeft: Spacing.large,
            paddingTop: Spacing.large,
            paddingRight: Spacing.large,
            paddingBottom: Spacing.large,
          }}
        >
          <div>
        
             {availablePaymentInfoArr.length > 0 ?
                <div>
                    {renderPaymentArrayInfo()}
                </div>
              :
                <div>
                  You have the credit card ending in {availablePaymentInfo?.lastFourDigits} set up. Do you want to use this card?
                  
                </div>
              }
  
          
          </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}
        >

          <RBButton
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
            }}
            buttonBackgroundColor={secondaryColor}
            onClick={() => {
              setShowPopup(false)
              saveInvoicePayment(paymentTypeEnum.creditCard, availablePaymentInfo?.category == 'shift' ? true : false)
            }}
            text="Yes"
          />
          <RBButton
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
            }}
            buttonBackgroundColor={secondaryColor}
            onClick={() => {
              setShowPopup(false);
              const selectedLocation = [pharmacyDetails];
              history.push('/paymentCreditInput', {
                selectedLocation: selectedLocation,
                payType: 'Job'
              });

            }}
            text="No, Add New"
          />
        </div>
          
        </RBCardView>
     
    </div>
  );
};
const saveInvoicePayment = async (type:any,copyPayment:any) => {
  const pharmacy = pharmacyDetails;
  try {
    //setLoading(true);
    if(copyPayment){
      await PharmacyService.createPAD({ locationId: pharmacy.id, paymentType: type,paymentSetupType:'job',copyPayment:copyPayment });
    }
    else{
      await PharmacyService.createPAD({ locationId: pharmacy.id, paymentType: type,paymentSetupType:'job'});
    }
    setIsAlertShowing(true);
    setAlertInfo({
      title: "Payment info Saved",
      message: `Your payment information has been saved`,
      buttons: [
        <RBButton
          key={"confirm"}
          onClick={async () => {
            setIsAlertShowing(false);
            history.push('/dashboard');
          }}
          buttonBackgroundColor={primaryColor}
          color="primary"
          text="Go Home"
        />,
      ],
    });
   
  } catch (error) {
    setIsAlertShowing(true);
    setAlertInfo({
      title: "Payment info Error",
      message: `Unable to Validate Payment Information`,
      buttons: [
        <RBButton
          key={"confirm"}
          onClick={async () => {
            setIsAlertShowing(false);
            history.push('/dashboard');
          }}
          buttonBackgroundColor={primaryColor}
          color="primary"
          text="Go Home"
        />,
      ],
    });
  } finally {
    setLoading(false);
  }
  
}

const renderPaymentPopup = () => {
const pharmacy =  pharmacyDetails;
return(
  <>
    <RBModal open={showPopup}
       onClose={closePopup}
       modalTitle={""}
       header={
        <RBEmptyBlock
          style={{
            width: "100%",
            height: Spacing.large,
            backgroundColor: Colors.defaultGrey,
            flexShrink: 0,
          }}
        />
      }
      children={
          renderPaymentPopupModal()
      }
    />
  </>
)
}

  const renderNotSetup = () => {
    return (
      <RBCardView
      style={{
        margin: Spacing.large,
        padding: Spacing.large,
        height: "100%",
      }}
    >
        {renderTabHeader()}
        <div style={{ padding: 16 }}>
          {showShiftDetails && 
          <>
          <div>
            <div style={{textAlign:"justify"}}>
            Please select and configure your payment options for <b>Shift Placements</b>.
             Due to Stripe payment processing requirements, you must set up payment options for each location on your account. <br></br><br></br>
            Payments for <b>Shift Placements</b> made by pre-authorized debit or credit card will be automatically 
            charged upon confirmation of the shift details (hours) submitted by your candidate. Stripe processing fees may apply.<br></br><br></br>
            Stripe is a secure and highly respected third-party payment processor used by Relief Buddy.
              <br></br><br></br>
            </div>
          </div>
            {account && account.pharmacies?.length > 1 ?
             <div
              style={{
                ...ColumnStyle,
                padding: Spacing.large,
                marginTop: Spacing.xlarge,
                marginLeft:"30%",
                marginRight:"30%",
              }}
            >
                <RBButton
                key="Confirm"
                onClick={goToLocations}
                text="Choose Location to Set-up Payment"
                style={{background:secondaryColor, alignContent:"center"}}
              />
            </div>:
            <div
            style={{
              ...ColumnStyle,
              padding: Spacing.large,
              marginTop: Spacing.xlarge,
              marginLeft:"30%",
              marginRight:"30%",
            }}
          >
            <RBButton
              key="Confirm"
              onClick={goToSetup}
              text="Choose Payment Option"
              style={{background:secondaryColor}}
            />
            </div>
            }
          </>}
          {showJobDetails && 
          <>
          <div>
            <div style={{textAlign:"justify"}}>
            Please configure your payment information for permanent <b>Job Postings</b>. 
            Due to Stripe payment processing requirements, you must set up payment options for each location on your account.<br></br><br></br>
            Payment for permanent <b>Job Postings</b> will be automatically charged at the time of posting or renewal if expired. 
            Your payment will be processed via Stripe on your credit card, and Stripe processing fees may apply.<br></br><br></br>
            Stripe is a secure and highly respected third-party payment processor used by Relief Buddy.
             <br></br><br></br>
            </div>
          </div>
             {account && account.pharmacies?.length > 1 ?
             <div
              style={{
                ...ColumnStyle,
                padding: Spacing.large,
                marginTop: Spacing.xlarge,
                marginLeft:"30%",
                marginRight:"30%",
              }}
            >
                <RBButton
                key="Confirm"
                onClick={goToJobLocations}
                text="Choose Location to Set-up your Credit Card"
                style={{background:secondaryColor, alignContent:"center"}}
              />
            </div>:
            <div
            style={{
              ...ColumnStyle,
              padding: Spacing.large,
              marginTop: Spacing.xlarge,
              marginLeft:"30%",
              marginRight:"30%",
            }}
          >
            <RBButton
              key="Confirm"
              onClick={goToJobCreditCardSetup}
              text="Set up your Credit Card now"
              style={{background:secondaryColor}}
            />
            </div>
            }
            </>}
        </div>
      </RBCardView>
    );
  }
  const renderTabHeader = () => {
   // account.payType = tabType === TabType.Shift ? "Shift" : "Job";
    const paymentStatus = (tabTypes === TabType.Shift && locPaymentType !== null) || (tabTypes === TabType.Job && pharmacyDetails && pharmacyDetails.creditCards.length>0)
      ? 'Set Up'
      : 'Not set up';
   // account.editMode = paymentStatus == "Set Up" ? true : false;
   // account.newPharmacy = false;

    return (
      <div style={{flexDirection: 'row',
      paddingLeft: 16,
      paddingTop: 16,
      justifyContent: 'space-between',
      alignItems: 'center',}}>
        {tabTypes === TabType.Shift ?
        <div 
        className={classes.sectionHeader}
        color="primary"
        style={{
          display: "flex",
          flex: 1,
          overflowWrap: "anywhere",
        }}>Shift Fee Payment Options</div>
        :<div className={classes.sectionHeader}
        color="primary"
        style={{
          display: "flex",
          flex: 1,
          overflowWrap: "anywhere",
        }}>Job Post Payment Options</div>}
      
      </div>
    );
  };

  const renderPaymentInfo = () => {
    if (
      !account.owner ||
      !Array.isArray(account.pharmacies) ||
      !account.pharmacies.length
    ) {
      return null;
    }
    const pharmacy = pharmacyDetails;
      let defaultPayment;
    if(tabTypes === TabType.Job)
    {
      
        if (pharmacy.creditCards && pharmacy.creditCards.length > 0){
          defaultPayment = pharmacy.creditCards.find((x:any) => x.category == 'job');
        }
      if (!defaultPayment) {
        return null;
      }
    }


    return(
      <>
      {
      tabTypes === TabType.Shift ? 
        <AddmultiplelocationsPayment account ={account} paymentSetup="Setup" />
      :
        <AddmultiplelocationsJobPayment account ={account} paymentSetup="Setup" />

      }
      </>
      
    )
  }

  return (
    
    <>
      {isPageLoading ? (
        <LoadingIndicator />
      ) : (
        <>
         <RBAlert
          show={isAlertShowing}
          alertTitle={alertInfo.title}
          alertMessage={alertInfo.message}
          buttons={alertInfo.buttons}
        />
          {renderAppBar()}
          {(tabTypes === TabType.Shift && locPaymentType !== null &&  renderPaymentInfo()) || ((tabTypes === TabType.Job && jobCard) && renderPaymentInfo())}
          {(locPaymentType === null && tabTypes === TabType.Shift && renderNotSetup()) || (tabTypes === TabType.Job && jobCard === undefined && renderNotSetup())}
          {renderPaymentPopup()}
        </>
      )}

    </>
  );
};
