import React,{useContext} from "react";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { Colors, Spacing } from "../../../theme/Theme";
import formatService, {
  formatWorkerType,
} from "../../../util/FormattingService";
import { Avatar, Typography } from "@material-ui/core";
import { StarRatings } from "../../../components/StarRatings";
import { RBContext } from "../../../components/RBContext";

interface WorkerInformationProps {
  shiftData: ObjectLiteral;
}

const NoWorkerInformation = () => (
  <div>
    <div className={"section-header-text"}>Hired Worker</div>
    <div>
      <div>
        You haven&apos;t selected someone for this shift yet! Please review {}
        applicants to find workers.
      </div>
    </div>
    <div
      style={{
        height: 1,
        backgroundColor: Colors.defaultGrey,
        marginTop: Spacing.medium,
        marginBottom: Spacing.medium,
      }}
    />
  </div>
);

export const WorkerInformation: React.FC<WorkerInformationProps> = ({
  ...props
}) => {
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);

  const { shiftData } = props;
  const showPartialInfo =
    shiftData.flags.isCompletionAcknowledged || shiftData.flags.isCancelled;
  const pharmacist = shiftData.pharmacist;
  if (!pharmacist) {
    return null;
  }
  const pharmacistName =
    pharmacist.pharmacist.firstName + " " + pharmacist.pharmacist.lastName;
  let associationLogo = null;
  if (pharmacist.userAssociationMember.length > 0) {
    associationLogo = pharmacist.userAssociationMember[0].association.logo;
  }

  if (showPartialInfo) {
    const rating = pharmacist.rating.averageRating ?? 0;
    const numberRatings = pharmacist.rating.numberRatings ?? 0;
    return (
      <div>
        <div className={"section-header-text"}>
          Hired {formatWorkerType(shiftData.pharmacistType)}
        </div>
        {/* <div style={{ fontWeight: "bold", marginBottom: 4 }}>
          {pharmacistName}
        </div> */}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginTop: Spacing.large,
            marginBottom: Spacing.large,
          }}
        >
          <Avatar
            style={{ height: 96, width: 96, marginRight: Spacing.large }}
            src={pharmacist.pharmacist.profilePhoto}
          >
            :(
          </Avatar>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <Typography variant={"h5"}>
              {pharmacistName}
              <Typography
                variant={"body1"}
                style={{ fontWeight: "lighter", marginLeft: Spacing.small }}
                display={"inline"}
              >
                {formatWorkerType(pharmacist.pharmacist.type)}
              </Typography>
            </Typography>

            <StarRatings rating={rating} numberReviews={numberRatings} page="shiftDetails"/>
            {associationLogo && (
              <img
                src={associationLogo}
                alt={"associationLogo"}
                style={{
                  width: 96,
                  height: 36,
                  marginTop: 4,
                  marginBottom: 4,
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            height: 1,
            backgroundColor: Colors.defaultGrey,
            marginTop: Spacing.medium,
            marginBottom: Spacing.medium,
          }}
        />
      </div>
    );
  } else {
    console.log("WorkerInformation ", pharmacist);
    const rating = pharmacist.rating.averageRating ?? 0;
    const numberRatings = pharmacist.rating.numberRatings ?? 0;
    const phone = pharmacist.pharmacist.phone;
    const email = pharmacist.email;
    return (
      <div>
        <div className={"section-header-text"}>
          Hired {formatWorkerType(shiftData.pharmacistType)}
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginTop: Spacing.large,
            marginBottom: Spacing.large,
          }}
        >
          <Avatar
            style={{ height: 124, width: 124, marginRight: Spacing.large }}
            src={pharmacist.pharmacist.profilePhoto}
          >
            :(
          </Avatar>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <Typography variant={"h5"}>
              {pharmacistName}
              <Typography
                variant={"body1"}
                style={{ fontWeight: "lighter", marginLeft: Spacing.small }}
                display={"inline"}
              >
                {formatWorkerType(pharmacist.pharmacist.type)}
              </Typography>
            </Typography>
            <StarRatings rating={rating} numberReviews={numberRatings} />
            <Typography variant={"body1"}  style={{ marginTop: Spacing.small }}>
             License Number: {pharmacist.pharmacist?.licenseNumber ? pharmacist.pharmacist.licenseNumber : 'N/A'}
            </Typography>
            <Typography variant={"body1"}>
              {formatService.formatPhoneNumber(phone)}
            </Typography>
            <Typography variant={"body1"}>
              {formatService.formatEmail(email,primaryColor)}
            </Typography>
            {associationLogo && (
              <img
                src={associationLogo}
                alt={"associationLogo"}
                style={{
                  width: 96,
                  height: 36,
                  marginTop: 4,
                  marginBottom: 4,
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            height: 1,
            backgroundColor: Colors.defaultGrey,
            marginTop: Spacing.medium,
            marginBottom: Spacing.medium,
          }}
        />
      </div>
    );
  }
};
