import React, { CSSProperties } from "react";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@material-ui/icons/Room";
import { Icon } from "@material-ui/core";
import { Colors } from "../theme/Theme";
const AnyReactComponent = (text: string) => <div>{text}</div>;

interface RBMapProps {
  style?: CSSProperties;
  lat: number;
  lng: number;
  markerFlag?: boolean;
}
export const RBMap: React.FC<RBMapProps> = ({ style, lat, lng,markerFlag }) => {
  const defaultProps = {
    zoom: 15,
  };
  const renderMarker = (lat: number, lng: number) => {
    return (
      <div>
        <Icon
          style={{
            textAlign: "center",
            transform: "translate(-50%,-50%)",
            position: "absolute",
          }}
        >
          {/* <img alt="Marker" style={{ height: "100%" }} src={RoomIcon} /> */}
          <RoomIcon />
        </Icon>
      </div>
    );
  };
  const Marker = (props: any) => {
    return (
      <div>
        <Icon
          style={{
            textAlign: "center",
            transform: "translate(-50%,-50%)",
            position: "absolute",
          }}
        >
          {/* <img alt="Marker" style={{ height: "100%" }} src={RoomIcon} /> */}
          {markerFlag ? null:
          <RoomIcon
            style={{ color: Colors.themeBlue, fontSize: 30, height: "100%" }}
          />}
        </Icon>
      </div>
    );
  };
  const mapOptions = {
    fullscreenControl:markerFlag ?  false : true, // Set to true to show the full-screen control, false to hide it
    // Other map options...
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100vh", width: "100%", ...style }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDqGurgUDrK6ViMUDc-gAhIbLYNEksmsso" }}
        defaultCenter={{ lat, lng }}
        defaultZoom={defaultProps.zoom}
        options={mapOptions}
      >
        <Marker lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
};
