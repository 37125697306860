import React,{useContext} from "react";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { Colors, Spacing } from "../../../theme/Theme";
import formatService, {
  formatWorkerType,
} from "../../../util/FormattingService";
import { Avatar, Typography } from "@material-ui/core";
import { StarRatings } from "../../../components/StarRatings";
import { RBContext } from "../../../components/RBContext";
import { IndustryModeEnum } from "../../../api/constants";

interface WorkerInformationProps {
  jobData: ObjectLiteral;
}

const NoWorkerInformation = () => (
  <div>
    <div className={"section-header-text"}>Hired Worker</div>
    <div>
      <div>
        You haven&apos;t selected someone for this shift yet! Please review {}
        applicants to find workers.

        You haven&apos;t selected someone for this job yet. Please review
          applicants or select the “Find Candidates” button at the bottom to
          find available candidates.
      </div>
    </div>
    <div
      style={{
        height: 1,
        backgroundColor: Colors.defaultGrey,
        marginTop: Spacing.medium,
        marginBottom: Spacing.medium,
      }}
    />
  </div>
);

export const WorkerInformation: React.FC<WorkerInformationProps> = ({
  ...props
}) => {
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);

  const { jobData } = props;
  const pharmacist = jobData.pharmacist;
  const renderNoWorkerInformation = () => {
    return (
      <div>
        <div className={"section-header-text"}>Hired Worker</div>

        {industryMode === IndustryModeEnum.Pharmaceutical ?
          <div>
            You haven&apos;t selected someone for this job yet! Please review { }
            applicants to find workers.
          </div>
          : <div>
           You haven&apos;t selected someone for this job yet. Please review
          applicants or select the “Find Candidates” button at the bottom to
          find available candidates.
          </div>
        }
        <div
        style={{
          height: 1,
          backgroundColor: Colors.defaultGrey,
          marginTop: Spacing.medium,
          marginBottom: Spacing.medium,
        }}
      />
      </div>
    );
  };
  if (!pharmacist) {
    return renderNoWorkerInformation();
  }

  const pharmacistName =
    pharmacist.pharmacist.firstName + " " + pharmacist.pharmacist.lastName;
  let associationLogo = null;
  if (pharmacist.userAssociationMember.length > 0) {
    associationLogo = pharmacist.userAssociationMember[0].association.logo;
  }

  
    console.log("WorkerInformation ", pharmacist);
    const rating = pharmacist.rating.averageRating ?? 0;
    const numberRatings = pharmacist.rating.numberRatings ?? 0;
    const phone = pharmacist.pharmacist.phone;
    const email = pharmacist.email;
    return (
      <div>
        <div className={"section-header-text"}>
          Hired {formatWorkerType(jobData.pharmacistType)}
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            marginTop: Spacing.large,
            marginBottom: Spacing.large,
          }}
        >
          <Avatar
            style={{ height: 124, width: 124, marginRight: Spacing.large }}
            src={pharmacist.pharmacist.profilePhoto}
          >
            :(
          </Avatar>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <Typography variant={"h5"}>
              {pharmacistName}
              <Typography
                variant={"body1"}
                style={{ fontWeight: "lighter", marginLeft: Spacing.small }}
                display={"inline"}
              >
                {formatWorkerType(pharmacist.pharmacist.type)}
              </Typography>
            </Typography>

            <StarRatings rating={rating} numberReviews={numberRatings} page="jobDetails" />

            <Typography variant={"body1"} style={{ marginTop: Spacing.medium, color:primaryColor}}>
              {formatService.formatPhoneNumber(phone)}
            </Typography>
            <Typography variant={"body1"}>
              {formatService.formatEmail(email,primaryColor)}
            </Typography>
            {associationLogo && (
              <img
                src={associationLogo}
                alt={"associationLogo"}
                style={{
                  width: 96,
                  height: 36,
                  marginTop: 4,
                  marginBottom: 4,
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            height: 1,
            backgroundColor: Colors.defaultGrey,
            marginTop: Spacing.medium,
            marginBottom: Spacing.medium,
          }}
        />
      </div>
    );
  
};
