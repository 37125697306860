import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthService } from '../api/AuthService';


export const AccountInfoContext = createContext<any>(null);

export const AccountInfoProvider = ({ ...props}) => {
  const [accountInformation, setAccountInformation] = useState<any>({});
  const [isPageLoading, setIsPageLoading] = useState<any>(true);

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const userData = await AuthService.loadAccountInfo();
        setAccountInformation(userData);
      } catch (error) {
        console.error('Error fetching account info:', error);
      } finally {
        setIsPageLoading(false);
      }
    };

    fetchAccountInfo();
  }, []);

  return (
    <AccountInfoContext.Provider value={{ accountInformation,setAccountInformation, isPageLoading }}>
      {props.children}
    </AccountInfoContext.Provider>
  );
};

// export const useAccountInfo = () => {
//   return useContext(AccountInfoContext);
// };
