import React, { useState, useEffect ,useContext} from "react";
import { AuthService } from "../../api/AuthService";
import { ObjectLiteral } from "../../interfacesProps/ObjectLiteralProps";
import { RBCardView } from "../../components/RBCardView";
import { Spacing, Colors, FontSize, Font } from "../../theme/Theme";
import {
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router";
import { RBContext } from "../../components/RBContext";

import {CardElement,useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement, } from '@stripe/react-stripe-js';
import { ColumnStyle } from "../../theme/ComponentTheme";
import { RBButton } from "../../components/RBButton";
import { PharmacyService } from "../../api/PharmacyService";
import { RBAlert } from "../../components/RBAlert";

interface LocationState {
  selectedLocation?:ObjectLiteral; 
  payType?:string;

}

export const CreditCardForm = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const {selectedLocation,payType } = location.state || {};
  const { industryMode,primaryColor,secondaryColor, updateMode } = useContext(RBContext);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [isFormCardValid, setIsFormCardValid] = useState(false);
  const [isFormExpiryValid, setIsFormExpiryValid] = useState(false);
  const [isFormCvcValid, setIsFormCvcValid] = useState(false);
  const [isFormNameValid, setIsFormNameValid] = useState(false);
  const [isAlertShowing, setIsAlertShowing] = useState(false);
  const [alertInfo,setAlertInfo]  = useState <any> ({title:"",message:"", buttons:[]})

  const cardElementOptions = {
    hidePostalCode: true,
    showIcon: true,
    iconStyle: 'solid' as const,
    style: {
        base: {
            iconColor: 'black',
            color: 'black',
            fontWeight: 400,
            fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
            fontSize: '18px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#fce883',
            },
            '::placeholder': {
                color: 'lightgrey',
            },
            '::selection' :{
              color:'red'
            }
        },
        invalid: {
            iconColor: 'red',
            color: 'red',
        },
    },
    // other options can be added here if needed
  };
  useEffect(() => {
    
  }, []);

  const handleSubmit = async () =>{
    debugger
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    // const cardElement = elements.getElement(CardElement);
    // if (!cardElement) {
    //   // Handle the case where CardElement is not found.
    //   return;
    // }
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      // Handle the case where CardElement is not found.
      return;
    }
    const { token, error } = await stripe.createToken(cardNumberElement);
    if (error) {
      console.error(error.message);
      setIsLoading(false);
      // Handle error (e.g., display error message to the user)
    } else {
      try {
        setIsLoading(true);
        await PharmacyService.createCreditCard(token?.id,selectedLocation,payType);
        setIsAlertShowing(true);
        setAlertInfo({
          title: "Payment Info Registered",
          message: `Your credit card has successfully linked with your account.`,
          buttons: [
            <RBButton
              key={"confirm"}
              onClick={async () => {
                setIsAlertShowing(false);
                history.push('/dashboard');
              }}
              buttonBackgroundColor={primaryColor}
              color="primary"
              text="Go Home"
            />,
          ],
        });
      } catch (error) {
        console.log("card",error.response)
        if(error.response && error.response?.data?.message)
        {
          setIsAlertShowing(true);
          setAlertInfo({
            title: "Registration failed",
            message: `${error.response.data.message }`,
            buttons: [
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  setIsAlertShowing(false);
                  history.push('/dashboard');
                }}
                buttonBackgroundColor={primaryColor}
                color="primary"
                text="Go Home"
              />,
            ],
          });
        }
        else{
          setIsAlertShowing(true);
          setAlertInfo({
            title: "Registration failed",
            message: `Unable to Validate Payment Information`,
            buttons: [
              <RBButton
                key={"confirm"}
                onClick={async () => {
                  setIsAlertShowing(false);
                  history.push('/dashboard');
                }}
                buttonBackgroundColor={primaryColor}
                color="primary"
                text="Go Home"
              />,
            ],
          });
        }
      } finally {
        setIsLoading(false);
      }
    }

    setIsLoading(false);
  }
  const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textTransform: "none",
      // color: Colors.black,
      "&:hover": {
        color: Colors.themeBlue,
        opacity: 1,
      },
      "&$selected": {
        color: Colors.black,
        fontWeight: "600",
      },
      "&:focus": {
        color: Colors.black,
      },
    },
    selected: {
      //color: Colors.themeBlue,
    },
    indicator: {
      backgroundColor: Colors.black,
    },
    sectionHeader: {
      fontSize: Font.largest, fontWeight:"bold"
      //  backgroundColor: "red",
    },
  })
  );
  const classes = useStyles();
  const renderHeader = () => {
   
 
     return (
       <div style={{
         flexDirection: 'row',
         //paddingLeft: 8,
         paddingTop: 16,
         paddingBottom: 18,
         justifyContent: 'space-between',
         alignItems: 'center',
       }}>
         <div className={classes.sectionHeader}
           color="primary"
           style={{
             display: "flex",
             flex: 1,
             overflowWrap: "anywhere",
           }}>Payment Information</div>

       </div>
     );
   };


const handleCardNumberChange = (event:any) => {
  setIsFormCardValid(event.complete);
};

const handleCardExpiryChange = (event:any) => {
  setIsFormExpiryValid(event.complete);
};

const handleCardCvcChange = (event:any) => {
  setIsFormCvcValid(event.complete);
};
const handleCardNameChange = (event:any) => {
  if(event.target.value !== ""){
    setIsFormNameValid(true);
  }
  else{
    setIsFormNameValid(false);
  }
  
};

  const renderCard = () => {
    return (
      <>
       
       
      <RBCardView
        //s   marginHorizontal={Spacing.large}
        style={{
          boxShadow: "0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07)",
          borderRadius: "7px",
          padding:Spacing.large,
          margin:Spacing.large,
        }}
      >
        
            {/* <form id="payment-form"> */}
            <div style={{
            paddingBottom:25,paddingLeft:25,paddingRight:25,
            margin:25
          }}>
            {renderHeader()}
              <div id="payment-element">
                <div style={{textAlign:"left",paddingBottom:5}}> Card Number</div>
                <div style={{textAlign:"left"}} className="card-input-container">
                   <CardNumberElement  options={cardElementOptions} onChange={handleCardNumberChange}/>
                   </div>
              </div>
              <div className="row">
              <div id="payment-element" className="column">
                <div style={{textAlign:"left",paddingBottom:5}}> Expiry Date</div>
                <div style={{textAlign:"left"}} className="card-input-container">
                   <CardExpiryElement  options={cardElementOptions} onChange={handleCardExpiryChange}/>
                   </div>
              </div>
              <div id="payment-element" className="column" style={{marginRight:0}}>
                <div style={{textAlign:"left",paddingBottom:5}}> CVC/CCV</div>
                <div style={{textAlign:"left"}} className="card-input-container">
                   <CardCvcElement  options={cardElementOptions} onChange={handleCardCvcChange}/>
                   </div>
              </div>
              </div>
              <div id="payment-element">
                <div style={{textAlign:"left",paddingBottom:5}}> Cardholder's Name</div>
                <input
                    style={{
                      padding: "12px 4px",
                      borderRadius:5,
                      border: "1px solid rgb(220 220 220)",
                      fontSize:15,
                      alignContent:"left",
                      width:"100%",
                    }}
                    onChange={handleCardNameChange}
                    placeholder="Full Name"
                    className="inputWithCustomPlaceholder"
                    
                  />
              </div>
              <div style={{paddingTop:18, paddingBottom:18}}
            >
               <RBButton
                  isLoading={isLoading}
                  disabled={!isFormCardValid|| !isFormCvcValid || !isFormExpiryValid || !isFormNameValid || isLoading}
                  style={{
                    paddingTop:12, 
                    paddingBottom:12,
                    borderRadius:2
                  }}
                  className="CCButton"
                  buttonBackgroundColor={secondaryColor}
                  text="Submit"
                  onClick={() =>  handleSubmit()}
                />  
            </div>
            </div>
            {/* </form>
            <br></br> */}
            
       
      </RBCardView>
      </>
    );
  };
  return <>
        <RBAlert
          show={isAlertShowing}
          alertTitle={alertInfo.title}
          alertMessage={alertInfo.message}
          buttons={alertInfo.buttons}
        />
        {renderCard()}
        </>;
};
