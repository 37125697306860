import React from "react";
import { Formik, Form } from "formik";
import { Schema } from "../YupSchema";
import {
  RBTextFieldForForm,
  RBSelectForForm,
  RBTextFieldForFormFieldType,
} from "../../../components/RBTextField";
import { Spacing } from "../../../theme/Theme";
import { CanadaProvinces } from "../../../util/Common";
import { RBMonthYearPicker } from "../../../components/RBMonthYearPicker";
import { workerTypeEnum } from "../../../util/Enumeration";

export interface NonLicensedDentistInformationValueTypes {
  experienceMonth: number;
  graduationYear: string;
  graduationInstitution: string;
}

interface NonLicensedDentistInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: NonLicensedDentistInformationValueTypes;
  workerType?: workerTypeEnum;
  account?:any;
}

export const NonLicensedDentistInformation: React.FC<NonLicensedDentistInformationProps> = ({
  ...props
}) => {
  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={Schema.NonLicensedDentistInformation}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={Schema.NonLicensedDentistInformation.isValidSync(
        props.initialValues
      )}
      onSubmit={() => {}}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          onKeyUp={props.onFormChange}
          onMouseDown={props.onFormChange}
        >
          


          <RBTextFieldForForm
            FieldType={RBTextFieldForFormFieldType.yearGraduation}
            label="Year of Graduation"
            placeholder="Required"
            name="graduationYear"
            value={values.graduationYear}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="Institution Name"
            placeholder="Required"
            name="graduationInstitution"
            value={values.graduationInstitution}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBMonthYearPicker
            numberOfMonths={values.experienceMonth}
            onValueChange={(value: number) =>
              setFieldValue("experienceMonth", value, true)
            }
            workerType={props.workerType}
          />    
        </Form>
      )}
    </Formik>
  );
};
