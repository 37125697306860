import React, { useState, useEffect,useContext } from "react";
import {
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  Link,
} from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";

import * as Yup from "yup";
import {
  RBTextFieldForForm,
  RBTextFieldForFormFieldType,
} from "../../../components/RBTextField";
import { Spacing, Colors } from "../../../theme/Theme";
import { RBButton } from "../../../components/RBButton";
import { EmptyString } from "../../../util/Common";
import { RBExternalHelpLink } from "../../../components/RBExternalHelpLink";
import { HelperPageTitleEnum } from "../../../util/Enumeration";
import { Schema } from "../YupScheme";
import { RBContext } from "../../../components/RBContext";
import { RBShiftAgreementModal } from "../../../components/RBShiftAgreementModal";
import { RBEmptyBlock } from "../../../components/RBEmptyBlock";


export interface DesignatedManagerInformationValueTypes {
  designatedManagerFirstName: string;
  designatedManagerLastName: string;
  designatedManagerPhoneNumber: string;
  designatedManagerLicenseNumber: string;
  agreePrivacyPolicy: boolean;

  ownerFirstName: string;
  ownerLastName: string;
  ownerPhoneNumber: string;
}
interface DesignatedManagerInformationProps {
  innerRef?: any;
  onFormChange: any;
  initialValues: DesignatedManagerInformationValueTypes;
  account?: any;
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const DesignatedManagerInformation: React.FC<DesignatedManagerInformationProps> = ({
  ...props
}) => {

  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const [showTermOfService, setShowTermOfService] = useState(false) 
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false) 

  const setShowTermAndService = (showTermOfService:boolean) =>
    setShowTermOfService(showTermOfService);

  const setShowPrivacyAndPolicy = (showPrivacyPolicy:boolean) =>
  setShowPrivacyPolicy(showPrivacyPolicy);

  const onTermsClose = () =>{
    setShowTermOfService(false);
    }
  const onPrivacyClose = () =>{
    setShowPrivacyPolicy(false);
    }
  return (
    <Formik
      initialValues={props.initialValues}
      innerRef={props.innerRef}
      validationSchema={Schema.DesignatedManagerInformation}
      validateOnBlur={false}
      validateOnMount={true}
      validateOnChange={true}
      isInitialValid={Schema.DesignatedManagerInformation.isValidSync(
        props.initialValues
      )}
      onSubmit={(values) => {
        // same shape as initial values
        // console.log("onSubmit", values);
      }}
    >
      {({
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        setFieldValue,
      }) => (
        <Form
          onSubmit={handleSubmit}
          // basically call onFormChange to check if the form is valid
          //   onKeyUp={props.onFormChange}
          //    onMouseDown={props.onFormChange}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1" style={{ marginBottom: Spacing.small }}>
              Designated Manager Contact
            </Typography>
            <RBButton
              text={"Same As Account Owner"}
              onClick={() => {
                setFieldValue(
                  "designatedManagerFirstName",
                  props.initialValues.ownerFirstName,
                  true
                );
                setFieldValue(
                  "designatedManagerLastName",
                  props.initialValues.ownerLastName,
                  true
                );
                setFieldValue(
                  "designatedManagerPhoneNumber",
                  props.initialValues.ownerPhoneNumber,
                  true
                );
              }}
              buttonBackgroundColor={secondaryColor}
              style={{ marginBottom: Spacing.large }}
            />
          </div>
          <RBTextFieldForForm
            label="First Name"
            name="designatedManagerFirstName"
            value={values.designatedManagerFirstName}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            label="Last Name"
            name="designatedManagerLastName"
            value={values.designatedManagerLastName}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <RBTextFieldForForm
            FieldType={RBTextFieldForFormFieldType.phone}
            label="Phone Number"
            name="designatedManagerPhoneNumber"
            value={values.designatedManagerPhoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />

          <RBTextFieldForForm
            label="License Number"
            name="designatedManagerLicenseNumber"
            value={values.designatedManagerLicenseNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size={"small"}
            style={{
              marginBottom: Spacing.large,
            }}
          />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              name="agreePrivacyPolicy"
              checked={values.agreePrivacyPolicy}
              style={{
                padding: 0,
                paddingRight: Spacing.large,
                // Why marginLeft -2, so  it will align with regular label or text field
                marginLeft: -2,
                color: values.agreePrivacyPolicy
                  ? secondaryColor
                  : Colors.darkGrey,
              }}
              onChange={async (_, checked) => {
                setFieldValue("agreePrivacyPolicy", checked, true);

                await sleep(500);
                console.log(
                  "check box on change ",
                  checked,
                  values.agreePrivacyPolicy
                );
              }}
              onMouseDown={(e) => {
                console.log("on mouse down", e);
                props.onFormChange(e);
              }}
              onMouseUp={(e) => {
                console.log("on mouse up", e);
                props.onFormChange(e);
              }}
            />
            <Typography variant="body2">
              I have read and understood the{" "}
              <Link onClick={() => setShowTermAndService(true)}  style={{color: Colors.themeDarkBlue}}>{HelperPageTitleEnum.termOfService}{" "}
            </Link>{" "}
            and{" "}
            <Link onClick={() => setShowPrivacyAndPolicy(true)} style={{color: Colors.themeDarkBlue}}>{HelperPageTitleEnum.privacyPolicy}{" "}
            </Link>.
            </Typography>
          </div>
          {showTermOfService && <RBShiftAgreementModal
            open={showTermOfService}
            onClose={onTermsClose}
            modalTitle={"Terms of Service"}
            pageName="terms-of-use"
            pageType="page"
            networkId= {props.account?.networks[0].id}
            header={
              <RBEmptyBlock
                style={{
                  width: "100%",
                  height: Spacing.large,
                  backgroundColor: Colors.defaultGrey,
                  flexShrink: 0,
                }}
              />
            }
          />
          }
          {showPrivacyPolicy && <RBShiftAgreementModal
            open={showPrivacyPolicy}
            onClose={onPrivacyClose}
            modalTitle={"Privacy Policy"}
            pageName="privacy-policy"
            pageType="page"
            networkId= {props.account?.networks[0].id}
            header={
              <RBEmptyBlock
                style={{
                  width: "100%",
                  height: Spacing.large,
                  backgroundColor: Colors.defaultGrey,
                  flexShrink: 0,
                }}
              />
            }
          />
          }
          <ErrorMessage
            component="div"
            name="agreePrivacyPolicy"
            className="invalid-feedback"
          >
            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
          </ErrorMessage>

          {/* <pre>{JSON.stringify(values, null, 2)}</pre>
          <pre>{JSON.stringify(errors, null, 2)}</pre> */}
        </Form>
      )}
    </Formik>
  );
};
