export const IndustryModeEnum = {
    Dentistry: 'Dentistry',
    Pharmaceutical: 'Pharmaceutical',
  };
  export const paymentTypeEnum = {
    creditCard:'credit_card',
    pad:'pad',
    invoice:'invoice'
  }
  export const formatPaymentType = (paymentType:any) =>{
    switch (paymentType) {
      case paymentTypeEnum.creditCard:
        return 'Credit Card';
      case paymentTypeEnum.pad:
        return 'Pre-Authorized Debit (PAD)';
      case paymentTypeEnum.invoice:
        return 'Traditional Invoice';
      default:
        return 'Something is wrong';
    }
  }

export const getIndustryMode = () => IndustryModeEnum.Pharmaceutical;