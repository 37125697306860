import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export const LoadingIndicator = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
      <div>Loading ...</div>
    </div>
  );
};
