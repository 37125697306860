import {
  withStyles,
  Switch,
  SwitchProps,
  FormControlLabel,
  FormControlLabelProps,
} from "@material-ui/core";
import { Colors } from "../theme/Theme";
import React from "react";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface RBSwitchProps extends SwitchProps {
  label?: React.ReactNode;
  labelPlacement?: string;
  formControlStyle?: CSSProperties;
}
export const PureRBSwitch = withStyles({
  switchBase: {
    color: Colors.defaultGrey,
    "&$checked": {
      color: localStorage.getItem("primaryColor") ?? Colors.themeDarkBlue,
    },
    "&$checked + $track": {
      backgroundColor: localStorage.getItem("secondaryColor") ??Colors.themeLightBlue,
    },
  },
  checked: {},
  track: {},
})(Switch);

export const RBSwitch: React.FC<RBSwitchProps> = ({
  label,
  labelPlacement,
  formControlStyle,
  ...props
}) => {
  const { checked, onChange, name, ...rest } = props;
  return (
    <FormControlLabel
      style={formControlStyle}
      control={
        <PureRBSwitch
          checked={checked}
          onChange={onChange}
          name={name}
          {...rest}
        />
      }
      label={label}
      labelPlacement={labelPlacement === "start" ? "start" : "end"}
    />
  );
};
