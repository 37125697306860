import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { ShiftListController } from "./pages/shiftList/ShiftListController";
import { ErrorPage } from "./pages/Error";
import { AuthRoute } from "./AuthRoute";
import { CssBaseline, makeStyles } from "@material-ui/core";
import { DashboardController } from "./pages/dashboard/DashboardController";
import { BottomAppBar } from "./components/BottomAppBar";
import { RBAuthorizedContainer } from "./components/RBAuthorizedContainer";
import { AppBackgroundColor } from "./theme/Theme";
import { RegisterPage } from "./pages/register/RegisterPage";
import { RegisterSuccessPage } from "./pages/RegisterSuccess/registerSuccess";
import { RoutePathConstant, EmptyString } from "./util/Common";
import { ConfirmShiftHourController } from "./pages/confirmShiftHours/ConfirmShiftHourController";
import { SettingPage } from "./pages/settings/SettingPage";
import { RegisterOwnerAndPharmacyPage } from "./pages/registerOwnerAndPharmacy/RegisterOwnerAndPharmacyPage";
import { RegisterWorkerPage } from "./pages/registerWorker/RegisterWorkerPage";
import { RBAppbar } from "./components/RBAppbar";
import { ViewApplicantsPage } from "./pages/viewApplicants/ViewApplicantsPage";
import { HelperPage } from "./pages/HelperPage";
import { SearchShiftPage } from "./pages/searchShift/SearchShiftPage";
import { ApplyShiftPage } from "./pages/applyShift/ApplyShiftPage";
import { CreateShiftPage } from "./pages/createShift/CreateShiftPage";
import { CreateDentalShiftPage } from "./pages/dentalShift/CreateDentalShiftPage";

import { ShiftDetailPage } from "./pages/shiftDetail/ShiftDetailPage";
import { SignInPage } from "./pages/signIn/SignInPage";
import { LastLocationProvider } from "react-router-last-location";
import { ProfilePage } from "./pages/profile/ProfilePage";
import { ContactUsPage } from "./pages/contactUs/contactUs";
import { SINBusinessSettingPage } from "./pages/sinBusinessSetting/SINBusinessSettingPage";
import { SINBusinessInfoPage } from "./pages/sinBusinessInfo/SINBusinessInfoPage";
import { SwitchPharmacy } from "./pages/switchPharmacy/SwitchPharmacy";
import { RegisterNewPharmacyPage } from "./pages/registerNewPharmacy/RegisterNewPharmacy";
import { ShiftSurveyPage } from "./pages/shiftSurvey/ShiftSurveyPage";
import { ForgetPasswordPage } from "./pages/forgetPassword/ForgetPasswordPage";
import { HelperPageTitleEnum } from "./util/Enumeration";
import { LabelText } from "./util/TextConstant";
import { EditShiftPage } from "./pages/editShift/EditShiftPage";
import { EditDentalShiftPage } from "./pages/dentalShift/EditDentalShiftPage";
import { PayrollPage } from "./pages/payroll/PayrollPage";
import { RBContext, RBProvider } from "./components/RBContext";
import { IndustryModeEnum } from "./api/constants";
import { PendingApprovalPage } from "./pages/pendingApproval/PendingApprovalPage";
import { JobListController } from "./pages/jobList/JobListController";

import { CreateJobPage } from "./pages/createJob/CreateJobPage";
import { CreateDentalJobPage } from "./pages/dentalJob/CreateDentalJobPage";
import { SearchJobPage } from "./pages/searchJob/SearchJobPage";
import { EditJobPage } from "./pages/editJob/EditJobPage";
import { EditDentalJobPage } from "./pages/dentalJob/EditDentalJobPage";
import { ApplyJobPage } from "./pages/applyJob/ApplyJobPage";
import { JobCandidatesPage } from "./pages/jobCandidates/JobCandidatesPage";
import { JobApplicantsPage } from "./pages/jobApplicants/JobApplicantsPage";
import { JobDetailPage } from "./pages/jobDetail/JobDetailPage";
import { StripePayment } from "./pages/stripePayment/StripePayment";
import { PaymentOption } from "./pages/stripePayment/PaymentOption";
import { PadWebpage } from "./pages/stripePayment/PadWebpage";
import { PadSuccess } from "./pages/stripePayment/PadSuccess";
import { PadFailure } from "./pages/stripePayment/PadFailure";
import { PaymentCreditInput } from "./pages/stripePayment/PaymentCreditInput";
import { AddmultiplelocationsPayment } from "./pages/stripePayment/AddmultiplelocationsPayment";
import { AddmultiplelocationsJobPayment } from "./pages/stripePayment/AddmultiplelocationsJobPayment";
import { SocialInvite } from "./pages/socialInvite/SocialInvite";
import { ApplicantsShiftPage } from "./pages/applicantsShift/ApplicantsShiftPage";
import { SearchMultipleShiftsByLocation } from "./pages/searchShift/SearchMultipleShiftsByLocation";
import { QuickRegistration } from "./pages/quickRegistration/QuickRegistration";
import { LoginPage } from "./pages/login/loginPage";
import { LoginPasswordPage } from "./pages/loginPassword/loginPasswordPage";
import { LoginVerificationPage } from "./pages/loginVerification/loginVerificationPage";
import { LoginCodeVerificationPage } from "./pages/loginCodeVerification/loginCodeVerificationPage";
import { RegisterationIncompletePage } from "./pages/registrationIncomplete/registrationIncompletePage";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function AppRouter() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      console.log("getWindowDimensions ", getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    
  }, []);
  const history = useHistory();

  const location = useLocation<{
    background?: any;
  }>();
  let background = location.state && location.state?.background;
  console.log("AppRouter ", location);
  return (
    <RBProvider>
      <Switch location={background || location}>
        <Route path="/" exact>
          <SignInPage />
        </Route>

        <Route path="/pre-registration" exact>
          <QuickRegistration/>
        </Route>
        <Route path={RoutePathConstant.invite + ":networkId"} exact>
          <QuickRegistration/>
        </Route>
        <Route path={RoutePathConstant.SignIn} exact>
          <SignInPage />
        </Route>
        <Route path={RoutePathConstant.Login} exact>
          <LoginPage />
        </Route>
        <Route path={RoutePathConstant.Password} exact>
          <LoginPasswordPage />
        </Route>
        <Route path={RoutePathConstant.AccountVerification} exact>
          <LoginVerificationPage />
        </Route>
        <Route path={RoutePathConstant.LogincodeVerification} exact>
          <LoginCodeVerificationPage />
        </Route>
        <Route path={RoutePathConstant.ForgetPassword} exact>
          <ForgetPasswordPage />
        </Route>
        <Route path="/register" exact>
          <RegisterPage />
        </Route>
        <Route path="/registerSuccess" exact>
          <RegisterSuccessPage />
        </Route>


        <AuthRoute path={RoutePathConstant.ChangePharmacy} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar barName="Change Pharmacy" />
                <SwitchPharmacy />
              </>
            }
          />
        </AuthRoute>
       
        <AuthRoute path={RoutePathConstant.ShiftSurvey + ":shiftId"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Shift Rating" />
                <ShiftSurveyPage />
              </>
            }
          />
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.PendingApproval + ":reason"} exact>
        <PendingApprovalPage />
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.EditShift + ":shiftId"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Edit Shift" />
                <EditShiftPage />
              </>
            }
          />
        </AuthRoute>
        
        <AuthRoute path={RoutePathConstant.PendingRegistration} exact>
                <RegisterationIncompletePage />
        </AuthRoute>


     
        <Route path={RoutePathConstant.Helper + ":pageTitle"} exact>
          <HelperPage />
        </Route>

        <Route path={RoutePathConstant.InviteOwner + ":networkId"} exact>
        <QuickRegistration/>
        </Route>

        <Route path={RoutePathConstant.InviteWorker + ":networkId"} exact>
        <QuickRegistration/>
        </Route>

        {/* Appbar helper page */}
        <Route path={RoutePathConstant.Help + ":pageTitle"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar
                  barName={
                    location.pathname ===
                    RoutePathConstant.Help + HelperPageTitleEnum.faq
                      ? LabelText.HelpFAQ
                      : location.pathname ===
                        RoutePathConstant.Help +
                          HelperPageTitleEnum.aboutReliefBuddy
                      ? LabelText.About
                      : location.pathname ===
                        RoutePathConstant.Help +
                          HelperPageTitleEnum.termOfService
                      ? LabelText.TermsOfService
                      : location.pathname ===
                        RoutePathConstant.Help +
                          HelperPageTitleEnum.privacyPolicy
                      ? LabelText.PrivacyPolicy
                      : EmptyString
                  }
                />
                <HelperPage />
              </>
            }
          />
        </Route>

        <AuthRoute path={RoutePathConstant.ShiftDetail + ":shiftId"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Shift Details" />
                <ShiftDetailPage />
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.ApplicantsShift} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Shift List" />
                <ApplicantsShiftPage />
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.EditShift + ":shiftId"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Edit Shift" />
                <EditShiftPage />
              </>
            }
          />
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.EditeDentalShift + ":shiftId"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Edit Dental Shift" />
                <EditDentalShiftPage />
              </>
            }
          />
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.EditJob + ":jobId"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Edit Job" />
                <EditJobPage />
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.StripePayment} exact>
          <RBAuthorizedContainer
            children={
              <>
                <StripePayment />
              </>
            }
          />
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.PaymentOption} exact>
          <RBAuthorizedContainer
            children={
              <>
                <PaymentOption/>
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.PadWebpage} exact>
          <RBAuthorizedContainer
            children={
              <>
                <PadWebpage history={history} location={location}/>
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.PadSuccess} exact>
          <RBAuthorizedContainer
            children={
              <>
                <PadSuccess/>
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.PadFailure} exact>
          <RBAuthorizedContainer
            children={
              <>
                <PadFailure/>
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.PaymentCreditInput} exact>
          <RBAuthorizedContainer
            children={
              <>
                <PaymentCreditInput/>
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.AddmultiplelocationsPayment} exact>
          <RBAuthorizedContainer
            children={
              <>
                <AddmultiplelocationsPayment/>
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.AddmultiplelocationsJobPayment} exact>
          <RBAuthorizedContainer
            children={
              <>
                <AddmultiplelocationsJobPayment/>
              </>
            }
          />
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.JobDetail + ":jobId"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Job Details" />
                <JobDetailPage />
              </>
            }
          />
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.EditeDentalJob + ":jobId"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Edit Dental Job" />
                <EditDentalJobPage />
              </>
            }
          />
        </AuthRoute>


        <AuthRoute path={RoutePathConstant.Shifts} exact>
          <RBAuthorizedContainer children={<ShiftListController />} />
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.Jobs} exact>
          <RBAuthorizedContainer children={<JobListController />} />
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.Payroll} exact>
          <RBAuthorizedContainer children={<PayrollPage />} />
          
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.ViewApplicant + ":shiftId"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Shift Applicant" />
                <ViewApplicantsPage />
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.JobApplicant + ":jobId"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Job Applicant" />
                <JobApplicantsPage />
              </>
            }
          />
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.ShiftConfirmHour + ":shiftId"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Shift Confirmation" />
                <ConfirmShiftHourController />
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.DashBoard} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar barName={localStorage.getItem("networkName")} />
                <DashboardController />
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.Profile} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar barName="Profile" />
                <ProfilePage />
              </>
            }
          />
        </AuthRoute>
        <AuthRoute path={RoutePathConstant.ContactUs} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar barName="Contact Us" />
                <ContactUsPage />
              </>
            }
          />
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.Setting} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar barName="Payment Settings" />
                <SettingPage />
              </>
            }
          />
        </AuthRoute>

        <AuthRoute path={RoutePathConstant.SINBusinessSetting} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="SIN/Business Settings" />
                <SINBusinessSettingPage />
              </>
            }
          />
        </AuthRoute>
        <AuthRoute
          path={RoutePathConstant.SINBusinessRegistrationSetting}
          exact
        >
          <RBAuthorizedContainer children={<SINBusinessInfoPage />} />
        </AuthRoute>

       
        <AuthRoute path="/about" exact component={About} />

        <Route path="/error" exact component={ErrorPage} />
        {/* </LastLocationProvider> */}
      </Switch>

      {/* Show the modal when a background page is set */}
      <AuthRoute path={RoutePathConstant.CreateShift} exact>
        <RBAuthorizedContainer
          children={
            <>
              <CreateShiftPage history={history} location={location} />
            </>
          }
        />
      </AuthRoute>
      
      <AuthRoute path={RoutePathConstant.CreateDentalShift} exact>
        <RBAuthorizedContainer
          children={
            <>
              <CreateDentalShiftPage history={history} location={location} />
            </>
          }
        />
      </AuthRoute>

      <AuthRoute path={RoutePathConstant.SearchShifts} exact>
        <RBAuthorizedContainer
          children={
            <>
              {/* <RBAppbar barName="Search Shift" /> */}
              <SearchShiftPage />
            </>
          }
        />
      </AuthRoute>
      <AuthRoute path={RoutePathConstant.SearchMultipleShiftsByLocation} exact>
        <RBAuthorizedContainer
          children={
            <>
              {/* <RBAppbar barName="Search Shift" /> */}
              <SearchMultipleShiftsByLocation />
            </>
          }
        />
      </AuthRoute>

      <AuthRoute path={RoutePathConstant.SearchShiftDetail + ":shiftId"} exact>
        <RBAuthorizedContainer
          children={
            <>
              {/* <RBAppbar barName="Apply Shift" /> */}
              <ApplyShiftPage />
            </>
          }
        />
      </AuthRoute>

      <AuthRoute path={RoutePathConstant.CreateJob} exact>
        <RBAuthorizedContainer
          children={
            <>
              <CreateJobPage history={history} location={location} />
            </>
          }
        />
      </AuthRoute>
      
      <AuthRoute path={RoutePathConstant.CreateDentalJob} exact>
        <RBAuthorizedContainer
          children={
            <>
              <CreateDentalJobPage history={history} location={location} />
            </>
          }
        />
      </AuthRoute>
        
      <AuthRoute path={RoutePathConstant.JobCandidates + ":jobId"} exact>
          <RBAuthorizedContainer
            children={
              <>
                <RBAppbar deeperLevel={true} barName="Job Candidates" />
                <JobCandidatesPage />
              </>
            }
          />
        </AuthRoute>

      <AuthRoute path={RoutePathConstant.SearchJobs} exact>
        <RBAuthorizedContainer
          children={
            <>
              {/* <RBAppbar barName="Search Shift" /> */}
              <SearchJobPage />
            </>
          }
        />
      </AuthRoute>

      <AuthRoute path={RoutePathConstant.SearchJobDetail + ":jobId"} exact>
        <RBAuthorizedContainer
          children={
            <>
              {/* <RBAppbar barName="Apply Shift" /> */}
              <ApplyJobPage />
            </>
          }
        />
      </AuthRoute>

      <Route path={RoutePathConstant.RegisterOwnAndPharmacy} exact>
        <RegisterOwnerAndPharmacyPage />
      </Route>
      <Route path={RoutePathConstant.RegisterWorker + ":workerType"} exact>
        <RegisterWorkerPage />
      </Route>
      <Route path={RoutePathConstant.RegisterNewPharmacy} exact>
        <RegisterNewPharmacyPage />
      </Route>
      {/* <Route path="/" render={() => <div>{location.pathname}</div>} /> */}
    </RBProvider>
  );
}

const RouterWrapper = () => {

  document.body.style.backgroundColor = AppBackgroundColor;
  const location = useLocation();

  // Define your routes and corresponding styles
  const noStyleRoutes = [
    '/',
    'signIn',
    '/Login',
    '/password',
    '/accountVerification',
    '/invite/',
    '/codeVerification',
    '/pre-registration',
    '/register',
    '/registerSuccess',
    '/signIn',
    '/inviteowner/',
    '/inviteworker/'


    // Add other routes that don't require the special style
  ];

  // Determine if the current route is one of the special cases
  const isSpecialRoute = !noStyleRoutes.includes(location.pathname);

  return (
    <div style={isSpecialRoute ? {} : { width: '100%' }}>
      <AppRouter />
    </div>
  );
}


const App = () => (
  <React.Fragment>
    <CssBaseline />
    <BrowserRouter>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flexGrow: 2, flexShrink: 2 }}></div>
        <RouterWrapper />
        <div style={{ flexGrow: 2, flexShrink: 2 }}></div>
      </div>
    </BrowserRouter>
  </React.Fragment>
);

export default App;



//export default App;
