import React, { useEffect, useRef, useState } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import '../components/SignaturePadStyle.css';
import moment from 'moment';
import { RBButton } from './RBButton';
import { Colors, Spacing } from '../theme/Theme';
import { RBEmptyBlock } from './RBEmptyBlock';
import { ObjectLiteral } from '../interfacesProps/ObjectLiteralProps';
import { ColumnStyle } from '../theme/ComponentTheme';
import { makeStyles } from '@material-ui/core';

type SignaturePadProps = {
    isVisible: boolean;
    onLoad: (file:File,dataURL: any) => void;
    onSave: (file:File,dataURL: any) => void;
    onCancel: () => void;
    onClear: () => void;
    userData: ObjectLiteral;
    page:string;
  };

  const useStyles = makeStyles((theme) => ({
    
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 2 * Spacing.larger,
        marginBottom: Spacing.larger,
        paddingLeft: 3 * Spacing.large,
        paddingRight: 3 * Spacing.large,
        margin: "0px",
        '@media (max-width: 768px)': {
            flexDirection: "column",
            margin: "0px",
            '& > *:not(:last-child)': {
                marginBottom: Spacing.medium,
            },
        },
        '@media (min-width: 769px)': {
            '& > *:not(:last-child)': {
                marginRight: Spacing.medium,  // Add margin-right between buttons on larger screens
            },
        },
    },

}));

const SignaturePad: React.FC<SignaturePadProps> = ({ isVisible, onLoad, onSave, onCancel, onClear, userData,page }) => {
  const signatureRef = useRef<ReactSignatureCanvas | null>(null)
  const [signatureDataURL, setSignatureDataURL] = useState<any>('');
  const [disableSave, setDisableSave] = useState(true);
  console.log("userData",userData)
  const classes = useStyles();
  useEffect(() => {
   // createDefaultSignature()
   setDisableSave(true)
    if(localStorage.getItem('workerSign') ){
        const file = convertimgFile(localStorage.getItem('workerSign'))
        onLoad(file,localStorage.getItem('workerSign')); // Send the dataURL back to parent
        //clearSignature();
    }
  }, []);

  useEffect(() => {
    setDisableSave(true)
    if (isVisible) {
      // Manually redraw the canvas when becoming visible
      if (signatureRef.current) {

        signatureRef.current.clear();
        //create default sign
        if(!userData.signature)
        {
          const canvas = signatureRef.current.getCanvas();
          const ctx = canvas.getContext('2d');
      
          if(ctx)
          {
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
      
          // Configure text styles
          ctx.font = 'italic 36px "Cedarville Cursive", cursive'
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
      
          // Replace these with the user's initials
          const firstnameInitial = userData.firstName+' ';
          const lastnameInitial = userData.lastName;
      
          // Calculate the position for the initials
          const x = canvas.width / 2;
          const y = canvas.height / 2;
      
          // Draw the initials on the canvas
          ctx.fillText(firstnameInitial + lastnameInitial, x, y);
          if(userData.firstName !== "" && userData.lastName !=="" )
          setDisableSave(false)
          }
          if(page == "applyShift" || page == "createShift")
          {
            onloadImage()
          }
          
        }
      }
    }
  }, [isVisible]);

  const clearSignature = () => {
    if (signatureRef.current) {
        signatureRef.current.clear();
        setDisableSave(true)
        //setSignatureDataURL('');
        localStorage.removeItem("workerSign")
        onClear();
    }
  };

  const onSignCancel = () => {
    console.log('hii')
    //setSignatureDataURL('');
    localStorage.removeItem("workerSign")
    setDisableSave(true)
    onCancel()
  };

  const onloadImage = () =>{
    console.log("file")
    setDisableSave(false)
    if(page == "applyShift" || page == "createShift")
    {
      saveSignature();
    }
  }

  const saveSignature = () =>{
    if (signatureRef.current) {
        const dataURL = signatureRef.current.toDataURL('image/png');
        localStorage.setItem('workerSign',dataURL)
        const file = convertimgFile(dataURL)
        onSave(file,dataURL); // Send the dataURL back to parent
        if(page == "applyShift" || page == "createShift")
        {
          onLoad(file,dataURL)
        }
       // clearSignature();
      }
  }
  const convertimgFile = (dataURL:any) =>{
        setSignatureDataURL(localStorage.getItem("workerSign"))
        setDisableSave(false)
        const now = moment().unix();
        // Convert DataURL to Blob
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: mimeString });

        // Create a File from the Blob
        const file = new File([blob], `signature-${now}.png`, { type: 'image/png' });
        return file;
  }

  return (
    <>
      <div style={{ display: isVisible ? 'block' : 'none' }}>
      {(page == "applyShift" || page == "createShift")&& <div
          style={
            ColumnStyle
          }
        >
          <div style={{ marginTop: 5, color: Colors.darkGrey, }}>Signature</div>

            <div><a onClick={clearSignature} style={{color:Colors.themeDarkBlue, cursor:"pointer"}}>Clear</a></div> 
        </div>}

        <ReactSignatureCanvas
          ref={signatureRef}
          penColor="black"
          canvasProps={{ width: (page == "workerRegistration") ? 730 : 540, height: 200, className: 'signature-canvas' }}
          onEnd={() => {
            onloadImage();
          }}
        />

<div className={classes.buttonContainer}>
          {page !== "applyShift" && page !== "createShift" && <RBButton
            key={"back"}
            onClick={() => {
              saveSignature();
            }}
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
              background: disableSave ? Colors.darkGrey : localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue 
            }}
            disabled={disableSave}
            text="Save"
          />}
          <RBEmptyBlock />
          {page == "workerRegistration" &&<RBButton
            key={"cancel"}
            onClick={onSignCancel}
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
              background:  localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue 
            }}
            text="Cancel"
          />}
          <RBEmptyBlock />
          {page !== "applyShift" && page !== "createShift" && <RBButton
            key={"submit"}
            onClick={clearSignature}
            style={{
              display: "flex",
              flex: 1,
              padding: Spacing.medium,
              margin: Spacing.large,
              background:  localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue 
            }}
            text="Clear"
          />
}
        </div>

      </div>
    </>
  );
};

export default SignaturePad;
